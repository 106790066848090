import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';
import { Helmet } from 'react-helmet';

import * as Hosts from '../../../Hosts';
import { reqGET } from '../../../Utils';

import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Loader from '../../../loader';
import Preview from '../Preview/Preview';
import Breadcrumb from '../Breadcrumb/Breadcrumb';

import AssecImage from "../ASSEC_Image/Image"

export default function Card(props) {
    const { pathname } = useLocation();

    const [isloading, setLoading] = useState(true);

    const data_interface = {
        id: '',
        info: {},
        content: {}
    }
    const [content, set_content] = useState(data_interface);

    useEffect(() => {
        try {
			let id = props.info.state.id;
			let slug = props.info.state.slug;
			let preview_id = props.info.state.preview;

            if(id !== '') {
        
                let getPreviewInfoId = async () => {
                    await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${preview_id}`)
                        .then(res => { 
                            if(res.data.length > 0) {
                                res.data[0].info['preview'] = true

                                var hoje = new Date();
                                let status = 0
                                if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                    var date_p = new Date(res.data[0].info.date.published);
        
                                    status = 1
                                    if(hoje >= date_p) 
                                        status = 2
                                }
       
                                if(res.data[0].info.preview === true) {
                                    //res.data[0].content = {text: 'preview'}
                                    console.log('Normal Preview')
        
                                    set_content(res.data[0])
                                    setLoading(false)   
                                }
                                else if(status !== 2) {
                                    res.data[0].content = {text: 'rascunho ou agendado'}
        
                                    set_content(res.data[0])
                                    setLoading(false)    
                                }
                                else {
                                    set_content(res.data[0]) 

                                    setLoading(false) 
                                }
                            }
                        })
                        .catch(erro => console.log('ERROR', erro))
                }

                let getInfoId = async () => {
                    await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${id}`)
                        .then(res => { 
                            if(res.data.length > 0) {
        
                                var hoje = new Date();
                                let status = 0
                                if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                    var date_p = new Date(res.data[0].info.date.published);
        
                                    status = 1
                                    if(hoje >= date_p) 
                                        status = 2
                                }
        
                                if(status !== 2) {
                                    res.data[0].content = {text: 'rascunho ou agendado'}
        
                                    set_content(res.data[0])
                                    setLoading(false)    
                                }
                                else {
                                    set_content(res.data[0]) 
                    
                                    setLoading(false) 
                                }
                            }
                        })
                        .catch(erro => console.log('ERROR', erro))
                }
            
                let getInfoSlug = async () => {
                    reqGET(`${Hosts.SIMCore_API}/web/content/slug/${Hosts.companycode}/${slug}`)
                        .then(res => { 
                            if(res.data.length > 0) {
        
                                var hoje = new Date();
                                let status = 0
                                if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                    var date_p = new Date(res.data[0].info.date.published);
        
                                    status = 1
                                    if(hoje >= date_p) 
                                        status = 2
                                }
        
                                if(status !== 2)  {
                                    res.data[0].content = [{text: ''}]
        
                                    set_content(res.data[0])
                                    setLoading(false)
                                }
                                else {        
                                    set_content(res.data[0]) 
        
                                    setLoading(false) 
                                }
                            }
                        })
                        .catch(erro => console.log('ERROR', erro))
                }
        
                if(preview_id !== undefined && preview_id !== 'undefined' && preview_id !== '') {
                    getPreviewInfoId()
                }
                else if(id !== undefined && id !== 'undefined' && id !== '') {
                    getInfoId()
                }
                else if(slug !== undefined && slug !== 'undefined' && slug !== '')  {
                    getInfoSlug()
                }
            }
            else {
                console.log('=== ""')
            }

            return

        
        } catch {
            console.log('nada')

			return
		}
        
    }, [props]);

    const get_seo_data = (type) => {

        if(type === 'title') {
            if(content.info.seo !== undefined && content.info.seo['title'] !== undefined  && content.info.seo['title'] !== '') {
                return content.info.seo['title']
            } 
            else {
                return Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_TITLE
            }
        }
        else if(type === 'description') {
            if(content.info.seo !== undefined && content.info.seo['description'] !== undefined  && content.info.seo['description'] !== '') {
                return content.info.seo['description']
            } 
            else if(content.content !== undefined && content.content.length > 0 && content.content[0]['abstract'] !== undefined && content.content[0]['abstract'] !== undefined  && content.content[0]['abstract'] !== '') {
                return content.content[0]['abstract']
            } 
            else {
                return Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_DESCRIPTION
            }
        }
        if(type === 'keywords') {
            if(content.info.seo !== undefined && content.info.seo['keywords'] !== undefined  && content.info.seo['keywords'] !== '') {
                return content.info.seo['keywords']
            } 
            else {
                return Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_KEYWORDS
            }
        }
        else if(type === 'image') {
            if(content.content !== undefined && content.content.length > 0 && content.content[0]['media'] !== undefined && content.content[0]['media']['image'] !== undefined && content.content[0]['media']['image'].length > 0 && content.content[0]['media']['image'][0]['link'] !== '') {
                return content.content[0]['media']['image'][0]['link']
            } 
            else {
                return Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_IMAGE
            }
        }
        else if(type === 'link') {
            let title_seo = Hosts.WEB_SITE_URI + pathname
            return '.' + title_seo.replace("//", "/");
        }
    }

    return (isloading ?

		<Loader />
		
		:
        <>
            {
            content.info['preview'] === true ? 
                <Preview />
            : 
                null
            }
            <Navbar />
            <Breadcrumb />
            <main className="div-card">
                <div>
                    <Helmet>
                        <title>{ get_seo_data('title') }</title>
                        <meta property="og:url" content={ get_seo_data('link') } />
                        <meta property="og:title" content={ get_seo_data('title') }></meta>
                        <meta name="twitter:title" content={ get_seo_data('title') }></meta>
                        <meta name="description" content={ get_seo_data('description') } />
                        <meta property="og:description" content={ get_seo_data('description') }></meta>
                        <meta name="twitter:description" content={ get_seo_data('description') }></meta>
                        <meta name="keywords" content={ get_seo_data('keywords') } />
                        <meta property="og:image" content={ get_seo_data('image') }></meta>
                        <meta name="twitter:image" content={ get_seo_data('image') }></meta>
                    </Helmet>
                </div>

                <section className="content-body">
                    <div className="container">
                        <div className="row mt-5">
                        {
                        content.content.map((el, k) => {  
                            return (
                                <div key={k}>
                                    {
                                    k === 0 ?
                                        <div className="col">
                                            {
                                            el.media !== undefined && el.media['link'] !== undefined && el.media['link'] !== '' ?
                                                <>
                                                    <div className="div-content col-12 col-xl-6 p-5 vh-100">
                                                        <h2 className={el.title !== '' ? "titulo-primario mb-5" : "d-none"}>{el.title}</h2>
                                                        <div className={"div-text"}>
                                                            {ReactHtmlParser(el.text)}
                                                        </div>
                                                    </div>

                                                    <div className="div-orange-image position-absolute bottom-0 d-none d-xl-block"></div>

                                                    <div className="div-image col-12 col-xl-6 text-center">
                                                        <AssecImage 
                                                            linkImage={el.media['link']}
                                                            width="450"
                                                            height="300"
                                                            fit='crop'
                                                            type='img'
                                                            sClass="img-fluid ms-5" 
                                                            alt={el.title}
                                                        />
                                                        {/*<img src={el.media['link']} className="img-fluid ms-5" alt={el.title} />*/}
                                                    </div>

                                                    <section className="div-scroll mt-5">
                                                        <div>
                                                            <span></span>
                                                            <p className="small mt-n2">Scroll</p>
                                                        </div>
                                                    </section>
                                                </>
                                            : 
                                                <>
                                                    <div id="div-content" className="div-content col-12 mb-5">
                                                        <h2 className={el.title !== '' ? "titulo-primario mb-5" : "d-none"}>{el.title}</h2>
                                                        <div className="div-text">
                                                            {ReactHtmlParser(el.text)}
                                                        </div>
                                                    </div>
                                                </>
                                            }

                                            {
                                            el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 0 ?
                                                <div className="div-content col-12 mb-5">
                                                    <div className="row div-gallery">
                                                        {
                                                        el.media.image.map((el_img, k_img) => {
                                                            return (
                                                                <div key={k_img} className="col-2">
                                                                    <AssecImage 
                                                                        linkImage={el_img.link}
                                                                        width="450"
                                                                        height="300"
                                                                        fit='crop'
                                                                        type='img'
                                                                        sClass="img-fluid" 
                                                                        alt=""
                                                                    />
                                                                    {/*<img className="img-fluid" src={el_img.link} alt="" />*/}
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                </div>
                                            :
                                                <></>
                                            }
                                            {
                                            el['media'] !== undefined && el['media']['doc'] !== undefined && el.media.doc.length > 0 ?
                                                <div className="div-content col-12 mb-5">
                                                    <div className="row div-documents">
                                                        {
                                                        el.media.doc.map((el_doc, k_doc) => {
                                                            return (
                                                                <div key={k_doc} className="mb-2">
                                                                    <AssecImage 
                                                                        linkImage={el_doc.link}
                                                                        width="450"
                                                                        height="300"
                                                                        fit='crop'
                                                                        type='img'
                                                                        sClass="img-fluid" 
                                                                        alt=""
                                                                    />
                                                                    {/*<img className="img-fluid" src={el_doc.link} alt="" />*/}
                                                                    <a className="btn btn-primary" href={el_doc.link} target="_blank" rel="noreferrer">{el_doc.name}</a>
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                </div>
                                            :
                                                <></>
                                            }
                                        </div>
                                    :
                                        null
                                    }
                                </div>
                            )
                        })
                        }
                        </div>
                    </div>
                </section>

                <section className="content-body content-list">
                    <div className="container">
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 g-3 mb-5">
                            {
                            content.content.map((el, k) => {  
                                return (
                                    k > 0 ?
                                        <div key={k} className="col">
                                            <div className="card shadow-sm">
                                                {
                                                el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 0 ?
                                                    <>
                                                        <AssecImage 
                                                            linkImage={el.media.image[0].link}
                                                            width="450"
                                                            height="300"
                                                            fit='crop'
                                                            type='img'
                                                            sClass="img-fluid" 
                                                            alt=""
                                                        />
                                                        {/* <img alt='' className="img-fluid" src={el.media.image[0].link} /> */}
                                                    </>
                                                :
                                                    ""
                                                }

                                                <div className="card-body">
                                                    <div className="card-text">{ReactHtmlParser(el.text)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    :
                                        null
                                )
                            })
                            }
                        </div>
                    </div>
                </section>

            </main>
            <Footer />
        </>
    )
}