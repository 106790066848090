import React, { useState, useEffect } from 'react';
//import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import './Footer.css';

import { language } from '../WEBContent/Language';

import { reqGET, /*set_language,*/ repo_logo_link, repo_site_documents_link, /*repo_img_link,*/ repo_site_assets_link, content_language } from '../../../Utils';

import * as Hosts from '../../../Hosts';

//import { facebook } from "@fortawesome/free-solid-svg-icons";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const Footer = (props) => {

  const [lang, set_lang] = useState(localStorage.getItem('lang'));

  const data_interface_navbar = {
      id: '',
      info: {},
      content: {
          childs: []
      }
  }

  const [navbar, set_navbar] = useState(data_interface_navbar);
	//const [isloading, setLoading] = useState(true);
	const [/*refresh*/, setRefresh] = useState(0);
  
	const [isloading, setLoading] = useState(true);

  

  useEffect(() => {

    set_lang(localStorage.getItem('lang'))

    let getInfoNavbar = async () => {

      reqGET(`${Hosts.SIMCore_API}/web/structure/${Hosts.companycode}/1`)
        .then(res => {
            res.data.forEach((v) => {
                if (v.info.principal === true) {
                    //set_dados(dados.current = v);
  
                    if(lang !== 'pt') {
                      let x = 0
                      content_language.forEach((cl) => {
                          if(cl.code === lang) {
  
                            v.content.childs.forEach((el) => {
                              if(el.id_content !== '') {
                                  reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${el.id_content}`)
                                      .then(res_2 => {
                                          if(res_2.data.length > 0) {
                                              if(res_2.data[0].info.language.length > 0) {
                                                  if(res_2.data[0].info.language[cl.id] !== undefined && res_2.data[0].info.language[cl.id] !== null) {
                                                      if(res_2.data[0].info.language[cl.id]['name'] !== '') {
                                                          el.title = res_2.data[0].info.language[cl.id]['name'];
  
                                                          x++
                                                          if(v.content.childs.length === x) {
                                                            setRefresh(refresh => refresh + 1)
                                                          }
                                                      }
                                                  }
                                              }
                                              else {
                                                  x++
                                                  if(v.content.childs.length === x) {
                                                    setRefresh(refresh => refresh + 1)
                                                  }
                                              } 
                                          }
                                          else {
                                              x++
                                              if(v.content.childs.length === x) {
                                                  setRefresh(refresh => refresh + 1)
                                              }
                                          } 
                                      })
                              }
                              else {
                                  if(el['language'] !== undefined && el['language'] !== null) {
                                      if(el.language[cl.id] !== undefined && el.language[cl.id]['title'] !== undefined && el.language[cl.id]['title'] !== '') { 
                                          el.title = el.language[cl.id]['title']
  
                                          x++
                                          if(v.content.childs.length === x) {
                                            setRefresh(refresh => refresh + 1)
                                          }
                                      }
                                  }
                              }
  
                            })
  
                          }
                      })
                        
                    }
                    
                    set_navbar(v);
                    setLoading(false)
                }
            });
  
        })
        .catch(erro => console.log('ERROR', erro))
    }

    //set_navbar(navbar = { id: '', info: '', content: { childs: [] } })

    getInfoNavbar()

    /*if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
      change_lang('pt')
    }
    else {
      set_lang(localStorage.getItem('lang'))
    }*/

  }, [lang])


  const page_location = useLocation().pathname.split('/').pop();

  return isloading ? 
      null
    :
      <section id="footer" className={props.pag_welcome ? 'mt-0': ''}>
        <footer className="footer">
          {/*<img alt='Snow' className="img-fluid d-none" src={repo_img_link("banner_snow_footer.svg")} style={{ zIndex: 20, position: 'absolute', marginTop: `-1px`}}/>*/}
          <div className="container">
            <div className="row">
              <div className='col-12 text-center'>
                <a href={repo_site_documents_link('CENTRO-04-38D7-FEDER-001180.pdf')} target="_blank" rel="noreferrer" alt="Centro 2020" title="Centro 2020" >
                  <img src={repo_site_assets_link("centro_2020_FEDER-branco.svg")} alt="Centro 2020" title="Centro 2020" className="img-fluid" />
                </a>
              </div>
            </div>
            <div className="row py-5">
              <div className="col-12 mb-2 d-none">
                <a href={Hosts.WEB_SITE_URI} title={Hosts.webSiteTitle} alt={Hosts.webSiteTitle} >
                  <img src={repo_logo_link("logo.svg")} width="180px" height="75px" alt={Hosts.webSiteTitle} className="img-fluid" />
                </a>
              </div>

              <div className="col-6 col-md-3 col-lg-2">
                <h5 className="my-2">{language[lang].site_map}</h5>
                {
                  navbar.content.childs.map((v, key) => {

                    //console.log(v)

                    if (v.id_content !== '') {
                      let link = (v.slug !== undefined && v.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.slug : Hosts.WEB_SITE_URI + 'pag/' + v.id_content);
                      let current_top_check = (v.slug !== undefined && v.slug !== '' ? v.slug : (v.id_content !== '' ? v.id_content : v.link));

                      /*if(v.id_content === '98') {
                        link = Hosts.WEB_SITE_URI + 'pag/99';
                        current_top_check = (v.slug !== undefined && v.slug !== '' ? v.slug : '99');
                      }*/

                      if(v.id_content === '98') {
                        if(v.children !== undefined && v.children !== '' && v.children.length > 0){

                          v.children.forEach((v2) => {
                            if(v2.id_content === '99'){
                              link = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + v2.slug : Hosts.WEB_SITE_URI + 'pag/' + v2.id_content);
                              current_top_check = (v2.slug !== undefined && v2.slug !== '' ? v2.slug : v2.id_content);
                            }
                          })
                        }
                      }

                      return (
                            <a key={key} href={link} className={"text-white " + (page_location === current_top_check ? 'current_bottom' : '')}>
                                <h6 className="mb-2">
                                  {v.title}
                                </h6>
                            </a>
                        )
                    }
                    else {
                      let aux_title = v.title.toLowerCase()
                        return (

                            v.link.substr(0, 4) !== 'http' ?
                              <a key={key} href={Hosts.WEB_SITE_URI + v.link} className={"text-white " + (page_location === v.link ? 'current_bottom' : (page_location === aux_title ? 'current_bottom' : ''))}>
                                  <h6 className="mb-2">
                                    {v.title}
                                  </h6>
                              </a>
                            :
                              <a key={key} href={v.link} className={"text-white " + (page_location === v.link ? 'current_bottom' : (page_location === aux_title ? 'current_bottom' : ''))} target="_blank" rel="noreferrer">
                                  <h6 className="mb-2">
                                    {v.title}
                                  </h6>
                              </a>
                        )
                    }
                  })
                }

              </div>

              <div className="col-6 col-md-3 col-lg-2">
                <h5 className=" my-2">{language[lang].information}</h5>
                <a className="text-white" href="https://www.turistrela.pt/" alt={language[lang].turistrela_group} title={language[lang].turistrela_group} target="_blank" rel="noreferrer">
                  <h6 className="mb-2">{language[lang].turistrela_group}</h6>
                </a>
                <a className="text-white d-none" href="https://www.turistrela.pt/onde-comer" alt={language[lang].turistrela_eat} title={language[lang].turistrela_eat} target="_blank" rel="noreferrer">
                  <h6 className="mb-2">{language[lang].turistrela_eat}</h6>
                </a>
                <a className="text-white" href="https://www.turistrela.pt/onde-dormir" alt={language[lang].turistrela_sleep} title={language[lang].turistrela_sleep} target="_blank" rel="noreferrer">
                  <h6 className="mb-2">{language[lang].turistrela_sleep}</h6>
                </a>
                <a className="text-white" href="https://www.meteoestrela.pt/previsoes/neve/" alt={language[lang].meteorology} title={language[lang].meteorology} target="_blank" rel="noreferrer">
                  <h6 className="mb-2">{language[lang].meteorology}</h6>
                </a>
                <a className={"text-white " + (page_location ===  'livecam' ? 'current_bottom': '')} href={Hosts.WEB_SITE_URI + "livecam"}>
                  <h6 className="mb-2">{language[lang].livecam}</h6>
                </a>
                <a className={"text-white " + (page_location ===  'accesses' ? 'current_bottom': '')} href={Hosts.WEB_SITE_URI + "accesses"}>
                  <h6 className="mb-2">{language[lang].accesses}</h6>
                </a>
                {/*<a className={"text-white " + (page_location ===  'chucb' ? 'current_bottom': '')} href={Hosts.WEB_SITE_URI + "chucb"}>
                  <h6 className="mb-2">CHUCB</h6>
                </a>
                <a className={"text-white " + (page_location ===  'scientia_magazine' ? 'current_bottom': '')} href={Hosts.WEB_SITE_URI + "scientia_magazine"}>
                  <h6 className="mb-2">{language[lang].scientia_magazine}</h6>
                </a>*/}
              </div>

              <div className="col-6 col-md-3 col-lg-3">
                <h5 className=" my-2">{language[lang].legal_information}</h5>
                <a className={"text-white d-none " + (page_location === 'terms-conditions' ? 'current_bottom' : '')} href={Hosts.WEB_SITE_URI + "terms-conditions"}>
                  <h6 className="mb-2">{language[lang].terms_conditions}</h6>
                </a>
                <a className={"text-white " + (page_location === 'terms-conditions' ? 'current_bottom' : '')} href={"https://cdn.assec.app/repo/turistrela/estanciaski/upload/content/termos-condicoes-estancia-ski-serra-da-estrela.pdf"} target="_blank">
                  <h6 className="mb-2">{language[lang].terms_conditions}</h6>
                </a>

                <a className={"text-white d-none " + (page_location === 'marcacao-e-utilizacao-de-aulas' ? 'current_bottom' : '')} href={Hosts.WEB_SITE_URI + "marcacao-e-utilizacao-de-aulas"}>
                  <h6 className="mb-2">{language[lang].regulations}</h6>
                </a>
                <a className={"text-white " + (page_location === 'marcacao-e-utilizacao-de-aulas' ? 'current_bottom' : '')} href={"https://cdn.assec.app/repo/turistrela/estanciaski/upload/content/condicoes-gerais-de-marcacao-e-utilizacao-de-aulas.pdf"} target="_blank">
                  <h6 className="mb-2">{language[lang].regulations}</h6>
                </a>

                <a className={"text-white d-none " + (page_location === 'devolucao-e-reembolso' ? 'current_bottom' : '')} href={Hosts.WEB_SITE_URI + "devolucao-e-reembolso"}>
                  <h6 className="mb-2">{language[lang].return_policy}</h6>
                </a>
                <a className={"text-white " + (page_location === 'devolucao-e-reembolso' ? 'current_bottom' : '')} href={"https://cdn.assec.app/repo/turistrela/estanciaski/upload/content/politica-de-devolucao-e-reembolso.pdf"} target="_blank">
                  <h6 className="mb-2">{language[lang].return_policy}</h6>
                </a>

                <a className="text-white" href="https://www.livroreclamacoes.pt/" alt={language[lang].complaint_book} title={language[lang].complaint_book} target="_blank" rel="noreferrer">
                  <h6 className="mb-2">{language[lang].complaint_book}</h6>
                </a>
                {/*<a className={"text-white " + (page_location ===  'chucb' ? 'current_bottom': '')} href={Hosts.WEB_SITE_URI + "chucb"}>
                  <h6 className="mb-2">CHUCB</h6>
                </a>
                <a className={"text-white " + (page_location ===  'scientia_magazine' ? 'current_bottom': '')} href={Hosts.WEB_SITE_URI + "scientia_magazine"}>
                  <h6 className="mb-2">{language[lang].scientia_magazine}</h6>
                </a>*/}
              </div>

              <div className="col-6 col-md-3 col-lg-2 social-media">
                <h5 className=" my-2">{language[lang].social_media}</h5>
                  <a href={Hosts.fbLink} title="Facebook" alt="Facebook"  target="_blank" rel="noreferrer" >
                    <img src={repo_site_assets_link("facebook.svg")} width="25px" height="25px" alt="Facebook" className="img-fluid mr-2" />
                  </a>
                  <a href={Hosts.instaLink} title="Instagram" alt="Instagram"  target="_blank" rel="noreferrer" >
                    <img src={repo_site_assets_link("instagram.svg")} width="25px" height="25px" alt="Instagram" className="img-fluid" />
                  </a>
              </div>
              <div className="col-12 col-lg-3">
                <h5 className=" my-2">{language[lang].download_app}</h5>
                <a href="https://play.google.com/store/apps/details?id=com.skiserradaestrela.skiserradaestrelaapp" title="App Google Play" alt="App Google Play" target="_blank" rel="noreferrer" >
                  <img src={repo_site_assets_link("googleplay.svg")} title="App Google Play" alt="App Google Play" className="img-fluid mb-2 mr-2" />
                </a>
                <a href=" https://apps.apple.com/us/app/ski-serra-da-estrela/id6466815914" title="App Store" alt="App Store" target="_blank" rel="noreferrer" >
                  <img src={repo_site_assets_link("applestore.svg")} title="App Store" alt="App Store"className="img-fluid mb-2" />
                </a>

                  <h5 className="mt-4">{language[lang].payment_methods}</h5>
                  <img src={repo_site_assets_link("cc-mbs-reta-white.png")} title="Easypay" alt="Easypay" className="img-fluid" />
                </div>
            </div>
          </div>
          <div className="col-12 text-center py-3 justify-content-center text-white">
            <ul className="list-inline mb-0 copyright">
              <li className="list-inline-item p-0">
                <span className="">
                  <i className="far fa-copyright pr-1"></i> 2022 {Hosts.webSiteTitle}
                </span>
              </li>
              <li className="list-inline-item p-0">
                <span className="px-2">&#124;</span>
              </li>
              <li className="list-inline-item p-0">
                <span>{language[lang].developed}</span>
              </li>
              <li className="list-inline-item p-0">
                <span>
                  <a href="http://sim.assec.pt" title="Assec Sim!" target="_blank" rel="noreferrer">
                    {/*<img className="img-fluid" src="./img/logo.png" alt="Assec Sim!" style={logoAssec}/>*/}
                    Assec Sim!
                  </a>
                </span>
              </li>
            </ul>
          </div>
        </footer>
      </section>
  

};
export default Footer;