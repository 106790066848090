import React, { useState, useEffect, useRef } from 'react'
import { useParams, useLocation } from "react-router-dom";

import * as Hosts from '../../../Hosts';
import { reqGET, set_language, content_language } from '../../../Utils';
import { language } from '../WEBContent/Language';
import { getTokenInfo } from '../../../UseToken';

import Loader from '../../../loader';

import './Breadcrumb.css';

export default function Breadcrumb() {
    const { pathname } = useLocation();

    const { pag_id, slug, article_id, gallery_id, preview_id } = useParams();
    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState('pt');
    const [lang_id, set_lang_id] = useState('1');

    //const page_location = useLocation().pathname.split('/').pop();

    const page_location_url = useLocation().pathname.split('/')[1];

    const change_lang = (l) => {
      set_language(l)
      set_lang(l)
  
      //console.log('language', localStorage.getItem('lang'))
      //console.log('localstorage', localStorage)
    }
    //const [content, set_content] = useState(data_interface);
    const [menu, set_menu] = useState([]);

    const [, set_dados] = useState(null);
	const content = useRef(null);

    useEffect(() => {

        const data_interface = {
            id: '',
            info: {},
            content: {}
        }

        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            change_lang('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }

        let getPreviewInfoId = async (id) => {
            return await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${id}`)
                .then(res => { 
                    if(res.data.length > 0) {

                        if(getTokenInfo()['profile'] !== '') {
                            res.data[0].info['preview'] = true
                            //set_content(res.data[0])
                            //setLoading(false)
                            set_dados(content.current = res.data[0])

                            return true
                        }
                        else {
                        }
                    }
                })
                .catch(erro => console.log('ERROR', erro))
        }

        let getInfoId = async (id) => {
            return await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${id}`)
                .then(res => { 
                    if(res.data.length > 0) {

                        var hoje = new Date();
                        let status = 0
                        if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                            var date_p = new Date(res.data[0].info.date.published);

                            status = 1
                            if(hoje >= date_p) 
                                status = 2
                        }

                        if(status !== 2) {
                            //res.data[0].content[0] = {text: 'rascunho ou agendado'}
                            res.data[0].info.layout = '404'
                        }

                        //set_content(res.data[0])
                        set_dados(content.current = res.data[0])
                        //setLoading(false)
                        return true
                    }
                })
                .catch(erro => console.log('ERROR', erro))
        }
    
        let getInfoSlug = async (slug) => {
            return await reqGET(`${Hosts.SIMCore_API}/web/content/slug/${Hosts.companycode}/${slug}`)
                .then(res => { 
                    if(res.data.length > 0) {

                        var hoje = new Date();
                        let status = 0
                        if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                            var date_p = new Date(res.data[0].info.date.published);

                            status = 1
                            if(hoje >= date_p) 
                                status = 2
                        }

                        if(status !== 2) {
                            //res.data[0].content[0] = {text: 'rascunho ou agendado'}
                            res.data[0].info.layout = '404'
                        }
                        //set_content(res.data[0])
                       //setLoading(false)
                       set_dados(content.current = res.data[0])

                        return true
                    }
                    else {
                        //console.log('slug: ', slug)
                        //set_content({id: '', info: {type: slug}})
                        //setLoading(false)
                        set_dados(content.current = {id: '', info: {type: slug}})
                        return true
                    }
                })
                .catch(erro => console.log('ERROR', erro))
        }

        let getAll = async (type) => {
            let aux = data_interface
            aux.info['type'] = type
            aux.info['layout'] = 'all'
            
            //set_content(content)
            set_dados(content.current = aux)

            return true
        }

        let getMenu = async () => {
            //setLoading(true)
        
            return await reqGET(`${Hosts.SIMCore_API}/web/structure/${Hosts.companycode}/1`)
              .then(res => {
                set_menu(res.data[0])
                //console.log('MENU', res.data)
        
            })
            .catch(erro => alert('Erro'))
        }
        getMenu()
            /*.then(() => {
                setLoading(false)
            })*/

        if(preview_id !== undefined && preview_id !== 'undefined' && preview_id !== '') {
            getPreviewInfoId(preview_id)
                .then((res) => {
                    if(res === true){
                        setLoading(false)
                    }
                })
        }
        else if(pag_id !== undefined && pag_id !== 'undefined' && pag_id !== '') {
            getInfoId(pag_id)
                .then((res) => {
                    if(res === true){
                        setLoading(false)
                    }
                })
        }
        else if(slug !== undefined && slug !== 'undefined' && slug !== '')  {
            getInfoSlug(slug)
                .then((res) => {
                    if(res === true){
                        setLoading(false)
                    }
                })
        }
        else if(article_id !== undefined && article_id !== 'undefined' && article_id !== '')  {
            
            if(article_id === 'all') {
                getAll('news')
                    .then((res) => {
                        if(res === true){
                            setLoading(false)
                        }
                    })
            }
            else {
                getInfoId(article_id)
                    .then((res) => {
                        if(res === true){
                            setLoading(false)
                        }
                    })
            }
        }
        else if(gallery_id !== undefined && gallery_id !== 'undefined' && gallery_id !== '')  {
            if(gallery_id === 'all') {
                getAll('gallery')
                    .then((res) => {
                        if(res === true){
                            setLoading(false)
                        }
                    })
            }
            else {
                getInfoId(gallery_id)
                    .then(() => {
                        setLoading(false)
                    })
            }
        }
        else if(content.current === null && page_location_url === 'store')  {

            //console.log(slug)
            //console.log(pag_id)
            //console.log('CONTENT', content)
            //console.log(page_location_url)
            
            // console.log('11111', page_location_url)

                
            setLoading(false)
                   
        }
        else if(content.current === null && page_location_url === 'regist')  {

            //console.log(slug)
            //console.log(pag_id)
            //console.log('CONTENT', content)
            //console.log(page_location_url)
            console.log('asdasda', page_location_url)
                
            setLoading(false)
                   
        }
        
    }, [pag_id, slug, article_id, gallery_id, preview_id, page_location_url]);

    return (isloading ?

		<Loader />

		:
            <>

                <nav aria-label="breadcrumb">
                    
                    <ol className="breadcrumb mb-0 px-0">
                        <div className="container">
                            <div className="row">
                                {/*console.log('pathname', pathname)*/}
                                {/*console.log('CONTENT', content)*/}
                                {/*console.log('MENU.CONTENT', menu.content)*/}
                                <div className='col-12 d-flex'>
                                <li className="breadcrumb-item">
                                    <a className="text-nowrap" href={Hosts.WEB_SITE_URI}>Home</a>
                                </li>
                                {
                                    content.current === null && page_location_url === 'store' ?

                                    
                                        pathname === '/store/tarifas' ? 
                                            <li className="breadcrumb-item active text-truncate" aria-current="page">{language[lang]['store_tarifas']}</li>
                                        : 
                                        
                                        pathname === '/agendar/aula' ? 
                                            <li className="breadcrumb-item active text-truncate" aria-current="page">{language[lang]['agendar_aula']}</li>
                                        : 
                                            
                                        pathname === '/store/forfaits' ? 
                                            <>
                                                <li className="breadcrumb-item active text-truncate" aria-current="page">
                                                    <a href={Hosts.WEB_SITE_URI + "store/tarifas"}>{language[lang]['store_tarifas']}</a>
                                                </li>
                                                <li className="breadcrumb-item active text-truncate" aria-current="page">{language[lang]['store_forfaits']}</li>
                                            </>
                                        : 
                                            
                                        pathname === '/store/equipments' ? 
                                            <>
                                                <li className="breadcrumb-item active text-truncate" aria-current="page">
                                                    <a href={Hosts.WEB_SITE_URI + "store/tarifas"}>{language[lang]['store_tarifas']}</a>
                                                </li>
                                                <li className="breadcrumb-item active text-truncate" aria-current="page">{language[lang]['store_equipamentos']}</li>
                                            </>
                                        : 

                                        pathname === '/store/activities' ? 
                                            <>
                                                <li className="breadcrumb-item active text-truncate" aria-current="page">
                                                    <a href={Hosts.WEB_SITE_URI + "store/tarifas"}>{language[lang]['store_tarifas']}</a>
                                                </li>
                                                <li className="breadcrumb-item active text-truncate" aria-current="page">{language[lang]['store_atividades']}</li>
                                            </>
                                        : 
                                        pathname === '/store/packs' ? 
                                            <>
                                                <li className="breadcrumb-item active text-truncate" aria-current="page">
                                                    <a href={Hosts.WEB_SITE_URI + "store/tarifas"}>{language[lang]['store_tarifas']}</a>
                                                </li>
                                                <li className="breadcrumb-item active text-truncate" aria-current="page">{language[lang]['store_packs']}</li>
                                            </>
                                        : 
                                        pathname === '/store/vouchers' ? 
                                            <>
                                                <li className="breadcrumb-item active text-truncate" aria-current="page">
                                                    <a href={Hosts.WEB_SITE_URI + "store/tarifas"}>{language[lang]['store_tarifas']}</a>
                                                </li>
                                                <li className="breadcrumb-item active text-truncate" aria-current="page">{language[lang]['store_vouchers']}</li>
                                            </>
                                        : 
                                        pathname === '/store/cart' ? 
                                            <>
                                                <li className="breadcrumb-item active text-truncate" aria-current="page">
                                                    <a href={Hosts.WEB_SITE_URI + "store/tarifas"}>{language[lang]['store_tarifas']}</a>
                                                </li>
                                                <li className="breadcrumb-item active text-truncate" aria-current="page">{language[lang]['store_cart']}</li>
                                            </>
                                        : 
                                        pathname === '/store/aulas' ? 
                                            <>
                                                <li className="breadcrumb-item active text-truncate" aria-current="page">
                                                    <a href={Hosts.WEB_SITE_URI + "store/tarifas"}>{language[lang]['store_tarifas']}</a>
                                                </li>
                                                <li className="breadcrumb-item active text-truncate" aria-current="page">{language[lang]['store_aulas']}</li>
                                            </>
                                        : 
                                            ''
                                            
                                        

                                    :

                                    content.current === null && page_location_url === 'regist' ?

                                    
                                        pathname === '/regist/client' ? 
                                            <li className="breadcrumb-item active text-truncate" aria-current="page">{language[lang]['regist']}</li>
                                        : 
                                            ''
                                    :

                                    content.current.info.type === 'news' ? 
                                        content.current.id !== '' ?
                                            <>
                                                <li className="breadcrumb-item text-truncate"><a href={Hosts.WEB_SITE_URI + 'article/all'}>{language[lang].news_turistrela}</a></li>
                                                <li className="breadcrumb-item active text-truncate" aria-current="page">
                                                    {lang === 'pt' || content.current.content[0].language === undefined ? content.current.content[0].title : (content.current.content[0].language[lang_id] === undefined || content.current.content[0].language[lang_id] === null || content.current.content[0].language[lang_id].title === undefined || content.current.content[0].language[lang_id].title === '' ? content.current.content[0].title : content.current.content[0].language[lang_id].title)}
                                                </li>
                                            </>
                                        : 
                                            <li className="breadcrumb-item active text-truncate" aria-current="page">{language[lang].news_turistrela}</li>
                                    :
                                    content.current.info.type === 'gallery' ? 
                                        content.current.id !== '' ?
                                            <>
                                                <li className="breadcrumb-item text-truncate"><a href={Hosts.WEB_SITE_URI + 'gallery/all'}>{language[lang].galleries}</a></li>
                                                <li className="breadcrumb-item active text-truncate" aria-current="page">
                                                    {lang === 'pt' || content.current.content[0].language === undefined ? content.current.content[0].title : (content.current.content[0].language[lang_id] === undefined || content.current.content[0].language[lang_id] === null || content.current.content[0].language[lang_id].title === undefined || content.current.content[0].language[lang_id].title === '' ? content.current.content[0].title : content.current.content[0].language[lang_id].title)}
                                                </li>
                                            </>
                                        : 
                                            <li className="breadcrumb-item active text-truncate" aria-current="page">{language[lang].galleries}</li>
                                    :
                                        <>
                                            {
                                            content.current.info.type !== '' && menu.content !== undefined ?
                                                menu.content.childs.map((v, key) => {
                                                    
                                                    return(
                                                        v.id_content === content.current.info.parent ?
                                                            <li key={key} className="breadcrumb-item active text-truncate" aria-current="page">
                                                                {lang === 'pt' || v.language === undefined ? v.title : (v.language[lang_id] === undefined || v.language[lang_id].title === undefined || v.language[lang_id].title === '' ? v.title : v.language[lang_id].title)}
                                                            </li>
                                                        :
                                                        v.link === content.current.info.type ?
                                                            <li key={key} className="breadcrumb-item active text-truncate" aria-current="page">
                                                                {/*v.title*/}
                                                                {lang === 'pt' || v.language === undefined ? v.title : (v.language[lang_id] === undefined || v.language[lang_id].title === undefined || v.language[lang_id].title === '' ? v.title : v.language[lang_id].title)}
                                                            </li>
                                                        :
                                                            ''
                                                    )                       
                                                    
                                                })
                                            : 
                                            ''
                                            }
                                            {
                                            content.current.info.type !== '' && content.current.info.name !== undefined ? 
                                                <li className="breadcrumb-item active text-truncate" aria-current="page">
                                                    {/*content.info.name*/}
                                                    {lang === 'pt' || content.current.info.language === undefined ? content.current.info.name : (content.current.info.language[lang_id] === undefined || content.current.info.language[lang_id].name === undefined || content.current.info.language[lang_id].name === '' ? content.current.info.name : content.current.info.language[lang_id].name)}
                                                </li> 
                                            : 
                                                ''
                                            } 

                                            {
                                            content.current.info.type === 'accesses' || content.current.info.type === 'livecam' ? 
                                                <li className="breadcrumb-item active text-truncate" aria-current="page">{language[lang][content.current.info.type]}</li>
                                            : 
                                                ''
                                            }

                                            {
                                            pathname === '/agendar/aula' ? 
                                                <>
                                                    <li className="breadcrumb-item active text-truncate" aria-current="page">
                                                        <a href={Hosts.WEB_SITE_URI + "store/tarifas"}>{language[lang]['store_tarifas']}</a>
                                                    </li>
                                                    <li className="breadcrumb-item active text-truncate" aria-current="page">{language[lang]['agendar_aula']}</li>
                                                </>
                                            : 
                                                ''
                                            }

                                            {
                                            pathname === '/store/forfaits' ? 
                                                <>
                                                    <li className="breadcrumb-item active text-truncate" aria-current="page">
                                                        <a href={Hosts.WEB_SITE_URI + "store/tarifas"}>{language[lang]['store_tarifas']}</a>
                                                    </li>
                                                    <li className="breadcrumb-item active text-truncate" aria-current="page">{language[lang]['store_forfaits']}</li>
                                                </>
                                            : 
                                                ''
                                            }

                                            {
                                            pathname === '/store/equipments' ? 
                                                <>
                                                    <li className="breadcrumb-item active text-truncate" aria-current="page">
                                                        <a href={Hosts.WEB_SITE_URI + "store/tarifas"}>{language[lang]['store_tarifas']}</a>
                                                    </li>
                                                    <li className="breadcrumb-item active text-truncate" aria-current="page">{language[lang]['store_equipamentos']}</li>
                                                </>
                                            : 
                                                ''
                                            }

                                            {
                                            pathname === '/store/activities' ? 
                                                <>
                                                    <li className="breadcrumb-item active text-truncate" aria-current="page">
                                                        <a href={Hosts.WEB_SITE_URI + "store/tarifas"}>{language[lang]['store_tarifas']}</a>
                                                    </li>
                                                    <li className="breadcrumb-item active text-truncate" aria-current="page">{language[lang]['store_atividades']}</li>
                                                </>
                                            : 
                                                ''
                                            }

                                            {
                                            pathname === '/store/packs' ? 
                                                <>
                                                    <li className="breadcrumb-item active text-truncate" aria-current="page">
                                                        <a href={Hosts.WEB_SITE_URI + "store/tarifas"}>{language[lang]['store_tarifas']}</a>
                                                    </li>
                                                    <li className="breadcrumb-item active text-truncate" aria-current="page">{language[lang]['store_packs']}</li>
                                                </>
                                            : 
                                                ''
                                            }

                                            {
                                            pathname === '/store/vouchers' ? 
                                                <>
                                                    <li className="breadcrumb-item active text-truncate" aria-current="page">
                                                        <a href={Hosts.WEB_SITE_URI + "store/tarifas"}>{language[lang]['store_tarifas']}</a>
                                                    </li>
                                                    <li className="breadcrumb-item active text-truncate" aria-current="page">{language[lang]['store_vouchers']}</li>
                                                </>
                                            : 
                                                ''
                                            }

                                            {
                                            pathname === '/store/cart' ? 
                                                <>
                                                    <li className="breadcrumb-item active text-truncate" aria-current="page">
                                                        <a href={Hosts.WEB_SITE_URI + "store/tarifas"}>{language[lang]['store_tarifas']}</a>
                                                    </li>
                                                    <li className="breadcrumb-item active text-truncate" aria-current="page">{language[lang]['store_cart']}</li>
                                                </>
                                            : 
                                                ''
                                            }

                                        </>
                                }
                                </div>
                                
                            </div>
                        </div>
                    </ol>
                    
                </nav>
            </>
    
    )
}
