import React, { useState, useEffect } from 'react';

//import parse from 'html-react-parser';

import ReactHtmlParser from 'react-html-parser';

import "../Pagina.css";
import "../content-styles.css"

import * as Hosts from '../../../Hosts';
import {
    set_language,
	reqGET,
    content_language,
    repo_site_assets_link,

} from '../../../Utils';

import './mapaPistas.css';

import Navbar from "../Navbar/Navbar";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Footer from "../Footer/Footer";

import Mapa from "./Mapa";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { /*faChevronLeft, faFileDownload, faFilePdf,*/ faCircle, faCheck, faBan} from "@fortawesome/free-solid-svg-icons";

import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';


import { language } from '../WEBContent/Language';

const MapaPistas = () => {

    Fancybox.bind('[data-fancybox]', {
        // Your custom options
      });  
    
    const [lang, set_lang] = useState('pt');
    const [lang_id, set_lang_id] = useState('1');

    const data_interface = {
        id: '',
        info: {
            companycode: '',
            name: '',
            description: '',
            text: '',
            pistas: [],
            meios: [],
            meteo: {
                min: '',
                max: '',
                snow: '',
            },
            date: '',
            language: []
        }
    }

    const [dataPistasBalizadas, setDataPistasBalizadas] = useState([]);   
    const [dataPistasNaturais, setDataPistasNaturais] = useState([])
    const [dataSnowParks, setDataSnowParks] = useState([])
    const [dataMeiosMecanicos, setDataMeiosMecanicos] = useState([])
    const [dataPistasState, setDataPistasState] = useState([]);
    //const [dataDescription, setDataDescription] = useState('');
    const [dataDescription, setDataDescription] = useState(data_interface);

    const [dataLatestUpdateDate, setDataLatestUpdateDate] = useState([]);
    const [dataLatestUpdateHour, setDataLatestUpdateHour] = useState([]);
    const [dataMeiosMecanicosState, setDataMeiosMecanicosState] = useState([])

    let getInfoPistasState = async () => {

        reqGET(`${Hosts.SIMCore_API}/estancia/config/${Hosts.companycode}/1`)
            .then(res => { 
                setDataPistasState(res.data[0].info.pistas)
                setDataMeiosMecanicosState(res.data[0].info.meios)
                //setDataDescription(res.data[0].info.description)
                setDataDescription(res.data[0].info)
                setDataLatestUpdateDate(res.data[0].info.date.split('T')[0])
                setDataLatestUpdateHour(res.data[0].info.date.split('T')[1].split('.')[0].slice(0, -3))
            })
        .catch(erro => console.log(erro))  


    }

    let getInfoPistasBalizadas = async () => {

        //reqGET(`${Hosts.SIMCore_API}/estancia/pista/type/${Hosts.companycode}/pista_balizada`)
        reqGET(`${Hosts.SIMCore_API}/estancia/pista/type/order/${Hosts.companycode}/pista_balizada`)
            .then(res => { 
                setDataPistasBalizadas(res.data)
                //console.log('PISTA BALIZADA: ', res.data)

            })
            .catch(erro => console.log(erro))  

    }

    let getInfoPistasNaturais = async () => {

        //reqGET(`${Hosts.SIMCore_API}/estancia/pista/type/${Hosts.companycode}/pista_natural`)
        reqGET(`${Hosts.SIMCore_API}/estancia/pista/type/order/${Hosts.companycode}/pista_natural`)
            .then(res => { 
                setDataPistasNaturais(res.data)
                //console.log(res.data)

            })
            .catch(erro => console.log(erro))  

    }

    let getInfoSnowParks = async () => {

        //reqGET(`${Hosts.SIMCore_API}/estancia/pista/type/${Hosts.companycode}/snowpark`)
        reqGET(`${Hosts.SIMCore_API}/estancia/pista/type/order/${Hosts.companycode}/snowpark`)
            .then(res => { 
                setDataSnowParks(res.data)
                //console.log(res.data) 

            })
            .catch(erro => console.log(erro))  

    }

    let getInfoMeiosMecanicos = async () => {

        reqGET(`${Hosts.SIMCore_API}/estancia/meio/${Hosts.companycode}`)
            .then(res => { 
                setDataMeiosMecanicos(res.data)
                //console.log(res.data)

            })
            .catch(erro => console.log(erro))  

    }
    
    useEffect(() => { 

        getInfoPistasBalizadas()
        getInfoPistasNaturais()
        getInfoSnowParks()
        getInfoMeiosMecanicos()
        getInfoPistasState()

        if(localStorage.getItem('lang') === undefined  || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if(el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }
    
    }, []); 

  

    return (
        <>
            <Navbar /> 

            <Breadcrumb /> 

            <div id="tracks" className="container-fluid my-5">
                <div className="row">
                    <div className="container mb-5">
                        <h3 className="titulo-primario">{language[lang].track_map}</h3>
                    </div>
                    <div className='container d-block d-md-none'>
                        <a 
                            href={repo_site_assets_link("mapa.svg")}
                            data-fancybox="gallery"
                            data-caption={language[lang].track_map}
                        >
                            <img src={repo_site_assets_link("mapa.svg")} alt={language[lang].track_map} title={language[lang].track_map} className="img-fluid" />
                        </a>
                    </div>

                    <Mapa />
                    
                    <div className="container">
                        <div className="row">
                            <div className="col-12 my-4">
                                <h3 className="titulo-secundario">{language[lang].labels}</h3>
                                <hr></hr>
                            </div>
                            <div className="col-12">
                                <ul className="list-inline">
                                    <li className="list-inline-item font-weight-bold">
                                        {language[lang].difficulty}&#58;&nbsp;
                                    </li>
                                    <li className="list-inline-item">
                                        <FontAwesomeIcon className="mr-2 pista-muito_facil" icon={faCircle} fixedWidth />
                                        {language[lang].muito_facil}   
                                    </li>
                                    <li className="list-inline-item">
                                        <FontAwesomeIcon className="mr-2 pista-facil" icon={faCircle} fixedWidth />
                                        {language[lang].facil}  
                                    </li>
                                    <li className="list-inline-item">
                                        <FontAwesomeIcon className="mr-2 pista-dificil" icon={faCircle} fixedWidth />
                                        {language[lang].dificil}  
                                    </li>
                                    <li className="list-inline-item">
                                        <FontAwesomeIcon className="mr-2 pista-muito_dificil" icon={faCircle} fixedWidth />
                                        {language[lang].muito_dificil}  
                                    </li>
                                    <li className="list-inline-item">
                                        <FontAwesomeIcon className="mr-2 pista-not_applicable" icon={faCircle} fixedWidth />
                                        {language[lang].not_applicable}  
                                    </li>
                                    <li className="list-inline-item font-weight-bold ml-3">
                                        {language[lang].state}&#58;&nbsp;
                                    </li>
                                    <li className="list-inline-item">
                                        <FontAwesomeIcon className="mr-2" color="green" icon={faCheck} fixedWidth />
                                        {language[lang].open}  
                                    </li>
                                    <li className="list-inline-item">
                                        <FontAwesomeIcon className="mr-2" color="red" icon={faBan} fixedWidth />
                                        {language[lang].closed}  
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row" id="marked_tracks">
                            <div className="col-12 my-4">
                                <h3 className="titulo-secundario p-4 bg-color-gray-light">{language[lang].marked_tracks}</h3>
                            </div>
                            <div className="col-12">
                                <div className="row no-gutters d-none">
                                    {
                                        /* pistas com ordenação normal nas colunas boostrap (esquerda, direita, etc) */
                                        dataPistasBalizadas.map((v, key) => {

                                            //let comprimento = v.comprimento + ' m'
                                            //let desnivel = v.desnivel + ' m'

                                            let lang_aux = JSON.parse(v.language)
                                            
                                            return (

                                                <div className={"col-12 col-md-6"} key={key}>
                                                    <div className="card px-2 py-3">
                                                        <div className="dificulty">
                                                            <FontAwesomeIcon className={"pista-" + v.dificuldade} alt={language[lang][v.dificuldade]} title={language[lang][v.dificuldade]} icon={faCircle} fixedWidth />
                                                        </div>
                                                        <div className="info">
                                                            <h5 className="subtitulo-primario">
                                                                {/*v.name*/}
                                                                {lang === 'pt' || lang_aux === undefined ? v.name : (lang_aux[lang_id] === undefined || lang_aux[lang_id].name === undefined || lang_aux[lang_id].name === '' ? v.name : lang_aux[lang_id].name)}
                                                                {v.canhoes === 'true' ? '*': ''}
                                                            </h5>
                                                            <span className="mr-3">{language[lang].length}&#58;&nbsp;{v.comprimento}&nbsp;&#109;</span>
                                                            <span className="mr-3">{language[lang].unevenness}&#58;&nbsp;{v.desnivel}&nbsp;&#109;</span>
                                                            <span>{language[lang].difficulty}&#58;&nbsp;{language[lang][v.dificuldade]}</span>
                                                        </div>
                                                        <div className="state mr-4">
                                                            {
                                                                dataPistasState.filter(v2 => v2.id === v.id).map((v3, key3) => {

                                                                    let color = '';
                                                                    let icon = '';
                                                                    let title = '';

                                                                    if(v3.state === false){
                                                                        color = 'red';
                                                                        icon = faBan;
                                                                        title = language[lang].closed;
                                                                    }
                                                                    if(v3.state === true){

                                                                        color = 'green';
                                                                        icon = faCheck;
                                                                        title = language[lang].open;

                                                                    }

                                                                    return (

                                                                        <FontAwesomeIcon key={key3} className="mr-2" size="lg" color={color} icon={icon} alt={title} title={title} fixedWidth />

                                                                    );

                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                            );

                                        })
                                    }
                                </div>
                                <div className="row no-gutters">
                                    <div className='col-12 col-md-6'>
                                        {
                                            dataPistasBalizadas.map((v, key) => {

                                                //let comprimento = v.comprimento + ' m'
                                                //let desnivel = v.desnivel + ' m'

                                                let lang_aux = JSON.parse(v.language)
                                                let x = Math.round(dataPistasBalizadas.length/2)

                                                if(x > key) { 
                                                
                                                    return (

                                                        <div className={"pista"} key={key}>
                                                            <div className="card px-2 py-3">
                                                                <div className="dificulty">
                                                                    <FontAwesomeIcon className={"pista-" + v.dificuldade} alt={language[lang][v.dificuldade]} title={language[lang][v.dificuldade]} icon={faCircle} fixedWidth />
                                                                </div>
                                                                <div className="info">
                                                                    <h5 className="subtitulo-primario">
                                                                        {lang === 'pt' || lang_aux === undefined ? v.name : (lang_aux[lang_id] === undefined || lang_aux[lang_id].name === undefined || lang_aux[lang_id].name === '' ? v.name : lang_aux[lang_id].name)}
                                                                        {v.canhoes === 'true' ? '*': ''}
                                                                    </h5>
                                                                    <span className="mr-3">{language[lang].length}&#58;&nbsp;{v.comprimento}&nbsp;&#109;</span>
                                                                    <span className="mr-3">{language[lang].unevenness}&#58;&nbsp;{v.desnivel}&nbsp;&#109;</span>
                                                                    <span>{language[lang].difficulty}&#58;&nbsp;{language[lang][v.dificuldade]}</span>
                                                                </div>
                                                                <div className="state mr-4">
                                                                    {
                                                                        dataPistasState.filter(v2 => v2.id === v.id).map((v3, key3) => {

                                                                            let color = '';
                                                                            let icon = '';
                                                                            let title = '';

                                                                            if(v3.state === false){
                                                                                color = 'red';
                                                                                icon = faBan;
                                                                                title = language[lang].closed;
                                                                            }
                                                                            if(v3.state === true){

                                                                                color = 'green';
                                                                                icon = faCheck;
                                                                                title = language[lang].open;

                                                                            }

                                                                            return (

                                                                                <FontAwesomeIcon key={key3} className="mr-2" size="lg" color={color} icon={icon} alt={title} title={title} fixedWidth />

                                                                            );

                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>

                                                    );
                                                }
                                                else {
                                                    return null
                                                }

                                            })
                                        }
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        {
                                            dataPistasBalizadas.map((v, key) => {

                                                //let comprimento = v.comprimento + ' m'
                                                //let desnivel = v.desnivel + ' m'

                                                let lang_aux = JSON.parse(v.language)
                                                let x = Math.round(dataPistasBalizadas.length/2)

                                                if(x > key) { 
                                                
                                                    return null
                                                }
                                                else {
                                                    return (

                                                        <div className={"pista"} key={key}>
                                                            <div className="card px-2 py-3">
                                                                <div className="dificulty">
                                                                    <FontAwesomeIcon className={"pista-" + v.dificuldade} alt={language[lang][v.dificuldade]} title={language[lang][v.dificuldade]} icon={faCircle} fixedWidth />
                                                                </div>
                                                                <div className="info">
                                                                    <h5 className="subtitulo-primario">
                                                                        {lang === 'pt' || lang_aux === undefined ? v.name : (lang_aux[lang_id] === undefined || lang_aux[lang_id].name === undefined || lang_aux[lang_id].name === '' ? v.name : lang_aux[lang_id].name)}
                                                                        {v.canhoes === 'true' ? '*': ''}
                                                                    </h5>
                                                                    <span className="mr-3">{language[lang].length}&#58;&nbsp;{v.comprimento}&nbsp;&#109;</span>
                                                                    <span className="mr-3">{language[lang].unevenness}&#58;&nbsp;{v.desnivel}&nbsp;&#109;</span>
                                                                    <span>{language[lang].difficulty}&#58;&nbsp;{language[lang][v.dificuldade]}</span>
                                                                </div>
                                                                <div className="state mr-4">
                                                                    {
                                                                        dataPistasState.filter(v2 => v2.id === v.id).map((v3, key3) => {

                                                                            let color = '';
                                                                            let icon = '';
                                                                            let title = '';

                                                                            if(v3.state === false){
                                                                                color = 'red';
                                                                                icon = faBan;
                                                                                title = language[lang].closed;
                                                                            }
                                                                            if(v3.state === true){

                                                                                color = 'green';
                                                                                icon = faCheck;
                                                                                title = language[lang].open;

                                                                            }

                                                                            return (

                                                                                <FontAwesomeIcon key={key3} className="mr-2" size="lg" color={color} icon={icon} alt={title} title={title} fixedWidth />

                                                                            );

                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>

                                                    );
                                                }

                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 my-3">
                                <p>&#42;&nbsp;{language[lang].tracks_with_snow_cannon}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 my-4">
                                <h3 className="titulo-secundario p-4 bg-color-gray-light">{language[lang].natural_tracks}</h3>
                            </div>
                            <div className="col-12">
                                <div className="row no-gutters">
                                    <div className='col-12 col-md-6'>
                                        {
                                            dataPistasNaturais.map((v, key) => {
                                                let lang_aux = JSON.parse(v.language)
                                                let x = Math.round(dataPistasNaturais.length/2)

                                                if(x > key) { 
                                                    return (
                                                        <div className="pista" key={key}>
                                                            <div className="card px-2 py-3">
                                                                <div className="dificulty">
                                                                    <FontAwesomeIcon className={"pista-" + v.dificuldade} alt={language[lang][v.dificuldade]} title={language[lang][v.dificuldade]} icon={faCircle} fixedWidth />
                                                                </div>
                                                                <div className="info">
                                                                    <h5 className="subtitulo-primario">
                                                                        {/*v.info.name*/}
                                                                        {lang === 'pt' || lang_aux === undefined ? v.name : (lang_aux[lang_id] === undefined || lang_aux[lang_id].name === undefined || lang_aux[lang_id].name === '' ? v.name : lang_aux[lang_id].name)}
                                                                    </h5>
                                                                    <span className="mr-3">{language[lang].length}&#58;&nbsp;{v.comprimento}&nbsp;&#109;</span>
                                                                    <span className="mr-3">{language[lang].unevenness}&#58;&nbsp;{v.desnivel}&nbsp;&#109;</span>
                                                                    <span>{language[lang].difficulty}&#58;&nbsp;{language[lang][v.dificuldade]}</span>
                                                                </div>
                                                                <div className="state mr-4">
                                                                    {
                                                                        dataPistasState.filter(v2 => v2.id === v.id).map((v3, key3) => {

                                                                            let color = '';
                                                                            let icon = '';
                                                                            let title = '';

                                                                            if(v3.state === false){
                                                                                color = 'red';
                                                                                icon = faBan;
                                                                                title = language[lang].closed;
                                                                            }
                                                                            if(v3.state === true){

                                                                                color = 'green';
                                                                                icon = faCheck;
                                                                                title = language[lang].open;

                                                                            }

                                                                            return (

                                                                                <FontAwesomeIcon key={key3} className="mr-2" size="lg" color={color} icon={icon} alt={title} title={title} fixedWidth />

                                                                            );

                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                                else {
                                                    return null
                                                }

                                            })
                                        }
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        {
                                            dataPistasNaturais.map((v, key) => {
                                                let lang_aux = JSON.parse(v.language)
                                                let x = Math.round(dataPistasNaturais.length/2)

                                                if(x > key) { 
                                                    return null
                                                }
                                                else {
                                                    return (

                                                        <div className="pista" key={key}>
                                                            <div className="card px-2 py-3">
                                                                <div className="dificulty">
                                                                    <FontAwesomeIcon className={"pista-" + v.dificuldade} alt={language[lang][v.dificuldade]} title={language[lang][v.dificuldade]} icon={faCircle} fixedWidth />
                                                                </div>
                                                                <div className="info">
                                                                    <h5 className="subtitulo-primario">
                                                                        {/*v.info.name*/}
                                                                        {lang === 'pt' || lang_aux === undefined ? v.name : (lang_aux[lang_id] === undefined || lang_aux[lang_id].name === undefined || lang_aux[lang_id].name === '' ? v.name : lang_aux[lang_id].name)}
                                                                    </h5>
                                                                    <span className="mr-3">{language[lang].length}&#58;&nbsp;{v.comprimento}&nbsp;&#109;</span>
                                                                    <span className="mr-3">{language[lang].unevenness}&#58;&nbsp;{v.desnivel}&nbsp;&#109;</span>
                                                                    <span>{language[lang].difficulty}&#58;&nbsp;{language[lang][v.dificuldade]}</span>
                                                                </div>
                                                                <div className="state mr-4">
                                                                    {
                                                                        dataPistasState.filter(v2 => v2.id === v.id).map((v3, key3) => {

                                                                            let color = '';
                                                                            let icon = '';
                                                                            let title = '';

                                                                            if(v3.state === false){
                                                                                color = 'red';
                                                                                icon = faBan;
                                                                                title = language[lang].closed;
                                                                            }
                                                                            if(v3.state === true){

                                                                                color = 'green';
                                                                                icon = faCheck;
                                                                                title = language[lang].open;

                                                                            }

                                                                            return (

                                                                                <FontAwesomeIcon key={key3} className="mr-2" size="lg" color={color} icon={icon} alt={title} title={title} fixedWidth />

                                                                            );

                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 my-3">
                                <p>&#42;&nbsp;{language[lang].natural_tracks_info}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 my-4">
                                <h3 className="titulo-secundario p-4 bg-color-gray-light">{language[lang].snowpark}</h3>
                            </div>
                            <div className="col-12">
                                <div className="row no-gutters">
                                    <div className='col-12 col-md-6'>
                                        {
                                            dataSnowParks.map((v, key) => {

                                                let lang_aux = JSON.parse(v.language)
                                                let x = Math.round(dataSnowParks.length/2)

                                                if(x > key) { 
                                                
                                                    return (

                                                        <div className="pista" key={key}>
                                                            <div className="card px-2 py-3">
                                                                <div className="dificulty">
                                                                    <FontAwesomeIcon className={"pista-" + v.dificuldade} alt={language[lang][v.dificuldade]} title={language[lang][v.dificuldade]} icon={faCircle} fixedWidth />
                                                                </div>
                                                                <div className="info">
                                                                    <h5 className="subtitulo-primario">
                                                                        {/*v.info.name*/}
                                                                        {lang === 'pt' || lang_aux === undefined ? v.name : (lang_aux[lang_id] === undefined || lang_aux[lang_id].name === undefined || lang_aux[lang_id].name === '' ? v.name : lang_aux[lang_id].name)}
                                                                    </h5>
                                                                    <span className="mr-3">{language[lang].length}&#58;&nbsp;{v.comprimento}&nbsp;&#109;</span>
                                                                    <span className="mr-3 d-none">{language[lang].unevenness}&#58;&nbsp;{v.desnivel}&nbsp;&#109;</span>
                                                                    <span>{language[lang].difficulty}&#58;&nbsp;{language[lang][v.dificuldade]}</span>
                                                                </div>
                                                                <div className="state mr-4">
                                                                    {
                                                                        dataPistasState.filter(v2 => v2.id === v.id).map((v3, key3) => {

                                                                            let color = '';
                                                                            let icon = '';
                                                                            let title = '';

                                                                            if(v3.state === false){
                                                                                color = 'red';
                                                                                icon = faBan;
                                                                                title = language[lang].closed;
                                                                            }
                                                                            if(v3.state === true){

                                                                                color = 'green';
                                                                                icon = faCheck;
                                                                                title = language[lang].open;

                                                                            }

                                                                            return (

                                                                                <FontAwesomeIcon key={key3} className="mr-2" size="lg" color={color} icon={icon} alt={title} title={title} fixedWidth />

                                                                            );

                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>

                                                    );
                                                } else {

                                                    return null
                                                    
                                                }

                                            })
                                        }
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        {
                                            dataSnowParks.map((v, key) => {

                                                let lang_aux = JSON.parse(v.language)
                                                let x = Math.round(dataSnowParks.length/2)

                                                if(x > key) { 
                                                
                                                    return null
                                                } else {

                                                    return (

                                                        <div className="pista px-0" key={key}>
                                                            <div className="card px-2 py-3">
                                                                <div className="dificulty">
                                                                    <FontAwesomeIcon className={"pista-" + v.dificuldade} alt={language[lang][v.dificuldade]} title={language[lang][v.dificuldade]} icon={faCircle} fixedWidth />
                                                                </div>
                                                                <div className="info">
                                                                    <h5 className="subtitulo-primario">
                                                                        {/*v.info.name*/}
                                                                        {lang === 'pt' || lang_aux === undefined ? v.name : (lang_aux[lang_id] === undefined || lang_aux[lang_id].name === undefined || lang_aux[lang_id].name === '' ? v.name : lang_aux[lang_id].name)}
                                                                    </h5>
                                                                    <span className="mr-3">{language[lang].length}&#58;&nbsp;{v.comprimento}&nbsp;&#109;</span>
                                                                    <span className="mr-3 d-none">{language[lang].unevenness}&#58;&nbsp;{v.desnivel}&nbsp;&#109;</span>
                                                                    <span>{language[lang].difficulty}&#58;&nbsp;{language[lang][v.dificuldade]}</span>
                                                                </div>
                                                                <div className="state mr-4">
                                                                    {
                                                                        dataPistasState.filter(v2 => v2.id === v.id).map((v3, key3) => {

                                                                            let color = '';
                                                                            let icon = '';
                                                                            let title = '';

                                                                            if(v3.state === false){
                                                                                color = 'red';
                                                                                icon = faBan;
                                                                                title = language[lang].closed;
                                                                            }
                                                                            if(v3.state === true){

                                                                                color = 'green';
                                                                                icon = faCheck;
                                                                                title = language[lang].open;

                                                                            }

                                                                            return (

                                                                                <FontAwesomeIcon key={key3} className="mr-2" size="lg" color={color} icon={icon} alt={title} title={title} fixedWidth />

                                                                            );

                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>

                                                    );
                                                    
                                                }

                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 my-3">
                                <p>&#42;&nbsp;{language[lang].snowpark_info}</p>
                            </div>
                        </div>
                        <div className="row" id="mechanical_means">
                            <div className="col-12 my-4">
                                <h3 className="titulo-secundario p-4 bg-color-gray-light">{language[lang].mechanical_means}</h3>
                            </div>
                            <div className="col-12">
                                <div className="row no-gutters">
                                {
                                    dataMeiosMecanicos.map((v, key) => {
                                        
                                        return (

                                            <div className="col-12 col-md-6" key={key}>
                                                <div className="card px-2 py-3">
                                                    <div className="info ml-4">
                                                        <h5 className="subtitulo-primario">
                                                            {/*v.info.name*/}
                                                            {lang === 'pt' || v.info.language === undefined ? v.info.name : (v.info.language[lang_id] === undefined || v.info.language[lang_id].name === undefined || v.info.language[lang_id].name === '' ? v.info.name : v.info.language[lang_id].name)}
                                                         </h5>
                                                         <span className="mr-3">{language[lang].seats}&#58;&nbsp;{v.info.settings.capacidade}</span>
                                                         <span className="mr-3">{language[lang].unevenness}&#58;&nbsp;{v.info.settings.desnivel}&nbsp;&#109;</span>
                                                         <span>{language[lang].velocity}&#58;&nbsp;{v.info.settings.por_hora}{language[lang].px_hour}</span>
                                                    </div>
                                                    <div className="state mr-4">
                                                        {
                                                            dataMeiosMecanicosState.filter(v2 => v2.id === v.id).map((v3, key3) => {

                                                                let color = '';
                                                                let icon = '';
                                                                let title = '';

                                                                if(v3.state === false){
                                                                    color = 'red';
                                                                    icon = faBan;
                                                                    title = language[lang].closed;
                                                                }
                                                                if(v3.state === true){

                                                                    color = 'green';
                                                                    icon = faCheck;
                                                                    title = language[lang].open;

                                                                }

                                                                return (

                                                                    <FontAwesomeIcon key={key3} className="mr-2" size="lg" color={color} icon={icon} alt={title} title={title} fixedWidth />

                                                                );

                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                        );

                                    })
                                }
                                </div>
                            </div>
                            <div className="col-12 my-3">
                                <p>&#42;&nbsp;{language[lang].mechanical_means_info}</p>
                            </div>
                        </div>
                        <div className="row">
                            { dataDescription.description !== '' ? (
                                <div className="col-12">
                                    <h3 className="titulo-secundario my-3">{language[lang].observations}</h3>
                                    {/*console.log(typeof dataDescription.description)*/}
                                    {/*parse(dataDescription)*/}
                                    {ReactHtmlParser(lang === 'pt' || dataDescription.language === undefined ? dataDescription.description : (dataDescription.language[lang_id] === undefined || dataDescription.language[lang_id].description === undefined || dataDescription.language[lang_id].description === '' ? dataDescription.description : dataDescription.language[lang_id].description))}
                                    {/*parse(lang === 'pt' || dataDescription.language === undefined ? dataDescription.description : (dataDescription.language[lang_id] === undefined || dataDescription.language[lang_id].description === undefined || dataDescription.language[lang_id].description === '' ? dataDescription.description : dataDescription.language[lang_id].description))*/}
                                </div>
                            ) : null }
                            <div className="col-12 my-3 text-right">
                                <p>{language[lang].latest_update}&#58;&nbsp;{dataLatestUpdateDate}&nbsp;{language[lang].at}&nbsp;{dataLatestUpdateHour}</p>
                            </div>
                        </div>
                    </div>  
                </div>              
            </div>    
        
            <Footer />
        </>
      
    );

};
export default MapaPistas;

