import React, { useState, useEffect } from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';
//import {useLocation } from 'react-router-dom';

import './App.css';

//import Login from './components/Login/Login';

import useToken from './UseToken';

import Pagina from './components/Pagina/Pagina';
//import AppForm from './components/Pagina/Forms/AppForm';



/*import politicaPrivacidade from "./components/Pagina/Conteudo/politicaPrivacidade"; */
//import termosCondicoes from "./components/Pagina/Conteudo/termosCondicoes";

import Cookies from "./components/Pagina/Cookies/Cookies";

import GoTopButton from './components/Pagina/GoTopButton/GoTopButton';

import Content from './components/Pagina/Content/Content';

import Store from './components/Pagina/Store/Content';
import Tarifas from './components/Pagina/Tarifas/Tarifas';
import Forfaits from './components/Pagina/Forfait/Forfait';
import Equipamentos from './components/Pagina/Equipamento/Equipamento';
// import Vouchers from './components/Pagina/Voucher/Voucher';
import Packs from './components/Pagina/Pack/Pack';
import Atividades from './components/Pagina/Atividade/Atividade';
import Cart from './components/Pagina/Cart/Cart';
import PaymentNotificationPage from './components/Pagina/Store/PaymentNotificationPage';
import PaymentNotificationPageApp from './components/Pagina/Store/PaymentNotificationPageApp';
import Aula from './components/Pagina/Aula/Aula';
import Aula_Store from './components/Pagina/Aula_Store/Aula_Store';

//import MapaPistas from './components/Pagina/MapaPistas/mapaPistas';

//import LiveCam from './components/Pagina/Navbar_Extra/Livecam';
//import Accesses from './components/Pagina/Navbar_Extra/Accesses';

//import Contact from "./components/Pagina/Forms/ContactForm";
import RegistClient from "./components/Pagina/Forms/RegisterClient";
import RecoveryClient from "./components/Pagina/Forms/RecoveryClient";


import Loader from './loader'

//import Navbar from './components/Pagina/Navbar/Navbar';

//import CookieConsent from "react-cookie-consent";


import * as Hosts from './Hosts';

import "../src/components/Pagina/Pagina.css";

import {
  repo_favicon_link,
  set_language
} from './Utils';

//if (process.env.REACT_APP_MODE === 'production') console.log = function () { };

function App() {

  const { setToken } = useToken();

  const handleFavIcon = () => {

    function getFaviconEl() {
      return document.getElementById("favicon");
    }
    const favicon = getFaviconEl(); // Accessing favicon element
    //console.log('FavICON', repo_favicon_link("favicon.ico"))
    //console.log('FavICON get', document.getElementById("favicon"))
    favicon.href = repo_favicon_link("favicon.ico");
    //favicon.href = "favicon.ico";
  };

  // 
  //document.title = `${document.title}-Scientia`
  handleFavIcon()

  //const page_location = useLocation().pathname.split('/').pop();

  //const [lang, set_lang] = useState(localStorage.getItem('lang'));

  //const menus = [];
  //const { sideBarData, setSideBarData, getSidebar } = useSidebar();


  //const [menus, setMenus] = useState([])

  //const [isloading, setLoading] = useState(true);

  //const [isloading, setLoading] = useState(true);

  //LOADER TIMEOUT
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);

  useEffect(() => {
    if(localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null) {
      set_language('pt')
    }

    //setToken({ "token_site": Hosts.SITES.SIMCONFERENCE.ACCESS_TOKEN })
    setToken({ "token_site": Hosts.SITES.SIMWEBSITE.ACCESS_TOKEN })

    const timer = setTimeout(() => {
      setIsLoading(false);
      //setCount('Timeout called!');
    }, 500);

    return () => clearTimeout(timer);

    /*    setTimeout(() => {
        setIsLoading(false);
      }, 500);  */
  }, [setToken])


  /*useEffect(() => {
    setToken({ "token_site": Hosts.SITES.SIMCONFERENCE.ACCESS_TOKEN })
    //console.log('token_site', Hosts.SITES.SIMCONFERENCE.ACCESS_TOKEN)

    /* setTimeout(() => {
    setLoading(false)
    /* })

  }, [])*/


  return isLoading ?
    <main>
      <Loader />
    </main>
    :
    <>
      <Cookies />
      <GoTopButton />




      <>


        {/* <Navbar /> */}
        {/*  <Pagina /> */}
        <Switch >

          <Route path='/' exact component={Pagina} />
          {/*<Route path="/app_form" exact component={AppForm} />*/}

          {/*<Route path={"/terms_conditions"} exact component={termosCondicoes} />
          <Route path={"/privacy_policy"} exact component={termosCondicoes} />
          <Route path={"/contact"} exact component={Contact} />*/}

          {/*<Route path={"/mapa_pistas"} exact component={MapaPistas} />
          <Route path={"/livecam"} exact component={LiveCam} />
        <Route path={"/accesses"} exact component={Accesses} />*/}

          

          <Route path={"/pag/:pag_id"} component={Content} exact={true} />
          <Route path={"/:slug"} component={Content} exact={true} />

          <Route path={"/article/:article_id"} component={Content} exact={true} />
          <Route path={"/gallery/:gallery_id"} component={Content} exact={true} />
          <Route path={"/store/product/:product_id"} component={Store} exact={true} />
          <Route path={"/store/tarifas"} component={Tarifas} exact={true} />
          <Route path={"/store/forfaits"} component={Forfaits} exact={true} />
          <Route path={"/store/equipments"} component={Equipamentos} exact={true} />
          {/*
          <Route path={"/store/vouchers"} component={Vouchers} exact={true} />
          */}
          <Route path={"/store/packs"} component={Packs} exact={true} />
          <Route path={"/store/activities"} component={Atividades} exact={true} />
          <Route path={"/store/aulas"} component={Aula_Store} exact={true} />

          <Route path={"/store/cart"} component={Cart} exact={true} />
          <Route path={"/store/payment/notification/status/:key/:state"} component={PaymentNotificationPage} exact={true} />
          <Route path={"/app/payment/notification/status/:key/:state"} component={PaymentNotificationPageApp} exact={true} />

          <Route path={"/agendar/aula/:order"} component={Aula} exact={true} />

          <Route path={"/regist/client"} component={RegistClient} exact={true} />
          <Route path={"/regist/client/recovery/:hash"} component={RecoveryClient} exact={true} />

          <Route path={"/preview/:preview_id"} component={Content} exact={true} />

          {/*   <Route exact path={Hosts.WEB_SITE_URI + "contacts"} component={Contact} /> */}

          {/*  <Route path={Hosts.WEB_SITE_URI + "terms_conditions"} exact component={termosCondicoes} />
          <Route path={Hosts.WEB_SITE_URI + "privacy_policy"} exact component={politicaPrivacidade} /> */}

          <Redirect to="/" />


        </Switch >
      </>



    </>


}

export default App;

