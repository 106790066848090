import React, { useState, useEffect } from 'react';

import parse from 'html-react-parser';

//import ReactHtmlParser from 'react-html-parser';

import "../Pagina.css";
import "../content-styles.css"

//import * as Hosts from '../../../Hosts';
import {
    set_language,
	/*reqGET,*/
    content_language,
    /*repo_site_assets_link,*/

} from '../../../Utils';

import './Extra.css';


import Navbar from "../Navbar/Navbar";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Footer from "../Footer/Footer";

//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faCircle, faCheck, faBan} from "@fortawesome/free-solid-svg-icons";

import { language } from '../WEBContent/Language';


const Accesses = () => {

    const [lang, set_lang] = useState('pt');
    const [/*lang_id*/, set_lang_id] = useState('1');


    const [clean_cache, set_clean_cache] = useState(0)

    //const [dataPistasBalizadas, setDataPistasBalizadas] = useState([]);

    /*let getInfoPistasState = async () => {

        reqGET(`${Hosts.SIMCore_API}/estancia/config/${Hosts.companycode}/1`)
            .then(res => { 
                setDataPistasState(res.data[0].info.pistas)
                setDataMeiosMecanicosState(res.data[0].info.meios)
                //setDataDescription(res.data[0].info.description)
                setDataDescription(res.data[0].info)
                setDataLatestUpdateDate(res.data[0].info.date.split('T')[0])
                setDataLatestUpdateHour(res.data[0].info.date.split('T')[1].split('.')[0].slice(0, -3))
            })
        .catch(erro => console.log(erro))  


    }*/

    
    useEffect(() => {

        let i = Date.now()
        set_clean_cache(i)

        if(localStorage.getItem('lang') === undefined  || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if(el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }
    
    }, []); 



    return (
        <>
            <Navbar />  

            <Breadcrumb /> 

            <div id="acessos" className="container-fluid my-5">
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 my-4">
                                <h3 className="titulo-primario">
                                    {language[lang].accesses}
                                </h3>
                            </div>
                            <div className="col-12 mb-4">
                                <div className="text-center">
	                                <iframe className="mw-100" frameBorder="0" id="blockrandom" title="Mapa de Acessos" name="iframe" scrolling="auto" src={"https://sigext.infraestruturasdeportugal.pt/sigext/rim.svg?cc=" + clean_cache} >This option will not work correctly. Unfortunately, your browser does not support inline frames.</iframe>
                                    {/*<iframe src="https://sigext.infraestruturasdeportugal.pt/sigext/rim.svg?fbclid=IwAR0PHGCqtcIjDyRlHUA1q_Hhc5dp9V6nN5ljQKxCNrd8bYb3axi1WUFOAjk" title="access" name="iframe" width="500" height="500"></iframe>*/}
                                </div>
                            </div>
                            <div className="col-12 text-center my-4">
                                {parse(language[lang].accesses_info)}
                            </div>
                        </div>
                    </div>  
                </div>              
            </div>    
        
            <Footer />
        </>
      
    );

};
export default Accesses;

