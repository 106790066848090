import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

import { WEB_SITE_URI } from './Hosts'

//  <BrowserRouter basename='/simnet_credit' forceRefresh={true} >
/* <BrowserRouter basename='/sim.credit.net' > */
ReactDOM.render(


  < BrowserRouter basename={WEB_SITE_URI} >
    <App />
  </BrowserRouter >,
  document.getElementById('root')

);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
