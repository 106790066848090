import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';

//import { Helmet } from 'react-helmet';
import AssecHelmet from "../ASSEC_Helmet/ASSEC_Helmet";

import "../Pagina.css";
import "../content-styles.css"

import * as Hosts from '../../../Hosts';
import {
    //set_language,
	reqGET,
    content_language,
    upgrade_cart,
    my_local_storage,

} from '../../../Utils';

import './Atividade.css';

import Loader from '../../../loader';
import Navbar from "../Navbar/Navbar";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Footer from "../Footer/Footer";

import { language } from '../WEBContent/Language';

const Atividade = () => {
    const { pathname } = useLocation();

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState('pt');
    const [lang_id, set_lang_id] = useState('1');
    
    const [atividades, set_atividades] = useState([]);
    const [content_parent, set_content_parent] = useState({});

    const [cart, set_cart] = useState({});
    const [store_state, set_store_state] = useState(true);

    const [refresh, setRefresh] = useState(0)

    useEffect(() => { 

        let getInfoResort = async () => {
            //setLoading(true)
            await reqGET(`${Hosts.SIMCore_API}/estancia/config/${Hosts.companycode}/1`)
              .then(res => {
                set_store_state(res.data[0].info.store.state)
  
              })
              .catch(erro => console.log('Erro', erro))
        }
        getInfoResort()

        let getatividades = async () => {

            await reqGET(`${Hosts.SIMCore_API}/estancia/activity/${Hosts.companycode}`)
                .then(res => { 
                    set_atividades(res.data)
    
                    setLoading(false)
                    // console.log(res.data)
                })
                .catch(erro => console.log(erro))  
    
        }

        let getInfoList = async () => {

            await reqGET(`${Hosts.SIMCore_API}/web/structure/${Hosts.companycode}/1`)
                .then(res => {
                    if(res.data.length > 0) {
                        res.data.forEach((v) => {
                            if (v.info.principal === true) {
                                //set_dados(dados.current = v);

                                v.content.childs.forEach((el) => {

                                    if(el.id_content === '' && ('/' + el.link === pathname)) {
                                        if(el['parent'] !== undefined) {
                                            console.log(el['parent'])

                                            if(el['parent'] !== undefined) {

                                                reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${el['parent']}`)
                                                    .then(res => { 
                                                        if(res.data.length > 0) {
                                    
                                                            var hoje = new Date();
                                                            let status = 0
                                                            if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                                                var date_p = new Date(res.data[0].info.date.published);
                                    
                                                                status = 1
                                                                if(hoje >= date_p) 
                                                                    status = 2
                                                            }
                                    
                                                            if(status !== 2) {
                                                                res.data[0].content = {text: 'rascunho ou agendado'}
                                    
                                                                set_content_parent(res.data[0])   
                                                            }
                                                            else {
                                                                set_content_parent(res.data[0]) 
                                                            }
                                                        }

                                                        // ### setLoading(false)
                                                    })
                                                    .catch(erro => console.log('ERROR', erro))

                                            }
                                        }
                                    }

                                    if(el['children'] !== undefined && el['children'].length > 0) {
                                        el['children'].forEach(async (el_c) => {
                                            if(el_c.id_content === '' && ('/' + el_c.link === pathname)) {
                                                if(el_c['parent'] !== undefined) {

                                                    await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${el_c['parent']}`)
                                                        .then(res => { 
                                                            if(res.data.length > 0) {
                                        
                                                                var hoje = new Date();
                                                                let status = 0
                                                                if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                                                    var date_p = new Date(res.data[0].info.date.published);
                                        
                                                                    status = 1
                                                                    if(hoje >= date_p) 
                                                                        status = 2
                                                                }
                                        
                                                                if(status !== 2) {
                                                                    res.data[0].content = {text: 'rascunho ou agendado'}
                                        
                                                                    set_content_parent(res.data[0])   
                                                                }
                                                                else {
                                                                    set_content_parent(res.data[0]) 
                                                                }
                                                            }

                                                            // ### setLoading(false)
                                                        })
                                                        .catch(erro => console.log('ERROR', erro))

                                                }
                                            }
                                        })
                                    }

                                })
                                
                            }
                        })
                    }
                    else {
                        return []
                    }

                })
                .catch(erro => console.log('ERROR', erro))

        }
        getInfoList()

        if(my_local_storage.getItem('snowCart') !== undefined && my_local_storage.getItem('snowCart') !== null) {
            //console.log('cart', my_local_storage.getItem('snowCart'))
            
            let start =  new Date(JSON.parse(my_local_storage.getItem('snowCart')).date).getTime()
            let end =  new Date().getTime()

            var msDiff = start - end; 
            //var diffdays = Math.floor(msDiff / (1000 * 60 * 60 * 24));
            var diffhours = Math.floor(msDiff / (1000 * 60 * 60));
            if(diffhours > -2) {
                set_cart(JSON.parse(my_local_storage.getItem('snowCart')))
            }
            else {
                set_cart({})
                my_local_storage.removeItem('snowCart')
            }
        }

        /*if(localStorage.getItem('lang') === undefined  || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if(el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }*/

        set_lang(localStorage.getItem('lang'))
        content_language.forEach(el => {
            if (el.code === localStorage.getItem('lang')) {
            set_lang_id(el.id)
            }
        })

        getatividades()
    
    }, [pathname]); 

    const get_item_cart_value = (id) => {

        let result = 0
        if(cart['atividades'] !== undefined) {
            cart['atividades'].forEach(el => {
                if(el.id === id) {
                    result = el.quantity
                }
            })
        }
        return result

    }

    const change_quantity = (id, e) => {

        //if(cart['date'] === undefined) {
            cart['date'] = new Date()
        //}

        if(cart['atividades'] === undefined)
            cart['atividades'] = [] 

        if(e >= 0) {
            const aux = cart['atividades'].filter(el => el.id === id)
            if(aux.length > 0) {
                cart['atividades'].forEach(el => {
                    if(el.id === id) {
                        el.quantity = e
                    }
                })
            }
            else {
                cart['atividades'].push({id: id, quantity: e})
            }
        }

        // console.log('Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«')
		// console.log('snowCart:' + JSON.stringify(cart))

        upgrade_cart('snowCart', JSON.stringify(cart))
        set_cart(cart)

        setRefresh(refresh + 1)
    }

    const add_cart_go_equipments = () => {
        // console.log('cart', cart)
        // upgrade_cart('snowCart', JSON.stringify(cart))

        // setRefresh(refresh + 1)

        // console.log('sessionSotage', sessionStorage.getItem('snowCart'))

        window.location.href = Hosts.WEB_SITE_URI + 'store/equipments'
    }

    const add_cart_finalize_order = () => {
        // console.log('cart', cart)
        // upgrade_cart('snowCart', JSON.stringify(cart))

        // setRefresh(refresh + 1)

        // console.log('sessionSotage', sessionStorage.getItem('snowCart'))

        window.location.href = Hosts.WEB_SITE_URI + 'store/cart'
    }

    return (isloading ?

		<Loader />
		
		:
        <>
            <Navbar /> 
            <Breadcrumb /> 

            <div id="atividade" className="container-fluid my-5">
                <div className="row">
                    <div className='col-12'>
                        <div>
                            <AssecHelmet
                                title={content_parent['info'] !== undefined && content_parent.info.seo !== undefined && content_parent.info.seo['title'] !== undefined && content_parent.info.seo['title'] !== '' ? content_parent.info.seo['title'] : ''}
                                description={content_parent['info'] !== undefined && content_parent.info.seo !== undefined && content_parent.info.seo['description'] !== undefined && content_parent.info.seo['description'] !== '' ? content_parent.info.seo['description'] : ''}
                                keywords={content_parent['info'] !== undefined && content_parent.info.seo !== undefined && content_parent.info.seo['keywords'] !== undefined && content_parent.info.seo['keywords'] !== '' ? content_parent.info.seo['keywords'] : ''}
                            />
                        </div>
                        <div className="container my-5 d-none">
                            <h3 className="titulo-primario">{language[lang].comprar}</h3>
                        </div>

                        {
                        store_state ?
                            <div className="container">
                                <div className="row">
                                    {
                                    content_parent['content'] !== undefined ?
                                        <div className="div-content col-12 mb-5">
                                            <div className="row">
                                                <div className='col-12 col-md-8'>
                                                    <h2 className={content_parent['content'][0] !== undefined && content_parent['content'][0]['title'] !== undefined && content_parent.content[0].title !== '' ? "titulo-secundario" : "d-none"}>{content_parent['content'][0] !== undefined && content_parent['content'][0]['title'] !== undefined && content_parent.content[0].title !== '' ? content_parent.content[0].title : ''}</h2>
                                                    <h2 className={content_parent['content'][0] !== undefined && content_parent['content'][0]['subtitle'] !== undefined && content_parent.content[0].subtitle !== '' ? "titulo-secundario" : "d-none"}>{content_parent['content'][0] !== undefined && content_parent['content'][0]['subtitle'] !== undefined && content_parent.content[0].subtitle !== '' ? content_parent.content[0].subtitle : ''}</h2>
                                                    <div className={"div-text"}>
                                                        {ReactHtmlParser(content_parent.content[0].text)}
                                                    </div>
                                                </div>
                                                <div className='col-12 col-md-4'>
                                                    <img className='img-fluid' src={content_parent['content'][0]['media']['image'].length > 0 ? content_parent['content'][0]['media']['image'][0]['link'] : ''} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    :
                                        ''
                                    }

                                    <div className="col-12 col-lg-8 table-responsive">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <td></td>
                                                    <td className="text-right"><h3 className="titulo-secundario p-4 m-0 w-100 bg-color-gray-light">{language[lang].precos}</h3></td>
                                                    <td className="text-right"><h3 className="titulo-secundario p-4 m-0 w-100 bg-color-gray-light">{language[lang].quantidade}</h3></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                atividades.map((el, key) => {
                                                    if(el.id !== '90') {
                                                        if(el.info.status === true) {
                                                            return (
                                                                <tr key={key} className={key > 0 ? 'border-bottom' : 'border-bottom'}>
                                                                    <td colSpan={3}>
                                                                        <table>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td data-toggle="collapse" data-target={"#expand_" + key}>
                                                                                        <h3 className="subtitulo-secundario px-4 py-3 m-0 w-100">
                                                                                            <i className="bi bi-plus-circle pr-3"></i> 
                                                                                            {lang === 'pt' || el.info.language === undefined ? el.info.name : (el.info.language[lang_id] === undefined || el.info.language[lang_id] === null || el.info.language[lang_id].name === undefined || el.info.language[lang_id].name === '' ? el.info.name : el.info.language[lang_id].name)}
                                                                                            &nbsp;|&nbsp;
                                                                                            <span className='subtitulo-primario'>
                                                                                                {lang === 'pt' || el.info.language === undefined ? el.info.category : (el.info.language[lang_id] === undefined || el.info.language[lang_id] === null || el.info.language[lang_id].category === undefined || el.info.language[lang_id].category === '' ? el.info.category : el.info.language[lang_id].category)}
                                                                                            </span> 
                                                                                            {
                                                                                            el.info.observation !== undefined && el.info.observation !== '' ?
                                                                                                <>
                                                                                                    <br /> 
                                                                                                    <small className='pl-4'>
                                                                                                        {lang === 'pt' || el.info.language === undefined ? el.info.observation : (el.info.language[lang_id] === undefined || el.info.language[lang_id] === null || el.info.language[lang_id].observation === undefined || el.info.language[lang_id].observation === '' ? el.info.observation : el.info.language[lang_id].observation)}
                                                                                                    </small>
                                                                                                </>
                                                                                            :
                                                                                                null
                                                                                            }
                                                                                        </h3>
                                                                                    </td>
                                                                                    <td className="text-right" data-toggle="collapse" data-target={"#expand_" + key}><h3 className="subtitulo-secundario px-4 py-3 m-0 w-100">{(parseFloat(el.info.value.price)).toFixed(2)} €</h3></td>
                                                                                    <td className="text-right">
                                                                                        <div className="quantity-input float-right">
                                                                                            <button 
                                                                                                className="quantity-input__modifier quantity-input__modifier--left" 
                                                                                                onClick={() => change_quantity(el.id, parseInt(get_item_cart_value(el.id)) - 1)}
                                                                                            >
                                                                                                &mdash;
                                                                                            </button>
                                                                                            <input 
                                                                                                className="quantity-input__screen" 
                                                                                                type="text" 
                                                                                                value={cart['atividades'] !== undefined ? get_item_cart_value(el.id) : 0} 
                                                                                                readOnly 
                                                                                            />
                                                                                            <button 
                                                                                                className="quantity-input__modifier quantity-input__modifier--right" 
                                                                                                onClick={() => change_quantity(el.id, parseInt(get_item_cart_value(el.id)) + 1)}
                                                                                            >
                                                                                                &#xff0b;
                                                                                            </button>  
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr id={"expand_" + key} className="collapse expand row-child">
                                                                                    <td colSpan="3">
                                                                                        <div className='px-4 py-3'>
                                                                                            {ReactHtmlParser(lang === 'pt' || el.info.language === undefined ? el.info.description : (el.info.language[lang_id] === undefined || el.info.language[lang_id] === null || el.info.language[lang_id].description === undefined || el.info.language[lang_id].description === '' ? el.info.description : el.info.language[lang_id].description))}
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    }
                                                    else return null
                                                })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="row">

                                    <div className='col-12 col-md-8 mb-5'>
                                        <div className='row'>
                                            <div className='col-6 mt-3'>
                                                <Link className="btn" to={{ pathname: '/store/tarifas' }} replace>
                                                    <i className="bi bi-arrow-left mr-1"></i> {language[lang].voltar}
                                                </Link>
                                            </div>

                                            <div className='col-6 text-right mt-3'>
                                                <button className="btn btn-primary px-5 mr-3 d-none" onClick={add_cart_go_equipments}>{language[lang].equipments}</button>
                                                <button className="btn btn-primary px-4" onClick={add_cart_finalize_order}>
                                                    {language[lang].finalize_order} <i className="bi bi-arrow-right ml-1"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                
                                </div> 
                            </div> 
                        :
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-12 text-center'>
                                        <i class="bi bi-clock-history" style={{fontSize: '150px'}}></i>
                                    </div>
                                    <div className='col-12 my-5 text-center'>
                                        <h3>{language[lang].loja_fechada}</h3>
                                    </div>
                                </div>
                            </div>
                        }
                    </div> 
                </div> 
            </div>    
        
            <Footer />
        </>
      
    );

};
export default Atividade;

