import React, { useState, useEffect } from 'react'
import { useParams, Redirect } from "react-router-dom";

import NormalContent from './Normal';
import CardContent from './Card';
import NewsCardsContent from './NewsCards';
import AccordionContent from './Accordion';
import TabContent from './Tabs';
import NewsContent from './News';
import ContactContent from './ContactForm';
//import ContactContent from '../Forms/ContactForm';
import ListContent from './List';
import ListContent2 from './List2';
import NotFound from './NotFound';
import MapaPistas from '../MapaPistas/mapaPistas';

import GalleryCardsContent from './GalleryCards';
import GalleryContent from './Gallery';

import Livecam from '../Navbar_Extra/Livecam';
import Accesses from '../Navbar_Extra/Accesses';
import Loader from '../../../loader';

import * as Hosts from '../../../Hosts';
import { reqGET } from '../../../Utils';
import { getTokenInfo } from '../../../UseToken';

import './Content.css';

export default function Content() {

    const { pag_id, slug, article_id, gallery_id, preview_id } = useParams();
    const [isloading, setLoading] = useState(true);

    const data_interface = {
        id: '',
        info: {
            type: 'news',
            layout: 'all'
        },
        content: {}
    }
    const [content, set_content] = useState(data_interface);

    useEffect(() => {


        let getPreviewInfoId = async (id) => {
            await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${id}`)
                .then(res => { 
                    if(res.data.length > 0) {

                        if(getTokenInfo()['profile'] !== '') {
                            res.data[0].info['preview'] = true
                            set_content(res.data[0])
                            setLoading(false)
                        }
                        else {
                        }
                    }
                })
                .catch(erro => console.log('ERROR', erro))
        }

        let getInfoId = async (id) => {
            await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${id}`)
                .then(res => { 
                    if(res.data.length > 0) {

                        var hoje = new Date();
                        let status = 0
                        if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                            var date_p = new Date(res.data[0].info.date.published);

                            status = 1
                            if(hoje >= date_p) 
                                status = 2
                        }

                        if(status !== 2) {
                            //res.data[0].content[0] = {text: 'rascunho ou agendado'}
                            res.data[0].info.layout = '404'
                        }

                        set_content(res.data[0])
                        setLoading(false)
                    }
                    else {
                        if(article_id !== undefined && article_id !== 'undefined' && article_id !== '')
                            set_content({id: id, info: {type: 'news', layout: '404'}, content: []})
                        else
                            set_content({id: id, info: {type: 'content', layout: '404'}, content: []})

                        setLoading(false)
                    }
                })
                .catch(erro => console.log('ERROR', erro))
        }
    
        let getInfoSlug = async (slug) => {
            await reqGET(`${Hosts.SIMCore_API}/web/content/slug/${Hosts.companycode}/${slug}`)
                .then(res => { 
                    if(res.data.length > 0) {

                        var hoje = new Date();
                        let status = 0
                        if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                            var date_p = new Date(res.data[0].info.date.published);

                            status = 1
                            if(hoje >= date_p) 
                                status = 2
                        }

                        if(status !== 2) {
                            //res.data[0].content[0] = {text: 'rascunho ou agendado'}
                            res.data[0].info.layout = '404'
                        }
                        set_content(res.data[0])

                        setLoading(false)
                    }
                    else {
                        //console.log('slug: ', slug)
                        set_content({id: '', info: {type: slug}})
  
                        setLoading(false)
                    }
                })
                .catch(erro => console.log('ERROR', erro))
        }

        if(preview_id !== undefined && preview_id !== 'undefined' && preview_id !== '') {
            getPreviewInfoId(preview_id)
               .then(() => {
               })
        }
        else if(pag_id !== undefined && pag_id !== 'undefined' && pag_id !== '') {
            getInfoId(pag_id)
        }
        else if(slug !== undefined && slug !== 'undefined' && slug !== '')  {
            getInfoSlug(slug)
        }
        else if(article_id !== undefined && article_id !== 'undefined' && article_id !== '')  {
            if(article_id === 'all') {
                // content.info['type'] = 'news'
                // content.info['layout'] = 'all'
                
                // set_content(content)
                setLoading(false)
            }
            else {
                getInfoId(article_id)
                    .then((res) => {
                        if(res === true){
                            setLoading(false)
                        }
                    })
            }
        }
        else if(gallery_id !== undefined && gallery_id !== 'undefined' && gallery_id !== '')  {
            if(gallery_id === 'all') {

                set_content({id: '', info: {type: 'gallery', layout: 'all'}, content: []})
                setLoading(false)

            }
            else {
                getInfoId(gallery_id)
                    .then(() => {
                        setLoading(false)
                    })
            }
        }
        else {
            console.log('aaa')
        }
        
    }, [pag_id, slug, article_id, gallery_id, preview_id]);

    return (isloading ?

		<Loader />
		
		:

        (() => {
            switch (content.info.type) {
                case 'content':
                    switch (content.info.layout) {

                        case 'card':
                            return (
                                <CardContent info={{ state: { id: pag_id, preview: preview_id, slug: slug } }} />
                            )
                        
                        case 'accordion':
                            return (
                                <AccordionContent info={{ state: { id: pag_id, preview: preview_id, slug: slug } }} />
                            )
                        
                        case 'tabs':
                            return (
                                <TabContent info={{ state: { id: pag_id, preview: preview_id, slug: slug } }} />
                            )

                        case 'contact':
                            return (
                                <ContactContent info={{ state: { id: pag_id, preview: preview_id, slug: slug } }} />
                            )

                        case 'list':
                            return (
                                <ListContent info={{ state: { id: pag_id, preview: preview_id, slug: slug } }} />
                            ) 
                        
                        case 'list2':
                            return (
                                <ListContent2 info={{ state: { id: pag_id, preview: preview_id, slug: slug } }} />
                            ) 
                        
                        case '404':
                            return (
                                <NotFound info={{ state: { id: pag_id, preview: preview_id, slug: slug } }} />
                            ) 

                        default:
                            return (
                                <NormalContent info={{ state: { id: pag_id, preview: preview_id, slug: slug } }} />
                            )
                    }
                
                case 'news':
                    switch (content.info.layout) {
                        case 'all':
                            return (
                                <NewsCardsContent info={{ state: { id: article_id, preview: preview_id, slug: slug } }} />
                            )

                        case 'normal':
                            return (
                                <NewsContent info={{ state: { id: article_id, preview: preview_id, slug: slug } }} />
                            )
                        case '404':
                            return (
                                <NotFound info={{ state: { id: article_id, preview: preview_id, slug: slug } }} />
                            ) 

                        default:
                            return (
                                <NormalContent info={{ state: { id: article_id, preview: preview_id, slug: slug } }} />
                            )
                    }

                    case 'gallery':
                        switch (content.info.layout) {
                            case 'all':
                                return (
                                    <GalleryCardsContent info={{ state: { id: gallery_id, preview: preview_id, slug: slug } }} />
                                )
    
                            case 'normal':
                                return (
                                    <GalleryContent info={{ state: { id: gallery_id, preview: preview_id, slug: slug } }} />
                                )
                            
                            case '404':
                                return (
                                    <NotFound info={{ state: { id: gallery_id, preview: preview_id, slug: slug } }} />
                                ) 
    
                            default:
                                return (
                                    <NormalContent info={{ state: { id: gallery_id, preview: preview_id, slug: slug } }} />
                                )
                        }

                case 'mapa_pistas':
                    return (
                        <MapaPistas info={{ state: { slug: slug } }}/>
                    )
               
                case 'livecam':
                    return (
                        <Livecam />
                    )
                case 'accesses':
                    return (
                        <Accesses />
                    )
                   
                default:
                    return (
                        <Redirect to="/" />
                    )
            }
   
        })()
    
    )
}
