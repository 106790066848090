import React, { useState, useEffect, /*useRef*/ } from 'react';
import { useLocation } from 'react-router-dom';

import './Navbar.css';

import { getTokenInfo } from '../../../UseToken';

import { language } from '../WEBContent/Language';

import { 
    reqGET, 
    set_language, 
    repo_site_assets_link, 
    repo_logo_link, 
    repo_site_documents_link, 
    content_language 
} from '../../../Utils';

//import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faLongArrowAltLeft, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as Hosts from "../../../Hosts";

//import jwt_decode from "jwt-decode";

/* import { Link, animateScroll as scroll } from "react-scroll"; */
//import { Link } from "react-scroll";

/*const getTokenInfo = () => {

    const tokenString = localStorage.getItem('token');
    //console.log(tokenString)
    if (tokenString !== null)
        return jwt_decode(JSON.parse(tokenString).token);
    else
        return null
};*/

const Navbar = (props) => {

    const data_interface = {
        id: '',
        info: {},
        content: {
            childs: []
        }
    }

    const [navbar, set_navbar] = useState(data_interface);
    //const [navbar_active, set_navbar_active] = useState(false);

    const [lang, set_lang] = useState(localStorage.getItem('lang'));

    const [enableFixedSideNavigationMenu, /*setEnableFixedSideNavigationMenu*/] = useState(true);

    const [mainLogo, setMainLogo] = useState(true)

    const [disableScroll, setDisableScroll] = useState(false)

    //const [login_name,] = useState(getTokenInfo() === null ? '' : getTokenInfo().name);

    //const page_location = useLocation().pathname.split('/')[2];


	const [/*refresh*/, setRefresh] = useState(0);

    const page_location = useLocation().pathname.split('/').pop();

    const location = useLocation().pathname;

    //console.log('teste: ', page_location)

    //const navBarRef = useRef();

    //const [navBarHeight, setNavBarHeight] = useState(null);

    function logout() {

        if(localStorage.snowCart !== undefined  && localStorage.snowCart !== null) {
            let aux = localStorage.snowCart 
            localStorage.clear(); 
            localStorage.snowCart = aux
          }
          else {
            localStorage.clear();
          }
    
        //console.log('CAHCE LIMPA');
        //< Redirect to='/simconference' replace />
        //window.location.href = '/chucb-conference-gest';
    }

    const changeLogo = () => {

        let speed = '';

        //console.log(disableScroll)

        if(mainLogo) {
            speed = 800
        }else{
            speed = 100
        }

        setTimeout(function () {

            setMainLogo(!mainLogo)

        }, speed)

        let documentWidth = document.documentElement.clientWidth;
        let windowWidth = window.innerWidth;
        let scrollBarWidth = windowWidth - documentWidth;

        setTimeout(function () {

            if(!disableScroll) {
                document.body.style.marginRight = scrollBarWidth+"px"
                document.body.style.overflow = "hidden"
            }else{
                document.body.style = ''
                //document.body.style.marginRight = "0px"
                //document.body.style.overflow = "auto";
            }

            setDisableScroll(!disableScroll)

        }, 500)

    }

    const logoPath = (e) => {

        if(!e) {
            return repo_logo_link("logo_white.svg");
        }else{
            return repo_logo_link("logo.svg");
        }

        
    }

    const change_lang = (l) => {
        set_language(l)
        set_lang(l)
        window.location.reload()

        //console.log('language', localStorage.getItem('lang'))
        //console.log('localstorage', localStorage)
    }

    /*const NabBarHeightCalc = () => { 

        const newHeight = navBarRef.current.clientHeight;

        setNavBarHeight(newHeight);

    }*/

    /*function do_logout() {
        localStorage.clear();
        window.location.reload()
    }*/


    useEffect(() => {
        set_lang(localStorage.getItem('lang'))


        //console.log('profile', getTokenInfo().profile)


        set_navbar({ id: '', info: '', content: { childs: [] } })

        let getInfo = async () => {

            reqGET(`${Hosts.SIMCore_API}/web/structure/${Hosts.companycode}/1`)
                .then(res => {
                    res.data.forEach((v) => {
                        if (v.info.principal === true) {
                            //set_dados(dados.current = v);
    
                            if(lang !== 'pt') {
                                let x = 0
                                content_language.forEach((cl) => {
                                    if(cl.code === lang) {
    
                                        v.content.childs.forEach((el) => {
                                            if(el.id_content !== '') {
                                                if(el.children !== undefined) {
                                                    x = x - el.children.length
                                                    el.children.forEach((el_c) => {
                                                        reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${el_c.id_content}`)
                                                            .then(res_3 => {
                                                                if(res_3.data.length > 0) {
                                                                    if(res_3.data[0].info.language.length > 0) {
                                                                        if(res_3.data[0].info.language[cl.id] !== undefined && res_3.data[0].info.language[cl.id] !== null) {
                                                                            if(res_3.data[0].info.language[cl.id]['name'] !== '') {
                                                                                el_c.title = res_3.data[0].info.language[cl.id]['name'];
                
                                                                                x++
                                                                                if(v.content.childs.length === x) {
                                                                                    setRefresh(refresh => refresh + 1)
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                    else {
                                                                        x++
                                                                        if(v.content.childs.length === x) {
                                                                            setRefresh(refresh => refresh + 1)
                                                                        }
                                                                    } 
                                                                }
                                                                else {
                                                                    x++
                                                                    if(v.content.childs.length === x) {
                                                                        setRefresh(refresh => refresh + 1)
                                                                    }
                                                            } 
                                                        })
                                                    })
                                                }
    
                                                reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${el.id_content}`)
                                                    .then(res_2 => {
                                                        if(res_2.data.length > 0) {
                                                            if(res_2.data[0].info.language.length > 0) {
                                                                if(res_2.data[0].info.language[cl.id] !== undefined && res_2.data[0].info.language[cl.id] !== null) {
                                                                    if(res_2.data[0].info.language[cl.id]['name'] !== '') {
                                                                        el.title = res_2.data[0].info.language[cl.id]['name'];
          
                                                                        x++
                                                                        if(v.content.childs.length === x) {
                                                                          setRefresh(refresh => refresh + 1)
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            else {
                                                                x++
                                                                if(v.content.childs.length === x) {
                                                                    setRefresh(refresh => refresh + 1)
                                                                }
                                                            } 
                                                        }
                                                        else {
                                                            x++
                                                            if(v.content.childs.length === x) {
                                                                setRefresh(refresh => refresh + 1)
                                                            }
                                                        } 
                                                    })
    
                                                
                                            }
                                            else {
                                                if(el['language'] !== undefined && el['language'] !== null) {
                                                    if(el.language[cl.id] !== undefined && el.language[cl.id]['title'] !== undefined && el.language[cl.id]['title'] !== '') { 
                                                        el.title = el.language[cl.id]['title']
          
                                                        x++
                                                        if(v.content.childs.length === x) {
                                                          setRefresh(refresh => refresh + 1)
                                                        }
                                                    }
                                                }
                                            }
    
                                        })
    
                                    }
                                })
                                
                            }
                            
                            set_navbar(v);
                        }
                    });
    
                })
                .catch(erro => console.log('ERROR', erro))
        }

        getInfo()

        //NabBarHeightCalc();

        //window.addEventListener("resize", NabBarHeightCalc)


        // if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
        //     change_lang('pt')
        // }
        // else {
        //     set_lang(localStorage.getItem('lang'))
        // }

        //console.log('language', localStorage.getItem('lang'))
        //console.log('localstorage', localStorage)


    }, [lang])

    return (

        /*<div className="fixed-top">*/
        //coloca navbar fixed na página principal e sticky nas secundárias
        /*<div className={props.pag_welcome ? "fixed-top" : "sticky-top"}>*/
        <div className={"sticky-top"}>
            <section id="navbar" /*ref={navBarRef}*/>

                {
                   !enableFixedSideNavigationMenu ?

                    <nav className="navbar navbar-expand-lg navbar-light py-0 px-0 nav-effect">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12 col-lg-10 offset-lg-1 top-bar w-100 py-2 small">
                                    <div className="float-left d-none">
                                        <button className={"pr-2 button_no_border " + (lang === 'pt' ? 'current_lang' : '')} onClick={() => change_lang('pt')}>PT</button>
                                        &#124;
                                        <button className={"pr-2 button_no_border " + (lang === 'en' ? 'current_lang' : '')} onClick={() => change_lang('en')}>EN</button>
                                        &#124;
                                        <button className={"p-2 button_no_border " + (lang === 'es' ? 'current_lang' : '')} onClick={() => change_lang('es')}>ES</button>
                                        &#124;
                                        <button className={"p-2 button_no_border " + (lang === 'fr' ? 'current_lang' : '')} onClick={() => change_lang('fr')}>FR</button>
                                    </div>
                                    <div className="float-right bg-white">
                                        <button className={"p-2 button_no_border " + (lang === 'pt' ? 'current_lang' : '')} onClick={() => change_lang('pt')}>PT</button>
                                        &#124;
                                        <button className={"p-2 button_no_border " + (lang === 'en' ? 'current_lang' : '')} onClick={() => change_lang('en')}>EN</button>
                                        &#124;
                                        <button className={"p-2 button_no_border " + (lang === 'es' ? 'current_lang' : '')} onClick={() => change_lang('es')}>ES</button>
                                        &#124;
                                        <button className={"p-2 button_no_border " + (lang === 'fr' ? 'current_lang' : '')} onClick={() => change_lang('fr')}>FR</button>
                                    </div>
                                </div>

                                <div className="col-12 d-none">
                                    <div className="row text-center border-bottom">
                                        <a className="navbar-brand pt-0 pb-0 mr-auto ml-auto " href={Hosts.WEB_SITE_URI} alt="">
                                            <img id="big-logo" src={repo_logo_link("logo.svg")} alt={Hosts.webSiteTitle} title={Hosts.webSiteTitle} className="my-4 svg-logo img-fluid" />
                                        </a>
                                    </div>
                                </div>


                                <div className="col-12 d-lg-none d-flex justify-content-between bg-color-white py-2">
                                    <a className="navbar-brand" href={Hosts.WEB_SITE_URI}>
                                        <img id="small-logo" src={repo_logo_link("logo-mobile.svg")} alt={Hosts.webSiteTitle} title={Hosts.webSiteTitle} className="svg-logo-small img-fluid" />
                                    </a>
                                    <button className="navbar-toggler float-right" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                </div>
                                <div className="col-12 col-lg-10 offset-lg-1 collapse navbar-collapse" id="navbarNav">
                                    <a className="navbar-brand pl-3 d-none d-lg-block py-2" href={Hosts.WEB_SITE_URI} alt="">
                                        <img id="big-logo" className="svg-logo img-fluid" src={repo_logo_link("logo.svg")} alt={Hosts.webSiteTitle} title={Hosts.webSiteTitle} />
                                    </a>
                                    <div className="top w-100 d-none bg-color-gray-light px-4 py-2">
                                        {/* <div className="float-left">
                                            <ul className="list-inline m-0">
                                                <li className="list-inline-item pr-3">
                                                    <a href="http://www.chcbeira.min-saude.pt/" target="_blank" rel="noreferrer">
                                                        <img className="img-fluid" src="./img/logo_chcb.svg" width="180px" alt="Logo CHCB" title="Logo CHCB"/>
                                                    </a>
                                                </li>
                                                <li className="list-inline-item">
                                                    <a href="https://www.sns.gov.pt/" target="_blank" rel="noreferrer">
                                                        <img className="img-fluid" src="./img/logo_sns.svg" width="180px" alt="Logo SNS" title="Logo SNS"/>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div> */}
                                        {/* <div className="float-left "> */}
                                        <div className="float-left d-none">
                                            <button className="pr-2 button_no_border" onClick={() => change_lang('en')}>EN</button>
                                            |
                                            <button className="pl-2 button_no_border" onClick={() => change_lang('pt')}>PT</button>
                                        </div>
                                        <div className="text-right">
                                            <a className={"pr-2 " + (page_location === 'form_author' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'form_author'}>{language[lang].regist}</a>
                                            |
                                            <a className="pl-2" href={Hosts.WEB_BACKOFFICE_URI} target="_blank" rel="noreferrer">{language[lang].login}</a>
                                        </div>
                                    </div>
                                    <ul className="navbar-nav ml-auto my-2">
                                        {/*console.log('RES DATA MENU', navbar)*/}

                                        {
                                            props.mostrar_botao_voltar ?

                                                <li className="nav-item" /* key={key} */ >
                                                    <a href={Hosts.WEB_SITE_URI} className={"nav-link "}>
                                                        <FontAwesomeIcon className="mr-2" icon={faLongArrowAltLeft} />
                                                        {language[lang].back}
                                                    </a>
                                                </li>

                                            :

                                                navbar.content.childs.map((v, key) => {

                                                    //console.log(v)
                                                        
                                                    let link = ''
                                                    v.id_content !== '' ?
                                                        link = (v.slug !== undefined && v.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.slug : Hosts.WEB_SITE_URI + 'pag/' + v.id_content)
                                                    :
                                                        link = (v.slug !== undefined && v.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.slug : (v.link.substr(0, 4) !== 'http' ? Hosts.WEB_SITE_URI + v.link : v.link))

                                                    //let link = (v.slug !== undefined && v.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.slug : Hosts.WEB_SITE_URI + 'pag/' + v.id_content);
                                                    let current_top_check = (v.slug !== undefined && v.slug !== '' ? v.slug : (v.id_content !== '' ? v.id_content : v.link));

                                                    if (v.children !== undefined && v.children !== '' && v.children.length > 0) {
                                                        return (
                                                            <li className="nav-item dropdown" key={key}>
                                                                <a className="nav-link px-4 dropdown-toggle" href="/#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
                                                                    {v.title}
                                                                    <FontAwesomeIcon icon={faAngleDown} fixedWidth />
                                                                </a>
                                                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                                    {
                                                                        v.children.map((v2, key2) => {

                                                                            //console.log(v2)

                                                                            let link_content_dropdown_item = ''
                                                                            v2.id_content !== '' ?
                                                                                link_content_dropdown_item = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + '' + v2.slug : Hosts.WEB_SITE_URI + 'pag/' + v2.id_content)
                                                                            :
                                                                                link_content_dropdown_item = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + '' + v2.slug : Hosts.WEB_SITE_URI + v2.link)
                    

                                                                            //let link_content_dropdown_item = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + '' + v2.slug : Hosts.WEB_SITE_URI + 'pag/' + v2.id_content);
                                                                            let current_top_dropdown = (v2.slug !== undefined && v2.slug !== '' ? v2.slug : (v2.id_content !== '' ? v2.id_content : v2.link));

                                                                            return (
                                                                                v2.link.substr(0, 4) !== 'http' ?
                                                                                    <a key={key2} href={link_content_dropdown_item} className={"dropdown-item " + ((page_location !== '' && page_location === current_top_dropdown) || (page_location !== '' && location === '/' + current_top_dropdown) ? 'current_top' : '')}  >
                                                                                        {v2.title}
                                                                                    </a>
                                                                                :
                                                                                    <a key={key2} href={link_content_dropdown_item} className={"dropdown-item " + ((page_location !== '' && page_location === current_top_dropdown) || (page_location !== '' && location === '/' + current_top_dropdown) ? 'current_top' : '')} target="_blank" rel="noreferrer" >
                                                                                        {v2.title}
                                                                                    </a>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                    else {

                                                        return (
                                                            v.link.substr(0, 4) !== 'http' ?
                                                                <li className="nav-item" key={key} >
                                                                    <a href={link} className={"nav-link " + (page_location === current_top_check ? 'current_top' : '')} >
                                                                        {v.title}
                                                                    </a>
                                                                </li>
                                                            :
                                                                <li className="nav-item" key={key} >
                                                                    <a href={link} className={"nav-link " + (page_location === current_top_check ? 'current_top' : '')} target="_blank" rel="noreferrer" >
                                                                        {v.title}
                                                                    </a>
                                                                </li>
                                                            
                                                        )

                                                    }
                                                    
                                                    /*return (
                                                        <li className="nav-item" key={key}>
                                                            <Link activeClass="active" to={v.link} spy={true} smooth={true} offset={-150} duration={600}
                                                                className={"nav-link " + (page_location === 'scientia_magazine' ? 'current_top' : '')}>
                                                                {v.title}
                                                            </Link>
                                                        </li>
                                                    )*/
                                                })
                                        }

                                        {/*<div className="d-none">
                                            <li className="nav-item">
                                                <a className={"nav-link " + (page_location === 'scientia_magazine' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'scientia_magazine'}>{language[lang].scientia_magazine}</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className={"nav-link " + (page_location === 'rules' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'rules'}>{language[lang].submission_rules}</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className={"nav-link " + (page_location === 'submissions' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'submissions'}>{language[lang].articles_submission}</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className={"nav-link " + (page_location === 'search' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'search'}>{language[lang].search}</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className={"nav-link " + (page_location ===  'reviewers' ? 'current_top': '')} href={Hosts.WEB_SITE_URI + 'reviewers'}>Revisores</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className={"nav-link " + (page_location ===  'technical_team' ? 'current_top': '')} href={Hosts.WEB_SITE_URI + 'technical_team'}>{language[lang].technical_team}</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className={"nav-link " + (page_location === 'contacts' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'contacts'}>{language[lang].contacts}</a>
                                            </li>
                                             <li className="nav-item dropdown d-none">
                                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
                                                    Teste Dropdown
                                                    <FontAwesomeIcon className="mr-2" icon={faAngleDown} fixedWidth />
                                                </a>
                                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                    <a className="dropdown-item" href="#">Action</a>
                                                    <a className="dropdown-item" href="#">Another action</a>
                                                    <div className="dropdown-divider"></div>
                                                    <a className="dropdown-item" href="#">Something else here</a>
                                                </div>
                                            </li>
                                        </div>*/}
                                    </ul>

                                    {/*<a href={Hosts.WEB_SITE_URI + 'form_author'} className="btn btn-primary">Registo</a>*/}
                                    {/*<a href={Hosts.WEB_SITE_URI + 'form_reviewer'} className="btn btn-primary">Revisor</a>*/}
                                    <div className="overlay"></div>
                                </div>


                            </div>
                        </div>
                    </nav>

                : 
                    <nav className="navbar navbar-expand-none navbar-light py-0 px-0 nav-effect">
                        <div className="col-12">
                            <div className="row">
                                <div className="d-none d-md-block col-12 top-bar">
                                    <div className="row">
                                        <div className="col-12 col-lg-10 offset-lg-1 px-3 py-0 small d-flex flex-direction-row justify-content-between align-items-center">
                                            <div className=" d-none">
                                                <button className={"pr-2 button_no_border " + (lang === 'pt' ? 'current_lang' : '')} onClick={() => change_lang('pt')}>PT</button>
                                                &#124;
                                                <button className={"pr-2 button_no_border " + (lang === 'en' ? 'current_lang' : '')} onClick={() => change_lang('en')}>EN</button>
                                                &#124;
                                                <button className={"p-2 button_no_border " + (lang === 'es' ? 'current_lang' : '')} onClick={() => change_lang('es')}>ES</button>
                                                &#124;
                                                <button className={"p-2 button_no_border " + (lang === 'fr' ? 'current_lang' : '')} onClick={() => change_lang('fr')}>FR</button>
                                            </div>
                                            <div className="top-phone mr-3 p-2 d-none">
                                                <i className="bi bi-telephone"></i>
                                                <a href="tel:(+351)275094779" title="(+351) 275 094 779">(+351) 275 094 779</a> <small>({language[lang].chamada_rede_fixa})</small>
                                            </div>
                                            <div className="mr-2 mr-lg-3 p-2 small">
                                                <a /*className="text-body"*/ href={repo_site_documents_link('CENTRO-04-38D7-FEDER-001180.pdf')} /*style={{fontSize: '10px'}}*/ target="_blank" rel="noreferrer">
                                                    {language[lang].projeto_cofinanciado}
                                                </a>
                                            </div>
                                            <div className="mr-2 mr-lg-3 p-2 small d-none">
                                                {
                                                getTokenInfo() !== undefined && getTokenInfo() !== null && getTokenInfo().profile === 'Cliente' ? (
                                                    //'Carrinho | Perfil | Sair'
                                                    <>
                                                        <a href={Hosts.WEB_SITE_URI + 'store/tarifas'} className='mx-1'>
                                                            {language[lang].store}
                                                        </a>
                                                        |
                                                        <a href={Hosts.WEB_SITE_URI + 'store/cart'} className='mx-1'>
                                                            {language[lang].store_cart}
                                                        </a>
                                                        |
                                                        <a href={Hosts.WEB_BACKOFFICE_URI} className='mx-1'>
                                                            {language[lang].my_account}
                                                        </a>
                                                        |
                                                        <a href={Hosts.WEB_SITE_URI} className='mx-1' onClick={logout}>
                                                            {language[lang].logout}
                                                        </a>
                                                    </>
                                                )
                                                : 
                                                <>
                                                    <a href={Hosts.WEB_SITE_URI + 'store/tarifas'} className='mx-1'>
                                                        {language[lang].store}
                                                    </a>
                                                    <a href={Hosts.WEB_BACKOFFICE_URI} className='mx-1' onClick={logout}>
                                                        {language[lang].login}
                                                    </a>
                                                </>
                                                }
                                            </div>
                                            <div className="ml-3">
                                                {
                                                getTokenInfo() !== undefined && getTokenInfo() !== null && getTokenInfo().profile === 'Cliente' ? (
                                                    //'Carrinho | Perfil | Sair'
                                                    <>
                                                        <a href={Hosts.WEB_SITE_URI + 'store/tarifas'} className='mx-1'>
                                                            {language[lang].store}
                                                        </a>
                                                        |
                                                        <a href={Hosts.WEB_SITE_URI + 'store/cart'} className='mx-1'>
                                                            {language[lang].store_cart}
                                                        </a>
                                                        |
                                                        <a href={Hosts.WEB_BACKOFFICE_URI} className='mx-1'>
                                                            {language[lang].my_account}
                                                        </a>
                                                        |
                                                        <a href={Hosts.WEB_SITE_URI} className='mx-1' onClick={logout}>
                                                            {language[lang].logout}
                                                        </a>
                                                    </>
                                                )
                                                : 
                                                <>
                                                    <a href={Hosts.WEB_SITE_URI + 'store/tarifas'} className='mx-1'>
                                                        {language[lang].store}
                                                    </a>
                                                    <a href={Hosts.WEB_BACKOFFICE_URI} className='mx-1' onClick={logout}>
                                                        {language[lang].login}
                                                    </a>
                                                </>
                                                }

                                                <button className={"p-2 button_no_border " + (lang === 'pt' ? 'current_lang' : '')} onClick={() => change_lang('pt')}>PT</button>
                                                &#124;
                                                <button className={"p-2 button_no_border " + (lang === 'en' ? 'current_lang' : '')} onClick={() => change_lang('en')}>EN</button>
                                                &#124;
                                                <button className={"p-2 button_no_border " + (lang === 'es' ? 'current_lang' : '')} onClick={() => change_lang('es')}>ES</button>
                                                &#124;
                                                <button className={"p-2 button_no_border " + (lang === 'fr' ? 'current_lang' : '')} onClick={() => change_lang('fr')}>FR</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-block d-md-none col-12 top-bar top-bar-mobile">
                                    <div className="row">
                                        <div className="col-12 col-lg-10 offset-lg-1 px-3 py-0 small">
                                            <div className="float-left">
                                                <button className={"pr-2 button_no_border " + (lang === 'pt' ? 'current_lang' : '')} onClick={() => change_lang('pt')}>PT</button>
                                                &#124;
                                                <button className={"pr-2 button_no_border " + (lang === 'en' ? 'current_lang' : '')} onClick={() => change_lang('en')}>EN</button>
                                                &#124;
                                                <button className={"p-2 button_no_border " + (lang === 'es' ? 'current_lang' : '')} onClick={() => change_lang('es')}>ES</button>
                                                &#124;
                                                <button className={"p-2 button_no_border " + (lang === 'fr' ? 'current_lang' : '')} onClick={() => change_lang('fr')}>FR</button>
                                            </div>
                                            <div className="mr-2 mr-lg-3 p-2 small float-right">
                                                {
                                                getTokenInfo() !== undefined && getTokenInfo() !== null && getTokenInfo().profile === 'Cliente' ? (
                                                    //'Carrinho | Perfil | Sair'
                                                    <>
                                                        <a href={Hosts.WEB_SITE_URI + 'store/tarifas'} className='mx-1'>
                                                            {language[lang].store}
                                                        </a>
                                                        |
                                                        <a href={Hosts.WEB_SITE_URI + 'store/cart'} className='mx-1'>
                                                            {language[lang].store_cart}
                                                        </a>
                                                        |
                                                        <a href={Hosts.WEB_BACKOFFICE_URI} className='mx-1'>
                                                            {language[lang].my_account}
                                                        </a>
                                                        |
                                                        <a href={Hosts.WEB_SITE_URI} className='mx-1' onClick={logout}>
                                                            {language[lang].logout}
                                                        </a>
                                                    </>
                                                )
                                                : 
                                                <>
                                                    <a href={Hosts.WEB_SITE_URI + 'store/tarifas'} className='mx-1'>
                                                        {language[lang].store}
                                                    </a>
                                                    <a href={Hosts.WEB_BACKOFFICE_URI} className='mx-1' onClick={logout}>
                                                        {language[lang].login}
                                                    </a>
                                                </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center align-items-center">
                                        <div className="d-none mr-3">
                                            <button className={"p-2 button_no_border " + (lang === 'pt' ? 'current_lang' : '')} onClick={() => change_lang('pt')}>PT</button>
                                            &#124;
                                            <button className={"p-2 button_no_border " + (lang === 'en' ? 'current_lang' : '')} onClick={() => change_lang('en')}>EN</button>
                                            &#124;
                                            <button className={"p-2 button_no_border " + (lang === 'es' ? 'current_lang' : '')} onClick={() => change_lang('es')}>ES</button>
                                            &#124;
                                            <button className={"p-2 button_no_border " + (lang === 'fr' ? 'current_lang' : '')} onClick={() => change_lang('fr')}>FR</button>
                                        </div>
                                        <div className="top-phone mr-3 p-2 d-none">
                                            <i className="bi bi-telephone"></i>
                                            <a href="tel:(+351)275094779" title="(+351) 275 094 779">(+351) 275 094 779</a> <small>({language[lang].chamada_rede_fixa})</small>
                                        </div>
                                        <div className="p-2 small">
                                            <a /*className="text-body"*/ href={repo_site_documents_link('CENTRO-04-38D7-FEDER-001180.pdf')} /*style={{fontSize: '10px'}}*/ target="_blank" rel="noreferrer">
                                                {language[lang].projeto_cofinanciado}
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-xl-10 offset-xl-1 d-flex justify-content-between py-0">
                                    <a className="navbar-brand pl-2" href={Hosts.WEB_SITE_URI} alt={Hosts.webSiteTitle}>
                                        <img id="big-logo" className="svg-logo img-fluid" src={logoPath(mainLogo)} /*src={repo_logo_link("logo.svg")}*/ alt={Hosts.webSiteTitle} title={Hosts.webSiteTitle} />
                                    </a>
                                    <div className="d-flex navbar-expand-lg navbar-collapse">
                                        <ul className="navbar-nav ml-auto flex-row flex-wrap align-items-center">
                                            <li className="nav-item">
                                                <a className={"nav-link px-3 px-xl-2 " + (page_location === 'store/tarifas' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'store/tarifas'}>
                                                    <img src={repo_site_assets_link("tarifas.svg")} alt={language[lang].store} title={language[lang].store} className="img-fluid mr-2" style={{width: '20px', height: '20px'}}/>
                                                    {language[lang].store}
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className={"nav-link px-3 px-xl-2 " + (page_location === 'escola-de-ski' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'escola-de-ski'}>
                                                    <img src={repo_site_assets_link("escola.svg")} alt={language[lang].ski_school} title={language[lang].ski_school} className="img-fluid mr-2" style={{width: '20px', height: '20px'}}/>
                                                    {language[lang].ski_school}
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className={"nav-link px-3 px-xl-2 " + (page_location === 'mapa_pistas' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'mapa_pistas'}>
                                                    <img src={repo_site_assets_link("pistas.svg")} alt={language[lang].track_map} title={language[lang].track_map} className="img-fluid mr-2" style={{width: '20px', height: '20px'}}/>
                                                    {language[lang].track_map}
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link px-3 px-xl-2" href="https://www.meteoestrela.pt/previsoes/neve/" target="_blank" rel="noreferrer">
                                                    <img src={repo_site_assets_link("meteorologia.svg")} alt={language[lang].meteorology} title={language[lang].meteorology} className="img-fluid mr-2" style={{width: '20px', height: '20px'}}/>
                                                    {language[lang].meteorology}
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className={"nav-link px-3 px-xl-2 " + (page_location === 'livecam' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'livecam'}>
                                                    <img src={repo_site_assets_link("livecam.svg")} alt={language[lang].livecam} title={language[lang].livecam} className="img-fluid mr-2" style={{width: '20px', height: '20px'}}/>
                                                    {language[lang].livecam}
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className={"nav-link px-3 px-xl-2 " + (page_location === 'accesses' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'accesses'}>
                                                    <img src={repo_site_assets_link("acessos.svg")} alt={language[lang].accesses} title={language[lang].accesses} className="img-fluid mr-2" style={{width: '35px', height: '35px'}}/>
                                                    {language[lang].accesses}
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className={"nav-link px-3 px-xl-2 " + (page_location === 'perguntas-frequentes' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'perguntas-frequentes'}>
                                                    <img src={repo_site_assets_link("faq.svg")} alt={language[lang].faqs} title={language[lang].faqs} className="img-fluid mr-2" style={{width: '20px', height: '20px'}}/>
                                                    {language[lang].faqs}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <button className="navbar-toggler align-self-center float-right" type="button" onClick={changeLogo} data-toggle="collapse" data-target="#sideNavigationMenu" aria-controls="sideNavigationMenu" aria-expanded="false" aria-label="Toggle navigation">
                                        {/*<span className="navbar-toggler-icon"></span>*/}
                                        <div className="animated-icon3">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </button>
                                </div>
                                <div className="collapse navbar-collapse" id="sideNavigationMenu">

                                    <ul className="navbar-nav my-2">

                                        {
                                            props.mostrar_botao_voltar ?

                                                <li className="nav-item" /* key={key} */ >
                                                    <a href={Hosts.WEB_SITE_URI} className={"nav-link"}>
                                                        <FontAwesomeIcon className="mr-2" icon={faLongArrowAltLeft} />
                                                        {language[lang].back}
                                                    </a>
                                                </li>

                                                :

                                                navbar.content.childs.map((v, key) => {

                                                    // console.log(v)

                                                    let link = ''
                                                    v.id_content !== '' ?
                                                        link = (v.slug !== undefined && v.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.slug : Hosts.WEB_SITE_URI + 'pag/' + v.id_content)
                                                    :
                                                        link = (v.slug !== undefined && v.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.slug : (v.link.substr(0, 4) !== 'http' ? Hosts.WEB_SITE_URI + v.link : v.link))

                                                    let current_top_check = (v.slug !== undefined && v.slug !== '' ? v.slug : (v.id_content !== '' ? v.id_content : v.link));

                                                    if (v.children !== undefined && v.children !== '' && v.children.length > 0) {
                                                        
                                                        return (
                                                            <li className="nav-item dropdown" key={key}>
                                                                <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
                                                                    {v.title}
                                                                    <FontAwesomeIcon icon={faAngleDown} fixedWidth />
                                                                </a>
                                                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                                    {
                                                                        v.children.map((v2, key2) => {

                                                                            //console.log(v2)

                                                                            let link_content_dropdown_item = ''
                                                                            v2.id_content !== '' ?
                                                                                link_content_dropdown_item = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + '' + v2.slug : Hosts.WEB_SITE_URI + 'pag/' + v2.id_content)
                                                                            :
                                                                                link_content_dropdown_item = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + '' + v2.slug : (v2.link.substr(0, 4) !== 'http' ? Hosts.WEB_SITE_URI + v2.link : v2.link))
                    

                                                                            //let link_content_dropdown_item = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + '' + v2.slug : Hosts.WEB_SITE_URI + 'pag/' + v2.id_content);
                                                                            let current_top_dropdown = (v2.slug !== undefined && v2.slug !== '' ? v2.slug : (v2.id_content !== '' ? v2.id_content : v2.link));

                                                                            if(location === '/' + v2.link) {
                                                                                current_top_dropdown = v2.link
                                                                            }

                                                                            return (
                                                                                v2.link.substr(0, 4) !== 'http' ?
                                                                                    <a key={key2} href={link_content_dropdown_item} className={"dropdown-item " + ((page_location !== '' && page_location === current_top_dropdown) || (page_location !== '' && location === '/' + current_top_dropdown) ? 'current_top' : '')}  >
                                                                                        {v2.title}
                                                                                    </a>
                                                                                :
                                                                                    <a key={key2} href={link_content_dropdown_item} className={"dropdown-item " + ((page_location !== '' && page_location === current_top_dropdown) || (page_location !== '' && location === '/' + current_top_dropdown) ? 'current_top' : '')} target="_blank" rel="noreferrer" >
                                                                                        {v2.title}
                                                                                    </a>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                    else {

                                                        return (
                                                            v.link.substr(0, 4) !== 'http' ?
                                                                <li className="nav-item" key={key} >
                                                                    <a href={link} className={"nav-link " + (page_location === current_top_check ? 'current_top' : '')} >
                                                                        {v.title}
                                                                    </a>
                                                                </li>
                                                            :
                                                                <li className="nav-item" key={key} >
                                                                    <a href={link} className={"nav-link " + (page_location === current_top_check ? 'current_top' : '')} target="_blank" rel="noreferrer" >
                                                                        {v.title}
                                                                    </a>
                                                                </li>
                                                            
                                                        )

                                                    }
                                                })
                                        }

                                        <li className="nav-item d-block d-lg-none">
                                            <a className={"nav-link " + (page_location === 'store/tarifas' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'store/tarifas'}>
                                                <img src={repo_site_assets_link("tarifas.svg")} alt={language[lang].store} title={language[lang].store} className="img-fluid mr-2 d-none" style={{width: '20px', height: '20px'}}/>
                                                {language[lang].store}
                                            </a>
                                        </li>

                                        <li className="nav-item d-block d-lg-none">
                                            <a className={"nav-link " + (page_location === 'mapa_pistas' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'mapa_pistas'}>
                                                <img src={repo_site_assets_link("pistas.svg")} alt={language[lang].track_map} title={language[lang].track_map} className="img-fluid mr-2 d-none" style={{width: '15px', height: '15px'}}/>
                                                {language[lang].track_map}
                                            </a>
                                        </li>

                                        <li className="nav-item d-block d-lg-none">
                                            <a className="nav-link" href="https://www.meteoestrela.pt/previsoes/neve/" target="_blank" rel="noreferrer">
                                                <img src={repo_site_assets_link("meteorologia.svg")} alt={language[lang].meteorology} title={language[lang].meteorology} className="img-fluid mr-2 d-none" style={{width: '15px', height: '15px'}}/>
                                                {language[lang].meteorology}
                                            </a>
                                        </li>
                                        <li className="nav-item d-block d-lg-none">
                                            <a className={"nav-link " + (page_location === 'livecam' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'livecam'}>
                                                <img src={repo_site_assets_link("livecam.svg")} alt={language[lang].livecam} title={language[lang].livecam} className="img-fluid mr-2 d-none" style={{width: '15px', height: '15px'}}/>
                                                {language[lang].livecam}
                                            </a>
                                        </li>
                                        <li className="nav-item d-block d-lg-none">
                                            <a className={"nav-link " + (page_location === 'accesses' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'accesses'}>
                                            <img src={repo_site_assets_link("acessos.svg")} alt={language[lang].accesses} title={language[lang].accesses} className="img-fluid mr-2 d-none" style={{width: '25px', height: '25px'}}/>
                                                {language[lang].accesses}
                                            </a>
                                        </li>
                                        <li className="nav-item d-block d-lg-none">
                                            <a className={"nav-link " + (page_location === 'perguntas-frequentes' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'perguntas-frequentes'}>
                                            <img src={repo_site_assets_link("acessos.svg")} alt={language[lang].faqs} title={language[lang].faqs} className="img-fluid mr-2 d-none" style={{width: '25px', height: '25px'}}/>
                                                {language[lang].faqs}
                                            </a>
                                        </li>
                                        <li className="nav-item dropdown d-none">
                                            <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
                                                Teste Dropdown
                                                <FontAwesomeIcon className="mr-2" icon={faAngleDown} fixedWidth />
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <a className="dropdown-item" href="/#">Action</a>
                                                <a className="dropdown-item" href="/#">Another action</a>
                                                <div className="dropdown-divider"></div>
                                                <a className="dropdown-item" href="/#">Something else here</a>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul className="navbar-nav social-media my-5 text-center">
                                        <li className="nav-item">
                                            <a className="nav-link d-inline-block mr-2" href={Hosts.fbLink} title="Facebook" alt="Facebook" target="_blank" rel="noreferrer">
                                                <img src={repo_site_assets_link("facebook.svg")} title="Facebook" alt="Facebook" className="img-fluid"/>
                                            </a>
                                            <a className="nav-link d-inline-block" href={Hosts.instaLink} title="Instagram" alt="Instagram" target="_blank" rel="noreferrer">
                                                <img src={repo_site_assets_link("instagram.svg")} title="Instagram" alt="Instagram" className="img-fluid"/>
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="overlay d-none"></div>
                                </div>


                            </div>
                        </div>
                    </nav>

                }
            </section >
        </div >
    )
};
export default Navbar;