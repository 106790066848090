import React, { useState, useEffect } from 'react';


import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';
/* import { Helmet } from 'react-helmet'; */

import * as Hosts from '../../../Hosts';
import { reqGET } from '../../../Utils';

import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Loader from '../../../loader';
import Breadcrumb from '../Breadcrumb/Breadcrumb';

export default function Tabs(props) {

    const [isloading, setLoading] = useState(true);

    const data_interface = {
        id: '',
        info: {},
        content: {}
    }
    const [content, set_content] = useState(data_interface);
    const [/*gallery*/, set_gallery] = useState([]);

    useEffect(() => {
        try {
			let id = props.info.state.id;
			let slug = props.info.state.slug;

            if(id !== '') {
        
                let getInfoId = async () => {
                    await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${id}`)
                        .then(res => { 
                            if(res.data.length > 0) {
        
                                var hoje = new Date();
                                let status = 0
                                if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                    var date_p = new Date(res.data[0].info.date.published);
        
                                    status = 1
                                    if(hoje >= date_p) 
                                        status = 2
                                }
        
                                if(status !== 2) {
                                    res.data[0].content = {text: 'rascunho ou agendado'}
        
                                    set_content(res.data[0])
                                    setLoading(false)    
                                }
                                else {
                                    set_content(res.data[0]) 
        
                                    if(res.data[0].content['gallery'] !== undefined && res.data[0].content['gallery'] !== '') {
                                        reqGET(`${Hosts.SIMCore_API}/gallery/${Hosts.companycode}/${res.data[0].content['gallery']}`)
                                            .then(res => {
                                                set_gallery(res.data[0].info.media);
                                            })
                                            .catch(error => console.log(error)/*TODO: GO TO FAIL FETCH*/)
                                    }
            
                                    setLoading(false) 
                                }
                            }
                        })
                        .catch(erro => console.log('ERROR', erro))
                }
            
                let getInfoSlug = async () => {
                    reqGET(`${Hosts.SIMCore_API}/web/content/slug/${Hosts.companycode}/${slug}`)
                        .then(res => { 
                            if(res.data.length > 0) {
        
                                var hoje = new Date();
                                let status = 0
                                if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                    var date_p = new Date(res.data[0].info.date.published);
        
                                    status = 1
                                    if(hoje >= date_p) 
                                        status = 2
                                }
        
                                if(status !== 2)  {
                                    res.data[0].content = [{text: 'rascunho ou agendado'}]
        
                                    set_content(res.data[0])
                                    setLoading(false)
                                }
                                else {
                                    if(res.data[0].content['gallery'] !== undefined && res.data[0].content['gallery'] !== '') {
                                        reqGET(`${Hosts.SIMCore_API}/gallery/${Hosts.companycode}/${res.data[0].content['gallery']}`)
                                            .then(res => {
                                                set_gallery(res.data[0].info.media);
                                            })
                                            .catch(error => console.log(error)/*TODO: GO TO FAIL FETCH*/)
                                    }
        
                                    set_content(res.data[0]) 
        
                                    setLoading(false) 
                                }
                            }
                        })
                        .catch(erro => console.log('ERROR', erro))
                }
        
                if(id !== undefined && id !== 'undefined' && id !== '') {
                    getInfoId()
                }
                else if(slug !== undefined && slug !== 'undefined' && slug !== '')  {
                    getInfoSlug()
                }
            }
            else {
                console.log('=== ""')
            }

            return

        } catch {
            console.log('nada')

			return
		}
        
    }, [props]);

    return (isloading ?

		<Loader />
		
		:
        <>
            <Navbar />
            <Breadcrumb />
            <main className="tab">
                <div>
                    {/* <Helmet>
                        <title>{content.info.seo !== undefined && content.info.seo['title'] !== undefined ? content.info.seo['title'] : Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_TITLE}</title>
                        <meta name="description" content={content.info.seo !== undefined && content.info.seo['description'] !== undefined ? content.info.seo['description'] : Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_DESCRIPTION} />
                        <meta name="keywords" content={content.info.seo !== undefined && content.info.seo['keywords'] !== undefined ? content.info.seo['keywords'] : Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_KEYWORDS} />
                    </Helmet> */}
                </div>

                <section className="content-body">
                    <div className="container">
                        <div className="row">
                        {
                        content.content.map((el, k) => {  
                            return (
                                k === 0 ?
                                    <div key={k} className="col">
                                        {
                                        el.media !== undefined && el.media['link'] !== undefined && el.media['link'] !== '' ?
                                            <>
                                                <div className="div-content col-12 col-xl-6 p-5 vh-100">
                                                    <h2 className={el.title !== '' ? "titulo-primario mb-5" : "d-none"}>{el.title}</h2>
                                                    <div className={"div-text"}>
                                                        {ReactHtmlParser(el.text)}
                                                    </div>
                                                </div>

                                                <div className="div-orange-image position-absolute bottom-0 d-none d-xl-block"></div>

                                                <div className="div-image col-12 col-xl-6 text-center">
                                                    <img src={el.media['link']} className="img-fluid ms-5" alt="" />
                                                </div>

                                                <section className="div-scroll mt-5">
                                                    <div>
                                                        <span></span>
                                                        <p className="small mt-n2">Scroll</p>
                                                    </div>
                                                </section>
                                            </>
                                        : 
                                            <>
                                                <div id="div-content" className="div-content col-12 mb-5">
                                                    <h2 className={el.title !== '' ? "titulo-primario mb-5" : "d-none"}>{el.title}</h2>
                                                    <div className="div-text">
                                                        {ReactHtmlParser(el.text)}
                                                    </div>
                                                </div>
                                            </>
                                        }

                                        {
                                        el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 0 ?
                                            <div className="div-content col-12 mb-5">
                                                <div className="row div-gallery">
                                                    {
                                                    el.media.image.map((el_img, k_img) => {
                                                        return (
                                                            <div key={k_img} className="col-2">
                                                                <img className="img-fluid" src={el_img.link} alt="" />
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </div>
                                            </div>
                                        :
                                            <></>
                                        }
                                        {
                                        el['media'] !== undefined && el['media']['doc'] !== undefined && el.media.doc.length > 0 ?
                                            <div className="div-content col-12 mb-5">
                                                <div className="row div-documents">
                                                    {
                                                    el.media.doc.map((el_doc, k_doc) => {
                                                        return (
                                                            <div key={k_doc} className="mb-2">
                                                                <img className="img-fluid" src={el_doc.link} alt="" />
                                                                <a className="btn btn-primary" href={el_doc.link} target="_blank" rel="noreferrer">{el_doc.name}</a>
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </div>
                                            </div>
                                        :
                                            <></>
                                        }
                                    </div>
                                :
                                    <></>
                            )
                        })
                        }
                        </div>
                    </div>
                </section>

                <section className="content-body content-tab">
                    <div className="container">
                        <ul className="nav nav-tabs">
                            {
                            content.content.map((el, k) => {  
                                return (
                                    <div key={k}>
                                    {k > 0 ?
                                        <li className="nav-item">
                                            <a className={"nav-link" + (k === 1 ? " active" : "")} data-toggle="tab" href={"#tab_pane_content_" + k}>{el.title}</a>
                                        </li>
                                        
                                    :
                                        ''
                                    }
                                    </div>
                                )
                            })
                            }
                        </ul>

                        <div className="tab-content">
                            {
                            content.content.map((el, k) => {  
                                return (
                                    <div key={k} className={"tab-pane" + (k === 1 ? " show active" : '')} id={"tab_pane_content_" + k} role="tabpanel">
                                        <div className="row mb-5">
                                           
                                                {
                                                el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 0 ?
                                                    <div className="mt-3">
                                                        <div className="col col-image">
                                                            <img src={el.media.image[0].link} alt={el['media']['image'][0]['name']} title={el['media']['image'][0]['name']} />
                                                        </div>
                                                        <div className="col col-text">
                                                            {ReactHtmlParser(el.text)}
                                                        </div>
                                                    </div>
                                                :
                                                    <div className="col-12 col-text mt-3">
                                                        {ReactHtmlParser(el.text)}
                                                    </div>
                                                }

                                            
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>

                    </div>
                </section>

            </main>
            <Footer />
        </>
    )
}