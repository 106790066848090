import React, { useState, useEffect } from 'react';
//import {useLocation } from 'react-router-dom';

import "../Pagina.css";

//import parse from 'html-react-parser';

//import { language } from '../WEBContent/Language';

// get our fontawesome imports
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
    set_language,

} from '../../../Utils';

//import CookieConsent from "react-cookie-consent";

//import * as Hosts from "../../../Hosts";


const GoTopButton = () => {

    const [/*lang*/, set_lang] = useState('pt');

    const [ showGoTop, setShowGoTop ] = useState( false )

    const handleVisibleButton = () => {
        setShowGoTop( window.pageYOffset > 50 )
    }
    
    const handleScrollUp = () => {
        window.scrollTo( { left: 0, top: 0, behavior: 'smooth' } )
    }

    useEffect(() => {

        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
        }
        else {
            set_lang(localStorage.getItem('lang'))
        }

        window.addEventListener( 'scroll', handleVisibleButton )

    }, [])

    return (

        <div className={ showGoTop ? 'scroll-top-wrapper show' : 'scroll-top-wrapper' } onClick={ handleScrollUp }>
            {/*<button type={'button'} className="btn btn-primary">
                <FontAwesomeIcon icon={faArrowUp} />
            </button>*/}
            <span>
                <FontAwesomeIcon icon={faArrowUp} size="lg" fixedWidth/>
            </span>
        </div>
    )
};
export default GoTopButton;