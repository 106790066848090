import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import Navbar from '../Navbar/Navbar';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import Footer from "../Footer/Footer";
import Loader from '../../../loader';
// import './PaymentNotificationPageApp.css';

import * as Hosts from '../../../Hosts';
import {
    set_language,
    reqGET,
    // repo_logo_link,
    repo_site_assets_link,
    content_language

} from '../../../Utils';


import { language } from '../WEBContent/Language';


export default function PaymentNotificationPageApp()  {

    const { key } = useParams();
    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState('pt');
    const [, set_lang_id] = useState('1');
    const [data, setData] = useState([]);  

    

    useEffect(() => { 
        let getInfo = async () => {

            reqGET(`${Hosts.SIMCore_API}/store/order/payment/${Hosts.companycode}/${key}`)
                .then(res => { 
    
                    if(res.data.length > 0) {
    
                        setData(res.data[0]);
                        setLoading(false)
    
                    }
                })
                .catch(erro => alert('Erro', erro))  
        }
        getInfo()

        if(localStorage.getItem('lang') === undefined  || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if(el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }

        setTimeout(() => {
            var element = document.getElementsByClassName("containerCookie");
            if(element !== undefined && element[0] !== undefined) {
                element[0].className += ' d-none'
            }
        }, 50);
    
    }, [key]); 

    return (isloading ?

		<Loader />

		:
        <>
            {
            data.info['origin'] !== undefined && data.info['origin'] === 'web' ?
                <Navbar />
            :
                null
            }
            {
            data.info['origin'] !== undefined && data.info['origin'] === 'web' ?
                <Breadcrumb />
            :
                null
            }
            <main className="payment-notification" style={{position: 'relative', height: '100vh'}}>
                <div className="col-12 text-center mb-5 mt-5" style={{position: 'absolute', left: '50%', top: '45%', transform: 'translate(-50%, -50%)' }}>
                    <div className="mb-5 mt-5">
                        <div className="mb-5 mt-5">
                            <div className="mb-5 mt-5">
                                <img id="big-logo" className="svg-logo img-fluid" src={(data.info.pagamento_pendente === '0' || data.info.pagamento_pendente === 0) ? repo_site_assets_link("app-forfait-wait.png") : ((data.info.pagamento_pendente === '-1' || data.info.pagamento_pendente === -1) ? repo_site_assets_link("app-forfait-red.png") : repo_site_assets_link("app-forfait-green.png") ) } alt={Hosts.webSiteTitle} title={Hosts.webSiteTitle} />
                            </div>
                            <h2>{(data.info.pagamento_pendente === '0' || data.info.pagamento_pendente === 0) ? language[lang].payment_pendente : ((data.info.pagamento_pendente === '-1' || data.info.pagamento_pendente === -1) ? language[lang].payment_failed : language[lang].payment_success ) }</h2>
                            <p className="d-none">
                                <a href="./" title="Home">{language[lang].not_found3}</a>
                            </p>
                        </div>
                    </div>
                </div>

            </main>
            {
            data.info['origin'] !== undefined && data.info['origin'] === 'web' ?
                <Footer />
            :
                null
            }
        </>
    )

};
