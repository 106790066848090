import React, { useState, useEffect } from 'react';


import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';
//import { Helmet } from 'react-helmet';

import * as Hosts from '../../../Hosts';
import { reqGET, content_language, set_language } from '../../../Utils';

import Loader from '../../../loader';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { language } from '../WEBContent/Language';

export default function NewsCards(props) {

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState('pt');
    const [/*lang_id*/, set_lang_id] = useState('1');

    /*const data_interface = {
        id: '',
        info: {},
        content: {}
    }*/
    //const [content, set_content] = useState(data_interface);
    const [list_content, set_list_content] = useState([]);

    useEffect(() => {
        /* console.log('aaaa') */
        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
                }
            })
            }

        try {
            let getInfoList = async () => {
                await reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/news`)
                    .then(res => { 
                        if (res.data.length > 0) {

                            var hoje = new Date();
                            let aux = []
                            res.data.forEach((v) => {
                              if(v.info.date.published !== undefined && v.info.date.published !== '') {
                                  var date_p = new Date(v.info.date.published);
                    
                                  if(hoje >= date_p) 
                                    aux.push(v)
                              }
                            })
                            set_list_content(aux)
                            //set_list_content(res.data)
                            //console.log('NOTICIAS', res.data)
                  
                            setLoading(false)
                          }
                    })
                    .catch(erro => console.log('ERROR', erro))
            }
            getInfoList()
 
            return

        } catch {
            console.log('nada')

			return
		}
        
    }, []);

    return (isloading ?

		<Loader />
		
		:
        <>
            <Navbar />
            <Breadcrumb />
            <main className="news-cards">
                <section className="content-body my-5">
                    <div className="container">
                        <div className="row">
                            {
                            list_content.map((content) => {
                                return (
                                    content.content.map((el, k) => {  
                                        return (
                                            <div key={k} className="col-4">
                                                {
                                                el['media'] !== undefined && el['media']['image'] !== undefined && el['media']['image'].length > 0 ?
                                                    <>
                                                        <div className="div-card card col-12 mb-3">
                                                            <div className="row">
                                                                <div className={"div-image"} style={{ 
                                                                    backgroundImage: `url(` + el['media']['image'][0]['link']+ `)`,
                                                                    backgroundSize: `cover`,
                                                                    backgroundPosition: `center center`,
                                                                    width: `100%`,
                                                                    height: `220px`
                                                                    }}>
                                                                    {/* <img src={el['media']['image'][0]['link']} className="img-fluid" alt="" /> */}
                                                                </div>
                                                            </div>
                
                                                            <div className={"div-title px-3 mt-3"}>
                                                                <h2 className={el.title !== '' ? "subtitulo-secundario" : "d-none"}>{el.title}</h2>
                                                            </div>
                
                                                            <div className="div-categories px-3">
                                                                {
                                                                content.info.tags.map((v, k) => {
                                                                    return (
                                                                        <span key={k} className="badge rounded-pill bg-color-gray-light border-blue mr-1">{v.text}</span>
                                                                    )
                                                                })
                                                                }
                                                            </div>
                
                                                            <div className={"div-abstract px-3 mt-3"}>
                                                                {ReactHtmlParser(el.abstract)}
                                                            </div>

                                                            <div className={"div-more px-3 my-3 text-right"}>
                                                                {/* <a className={"btn btn-sm btn-outline-secondary"} href={'./article/' + content.id}>{'ver +'}</a> */}
                                                                <a href={'./article/' + content.id}>
                                                                    {language[lang].see_more}
                                                                    <FontAwesomeIcon className="ml-2" icon={faLongArrowAltRight} />
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </>
                                                : 
                                                    <>
                                                        <div id="div-card" className="div-content col-12 mb-5">
                                                            <div className={"div-title"}>
                                                                <h2 className={el.title !== '' ? "title" : "d-none"}>{el.title}</h2>
                                                            </div>
                
                                                            <div className="div-categories">
                                                                {
                                                                content.info.tags.map((v, k) => {
                                                                    return (
                                                                        <span key={k} className="badge rounded-pill bg-color-gray-light border-blue mr-1">{v.text}</span>
                                                                    )
                                                                })
                                                                }
                                                            </div>
                
                                                            <div className={"div-abstract px-3 mt-5"}>
                                                                {ReactHtmlParser(el.abstract)}
                                                            </div>

                                                            <div className={"div-more px-3 mt-5 mb-3"}>
                                                                <a className={"btn btn-sm btn-outline-secondary"} href={'./article/' + content.id}>{'ver +'}</a>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </div>                                                
                                        )
                                    })
                                )
                            })
                            }
                    </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}