import React, { useState, useEffect } from 'react';

//import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
//import Tooltip from "react-bootstrap/Tooltip";

import { Tooltip  } from 'react-tooltip';

import * as Hosts from '../../../Hosts';

import {
    set_language,
	reqGET,
    content_language,
} from '../../../Utils';

import { language } from '../WEBContent/Language';

import Loader from '../../../loader';

const Mapa = () => {

    const [dataPistas, setDataPistas] = useState([])

    const [dataMeiosMecanicos, setDataMeiosMecanicos] = useState([])

    const [lang, set_lang] = useState('pt');
    const [lang_id, set_lang_id] = useState('1');

    const [isloading, setLoading] = useState(true);

    let getInfoPistas = async () => {

        reqGET(`${Hosts.SIMCore_API}/estancia/pista/${Hosts.companycode}`)
            .then(res => { 

                //console.log('Todas as pistas: ', res.data)

                reqGET(`${Hosts.SIMCore_API}/estancia/config/${Hosts.companycode}/1`)
                    .then(res2 => { 

                        let aTracks = [];

                        res.data.forEach(v => { 

                            let aux_state = res2.data[0].info.pistas.filter(v2 => v2.id === v.id)

                            if(v.info.settings.estado === undefined)
                                v.info.settings.estado = ''

                            v.info.settings.estado = aux_state[0].state

                            //console.log('estado: ', v.info.settings.estado)
                            //console.log(v)

                            aTracks.push(v)

                        })

                        setDataPistas(aTracks)
                        //setDataPistas(res.data)
                        setLoading(false)
                    })
                    .catch(erro => console.log(erro))  

            })
            .catch(erro => console.log(erro))  

    }

    let getInfoMeiosMecanicos = async () => {

        reqGET(`${Hosts.SIMCore_API}/estancia/meio/${Hosts.companycode}`)
            .then(res => { 

                //console.log('Todos os meios mecânicos: ', res.data)

                reqGET(`${Hosts.SIMCore_API}/estancia/config/${Hosts.companycode}/1`)
                    .then(res2 => { 

                        let aMeiosMecanicos = [];

                        res.data.forEach(v => { 

                            let aux_state = res2.data[0].info.meios.filter(v2 => v2.id === v.id)

                            if(v.info.settings.estado === undefined)
                                v.info.settings.estado = ''

                            v.info.settings.estado = aux_state[0].state

                            //console.log('estado: ', v.info.settings.estado)
                            //console.log(v)

                            aMeiosMecanicos.push(v)

                        })

                        setDataMeiosMecanicos(aMeiosMecanicos)
                        //setDataPistas(res.data)
                        setLoading(false)
                    })
                    .catch(erro => console.log(erro))  

            })
            .catch(erro => console.log(erro))  

    }

    useEffect(() => { 
        getInfoPistas()
        getInfoMeiosMecanicos()

        if(localStorage.getItem('lang') === undefined  || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if(el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }

        //ReactTooltip.rebuild()
    
    }, []); 

    /* const renderTooltip = props => (
        <Tooltip id="tooltip-2" {...props}>
            Tooltip for the register button
        </Tooltip>
    ); */

    const tooltipInfoPistas = (id_pista) => {

        //console.log('Pistas:', dataPistas)
       // console.log('id_pista: ', id_pista)

       let aux_pista = dataPistas.filter(v2 => v2.id === id_pista)

       if(aux_pista.length > 0 ) {

            return ( 

                <div>
                    <h4 className='font-weight-bold'>
                        {/*aux_pista[0].info.name*/}
                        {lang === 'pt' || aux_pista[0].info.language === undefined ? aux_pista[0].info.name : (aux_pista[0].info.language[lang_id] === undefined || aux_pista[0].info.language[lang_id].name === undefined || aux_pista[0].info.language[lang_id].name === '' ? aux_pista[0].info.name : aux_pista[0].info.language[lang_id].name)}
                    </h4>
                    <div>{language[lang].length}&#58;&nbsp;{aux_pista[0].info.settings.comprimento}&nbsp;&#109;</div>
                    <div>{language[lang].unevenness}&#58;&nbsp;{aux_pista[0].info.settings.desnivel}&nbsp;&#109;</div>
                    <div>{language[lang].difficulty}&#58;&nbsp;{language[lang][aux_pista[0].info.settings.dificuldade]}</div>
                    <div>{language[lang].state}&#58;&nbsp;{aux_pista[0].info.settings.estado ? language[lang].open : language[lang].closed }</div>
                </div>

            )
        }
		
	}

    const tooltipInfoMeiosMecanicos = (id_meiomecanicos) => {

        //console.log('Meios Mecânicos:', dataMeiosMecanicos)
       // console.log('id_meio: ', id_meiomecanicos)

       let aux_meiosmecanicos = dataMeiosMecanicos.filter(v2 => v2.id === id_meiomecanicos)

       if(aux_meiosmecanicos.length > 0) {

            return ( 

                <div>
                    <h4 className='font-weight-bold'>
                        {lang === 'pt' || aux_meiosmecanicos[0].info.language === undefined ? aux_meiosmecanicos[0].info.name : (aux_meiosmecanicos[0].info.language[lang_id] === undefined || aux_meiosmecanicos[0].info.language[lang_id].name === undefined || aux_meiosmecanicos[0].info.language[lang_id].name === '' ? aux_meiosmecanicos[0].info.name : aux_meiosmecanicos[0].info.language[lang_id].name)}
                    </h4>
                    <div>{language[lang].seats}&#58;&nbsp;{aux_meiosmecanicos[0].info.settings.capacidade}</div>
                    <div>{language[lang].unevenness}&#58;&nbsp;{aux_meiosmecanicos[0].info.settings.desnivel}&nbsp;&#109;</div>
                    <div>{language[lang].velocity}&#58;&nbsp;{aux_meiosmecanicos[0].info.settings.por_hora}{language[lang].px_hour}</div>
                    <div>{language[lang].state}&#58;&nbsp;{aux_meiosmecanicos[0].info.settings.estado ? language[lang].open : language[lang].closed }</div>
                </div>

            )
        }
		
	}

    

    return (

        isloading ?

            <Loader />

		:
        
        <div className="container div-mapa-svg w-100 position-relative d-none d-md-block">
            <svg version="1.1" x="0px" y="0px"
                width="1689.346px" height="812.794px" viewBox="0 0 1689.346 812.794" enableBackground="new 0 0 1689.346 812.794">
                <g id="Fundo_1_">
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#EAEAEA" points="1131.816,95.002 1000.319,108.494 947.622,120.688 
                        1688.152,368.003 1690.84,111.539 1436.007,109.586 1420.382,112.568 1415.99,112.934 1411.627,113.102 1404.689,113.08 
                        1397.573,112.891 1390.334,112.564 1383.037,112.135 1375.731,111.627 1368.484,111.08 1361.35,110.521 1354.384,109.979 
                        1351.188,109.688 1341.886,108.494 1332.642,106.988 1323.427,105.318 1314.231,103.627 1305.012,102.063 1295.768,100.768 
                        1286.466,99.881 1281.79,99.641 1277.085,99.557 1245.815,99.557 1230.134,98.463 1217.733,97.6 1209.695,97.037 1202.397,96.527 
                        1195.816,96.068 1170.068,94.389 	"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#EAEAEA" points="1688.152,617.101 1538.479,424.407 1463.194,384.075 
                        1432.72,334.782 1417.485,350.019 1375.361,319.546 1309.935,256.808 1283.825,265.831 1263.927,221.854 1244.806,218.271 
                        1229.273,159.712 1308.142,229.024 1410.912,262.483 1433.618,244.558 1496.952,295.942 1555.506,273.239 1689.346,397.521 	"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M-40.177,136.686c24.902-3.118,218.289-48.128,218.289-48.128
                        l32.861,3.586l115.916-25.096l43.618,13.146l114.121-21.51l78.276,13.74L733.66,44.078l59.928,20.244V52.107
                        c0,0,0.4-10.818,12.818-10.818s12.418,12.219,12.418,12.219s9.413-1.002,9.813,5.809c0-5.609-1.402-20.031,13.22-20.031
                        c14.621,0,13.619,14.223,13.619,14.223l-0.199,16.623l2.281,7.643c0,0,9.35,1.232,15.5,2.006c6.148,0.77,15.129,5.654,15.129,5.654
                        l41.756-10.27l84.564,26.908l18.625-6.238l46.01,14.939l42.994-4.09l30.498,11.26l11.352-4.184l21.51,8.367l20.314-2.391
                        l14.34,19.716l64.529,13.146l19.717-12.547l19.498,11.561l22.926-6.184c0,0,31.51,24.977,54.373,35.055
                        c22.863,10.084,287.663,50.118,297.19,50.862l2.976,576.778H-40.177V136.686z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#CECECE" d="M1030.28,104.855l13.692,2.332l97.05-10.703l64.933,9.768
                        l56.957-3.492l32.69,17.125l62.313,10.658c0,0-44.133-1.873-48.398,0.896l-15.235,10.755l21.51,13.443l69.784-17.309l-6.15,19.998
                        l41.228-30.474l29.576,17.028l20.188-33.819c0,0,214.084,0.473,218.214,0.477v-3.379c-19.219-1.021-254.535-8.986-254.535-8.986
                        l-44.813,12.549l-40.331-14.342l-82.454-10.754l-60.707,9.203c-23.256,0-67.457-9.203-67.457-9.203l-78.63,10.947l-11.653-1.748
                        L1030.28,104.855z"/>
                    <polygon fill="#EAEAEA" points="964.879,281.903 1020.447,220.062 1071.533,298.933 1026.721,281.007 1015.965,306.103 
                        992.664,281.903 	"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#CECECE" d="M-2.773,219.464l99.187,17.924l45.408-31.07l43.021,11.953
                        l31.069-26.293l31.071,7.17c0,0,98.629-27.863,125.163-36.365c84.819-27.181,162.062-42.628,221.575-55.278
                        C615.57,102.857,688.569,89,715.652,88.783c0,0,1.237,7.416,23.047,12.244c15.905,3.514,27.727,2.24,37.993,0.85
                        c10.947-1.479,31.445-10.455,24.945-16.951c-6.502-6.506-32.557-8.852-39.041-9.348c-8.659-0.67-30.623,1.379-39.291,3.547
                        c-8.667,2.166-41.689,4.455-54.603,6.332c-65.81,9.545-196.613,30.785-217.874,34.975c-4.13,0.816-33.979,5.23-55.709,8.525
                        l-195.304,27.212l-201.394,33.42L-2.773,219.464z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M279.552,145.503c-0.268-0.168-0.35-0.52-0.181-0.789
                        c0.163-0.266,0.517-0.346,0.783-0.182l15.579,9.723c0.268,0.164,0.348,0.514,0.184,0.785c-0.166,0.266-0.517,0.346-0.784,0.182
                        L279.552,145.503z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M265.242,147.487c-0.264-0.172-0.34-0.529-0.164-0.791
                        c0.171-0.264,0.523-0.336,0.788-0.164l16.854,11.041c0.263,0.172,0.338,0.529,0.165,0.791c-0.17,0.264-0.525,0.336-0.789,0.164
                        L265.242,147.487z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M249.199,149.706c-0.256-0.184-0.312-0.543-0.128-0.799
                        c0.182-0.254,0.539-0.314,0.795-0.131l16.073,11.518c0.255,0.182,0.315,0.543,0.133,0.799c-0.184,0.256-0.543,0.314-0.8,0.129
                        L249.199,149.706z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M234.551,151.737c-0.251-0.189-0.304-0.549-0.11-0.801
                        c0.187-0.25,0.546-0.299,0.796-0.109l16.575,12.498c0.251,0.189,0.3,0.547,0.112,0.799c-0.19,0.248-0.546,0.301-0.797,0.111
                        L234.551,151.737z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M218.357,153.981c-0.241-0.199-0.281-0.557-0.084-0.803
                        c0.196-0.244,0.557-0.283,0.8-0.086l15.849,12.789c0.247,0.195,0.282,0.557,0.088,0.801s-0.557,0.283-0.802,0.092L218.357,153.981z
                        "/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M203.681,157.196c-0.235-0.207-0.259-0.568-0.051-0.803
                        c0.204-0.238,0.565-0.264,0.8-0.055l15.095,13.223c0.239,0.205,0.262,0.566,0.054,0.805c-0.206,0.234-0.567,0.258-0.803,0.053
                        L203.681,157.196z"/>
                    <g>
                        <g>
                            <path fill="#E2E2E2" d="M481.465,472.471l-0.503-1.721l5.162-1.509l0.503,1.721L481.465,472.471L481.465,472.471z
                                M491.788,469.453l-0.503-1.721l5.161-1.509l0.503,1.721L491.788,469.453L491.788,469.453z M502.111,466.436l-0.503-1.721
                                l5.162-1.509l0.503,1.721L502.111,466.436L502.111,466.436z M512.434,463.419l-0.503-1.721l5.161-1.509l0.503,1.721
                                L512.434,463.419L512.434,463.419z M522.757,460.401l-0.503-1.721l5.162-1.509l0.503,1.721L522.757,460.401L522.757,460.401z
                                M533.08,457.384l-0.503-1.721l5.162-1.509l0.503,1.721L533.08,457.384L533.08,457.384z M543.403,454.366l-0.503-1.721
                                l5.162-1.509l0.503,1.721L543.403,454.366L543.403,454.366z M553.726,451.349l-0.503-1.721l5.162-1.509l0.503,1.721
                                L553.726,451.349L553.726,451.349z M564.049,448.331l-0.503-1.721l5.162-1.509l0.503,1.721L564.049,448.331L564.049,448.331z
                                M574.372,445.314l-0.503-1.721l5.162-1.509l0.503,1.721L574.372,445.314L574.372,445.314z M584.695,442.297l-0.503-1.721
                                l5.161-1.509l0.503,1.721L584.695,442.297L584.695,442.297z M595.018,439.279l-0.503-1.721l5.162-1.509l0.503,1.721
                                L595.018,439.279L595.018,439.279z M605.341,436.262l-0.503-1.721l5.161-1.509l0.503,1.721L605.341,436.262L605.341,436.262z
                                M615.664,433.245l-0.503-1.721l5.162-1.51l0.503,1.721L615.664,433.245L615.664,433.245z M625.987,430.228l-0.503-1.721
                                l5.162-1.51l0.503,1.721L625.987,430.228L625.987,430.228z M636.31,427.21l-0.502-1.721l0.503-0.147l0.502,1.721L636.31,427.21
                                L636.31,427.21z"/>
                            <path fill="#E2E2E2" d="M475.499,489.199l-0.527-1.713l5.14-1.581l0.527,1.713L475.499,489.199L475.499,489.199z
                                M485.778,486.036l-0.527-1.713l5.14-1.581l0.527,1.713L485.778,486.036L485.778,486.036z M496.057,482.873l-0.527-1.713
                                l5.14-1.581l0.527,1.713L496.057,482.873L496.057,482.873z M506.336,479.71l-0.527-1.713l5.14-1.581l0.527,1.713L506.336,479.71
                                L506.336,479.71z M516.616,476.547l-0.527-1.713l5.14-1.581l0.527,1.713L516.616,476.547L516.616,476.547z M526.896,473.385
                                l-0.527-1.713l5.14-1.582l0.527,1.713L526.896,473.385L526.896,473.385z M537.175,470.222l-0.527-1.713l5.14-1.582l0.527,1.713
                                L537.175,470.222L537.175,470.222z M547.454,467.059l-0.527-1.713l5.14-1.582l0.527,1.713L547.454,467.059L547.454,467.059z
                                M557.733,463.896l-0.527-1.713l5.14-1.582l0.527,1.713L557.733,463.896L557.733,463.896z M568.013,460.732l-0.527-1.713
                                l5.14-1.582l0.527,1.713L568.013,460.732L568.013,460.732z M578.292,457.569l-0.527-1.713l5.14-1.581l0.527,1.713
                                L578.292,457.569L578.292,457.569z M588.571,454.406l-0.527-1.713l5.14-1.581l0.527,1.713L588.571,454.406L588.571,454.406z
                                M598.851,451.243l-0.527-1.713l5.14-1.581l0.527,1.713L598.851,451.243L598.851,451.243z M609.13,448.08l-0.527-1.713
                                l5.14-1.581l0.527,1.713L609.13,448.08L609.13,448.08z M619.41,444.917l-0.527-1.713l5.14-1.581l0.527,1.713L619.41,444.917
                                L619.41,444.917z M629.689,441.755l-0.527-1.713l5.14-1.582l0.527,1.713L629.689,441.755L629.689,441.755z M639.968,438.592
                                l-0.527-1.713l5.14-1.582l0.527,1.713L639.968,438.592L639.968,438.592z"/>
                            <path fill="#E2E2E2" d="M473.108,503.538l-0.523-1.715l5.144-1.569l0.523,1.715L473.108,503.538L473.108,503.538z M483.395,500.4
                                l-0.523-1.715l5.144-1.569l0.523,1.715L483.395,500.4L483.395,500.4z M493.682,497.263l-0.523-1.715l5.144-1.569l0.523,1.715
                                L493.682,497.263L493.682,497.263z M503.969,494.124l-0.522-1.715l5.144-1.568l0.522,1.715L503.969,494.124L503.969,494.124z
                                M514.256,490.986l-0.522-1.715l5.143-1.568l0.522,1.715L514.256,490.986L514.256,490.986z M524.542,487.849l-0.522-1.715
                                l5.144-1.568l0.522,1.715L524.542,487.849L524.542,487.849z M534.83,484.711l-0.523-1.715l5.144-1.569l0.523,1.715
                                L534.83,484.711L534.83,484.711z M545.117,481.573l-0.523-1.715l5.144-1.569l0.523,1.715L545.117,481.573L545.117,481.573z
                                M555.404,478.436l-0.523-1.715l5.144-1.569l0.523,1.715L555.404,478.436L555.404,478.436z M565.691,475.297l-0.522-1.715
                                l5.144-1.568l0.522,1.715L565.691,475.297L565.691,475.297z M575.978,472.159l-0.522-1.715l5.144-1.568l0.522,1.715
                                L575.978,472.159L575.978,472.159z M586.266,469.021l-0.523-1.715l5.144-1.569l0.523,1.715L586.266,469.021L586.266,469.021z
                                M596.553,465.884l-0.523-1.715l5.144-1.569l0.523,1.715L596.553,465.884L596.553,465.884z M606.839,462.746l-0.523-1.715
                                l5.144-1.569l0.523,1.715L606.839,462.746L606.839,462.746z M617.126,459.608l-0.523-1.715l5.144-1.569l0.523,1.715
                                L617.126,459.608L617.126,459.608z M627.413,456.47l-0.522-1.715l5.144-1.568l0.522,1.715L627.413,456.47L627.413,456.47z
                                M637.7,453.332l-0.522-1.715l5.144-1.568l0.522,1.715L637.7,453.332L637.7,453.332z M647.988,450.194l-0.523-1.715l5.144-1.569
                                l0.523,1.715L647.988,450.194L647.988,450.194z M658.275,447.057l-0.523-1.715l4.837-1.476l0.523,1.715L658.275,447.057
                                L658.275,447.057z"/>
                            <path fill="#E2E2E2" d="M473.107,516.685l-0.522-1.715l5.144-1.567l0.522,1.715L473.107,516.685L473.107,516.685z
                                M483.396,513.55l-0.522-1.715l5.144-1.568l0.522,1.715L483.396,513.55L483.396,513.55z M493.683,510.414l-0.522-1.715
                                l5.144-1.567l0.522,1.715L493.683,510.414L493.683,510.414z M503.971,507.279l-0.522-1.715l5.144-1.567l0.522,1.715
                                L503.971,507.279L503.971,507.279z M514.259,504.145l-0.522-1.715l5.144-1.568l0.522,1.715L514.259,504.145L514.259,504.145z
                                M524.547,501.009l-0.522-1.715l5.144-1.567l0.522,1.715L524.547,501.009L524.547,501.009z M534.835,497.874l-0.522-1.715
                                l5.144-1.567l0.522,1.715L534.835,497.874L534.835,497.874z M545.123,494.739l-0.522-1.715l5.144-1.568l0.522,1.715
                                L545.123,494.739L545.123,494.739z M555.411,491.604l-0.522-1.715l5.144-1.567l0.522,1.715L555.411,491.604L555.411,491.604z
                                M565.699,488.469l-0.522-1.715l5.144-1.567l0.522,1.715L565.699,488.469L565.699,488.469z M575.986,485.334l-0.522-1.715
                                l5.144-1.568l0.522,1.715L575.986,485.334L575.986,485.334z M586.274,482.198l-0.522-1.715l5.144-1.567l0.522,1.715
                                L586.274,482.198L586.274,482.198z M596.562,479.063l-0.522-1.715l5.144-1.567l0.522,1.715L596.562,479.063L596.562,479.063z
                                M606.85,475.929l-0.522-1.715l5.144-1.568l0.522,1.715L606.85,475.929L606.85,475.929z M617.138,472.793l-0.522-1.715
                                l5.144-1.567l0.522,1.715L617.138,472.793L617.138,472.793z M627.426,469.658l-0.522-1.715l5.144-1.567l0.522,1.715
                                L627.426,469.658L627.426,469.658z M637.714,466.523l-0.522-1.715l5.144-1.567l0.522,1.715L637.714,466.523L637.714,466.523z
                                M648.001,463.388l-0.522-1.715l5.144-1.567l0.522,1.715L648.001,463.388L648.001,463.388z M658.29,460.253l-0.522-1.715
                                l5.144-1.567l0.522,1.715L658.29,460.253L658.29,460.253z M668.577,457.118l-0.522-1.715l5.144-1.567l0.522,1.715
                                L668.577,457.118L668.577,457.118z"/>
                            <path fill="#E2E2E2" d="M483.853,525.051l-0.504-1.721l5.161-1.512l0.504,1.721L483.853,525.051L483.853,525.051z
                                M494.174,522.027l-0.504-1.721l5.161-1.513l0.504,1.721L494.174,522.027L494.174,522.027z M504.495,519.003l-0.504-1.721
                                l5.161-1.512l0.504,1.721L504.495,519.003L504.495,519.003z M514.816,515.979l-0.504-1.721l5.161-1.512l0.504,1.721
                                L514.816,515.979L514.816,515.979z M525.138,512.955l-0.504-1.721l5.161-1.512l0.504,1.721L525.138,512.955L525.138,512.955z
                                M535.458,509.932l-0.504-1.721l5.161-1.512l0.504,1.721L535.458,509.932L535.458,509.932z M545.78,506.907l-0.504-1.721
                                l5.161-1.512l0.504,1.721L545.78,506.907L545.78,506.907z M556.101,503.884l-0.504-1.721l5.161-1.512l0.504,1.721
                                L556.101,503.884L556.101,503.884z M566.422,500.86l-0.504-1.721l5.16-1.513l0.504,1.721L566.422,500.86L566.422,500.86z
                                M576.743,497.836l-0.504-1.721l5.161-1.512l0.504,1.721L576.743,497.836L576.743,497.836z M587.064,494.813l-0.504-1.721
                                l5.161-1.512l0.504,1.721L587.064,494.813L587.064,494.813z M597.386,491.788l-0.504-1.721l5.161-1.512l0.504,1.721
                                L597.386,491.788L597.386,491.788z M607.707,488.765l-0.504-1.721l5.161-1.512l0.504,1.721L607.707,488.765L607.707,488.765z
                                M618.028,485.741l-0.504-1.721l5.161-1.513l0.504,1.721L618.028,485.741L618.028,485.741z M628.349,482.717l-0.504-1.721
                                l5.161-1.512l0.504,1.721L628.349,482.717L628.349,482.717z M638.67,479.693l-0.504-1.721l5.161-1.513l0.504,1.721
                                L638.67,479.693L638.67,479.693z M648.991,476.669l-0.504-1.721l5.161-1.512l0.504,1.721L648.991,476.669L648.991,476.669z
                                M659.313,473.646l-0.504-1.721l5.161-1.512l0.504,1.721L659.313,473.646L659.313,473.646z M669.633,470.621l-0.504-1.721
                                l1.835-0.537l0.504,1.721L669.633,470.621L669.633,470.621z"/>
                            <path fill="#E2E2E2" d="M483.245,535.212l-0.484-1.727l5.177-1.453l0.484,1.727L483.245,535.212L483.245,535.212z
                                M493.599,532.305l-0.484-1.727l5.177-1.453l0.484,1.727L493.599,532.305L493.599,532.305z M503.954,529.397l-0.484-1.727
                                l5.177-1.453l0.484,1.727L503.954,529.397L503.954,529.397z M514.309,526.49l-0.484-1.727l5.177-1.453l0.484,1.727
                                L514.309,526.49L514.309,526.49z M524.663,523.584l-0.484-1.727l5.177-1.454l0.484,1.727L524.663,523.584L524.663,523.584z
                                M535.018,520.677l-0.484-1.727l5.178-1.454l0.484,1.727L535.018,520.677L535.018,520.677z M545.373,517.77l-0.484-1.727
                                l5.177-1.454l0.484,1.727L545.373,517.77L545.373,517.77z M555.727,514.862l-0.484-1.727l5.177-1.453l0.484,1.727
                                L555.727,514.862L555.727,514.862z M566.082,511.955l-0.484-1.727l5.177-1.453l0.484,1.727L566.082,511.955L566.082,511.955z
                                M576.437,509.048l-0.484-1.727l5.177-1.453l0.484,1.727L576.437,509.048L576.437,509.048z M586.791,506.141l-0.484-1.727
                                l5.177-1.453l0.484,1.727L586.791,506.141L586.791,506.141z M597.146,503.234l-0.484-1.727l5.177-1.454l0.484,1.727
                                L597.146,503.234L597.146,503.234z M607.5,500.327l-0.484-1.727l5.177-1.454l0.484,1.727L607.5,500.327L607.5,500.327z
                                M617.855,497.42l-0.484-1.727l5.177-1.454l0.484,1.727L617.855,497.42L617.855,497.42z M628.209,494.513l-0.484-1.727
                                l5.177-1.453l0.484,1.727L628.209,494.513L628.209,494.513z M638.564,491.605l-0.484-1.727l5.178-1.453l0.484,1.727
                                L638.564,491.605L638.564,491.605z M648.919,488.698l-0.484-1.727l5.177-1.453l0.484,1.727L648.919,488.698L648.919,488.698z
                                M659.273,485.791l-0.484-1.727l5.177-1.453l0.484,1.727L659.273,485.791L659.273,485.791z M669.628,482.885l-0.484-1.727
                                l5.177-1.454l0.484,1.727L669.628,482.885L669.628,482.885z M679.983,479.978l-0.484-1.727l5.177-1.454l0.484,1.727
                                L679.983,479.978L679.983,479.978z M690.337,477.07l-0.484-1.727l5.177-1.454l0.484,1.727L690.337,477.07L690.337,477.07z
                                M700.692,474.163l-0.484-1.727l5.177-1.453l0.484,1.727L700.692,474.163L700.692,474.163z"/>
                            <path fill="#E2E2E2" d="M497.587,544.771l-0.49-1.725l5.172-1.472l0.49,1.725L497.587,544.771L497.587,544.771z M507.932,541.828
                                l-0.49-1.725l5.172-1.472l0.49,1.725L507.932,541.828L507.932,541.828z M518.276,538.885l-0.49-1.725l5.172-1.472l0.49,1.725
                                L518.276,538.885L518.276,538.885z M528.621,535.941l-0.49-1.725l5.172-1.472l0.49,1.725L528.621,535.941L528.621,535.941z
                                M538.965,532.998l-0.491-1.725l5.172-1.473l0.491,1.725L538.965,532.998L538.965,532.998z M549.309,530.054l-0.49-1.725
                                l5.172-1.472l0.49,1.725L549.309,530.054L549.309,530.054z M559.653,527.11l-0.49-1.725l5.172-1.472l0.49,1.725L559.653,527.11
                                L559.653,527.11z M569.998,524.167l-0.49-1.725l5.172-1.472l0.49,1.725L569.998,524.167L569.998,524.167z M580.342,521.224
                                l-0.49-1.725l5.172-1.472l0.49,1.725L580.342,521.224L580.342,521.224z M590.687,518.28l-0.49-1.725l5.172-1.472l0.49,1.725
                                L590.687,518.28L590.687,518.28z M601.031,515.337l-0.49-1.725l5.172-1.472l0.49,1.725L601.031,515.337L601.031,515.337z
                                M611.375,512.394l-0.49-1.725l5.172-1.472l0.49,1.725L611.375,512.394L611.375,512.394z M621.72,509.45l-0.49-1.725l5.172-1.472
                                l0.49,1.725L621.72,509.45L621.72,509.45z M632.064,506.507l-0.491-1.725l5.172-1.473l0.491,1.725L632.064,506.507
                                L632.064,506.507z M642.408,503.563l-0.49-1.725l5.172-1.472l0.49,1.725L642.408,503.563L642.408,503.563z M652.752,500.619
                                l-0.49-1.725l5.172-1.472l0.49,1.725L652.752,500.619L652.752,500.619z M663.097,497.676l-0.49-1.725l5.172-1.472l0.49,1.725
                                L663.097,497.676L663.097,497.676z M673.441,494.732l-0.49-1.725l5.172-1.472l0.49,1.725L673.441,494.732L673.441,494.732z
                                M683.786,491.789l-0.49-1.725l5.172-1.472l0.49,1.725L683.786,491.789L683.786,491.789z M694.13,488.846l-0.49-1.725
                                l5.172-1.472l0.49,1.725L694.13,488.846L694.13,488.846z M704.475,485.902l-0.49-1.725l5.172-1.472l0.49,1.725L704.475,485.902
                                L704.475,485.902z M714.819,482.959l-0.491-1.725l3.245-0.924l0.491,1.725L714.819,482.959L714.819,482.959z"/>
                            <path fill="#E2E2E2" d="M505.953,554.928l-0.49-1.725l5.172-1.472l0.49,1.725L505.953,554.928L505.953,554.928z M516.297,551.984
                                l-0.491-1.725l5.172-1.473l0.491,1.725L516.297,551.984L516.297,551.984z M526.641,549.04l-0.49-1.725l5.172-1.472l0.49,1.725
                                L526.641,549.04L526.641,549.04z M536.986,546.097l-0.491-1.725l5.172-1.473l0.491,1.725L536.986,546.097L536.986,546.097z
                                M547.33,543.152l-0.49-1.725l5.172-1.472l0.49,1.725L547.33,543.152L547.33,543.152z M557.674,540.209l-0.491-1.725l5.172-1.473
                                l0.491,1.725L557.674,540.209L557.674,540.209z M568.018,537.265l-0.49-1.725l5.172-1.472l0.49,1.725L568.018,537.265
                                L568.018,537.265z M578.362,534.321l-0.49-1.725l5.172-1.472l0.49,1.725L578.362,534.321L578.362,534.321z M588.707,531.377
                                l-0.49-1.725l5.172-1.472l0.49,1.725L588.707,531.377L588.707,531.377z M599.051,528.434l-0.49-1.725l5.172-1.472l0.49,1.725
                                L599.051,528.434L599.051,528.434z M609.395,525.489l-0.49-1.725l5.172-1.472l0.49,1.725L609.395,525.489L609.395,525.489z
                                M619.739,522.546l-0.49-1.725l5.172-1.472l0.49,1.725L619.739,522.546L619.739,522.546z M630.083,519.602l-0.49-1.725
                                l5.172-1.472l0.49,1.725L630.083,519.602L630.083,519.602z M640.428,516.658l-0.49-1.725l5.172-1.472l0.49,1.725L640.428,516.658
                                L640.428,516.658z M650.772,513.715l-0.491-1.725l5.172-1.473l0.491,1.725L650.772,513.715L650.772,513.715z M661.117,510.771
                                l-0.49-1.725l5.172-1.472l0.49,1.725L661.117,510.771L661.117,510.771z M671.461,507.827l-0.491-1.725l5.172-1.473l0.491,1.725
                                L671.461,507.827L671.461,507.827z M681.805,504.883l-0.49-1.725l5.172-1.472l0.49,1.725L681.805,504.883L681.805,504.883z
                                M692.15,501.939l-0.491-1.725l5.172-1.473l0.491,1.725L692.15,501.939L692.15,501.939z M702.494,498.995l-0.49-1.725
                                l5.172-1.472l0.49,1.725L702.494,498.995L702.494,498.995z M712.838,496.052l-0.49-1.725l5.172-1.472l0.49,1.725L712.838,496.052
                                L712.838,496.052z M723.182,493.107l-0.49-1.725l5.172-1.472l0.49,1.725L723.182,493.107L723.182,493.107z M733.527,490.164
                                l-0.491-1.725l1.269-0.361l0.491,1.725L733.527,490.164L733.527,490.164z"/>
                            <path fill="#E2E2E2" d="M513.123,565.088l-0.49-1.725l5.172-1.472l0.49,1.725L513.123,565.088L513.123,565.088z M523.467,562.144
                                l-0.49-1.725l5.172-1.472l0.49,1.725L523.467,562.144L523.467,562.144z M533.812,559.2l-0.491-1.725l5.172-1.473l0.491,1.725
                                L533.812,559.2L533.812,559.2z M544.155,556.256l-0.49-1.725l5.172-1.472l0.49,1.725L544.155,556.256L544.155,556.256z
                                M554.5,553.312l-0.49-1.725l5.172-1.472l0.49,1.725L554.5,553.312L554.5,553.312z M564.844,550.368l-0.49-1.725l5.172-1.472
                                l0.49,1.725L564.844,550.368L564.844,550.368z M575.188,547.424l-0.49-1.725l5.172-1.472l0.49,1.725L575.188,547.424
                                L575.188,547.424z M585.533,544.48l-0.491-1.725l5.172-1.473l0.491,1.725L585.533,544.48L585.533,544.48z M595.876,541.536
                                l-0.49-1.725l5.172-1.472l0.49,1.725L595.876,541.536L595.876,541.536z M606.22,538.592l-0.49-1.725l5.172-1.472l0.49,1.725
                                L606.22,538.592L606.22,538.592z M616.565,535.648l-0.491-1.725l5.172-1.473l0.491,1.725L616.565,535.648L616.565,535.648z
                                M626.909,532.704l-0.49-1.725l5.172-1.472l0.49,1.725L626.909,532.704L626.909,532.704z M637.253,529.76l-0.49-1.725
                                l5.172-1.472l0.49,1.725L637.253,529.76L637.253,529.76z M647.597,526.816l-0.49-1.725l5.172-1.472l0.49,1.725L647.597,526.816
                                L647.597,526.816z M657.941,523.872l-0.49-1.725l5.172-1.472l0.49,1.725L657.941,523.872L657.941,523.872z M668.286,520.929
                                l-0.491-1.725l5.172-1.473l0.491,1.725L668.286,520.929L668.286,520.929z M678.63,517.984l-0.49-1.725l5.172-1.472l0.49,1.725
                                L678.63,517.984L678.63,517.984z M688.974,515.04l-0.49-1.725l5.172-1.472l0.49,1.725L688.974,515.04L688.974,515.04z
                                M699.319,512.097l-0.491-1.725l5.172-1.473l0.491,1.725L699.319,512.097L699.319,512.097z M709.663,509.152l-0.49-1.725
                                l5.172-1.472l0.49,1.725L709.663,509.152L709.663,509.152z M720.007,506.208l-0.49-1.725l5.172-1.472l0.49,1.725L720.007,506.208
                                L720.007,506.208z M730.352,503.265l-0.491-1.725l5.172-1.473l0.491,1.725L730.352,503.265L730.352,503.265z M740.695,500.32
                                l-0.49-1.725l1.27-0.361l0.49,1.725L740.695,500.32L740.695,500.32z"/>
                            <path fill="#E2E2E2" d="M520.89,577.037l-0.49-1.725l5.172-1.472l0.49,1.725L520.89,577.037L520.89,577.037z M531.234,574.093
                                l-0.49-1.725l5.172-1.472l0.49,1.725L531.234,574.093L531.234,574.093z M541.579,571.149l-0.491-1.725l5.172-1.473l0.491,1.725
                                L541.579,571.149L541.579,571.149z M551.923,568.205l-0.49-1.725l5.172-1.472l0.49,1.725L551.923,568.205L551.923,568.205z
                                M562.267,565.261l-0.49-1.725l5.172-1.472l0.49,1.725L562.267,565.261L562.267,565.261z M572.612,562.317l-0.491-1.725
                                l5.172-1.473l0.491,1.725L572.612,562.317L572.612,562.317z M582.956,559.373l-0.49-1.725l5.172-1.472l0.49,1.725
                                L582.956,559.373L582.956,559.373z M593.3,556.43l-0.491-1.725l5.172-1.473l0.491,1.725L593.3,556.43L593.3,556.43z
                                M603.644,553.485l-0.49-1.725l5.172-1.472l0.49,1.725L603.644,553.485L603.644,553.485z M613.988,550.541l-0.49-1.725
                                l5.172-1.472l0.49,1.725L613.988,550.541L613.988,550.541z M624.333,547.598l-0.491-1.725l5.172-1.473l0.491,1.725
                                L624.333,547.598L624.333,547.598z M634.676,544.653l-0.49-1.725l5.172-1.472l0.49,1.725L634.676,544.653L634.676,544.653z
                                M645.021,541.709l-0.49-1.725l5.172-1.472l0.49,1.725L645.021,541.709L645.021,541.709z M655.365,538.766l-0.491-1.725
                                l5.172-1.473l0.491,1.725L655.365,538.766L655.365,538.766z M665.709,535.821l-0.49-1.725l5.172-1.472l0.49,1.725
                                L665.709,535.821L665.709,535.821z M676.053,532.877l-0.49-1.725l5.172-1.472l0.49,1.725L676.053,532.877L676.053,532.877z
                                M686.398,529.934l-0.491-1.725l5.172-1.473l0.491,1.725L686.398,529.934L686.398,529.934z M696.742,526.989l-0.49-1.725
                                l5.172-1.472l0.49,1.725L696.742,526.989L696.742,526.989z M707.086,524.045l-0.49-1.725l5.172-1.472l0.49,1.725L707.086,524.045
                                L707.086,524.045z M717.43,521.102l-0.49-1.725l5.172-1.472l0.49,1.725L717.43,521.102L717.43,521.102z M727.774,518.157
                                l-0.49-1.725l5.172-1.472l0.49,1.725L727.774,518.157L727.774,518.157z M738.119,515.214l-0.491-1.725l5.172-1.473l0.491,1.725
                                L738.119,515.214L738.119,515.214z M748.462,512.27l-0.49-1.725l1.27-0.361l0.49,1.725L748.462,512.27L748.462,512.27z"/>
                            <path fill="#E2E2E2" d="M535.23,587.791l-0.491-1.725l6.896-1.963l0.491,1.725L535.23,587.791L535.23,587.791z M549.022,583.865
                                l-0.49-1.725l6.896-1.962l0.49,1.725L549.022,583.865L549.022,583.865z M562.815,579.94l-0.491-1.725l6.896-1.963l0.491,1.725
                                L562.815,579.94L562.815,579.94z M576.607,576.015l-0.49-1.725l6.896-1.962l0.49,1.725L576.607,576.015L576.607,576.015z
                                M590.399,572.09l-0.491-1.725l6.896-1.963l0.491,1.725L590.399,572.09L590.399,572.09z M604.191,568.165l-0.49-1.725
                                l6.896-1.963l0.491,1.725L604.191,568.165L604.191,568.165z M617.984,564.239l-0.491-1.725l6.896-1.963l0.49,1.725
                                L617.984,564.239L617.984,564.239z M631.776,560.313l-0.49-1.725l6.896-1.962l0.49,1.725L631.776,560.313L631.776,560.313z
                                M645.568,556.389l-0.49-1.725l6.896-1.963l0.49,1.725L645.568,556.389L645.568,556.389z M659.36,552.463l-0.49-1.725
                                l6.896-1.962l0.49,1.725L659.36,552.463L659.36,552.463z M673.153,548.538l-0.491-1.725l6.896-1.963l0.491,1.725L673.153,548.538
                                L673.153,548.538z M686.945,544.612l-0.49-1.725l6.896-1.962l0.49,1.725L686.945,544.612L686.945,544.612z M700.738,540.688
                                l-0.491-1.725l6.896-1.963l0.491,1.725L700.738,540.688L700.738,540.688z M714.53,536.763l-0.491-1.725l6.896-1.963l0.49,1.725
                                L714.53,536.763L714.53,536.763z M728.322,532.837l-0.491-1.725l6.896-1.963l0.491,1.725L728.322,532.837L728.322,532.837z
                                M742.114,528.911l-0.49-1.725l6.896-1.962l0.49,1.725L742.114,528.911L742.114,528.911z M755.907,524.986l-0.491-1.725
                                l6.896-1.963l0.491,1.725L755.907,524.986L755.907,524.986z"/>
                            <path fill="#E2E2E2" d="M551.96,594.961l-0.491-1.725l6.896-1.963l0.491,1.725L551.96,594.961L551.96,594.961z M565.752,591.035
                                l-0.49-1.725l6.896-1.962l0.49,1.725L565.752,591.035L565.752,591.035z M579.544,587.11l-0.491-1.725l6.896-1.963l0.491,1.725
                                L579.544,587.11L579.544,587.11z M593.336,583.185l-0.49-1.725l6.896-1.962l0.49,1.725L593.336,583.185L593.336,583.185z
                                M607.129,579.26l-0.491-1.725l6.896-1.963l0.491,1.725L607.129,579.26L607.129,579.26z M620.921,575.335l-0.49-1.725
                                l6.896-1.963l0.491,1.725L620.921,575.335L620.921,575.335z M634.713,571.409l-0.491-1.725l6.896-1.963l0.49,1.725
                                L634.713,571.409L634.713,571.409z M648.505,567.483l-0.49-1.725l6.896-1.962l0.49,1.725L648.505,567.483L648.505,567.483z
                                M662.297,563.559l-0.49-1.725l6.896-1.963l0.49,1.725L662.297,563.559L662.297,563.559z M676.09,559.633l-0.49-1.725
                                l6.896-1.962l0.49,1.725L676.09,559.633L676.09,559.633z M689.883,555.708l-0.491-1.725l6.896-1.963l0.491,1.725L689.883,555.708
                                L689.883,555.708z M703.674,551.782l-0.49-1.725l6.896-1.962l0.49,1.725L703.674,551.782L703.674,551.782z M717.467,547.857
                                l-0.491-1.725l6.896-1.963l0.491,1.725L717.467,547.857L717.467,547.857z M731.259,543.933l-0.491-1.725l6.896-1.963l0.49,1.725
                                L731.259,543.933L731.259,543.933z M745.052,540.007l-0.491-1.725l6.896-1.963l0.491,1.725L745.052,540.007L745.052,540.007z
                                M758.843,536.081l-0.49-1.725l6.896-1.962l0.49,1.725L758.843,536.081L758.843,536.081z M772.636,532.156l-0.491-1.725
                                l6.896-1.963l0.491,1.725L772.636,532.156L772.636,532.156z"/>
                            <path fill="#E2E2E2" d="M574.665,603.324l-0.49-1.725l6.896-1.963l0.491,1.725L574.665,603.324L574.665,603.324z
                                M588.458,599.399l-0.491-1.725l6.896-1.963l0.49,1.725L588.458,599.399L588.458,599.399z M602.249,595.474l-0.49-1.725
                                l6.896-1.962l0.49,1.725L602.249,595.474L602.249,595.474z M616.042,591.549l-0.49-1.725l6.896-1.962l0.49,1.725L616.042,591.549
                                L616.042,591.549z M629.834,587.624l-0.491-1.725l6.896-1.963l0.491,1.725L629.834,587.624L629.834,587.624z M643.626,583.699
                                l-0.49-1.725l6.896-1.963l0.49,1.725L643.626,583.699L643.626,583.699z M657.418,579.773l-0.49-1.725l6.896-1.962l0.49,1.725
                                L657.418,579.773L657.418,579.773z M671.211,575.849l-0.49-1.725l6.896-1.963l0.49,1.725L671.211,575.849L671.211,575.849z
                                M685.003,571.924l-0.49-1.725l6.896-1.963l0.49,1.725L685.003,571.924L685.003,571.924z M698.795,567.999l-0.49-1.725
                                l6.896-1.963l0.49,1.725L698.795,567.999L698.795,567.999z M712.588,564.073l-0.49-1.725l6.896-1.962l0.49,1.725L712.588,564.073
                                L712.588,564.073z M726.38,560.148l-0.49-1.725l6.896-1.963l0.49,1.725L726.38,560.148L726.38,560.148z M740.173,556.224
                                l-0.491-1.725l6.896-1.963l0.491,1.725L740.173,556.224L740.173,556.224z M753.965,552.298l-0.49-1.725l6.896-1.962l0.49,1.725
                                L753.965,552.298L753.965,552.298z M767.757,548.373l-0.49-1.725l6.896-1.963l0.49,1.725L767.757,548.373L767.757,548.373z
                                M781.549,544.448l-0.49-1.725l6.896-1.963l0.491,1.725L781.549,544.448L781.549,544.448z M795.342,540.523l-0.491-1.725
                                l6.896-1.963l0.49,1.725L795.342,540.523L795.342,540.523z"/>
                            <path fill="#E2E2E2" d="M585.419,614.08l-0.49-1.725l6.896-1.963l0.491,1.725L585.419,614.08L585.419,614.08z M599.212,610.155
                                l-0.491-1.725l6.896-1.963l0.49,1.725L599.212,610.155L599.212,610.155z M613.004,606.229l-0.49-1.725l6.896-1.962l0.49,1.725
                                L613.004,606.229L613.004,606.229z M626.796,602.305l-0.49-1.725l6.896-1.962l0.49,1.725L626.796,602.305L626.796,602.305z
                                M640.589,598.38l-0.491-1.725l6.896-1.963l0.491,1.725L640.589,598.38L640.589,598.38z M654.381,594.455l-0.49-1.725
                                l6.896-1.963l0.49,1.725L654.381,594.455L654.381,594.455z M668.173,590.529l-0.49-1.725l6.896-1.962l0.49,1.725L668.173,590.529
                                L668.173,590.529z M681.966,586.604l-0.49-1.725l6.896-1.963l0.49,1.725L681.966,586.604L681.966,586.604z M695.758,582.68
                                l-0.49-1.725l6.896-1.963l0.49,1.725L695.758,582.68L695.758,582.68z M709.55,578.755l-0.49-1.725l6.896-1.963l0.49,1.725
                                L709.55,578.755L709.55,578.755z M723.343,574.829l-0.49-1.725l6.896-1.962l0.49,1.725L723.343,574.829L723.343,574.829z
                                M737.135,570.904l-0.49-1.725l6.896-1.963l0.49,1.725L737.135,570.904L737.135,570.904z M750.928,566.979l-0.491-1.725
                                l6.896-1.963l0.491,1.725L750.928,566.979L750.928,566.979z M764.72,563.054l-0.49-1.725l6.896-1.962l0.49,1.725L764.72,563.054
                                L764.72,563.054z M778.512,559.129l-0.49-1.725l6.896-1.963l0.49,1.725L778.512,559.129L778.512,559.129z M792.304,555.204
                                l-0.49-1.725l6.896-1.963l0.491,1.725L792.304,555.204L792.304,555.204z M806.097,551.279l-0.491-1.725l6.896-1.963l0.49,1.725
                                L806.097,551.279L806.097,551.279z"/>
                            <path fill="#E2E2E2" d="M592.591,626.031l-0.491-1.725l6.896-1.963l0.491,1.725L592.591,626.031L592.591,626.031z
                                M606.383,622.106l-0.491-1.725l6.896-1.963l0.491,1.725L606.383,622.106L606.383,622.106z M620.175,618.181l-0.49-1.725
                                l6.896-1.963l0.49,1.725L620.175,618.181L620.175,618.181z M633.967,614.256l-0.49-1.725l6.896-1.963l0.491,1.725
                                L633.967,614.256L633.967,614.256z M647.759,610.33l-0.49-1.725l6.896-1.962l0.49,1.725L647.759,610.33L647.759,610.33z
                                M661.552,606.405l-0.49-1.725l6.896-1.963l0.491,1.725L661.552,606.405L661.552,606.405z M675.345,602.48l-0.491-1.725
                                l6.896-1.963l0.491,1.725L675.345,602.48L675.345,602.48z M689.136,598.555l-0.49-1.725l6.896-1.963l0.49,1.725L689.136,598.555
                                L689.136,598.555z M702.929,594.63l-0.491-1.725l6.896-1.963l0.491,1.725L702.929,594.63L702.929,594.63z M716.721,590.704
                                l-0.49-1.725l6.896-1.962l0.49,1.725L716.721,590.704L716.721,590.704z M730.514,586.779l-0.491-1.725l6.896-1.963l0.491,1.725
                                L730.514,586.779L730.514,586.779z M744.306,582.854l-0.491-1.725l6.896-1.963l0.491,1.725L744.306,582.854L744.306,582.854z
                                M758.098,578.929l-0.49-1.725l6.896-1.963l0.49,1.725L758.098,578.929L758.098,578.929z M771.89,575.004l-0.491-1.725
                                l6.896-1.963l0.491,1.725L771.89,575.004L771.89,575.004z M785.682,571.078l-0.49-1.725l6.896-1.962l0.49,1.725L785.682,571.078
                                L785.682,571.078z M799.475,567.153l-0.49-1.725l6.896-1.963l0.491,1.725L799.475,567.153L799.475,567.153z M813.268,563.229
                                l-0.491-1.725l6.896-1.963l0.49,1.725L813.268,563.229L813.268,563.229z"/>
                            <path fill="#E2E2E2" d="M612.904,634.396l-0.491-1.725l6.896-1.963l0.491,1.725L612.904,634.396L612.904,634.396z
                                M626.696,630.471l-0.49-1.725l6.896-1.962l0.49,1.725L626.696,630.471L626.696,630.471z M640.489,626.546l-0.491-1.725
                                l6.896-1.963l0.491,1.725L640.489,626.546L640.489,626.546z M654.281,622.62l-0.49-1.725l6.896-1.962l0.49,1.725L654.281,622.62
                                L654.281,622.62z M668.073,618.695l-0.491-1.725l6.896-1.963l0.491,1.725L668.073,618.695L668.073,618.695z M681.865,614.771
                                l-0.49-1.725l6.896-1.963l0.491,1.725L681.865,614.771L681.865,614.771z M695.658,610.845l-0.491-1.725l6.896-1.963l0.49,1.725
                                L695.658,610.845L695.658,610.845z M709.45,606.919l-0.49-1.725l6.896-1.962l0.49,1.725L709.45,606.919L709.45,606.919z
                                M723.242,602.994l-0.49-1.725l6.896-1.963l0.49,1.725L723.242,602.994L723.242,602.994z M737.034,599.068l-0.49-1.725
                                l6.896-1.962l0.49,1.725L737.034,599.068L737.034,599.068z M750.827,595.144l-0.491-1.725l6.896-1.963l0.491,1.725
                                L750.827,595.144L750.827,595.144z M764.619,591.218l-0.49-1.725l6.896-1.962l0.49,1.725L764.619,591.218L764.619,591.218z
                                M778.412,587.293l-0.491-1.725l6.896-1.963l0.491,1.725L778.412,587.293L778.412,587.293z M792.204,583.368l-0.491-1.725
                                l6.896-1.963l0.49,1.725L792.204,583.368L792.204,583.368z M805.996,579.442l-0.491-1.725l6.896-1.963l0.491,1.725
                                L805.996,579.442L805.996,579.442z M819.788,575.517l-0.49-1.725l6.896-1.962l0.49,1.725L819.788,575.517L819.788,575.517z
                                M833.581,571.592l-0.491-1.725l6.896-1.963l0.49,1.725L833.581,571.592L833.581,571.592z"/>
                            <path fill="#E2E2E2" d="M629.634,643.957l-0.491-1.725l6.896-1.963l0.491,1.725L629.634,643.957L629.634,643.957z
                                M643.426,640.031l-0.49-1.725l6.896-1.962l0.49,1.725L643.426,640.031L643.426,640.031z M657.218,636.106l-0.491-1.725
                                l6.896-1.963l0.491,1.725L657.218,636.106L657.218,636.106z M671.01,632.181l-0.49-1.725l6.896-1.962l0.49,1.725L671.01,632.181
                                L671.01,632.181z M684.803,628.256l-0.491-1.725l6.896-1.963l0.491,1.725L684.803,628.256L684.803,628.256z M698.595,624.331
                                l-0.49-1.725l6.896-1.963l0.491,1.725L698.595,624.331L698.595,624.331z M712.387,620.405l-0.491-1.725l6.896-1.963l0.49,1.725
                                L712.387,620.405L712.387,620.405z M726.179,616.479l-0.49-1.725l6.896-1.962l0.49,1.725L726.179,616.479L726.179,616.479z
                                M739.971,612.555l-0.49-1.725l6.896-1.963l0.49,1.725L739.971,612.555L739.971,612.555z M753.764,608.629l-0.49-1.725
                                l6.896-1.962l0.49,1.725L753.764,608.629L753.764,608.629z M767.557,604.704l-0.491-1.725l6.896-1.963l0.491,1.725
                                L767.557,604.704L767.557,604.704z M781.348,600.778l-0.49-1.725l6.896-1.962l0.49,1.725L781.348,600.778L781.348,600.778z
                                M795.141,596.854l-0.491-1.725l6.896-1.963l0.491,1.725L795.141,596.854L795.141,596.854z M808.933,592.929l-0.491-1.725
                                l6.896-1.963l0.49,1.725L808.933,592.929L808.933,592.929z M822.726,589.003l-0.491-1.725l6.896-1.963l0.491,1.725
                                L822.726,589.003L822.726,589.003z M836.517,585.077l-0.49-1.725l6.896-1.962l0.49,1.725L836.517,585.077L836.517,585.077z
                                M850.309,581.152l-0.49-1.725l6.896-1.963l0.49,1.725L850.309,581.152L850.309,581.152z"/>
                            <path fill="#E2E2E2" d="M643.973,652.32l-0.49-1.725l6.896-1.963l0.491,1.725L643.973,652.32L643.973,652.32z M657.766,648.396
                                l-0.491-1.725l6.896-1.963l0.49,1.725L657.766,648.396L657.766,648.396z M671.558,644.47l-0.49-1.725l6.896-1.962l0.49,1.725
                                L671.558,644.47L671.558,644.47z M685.351,640.545l-0.491-1.725l6.896-1.963l0.491,1.725L685.351,640.545L685.351,640.545z
                                M699.143,636.62l-0.491-1.725l6.896-1.963l0.491,1.725L699.143,636.62L699.143,636.62z M712.935,632.694l-0.491-1.725
                                l6.896-1.963l0.491,1.725L712.935,632.694L712.935,632.694z M726.728,628.77l-0.491-1.725l6.896-1.963l0.491,1.725
                                L726.728,628.77L726.728,628.77z M740.519,624.844l-0.49-1.725l6.896-1.962l0.49,1.725L740.519,624.844L740.519,624.844z
                                M754.312,620.919l-0.491-1.725l6.896-1.963l0.491,1.725L754.312,620.919L754.312,620.919z M768.104,616.994l-0.49-1.725
                                l6.896-1.963l0.49,1.725L768.104,616.994L768.104,616.994z M781.896,613.068l-0.49-1.725l6.896-1.962l0.49,1.725L781.896,613.068
                                L781.896,613.068z M795.689,609.144l-0.491-1.725l6.896-1.963l0.49,1.725L795.689,609.144L795.689,609.144z M809.48,605.219
                                l-0.49-1.725l6.896-1.963l0.49,1.725L809.48,605.219L809.48,605.219z M823.273,601.293l-0.491-1.725l6.896-1.963l0.49,1.725
                                L823.273,601.293L823.273,601.293z M837.065,597.368l-0.49-1.725l6.896-1.963l0.49,1.725L837.065,597.368L837.065,597.368z
                                M850.858,593.442l-0.49-1.725l6.896-1.962l0.49,1.725L850.858,593.442L850.858,593.442z M864.65,589.518l-0.49-1.725
                                l6.896-1.963l0.49,1.725L864.65,589.518L864.65,589.518z"/>
                            <path fill="#E2E2E2" d="M664.304,660.681l-0.522-1.715l5.144-1.566l0.522,1.715L664.304,660.681L664.304,660.681z
                                M674.592,657.547l-0.522-1.715l5.144-1.566l0.522,1.715L674.592,657.547L674.592,657.547z M684.88,654.413l-0.522-1.715
                                l5.144-1.566l0.522,1.715L684.88,654.413L684.88,654.413z M695.169,651.28l-0.522-1.715l5.144-1.567l0.522,1.715L695.169,651.28
                                L695.169,651.28z M705.457,648.146l-0.522-1.715l5.145-1.566l0.522,1.715L705.457,648.146L705.457,648.146z M715.746,645.013
                                l-0.522-1.715l5.144-1.566l0.522,1.715L715.746,645.013L715.746,645.013z M726.034,641.879l-0.522-1.715l5.145-1.566l0.522,1.715
                                L726.034,641.879L726.034,641.879z M736.322,638.746l-0.522-1.715l5.144-1.567l0.522,1.715L736.322,638.746L736.322,638.746z
                                M746.611,635.612l-0.522-1.715l5.144-1.567l0.522,1.715L746.611,635.612L746.611,635.612z M756.899,632.479l-0.522-1.715
                                l5.144-1.566l0.522,1.715L756.899,632.479L756.899,632.479z M767.188,629.345l-0.522-1.715l5.144-1.566l0.522,1.715
                                L767.188,629.345L767.188,629.345z M777.476,626.211l-0.522-1.715l5.145-1.566l0.522,1.715L777.476,626.211L777.476,626.211z
                                M787.764,623.078l-0.522-1.715l5.144-1.567l0.522,1.715L787.764,623.078L787.764,623.078z M798.052,619.944l-0.522-1.715
                                l5.145-1.566l0.522,1.715L798.052,619.944L798.052,619.944z M808.341,616.811l-0.522-1.715l5.144-1.566l0.522,1.715
                                L808.341,616.811L808.341,616.811z M818.629,613.677l-0.522-1.715l5.145-1.566l0.522,1.715L818.629,613.677L818.629,613.677z
                                M828.917,610.544l-0.522-1.715l5.144-1.567l0.522,1.715L828.917,610.544L828.917,610.544z M839.207,607.41l-0.523-1.715
                                l5.144-1.567l0.523,1.715L839.207,607.41L839.207,607.41z M849.494,604.276l-0.521-1.715l5.145-1.566l0.521,1.715
                                L849.494,604.276L849.494,604.276z M859.782,601.143l-0.521-1.715l5.145-1.566l0.521,1.715L859.782,601.143L859.782,601.143z
                                M870.072,598.01l-0.523-1.715l5.144-1.567l0.523,1.715L870.072,598.01L870.072,598.01z M880.359,594.876l-0.521-1.715
                                l5.145-1.567l0.521,1.715L880.359,594.876L880.359,594.876z M890.647,591.742l-0.521-1.715l5.145-1.566l0.521,1.715
                                L890.647,591.742L890.647,591.742z M900.936,588.608l-0.521-1.715l5.144-1.566l0.521,1.715L900.936,588.608L900.936,588.608z
                                M911.224,585.475l-0.521-1.715l5.144-1.566l0.521,1.715L911.224,585.475L911.224,585.475z M921.512,582.342l-0.521-1.715
                                l5.145-1.567l0.521,1.715L921.512,582.342L921.512,582.342z M931.8,579.208l-0.521-1.715l5.145-1.567l0.521,1.715L931.8,579.208
                                L931.8,579.208z M942.089,576.074l-0.521-1.715l5.144-1.566l0.521,1.715L942.089,576.074L942.089,576.074z M952.377,572.94
                                l-0.521-1.715l5.145-1.566l0.521,1.715L952.377,572.94L952.377,572.94z M962.666,569.808l-0.521-1.715l5.145-1.567l0.521,1.715
                                L962.666,569.808L962.666,569.808z M972.954,566.674l-0.521-1.715l5.145-1.567l0.521,1.715L972.954,566.674L972.954,566.674z
                                M983.243,563.54l-0.521-1.715l5.144-1.566l0.521,1.715L983.243,563.54L983.243,563.54z M993.531,560.406l-0.521-1.715
                                l5.145-1.566l0.521,1.715L993.531,560.406L993.531,560.406z"/>
                            <path fill="#E2E2E2" d="M687.014,669.042l-0.532-1.711l5.135-1.598l0.532,1.711L687.014,669.042L687.014,669.042z
                                M697.283,665.847l-0.532-1.711l5.134-1.598l0.532,1.711L697.283,665.847L697.283,665.847z M707.552,662.651l-0.532-1.711
                                l5.135-1.598l0.532,1.711L707.552,662.651L707.552,662.651z M717.822,659.456l-0.532-1.711l5.135-1.598l0.532,1.711
                                L717.822,659.456L717.822,659.456z M728.091,656.261l-0.532-1.711l5.134-1.598l0.532,1.711L728.091,656.261L728.091,656.261z
                                M738.36,653.065l-0.532-1.711l5.135-1.598l0.532,1.711L738.36,653.065L738.36,653.065z M748.63,649.87l-0.532-1.711l5.135-1.598
                                l0.532,1.711L748.63,649.87L748.63,649.87z M758.899,646.675l-0.532-1.711l5.134-1.598l0.532,1.711L758.899,646.675
                                L758.899,646.675z M769.168,643.479l-0.532-1.711l5.135-1.598l0.532,1.711L769.168,643.479L769.168,643.479z M779.438,640.284
                                l-0.532-1.711l5.135-1.598l0.532,1.711L779.438,640.284L779.438,640.284z M789.708,637.089l-0.532-1.711l5.134-1.598l0.532,1.711
                                L789.708,637.089L789.708,637.089z M799.977,633.894l-0.532-1.711l5.135-1.598l0.532,1.711L799.977,633.894L799.977,633.894z
                                M810.246,630.698l-0.532-1.711l5.135-1.598l0.532,1.711L810.246,630.698L810.246,630.698z M820.516,627.503l-0.532-1.711
                                l5.134-1.598l0.532,1.711L820.516,627.503L820.516,627.503z M830.785,624.309l-0.532-1.711l5.135-1.598l0.532,1.711
                                L830.785,624.309L830.785,624.309z M841.054,621.113l-0.533-1.711l5.135-1.598l0.533,1.711L841.054,621.113L841.054,621.113z
                                M851.324,617.918l-0.533-1.711l5.135-1.598l0.533,1.711L851.324,617.918L851.324,617.918z M861.593,614.723l-0.533-1.711
                                l5.135-1.598l0.533,1.711L861.593,614.723L861.593,614.723z M871.863,611.527l-0.533-1.711l5.135-1.598l0.533,1.711
                                L871.863,611.527L871.863,611.527z M882.132,608.332l-0.533-1.711l5.135-1.598l0.533,1.711L882.132,608.332L882.132,608.332z
                                M892.402,605.137l-0.533-1.711l5.135-1.598l0.533,1.711L892.402,605.137L892.402,605.137z M902.67,601.941l-0.533-1.711
                                l5.135-1.598l0.533,1.711L902.67,601.941L902.67,601.941z M912.94,598.746l-0.533-1.711l5.135-1.598l0.533,1.711L912.94,598.746
                                L912.94,598.746z M923.209,595.551l-0.533-1.711l5.135-1.598l0.533,1.711L923.209,595.551L923.209,595.551z M933.479,592.355
                                l-0.533-1.711l5.135-1.598l0.533,1.711L933.479,592.355L933.479,592.355z M943.749,589.16l-0.533-1.711l5.135-1.598l0.533,1.711
                                L943.749,589.16L943.749,589.16z M954.018,585.965l-0.533-1.711l5.135-1.598l0.533,1.711L954.018,585.965L954.018,585.965z
                                M964.287,582.77l-0.533-1.711l5.135-1.598l0.533,1.711L964.287,582.77L964.287,582.77z M974.556,579.574l-0.533-1.711
                                l5.135-1.598l0.533,1.711L974.556,579.574L974.556,579.574z M984.826,576.379l-0.533-1.711l5.135-1.598l0.533,1.711
                                L984.826,576.379L984.826,576.379z M995.095,573.184l-0.533-1.711l5.135-1.598l0.533,1.711L995.095,573.184L995.095,573.184z
                                M1005.365,569.988l-0.533-1.711l5.135-1.598l0.533,1.711L1005.365,569.988L1005.365,569.988z M1015.634,566.793l-0.533-1.711
                                l3.594-1.118l0.533,1.711L1015.634,566.793L1015.634,566.793z"/>
                            <path fill="#E2E2E2" d="M708.531,677.407l-0.546-1.707l5.122-1.638l0.546,1.707L708.531,677.407L708.531,677.407z
                                M718.775,674.132l-0.546-1.707l5.122-1.639l0.546,1.707L718.775,674.132L718.775,674.132z M729.019,670.855l-0.546-1.707
                                l5.122-1.638l0.546,1.707L729.019,670.855L729.019,670.855z M739.263,667.58l-0.546-1.707l5.122-1.639l0.546,1.707
                                L739.263,667.58L739.263,667.58z M749.507,664.304l-0.546-1.707l5.122-1.638l0.546,1.707L749.507,664.304L749.507,664.304z
                                M759.751,661.028l-0.546-1.707l5.122-1.638l0.546,1.707L759.751,661.028L759.751,661.028z M769.995,657.752l-0.546-1.707
                                l5.122-1.638l0.546,1.707L769.995,657.752L769.995,657.752z M780.239,654.477l-0.546-1.707l5.122-1.638l0.546,1.707
                                L780.239,654.477L780.239,654.477z M790.483,651.2l-0.546-1.707l5.122-1.638l0.546,1.707L790.483,651.2L790.483,651.2z
                                M800.727,647.925l-0.546-1.707l5.122-1.638l0.546,1.707L800.727,647.925L800.727,647.925z M810.971,644.649l-0.546-1.707
                                l5.122-1.639l0.546,1.707L810.971,644.649L810.971,644.649z M821.215,641.373l-0.546-1.707l5.122-1.638l0.546,1.707
                                L821.215,641.373L821.215,641.373z M831.458,638.098l-0.546-1.707l5.122-1.638l0.546,1.707L831.458,638.098L831.458,638.098z
                                M841.703,634.821l-0.547-1.707l5.123-1.638l0.545,1.707L841.703,634.821L841.703,634.821z M851.946,631.546l-0.545-1.707
                                l5.121-1.638l0.547,1.707L851.946,631.546L851.946,631.546z M862.191,628.27l-0.546-1.707l5.122-1.638l0.545,1.707
                                L862.191,628.27L862.191,628.27z M872.435,624.994l-0.547-1.707l5.122-1.638l0.547,1.707L872.435,624.994L872.435,624.994z
                                M882.679,621.719l-0.547-1.707l5.122-1.639l0.547,1.707L882.679,621.719L882.679,621.719z M892.923,618.442l-0.547-1.707
                                l5.121-1.638l0.547,1.707L892.923,618.442L892.923,618.442z M903.167,615.167l-0.547-1.707l5.122-1.639l0.547,1.707
                                L903.167,615.167L903.167,615.167z M913.411,611.891l-0.547-1.707l5.122-1.638l0.547,1.707L913.411,611.891L913.411,611.891z
                                M923.654,608.615l-0.545-1.707l5.121-1.638l0.546,1.707L923.654,608.615L923.654,608.615z M933.899,605.339l-0.547-1.707
                                l5.123-1.638l0.545,1.707L933.899,605.339L933.899,605.339z M944.142,602.063l-0.547-1.707l5.123-1.638l0.545,1.707
                                L944.142,602.063L944.142,602.063z M954.385,598.787l-0.546-1.707l5.122-1.638l0.547,1.707L954.385,598.787L954.385,598.787z
                                M964.629,595.512l-0.545-1.707l5.122-1.638l0.545,1.707L964.629,595.512L964.629,595.512z M974.875,592.236l-0.547-1.707
                                l5.122-1.639l0.547,1.707L974.875,592.236L974.875,592.236z M985.118,588.96l-0.545-1.707l5.121-1.638l0.547,1.707
                                L985.118,588.96L985.118,588.96z M995.363,585.685l-0.547-1.707l5.121-1.638l0.547,1.707L995.363,585.685L995.363,585.685z
                                M1005.605,582.408l-0.545-1.707l5.122-1.638l0.545,1.707L1005.605,582.408L1005.605,582.408z M1015.85,579.133l-0.547-1.707
                                l5.123-1.638l0.546,1.707L1015.85,579.133L1015.85,579.133z M1026.093,575.856l-0.545-1.707l5.121-1.638l0.547,1.707
                                L1026.093,575.856L1026.093,575.856z"/>
                            <path fill="#E2E2E2" d="M486.254,460.519l-0.521-1.715l5.145-1.565l0.521,1.715L486.254,460.519L486.254,460.519z
                                M496.543,457.387l-0.521-1.715l5.145-1.565l0.521,1.715L496.543,457.387L496.543,457.387z M506.833,454.256l-0.522-1.715
                                l5.145-1.566l0.522,1.715L506.833,454.256L506.833,454.256z M517.121,451.124l-0.521-1.715l5.145-1.565l0.521,1.715
                                L517.121,451.124L517.121,451.124z M527.41,447.992l-0.521-1.715l5.145-1.565l0.521,1.715L527.41,447.992L527.41,447.992z
                                M537.7,444.861l-0.522-1.715l5.145-1.566l0.522,1.715L537.7,444.861L537.7,444.861z M547.989,441.729l-0.522-1.715l5.145-1.566
                                l0.522,1.715L547.989,441.729L547.989,441.729z M558.277,438.598l-0.521-1.715l5.145-1.565l0.521,1.715L558.277,438.598
                                L558.277,438.598z M568.566,435.466l-0.521-1.715l5.144-1.565l0.521,1.715L568.566,435.466L568.566,435.466z M578.855,432.335
                                l-0.521-1.715l5.145-1.566l0.521,1.715L578.855,432.335L578.855,432.335z M589.144,429.203l-0.522-1.715l5.145-1.565l0.522,1.715
                                L589.144,429.203L589.144,429.203z M599.433,426.071l-0.521-1.715l5.145-1.565l0.521,1.715L599.433,426.071L599.433,426.071z
                                M609.722,422.94l-0.521-1.715l5.145-1.566l0.521,1.715L609.722,422.94L609.722,422.94z M620.012,419.809l-0.522-1.715
                                l3.667-1.116l0.522,1.715L620.012,419.809L620.012,419.809z"/>
                            <path fill="#E2E2E2" d="M492.231,446.178l-0.526-1.713l5.141-1.578l0.526,1.713L492.231,446.178L492.231,446.178z
                                M502.513,443.022l-0.526-1.713l5.141-1.578l0.526,1.713L502.513,443.022L502.513,443.022z M512.794,439.866l-0.525-1.713
                                l5.141-1.577l0.525,1.713L512.794,439.866L512.794,439.866z M523.076,436.711l-0.526-1.713l5.141-1.579l0.526,1.714
                                L523.076,436.711L523.076,436.711z M533.358,433.556l-0.526-1.714l5.141-1.577l0.526,1.713L533.358,433.556L533.358,433.556z
                                M543.64,430.4l-0.526-1.714l5.141-1.578l0.526,1.714L543.64,430.4L543.64,430.4z M553.921,427.245l-0.526-1.715l5.141-1.577
                                l0.526,1.714L553.921,427.245L553.921,427.245z M564.202,424.089l-0.525-1.714l5.141-1.577l0.525,1.713L564.202,424.089
                                L564.202,424.089z M574.484,420.934l-0.526-1.714l5.141-1.578l0.526,1.714L574.484,420.934L574.484,420.934z M584.766,417.778
                                l-0.526-1.715l5.141-1.577l0.526,1.714L584.766,417.778L584.766,417.778z M595.048,414.622l-0.526-1.714l5.141-1.577l0.526,1.713
                                L595.048,414.622L595.048,414.622z M605.329,411.467l-0.525-1.714l5.141-1.578l0.525,1.715L605.329,411.467L605.329,411.467z"/>
                            <path fill="#E2E2E2" d="M755.719,372.092l-0.503-1.721l6.882-2.012l0.503,1.721L755.719,372.092L755.719,372.092z
                                M769.483,368.068l-0.503-1.721l6.882-2.012l0.503,1.721L769.483,368.068L769.483,368.068z M783.247,364.045l-0.503-1.721
                                l6.882-2.012l0.503,1.721L783.247,364.045L783.247,364.045z"/>
                            <path fill="#E2E2E2" d="M764.672,381.055l-0.485-1.726l6.902-1.941l0.485,1.726L764.672,381.055L764.672,381.055z
                                M778.476,377.173l-0.485-1.726l6.902-1.941l0.485,1.726L778.476,377.173L778.476,377.173z M792.281,373.29l-0.485-1.726
                                l6.902-1.941l0.485,1.726L792.281,373.29L792.281,373.29z"/>
                            <path fill="#E2E2E2" d="M928.415,568.66l-0.541-1.709l5.126-1.624l0.541,1.709L928.415,568.66L928.415,568.66z M938.667,565.412
                                l-0.541-1.709l5.126-1.625l0.541,1.709L938.667,565.412L938.667,565.412z M948.919,562.163l-0.541-1.709l5.127-1.624l0.541,1.709
                                L948.919,562.163L948.919,562.163z M959.172,558.915l-0.541-1.709l5.126-1.625l0.541,1.709L959.172,558.915L959.172,558.915z
                                M969.425,555.666l-0.541-1.709l5.126-1.624l0.541,1.709L969.425,555.666L969.425,555.666z M979.678,552.418l-0.541-1.709
                                l5.126-1.625l0.541,1.709L979.678,552.418L979.678,552.418z"/>
                            <path fill="#E2E2E2" d="M920.074,557.898l-0.588-1.693l5.079-1.767l0.588,1.693L920.074,557.898L920.074,557.898z
                                M930.232,554.365l-0.588-1.693l5.079-1.767l0.588,1.693L930.232,554.365L930.232,554.365z M940.391,550.832l-0.59-1.693
                                l5.078-1.767l0.59,1.693L940.391,550.832L940.391,550.832z M950.547,547.298l-0.588-1.693l5.079-1.767l0.588,1.693
                                L950.547,547.298L950.547,547.298z M960.706,543.765l-0.589-1.693l5.08-1.767l0.589,1.693L960.706,543.765L960.706,543.765z
                                M970.865,540.231l-0.59-1.693l4.175-1.452l0.59,1.693L970.865,540.231L970.865,540.231z"/>
                            <path fill="#E2E2E2" d="M914.67,545.36l-0.547-1.707l5.122-1.639l0.547,1.707L914.67,545.36L914.67,545.36z M924.914,542.083
                                l-0.547-1.707l5.122-1.639l0.547,1.707L924.914,542.083L924.914,542.083z M935.158,538.806l-0.547-1.707l5.121-1.639l0.547,1.707
                                L935.158,538.806L935.158,538.806z M945.401,535.528l-0.547-1.707l5.122-1.639l0.547,1.707L945.401,535.528L945.401,535.528z
                                M955.644,532.251l-0.547-1.707l3.839-1.229l0.547,1.707L955.644,532.251L955.644,532.251z"/>
                            <path fill="#E2E2E2" d="M903.922,534.604l-0.561-1.703l5.107-1.683l0.561,1.703L903.922,534.604L903.922,534.604z
                                M914.137,531.239l-0.561-1.703l5.107-1.683l0.561,1.703L914.137,531.239L914.137,531.239z M924.352,527.874l-0.561-1.703
                                l5.107-1.683l0.561,1.703L924.352,527.874L924.352,527.874z M934.567,524.508l-0.561-1.703l5.107-1.683l0.561,1.703
                                L934.567,524.508L934.567,524.508z M944.782,521.143l-0.561-1.703l5.107-1.683l0.561,1.703L944.782,521.143L944.782,521.143z"/>
                            <path fill="#E2E2E2" d="M891.984,523.845l-0.584-1.695l5.084-1.753l0.584,1.695L891.984,523.845L891.984,523.845z
                                M902.152,520.339l-0.584-1.695l5.083-1.753l0.584,1.695L902.152,520.339L902.152,520.339z M912.319,516.833l-0.584-1.695
                                l5.084-1.753l0.584,1.695L912.319,516.833L912.319,516.833z M922.487,513.327l-0.584-1.695l5.083-1.753l0.584,1.695
                                L922.487,513.327L922.487,513.327z M932.654,509.821l-0.584-1.695l5.084-1.753l0.584,1.695L932.654,509.821L932.654,509.821z
                                M942.822,506.315l-0.584-1.695l1.145-0.395l0.584,1.695L942.822,506.315L942.822,506.315z"/>
                            <path fill="#E2E2E2" d="M884.809,513.093l-0.574-1.699l6.793-2.294l0.574,1.699L884.809,513.093L884.809,513.093z
                                M898.395,508.505l-0.574-1.699l6.793-2.294l0.574,1.699L898.395,508.505L898.395,508.505z M911.981,503.916l-0.574-1.699
                                l6.793-2.294l0.574,1.699L911.981,503.916L911.981,503.916z M925.568,499.328l-0.574-1.699l5.248-1.772l0.574,1.699
                                L925.568,499.328L925.568,499.328z"/>
                            <path fill="#E2E2E2" d="M864.494,508.31l-0.572-1.699l6.796-2.287l0.572,1.699L864.494,508.31L864.494,508.31z M878.084,503.735
                                l-0.572-1.699l6.796-2.286l0.572,1.699L878.084,503.735L878.084,503.735z M891.675,499.162l-0.572-1.699l6.796-2.287l0.572,1.699
                                L891.675,499.162L891.675,499.162z M905.267,494.588l-0.572-1.699l6.795-2.286l0.572,1.699L905.267,494.588L905.267,494.588z
                                M918.858,490.015l-0.572-1.699l6.795-2.287l0.572,1.699L918.858,490.015L918.858,490.015z"/>
                            <path fill="#E2E2E2" d="M848.362,501.739l-0.572-1.699l6.796-2.287l0.572,1.699L848.362,501.739L848.362,501.739z
                                M861.953,497.165l-0.572-1.699l6.796-2.287l0.572,1.699L861.953,497.165L861.953,497.165z M875.543,492.591l-0.572-1.699
                                l6.796-2.287l0.572,1.699L875.543,492.591L875.543,492.591z M889.134,488.017l-0.572-1.699l6.796-2.287l0.572,1.699
                                L889.134,488.017L889.134,488.017z M902.725,483.442l-0.572-1.699l6.796-2.287l0.572,1.699L902.725,483.442L902.725,483.442z"/>
                            <path fill="#E2E2E2" d="M842.983,490.983l-0.572-1.699l6.796-2.287l0.572,1.699L842.983,490.983L842.983,490.983z
                                M856.574,486.41l-0.572-1.699l6.796-2.287l0.572,1.699L856.574,486.41L856.574,486.41z M870.165,481.836l-0.572-1.699
                                l6.796-2.287l0.572,1.699L870.165,481.836L870.165,481.836z M883.756,477.263l-0.572-1.699l6.795-2.287l0.572,1.699
                                L883.756,477.263L883.756,477.263z M897.347,472.688l-0.572-1.699l6.796-2.286l0.572,1.699L897.347,472.688L897.347,472.688z"/>
                            <path fill="#E2E2E2" d="M834.021,480.229l-0.571-1.699l6.795-2.287l0.572,1.699L834.021,480.229L834.021,480.229z
                                M847.613,475.655l-0.572-1.699l6.796-2.287l0.572,1.699L847.613,475.655L847.613,475.655z M861.204,471.081l-0.572-1.699
                                l6.796-2.287l0.572,1.699L861.204,471.081L861.204,471.081z M874.794,466.507l-0.572-1.699l6.796-2.287l0.572,1.699
                                L874.794,466.507L874.794,466.507z M888.385,461.933l-0.572-1.699l6.796-2.287l0.572,1.699L888.385,461.933L888.385,461.933z"/>
                            <path fill="#E2E2E2" d="M835.22,467.679l-0.58-1.695l6.784-2.321l0.58,1.695L835.22,467.679L835.22,467.679z M848.789,463.037
                                l-0.58-1.695l6.783-2.321l0.58,1.695L848.789,463.037L848.789,463.037z M862.356,458.396l-0.58-1.695l6.784-2.321l0.58,1.695
                                L862.356,458.396L862.356,458.396z M875.924,453.754l-0.58-1.695l4.701-1.608l0.58,1.695L875.924,453.754L875.924,453.754z"/>
                            <path fill="#E2E2E2" d="M822.075,460.509l-0.58-1.695l6.784-2.321l0.58,1.695L822.075,460.509L822.075,460.509z M835.643,455.867
                                l-0.58-1.695l6.784-2.321l0.58,1.695L835.643,455.867L835.643,455.867z M849.21,451.226l-0.58-1.695l6.784-2.321l0.58,1.695
                                L849.21,451.226L849.21,451.226z M862.779,446.583l-0.58-1.695l4.699-1.607l0.58,1.695L862.779,446.583L862.779,446.583z"/>
                            <path fill="#E2E2E2" d="M819.669,448.567l-0.549-1.707l6.826-2.194l0.549,1.707L819.669,448.567L819.669,448.567z
                                M833.321,444.178l-0.549-1.707l6.826-2.194l0.549,1.707L833.321,444.178L833.321,444.178z M846.973,439.789l-0.549-1.707
                                l6.149-1.978l0.549,1.707L846.973,439.789L846.973,439.789z"/>
                            <path fill="#E2E2E2" d="M812.54,438.99l-0.63-1.678l6.713-2.518l0.629,1.678L812.54,438.99L812.54,438.99z M825.966,433.955
                                l-0.629-1.679l6.714-2.517l0.629,1.679L825.966,433.955L825.966,433.955z M839.393,428.921l-0.629-1.679l1.819-0.683l0.629,1.68
                                L839.393,428.921L839.393,428.921z"/>
                            <path fill="#E2E2E2" d="M788.614,434.22l-0.58-1.695l6.784-2.321l0.58,1.696L788.614,434.22L788.614,434.22z M802.182,429.579
                                l-0.58-1.697l6.784-2.32l0.58,1.696L802.182,429.579L802.182,429.579z M815.75,424.937l-0.58-1.695l6.784-2.322l0.58,1.697
                                L815.75,424.937L815.75,424.937z M829.317,420.295l-0.58-1.696l4.701-1.607l0.58,1.695L829.317,420.295L829.317,420.295z"/>
                            <path fill="#E2E2E2" d="M782.639,424.659l-0.58-1.695l6.784-2.321l0.58,1.696L782.639,424.659L782.639,424.659z M796.207,420.019
                                l-0.58-1.697l6.784-2.32l0.58,1.696L796.207,420.019L796.207,420.019z M809.775,415.376l-0.58-1.695l6.784-2.322l0.58,1.697
                                L809.775,415.376L809.775,415.376z M823.343,410.734l-0.58-1.696l4.701-1.607l0.58,1.695L823.343,410.734L823.343,410.734z"/>
                            <path fill="#E2E2E2" d="M774.274,415.099l-0.58-1.695l6.784-2.321l0.58,1.696L774.274,415.099L774.274,415.099z M787.842,410.458
                                l-0.58-1.697l6.784-2.32l0.58,1.696L787.842,410.458L787.842,410.458z M801.41,405.815l-0.58-1.696l6.784-2.321l0.58,1.696
                                L801.41,405.815L801.41,405.815z M814.978,401.174l-0.58-1.696l4.701-1.608l0.58,1.696L814.978,401.174L814.978,401.174z"/>
                            <path fill="#E2E2E2" d="M765.915,407.329l-0.58-1.695l6.784-2.321l0.58,1.696L765.915,407.329L765.915,407.329z M779.482,402.688
                                l-0.58-1.696l6.784-2.321l0.58,1.696L779.482,402.688L779.482,402.688z M793.05,398.046l-0.58-1.696l6.784-2.321l0.58,1.696
                                L793.05,398.046L793.05,398.046z M806.618,393.404l-0.58-1.696l4.701-1.608l0.58,1.696L806.618,393.404L806.618,393.404z"/>
                            <path fill="#E2E2E2" d="M767.109,394.783l-0.58-1.696l6.784-2.321l0.58,1.696L767.109,394.783L767.109,394.783z M780.677,390.141
                                l-0.58-1.696l6.784-2.321l0.58,1.696L780.677,390.141L780.677,390.141z M794.245,385.5l-0.58-1.696l6.784-2.321l0.58,1.696
                                L794.245,385.5L794.245,385.5z M807.813,380.857l-0.58-1.696l4.701-1.608l0.58,1.696L807.813,380.857L807.813,380.857z"/>
                            <path fill="#E2E2E2" d="M712.108,685.775l-0.516-1.717l5.15-1.548l0.516,1.717L712.108,685.775L712.108,685.775z
                                M722.408,682.679l-0.516-1.717l5.149-1.548l0.516,1.717L722.408,682.679L722.408,682.679z M732.708,679.583l-0.517-1.717
                                l5.15-1.549l0.517,1.717L732.708,679.583L732.708,679.583z M743.007,676.486l-0.516-1.717l5.15-1.548l0.516,1.717
                                L743.007,676.486L743.007,676.486z M753.307,673.391l-0.517-1.717l5.149-1.549l0.517,1.717L753.307,673.391L753.307,673.391z
                                M763.606,670.294l-0.516-1.717l5.15-1.548l0.516,1.717L763.606,670.294L763.606,670.294z M773.906,667.197l-0.516-1.717
                                l5.15-1.548l0.516,1.717L773.906,667.197L773.906,667.197z M784.206,664.102l-0.517-1.717l5.149-1.549l0.517,1.717
                                L784.206,664.102L784.206,664.102z M794.505,661.005l-0.516-1.717l5.15-1.548l0.516,1.717L794.505,661.005L794.505,661.005z
                                M804.805,657.908l-0.516-1.717l5.15-1.548l0.516,1.717L804.805,657.908L804.805,657.908z M815.104,654.813l-0.517-1.717
                                l5.15-1.549l0.517,1.717L815.104,654.813L815.104,654.813z M825.404,651.716l-0.516-1.717l5.15-1.548l0.516,1.717
                                L825.404,651.716L825.404,651.716z M835.704,648.62l-0.517-1.717l5.15-1.549l0.516,1.717L835.704,648.62L835.704,648.62z
                                M846.003,645.523l-0.516-1.717l5.149-1.548l0.516,1.717L846.003,645.523L846.003,645.523z M856.302,642.427l-0.516-1.717
                                l5.15-1.548l0.516,1.717L856.302,642.427L856.302,642.427z M866.602,639.331l-0.516-1.717l5.15-1.549l0.516,1.717
                                L866.602,639.331L866.602,639.331z M876.902,636.234l-0.516-1.717l5.15-1.548l0.516,1.717L876.902,636.234L876.902,636.234z
                                M887.202,633.138l-0.516-1.717l5.149-1.548l0.516,1.717L887.202,633.138L887.202,633.138z M897.501,630.042l-0.516-1.717
                                l5.149-1.549l0.516,1.717L897.501,630.042L897.501,630.042z M907.801,626.945l-0.516-1.717l5.055-1.52l0.516,1.717
                                L907.801,626.945L907.801,626.945z"/>
                            <path fill="#E2E2E2" d="M973.852,605.696l-0.596-1.691l5.071-1.788l0.596,1.691L973.852,605.696L973.852,605.696z
                                M983.996,602.12l-0.596-1.691l5.071-1.788l0.596,1.691L983.996,602.12L983.996,602.12z M994.138,598.544l-0.596-1.691
                                l5.071-1.787l0.596,1.691L994.138,598.544L994.138,598.544z M1004.282,594.969l-0.596-1.691l5.071-1.788l0.596,1.691
                                L1004.282,594.969L1004.282,594.969z M1014.424,591.393l-0.596-1.691l5.071-1.788l0.596,1.691L1014.424,591.393L1014.424,591.393
                                z M1024.568,587.816l-0.596-1.691l5.071-1.788l0.596,1.691L1024.568,587.816L1024.568,587.816z M1034.71,584.24l-0.596-1.691
                                l5.071-1.788l0.596,1.691L1034.71,584.24L1034.71,584.24z M1044.854,580.664l-0.596-1.689l1.882-0.664l0.596,1.689
                                L1044.854,580.664L1044.854,580.664z"/>
                            <path fill="#E2E2E2" d="M740.786,688.763l-0.51-1.719l5.156-1.529l0.51,1.719L740.786,688.763L740.786,688.763z M751.097,685.705
                                l-0.51-1.719l5.156-1.529l0.51,1.719L751.097,685.705L751.097,685.705z M761.408,682.646l-0.51-1.719l5.156-1.529l0.51,1.719
                                L761.408,682.646L761.408,682.646z M771.719,679.589l-0.51-1.719l5.156-1.529l0.51,1.719L771.719,679.589L771.719,679.589z
                                M782.03,676.53l-0.51-1.719l5.156-1.529l0.51,1.719L782.03,676.53L782.03,676.53z M792.341,673.473l-0.51-1.719l5.156-1.529
                                l0.51,1.719L792.341,673.473L792.341,673.473z M802.652,670.414l-0.51-1.719l5.156-1.529l0.51,1.719L802.652,670.414
                                L802.652,670.414z M812.963,667.356l-0.51-1.719l5.156-1.529l0.51,1.719L812.963,667.356L812.963,667.356z M823.274,664.298
                                l-0.51-1.719l5.156-1.529l0.51,1.719L823.274,664.298L823.274,664.298z M833.585,661.24l-0.51-1.719l5.156-1.529l0.51,1.719
                                L833.585,661.24L833.585,661.24z M843.896,658.182l-0.51-1.719l5.155-1.529l0.51,1.719L843.896,658.182L843.896,658.182z
                                M854.208,655.124l-0.51-1.719l5.155-1.529l0.51,1.719L854.208,655.124L854.208,655.124z M864.518,652.065l-0.51-1.719
                                l5.155-1.529l0.51,1.719L864.518,652.065L864.518,652.065z M874.83,649.008l-0.51-1.719l5.155-1.529l0.51,1.719L874.83,649.008
                                L874.83,649.008z M885.14,645.949l-0.51-1.719l5.155-1.529l0.51,1.719L885.14,645.949L885.14,645.949z M895.452,642.892
                                l-0.51-1.719l5.155-1.529l0.51,1.719L895.452,642.892L895.452,642.892z M905.762,639.833l-0.51-1.719l5.155-1.529l0.51,1.719
                                L905.762,639.833L905.762,639.833z"/>
                            <path fill="#E2E2E2" d="M777.838,689.958l-0.525-1.715l5.142-1.574l0.525,1.714L777.838,689.958L777.838,689.958z
                                M788.122,686.807l-0.525-1.714l5.142-1.575l0.525,1.715L788.122,686.807L788.122,686.807z M798.404,683.657l-0.524-1.715
                                l5.142-1.574l0.524,1.715L798.404,683.657L798.404,683.657z M808.688,680.508l-0.525-1.715l5.142-1.575l0.525,1.715
                                L808.688,680.508L808.688,680.508z M818.972,677.357l-0.525-1.715l5.142-1.574l0.525,1.714L818.972,677.357L818.972,677.357z
                                M829.255,674.206l-0.525-1.713l5.142-1.576l0.525,1.714L829.255,674.206L829.255,674.206z M839.538,671.057l-0.525-1.715
                                l5.143-1.575l0.525,1.715L839.538,671.057L839.538,671.057z M849.822,667.906l-0.525-1.715l5.142-1.574l0.525,1.715
                                L849.822,667.906L849.822,667.906z M860.105,664.756l-0.525-1.714l5.142-1.575l0.525,1.715L860.105,664.756L860.105,664.756z
                                M870.388,661.606l-0.525-1.714l5.142-1.576l0.525,1.714L870.388,661.606L870.388,661.606z M880.671,658.456l-0.525-1.715
                                l5.142-1.575l0.525,1.715L880.671,658.456L880.671,658.456z M890.955,655.306l-0.525-1.714l5.142-1.576l0.525,1.714
                                L890.955,655.306L890.955,655.306z M901.238,652.155l-0.525-1.715l5.142-1.574l0.525,1.715L901.238,652.155L901.238,652.155z"/>
                            <path fill="#E2E2E2" d="M810.093,692.353l-0.506-1.719l5.159-1.519l0.506,1.719L810.093,692.353L810.093,692.353z
                                M820.411,689.315l-0.506-1.719l5.159-1.519l0.506,1.719L820.411,689.315L820.411,689.315z M830.728,686.278l-0.506-1.719
                                l5.159-1.519l0.506,1.719L830.728,686.278L830.728,686.278z M841.045,683.241l-0.506-1.719l5.158-1.519l0.506,1.719
                                L841.045,683.241L841.045,683.241z"/>
                            <path fill="#E2E2E2" d="M1063.507,613.457l-0.652-1.67l5.01-1.954l0.652,1.67L1063.507,613.457L1063.507,613.457z
                                M1073.527,609.548l-0.652-1.67l5.01-1.954l0.652,1.67L1073.527,609.548L1073.527,609.548z M1083.546,605.64l-0.652-1.67
                                l5.01-1.954l0.652,1.67L1083.546,605.64L1083.546,605.64z M1093.566,601.73l-0.652-1.67l5.01-1.954l0.652,1.67L1093.566,601.73
                                L1093.566,601.73z"/>
                            <path fill="#E2E2E2" d="M1026.454,603.298l-0.635-1.676l5.028-1.905l0.635,1.676L1026.454,603.298L1026.454,603.298z
                                M1036.511,599.487l-0.635-1.676l5.028-1.905l0.635,1.676L1036.511,599.487L1036.511,599.487z M1046.569,595.678l-0.635-1.676
                                l5.028-1.905l0.635,1.676L1046.569,595.678L1046.569,595.678z M1056.625,591.867l-0.635-1.676l5.029-1.905l0.635,1.676
                                L1056.625,591.867L1056.625,591.867z"/>
                            <path fill="#E2E2E2" d="M1058.767,604.476l-0.732-1.637l4.908-2.197l0.732,1.637L1058.767,604.476L1058.767,604.476z
                                M1068.583,600.08l-0.732-1.637l4.907-2.197l0.732,1.637L1068.583,600.08L1068.583,600.08z M1078.399,595.686l-0.732-1.637
                                l3.06-1.37l0.732,1.637L1078.399,595.686L1078.399,595.686z"/>
                        </g>
                        <g>
                            <path fill="#E2E2E2" d="M1078.958,620.654l-0.488-1.725l5.175-1.464l0.488,1.725L1078.958,620.654L1078.958,620.654z
                                M1089.306,617.728l-0.488-1.725l5.175-1.464l0.488,1.725L1089.306,617.728L1089.306,617.728z M1099.656,614.8l-0.488-1.725
                                l5.174-1.464l0.488,1.725L1099.656,614.8L1099.656,614.8z M1110.004,611.873l-0.488-1.725l5.175-1.464l0.488,1.725
                                L1110.004,611.873L1110.004,611.873z M1120.353,608.945l-0.488-1.725l5.175-1.463l0.488,1.725L1120.353,608.945L1120.353,608.945
                                z M1130.703,606.019l-0.488-1.725l5.174-1.464l0.488,1.725L1130.703,606.019L1130.703,606.019z"/>
                            <path fill="#E2E2E2" d="M1089.715,630.809l-0.502-1.721l5.162-1.508l0.502,1.721L1089.715,630.809L1089.715,630.809z
                                M1100.039,627.792l-0.502-1.721l5.162-1.508l0.502,1.721L1100.039,627.792L1100.039,627.792z M1110.362,624.775l-0.502-1.721
                                l5.162-1.508l0.502,1.721L1110.362,624.775L1110.362,624.775z M1120.685,621.759l-0.502-1.721l5.162-1.508l0.502,1.721
                                L1120.685,621.759L1120.685,621.759z M1131.008,618.742l-0.502-1.721l5.162-1.508l0.502,1.721L1131.008,618.742L1131.008,618.742
                                z M1141.332,615.726l-0.502-1.721l5.162-1.508l0.502,1.721L1141.332,615.726L1141.332,615.726z M1151.655,612.709l-0.502-1.721
                                l5.162-1.508l0.502,1.721L1151.655,612.709L1151.655,612.709z M1161.978,609.693l-0.502-1.721l5.162-1.509l0.502,1.721
                                L1161.978,609.693L1161.978,609.693z M1172.301,606.677l-0.502-1.721l5.162-1.509l0.502,1.721L1172.301,606.677L1172.301,606.677
                                z"/>
                            <path fill="#E2E2E2" d="M1110.583,634.406l-0.414-1.744l5.232-1.241l0.414,1.744L1110.583,634.406L1110.583,634.406z
                                M1121.048,631.924l-0.414-1.744l5.232-1.241l0.414,1.744L1121.048,631.924L1121.048,631.924z M1131.513,629.441l-0.414-1.744
                                l5.231-1.241l0.414,1.744L1131.513,629.441L1131.513,629.441z M1141.977,626.96l-0.414-1.744l5.232-1.241l0.414,1.744
                                L1141.977,626.96L1141.977,626.96z M1152.442,624.478l-0.414-1.744l5.232-1.241l0.414,1.744L1152.442,624.478L1152.442,624.478z
                                M1162.907,621.995l-0.414-1.744l5.232-1.241l0.414,1.744L1162.907,621.995L1162.907,621.995z M1173.372,619.513l-0.414-1.744
                                l5.231-1.241l0.414,1.744L1173.372,619.513L1173.372,619.513z M1183.835,617.03l-0.414-1.744l5.232-1.241l0.414,1.744
                                L1183.835,617.03L1183.835,617.03z M1194.3,614.548l-0.414-1.744l5.232-1.241l0.414,1.744L1194.3,614.548L1194.3,614.548z"/>
                            <path fill="#E2E2E2" d="M1136.312,645.75l-0.484-1.725l5.177-1.456l0.484,1.725L1136.312,645.75L1136.312,645.75z
                                M1146.667,642.838l-0.486-1.725l5.176-1.456l0.486,1.725L1146.667,642.838L1146.667,642.838z M1157.018,639.926l-0.484-1.725
                                l5.177-1.456l0.484,1.725L1157.018,639.926L1157.018,639.926z M1167.372,637.013l-0.484-1.725l5.177-1.456l0.484,1.725
                                L1167.372,637.013L1167.372,637.013z M1177.726,634.101l-0.486-1.725l5.176-1.456l0.486,1.725L1177.726,634.101L1177.726,634.101
                                z M1188.078,631.188l-0.484-1.725l5.177-1.456l0.484,1.725L1188.078,631.188L1188.078,631.188z M1198.431,628.276l-0.484-1.725
                                l5.177-1.456l0.484,1.725L1198.431,628.276L1198.431,628.276z M1208.786,625.364l-0.486-1.725l5.176-1.456l0.486,1.725
                                L1208.786,625.364L1208.786,625.364z M1219.137,622.451l-0.484-1.725l5.177-1.456l0.484,1.725L1219.137,622.451L1219.137,622.451
                                z M1229.491,619.539l-0.484-1.725l2.417-0.68l0.484,1.725L1229.491,619.539L1229.491,619.539z"/>
                            <path fill="#E2E2E2" d="M1172.147,646.948l-0.455-1.734l5.201-1.367l0.455,1.734L1172.147,646.948L1172.147,646.948z
                                M1182.548,644.214l-0.455-1.734l5.201-1.367l0.455,1.734L1182.548,644.214L1182.548,644.214z M1192.951,641.479l-0.455-1.734
                                l5.2-1.367l0.455,1.734L1192.951,641.479L1192.951,641.479z M1203.352,638.746l-0.455-1.734l5.2-1.367l0.455,1.734
                                L1203.352,638.746L1203.352,638.746z M1213.753,636.012l-0.455-1.734l5.201-1.367l0.455,1.734L1213.753,636.012L1213.753,636.012
                                z M1224.155,633.277l-0.455-1.734l5.201-1.367l0.455,1.734L1224.155,633.277L1224.155,633.277z M1234.557,630.543l-0.455-1.734
                                l5.2-1.367l0.455,1.734L1234.557,630.543L1234.557,630.543z M1244.958,627.809l-0.455-1.734l5.2-1.367l0.455,1.734
                                L1244.958,627.809L1244.958,627.809z M1255.36,625.075l-0.455-1.734l5.201-1.367l0.455,1.734L1255.36,625.075L1255.36,625.075z"
                                />
                            <path fill="#E2E2E2" d="M1208.573,649.341l-0.419-1.742l5.228-1.257l0.419,1.742L1208.573,649.341L1208.573,649.341z
                                M1219.03,646.827l-0.42-1.742l5.229-1.257l0.418,1.742L1219.03,646.827L1219.03,646.827z M1229.487,644.314l-0.42-1.742
                                l5.229-1.257l0.419,1.742L1229.487,644.314L1229.487,644.314z M1239.943,641.801l-0.418-1.742l5.229-1.257l0.418,1.742
                                L1239.943,641.801L1239.943,641.801z M1250.402,639.287l-0.419-1.742l5.228-1.257l0.42,1.742L1250.402,639.287L1250.402,639.287z
                                M1260.858,636.773l-0.418-1.742l5.229-1.256l0.418,1.742L1260.858,636.773L1260.858,636.773z M1271.316,634.261l-0.42-1.742
                                l5.229-1.257l0.419,1.742L1271.316,634.261L1271.316,634.261z M1281.772,631.747l-0.418-1.742l3.876-0.932l0.418,1.742
                                L1281.772,631.747L1281.772,631.747z"/>
                            <path fill="#E2E2E2" d="M1230.083,656.509l-0.42-1.742l5.229-1.257l0.419,1.742L1230.083,656.509L1230.083,656.509z
                                M1240.54,653.995l-0.418-1.742l5.229-1.256l0.418,1.742L1240.54,653.995L1240.54,653.995z M1250.997,651.482l-0.419-1.742
                                l5.229-1.257l0.42,1.742L1250.997,651.482L1250.997,651.482z M1261.455,648.969l-0.418-1.742l5.229-1.256l0.418,1.742
                                L1261.455,648.969L1261.455,648.969z M1271.913,646.456l-0.419-1.742l5.228-1.257l0.419,1.742L1271.913,646.456L1271.913,646.456
                                z M1282.369,643.942l-0.418-1.742l5.229-1.256l0.418,1.742L1282.369,643.942L1282.369,643.942z M1292.827,641.43l-0.42-1.742
                                l5.229-1.257l0.419,1.742L1292.827,641.43L1292.827,641.43z M1303.283,638.916l-0.418-1.742l3.874-0.931l0.418,1.742
                                L1303.283,638.916L1303.283,638.916z"/>
                            <path fill="#E2E2E2" d="M1289.405,676.77l-0.752-1.627l4.883-2.254l0.752,1.627L1289.405,676.77L1289.405,676.77z
                                M1299.169,672.263l-0.752-1.627l4.883-2.254l0.752,1.627L1299.169,672.263L1299.169,672.263z M1308.935,667.755l-0.752-1.627
                                l4.882-2.253l0.752,1.627L1308.935,667.755L1308.935,667.755z M1318.7,663.248l-0.752-1.627l4.883-2.254l0.752,1.627
                                L1318.7,663.248L1318.7,663.248z M1328.464,658.741l-0.752-1.627l4.883-2.254l0.752,1.627L1328.464,658.741L1328.464,658.741z
                                M1338.23,654.233l-0.752-1.627l4.882-2.253l0.752,1.627L1338.23,654.233L1338.23,654.233z M1347.995,649.727l-0.752-1.627
                                l4.883-2.254l0.752,1.627L1347.995,649.727L1347.995,649.727z M1357.759,645.22l-0.752-1.627l4.883-2.254l0.752,1.627
                                L1357.759,645.22L1357.759,645.22z"/>
                            <path fill="#E2E2E2" d="M1313.303,679.158l-0.751-1.627l4.884-2.253l0.75,1.627L1313.303,679.158L1313.303,679.158z
                                M1323.07,674.651l-0.752-1.627l4.882-2.253l0.752,1.627L1323.07,674.651L1323.07,674.651z M1332.833,670.145l-0.75-1.627
                                l4.882-2.253l0.752,1.627L1332.833,670.145L1332.833,670.145z M1342.6,665.638l-0.752-1.627l4.882-2.253l0.752,1.627
                                L1342.6,665.638L1342.6,665.638z M1352.365,661.131l-0.752-1.627l4.883-2.253l0.751,1.627L1352.365,661.131L1352.365,661.131z
                                M1362.13,656.624l-0.752-1.627l4.882-2.253l0.752,1.627L1362.13,656.624L1362.13,656.624z M1371.894,652.117l-0.75-1.627
                                l4.883-2.253l0.75,1.627L1371.894,652.117L1371.894,652.117z M1381.661,647.61l-0.751-1.627l4.882-2.253l0.752,1.627
                                L1381.661,647.61L1381.661,647.61z"/>
                            <path fill="#E2E2E2" d="M1334.814,681.551l-0.752-1.627l4.883-2.254l0.752,1.627L1334.814,681.551L1334.814,681.551z
                                M1344.579,677.044l-0.752-1.627l4.883-2.254l0.752,1.627L1344.579,677.044L1344.579,677.044z M1354.344,672.537l-0.752-1.627
                                l4.883-2.254l0.752,1.627L1354.344,672.537L1354.344,672.537z M1364.109,668.03l-0.752-1.627l4.883-2.254l0.752,1.627
                                L1364.109,668.03L1364.109,668.03z M1373.875,663.523l-0.752-1.627l4.883-2.254l0.752,1.627L1373.875,663.523L1373.875,663.523z
                                M1383.639,659.017l-0.752-1.627l4.883-2.254l0.752,1.627L1383.639,659.017L1383.639,659.017z M1393.405,654.509l-0.752-1.627
                                l4.883-2.253l0.751,1.627L1393.405,654.509L1393.405,654.509z M1403.169,650.002l-0.751-1.627l4.882-2.253l0.752,1.627
                                L1403.169,650.002L1403.169,650.002z"/>
                        </g>
                        <g>
                            <path fill="#E2E2E2" d="M553.148,338.637l-0.472-1.729l5.188-1.415l0.472,1.729L553.148,338.637L553.148,338.637z
                                M563.524,335.807l-0.472-1.729l5.188-1.415l0.472,1.729L563.524,335.807L563.524,335.807z M573.9,332.978l-0.472-1.729
                                l5.188-1.415l0.472,1.729L573.9,332.978L573.9,332.978z M584.276,330.147l-0.472-1.729l5.188-1.415l0.472,1.729L584.276,330.147
                                L584.276,330.147z M594.652,327.318l-0.472-1.729l5.188-1.415l0.472,1.729L594.652,327.318L594.652,327.318z M605.028,324.488
                                l-0.472-1.729l5.188-1.415l0.472,1.729L605.028,324.488L605.028,324.488z M615.404,321.658l-0.472-1.729l5.188-1.415l0.472,1.729
                                L615.404,321.658L615.404,321.658z M625.78,318.828l-0.472-1.729l5.188-1.415l0.472,1.729L625.78,318.828L625.78,318.828z
                                M636.156,315.998l-0.472-1.729l5.188-1.415l0.472,1.729L636.156,315.998L636.156,315.998z M646.532,313.168l-0.472-1.729
                                l5.188-1.415l0.472,1.729L646.532,313.168L646.532,313.168z M656.908,310.338l-0.472-1.729l5.188-1.415l0.472,1.729
                                L656.908,310.338L656.908,310.338z M667.284,307.508l-0.472-1.729l5.188-1.415l0.472,1.729L667.284,307.508L667.284,307.508z
                                M677.66,304.679l-0.472-1.729l5.188-1.415l0.472,1.729L677.66,304.679L677.66,304.679z M688.036,301.849l-0.472-1.729
                                l5.188-1.415l0.472,1.729L688.036,301.849L688.036,301.849z M698.413,299.019l-0.472-1.729l5.188-1.415l0.472,1.729
                                L698.413,299.019L698.413,299.019z M708.789,296.189l-0.472-1.729l5.188-1.415l0.472,1.729L708.789,296.189L708.789,296.189z
                                M719.165,293.359l-0.472-1.729l5.188-1.415l0.472,1.729L719.165,293.359L719.165,293.359z M729.541,290.529l-0.472-1.729
                                l5.188-1.415l0.472,1.729L729.541,290.529L729.541,290.529z M739.917,287.699l-0.472-1.729l5.188-1.415l0.472,1.729
                                L739.917,287.699L739.917,287.699z M750.327,284.86l-0.512-1.718l0.028-0.008L750.327,284.86L750.327,284.86z"/>
                            <path fill="#E2E2E2" d="M542.392,354.171l-0.466-1.73l5.192-1.398l0.466,1.73L542.392,354.171L542.392,354.171z M552.777,351.375
                                l-0.466-1.73l5.192-1.398l0.466,1.73L552.777,351.375L552.777,351.375z M563.162,348.579l-0.466-1.73l5.192-1.398l0.466,1.73
                                L563.162,348.579L563.162,348.579z M573.547,345.783l-0.466-1.73l5.193-1.398l0.466,1.73L573.547,345.783L573.547,345.783z
                                M583.932,342.987l-0.466-1.73l5.193-1.398l0.466,1.73L583.932,342.987L583.932,342.987z M594.317,340.191l-0.466-1.73
                                l5.193-1.398l0.466,1.73L594.317,340.191L594.317,340.191z M604.703,337.395l-0.466-1.73l5.192-1.398l0.466,1.73L604.703,337.395
                                L604.703,337.395z M615.088,334.599l-0.466-1.73l5.192-1.398l0.466,1.73L615.088,334.599L615.088,334.599z M625.473,331.803
                                l-0.466-1.73l5.192-1.398l0.466,1.73L625.473,331.803L625.473,331.803z M635.858,329.006l-0.466-1.73l5.193-1.398l0.466,1.73
                                L635.858,329.006L635.858,329.006z M646.243,326.21l-0.466-1.73l5.193-1.398l0.466,1.73L646.243,326.21L646.243,326.21z
                                M656.628,323.415l-0.466-1.73l5.193-1.398l0.466,1.73L656.628,323.415L656.628,323.415z M667.014,320.619l-0.466-1.73
                                l5.192-1.398l0.466,1.73L667.014,320.619L667.014,320.619z M677.399,317.822l-0.466-1.73l5.192-1.398l0.466,1.73L677.399,317.822
                                L677.399,317.822z M687.784,315.026l-0.466-1.73l5.192-1.398l0.466,1.73L687.784,315.026L687.784,315.026z M698.169,312.23
                                l-0.466-1.73l5.193-1.398l0.466,1.73L698.169,312.23L698.169,312.23z M708.554,309.434l-0.466-1.73l5.193-1.398l0.466,1.73
                                L708.554,309.434L708.554,309.434z M718.939,306.638l-0.466-1.73l5.193-1.398l0.466,1.73L718.939,306.638L718.939,306.638z
                                M729.325,303.842l-0.466-1.73l5.192-1.398l0.466,1.73L729.325,303.842L729.325,303.842z M739.71,301.046l-0.466-1.73
                                l5.192-1.398l0.466,1.73L739.71,301.046L739.71,301.046z M750.095,298.25l-0.466-1.73l5.192-1.398l0.466,1.73L750.095,298.25
                                L750.095,298.25z"/>
                            <path fill="#E2E2E2" d="M501.778,430.646l-0.497-1.723l5.167-1.491l0.497,1.723L501.778,430.646L501.778,430.646z
                                M512.111,427.663l-0.497-1.723l5.167-1.492l0.497,1.723L512.111,427.663L512.111,427.663z M522.444,424.681l-0.497-1.723
                                l5.167-1.492l0.497,1.723L522.444,424.681L522.444,424.681z M532.778,421.697l-0.497-1.723l5.167-1.491l0.497,1.723
                                L532.778,421.697L532.778,421.697z M543.111,418.714l-0.497-1.721l5.167-1.492l0.497,1.722L543.111,418.714L543.111,418.714z
                                M553.444,415.731l-0.497-1.723l5.167-1.49l0.497,1.723L553.444,415.731L553.444,415.731z M563.777,412.749l-0.497-1.723
                                l5.167-1.491l0.497,1.723L563.777,412.749L563.777,412.749z M574.11,409.767l-0.497-1.723l5.167-1.491l0.497,1.723
                                L574.11,409.767L574.11,409.767z M584.443,406.784l-0.497-1.723l5.167-1.492l0.497,1.722L584.443,406.784L584.443,406.784z
                                M594.776,403.801l-0.497-1.723l5.167-1.491l0.497,1.722L594.776,403.801L594.776,403.801z M605.109,400.818l-0.497-1.723
                                l5.167-1.491l0.497,1.723L605.109,400.818L605.109,400.818z M615.442,397.835l-0.497-1.723l2.249-0.649l0.497,1.723
                                L615.442,397.835L615.442,397.835z"/>
                            <path fill="#E2E2E2" d="M508.922,413.922l-0.444-1.736l5.21-1.332l0.444,1.736L508.922,413.922L508.922,413.922z
                                M519.342,411.258l-0.444-1.736l5.21-1.332l0.444,1.736L519.342,411.258L519.342,411.258z M529.762,408.595l-0.444-1.736
                                l5.21-1.332l0.444,1.736L529.762,408.595L529.762,408.595z M540.181,405.931l-0.444-1.737l5.21-1.332l0.444,1.736
                                L540.181,405.931L540.181,405.931z M550.601,403.266l-0.444-1.736l5.21-1.332l0.444,1.736L550.601,403.266L550.601,403.266z
                                M561.021,400.602l-0.444-1.736l5.21-1.332l0.444,1.736L561.021,400.602L561.021,400.602z M571.441,397.938l-0.444-1.736
                                l5.21-1.332l0.444,1.736L571.441,397.938L571.441,397.938z M581.861,395.274l-0.444-1.736l5.21-1.332l0.444,1.736
                                L581.861,395.274L581.861,395.274z M592.281,392.61l-0.444-1.736l5.21-1.332l0.444,1.736L592.281,392.61L592.281,392.61z
                                M602.7,389.946l-0.444-1.736l5.21-1.332l0.444,1.736L602.7,389.946L602.7,389.946z M613.12,387.283l-0.444-1.736l0.96-0.246
                                l0.444,1.736L613.12,387.283L613.12,387.283z"/>
                            <path fill="#E2E2E2" d="M516.122,401.965l-0.504-1.721l5.161-1.511l0.504,1.721L516.122,401.965L516.122,401.965z
                                M526.443,398.944l-0.504-1.721l5.161-1.511l0.504,1.721L526.443,398.944L526.443,398.944z M536.766,395.923l-0.504-1.721
                                l5.161-1.511l0.504,1.721L536.766,395.923L536.766,395.923z M547.087,392.901l-0.503-1.721l5.161-1.51l0.503,1.721
                                L547.087,392.901L547.087,392.901z M557.409,389.88l-0.503-1.721l5.161-1.51l0.503,1.721L557.409,389.88L557.409,389.88z
                                M567.731,386.859l-0.504-1.721l5.161-1.51l0.504,1.721L567.731,386.859L567.731,386.859z M578.053,383.838l-0.504-1.721
                                l5.161-1.511l0.504,1.721L578.053,383.838L578.053,383.838z M588.375,380.817l-0.504-1.721l5.161-1.511l0.504,1.721
                                L588.375,380.817L588.375,380.817z M598.697,377.796l-0.504-1.721l5.161-1.511l0.504,1.721L598.697,377.796L598.697,377.796z
                                M609.019,374.775l-0.504-1.721l5.091-1.49l0.504,1.721L609.019,374.775L609.019,374.775z"/>
                            <path fill="#E2E2E2" d="M528.071,385.232l-0.503-1.721l5.161-1.51l0.503,1.721L528.071,385.232L528.071,385.232z
                                M538.394,382.212l-0.504-1.721l5.161-1.511l0.504,1.721L538.394,382.212L538.394,382.212z M548.715,379.191l-0.503-1.721
                                l5.161-1.51l0.503,1.721L548.715,379.191L548.715,379.191z M559.038,376.17l-0.503-1.721l5.161-1.51l0.503,1.721L559.038,376.17
                                L559.038,376.17z M569.36,373.15l-0.504-1.721l5.161-1.511l0.504,1.721L569.36,373.15L569.36,373.15z M579.682,370.129
                                l-0.503-1.721l5.162-1.51l0.503,1.721L579.682,370.129L579.682,370.129z M590.004,367.108l-0.504-1.721l5.161-1.51l0.504,1.721
                                L590.004,367.108L590.004,367.108z M600.326,364.088l-0.504-1.721l5.161-1.511l0.504,1.721L600.326,364.088L600.326,364.088z
                                M610.648,361.067l-0.503-1.721l5.161-1.51l0.503,1.721L610.648,361.067L610.648,361.067z M620.97,358.046l-0.504-1.721
                                l5.091-1.49l0.504,1.721L620.97,358.046L620.97,358.046z"/>
                            <path fill="#E2E2E2" d="M532.852,372.088l-0.503-1.721l5.161-1.51l0.503,1.721L532.852,372.088L532.852,372.088z
                                M543.173,369.067l-0.503-1.721l5.161-1.51l0.503,1.721L543.173,369.067L543.173,369.067z M553.496,366.046l-0.504-1.721
                                l5.161-1.511l0.504,1.721L553.496,366.046L553.496,366.046z M563.818,363.025l-0.504-1.721l5.161-1.511l0.504,1.721
                                L563.818,363.025L563.818,363.025z M574.14,360.004l-0.503-1.721l5.161-1.51l0.503,1.721L574.14,360.004L574.14,360.004z
                                M584.462,356.983l-0.503-1.721l5.161-1.51l0.503,1.721L584.462,356.983L584.462,356.983z M594.784,353.963l-0.504-1.721
                                l5.161-1.511l0.504,1.721L594.784,353.963L594.784,353.963z M605.106,350.942l-0.503-1.721l5.161-1.51l0.503,1.721
                                L605.106,350.942L605.106,350.942z M615.428,347.921l-0.503-1.721l5.161-1.51l0.503,1.721L615.428,347.921L615.428,347.921z
                                M625.75,344.9l-0.503-1.721l5.091-1.49l0.503,1.721L625.75,344.9L625.75,344.9z"/>
                            <path fill="#E2E2E2" d="M702.535,323.098l-0.488-1.725l6.899-1.953l0.488,1.725L702.535,323.098L702.535,323.098z
                                M716.333,319.193l-0.488-1.725l6.899-1.953l0.488,1.725L716.333,319.193L716.333,319.193z M730.131,315.288l-0.488-1.725
                                l6.899-1.953l0.488,1.725L730.131,315.288L730.131,315.288z M743.929,311.383l-0.488-1.725l6.899-1.953l0.488,1.725
                                L743.929,311.383L743.929,311.383z M757.727,307.479l-0.488-1.725l6.899-1.953l0.488,1.725L757.727,307.479L757.727,307.479z"/>
                            <path fill="#E2E2E2" d="M709.698,335.646l-0.476-1.729l6.913-1.904l0.476,1.729L709.698,335.646L709.698,335.646z
                                M723.524,331.838l-0.477-1.729l6.912-1.904l0.477,1.729L723.524,331.838L723.524,331.838z M737.348,328.029l-0.476-1.729
                                l6.913-1.904l0.476,1.729L737.348,328.029L737.348,328.029z M751.174,324.221l-0.477-1.729l6.912-1.904l0.477,1.729
                                L751.174,324.221L751.174,324.221z M764.998,320.413l-0.476-1.729l3.249-0.895l0.476,1.729L764.998,320.413L764.998,320.413z"/>
                            <path fill="#E2E2E2" d="M718.655,346.402l-0.463-1.731l6.927-1.852l0.463,1.731L718.655,346.402L718.655,346.402z
                                M732.508,342.699l-0.463-1.731l6.927-1.852l0.463,1.731L732.508,342.699L732.508,342.699z M746.362,338.996l-0.463-1.731
                                l6.927-1.851l0.463,1.731L746.362,338.996L746.362,338.996z M760.215,335.293l-0.463-1.731l6.927-1.852l0.463,1.731
                                L760.215,335.293L760.215,335.293z M774.069,331.59l-0.463-1.731l4.926-1.317l0.463,1.731L774.069,331.59L774.069,331.59z"/>
                            <path fill="#E2E2E2" d="M734.194,353.57l-0.475-1.729l6.914-1.899l0.475,1.729L734.194,353.57L734.194,353.57z M748.021,349.772
                                l-0.475-1.729l6.914-1.899l0.475,1.729L748.021,349.772L748.021,349.772z M761.85,345.974l-0.475-1.729l6.914-1.899l0.475,1.729
                                L761.85,345.974L761.85,345.974z M775.677,342.176l-0.475-1.729l6.914-1.899l0.475,1.729L775.677,342.176L775.677,342.176z"/>
                            <path fill="#E2E2E2" d="M746.742,361.34l-0.475-1.729l6.914-1.899l0.475,1.729L746.742,361.34L746.742,361.34z M760.569,357.541
                                l-0.475-1.729l6.914-1.899l0.475,1.729L760.569,357.541L760.569,357.541z M774.397,353.743l-0.475-1.729l6.914-1.899l0.475,1.729
                                L774.397,353.743L774.397,353.743z M788.225,349.944l-0.475-1.729l6.914-1.899l0.475,1.729L788.225,349.944L788.225,349.944z"/>
                            <path fill="#E2E2E2" d="M559.129,324.295l-0.483-1.726l5.178-1.451l0.483,1.726L559.129,324.295L559.129,324.295z
                                M569.485,321.393l-0.483-1.726l5.178-1.451l0.483,1.726L569.485,321.393L569.485,321.393z M579.841,318.491l-0.484-1.726
                                l5.178-1.452l0.484,1.726L579.841,318.491L579.841,318.491z M590.197,315.588l-0.483-1.726l5.178-1.451l0.483,1.726
                                L590.197,315.588L590.197,315.588z M600.553,312.686l-0.483-1.726l5.178-1.451l0.483,1.726L600.553,312.686L600.553,312.686z
                                M610.909,309.783l-0.483-1.726l5.178-1.451l0.483,1.726L610.909,309.783L610.909,309.783z M621.265,306.881l-0.483-1.726
                                l5.178-1.451l0.483,1.726L621.265,306.881L621.265,306.881z M631.621,303.978l-0.483-1.726l5.178-1.451l0.483,1.726
                                L631.621,303.978L631.621,303.978z M641.977,301.076l-0.483-1.726l5.178-1.451l0.483,1.726L641.977,301.076L641.977,301.076z
                                M652.333,298.173l-0.483-1.726l5.178-1.451l0.483,1.726L652.333,298.173L652.333,298.173z M662.688,295.271l-0.483-1.726
                                l5.178-1.451l0.483,1.726L662.688,295.271L662.688,295.271z M673.044,292.369l-0.483-1.726l5.178-1.452l0.483,1.726
                                L673.044,292.369L673.044,292.369z M683.4,289.466l-0.483-1.726l5.178-1.451l0.483,1.726L683.4,289.466L683.4,289.466z
                                M693.756,286.563l-0.483-1.726l5.178-1.451l0.483,1.726L693.756,286.563L693.756,286.563z M704.112,283.661l-0.483-1.726
                                l5.178-1.451l0.483,1.726L704.112,283.661L704.112,283.661z M714.468,280.759l-0.483-1.726l5.178-1.451l0.483,1.726
                                L714.468,280.759L714.468,280.759z M724.824,277.856l-0.483-1.726l5.178-1.451l0.483,1.726L724.824,277.856L724.824,277.856z
                                M735.18,274.954l-0.483-1.726l5.178-1.451l0.483,1.726L735.18,274.954L735.18,274.954z M745.536,272.051l-0.483-1.726
                                l1.206-0.338l0.483,1.726L745.536,272.051L745.536,272.051z"/>
                            <path fill="#E2E2E2" d="M587.813,302.783l-0.489-1.725l5.174-1.467l0.489,1.725L587.813,302.783L587.813,302.783z M598.16,299.85
                                l-0.489-1.725l5.174-1.467l0.489,1.725L598.16,299.85L598.16,299.85z M608.507,296.917l-0.489-1.725l5.174-1.467l0.489,1.725
                                L608.507,296.917L608.507,296.917z M618.854,293.984l-0.489-1.725l5.173-1.467l0.489,1.725L618.854,293.984L618.854,293.984z
                                M629.202,291.051l-0.489-1.725l5.174-1.467l0.489,1.725L629.202,291.051L629.202,291.051z M639.549,288.118l-0.489-1.725
                                l5.174-1.467l0.489,1.725L639.549,288.118L639.549,288.118z M649.896,285.185l-0.489-1.725l5.174-1.467l0.489,1.725
                                L649.896,285.185L649.896,285.185z M660.244,282.251l-0.489-1.725l5.173-1.467l0.489,1.725L660.244,282.251L660.244,282.251z
                                M670.591,279.318l-0.489-1.725l5.174-1.467l0.489,1.725L670.591,279.318L670.591,279.318z M680.938,276.385l-0.489-1.725
                                l5.174-1.467l0.489,1.725L680.938,276.385L680.938,276.385z M691.285,273.452l-0.489-1.725l5.174-1.467l0.489,1.725
                                L691.285,273.452L691.285,273.452z M701.633,270.519l-0.489-1.725l5.173-1.467l0.489,1.725L701.633,270.519L701.633,270.519z
                                M711.98,267.586l-0.489-1.725l5.174-1.467l0.489,1.725L711.98,267.586L711.98,267.586z M722.327,264.653l-0.489-1.725
                                l5.174-1.467l0.489,1.725L722.327,264.653L722.327,264.653z M732.675,261.72l-0.489-1.725l5.173-1.467l0.489,1.725
                                L732.675,261.72L732.675,261.72z"/>
                            <path fill="#E2E2E2" d="M637.415,277.687l-0.509-1.719l5.157-1.526l0.509,1.719L637.415,277.687L637.415,277.687z
                                M647.728,274.635l-0.509-1.719l5.157-1.526l0.509,1.719L647.728,274.635L647.728,274.635z M658.041,271.584l-0.509-1.719
                                l5.157-1.526l0.509,1.719L658.041,271.584L658.041,271.584z M668.354,268.533l-0.509-1.719l5.157-1.526l0.509,1.719
                                L668.354,268.533L668.354,268.533z M678.667,265.481l-0.509-1.719l5.156-1.526l0.509,1.719L678.667,265.481L678.667,265.481z
                                M688.98,262.43l-0.509-1.719l5.156-1.526l0.509,1.719L688.98,262.43L688.98,262.43z M699.293,259.379l-0.509-1.719l5.156-1.526
                                l0.509,1.719L699.293,259.379L699.293,259.379z M709.606,256.328l-0.509-1.719l5.157-1.526l0.509,1.719L709.606,256.328
                                L709.606,256.328z M719.919,253.276l-0.509-1.719l5.157-1.526l0.509,1.719L719.919,253.276L719.919,253.276z M730.232,250.225
                                l-0.509-1.719l5.157-1.526l0.509,1.719L730.232,250.225L730.232,250.225z"/>
                            <path fill="#E2E2E2" d="M663.693,256.18l-0.485-1.726l6.903-1.94l0.485,1.726L663.693,256.18L663.693,256.18z M677.499,252.301
                                l-0.485-1.726l6.903-1.94l0.485,1.726L677.499,252.301L677.499,252.301z M691.304,248.421l-0.485-1.726l6.903-1.94l0.485,1.726
                                L691.304,248.421L691.304,248.421z M705.109,244.542l-0.485-1.726l6.903-1.94l0.485,1.726L705.109,244.542L705.109,244.542z
                                M718.914,240.662l-0.484-1.726l6.902-1.939l0.485,1.726L718.914,240.662L718.914,240.662z M732.719,236.782l-0.485-1.726
                                l3.268-0.918l0.485,1.726L732.719,236.782L732.719,236.782z"/>
                            <path fill="#E2E2E2" d="M681.619,238.254l-0.484-1.726l6.904-1.936l0.484,1.726L681.619,238.254L681.619,238.254z
                                M695.426,234.383l-0.484-1.726l6.903-1.936l0.484,1.726L695.426,234.383L695.426,234.383z M709.233,230.511l-0.484-1.726
                                l6.904-1.936l0.484,1.726L709.233,230.511L709.233,230.511z M723.041,226.64l-0.484-1.726l6.904-1.936l0.484,1.726
                                L723.041,226.64L723.041,226.64z M736.848,222.768l-0.484-1.726l6.904-1.936l0.484,1.726L736.848,222.768L736.848,222.768z"/>
                            <path fill="#E2E2E2" d="M703.131,219.137l-0.489-1.725l5.173-1.468l0.489,1.725L703.131,219.137L703.131,219.137z
                                M713.478,216.201l-0.489-1.725l5.173-1.468l0.489,1.725L713.478,216.201L713.478,216.201z M723.824,213.266l-0.489-1.725
                                l5.173-1.468l0.489,1.725L723.824,213.266L723.824,213.266z M734.17,210.33l-0.489-1.725l5.173-1.468l0.489,1.725L734.17,210.33
                                L734.17,210.33z M744.517,207.395l-0.489-1.725l5.173-1.468l0.489,1.725L744.517,207.395L744.517,207.395z M754.864,204.459
                                l-0.489-1.725l5.173-1.468l0.489,1.725L754.864,204.459L754.864,204.459z M765.21,201.523l-0.489-1.725l5.173-1.468l0.489,1.725
                                L765.21,201.523L765.21,201.523z M775.557,198.588l-0.489-1.725l5.173-1.468l0.489,1.725L775.557,198.588L775.557,198.588z
                                M785.904,195.652l-0.489-1.725l1.472-0.418l0.489,1.725L785.904,195.652L785.904,195.652z"/>
                            <path fill="#E2E2E2" d="M710.301,204.199l-0.489-1.725l5.173-1.468l0.489,1.725L710.301,204.199L710.301,204.199z
                                M720.647,201.264l-0.489-1.725l5.173-1.467l0.489,1.725L720.647,201.264L720.647,201.264z M730.994,198.329l-0.489-1.725
                                l5.173-1.468l0.489,1.725L730.994,198.329L730.994,198.329z M741.341,195.393l-0.489-1.725l5.173-1.468l0.489,1.725
                                L741.341,195.393L741.341,195.393z M751.688,192.458l-0.489-1.725l5.173-1.468l0.489,1.725L751.688,192.458L751.688,192.458z
                                M762.034,189.522l-0.489-1.725l5.173-1.468l0.489,1.725L762.034,189.522L762.034,189.522z M772.381,186.587l-0.489-1.725
                                l5.173-1.467l0.489,1.725L772.381,186.587L772.381,186.587z M782.728,183.652l-0.489-1.725l5.173-1.468l0.489,1.725
                                L782.728,183.652L782.728,183.652z M793.074,180.716l-0.489-1.725l1.472-0.417l0.489,1.725L793.074,180.716L793.074,180.716z"/>
                            <path fill="#E2E2E2" d="M719.86,189.262l-0.489-1.725l5.173-1.468l0.489,1.725L719.86,189.262L719.86,189.262z M730.207,186.326
                                l-0.489-1.725l5.173-1.467l0.489,1.725L730.207,186.326L730.207,186.326z M740.554,183.391l-0.489-1.725l5.173-1.468l0.489,1.725
                                L740.554,183.391L740.554,183.391z M750.9,180.456l-0.489-1.725l5.173-1.468l0.489,1.725L750.9,180.456L750.9,180.456z
                                M761.247,177.521l-0.489-1.725l5.173-1.468l0.489,1.725L761.247,177.521L761.247,177.521z M771.594,174.585l-0.489-1.725
                                l5.173-1.468l0.489,1.725L771.594,174.585L771.594,174.585z M781.94,171.649l-0.489-1.725l5.173-1.467l0.489,1.725
                                L781.94,171.649L781.94,171.649z M792.287,168.714l-0.489-1.725l5.173-1.468l0.489,1.725L792.287,168.714L792.287,168.714z
                                M802.634,165.779l-0.489-1.725l1.472-0.417l0.489,1.725L802.634,165.779L802.634,165.779z"/>
                            <path fill="#E2E2E2" d="M729.408,174.925l-0.464-1.731l5.194-1.393l0.465,1.731L729.408,174.925L729.408,174.925z
                                M739.796,172.139l-0.464-1.731l5.194-1.393l0.464,1.731L739.796,172.139L739.796,172.139z M750.184,169.354l-0.464-1.731
                                l5.194-1.393l0.465,1.731L750.184,169.354L750.184,169.354z M760.572,166.567l-0.465-1.731l5.194-1.393l0.465,1.731
                                L760.572,166.567L760.572,166.567z M770.959,163.781l-0.464-1.731l5.194-1.393l0.464,1.731L770.959,163.781L770.959,163.781z
                                M781.348,160.995l-0.464-1.731l5.194-1.393l0.464,1.731L781.348,160.995L781.348,160.995z"/>
                            <path fill="#E2E2E2" d="M742.543,161.786l-0.44-1.737l5.212-1.323l0.44,1.737L742.543,161.786L742.543,161.786z M752.968,159.14
                                l-0.44-1.737l5.212-1.322l0.44,1.737L752.968,159.14L752.968,159.14z M763.392,156.495l-0.44-1.737l5.212-1.323l0.44,1.737
                                L763.392,156.495L763.392,156.495z M773.817,153.85l-0.44-1.737l5.212-1.322l0.44,1.737L773.817,153.85L773.817,153.85z
                                M784.241,151.205l-0.44-1.737l0.712-0.181l0.44,1.737L784.241,151.205L784.241,151.205z"/>
                            <path fill="#E2E2E2" d="M756.856,147.452l-0.388-1.75l5.25-1.165l0.388,1.75L756.856,147.452L756.856,147.452z M767.356,145.123
                                l-0.388-1.75l5.25-1.165l0.388,1.75L767.356,145.123L767.356,145.123z M777.856,142.794l-0.389-1.75l0.422-0.094l0.389,1.75
                                L777.856,142.794L777.856,142.794z"/>
                        </g>
                    </g>
                    
                        <rect x="190.079" y="164.309" transform="matrix(-0.7442 -0.668 0.668 -0.7442 236.6065 420.3752)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" width="17.444" height="1.141"/>
                    
                        <rect x="175.006" y="166.501" transform="matrix(-0.7557 -0.6549 0.6549 -0.7557 213.0743 413.624)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" width="17.354" height="1.141"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#FAFAFA" d="M440.868,326.87l7.771-33.65l-7.013,1.521l-0.375,0.127
                        L432,334.938C434.665,332.681,437.73,329.946,440.868,326.87z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#FCFCFC" d="M450.189,316.706l5.793-25.084l-7.375,1.604l-7.779,33.682
                        C443.99,323.81,447.222,320.364,450.189,316.706z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#F4F4F4" d="M398.153,258.901c17.149-6.566,166.105-49.338,183.441-59.088
                        c38.488-13.809,30.707-67.665-15.098-57.988c-36.695,7.752-151.016,34.625-171.568,46.574
                        c-21.93,12.754-28.821,36.746-26.142,48.988C372.409,253.927,381.003,265.47,398.153,258.901z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#F4F4F4" d="M442.965,552.868c21.507,25.096,45.406,52.58,74.086,78.869
                        c29.897,27.41,37.045,40.629,27.485,51.385c-19.721,22.188-75.284-10.756-106.355-27.484c-15.601-8.4-63.334-40.631-81.259-60.943
                        c-18.601-23.096-18.237-70.598-10.752-102.771s22.1-72.895,39.435-72.895c17.336,0,14.563,43.08,16.729,66.922
                        C404.5,509.78,426.417,533.558,442.965,552.868z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#E0E0E0" d="M7.922,446.907c118.945,70.496,448.113,265.35,467.614,274.213
                        c23.829,10.83,109.394,11.26,109.394,11.26h322.039c0,0,213.558,0,345.605,0s200.761,43.563,200.761,80.414h-37.644
                        c0-33.268-102.769-54.385-166.702-54.385c-63.933,0-442.745,0-442.745,0l-11.375,16.729H523.097c0,0-48.484,7.598-119.935-38.477
                        c-40.457-26.088-302.01-178.311-395.24-232.338V446.907z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M817.191,745.747c-22.908,1.656-69.571,4.971-118.426,8.121
                        c-31.017,2.004-62.911,3.939-90.127,5.336c-6.706,0.348-13.162,0.715-19.36,1.064c-64.642,3.674-100.921,5.732-168.661-31.645
                        C372.553,702.11,216.413,608.729,99.6,538.878c-36.208-21.658-68.656-41.063-92.111-55.023l0.87-1.467
                        c24.886,14.814,56.688,33.832,92.12,55.02c116.787,69.842,272.889,163.193,320.961,189.715
                        c67.316,37.141,103.418,35.094,167.741,31.441c6.158-0.35,12.577-0.717,19.369-1.064c27.139-1.396,59.042-3.332,90.108-5.338
                        c48.745-3.145,95.466-6.463,118.413-8.119L817.191,745.747z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M8.375,459.938c67.199,41.623,213.305,130.061,307.118,186.842
                        c33.295,20.152,59.91,36.268,74.717,45.275c6.93,4.215,14.179,8.385,20.781,12.074c10.167,5.684,18.867,10.258,22.545,12.135
                        c3.219,1.643,6.335,3.32,9.428,4.986c26.46,14.242,51.161,27.541,151.682,22.844c35.18-1.643,80.744-4.422,126.998-7.488
                        c71.255-4.721,144.205-10.117,183.197-13.061l0.127,1.709c-38.94,2.934-111.821,8.33-183.218,13.061
                        c-46.204,3.063-91.767,5.842-127.033,7.492c-100.99,4.715-125.891-8.691-152.565-23.053c-3.053-1.645-6.132-3.301-9.392-4.963
                        c-3.657-1.867-12.349-6.441-22.599-12.17c-6.632-3.703-13.902-7.889-20.843-12.109c-13.44-8.176-40.674-24.662-74.709-45.266
                        c-93.63-56.67-239.452-144.936-307.135-186.854L8.375,459.938z"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" points="286.592,611.39 282.335,628.671 281.229,628.394 
                        285.485,611.12 	"/>
                    
                        <rect x="252.836" y="605.941" transform="matrix(0.2194 -0.9756 0.9756 0.2194 -387.5436 728.6542)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" width="17.485" height="1.139"/>
                    
                        <rect x="263.823" y="612.455" transform="matrix(0.2184 -0.9759 0.9759 0.2184 -385.162 745.183)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" width="17.536" height="1.138"/>
                    
                        <rect x="231.297" y="591.961" transform="matrix(0.2194 -0.9756 0.9756 0.2194 -391.3129 695.9717)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" width="15.965" height="1.139"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" points="252.243,591.126 248.889,608.376 247.767,608.163 
                        251.123,590.917 	"/>
                    
                        <rect x="220.14" y="585.937" transform="matrix(0.2195 -0.9756 0.9756 0.2195 -393.9014 680.6171)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" width="16.618" height="1.137"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" points="218.912,571.487 215.553,587.44 214.438,587.21 
                        217.798,571.255 	"/>
                    <g>
                        <path fillRule="evenodd" clipRule="evenodd" fill="#D1D2D3" d="M787.109,60.684c0,0,1.902,0.238,6.779,0.357
                            c4.879,0.119,9.279-0.713,9.279-0.713v15.701c0,0-4.396,1.07-8.565,1.07c-4.757,0-7.493-0.955-7.493-0.955V60.684z"/>
                        <path fillRule="evenodd" clipRule="evenodd" fill="#808184" d="M802.626,60.422l0.541,0.119v0.09
                            c-0.378,0.189-1.663,0.813-3.926,1.605c-1.327,0.461-1.895,1.115-2.612,2.254c-0.886,1.4-0.837,4.162-0.837,4.162l1.906,0.48
                            l5.469-1.006v0.084c-0.735,0.316-2.452,1.072-3.565,1.75c-1.334,0.816-2.213,1.184-3.218,2.381
                            c-0.988,1.184-1.295,2.994-1.299,4.752l-0.479,0.006l0,0c-4.76,0-7.497-0.951-7.497-0.951V60.684l0,0c0,0,1.902,0.242,6.779,0.361
                            l0,0C797.747,61.137,801.305,60.635,802.626,60.422z"/>
                        <path fillRule="evenodd" clipRule="evenodd" fill="#E8E093" d="M786.873,60.447c0,0-0.833-4.402-0.119-6.545
                            c0.714-2.139,2.139-6.301,8.321-6.182c6.19,0.119,7.62,3.641,8.21,5.115c1.188,2.971,0.119,7.258,0.119,7.258
                            s-5.113,1.301-8.448,1.42C791.63,61.633,786.873,60.447,786.873,60.447z"/>
                        <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M794.417,53.088c0.989-0.082,0.247,0,1.895,0.328
                            c1.647,0.33,3.131,0.33,4.779,0c1.648-0.328,1.73-0.822,2.389-0.248c0.66,0.578,0.331-1.152-0.247-1.977
                            c-0.573-0.826-2.883-2.061-5.191-2.061c-2.304,0-3.377,1.072-4.117,2.061C793.183,52.178,793.428,53.168,794.417,53.088z"/>
                        <path fillRule="evenodd" clipRule="evenodd" fill="#D1D2D3" d="M786.055,53.781c0.007,0.002,2.035,1.582,8.438,1.697
                            c5.744,0.105,9.714-1.4,9.721-1.404l0.287,0.803c-0.01,0.002-4.114,1.561-10.021,1.449c-6.682-0.119-8.928-1.863-8.933-1.867
                            L786.055,53.781z"/>
                        <path fillRule="evenodd" clipRule="evenodd" fill="#D1D2D3" d="M786.025,66.863c0.008,0.008,2.164,1.373,8.584,1.488
                            c5.755,0.105,9.599-1.166,9.605-1.17l0.287,0.799c-0.01,0.004-4.002,1.324-9.902,1.219c-6.662-0.123-9.015-1.607-9.022-1.611
                            L786.025,66.863z"/>
                        <path fillRule="evenodd" clipRule="evenodd" fill="#D1D2D3" d="M821.816,58.748c0,0,2.275,0.287,8.104,0.428
                            c5.827,0.141,11.09-0.855,11.09-0.855v18.766c0,0-5.253,1.279-10.235,1.279c-5.689,0-8.959-1.135-8.959-1.135V58.748z"/>
                        <path fillRule="evenodd" clipRule="evenodd" fill="#808184" d="M840.364,58.434l0.646,0.143v0.105
                            c-0.45,0.229-1.988,0.973-4.695,1.916c-1.589,0.553-2.265,1.34-3.123,2.697c-1.055,1.676-0.997,4.977-0.997,4.977l2.275,0.57
                            l6.54-1.201v0.098c-0.883,0.379-2.933,1.283-4.267,2.098c-1.592,0.969-2.645,1.414-3.838,2.842
                            c-1.186,1.418-1.551,3.578-1.557,5.68l-0.574,0.008l0,0c-5.686,0-8.959-1.135-8.959-1.135V58.748l0,0c0,0,2.279,0.283,8.104,0.428
                            l0,0C834.535,59.287,838.789,58.686,840.364,58.434z"/>
                        <path fillRule="evenodd" clipRule="evenodd" fill="#E8E093" d="M821.532,58.461c0,0-0.992-5.258-0.14-7.814
                            c0.851-2.561,2.56-7.535,9.949-7.396c7.395,0.145,9.106,4.355,9.809,6.113c1.424,3.557,0.146,8.67,0.146,8.67
                            s-6.114,1.566-10.094,1.705C827.219,59.887,821.532,58.461,821.532,58.461z"/>
                        <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M830.462,53.748c2.842,0.568,8.814,0.201,8.814,0.201h3.806
                            c0,0-0.007-3.391-1.001-4.809c-0.996-1.422-8.613-4.406-11.016-1.002C829.787,49.844,827.623,53.178,830.462,53.748z"/>
                        <path fillRule="evenodd" clipRule="evenodd" fill="#D1D2D3" d="M820.564,56.924c0.007,0.004,2.43,1.887,10.076,2.027
                            c6.867,0.127,11.607-1.674,11.619-1.68l0.337,0.959c-0.011,0.008-4.91,1.865-11.974,1.736c-7.993-0.146-10.677-2.234-10.682-2.24
                            L820.564,56.924z"/>
                        <path fillRule="evenodd" clipRule="evenodd" fill="#D1D2D3" d="M820.513,66.129c0.007,0.006,2.589,1.639,10.271,1.777
                            c6.876,0.123,11.486-1.396,11.498-1.404l0.288,0.979c-0.011,0.004-4.756,1.568-11.804,1.441
                            c-7.96-0.146-10.764-1.914-10.774-1.922L820.513,66.129z"/>
                        <path fillRule="evenodd" clipRule="evenodd" fill="#808184" d="M739.896,87.779c0,1.283,8.114,2.326,18.127,2.326
                            c10.011,0,18.124-1.043,18.124-2.326s-8.113-2.326-18.124-2.326C748.01,85.453,739.896,86.496,739.896,87.779L739.896,87.779z
                            M729.88,88.836c0-3.736,12.279-6.768,27.429-6.768c15.145,0,27.421,3.031,27.421,6.768c0,3.738-12.276,6.768-27.421,6.768
                            C742.16,95.604,729.88,92.574,729.88,88.836z"/>
                        <polygon fillRule="evenodd" clipRule="evenodd" fill="#808184" points="813.206,75.924 813.206,83.404 833.162,88.707 
                            841.892,87.771 842.826,79.664 836.903,72.186 		"/>
                        <polygon fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" points="810.71,76.857 833.162,79.975 838.15,74.361 
                            842.202,81.537 844.696,79.975 839.087,70.623 814.139,70.936 		"/>
                        <polygon fillRule="evenodd" clipRule="evenodd" fill="#D1D2D3" points="700.357,70.182 700.178,77.457 713.454,78.549 
                            743.644,74.184 742.919,68.002 716.363,69.822 706.539,64 		"/>
                        <polygon fillRule="evenodd" clipRule="evenodd" fill="#808184" points="716.325,69.795 716.186,78.154 743.644,74.184 
                            742.919,68.002 716.363,69.818 		"/>
                        <polygon fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" points="706.539,64.729 715.634,70.91 744.006,67.633 
                            733.46,61.09 706.357,62.908 699.266,69.818 699.628,71.094 		"/>
                        <path fillRule="evenodd" clipRule="evenodd" fill="#D1D2D3" d="M802.184,62.455c0,0,0.521-0.279,1.332-0.531v0.924h0.51v-1.201
                            c0.235-0.117,0.505-0.24,0.803-0.357v0.939l0.466-0.119l0.044-0.998l0.804-0.23v0.902h0.506v-1.016l0.807-0.137v0.879h0.508
                            v-0.938l0.683-0.057l0.121-0.004v0.9h0.509v-0.91c0.331,0,0.646,0.014,0.951,0.039v0.857l0.484,0.057l0.02-0.852l0.807,0.145
                            v0.814l0.486,0.096l0.021-0.781l0.661,0.213v0.76l0.448,0.15l0.06-0.707l0.662,0.32l-0.035,0.643l0.579,0.287l0.076-0.693
                            c0.631,0.396,0.939,0.781,0.939,0.781l-0.088,2.412l-0.729,1.002v19.51c0,0-2.863,1.125-5.827,1.125
                            c-3.374,0-5.575-1.023-5.575-1.023V65.473l-0.948-1.084L802.184,62.455z"/>
                        <path fillRule="evenodd" clipRule="evenodd" fill="#808184" d="M814.621,66.201c-2.048,1.488-4.535,3.533-5.097,5.021
                            c-0.417,1.088-0.601,2.381-0.601,2.381l0.171,13.072l-0.299,0.004l0,0c-3.378,0-5.579-1.023-5.579-1.023V65.473l-0.948-1.084
                            l-0.084-1.934l0,0c0,0,0.521-0.279,1.332-0.531v0.924h0.51v-1.201l0,0c0.235-0.117,0.508-0.24,0.803-0.357v0.939l0.466-0.119
                            l0.044-0.998l0.807-0.23v0.902h0.508v-1.016l0.804-0.137v0.879h0.508v-0.938l0.685-0.057l0.12-0.004v0.9h0.508v-0.91l0,0
                            c0.329,0,0.644,0.014,0.952,0.039v0.857l0.486,0.057l0.015-0.852l0.807,0.145v0.814l0.486,0.096l0.021-0.781l0.661,0.213v0.76
                            l0.448,0.15l0.06-0.707l0.662,0.32l-0.032,0.643l0.576,0.287l0.076-0.693l0,0c0.633,0.396,0.939,0.781,0.939,0.781l-0.088,2.412
                            l-0.727,1.002V66.201z"/>
                        <path fillRule="evenodd" clipRule="evenodd" fill="#D1D2D3" d="M803.847,64.168c0,1.25,1.748,1.41,4.76,1.41
                            c3.016,0,5.956-0.633,5.956-1.883s-2.839-1.668-5.855-1.668C805.701,62.027,803.847,62.922,803.847,64.168z"/>
                        <path fillRule="evenodd" clipRule="evenodd" fill="#808184" d="M803.502,66.955l-1.303-2.609v-1.908c0,0,0.236-0.16,1.01,0.096
                            v0.77h0.581v-0.58c0.298,0.098,0.611,0.199,0.94,0.297v0.809h0.578v-0.631c0.313,0.096,0.63,0.184,0.941,0.27v0.707h0.579v-0.555
                            c0.358,0.09,0.7,0.17,1.017,0.234v0.58h0.578v-0.479L809.001,64l0.437-0.018v0.535h0.579v-0.6c0.295-0.041,0.609-0.09,0.942-0.152
                            v0.58h0.577v-0.693l0.797-0.172v0.605h0.581v-0.734l0.722-0.174v0.65h0.582v-0.795c0.932-0.234,1.305-0.424,1.305-0.424v2.258
                            l-2.171,1.912L803.502,66.955z"/>
                        <polygon fillRule="evenodd" clipRule="evenodd" fill="#E6E7E8" points="755.623,89.375 751.854,88.152 752.055,81.322 
                            755.419,79.594 755.828,73.578 756.44,72.457 756.543,70.115 757.863,70.115 757.863,72.457 758.582,73.172 758.785,79.285 
                            762.657,81.322 762.657,88.559 		"/>
                        <polygon fillRule="evenodd" clipRule="evenodd" fill="#808184" points="755.521,81.732 755.521,89.344 755.623,89.375 
                            762.657,88.559 762.657,81.322 762.625,81.309 		"/>
                        <polygon fillRule="evenodd" clipRule="evenodd" fill="#808184" points="755.425,79.506 757.048,79.594 759.092,79.453 
                            758.785,79.289 758.58,73.172 758.334,72.928 757.153,73.375 756.048,73.18 755.828,73.578 		"/>
                        <polygon fillRule="evenodd" clipRule="evenodd" fill="#D1D2D3" points="752.596,81.047 755.728,81.736 755.63,89.375 
                            755.623,89.375 751.854,88.152 752.055,81.322 		"/>
                        <polygon fillRule="evenodd" clipRule="evenodd" fill="#808184" points="756.473,71.68 757.863,71.775 757.863,70.115 
                            756.537,70.115 		"/>
                        
                            <rect x="756.47" y="70.998" transform="matrix(-0.9977 -0.0671 0.0671 -0.9977 1507.8214 194.083)" fillRule="evenodd" clipRule="evenodd" fill="#D1D2D3" width="1.398" height="1.46"/>
                        <polygon fillRule="evenodd" clipRule="evenodd" fill="#808184" points="834.244,84.219 834.244,94.195 860.854,101.26 
                            872.491,100.014 872.769,89.027 865.841,79.225 		"/>
                        <polygon fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" points="830.917,85.465 860.854,89.623 867.503,82.139 
                            872.909,91.699 876.235,89.623 868.75,77.15 835.492,77.566 		"/>
                    </g>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M385.18,131.453c-0.293-0.113-0.437-0.445-0.32-0.738
                        c0.113-0.291,0.446-0.432,0.738-0.318l19.082,7.623c0.291,0.117,0.435,0.448,0.319,0.739c-0.114,0.291-0.447,0.436-0.737,0.318
                        L385.18,131.453z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M371.269,133.703c-0.294-0.115-0.434-0.447-0.32-0.738
                        c0.115-0.295,0.446-0.438,0.74-0.322l19.08,7.626c0.292,0.115,0.437,0.447,0.318,0.738c-0.113,0.291-0.446,0.434-0.738,0.322
                        L371.269,133.703z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M357.354,135.947c-0.29-0.115-0.432-0.447-0.316-0.738
                        c0.119-0.295,0.448-0.438,0.738-0.318l19.087,7.624c0.291,0.121,0.433,0.453,0.315,0.744c-0.115,0.289-0.446,0.434-0.74,0.314
                        L357.354,135.947z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M342.091,136.83c-0.283-0.129-0.41-0.469-0.279-0.756
                        c0.131-0.283,0.467-0.41,0.754-0.279l19.771,9.021c0.284,0.131,0.412,0.469,0.28,0.754c-0.129,0.287-0.467,0.412-0.756,0.283
                        L342.091,136.83z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M326.344,139.011c-0.278-0.141-0.39-0.486-0.25-0.767
                        c0.14-0.279,0.486-0.393,0.765-0.252l18.405,9.3c0.279,0.139,0.392,0.482,0.251,0.764c-0.142,0.279-0.486,0.395-0.766,0.25
                        L326.344,139.011z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M310.204,141.267c-0.282-0.141-0.398-0.48-0.263-0.764
                        c0.14-0.279,0.478-0.4,0.763-0.26l18.048,8.83c0.283,0.137,0.399,0.479,0.261,0.76c-0.137,0.283-0.476,0.398-0.76,0.262
                        L310.204,141.267z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M295.204,144.081c-0.279-0.145-0.389-0.486-0.247-0.768
                        c0.144-0.283,0.484-0.395,0.767-0.252l16.582,8.441c0.28,0.143,0.393,0.486,0.251,0.766c-0.142,0.281-0.488,0.393-0.769,0.252
                        L295.204,144.081z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M400.951,126.277c-0.297-0.117-0.438-0.453-0.321-0.748
                        c0.116-0.291,0.45-0.434,0.746-0.314l19.082,7.625c0.294,0.119,0.438,0.451,0.32,0.744c-0.117,0.295-0.453,0.439-0.746,0.32
                        L400.951,126.277z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M417.76,124.02c-0.293-0.115-0.438-0.451-0.32-0.744
                        c0.117-0.295,0.448-0.439,0.743-0.318l19.086,7.621c0.294,0.119,0.436,0.451,0.32,0.746c-0.117,0.293-0.45,0.438-0.742,0.322
                        L417.76,124.02z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M432.761,122.393c-0.292-0.119-0.436-0.451-0.32-0.742
                        c0.116-0.295,0.45-0.441,0.744-0.322l19.089,7.617c0.291,0.121,0.435,0.453,0.317,0.746c-0.116,0.295-0.45,0.438-0.74,0.322
                        L432.761,122.393z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M444.05,118.951c-0.292-0.119-0.436-0.451-0.321-0.746
                        c0.116-0.295,0.451-0.438,0.741-0.322l19.088,7.625c0.294,0.115,0.438,0.449,0.32,0.742c-0.117,0.295-0.45,0.438-0.744,0.322
                        L444.05,118.951z"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" points="442.234,702.194 442.645,721.47 441.503,721.489 
                        441.092,702.22 	"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" points="418.376,688.433 417.758,709.372 416.619,709.345 
                        417.237,688.401 	"/>
                    <rect x="428.743" y="695.083" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" width="1.142" height="20.012"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" points="396.364,675.667 394.396,695.399 393.264,695.29 
                        395.231,675.556 	"/>
                    
                        <rect x="396.165" y="691.464" transform="matrix(0.0704 -0.9975 0.9975 0.0704 -312.7603 1048.4515)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" width="19.995" height="1.143"/>
                    
                        <rect x="363.007" y="671.068" transform="matrix(0.099 -0.9951 0.9951 0.099 -332.925 975.5775)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" width="18.54" height="1.142"/>
                    
                        <rect x="373.586" y="678.235" transform="matrix(0.0807 -0.9967 0.9967 0.0807 -324.0205 1006.3031)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" width="19.852" height="1.139"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" points="352.77,650.208 349.862,669.243 348.735,669.075 
                        351.642,650.04 	"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" points="363.635,656.554 361.358,675.396 360.223,675.263 
                        362.502,656.419 	"/>
                    
                        <rect x="320.316" y="645.952" transform="matrix(0.2197 -0.9756 0.9756 0.2197 -373.8137 825.6857)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" width="17.856" height="1.137"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" points="342.318,644.103 338.864,661.878 337.742,661.663 
                        341.197,643.89 	"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" points="309.265,624.716 305.221,642.274 304.112,642.021 
                        308.154,624.464 	"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" points="319.936,630.964 316.517,648.563 315.399,648.351 
                        318.815,630.751 	"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" points="298.386,618.333 294.042,635.323 292.938,635.042 
                        297.28,618.056 	"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" points="1088.267,746.714 955.623,746.884 955.623,725.651 
                        1072.82,727.058 	"/>
                    <g>
                        <g>
                            <path fillRule="evenodd" clipRule="evenodd" fill="#808184" d="M1094.26,678.776v12.25h-3.456c0,0-0.002-1-0.002-5.818
                                s-5.266-4.705-5.266,0.225s0.008,5.594,0.008,5.594h-5.924l-13.444-8.363h-36.446l-3.885-3.287v-8.963l5.079-9.26h20.912
                                l5.977-3.586l6.273,3.586l23.003,8.363L1094.26,678.776z"/>
                            <polygon fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" points="1080.439,675.966 1062.102,669.702 1036.111,670.599 
                                1029.708,660.526 1051.836,661.153 1064.086,661.153 1087.945,669.187 			"/>
                            <path fillRule="evenodd" clipRule="evenodd" fill="#969696" d="M1097.795,678.798h-3.429l-6.173-7.086l-7.088,8.459
                                l-10.744-7.545h-34.53l-5.706-9.145l-4.28,6.857h-2.578l6.401-10.285c0,0,3.915,5.359,5.487,7.771
                                c1.572,2.408,5.707,2.314,7.549-1.025c2.39-4.33,5.568-1.232,7.17,0.598c1.602,1.828,5.328,3.846,8.662-1.047
                                c3.335-4.889,7.418-1.105,7.918,1.195c0.744,3.424,4.929,3.584,8.066,2.24c3.137-1.344,2.986,7.619,6.871,4.633
                                c3.885-2.988,7.058-6.127,7.058-6.127L1097.795,678.798z"/>
                        </g>
                        <polygon fillRule="evenodd" clipRule="evenodd" fill="#808184" points="1025.973,676.81 1011.932,665.753 1025.673,665.903 
                            1026.421,665.306 1010.887,665.306 1010.887,667.847 1027.467,680.542 		"/>
                        <polygon fillRule="evenodd" clipRule="evenodd" fill="#BABCBE" points="1064.078,661.079 1088.45,668.292 1087.525,669.216 
                            1064.027,661.794 1030.137,660.729 1029.668,660.054 		"/>
                    </g>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#EAEAEA" points="1022.537,107.84 996.248,115.607 1012.977,119.191 
                        1014.173,139.509 1029.708,122.777 1032.098,141.899 1045.839,128.75 1054.801,146.675 1073.326,131.141 1077.507,155.042 
                        1096.627,128.754 1113.357,162.212 1120.527,127.561 1101.408,116.805 1064.363,122.777 	"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#EAEAEA" points="1162.353,159.821 1167.138,140.146 1175.748,164.11 
                        1190.435,144.886 1210.75,166.396 1190.435,134.727 1178.486,139.509 1154.343,125.256 	"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#EAEAEA" points="1285.437,176.552 1270.157,167.61 1279.462,185.515 
                        1246.002,156.835 1280.657,164.603 1294.4,158.028 1316.506,169.382 1332.042,159.821 1416.29,229.134 1351.759,186.708 
                        1364.903,222.558 1335.03,175.358 1321.288,178.944 1327.86,210.61 1299.179,172.368 1296.79,199.255 	"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#EAEAEA" points="910.807,86.328 886.908,92.9 906.026,93.498 
                        906.625,102.461 919.172,94.693 924.55,107.24 935.305,100.668 939.487,119.191 950.838,107.24 953.828,119.191 965.777,110.824 
                        970.556,131.141 981.597,115.719 990.905,113.563 962.193,101.863 	"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#F2F2F2" d="M648.656,367.858l19.119-7.768l14.938-24.496l45.108,25.18
                        l-15.236-1.793l3.585,19.717l-24.494-13.826l-1.793,16.729c0,0-10.757-11.949-18.523-10.756
                        C663.593,372.042,638.5,373.835,648.656,367.858z"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#B4ADB0" points="684.506,342.165 678.53,363.079 688.091,351.728 
                        704.821,364.872 	"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#808184" points="1080.779,745.817 1070.189,732.089 1070.189,719.146 
                        1082.347,729.345 1188.644,729.345 1188.644,745.817 	"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#CECECE" points="1070.189,719.146 1168.247,719.146 1188.644,729.345 
                        1082.347,729.345 	"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#D9D9DA" points="660.478,74.982 616.243,72.479 552.929,84.543 
                        486.008,82.152 437.013,89.322 444.183,102.469 483.619,89.322 482.423,102.469 502.739,88.129 524.357,100.441 566.073,88.129 
                        590.716,89.838 609.072,83.234 	"/>
                    <polygon fill="#EAEAEA" points="1425.698,438.04 1371.776,434.714 1362.366,418.134 1298.284,397.521 1276.326,365.255 
                        1303.661,362.565 1262.882,337.472 1341.304,345.089 1351.713,369.438 1406.368,366.829 1451.991,444.124 1501.135,463.843 
                        1515.475,524.188 1499.942,514.63 1471.261,527.179 1454.53,485.353 1433.619,484.753 	"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#CECECE" points="1300.077,349.122 1334.582,351.812 1347.129,378.698 
                        1404.937,375.817 1430.928,427.097 1409.418,409.618 1403.145,419.925 1401.352,394.382 1379.841,419.733 1382.532,393.741 
                        1364.606,409.874 1361.917,389.261 1337.718,405.394 1340.407,383.884 1320.69,384.78 1326.964,369.542 1292.907,378.505 
                        1319.793,363.271 	"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#CECECE" points="1439.891,451.294 1495.01,471.46 1500.388,506.86 
                        1488.737,491.626 1473.949,507.759 1475.292,479.526 1460.505,479.526 1464.089,465.634 	"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#EAEAEA" points="1072.728,563.024 1099.617,538.528 1115.749,551.075 
                        1142.636,531.952 1179.084,593.497 1157.575,589.911 1151.598,597.681 1138.454,580.948 1116.944,590.511 1111.566,578.558 
                        1090.057,581.548 1090.057,567.208 	"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#CECECE" points="1091.252,557.647 1100.812,549.878 1110.969,560.038 
                        1142.636,546.292 1160.56,580.948 1140.247,567.208 1128.295,574.378 1125.906,566.011 1099.019,571.388 1100.215,558.841 	"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#EAEAEA" d="M386.598,372.487c1.642,6.014,5.185,17.652,10.327,27.195
                        c5.144,9.543,8.64,26.131,10.012,31.701s11.245,6.961,12.282,16.424c1.038,9.459,3.434,22.613,7.019,29.783
                        s12.899,21.064,19.118,15.537c10.756-9.563-7.026-18.287,4.781-25.096c31.069-17.926,3.881-21.734,5.975-27.486
                        c1.046-2.877,16.841-19.412,16.841-19.412l-28.793-26.006l32.265,1.193l-22.706-23.9l39.436-20.313l10.755-37.047
                        c0,0,80.547-39.205,92.014-44.215c18.214-7.957,44.214-22.703,43.021-28.68c-1.194-5.973-4.779-11.949,6.572-16.727
                        c11.353-4.783,33.71-20.992,26.291-25.096c-7.419-4.107-84.907,16.877-84.907,16.877s16.489,7.777,9.77,11.582
                        c-6.721,3.807-29.424,2.014-37.789,5c-8.366,2.986-19.921,11.914-12.649,12.826c7.271,0.914-1.095,6.293-6.472,4.5
                        s-46.708,4.684-54.41,6.736c-7.702,2.051-4.513,4.6,1.944,9.688c6.458,5.086-3.72,7.281-11.467,3.293
                        c-7.748-3.986-14.818-1.168-23.627,2.355c-8.808,3.527-10.028,6.076-5.748,11.719c4.279,5.645,1.346,14.004-4.084,9.229
                        c-5.431-4.775-21.405-14.963-21.622-11.256c-0.217,3.711-6.459,47.105-8.851,52.482c-2.392,5.379-28.046,23.051-25.675,35.725"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#CECECE" points="490.765,333.694 454.916,328.913 505.703,355.802 
                        450.732,343.255 478.605,362.306 434.6,351.618 435.141,361.474 412.675,355.06 430.417,325.929 433.406,297.247 	"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#CECECE" points="650.317,206.185 623.841,211.796 635.875,215.007 
                        613.41,212.603 597.367,218.214 603.786,221.421 626.249,228.64 611.81,219.817 628.659,225.433 643.76,222.425 630.262,222.222 
                        641.491,219.015 662.352,209.392 643.9,213.403 	"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#CECECE" points="623.411,246.46 585.174,238.788 565.114,244.397 
                        601.301,254.823 588.754,244.667 	"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#CECECE" points="466.267,282.31 497.936,315.769 489.57,296.649 
                        527.809,304.417 	"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#CECECE" points="501.521,264.983 537.371,293.663 532.59,281.116 
                        558.282,284.7 540.357,269.763 563.659,275.737 554.697,266.776 583.976,267.37 530.201,256.021 511.677,260.2 	"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#CECECE" points="415.481,411.968 420.858,427.501 448.942,437.063 
                        438.784,428.101 456.112,426.206 	"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#CECECE" points="406.518,363.571 398.153,376.712 438.186,378.505 	"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#CECECE" points="431.614,450.204 444.758,478.29 441.771,460.364 
                        451.928,457.974 	"/>
                    <polygon fill="#CECECE" points="1018.654,239.78 995.352,262.187 1012.38,258.601 1014.173,279.214 1027.617,262.187 	"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#EAEAEA" points="-4.862,312.376 100.896,325.819 142.124,311.479 
                        184.247,334.782 250.57,294.45 299.138,305.927 330.335,287.28 388.591,297.14 368.564,307.454 387.695,327.612 378.733,342.849 
                        369.771,390.351 336.609,382.282 316.892,419.925 282.442,408.468 247.881,434.267 252.362,485.353 219.201,505.069 1.413,427.991 
                            "/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#EAEAEA" points="165.126,92.795 190.221,96.979 316.892,72.479 
                        357.522,84.432 472.242,64.115 551.712,74.869 615.941,65.012 600.406,55.451 553.8,65.908 472.839,48.578 360.508,68.895 
                        315.099,53.359 203.964,86.818 171.698,80.844 2.008,116.693 0.812,134.617 	"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#EAEAEA" points="5.894,281.007 100,294.45 137.642,270.251 
                        184.247,302.517 242.503,269.356 299.863,286.384 321.374,267.563 279.25,237.091 309.721,235.298 281.042,202.138 
                        248.777,212.892 216.512,207.515 187.832,235.298 142.124,222.751 104.481,253.224 2.309,231.714 	"/>
                    <path fill="#9B9B9B" d="M1669.431,629.255c-0.003-0.004-0.371-0.448-1.088-1.303l3.365-2.822c0.731,0.873,1.105,1.324,1.105,1.324
                        L1669.431,629.255L1669.431,629.255z M1661.233,619.563c-2.036-2.382-4.433-5.169-7.168-8.326l3.318-2.875
                        c2.743,3.165,5.146,5.96,7.188,8.348L1661.233,619.563L1661.233,619.563z M1646.847,602.955c-2.267-2.588-4.689-5.339-7.26-8.242
                        l3.287-2.91c2.576,2.909,5.003,5.666,7.275,8.258L1646.847,602.955L1646.847,602.955z M1632.287,586.509
                        c-2.351-2.629-4.798-5.354-7.339-8.169l3.26-2.941c2.545,2.819,4.998,5.55,7.353,8.183L1632.287,586.509L1632.287,586.509z
                        M1617.574,570.208c-2.403-2.636-4.874-5.338-7.413-8.101l3.232-2.971c2.543,2.767,5.02,5.473,7.426,8.113L1617.574,570.208
                        L1617.574,570.208z M1602.713,554.042c-2.441-2.632-4.937-5.31-7.483-8.031l3.205-3c2.553,2.726,5.052,5.408,7.497,8.045
                        L1602.713,554.042L1602.713,554.042z M1587.709,538.013c-2.474-2.62-4.993-5.274-7.554-7.962l3.178-3.029
                        c2.566,2.691,5.09,5.352,7.569,7.978L1587.709,538.013L1587.709,538.013z M1572.565,522.124c-2.508-2.606-5.05-5.237-7.628-7.893
                        l3.15-3.059c2.582,2.659,5.13,5.296,7.642,7.906L1572.565,522.124L1572.565,522.124z M1557.272,506.375
                        c-2.535-2.588-5.103-5.194-7.7-7.817l3.121-3.09c2.602,2.629,5.175,5.241,7.716,7.835L1557.272,506.375L1557.272,506.375z
                        M1541.833,490.776c-2.566-2.567-5.159-5.149-7.776-7.742l3.09-3.119c2.623,2.599,5.222,5.185,7.792,7.758L1541.833,490.776
                        L1541.833,490.776z M1526.241,475.332c-2.596-2.545-5.215-5.099-7.855-7.66l3.057-3.152c2.646,2.567,5.271,5.127,7.873,7.678
                        L1526.241,475.332L1526.241,475.332z M1510.489,460.053c-2.626-2.521-5.272-5.046-7.937-7.574l3.021-3.186
                        c2.672,2.534,5.324,5.065,7.956,7.592L1510.489,460.053L1510.489,460.053z M1494.572,444.949c-2.658-2.493-5.333-4.987-8.023-7.481
                        l2.984-3.221c2.698,2.5,5.379,5,8.043,7.499L1494.572,444.949L1494.572,444.949z M1478.48,430.036
                        c-2.691-2.464-5.396-4.926-8.115-7.383l2.945-3.257c2.725,2.464,5.438,4.931,8.135,7.399L1478.48,430.036L1478.48,430.036z
                        M1462.204,415.325c-2.726-2.43-5.463-4.855-8.211-7.273l2.9-3.297c2.756,2.426,5.501,4.856,8.232,7.293L1462.204,415.325
                        L1462.204,415.325z M1445.71,400.819c-2.766-2.397-5.541-4.787-8.326-7.166l2.852-3.339c2.793,2.386,5.578,4.782,8.352,7.187
                        L1445.71,400.819L1445.71,400.819z M1429.012,386.559c-2.8-2.353-5.606-4.694-8.422-7.021l2.799-3.384
                        c2.823,2.335,5.64,4.684,8.447,7.044L1429.012,386.559L1429.012,386.559z M1412.074,372.761c-2.635-1.985-5.611-4.14-8.847-6.407
                        l2.52-3.597c3.276,2.295,6.295,4.481,8.97,6.497L1412.074,372.761L1412.074,372.761z M1394.186,360.177
                        c-2.855-1.909-5.938-3.934-9.162-6.019l2.385-3.688c3.243,2.097,6.344,4.135,9.219,6.056L1394.186,360.177L1394.186,360.177z
                        M1375.778,348.255c-3.018-1.903-6.124-3.841-9.31-5.811l2.309-3.734c3.197,1.977,6.315,3.922,9.343,5.832L1375.778,348.255
                        L1375.778,348.255z M1357.11,336.707c-3.076-1.871-6.211-3.765-9.397-5.678l2.26-3.765c3.194,1.917,6.337,3.815,9.419,5.69
                        L1357.11,336.707L1357.11,336.707z M1338.283,325.403c-3.115-1.848-6.27-3.71-9.457-5.584l2.225-3.786
                        c3.193,1.877,6.354,3.742,9.473,5.593L1338.283,325.403L1338.283,325.403z M1319.346,314.273c-3.143-1.832-6.312-3.67-9.499-5.514
                        l2.199-3.801c3.191,1.846,6.364,3.688,9.511,5.521L1319.346,314.273L1319.346,314.273z M1300.332,303.273
                        c-3.167-1.819-6.346-3.641-9.531-5.461l2.178-3.813c3.189,1.822,6.372,3.646,9.541,5.466L1300.332,303.273L1300.332,303.273z
                        M1281.239,292.364c-3.186-1.811-6.373-3.618-9.556-5.42l2.164-3.821c3.185,1.803,6.374,3.612,9.562,5.424L1281.239,292.364
                        L1281.239,292.364z M1262.136,281.551c-3.204-1.806-6.397-3.604-9.575-5.389l2.15-3.828c3.18,1.787,6.375,3.585,9.581,5.392
                        L1262.136,281.551L1262.136,281.551z M1242.978,270.785l-9.589-5.367l2.143-3.833l9.593,5.369L1242.978,270.785L1242.978,270.785z
                        M1223.794,260.058l-9.599-5.354l2.139-3.835l9.601,5.355L1223.794,260.058L1223.794,260.058z M1204.595,249.352l-9.604-5.353
                        l2.139-3.835l9.602,5.352L1204.595,249.352L1204.595,249.352z M1185.389,238.641c-3.301-1.843-6.505-3.634-9.6-5.368l2.146-3.831
                        c3.093,1.732,6.295,3.522,9.594,5.365L1185.389,238.641L1185.389,238.641z M1166.197,227.887c-2.954-1.664-5.777-3.258-8.455-4.777
                        l-1.121-0.635l2.16-3.823l1.129,0.64c2.673,1.517,5.491,3.109,8.441,4.77L1166.197,227.887L1166.197,227.887z M1147.052,217.165
                        c-3.205-1.746-6.455-3.483-9.659-5.165l2.04-3.889c3.225,1.692,6.495,3.441,9.719,5.197L1147.052,217.165L1147.052,217.165z
                        M1127.65,206.983c-3.232-1.633-6.536-3.271-9.821-4.867l1.92-3.949c3.305,1.606,6.63,3.254,9.882,4.897L1127.65,206.983
                        L1127.65,206.983z M1107.931,197.398c-3.282-1.534-6.637-3.071-9.972-4.568l1.799-4.006c3.354,1.507,6.73,3.053,10.032,4.597
                        L1107.931,197.398L1107.931,197.398z M1087.917,188.41c-3.336-1.438-6.728-2.871-10.081-4.258l1.68-4.058
                        c3.373,1.396,6.784,2.837,10.14,4.284L1087.917,188.41L1087.917,188.41z M1067.695,180.041c-3.376-1.339-6.809-2.673-10.201-3.963
                        l1.561-4.104c3.413,1.298,6.864,2.639,10.26,3.987L1067.695,180.041L1067.695,180.041z M1047.238,172.258
                        c-3.393-1.235-6.861-2.472-10.311-3.674l1.445-4.146c3.469,1.209,6.957,2.453,10.367,3.695L1047.238,172.258L1047.238,172.258z
                        M1026.567,165.052c-3.435-1.145-6.937-2.286-10.408-3.393l1.334-4.184c3.489,1.112,7.01,2.259,10.463,3.41L1026.567,165.052
                        L1026.567,165.052z M1005.708,158.405c-3.451-1.05-6.981-2.099-10.494-3.119l1.225-4.217c3.53,1.024,7.079,2.079,10.547,3.134
                        L1005.708,158.405L1005.708,158.405z M984.68,152.301c-3.475-0.962-7.031-1.922-10.57-2.854l1.119-4.246
                        c3.556,0.937,7.13,1.902,10.623,2.868L984.68,152.301L984.68,152.301z M963.505,146.721c-3.526-0.884-7.072-1.75-10.637-2.6
                        l1.018-4.271c3.582,0.854,7.144,1.724,10.688,2.613L963.505,146.721L963.505,146.721z M942.202,141.643
                        c-3.546-0.802-7.111-1.587-10.695-2.356l0.922-4.293c3.6,0.772,7.181,1.561,10.742,2.367L942.202,141.643L942.202,141.643z
                        M920.785,137.047c-3.563-0.724-7.144-1.432-10.745-2.125l0.829-4.312c3.616,0.696,7.213,1.407,10.791,2.134L920.785,137.047
                        L920.785,137.047z M899.274,132.911c-3.577-0.649-7.173-1.284-10.788-1.903l0.742-4.328c3.629,0.622,7.238,1.259,10.831,1.911
                        L899.274,132.911L899.274,132.911z M877.68,129.21c-3.59-0.579-7.197-1.143-10.824-1.693l0.658-4.342
                        c3.641,0.552,7.262,1.119,10.865,1.7L877.68,129.21L877.68,129.21z M856.016,125.925c-3.601-0.512-7.219-1.01-10.855-1.494
                        l0.58-4.353c3.648,0.486,7.28,0.985,10.893,1.499L856.016,125.925L856.016,125.925z M834.293,123.033
                        c-3.608-0.448-7.235-0.884-10.879-1.306l0.505-4.362c3.657,0.424,7.296,0.86,10.917,1.311L834.293,123.033L834.293,123.033z
                        M812.523,120.511c-3.616-0.389-7.25-0.765-10.901-1.128l0.435-4.369c3.663,0.364,7.308,0.741,10.935,1.131L812.523,120.511
                        L812.523,120.511z M790.712,118.341c-3.622-0.332-7.261-0.652-10.918-0.959l0.368-4.376c3.668,0.309,7.318,0.629,10.951,0.962
                        L790.712,118.341L790.712,118.341z M768.87,116.502c-3.627-0.278-7.271-0.545-10.932-0.801l0.306-4.38
                        c3.671,0.256,7.326,0.524,10.962,0.804L768.87,116.502L768.87,116.502z M747,114.976c-3.63-0.228-7.278-0.445-10.943-0.652
                        l0.247-4.384c3.674,0.207,7.331,0.425,10.971,0.654L747,114.976L747,114.976z M725.129,113.754
                        c-2.33-0.091-5.811-0.188-10.956-0.306l0.101-4.39c5.169,0.118,8.672,0.216,11.026,0.308L725.129,113.754L725.129,113.754z
                        M703.187,113.208c-4.285-0.094-8.588-0.194-11.031-0.29l0.172-4.388c2.41,0.094,6.689,0.194,10.956,0.288L703.187,113.208
                        L703.187,113.208z"/>
                    <path fill="#9B9B9B" d="M385.437,279.034c-3.724-0.359-7.484-1.109-11.176-2.229l1.274-4.202c3.415,1.036,6.889,1.729,10.324,2.06
                        L385.437,279.034L385.437,279.034z M396.875,278.892l-0.547-4.356c2.741-0.344,5.444-0.944,8.035-1.784l2.125-0.76l1.479,4.135
                        l-2.188,0.782C402.863,277.854,399.889,278.514,396.875,278.892L396.875,278.892z M363.729,272.487
                        c-3.564-1.879-6.851-4.1-9.505-6.425l2.894-3.303c2.399,2.102,5.394,4.122,8.658,5.843L363.729,272.487L363.729,272.487z
                        M418.343,272.416l-1.479-4.135l10.373-3.711l1.479,4.134L418.343,272.416L418.343,272.416z M439.086,264.993l-1.48-4.134
                        l10.366-3.71l1.479,4.134L439.086,264.993L439.086,264.993z M459.814,257.573l-1.48-4.134l10.361-3.71l1.48,4.134L459.814,257.573
                        L459.814,257.573z M480.535,250.153l-1.48-4.134l10.359-3.709l1.48,4.134L480.535,250.153L480.535,250.153z M501.253,242.734
                        l-1.481-4.134l10.375-3.716l1.48,4.134L501.253,242.734L501.253,242.734z M521.971,235.314l-1.48-4.134l10.358-3.71l1.48,4.134
                        L521.971,235.314L521.971,235.314z M542.688,227.894l-1.48-4.134l10.36-3.711l1.48,4.134L542.688,227.894L542.688,227.894z
                        M563.41,220.472l-1.48-4.134l10.363-3.712l1.481,4.134L563.41,220.472L563.41,220.472z M584.142,213.045l-1.481-4.134
                        l10.371-3.715l1.48,4.134L584.142,213.045L584.142,213.045z M604.885,205.615l-1.48-4.134l10.423-3.734l1.481,4.134
                        L604.885,205.615L604.885,205.615z"/>
                    <path fill="#9B9B9B" d="M378.38,640.807c-3.052-2.174-6.051-4.355-8.914-6.485l2.621-3.523c2.839,2.112,5.813,4.276,8.841,6.433
                        L378.38,640.807L378.38,640.807z M360.692,627.639c-2.946-2.299-5.844-4.621-8.612-6.901l2.792-3.389
                        c2.738,2.256,5.605,4.553,8.521,6.829L360.692,627.639L360.692,627.639z M343.661,613.593c-2.818-2.466-5.575-4.961-8.195-7.414
                        l3.001-3.205c2.583,2.42,5.304,4.881,8.085,7.314L343.661,613.593L343.661,613.593z M327.535,598.469
                        c-2.631-2.658-5.194-5.36-7.618-8.031l3.251-2.951c2.382,2.624,4.902,5.28,7.488,7.895L327.535,598.469L327.535,598.469z
                        M312.666,582.057c-2.381-2.893-4.675-5.837-6.818-8.751l3.537-2.602c2.096,2.85,4.341,5.73,6.672,8.562L312.666,582.057
                        L312.666,582.057z M299.536,564.168c-2.036-3.151-3.961-6.358-5.722-9.533l3.84-2.131c1.714,3.09,3.588,6.212,5.571,9.281
                        L299.536,564.168L299.536,564.168z M288.77,544.711c-1.57-3.396-3.01-6.856-4.28-10.288l4.118-1.523
                        c1.23,3.323,2.625,6.677,4.147,9.968L288.77,544.711L288.77,544.711z M281.055,523.816c-0.995-3.577-1.846-7.232-2.528-10.865
                        l4.315-0.811c0.66,3.51,1.481,7.042,2.443,10.498L281.055,523.816L281.055,523.816z M276.941,501.909
                        c-0.366-3.663-0.581-7.409-0.638-11.134l4.391-0.066c0.055,3.601,0.263,7.222,0.617,10.763L276.941,501.909L276.941,501.909z
                        M280.969,479.916l-4.382-0.287c0.24-3.646,0.629-7.374,1.157-11.08l4.347,0.619C281.579,472.765,281.201,476.381,280.969,479.916
                        L280.969,479.916z M284,458.518l-4.293-0.924c0.764-3.553,1.669-7.182,2.69-10.785l4.225,1.197
                        C285.626,451.52,284.744,455.056,284,458.518L284,458.518z M289.885,437.66l-4.148-1.439c1.185-3.413,2.502-6.905,3.917-10.377
                        l4.066,1.656C292.334,430.901,291.044,434.319,289.885,437.66L289.885,437.66z M298.061,417.532l-3.984-1.846
                        c0.367-0.792,0.739-1.586,1.118-2.381c1.234-2.59,2.44-5.107,3.621-7.553l3.955,1.908c-1.177,2.439-2.381,4.949-3.612,7.533
                        C298.786,415.976,298.42,416.755,298.061,417.532L298.061,417.532z M307.564,397.813l-3.94-1.937
                        c1.687-3.433,3.314-6.709,4.882-9.839l3.926,1.967C310.868,391.125,309.246,394.391,307.564,397.813L307.564,397.813z
                        M317.378,378.237l-3.909-2c1.766-3.45,3.447-6.696,5.046-9.757l3.892,2.034C320.812,371.565,319.137,374.8,317.378,378.237
                        L317.378,378.237z M327.52,358.836l-3.874-2.067c1.864-3.493,3.595-6.702,5.201-9.667l3.861,2.091
                        C331.105,352.151,329.378,355.352,327.52,358.836L327.52,358.836z M337.933,339.554l-3.863-2.087
                        c2.289-4.239,3.812-7.122,5.091-9.638l3.914,1.989C341.779,332.37,340.241,335.281,337.933,339.554L337.933,339.554z
                        M347.738,319.684l-4.087-1.605c1.401-3.566,2.302-6.761,2.836-10.054l4.334,0.702
                        C350.236,312.337,349.256,315.819,347.738,319.684L347.738,319.684z M347.119,297.502c-0.054-2.959-0.254-6.394-0.631-10.809
                        l4.375-0.374c0.386,4.515,0.591,8.043,0.647,11.103L347.119,297.502L347.119,297.502z M345.575,275.732
                        c-0.292-3.873-0.532-7.574-0.714-11.002l4.385-0.233c0.181,3.396,0.419,7.065,0.708,10.906L345.575,275.732L345.575,275.732z
                        M344.438,253.691c-0.042-1.958-0.064-3.867-0.064-5.729c0-1.826,0.021-3.607,0.063-5.346l4.39,0.107
                        c-0.042,1.704-0.062,3.449-0.062,5.239c0,1.831,0.021,3.709,0.063,5.634L344.438,253.691L344.438,253.691z M349.433,231.92
                        l-4.372-0.405c0.365-3.943,0.89-7.67,1.56-11.079l4.309,0.847C350.288,224.545,349.784,228.125,349.433,231.92L349.433,231.92z
                        M353.706,211.021l-4.134-1.479c1.325-3.703,2.959-7.168,4.859-10.3l3.755,2.277C356.414,204.439,354.949,207.548,353.706,211.021
                        L353.706,211.021z M364.569,193.24l-3.16-3.049c2.555-2.648,5.39-5.055,8.668-7.356l2.522,3.594
                        C369.554,188.567,366.927,190.795,364.569,193.24L364.569,193.24z M381.748,180.956l-2.009-3.904
                        c1.708-0.879,3.534-1.755,5.428-2.604c1.538-0.689,3.099-1.374,4.68-2.052l1.731,4.035c-1.56,0.669-3.099,1.344-4.615,2.023
                        C385.142,179.271,383.387,180.113,381.748,180.956L381.748,180.956z M401.686,172.286l-1.605-4.087
                        c3.311-1.3,6.788-2.613,10.335-3.903l1.5,4.127C408.404,169.699,404.962,171,401.686,172.286L401.686,172.286z M422.243,164.798
                        l-1.41-4.158c3.382-1.147,6.908-2.306,10.481-3.443l1.333,4.184C429.099,162.51,425.599,163.66,422.243,164.798L422.243,164.798z
                        M443.113,158.143l-1.264-4.205c3.445-1.036,7.005-2.077,10.58-3.095l1.203,4.223C450.077,156.079,446.538,157.114,443.113,158.143
                        L443.113,158.143z M464.172,152.139l-1.148-4.238c3.538-0.958,7.089-1.897,10.647-2.816l1.099,4.251
                        C471.228,150.251,467.693,151.186,464.172,152.139L464.172,152.139z M485.42,146.645l-1.053-4.263
                        c3.573-0.883,7.144-1.746,10.705-2.588l1.011,4.273C492.536,144.906,488.979,145.766,485.42,146.645L485.42,146.645z
                        M506.772,141.588l-0.973-4.282c3.604-0.818,7.19-1.616,10.748-2.393l0.937,4.29C513.938,139.977,510.364,140.772,506.772,141.588
                        L506.772,141.588z M528.216,136.905l-0.902-4.297c3.636-0.764,7.234-1.505,10.783-2.222l0.87,4.304
                        C535.428,135.405,531.841,136.144,528.216,136.905L528.216,136.905z M549.736,132.552l-0.839-4.31
                        c3.675-0.716,7.283-1.405,10.813-2.068l0.811,4.315C557,131.151,553.401,131.838,549.736,132.552L549.736,132.552z M571.321,128.5
                        l-0.782-4.321c3.732-0.675,7.351-1.317,10.843-1.925l0.753,4.326C578.653,127.186,575.042,127.826,571.321,128.5L571.321,128.5z
                        M592.964,124.729l-0.726-4.331c3.845-0.644,7.478-1.239,10.872-1.784l0.696,4.335
                        C600.422,123.493,596.799,124.086,592.964,124.729L592.964,124.729z M614.663,121.242l-0.666-4.34
                        c4.326-0.664,7.997-1.208,10.906-1.631l0.631,4.346C622.634,120.038,618.975,120.58,614.663,121.242L614.663,121.242z
                        M397.485,653.463c-3.121-2.073-6.19-4.156-9.121-6.191l2.505-3.607c2.906,2.019,5.95,4.085,9.046,6.142L397.485,653.463
                        L397.485,653.463z M417.409,665.928c-3.206-1.938-6.361-3.89-9.376-5.798l2.349-3.711c2.99,1.895,6.119,3.828,9.299,5.751
                        L417.409,665.928L417.409,665.928z"/>
                    <path fill="#9B9B9B" d="M687.329,720.474h-4.52v-4.391l10.981-0.001l0.002,4.391L687.329,720.474L687.329,720.474z
                        M671.829,720.471l-10.981-0.008l0.004-4.391l10.979,0.008L671.829,720.471L671.829,720.471z M704.781,720.47l-0.002-4.391
                        l10.968-0.006l0.002,4.391L704.781,720.47L704.781,720.47z M726.731,720.454l-0.004-4.391l10.995-0.012l0.006,4.391
                        L726.731,720.454L726.731,720.454z M649.867,720.45l-10.981-0.018l0.009-4.391l10.979,0.018L649.867,720.45L649.867,720.45z
                        M748.738,720.43l-0.006-4.391l10.988-0.016l0.007,4.391L748.738,720.43L748.738,720.43z M627.903,720.408l-10.982-0.034
                        l0.016-4.391l10.979,0.034L627.903,720.408L627.903,720.408z M770.715,720.396l-0.007-4.391l10.989-0.019l0.007,4.391
                        L770.715,720.396L770.715,720.396z M792.693,720.357l-0.008-4.391l10.988-0.022l0.01,4.391L792.693,720.357L792.693,720.357z
                        M605.938,720.328c-3.866-0.019-7.536-0.039-10.985-0.062l0.028-4.391c3.447,0.022,7.114,0.043,10.978,0.062L605.938,720.328
                        L605.938,720.328z M814.674,720.312l-0.011-4.391l10.991-0.025l0.01,4.391L814.674,720.312L814.674,720.312z M836.656,720.26
                        l-0.012-4.391l10.992-0.028l0.012,4.391L836.656,720.26L836.656,720.26z M858.64,720.202l-0.012-4.391l10.994-0.03l0.012,4.391
                        L858.64,720.202L858.64,720.202z M583.964,720.181c-3.073-0.029-5.869-0.062-8.362-0.096c-0.886-0.012-1.772-0.029-2.656-0.054
                        l0.118-4.389c0.864,0.022,1.73,0.04,2.598,0.052c2.488,0.034,5.279,0.066,8.345,0.096L583.964,720.181L583.964,720.181z
                        M880.629,720.14l-0.014-4.391l11.003-0.034l0.014,4.391L880.629,720.14L880.629,720.14z M561.873,719.447
                        c-3.637-0.285-7.347-0.669-11.026-1.142l0.56-4.355c3.607,0.464,7.244,0.84,10.81,1.12L561.873,719.447L561.873,719.447z
                        M539.893,716.636c-3.578-0.631-7.232-1.36-10.861-2.167l0.954-4.287c3.566,0.794,7.156,1.511,10.67,2.13L539.893,716.636
                        L539.893,716.636z M518.278,711.836c-3.521-0.941-7.098-1.973-10.631-3.066l1.299-4.195c3.479,1.077,7,2.094,10.466,3.02
                        L518.278,711.836L518.278,711.836z M497.145,705.298c-3.425-1.201-6.913-2.495-10.367-3.846l1.599-4.09
                        c3.407,1.332,6.846,2.607,10.222,3.793L497.145,705.298L497.145,705.298z M476.552,697.258c-3.317-1.424-6.71-2.942-10.083-4.516
                        l1.855-3.98c3.333,1.555,6.684,3.056,9.959,4.461L476.552,697.258L476.552,697.258z M456.527,687.928
                        c-3.217-1.615-6.514-3.326-9.799-5.086l2.073-3.871c3.25,1.741,6.513,3.435,9.696,5.033L456.527,687.928L456.527,687.928z
                        M437.065,677.512c-3.057-1.734-6.176-3.545-9.537-5.536l2.238-3.777c3.336,1.977,6.432,3.773,9.464,5.493L437.065,677.512
                        L437.065,677.512z"/>
                    <path fill="#9B9B9B" d="M1683.999,813.9c-1.621-1.452-3.308-2.903-5.012-4.315l2.801-3.381c1.747,1.447,3.477,2.936,5.14,4.425
                        L1683.999,813.9L1683.999,813.9z M1670.433,802.915c-2.846-2.093-5.845-4.183-8.913-6.21l2.42-3.664
                        c3.131,2.068,6.19,4.2,9.095,6.337L1670.433,802.915L1670.433,802.915z M1652.309,790.918c-3.046-1.821-6.229-3.639-9.458-5.399
                        l2.102-3.855c3.281,1.789,6.515,3.635,9.61,5.487L1652.309,790.918L1652.309,790.918z M1633.188,780.473
                        c-3.193-1.597-6.503-3.186-9.837-4.722l1.838-3.988c3.376,1.557,6.728,3.165,9.962,4.782L1633.188,780.473L1633.188,780.473z
                        M1613.369,771.326c-3.273-1.396-6.673-2.793-10.104-4.151l1.615-4.082c3.468,1.372,6.903,2.783,10.212,4.194L1613.369,771.326
                        L1613.369,771.326z M1593.052,763.276c-3.38-1.245-6.846-2.478-10.303-3.666l1.428-4.152c3.486,1.198,6.982,2.442,10.393,3.697
                        L1593.052,763.276L1593.052,763.276z M1572.37,756.161c-3.424-1.1-6.939-2.192-10.45-3.249l1.266-4.205
                        c3.536,1.064,7.078,2.165,10.526,3.272L1572.37,756.161L1572.37,756.161z M1551.411,749.852c-3.479-0.981-7.033-1.951-10.562-2.884
                        l1.123-4.246c3.551,0.939,7.127,1.916,10.63,2.903L1551.411,749.852L1551.411,749.852z M1530.241,744.249
                        c-3.485-0.865-7.068-1.728-10.649-2.563l0.998-4.275c3.601,0.84,7.204,1.707,10.71,2.577L1530.241,744.249L1530.241,744.249z
                        M1508.906,739.271c-3.502-0.767-7.107-1.532-10.718-2.274l0.885-4.301c3.628,0.746,7.253,1.515,10.772,2.286L1508.906,739.271
                        L1508.906,739.271z M1487.419,734.85c-3.589-0.692-7.185-1.364-10.782-2.016l0.781-4.32c3.615,0.653,7.227,1.329,10.833,2.025
                        L1487.419,734.85L1487.419,734.85z M1465.842,730.943c-3.602-0.611-7.203-1.202-10.802-1.773l0.688-4.336
                        c3.614,0.573,7.231,1.166,10.849,1.779L1465.842,730.943L1465.842,730.943z M1444.23,727.512
                        c-3.623-0.537-7.242-1.055-10.853-1.554l0.602-4.35c3.624,0.501,7.257,1.021,10.896,1.56L1444.23,727.512L1444.23,727.512z
                        M1422.509,724.51c-3.64-0.467-7.269-0.915-10.883-1.346l0.52-4.359c3.626,0.431,7.269,0.881,10.922,1.35L1422.509,724.51
                        L1422.509,724.51z M1400.733,721.92c-3.656-0.399-7.293-0.782-10.907-1.146l0.439-4.369c3.627,0.365,7.276,0.748,10.944,1.149
                        L1400.733,721.92L1400.733,721.92z M1378.91,719.727c-3.671-0.337-7.314-0.653-10.926-0.951l0.361-4.377
                        c3.625,0.3,7.28,0.618,10.965,0.955L1378.91,719.727L1378.91,719.727z M1357.048,717.92c-3.686-0.272-7.334-0.525-10.941-0.76
                        l0.285-4.381c3.619,0.234,7.281,0.487,10.98,0.762L1357.048,717.92L1357.048,717.92z M1335.159,716.499
                        c-3.703-0.208-7.355-0.396-10.953-0.563l0.205-4.387c3.611,0.169,7.277,0.356,10.994,0.564L1335.159,716.499L1335.159,716.499z
                        M906.417,716.448l-0.027-4.391l11.078-0.07l0.027,4.391L906.417,716.448L906.417,716.448z M928.52,716.309l-0.027-4.391
                        l11.016-0.068l0.027,4.391L928.52,716.309L928.52,716.309z M950.543,716.174l-0.027-4.391l11.006-0.066l0.025,4.391
                        L950.543,716.174L950.543,716.174z M972.55,716.042l-0.027-4.391l11-0.064l0.025,4.391L972.55,716.042L972.55,716.042z
                        M994.546,715.913l-0.025-4.391l10.996-0.063l0.025,4.391L994.546,715.913L994.546,715.913z M1016.539,715.788l-0.025-4.391
                        l10.995-0.062l0.023,4.391L1016.539,715.788L1016.539,715.788z M1038.526,715.666l-0.023-4.391l10.992-0.06l0.024,4.391
                        L1038.526,715.666L1038.526,715.666z M1060.512,715.548l-0.023-4.391l10.993-0.058l0.021,4.391L1060.512,715.548L1060.512,715.548z
                        M1313.248,715.477c-3.725-0.139-7.38-0.257-10.962-0.354l0.119-4.389c3.596,0.098,7.267,0.216,11.005,0.354L1313.248,715.477
                        L1313.248,715.477z M1082.496,715.434l-0.021-4.391l10.992-0.056l0.021,4.391L1082.496,715.434L1082.496,715.434z
                        M1104.467,715.323l-0.021-4.391l11.004-0.054l0.021,4.391L1104.467,715.323L1104.467,715.323z M1126.463,715.219l-0.02-4.391
                        l10.991-0.051l0.021,4.391L1126.463,715.219L1126.463,715.219z M1148.448,715.119l-0.02-4.391l10.992-0.047l0.02,4.391
                        L1148.448,715.119L1148.448,715.119z M1170.431,715.026l-0.018-4.391l10.994-0.043l0.016,4.391L1170.431,715.026L1170.431,715.026z
                        M1192.417,714.942l-0.016-4.391l10.995-0.038l0.014,4.391L1192.417,714.942L1192.417,714.942z M1291.322,714.883
                        c-3.754-0.063-7.412-0.102-10.966-0.119l0.021-4.391c3.571,0.018,7.245,0.057,11.017,0.119L1291.322,714.883L1291.322,714.883z
                        M1214.405,714.868l-0.014-4.391l10.997-0.032l0.012,4.391L1214.405,714.868L1214.405,714.868z M1236.396,714.808l-0.01-4.391
                        l10.999-0.023l0.008,4.391L1236.396,714.808L1236.396,714.808z M1258.392,714.766l-0.006-4.391l10.997-0.011l0.002,4.391
                        L1258.392,714.766L1258.392,714.766z"/>
                    <polygon fill="#9B9B9B" points="642.752,120.771 640.96,106.431 649.854,105.319 651.646,119.659 642.752,120.771 	"/>
                    <polygon fill="#9B9B9B" points="674.858,119.074 673.086,102.958 681.996,101.979 683.767,118.094 674.858,119.074 	"/>
                    <path fill="#D3E6ED" d="M524.822,498.796c-2.688,25.094,16.249,34.031,63.633,49.293c52.879,17.029,84.248,28.68,128.164,42.125
                        c33.345,10.205,66.322,13.443,69.907-0.898c3.585-14.34-44.928-45.371-88.634-71.377c-71.492-42.533-109.835-88.596-139.013-72.918
                        C542.392,453.874,527.537,473.448,524.822,498.796z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#808184" d="M490.577,195.843l-5.531,3.313l-0.013-0.891l5.528-3.305
                        L490.577,195.843L490.577,195.843z M484.942,193.101l0.062-0.094c0.266-0.5,0.228-1.113-0.09-1.568L484.942,193.101
                        L484.942,193.101z M484.884,189.565c1.44-0.029,2.587-1.215,2.563-2.664c-0.024-1.443-1.212-2.592-2.659-2.57L484.884,189.565
                        L484.884,189.565z M484.788,184.331l0.097,5.234c-1.448,0.023-2.637-1.123-2.663-2.574
                        C482.196,185.546,483.343,184.354,484.788,184.331L484.788,184.331z M484.914,191.438l0.028,1.662l-3.279,5.9l-0.515,0.531
                        l-4.476,2.664l-0.062-3.381l2.682-1.582c0.242-0.438,0.627-1.338,1.095-2.328c-1.271,0.969-2.33,2.037-3.79,2.982l-0.073-4.152
                        c1.518-1.316,3.161-2.266,5.602-3.383c0.731-0.322,1.635,0.281,2.286,0.666L484.914,191.438L484.914,191.438z M485.033,198.265
                        l-8.345,4.982l0.018,0.889l8.34-4.98L485.033,198.265L485.033,198.265z M476.524,193.735c-0.636,0.566-1.274,1.225-1.901,1.943
                        l-11.689,7.035c-0.704,0.414-0.937,1.311-0.52,2.008c0.412,0.672,1.309,0.898,2.011,0.492l11.687-7.008l0.486-0.318
                        L476.524,193.735L476.524,193.735z M476.611,198.815l-2.766,1.676c-0.669,0.416-0.903,1.311-0.491,2.01
                        c0.414,0.701,1.311,0.936,2.006,0.486l1.313-0.791L476.611,198.815L476.611,198.815z M476.688,203.247l0.018,0.889l-4.441,2.689
                        c-2.258,1.33-5.127,0.645-6.426-1.516l0.701-0.414c1.078,1.797,3.453,2.371,5.311,1.264L476.688,203.247z"/>
                    <g>
                        <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M998.013,10.133c11.404,0.562,20.479,9.988,20.479,21.531
                            c0,11.533-9.056,20.953-20.444,21.531c-0.261,0.014-108.747,0-108.747,0l-11.95,12.117c0,0-22.258-21.74-22.258-33.648
                            c0-11.906,9.652-21.558,21.56-21.558C877.014,10.106,997.656,10.115,998.013,10.133z"/>
                        <path fill="#E2E2E2" d="M877.374,67.841l-1.276-1.247c-0.931-0.908-22.798-22.411-22.798-34.93
                            c0-12.875,10.477-23.35,23.353-23.35c1.209,0,121.022,0.007,121.449,0.027c12.439,0.613,22.184,10.857,22.184,23.322
                            c0,12.446-9.729,22.69-22.146,23.322c-0.081,0.004-0.16,0.008-28.4,0.008c-29.863,0-73.466-0.005-79.688-0.006L877.374,67.841
                            L877.374,67.841z M876.652,11.898c-10.899,0-19.767,8.867-19.767,19.766c0,8.562,13.746,24.293,20.454,31.105l11.211-11.366h0.75
                            c0,0,48.216,0.006,80.438,0.006c22.944,0,28.043-0.003,28.28-0.005c10.448-0.533,18.681-9.205,18.681-19.74
                            c0-10.552-8.246-19.223-18.773-19.74C996.653,11.908,877.845,11.898,876.652,11.898L876.652,11.898z"/>
                    </g>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M1281.614,760.741c11.404,0.561,20.479,9.988,20.479,21.531
                        c0,11.531-9.055,20.953-20.443,21.531c-0.369,0.018-237.173,0.027-237.548,0.027c-11.907,0-21.559-9.652-21.559-21.559
                        s9.651-21.559,21.559-21.559C1044.463,760.714,1281.257,760.724,1281.614,760.741z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#808184" d="M921.25,37.35l1.364-0.028c0.021,0.928-0.546,1.724-1.331,2.077
                        L921.25,37.35L921.25,37.35z M921.146,31.438l-0.023-1.337l1.356-0.811c0.333-0.181,0.569-0.588,0.566-0.999
                        c-0.015-0.615-0.537-1.123-1.115-1.111l-0.854,0.019l-0.021-1.097l0.854-0.016c1.196-0.021,2.278,0.951,2.297,2.188
                        c0.011,0.818-0.453,1.578-1.129,1.96L921.146,31.438L921.146,31.438z M897.7,37.758c0.019,1.2,1.063,2.207,2.362,2.188
                        l20.338-0.361l0.884-0.186l-0.033-2.049L897.7,37.758L897.7,37.758z M921.054,26.102l-21.229,0.369
                        c0.073,4.003,2.056,7.728,5.444,9.996l9.773-0.17c1.147-0.811,2.156-1.854,2.991-3.102l3.111-1.758l-0.023-1.337l-2.028,1.197
                        c0.56-1.278,0.945-2.651,1.053-4.087l0.929-0.013L921.054,26.102z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#808184" d="M952.332,36.979l3.771,3.48v0.135
                        c0.017,0.994-0.802,1.76-1.744,1.777l-0.184,0.002l-1.78-1.848L952.332,36.979L952.332,36.979z M952.185,28.439l-0.033-1.668
                        l3.194-3.306l0.157-0.081l0.305,0.262l-0.049,0.137l-2.594,3.781C952.899,27.943,952.56,28.245,952.185,28.439L952.185,28.439z
                        M952.139,26.051l2.822-2.98l0.056-0.184l-0.276-0.264l-0.183,0.107l-2.429,2.514L952.139,26.051L952.139,26.051z M952.119,24.521
                        l2.083-2.162l0.073-0.19l-0.271-0.261l-0.188,0.055l-1.715,1.778L952.119,24.521L952.119,24.521z M952.088,23.015l-0.02-1.05
                        l1.037-0.768l0.133-0.029l0.271,0.262l-0.102,0.193L952.088,23.015L952.088,23.015z M950.917,39.021l1.478,1.504l-0.063-3.547
                        l-1.47-1.346L950.917,39.021L950.917,39.021z M952.069,21.965l-1.43,1.05l0.026,1.479l1.423-1.479L952.069,21.965L952.069,21.965z
                        M952.101,23.741l-1.425,1.474l0.064,3.518l0.321-0.008c0.398-0.008,0.772-0.092,1.123-0.285l-0.033-1.668l-0.051,0.057
                        l-0.157,0.084l-0.306-0.266l0.106-0.186l0.395-0.41l-0.01-0.807l-0.79,0.82l-0.187,0.082l-0.274-0.264l0.077-0.166l1.163-1.195
                        L952.101,23.741L952.101,23.741z M948.293,36.324l-0.054-3.059l2.622,2.367l0.056,3.389L948.293,36.324L948.293,36.324z
                        M948.187,30.23l-0.047-2.766c0.1-0.3,0.147-0.622,0.171-0.944l-0.011-0.456c-0.013-0.832,0.377-1.619,1.093-2.143l1.246-0.907
                        l0.026,1.479l-0.477,0.463l-0.104,0.188l0.272,0.261l0.189-0.079l0.13-0.111l0.064,3.518l-0.135,0.005
                        c-0.727,0.064-1.39,0.347-1.865,0.891L948.187,30.23L948.187,30.23z M948.14,27.465c-0.16,0.352-0.367,0.701-0.66,0.98
                        l-1.896,1.855l1.389,1.236l1.215-1.307L948.14,27.465L948.14,27.465z M948.24,33.266l-3.134-2.846l-2.71-2.616l0.06,3.227
                        l0.214-0.034l0.647,0.26l4.977,5.068L948.24,33.266L948.24,33.266z M942.547,36.4l-0.052-3.093l1.136-1.118l1.417,1.48
                        L942.547,36.4L942.547,36.4z M942.396,27.804l0.06,3.227l-0.4,0.245l-0.315,0.327l-1.885-1.765l-0.082-4.563L942.396,27.804
                        L942.396,27.804z M942.496,33.308l0.052,3.093l-2.532,2.756l-0.059-3.383L942.496,33.308L942.496,33.308z M940.015,39.156
                        l-0.059-3.383l-5.094,5.056l0.003,0.137c0.014,0.993,0.805,1.729,1.774,1.712h0.165L940.015,39.156L940.015,39.156z
                        M939.773,25.274l-3.661-3.536l-0.647-0.261c-0.483,0.013-0.879,0.424-0.871,0.901c0.027,1.53,0.669,2.975,1.761,4.025l3.501,3.434
                        L939.773,25.274z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#808184" d="M973.399,40.658l-4.388-16.27l2.68-0.051l2.876,11.66
                        l3.011-11.762l1.75-0.035l3.512,11.653l2.467-11.749l2.494-0.051l-3.765,16.416l-2.194,0.049l-3.282-11.744l-2.929,11.844
                        L973.399,40.658L973.399,40.658z M1001.276,35.525c-0.447,2.734-2.822,4.833-5.763,4.882c-3.256,0.058-5.945-2.489-5.994-5.745
                        l-0.091-4.952c-0.058-3.179,2.528-5.907,5.79-5.953c2.947-0.06,5.397,1.956,5.935,4.761l-2.64,0.045
                        c-0.378-1.422-1.776-2.338-3.251-2.31c-1.875,0.03-3.324,1.618-3.29,3.409l0.086,4.947c0.03,1.937,1.531,3.332,3.409,3.297
                        c1.463-0.024,2.839-0.982,3.168-2.332L1001.276,35.525z"/>
                    <g>
                        <path fillRule="evenodd" clipRule="evenodd" fill="#808184" d="M1064.802,792.788l-4.387-16.268l2.676-0.053l2.879,11.658
                            l3.017-11.752l1.739-0.039l3.518,11.652l2.467-11.75l2.496-0.047l-3.766,16.428l-2.194,0.031l-3.282-11.734l-2.927,11.844
                            L1064.802,792.788L1064.802,792.788z M1092.68,787.657c-0.446,2.736-2.813,4.838-5.763,4.887
                            c-3.257,0.053-5.938-2.492-5.991-5.744l-0.095-4.959c-0.057-3.176,2.534-5.904,5.794-5.951c2.951-0.055,5.397,1.953,5.937,4.762
                            l-2.643,0.035c-0.37-1.414-1.777-2.324-3.252-2.305c-1.876,0.037-3.319,1.629-3.289,3.408l0.09,4.953
                            c0.034,1.934,1.522,3.336,3.406,3.297c1.47-0.023,2.841-0.986,3.172-2.328L1092.68,787.657z"/>
                    </g>
                    <g>
                        <polygon fillRule="evenodd" clipRule="evenodd" fill="#C3252E" points="1154.911,768.11 1162.978,767.968 1163.139,777.097 
                            1172.263,776.933 1172.402,785.007 1163.278,785.161 1163.439,794.288 1155.371,794.431 1155.208,785.304 1146.091,785.466 
                            1145.952,777.396 1155.075,777.237 		"/>
                    </g>
                    <g>
                        <polygon fillRule="evenodd" clipRule="evenodd" fill="#808184" points="1199.627,774.962 1210.21,774.776 1210.241,776.556 
                            1201.579,776.702 1201.65,781.114 1209.669,780.978 1209.695,782.653 1201.681,782.794 1201.773,787.712 1210.581,787.563 
                            1210.613,789.292 1199.88,789.474 		"/>
                        <polygon fillRule="evenodd" clipRule="evenodd" fill="#808184" points="1185.325,775.21 1195.903,775.024 1195.938,776.804 
                            1187.266,776.958 1187.347,781.37 1195.361,781.226 1195.39,782.899 1187.376,783.046 1187.46,787.962 1196.277,787.81 
                            1196.304,789.532 1185.575,789.724 		"/>
                    </g>
                    <g>
                        <path fill="#808184" d="M1233.744,769.103c0,0-3.112-2.385-5.627,3.547l-6.78,15.977l0.082,4.934c0,0.154,0.018,0.309,0.036,0.459
                            l8.497-20.025C1232.166,768.772,1233.744,769.103,1233.744,769.103z"/>
                        <path fill="#808184" d="M1236.967,770.491c0,0-3.115-2.381-5.629,3.547l-9.187,21.641c0.466,0.607,1.118,1.063,1.869,1.283
                            l9.156-21.58C1235.394,770.159,1236.967,770.491,1236.967,770.491z"/>
                        <path fill="#808184" d="M1243.83,779.843l-8.091,0.039c0,0,0.563,2.047,0.899,4.211l2.155-0.031
                            c1.756-0.041,0.019,1.375,0.019,1.375l-1.999,0.031c0.031,0.326,0.048,0.639,0.057,0.943l1.956-0.035
                            c1.752-0.027,0.022,1.383,0.022,1.383l-2.052,0.037c-0.079,0.48-0.217,0.873-0.455,1.098c0,0-2.388,0.16-5.124,1.725
                            c-3.618,2.324-1.602,5.535-1.729,5.543l17.674-0.414C1247.163,795.747,1248.535,790.192,1243.83,779.843z"/>
                    </g>
                    <g>
                        <path fill="#808184" d="M1279.833,777.095l0.681,0.154l-1.686-5.516l-9.933,3.029l3.452,11.279l9.926-3.037l-1.481-4.846
                            l-1.145-0.268L1279.833,777.095z M1276.584,781.892c-0.521,0.156-1.049,0.191-1.557,0.129l-0.717,0.795l-0.618-0.551l0.433-0.479
                            c-1.054-0.406-1.925-1.258-2.28-2.416c-0.61-2.006,0.514-4.127,2.521-4.732c2-0.613,4.116,0.508,4.733,2.516
                            C1279.713,779.153,1278.587,781.274,1276.584,781.892z"/>
                        <path fill="#808184" d="M1281.651,771.026c-0.183-0.602-0.634-1.162-1.194-1.445c-0.515-0.273-1.346-0.398-1.893-0.176
                            l-18.999,5.805l-0.048,0.857l19.306-5.891c0.435-0.125,0.88-0.074,1.251,0.127c0.369,0.195,0.671,0.535,0.8,0.969l1.997,6.518
                            l0.916,0.211L1281.651,771.026z"/>
                        <path fill="#808184" d="M1286.339,786.337l-2.273-7.422l-0.916-0.213l2.407,7.859c0.132,0.426,0.074,0.871-0.118,1.234
                            c-0.217,0.4-0.552,0.652-0.974,0.797l-23.823,7.281l0.628,0.654l23.446-7.158c0.639-0.205,1.141-0.643,1.435-1.191
                            C1286.444,787.636,1286.526,786.974,1286.339,786.337z"/>
                        <polygon fill="#808184" points="1259.599,787.759 1261.188,792.942 1261.961,792.708 1260.38,787.524 		"/>
                        
                            <rect x="1259.151" y="789.502" transform="matrix(-0.2928 -0.9562 0.9562 -0.2928 876.0935 2227.7612)" fill="#808184" width="5.419" height="0.808"/>
                        
                            <rect x="1260.123" y="789.201" transform="matrix(-0.2927 -0.9562 0.9562 -0.2927 877.4407 2228.2507)" fill="#808184" width="5.415" height="0.812"/>
                        
                            <rect x="1261.196" y="788.874" transform="matrix(-0.2929 -0.9561 0.9561 -0.2929 879.4357 2228.9319)" fill="#808184" width="5.421" height="0.808"/>
                        <polygon fill="#808184" points="1263.701,786.505 1265.282,791.69 1266.062,791.456 1264.475,786.271 		"/>
                        
                            <rect x="1263.253" y="788.245" transform="matrix(-0.2935 -0.956 0.956 -0.2935 883.6456 2230.3374)" fill="#808184" width="5.416" height="0.81"/>
                        
                            <rect x="1264.226" y="787.945" transform="matrix(-0.292 -0.9564 0.9564 -0.292 882.9507 2230.2861)" fill="#808184" width="5.414" height="0.812"/>
                        <polygon fill="#808184" points="1266.833,785.546 1268.416,790.728 1269.195,790.493 1267.606,785.312 		"/>
                        
                            <rect x="1266.273" y="787.319" transform="matrix(-0.2917 -0.9565 0.9565 -0.2917 885.6445 2231.2888)" fill="#808184" width="5.417" height="0.812"/>
                        <polygon fill="#808184" points="1271.244,789.868 1269.657,784.685 1268.877,784.927 1270.462,790.104 		"/>
                    </g>
                    <g>
                        <path fillRule="evenodd" clipRule="evenodd" fill="#808184" d="M1036.492,792.978l12.533-0.213l-0.018-0.938l-1.964,0.033
                            c-0.497,0.008-0.945-0.422-0.95-0.955l-0.21-12.064l-8.639,0.15l0.02,1.002l0.937-0.018c0.525-0.014,0.973,0.361,0.983,0.918
                            l0.172,10.131c0.012,0.529-0.421,0.977-0.946,0.984l-1.932,0.027L1036.492,792.978z"/>
                        <path fillRule="evenodd" clipRule="evenodd" fill="#808184" d="M1045.789,773.544c0.035,1.873-1.46,3.424-3.309,3.455
                            c-1.845,0.029-3.391-1.469-3.422-3.34c-0.035-1.875,1.462-3.42,3.307-3.451C1044.208,770.177,1045.756,771.671,1045.789,773.544z"
                            />
                    </g>
                    <g>
                        <g>
                            <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M1005.375,665.081c0,7.916-6.423,14.34-14.34,14.34
                                c-7.918,0-14.341-6.424-14.341-14.34s6.423-14.34,14.341-14.34C998.953,650.741,1005.375,657.165,1005.375,665.081z"/>
                            <path fill="#E2E2E2" d="M991.036,680.421c-8.459,0-15.341-6.882-15.341-15.34s6.882-15.34,15.341-15.34
                                c8.458,0,15.34,6.882,15.34,15.34S999.494,680.421,991.036,680.421L991.036,680.421z M991.036,651.741
                                c-7.356,0-13.341,5.984-13.341,13.34s5.984,13.34,13.341,13.34c7.355,0,13.34-5.984,13.34-13.34S998.391,651.741,991.036,651.741
                                L991.036,651.741z"/>
                        </g>
                        <g>
                            <path fill="#808184" d="M997.451,661.733l-6.147-7.311l-7.075,7.311v11.48h4.538v-4.045c0-1.277,1.037-2.316,2.316-2.316
                                s2.316,1.035,2.316,2.316v4.045h4.052V661.733z"/>
                            <rect x="987.888" y="673.214" fill="#808184" width="5.904" height="0.02"/>
                        </g>
                    </g>
                    <g>
                        <path fill="#808184" d="M1119.764,778.452c2.417-0.086,4.311-2.09,4.23-4.477c-0.095-2.389-2.121-4.254-4.54-4.178
                            c-2.416,0.084-4.316,2.09-4.228,4.479C1115.316,776.667,1117.343,778.536,1119.764,778.452z"/>
                        <path fill="#808184" d="M1127.976,782.415c-0.995-0.898-4.781-3.885-4.781-3.885c-1.029,1.572-5.178,1.941-6.971-0.148
                            c0,0-4.083,3.135-5.078,4.033c-1.568,1.418,0.012,2.738,1.494,1.939c2.057-1.105,3.212-1.865,3.212-1.865l-0.523,2.393l8.44-0.039
                            l-0.499-2.354c0,0,1.155,0.76,3.212,1.865C1127.962,785.153,1129.544,783.833,1127.976,782.415z"/>
                        <path fill="#808184" d="M1114.731,788.315l4.854,2.016c0,0-3.312,3.281-4.331,4.557c-0.869,1.088-3.705,0.17-2.614-2.166
                            C1113.73,790.384,1114.731,788.315,1114.731,788.315z"/>
                        <path fill="#808184" d="M1124.604,788.315l-5.019,2.016c0,0,3.477,3.281,4.495,4.557c0.87,1.088,3.706,0.17,2.616-2.166
                            C1125.605,790.384,1124.604,788.315,1124.604,788.315z"/>
                        <path fill="#808184" d="M1124.367,787.384c0,0-1.979,0.291-2.129-0.598c-0.149-0.895,1.644-1.195,1.644-1.195L1124.367,787.384z"
                            />
                        <path fill="#808184" d="M1114.874,787.384c0,0,1.978,0.291,2.128-0.598c0.149-0.895-1.644-1.195-1.644-1.195L1114.874,787.384z"/>
                        <circle fill="#808184" cx="1119.482" cy="775.646" r="1.316"/>
                    </g>
                    <g>
                        <polygon fillRule="evenodd" clipRule="evenodd" fill="#808184" points="879.992,28.851 883.545,32.518 881.126,35.162 
                            878.355,29.978 875.158,35.084 873.313,31.919 877.046,28.851 871.834,28.851 871.834,38.861 884.511,38.861 884.448,28.851 		"/>
                        <g>
                            
                                <path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#808184" strokeWidth="1.7925" strokeLinecap="round" strokeLinejoin="round" d="
                                M871.026,22.248c0.879-1.492,3.483-2.186,5.114-0.082c1.054,1.356,2.047,4.627,2.047,4.627s-3.585,0.287-5.051,0.017
                                C870.671,26.354,870.162,23.717,871.026,22.248z"/>
                            
                                <path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#808184" strokeWidth="1.7925" strokeLinecap="round" strokeLinejoin="round" d="
                                M885.556,22.248c-0.879-1.492-3.483-2.186-5.114-0.082c-1.054,1.356-2.047,4.627-2.047,4.627s3.585,0.287,5.051,0.017
                                C885.91,26.354,886.42,23.717,885.556,22.248z"/>
                        </g>
                    </g>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#E6E7E8" d="M1140.119,16.989c8.557,0.422,15.365,7.494,15.365,16.155
                        c0,8.654-6.795,15.721-15.34,16.156c-0.277,0.014-81.129,0.021-81.412,0.021c-8.935,0-16.175-7.242-16.175-16.178
                        c0-8.933,7.24-16.174,16.175-16.174C1059.004,16.971,1139.849,16.977,1140.119,16.989z"/>
                    <g>
                        <path fill="#808184" d="M1060.617,35.962h-4.469l-0.769,2.182h-1.827l3.824-10.696h2.027l3.824,10.696h-1.843L1060.617,35.962z
                            M1060.125,34.532l-1.735-4.964l-1.751,4.964H1060.125z"/>
                        <path fill="#808184" d="M1066.946,36.73h3.609v1.414h-5.36V27.463h1.751V36.73z"/>
                        <path fill="#808184" d="M1079.216,27.463v1.429h-2.841v9.252h-1.751v-9.252h-2.857v-1.429H1079.216z"/>
                        <path fill="#808184" d="M1081.198,37.929c-0.215-0.215-0.322-0.481-0.322-0.799s0.107-0.584,0.322-0.799s0.481-0.323,0.799-0.323
                            c0.308,0,0.568,0.107,0.783,0.323s0.322,0.482,0.322,0.799s-0.107,0.584-0.322,0.799c-0.215,0.215-0.476,0.323-0.783,0.323
                            C1081.679,38.251,1081.413,38.144,1081.198,37.929z"/>
                        <path fill="#808184" d="M1088.29,28.464v-2.081h3.886v11.761h-2.321v-9.68H1088.29z"/>
                        <path fill="#808184" d="M1097.923,35.926c0.307,0.253,0.718,0.379,1.233,0.379c0.666,0,1.15-0.277,1.451-0.831
                            c0.301-0.554,0.451-1.487,0.451-2.799c-0.247,0.344-0.594,0.613-1.04,0.807c-0.445,0.193-0.927,0.29-1.442,0.29
                            c-0.688,0-1.309-0.142-1.862-0.427s-0.991-0.71-1.314-1.274c-0.322-0.565-0.483-1.25-0.483-2.057c0-1.194,0.354-2.143,1.064-2.847
                            c0.709-0.705,1.677-1.057,2.901-1.057c1.526,0,2.604,0.489,3.233,1.468c0.629,0.979,0.942,2.452,0.942,4.42
                            c0,1.398-0.12,2.543-0.362,3.436c-0.242,0.893-0.655,1.571-1.241,2.033c-0.586,0.463-1.384,0.694-2.395,0.694
                            c-0.796,0-1.473-0.153-2.031-0.46c-0.56-0.306-0.986-0.709-1.282-1.21c-0.295-0.5-0.465-1.051-0.508-1.653h2.097
                            C1097.421,35.31,1097.617,35.673,1097.923,35.926z M1100.366,31.417c0.323-0.344,0.484-0.807,0.484-1.388
                            c0-0.634-0.17-1.126-0.508-1.476c-0.339-0.349-0.799-0.524-1.379-0.524s-1.04,0.18-1.379,0.541
                            c-0.338,0.36-0.508,0.836-0.508,1.428c0,0.57,0.164,1.035,0.492,1.396s0.819,0.541,1.476,0.541
                            C1099.603,31.933,1100.043,31.761,1100.366,31.417z"/>
                        <path fill="#808184" d="M1105.493,29.32c0-0.581,0.147-1.111,0.443-1.589s0.742-0.86,1.339-1.146
                            c0.596-0.285,1.324-0.427,2.185-0.427c0.859,0,1.588,0.143,2.185,0.427c0.596,0.285,1.042,0.667,1.338,1.146
                            s0.443,1.008,0.443,1.589c0,0.591-0.146,1.11-0.436,1.557s-0.677,0.793-1.161,1.041c0.592,0.258,1.054,0.635,1.387,1.129
                            s0.5,1.081,0.5,1.758c0,0.731-0.186,1.369-0.556,1.912c-0.371,0.543-0.879,0.96-1.524,1.25c-0.645,0.29-1.37,0.436-2.176,0.436
                            c-0.807,0-1.529-0.146-2.169-0.436c-0.64-0.291-1.145-0.707-1.516-1.25c-0.371-0.543-0.556-1.18-0.556-1.912
                            c0-0.678,0.166-1.267,0.499-1.767s0.796-0.874,1.387-1.121C1106.03,31.358,1105.493,30.492,1105.493,29.32z M1107.967,33.369
                            c-0.381,0.312-0.572,0.758-0.572,1.339c0,0.538,0.186,0.976,0.557,1.314c0.371,0.339,0.873,0.508,1.508,0.508
                            c0.634,0,1.134-0.172,1.499-0.516s0.548-0.78,0.548-1.307c0-0.57-0.188-1.014-0.564-1.331c-0.376-0.317-0.87-0.476-1.482-0.476
                            C1108.846,32.901,1108.349,33.057,1107.967,33.369z M1110.757,28.416c-0.317-0.29-0.75-0.436-1.298-0.436
                            c-0.538,0-0.965,0.146-1.282,0.436c-0.317,0.291-0.476,0.705-0.476,1.242c0,0.484,0.164,0.871,0.492,1.162
                            c0.327,0.291,0.749,0.436,1.266,0.436c0.516,0,0.94-0.147,1.273-0.443s0.5-0.686,0.5-1.17
                            C1111.233,29.115,1111.074,28.707,1110.757,28.416z"/>
                        <path fill="#808184" d="M1116.182,29.32c0-0.581,0.147-1.111,0.443-1.589s0.742-0.86,1.339-1.146
                            c0.596-0.285,1.324-0.427,2.185-0.427c0.859,0,1.588,0.143,2.185,0.427c0.596,0.285,1.042,0.667,1.338,1.146
                            s0.443,1.008,0.443,1.589c0,0.591-0.146,1.11-0.436,1.557s-0.677,0.793-1.161,1.041c0.592,0.258,1.054,0.635,1.387,1.129
                            s0.5,1.081,0.5,1.758c0,0.731-0.186,1.369-0.556,1.912c-0.371,0.543-0.879,0.96-1.524,1.25c-0.645,0.29-1.37,0.436-2.176,0.436
                            c-0.807,0-1.529-0.146-2.169-0.436c-0.64-0.291-1.145-0.707-1.516-1.25c-0.371-0.543-0.556-1.18-0.556-1.912
                            c0-0.678,0.166-1.267,0.499-1.767s0.796-0.874,1.387-1.121C1116.719,31.358,1116.182,30.492,1116.182,29.32z M1118.657,33.369
                            c-0.381,0.312-0.572,0.758-0.572,1.339c0,0.538,0.186,0.976,0.557,1.314c0.371,0.339,0.873,0.508,1.508,0.508
                            c0.634,0,1.134-0.172,1.499-0.516s0.548-0.78,0.548-1.307c0-0.57-0.188-1.014-0.564-1.331c-0.376-0.317-0.87-0.476-1.482-0.476
                            C1119.536,32.901,1119.039,33.057,1118.657,33.369z M1121.447,28.416c-0.317-0.29-0.75-0.436-1.298-0.436
                            c-0.538,0-0.965,0.146-1.282,0.436c-0.317,0.291-0.476,0.705-0.476,1.242c0,0.484,0.164,0.871,0.492,1.162
                            c0.327,0.291,0.749,0.436,1.266,0.436c0.516,0,0.94-0.147,1.273-0.443s0.5-0.686,0.5-1.17
                            C1121.922,29.115,1121.763,28.707,1121.447,28.416z"/>
                        <path fill="#808184" d="M1144.679,30.086c0.671,0.672,1.007,1.611,1.007,2.815v5.243h-2.257v-4.937
                            c0-0.699-0.178-1.234-0.532-1.605c-0.354-0.371-0.838-0.557-1.451-0.557c-0.612,0-1.099,0.186-1.459,0.557
                            c-0.36,0.371-0.54,0.906-0.54,1.605v4.937h-2.257v-4.937c0-0.699-0.178-1.234-0.532-1.605c-0.354-0.371-0.838-0.557-1.451-0.557
                            c-0.623,0-1.115,0.186-1.475,0.557c-0.36,0.371-0.54,0.906-0.54,1.605v4.937h-2.258v-8.938h2.258v1.081
                            c0.29-0.376,0.663-0.672,1.12-0.887s0.959-0.323,1.508-0.323c0.698,0,1.321,0.148,1.87,0.443c0.548,0.296,0.973,0.718,1.273,1.267
                            c0.29-0.516,0.712-0.93,1.266-1.242s1.152-0.468,1.798-0.468C1143.123,29.078,1144.007,29.414,1144.679,30.086z"/>
                    </g>
                    <g>
                        <path fill="#444444" d="M552.635,509.538c-0.656-0.27-1.18-0.667-1.572-1.194c-0.393-0.526-0.599-1.161-0.621-1.903h2.934
                            c0.043,0.419,0.188,0.739,0.436,0.96c0.247,0.221,0.569,0.33,0.967,0.33c0.408,0,0.731-0.094,0.967-0.282
                            c0.236-0.188,0.355-0.448,0.355-0.782c0-0.279-0.094-0.511-0.282-0.693c-0.188-0.183-0.419-0.333-0.693-0.452
                            c-0.274-0.118-0.664-0.252-1.169-0.403c-0.731-0.226-1.328-0.451-1.79-0.678c-0.462-0.226-0.86-0.559-1.193-1
                            c-0.333-0.44-0.5-1.017-0.5-1.726c0-1.055,0.381-1.88,1.145-2.477s1.757-0.896,2.983-0.896c1.247,0,2.251,0.299,3.015,0.896
                            s1.171,1.428,1.226,2.492h-2.983c-0.021-0.365-0.156-0.653-0.403-0.863c-0.248-0.209-0.564-0.314-0.951-0.314
                            c-0.333,0-0.602,0.089-0.806,0.267c-0.205,0.177-0.307,0.433-0.307,0.766c0,0.366,0.172,0.651,0.516,0.855
                            c0.344,0.204,0.881,0.425,1.612,0.661c0.731,0.248,1.325,0.484,1.781,0.71c0.457,0.226,0.852,0.555,1.185,0.984
                            s0.5,0.984,0.5,1.661c0,0.646-0.164,1.232-0.492,1.759c-0.328,0.527-0.804,0.946-1.427,1.259c-0.624,0.312-1.36,0.468-2.209,0.468
                            C554.033,509.941,553.291,509.807,552.635,509.538z"/>
                        <path fill="#444444" d="M571.128,509.828h-2.757l-4.611-6.985v6.985h-2.757v-11.325h2.757l4.611,7.018v-7.018h2.757V509.828z"/>
                        <path fill="#444444" d="M575.876,509.199c-0.887-0.495-1.591-1.186-2.112-2.073s-0.782-1.885-0.782-2.992
                            c0-1.108,0.26-2.103,0.782-2.985c0.521-0.881,1.226-1.569,2.112-2.064s1.862-0.742,2.926-0.742c1.064,0,2.039,0.247,2.926,0.742
                            c0.887,0.495,1.585,1.184,2.096,2.064c0.51,0.883,0.766,1.877,0.766,2.985c0,1.107-0.258,2.104-0.774,2.992
                            c-0.516,0.888-1.214,1.578-2.096,2.073c-0.881,0.494-1.854,0.742-2.918,0.742C577.738,509.941,576.763,509.693,575.876,509.199z
                            M580.971,506.521c0.542-0.602,0.814-1.397,0.814-2.387c0-1.001-0.271-1.799-0.814-2.396c-0.543-0.597-1.266-0.895-2.168-0.895
                            c-0.914,0-1.642,0.296-2.185,0.887c-0.542,0.592-0.814,1.394-0.814,2.404c0,1,0.271,1.799,0.814,2.396
                            c0.543,0.597,1.271,0.896,2.185,0.896C579.706,507.425,580.428,507.123,580.971,506.521z"/>
                        <path fill="#444444" d="M602.019,498.503l-2.951,11.325h-3.337l-1.806-7.453l-1.87,7.453h-3.337l-2.87-11.325h2.951l1.628,8.244
                            l2.015-8.244h3.031l1.935,8.244l1.645-8.244H602.019z"/>
                        <path fill="#444444" d="M610.154,504.473c-0.609,0.589-1.539,0.883-2.788,0.883h-2.058v4.473h-1.397v-10.712h3.456
                            c1.208,0,2.127,0.292,2.757,0.876c0.629,0.584,0.944,1.337,0.944,2.26C611.068,503.143,610.763,503.883,610.154,504.473z
                            M609.086,503.696c0.369-0.338,0.553-0.819,0.553-1.444c0-1.322-0.758-1.983-2.273-1.983h-2.058v3.935h2.058
                            C608.145,504.203,608.718,504.034,609.086,503.696z"/>
                        <path fill="#444444" d="M619.438,507.446h-4.669l-0.86,2.382h-1.475l3.871-10.65h1.613l3.855,10.65h-1.475L619.438,507.446z
                            M619.039,506.309l-1.936-5.409l-1.935,5.409H619.039z"/>
                        <path fill="#444444" d="M629.406,509.828l-2.549-4.38h-1.69v4.38h-1.397v-10.712h3.456c0.809,0,1.493,0.139,2.051,0.415
                            c0.558,0.276,0.975,0.65,1.251,1.122c0.277,0.472,0.415,1.009,0.415,1.613c0,0.738-0.213,1.389-0.638,1.952
                            s-1.063,0.938-1.912,1.122l2.688,4.487H629.406z M625.167,504.326h2.058c0.758,0,1.326-0.187,1.705-0.561s0.568-0.874,0.568-1.499
                            c0-0.635-0.187-1.127-0.561-1.475c-0.374-0.349-0.945-0.523-1.713-0.523h-2.058V504.326z"/>
                        <path fill="#444444" d="M639.221,509.828l-4.408-4.887v4.887h-1.397v-10.712h1.397v4.964l4.424-4.964h1.766l-4.854,5.363
                            l4.899,5.349H639.221z"/>
                    </g>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M678.243,534.653c0,12.371-10.032,22.404-22.406,22.404
                        s-22.406-10.033-22.406-22.404c0-12.377,10.032-22.406,22.406-22.406S678.243,522.276,678.243,534.653z"/>
                    <g>
                        <circle fill="#5F6062" cx="653.883" cy="527.175" r="3.385"/>
                        <polyline fill="none" stroke="#5F6062" strokeWidth="3.0472" strokeLinecap="round" strokeLinejoin="round" points="
                            647.907,533.749 655.973,533.749 661.798,528.673 		"/>
                        <polyline fill="none" stroke="#5F6062" strokeWidth="3.0472" strokeLinecap="round" strokeLinejoin="round" points="
                            653.583,545.253 653.583,538.083 656.42,539.126 660.155,535.989 660.006,541.669 		"/>
                        
                            <line fill="none" stroke="#5F6062" strokeWidth="1.7925" strokeLinecap="round" strokeLinejoin="round" x1="648.805" y1="549.282" x2="666.43" y2="540.319"/>
                        
                            <line fill="none" stroke="#5F6062" strokeWidth="3.0472" strokeLinecap="round" strokeLinejoin="round" x1="655.973" y1="533.749" x2="655.973" y2="538.679"/>
                    </g>
                    <path fill="#CECECE" d="M1232.154,693.445l1.649-10.775c5.051-2.475,10.418-5.153,16.083-7.98
                        c32.163-16.052,72.191-36.029,118.949-51.504c55.632-18.411,106.605-25.729,155.837-22.376
                        c139.836,9.532,167.91,22.761,169.037,23.319l-5.33,10.752c-1.052-0.489-28.941-12.856-164.523-22.099
                        c-108.261-7.375-204.761,40.779-268.611,72.645c-5.692,2.841-18.503,9.006-23.579,11.492L1232.154,693.445z"/>
                    
                        <text transform="matrix(0.9994 0 0 1 252.6372 160.29)" fill="#5F6062">P</text>
                    <path fill="#E2E2E2" d="M259.098,172.573c-13.183,0-23.906-10.724-23.906-23.906c0-13.182,10.724-23.905,23.906-23.905
                        c13.182,0,23.906,10.724,23.906,23.905C283.004,161.849,272.28,172.573,259.098,172.573L259.098,172.573z M259.098,127.762
                        c-11.528,0-20.906,9.378-20.906,20.905c0,11.528,9.378,20.906,20.906,20.906c11.527,0,20.906-9.378,20.906-20.906
                        C280.004,137.14,270.625,127.762,259.098,127.762L259.098,127.762z"/>
                    <circle data-tooltip-content={language[lang].tooltip_parking_lot} data-tooltip-id="svgTooltipGeral" className='geral' fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" cx="259.098" cy="148.667" r="22.406"/>
                    <g>
                        <path fill="#808184" d="M267.432,148.054c-0.538,0.996-1.381,1.798-2.527,2.407c-1.147,0.609-2.594,0.914-4.34,0.914h-3.563v8.167
                            h-4.264v-21.27h7.827c1.645,0,3.045,0.284,4.202,0.853c1.157,0.569,2.025,1.352,2.604,2.347c0.578,0.996,0.868,2.123,0.868,3.383
                            C268.24,145.992,267.97,147.059,267.432,148.054z M263.001,147.124c0.568-0.537,0.853-1.295,0.853-2.27
                            c0-2.072-1.157-3.108-3.472-3.108h-3.381v6.186h3.381C261.559,147.932,262.432,147.663,263.001,147.124z"/>
                    </g>
                    <path fill="#9B9B9B" d="M697.642,172.753l0-4.391c-0.281,0-0.479,0.056-0.501,0.063c0.606-0.18,4.466-1.776,9.28-4.557l2.196,3.802
                        C704.943,169.792,698.991,172.753,697.642,172.753L697.642,172.753z M717.987,161.381l-2.708-3.457
                        c1.86-1.457,3.569-2.962,5.079-4.475c0.887-0.888,1.749-1.819,2.562-2.768l3.334,2.857c-0.885,1.033-1.823,2.047-2.788,3.013
                        C721.83,158.19,719.987,159.814,717.987,161.381L717.987,161.381z M732.731,144.145l-3.852-2.108
                        c1.655-3.022,3.05-6.284,4.149-9.697l4.18,1.346C736.025,137.361,734.519,140.88,732.731,144.145L732.731,144.145z
                        M739.743,122.629l-4.347-0.619c0.515-3.614,0.623-6.589,0.623-8.448c0-0.97-0.029-1.68-0.051-2.076l4.385-0.24
                        c0.024,0.442,0.057,1.234,0.057,2.316C740.41,115.563,740.294,118.76,739.743,122.629L739.743,122.629z"/>
                    <circle fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" cx="576.613" cy="168.286" r="22.406"/>
                    <g>
                        <g>
                            <polygon fillRule="evenodd" clipRule="evenodd" fill="#808184" points="564.433,166.116 588.668,166.224 588.706,178.925 
                                564.466,178.821 			"/>
                            <polygon fillRule="evenodd" clipRule="evenodd" fill="#808184" points="565.774,157.647 587.401,157.763 590.31,164.501 
                                562.916,164.354 			"/>
                            <polygon fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" points="566.28,168.245 586.561,168.317 586.55,173.804 
                                566.26,173.733 			"/>
                        </g>
                    </g>
                    <g>
                        <path fill="#9B9B9B" d="M975.634,730.14v2.556h4.119v2.376h-4.119v2.772h4.659v2.466h-7.735v-12.636h7.735v2.466H975.634z"/>
                        <path fill="#9B9B9B" d="M993.587,740.31h-3.076l-5.145-7.794v7.794h-3.076v-12.636h3.076l5.145,7.83v-7.83h3.076V740.31z"/>
                        <path fill="#9B9B9B" d="M1004.902,727.674v2.466h-3.346v10.17h-3.076v-10.17h-3.346v-2.466H1004.902z"/>
                        <path fill="#9B9B9B" d="M1012.889,740.31l-2.627-4.77h-0.737v4.77h-3.076v-12.636h5.163c0.995,0,1.844,0.174,2.545,0.521
                            c0.702,0.349,1.227,0.825,1.574,1.432c0.348,0.605,0.521,1.28,0.521,2.024c0,0.84-0.236,1.591-0.71,2.25
                            c-0.474,0.66-1.173,1.128-2.096,1.404l2.914,5.004H1012.889z M1009.525,733.361h1.907c0.563,0,0.986-0.138,1.268-0.414
                            c0.282-0.275,0.423-0.666,0.423-1.17c0-0.479-0.141-0.857-0.423-1.134c-0.281-0.276-0.704-0.414-1.268-0.414h-1.907V733.361z"/>
                        <path fill="#9B9B9B" d="M1026.039,738.077h-4.713l-0.756,2.232h-3.22l4.569-12.636h3.562l4.569,12.636h-3.256L1026.039,738.077z
                            M1025.247,735.701l-1.564-4.626l-1.547,4.626H1025.247z"/>
                        <path fill="#9B9B9B" d="M1039.656,728.466c0.996,0.528,1.766,1.269,2.312,2.223s0.819,2.056,0.819,3.303
                            c0,1.236-0.273,2.334-0.819,3.294c-0.546,0.961-1.319,1.704-2.32,2.232s-2.161,0.792-3.48,0.792h-4.731v-12.636h4.731
                            C1037.498,727.674,1038.661,727.938,1039.656,728.466z M1038.685,736.691c0.647-0.636,0.971-1.536,0.971-2.7
                            c0-1.163-0.323-2.069-0.971-2.718c-0.647-0.647-1.554-0.972-2.717-0.972h-1.457v7.344h1.457
                            C1037.131,737.646,1038.038,737.328,1038.685,736.691z"/>
                        <path fill="#9B9B9B" d="M1052.375,738.077h-4.713l-0.756,2.232h-3.22l4.569-12.636h3.562l4.569,12.636h-3.256L1052.375,738.077z
                            M1051.583,735.701l-1.564-4.626l-1.547,4.626H1051.583z"/>
                    </g>
                    <g>
                        <g>
                            <path fill="#9B9B9B" d="M612.854,131.197v2.556h4.119v2.376h-4.119v2.772h4.659v2.466h-7.735v-12.636h7.735v2.466H612.854z"/>
                            <path fill="#9B9B9B" d="M630.807,141.367h-3.076l-5.145-7.794v7.794h-3.076v-12.636h3.076l5.145,7.83v-7.83h3.076V141.367z"/>
                            <path fill="#9B9B9B" d="M642.123,128.731v2.466h-3.347v10.17H635.7v-10.17h-3.346v-2.466H642.123z"/>
                            <path fill="#9B9B9B" d="M650.109,141.367l-2.627-4.77h-0.737v4.77h-3.076v-12.636h5.163c0.995,0,1.844,0.174,2.545,0.521
                                c0.702,0.349,1.227,0.825,1.574,1.432c0.348,0.605,0.521,1.28,0.521,2.024c0,0.84-0.236,1.591-0.71,2.25
                                c-0.474,0.66-1.173,1.128-2.096,1.404l2.914,5.004H650.109z M646.745,134.419h1.907c0.563,0,0.986-0.138,1.268-0.414
                                c0.282-0.275,0.423-0.666,0.423-1.17c0-0.479-0.141-0.857-0.423-1.134c-0.281-0.276-0.704-0.414-1.268-0.414h-1.907V134.419z"/>
                            <path fill="#9B9B9B" d="M663.259,139.135h-4.713l-0.756,2.232h-3.22l4.569-12.636h3.562l4.569,12.636h-3.256L663.259,139.135z
                                M662.467,136.759l-1.564-4.626l-1.547,4.626H662.467z"/>
                            <path fill="#9B9B9B" d="M676.876,129.523c0.996,0.528,1.766,1.269,2.312,2.223s0.819,2.056,0.819,3.303
                                c0,1.236-0.273,2.334-0.819,3.294c-0.546,0.961-1.319,1.704-2.32,2.232s-2.161,0.792-3.48,0.792h-4.731v-12.636h4.731
                                C674.718,128.731,675.881,128.995,676.876,129.523z M675.905,137.749c0.647-0.636,0.971-1.536,0.971-2.7
                                c0-1.163-0.323-2.069-0.971-2.718c-0.647-0.647-1.554-0.972-2.717-0.972h-1.457v7.344h1.457
                                C674.352,138.703,675.258,138.386,675.905,137.749z"/>
                            <path fill="#9B9B9B" d="M689.595,139.135h-4.713l-0.756,2.232h-3.22l4.569-12.636h3.562l4.569,12.636h-3.256L689.595,139.135z
                                M688.803,136.759l-1.564-4.626l-1.547,4.626H688.803z"/>
                            <path fill="#9B9B9B" d="M616.031,149.55c0.834,0.425,1.474,1.032,1.919,1.822c0.444,0.79,0.667,1.72,0.667,2.79
                                s-0.223,1.998-0.667,2.782c-0.445,0.785-1.085,1.386-1.919,1.801c-0.835,0.415-1.821,0.622-2.961,0.622h-3.253v-10.455h3.253
                                C614.21,148.912,615.196,149.125,616.031,149.55z M616.158,157.17c0.71-0.715,1.064-1.718,1.064-3.008
                                c0-1.3-0.357-2.314-1.071-3.045c-0.715-0.729-1.742-1.095-3.081-1.095h-1.889v8.22h1.889
                                C614.42,158.242,615.449,157.885,616.158,157.17z"/>
                            <path fill="#9B9B9B" d="M621.78,150.022v3.495h3.808v1.125h-3.808v3.6h4.257v1.125h-5.621v-10.47h5.621v1.125H621.78z"/>
                            <path fill="#9B9B9B" d="M638.539,148.912v1.11h-2.848v9.345h-1.364v-9.345h-2.863v-1.11H638.539z"/>
                            <path fill="#9B9B9B" d="M645.72,159.367l-2.489-4.275h-1.648v4.275h-1.364v-10.455h3.373c0.789,0,1.456,0.135,2.001,0.405
                                c0.545,0.27,0.952,0.635,1.222,1.095c0.271,0.46,0.405,0.985,0.405,1.575c0,0.72-0.208,1.355-0.622,1.905
                                c-0.415,0.55-1.037,0.915-1.866,1.095l2.623,4.38H645.72z M641.582,153.997h2.009c0.739,0,1.294-0.183,1.664-0.548
                                c0.369-0.364,0.555-0.852,0.555-1.462c0-0.62-0.183-1.1-0.548-1.44c-0.364-0.34-0.922-0.51-1.671-0.51h-2.009V153.997z"/>
                            <path fill="#9B9B9B" d="M650.696,150.022v3.495h3.808v1.125h-3.808v3.6h4.257v1.125h-5.621v-10.47h5.621v1.125H650.696z"/>
                            <path fill="#9B9B9B" d="M665.237,159.367h-1.364l-5.486-8.325v8.325h-1.364v-10.47h1.364l5.486,8.31v-8.31h1.364V159.367z"/>
                            <path fill="#9B9B9B" d="M669.645,158.79c-0.8-0.455-1.432-1.09-1.896-1.905s-0.697-1.732-0.697-2.753
                                c0-1.02,0.232-1.937,0.697-2.752s1.097-1.45,1.896-1.905c0.799-0.455,1.684-0.683,2.653-0.683c0.979,0,1.868,0.228,2.668,0.683
                                s1.43,1.088,1.889,1.897c0.46,0.811,0.69,1.73,0.69,2.76c0,1.03-0.23,1.95-0.69,2.761c-0.459,0.81-1.089,1.442-1.889,1.897
                                s-1.688,0.683-2.668,0.683C671.328,159.473,670.443,159.245,669.645,158.79z M674.269,157.777c0.585-0.34,1.045-0.825,1.38-1.455
                                c0.334-0.63,0.502-1.36,0.502-2.19c0-0.84-0.168-1.572-0.502-2.197c-0.335-0.625-0.793-1.107-1.372-1.447
                                c-0.58-0.34-1.239-0.51-1.979-0.51c-0.74,0-1.399,0.17-1.979,0.51c-0.58,0.34-1.037,0.822-1.372,1.447s-0.502,1.357-0.502,2.197
                                c0,0.83,0.167,1.561,0.502,2.19s0.795,1.115,1.379,1.455c0.585,0.34,1.242,0.51,1.972,0.51S673.685,158.117,674.269,157.777z
                                M673.797,146.647l-3.193,1.71v-1.021l3.193-1.875V146.647z"/>
                            <path fill="#9B9B9B" d="M680.806,159.104c-0.545-0.245-0.973-0.585-1.282-1.02c-0.31-0.436-0.47-0.938-0.479-1.508h1.454
                                c0.05,0.49,0.252,0.902,0.607,1.237c0.354,0.335,0.871,0.503,1.551,0.503c0.65,0,1.162-0.163,1.537-0.487
                                c0.375-0.325,0.563-0.743,0.563-1.253c0-0.399-0.11-0.725-0.33-0.975s-0.495-0.44-0.824-0.57c-0.33-0.13-0.775-0.271-1.335-0.42
                                c-0.689-0.18-1.241-0.36-1.656-0.54s-0.77-0.463-1.064-0.848s-0.442-0.902-0.442-1.553c0-0.569,0.146-1.074,0.436-1.515
                                c0.289-0.44,0.696-0.78,1.222-1.021c0.524-0.239,1.126-0.359,1.806-0.359c0.979,0,1.781,0.245,2.406,0.734
                                c0.625,0.49,0.977,1.141,1.057,1.95h-1.499c-0.05-0.399-0.26-0.752-0.629-1.058c-0.37-0.305-0.86-0.457-1.47-0.457
                                c-0.569,0-1.034,0.147-1.394,0.442c-0.36,0.295-0.54,0.708-0.54,1.237c0,0.381,0.107,0.69,0.322,0.931
                                c0.215,0.239,0.479,0.422,0.795,0.547c0.314,0.125,0.757,0.268,1.326,0.428c0.689,0.19,1.244,0.378,1.664,0.563
                                c0.42,0.186,0.779,0.47,1.079,0.854c0.3,0.386,0.45,0.908,0.45,1.568c0,0.51-0.135,0.989-0.405,1.439
                                c-0.27,0.45-0.669,0.815-1.199,1.095c-0.529,0.28-1.154,0.421-1.874,0.421C681.967,159.473,681.35,159.35,680.806,159.104z"/>
                        </g>
                    </g>
                    <g>
                        <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M1215.167,661.304c5.452,0.27,10.348,2.657,13.88,6.357
                            s5.701,8.713,5.701,14.232c0,5.514-2.164,10.521-5.689,14.22s-8.412,6.088-13.857,6.364c-0.177,0.009-23.544,0.016-46.912,0.021
                            c-23.367,0.005-46.736,0.008-46.916,0.008c-5.692,0-10.846-2.307-14.576-6.037s-6.037-8.884-6.037-14.576
                            s2.307-10.846,6.037-14.577c3.73-3.73,8.884-6.038,14.576-6.038c0.174,0,23.537,0.003,46.899,0.008
                            C1191.634,661.29,1214.996,661.297,1215.167,661.304z"/>
                        <path fill="#E2E2E2" d="M1121.373,704.007c-5.907,0-11.46-2.3-15.637-6.477s-6.477-9.729-6.477-15.637
                            c0-5.906,2.3-11.46,6.477-15.638c4.177-4.177,9.73-6.478,15.637-6.478l46.899,0.008c17.434,0.003,46.716,0.009,46.954,0.019
                            c5.687,0.281,10.975,2.703,14.905,6.82c3.944,4.132,6.116,9.555,6.116,15.269c0,5.708-2.168,11.126-6.104,15.255
                            c-3.923,4.115-9.203,6.54-14.867,6.827c-0.139,0.007-3.589,0.013-46.988,0.023L1121.373,704.007L1121.373,704.007z
                            M1121.373,662.778c-5.105,0-9.905,1.988-13.516,5.599c-3.609,3.61-5.598,8.411-5.598,13.517s1.988,9.905,5.598,13.516
                            c3.61,3.609,8.41,5.598,13.516,5.598l46.916-0.008c24.018-0.006,46.32-0.013,46.88-0.021c4.851-0.246,9.413-2.342,12.804-5.899
                            c3.402-3.568,5.275-8.251,5.275-13.185c0-4.939-1.877-9.626-5.286-13.196c-3.397-3.56-7.968-5.653-12.869-5.896
                            c-0.477-0.005-23.869-0.012-46.82-0.016L1121.373,662.778L1121.373,662.778z"/>
                    </g>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#808184" d="M1172.79,687.462l1.369-0.027
                        c0.014,0.924-0.554,1.719-1.329,2.076L1172.79,687.462L1172.79,687.462z M1172.688,681.55l-0.025-1.334l1.351-0.813
                        c0.342-0.174,0.576-0.588,0.567-1.002c-0.01-0.615-0.528-1.117-1.11-1.109l-0.855,0.014l-0.024-1.092l0.856-0.014
                        c1.195-0.018,2.275,0.955,2.296,2.182c0.015,0.818-0.451,1.582-1.127,1.963L1172.688,681.55L1172.688,681.55z M1149.246,687.872
                        c0.018,1.193,1.061,2.201,2.361,2.178l20.332-0.35l0.891-0.189l-0.04-2.049L1149.246,687.872L1149.246,687.872z M1172.59,676.214
                        l-21.22,0.367c0.066,4.002,2.047,7.734,5.439,9.996l9.771-0.172c1.151-0.809,2.158-1.85,2.99-3.092l3.117-1.764l-0.025-1.334
                        l-2.033,1.193c0.562-1.271,0.949-2.65,1.059-4.088l0.927-0.016L1172.59,676.214z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#808184" d="M1131.921,686.304l3.767,3.48l0.004,0.137
                        c0.016,0.99-0.805,1.758-1.745,1.777h-0.186l-1.777-1.848L1131.921,686.304L1131.921,686.304z M1131.771,677.765l-0.028-1.666
                        l3.196-3.305l0.154-0.084l0.301,0.262l-0.049,0.137l-2.593,3.781C1132.489,677.269,1132.138,677.569,1131.771,677.765
                        L1131.771,677.765z M1131.729,675.374l2.821-2.98l0.051-0.186l-0.275-0.266l-0.182,0.117l-2.428,2.506L1131.729,675.374
                        L1131.729,675.374z M1131.707,673.843l2.081-2.16l0.077-0.184l-0.273-0.268l-0.186,0.061l-1.719,1.773L1131.707,673.843
                        L1131.707,673.843z M1131.672,672.343l-0.018-1.047l1.038-0.773l0.132-0.029l0.272,0.262l-0.101,0.193L1131.672,672.343
                        L1131.672,672.343z M1130.501,688.341l1.482,1.51l-0.063-3.547l-1.471-1.344L1130.501,688.341L1130.501,688.341z M1131.655,671.296
                        l-1.428,1.039l0.024,1.477l1.421-1.469L1131.655,671.296L1131.655,671.296z M1131.687,673.065l-1.422,1.471l0.06,3.52l0.325-0.004
                        c0.399-0.006,0.771-0.094,1.121-0.287l-0.028-1.666l-0.056,0.057l-0.156,0.084l-0.307-0.264l0.108-0.189l0.396-0.412l-0.013-0.809
                        l-0.789,0.83l-0.189,0.076l-0.271-0.262l0.072-0.162l1.168-1.205L1131.687,673.065L1131.687,673.065z M1127.88,685.649
                        l-0.056-3.057l2.626,2.367l0.051,3.381L1127.88,685.649L1127.88,685.649z M1127.774,679.558l-0.05-2.77
                        c0.101-0.297,0.151-0.623,0.173-0.941l-0.009-0.459c-0.013-0.83,0.375-1.621,1.092-2.146l1.247-0.906l0.024,1.477l-0.477,0.469
                        l-0.105,0.186l0.276,0.271l0.187-0.084l0.133-0.117l0.06,3.52l-0.13,0.006c-0.728,0.068-1.395,0.344-1.865,0.891L1127.774,679.558
                        L1127.774,679.558z M1127.724,676.788c-0.154,0.354-0.365,0.707-0.659,0.98l-1.897,1.855l1.392,1.24l1.215-1.307L1127.724,676.788
                        L1127.724,676.788z M1127.825,682.593l-3.134-2.854l-2.709-2.607l0.058,3.225l0.215-0.033l0.652,0.256l4.974,5.07L1127.825,682.593
                        L1127.825,682.593z M1122.132,685.726l-0.051-3.092l1.136-1.123l1.419,1.484L1122.132,685.726L1122.132,685.726z M1121.982,677.132
                        l0.058,3.225l-0.397,0.244l-0.318,0.326l-1.883-1.766l-0.079-4.561L1121.982,677.132L1121.982,677.132z M1122.082,682.634
                        l0.051,3.092l-2.525,2.756l-0.062-3.381L1122.082,682.634L1122.082,682.634z M1119.607,688.481l-0.062-3.381l-5.098,5.057
                        l0.004,0.135c0.016,0.994,0.809,1.729,1.774,1.711h0.166L1119.607,688.481L1119.607,688.481z M1119.362,674.601l-3.661-3.539
                        l-0.65-0.26c-0.48,0.014-0.879,0.42-0.868,0.898c0.024,1.535,0.668,2.977,1.759,4.027l3.5,3.434L1119.362,674.601z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#808184" d="M1192.152,691.087l-4.392-16.281l2.675-0.045l2.89,11.658
                        l3.004-11.76l1.747-0.035l3.512,11.641l2.469-11.746l2.502-0.041l-3.775,16.42l-2.192,0.041l-3.282-11.738l-2.923,11.844
                        L1192.152,691.087L1192.152,691.087z M1220.025,685.954c-0.443,2.73-2.813,4.828-5.765,4.877c-3.26,0.057-5.934-2.486-5.992-5.756
                        l-0.089-4.951c-0.056-3.176,2.531-5.898,5.791-5.959c2.955-0.045,5.398,1.973,5.94,4.775l-2.636,0.043
                        c-0.379-1.418-1.787-2.332-3.256-2.307c-1.884,0.031-3.328,1.617-3.293,3.402l0.079,4.957c0.038,1.922,1.535,3.318,3.409,3.295
                        c1.474-0.025,2.843-0.984,3.176-2.332L1220.025,685.954z"/>
                    <g data-tooltip-content={language[lang].tooltip_parking_lot} data-tooltip-id="svgTooltipGeral" className='geral'>
                        
                            <circle fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" stroke="#CCCCCD" strokeWidth="1.7925" cx="259.105" cy="609.934" r="22.406"/>
                        <g>
                            <path fill="#808184" d="M267.439,609.32c-0.538,0.996-1.381,1.798-2.527,2.407c-1.147,0.609-2.594,0.914-4.34,0.914h-3.563v8.167
                                h-4.264v-21.27h7.827c1.645,0,3.045,0.284,4.202,0.853c1.157,0.569,2.025,1.352,2.604,2.347c0.578,0.996,0.868,2.123,0.868,3.383
                                C268.247,607.259,267.977,608.325,267.439,609.32z M263.008,608.391c0.568-0.537,0.853-1.295,0.853-2.27
                                c0-2.072-1.157-3.108-3.472-3.108h-3.381v6.186h3.381C261.566,609.198,262.439,608.93,263.008,608.391z"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M726.681,135.743c0,7.916-6.423,14.34-14.34,14.34
                                c-7.918,0-14.341-6.424-14.341-14.34s6.423-14.34,14.341-14.34C720.258,121.403,726.681,127.827,726.681,135.743z"/>
                            <path fill="#E2E2E2" d="M712.341,151.083c-8.459,0-15.341-6.882-15.341-15.34s6.882-15.34,15.341-15.34
                                c8.458,0,15.34,6.882,15.34,15.34S720.799,151.083,712.341,151.083L712.341,151.083z M712.341,122.403
                                c-7.356,0-13.341,5.984-13.341,13.34s5.984,13.34,13.341,13.34c7.355,0,13.34-5.984,13.34-13.34S719.697,122.403,712.341,122.403
                                L712.341,122.403z"/>
                        </g>
                        <g>
                            <path fill="#808184" d="M718.756,132.396l-6.147-7.311l-7.075,7.311v11.48h4.538v-4.045c0-1.277,1.037-2.316,2.316-2.316
                                s2.316,1.035,2.316,2.316v4.045h4.052V132.396z"/>
                            <rect x="709.194" y="143.876" fill="#808184" width="5.904" height="0.02"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M894.402,600.571c0,7.916-6.423,14.34-14.34,14.34
                                c-7.918,0-14.341-6.424-14.341-14.34s6.423-14.34,14.341-14.34C887.979,586.231,894.402,592.655,894.402,600.571z"/>
                            <path fill="#E2E2E2" d="M880.062,615.911c-8.459,0-15.341-6.882-15.341-15.34s6.882-15.34,15.341-15.34
                                c8.458,0,15.34,6.882,15.34,15.34S888.52,615.911,880.062,615.911L880.062,615.911z M880.062,587.231
                                c-7.356,0-13.341,5.984-13.341,13.34s5.984,13.34,13.341,13.34c7.355,0,13.34-5.984,13.34-13.34S887.417,587.231,880.062,587.231
                                L880.062,587.231z"/>
                        </g>
                        <g>
                            <path fill="#808184" d="M886.477,597.224l-6.147-7.311l-7.075,7.311v11.48h4.538v-4.045c0-1.277,1.037-2.316,2.316-2.316
                                s2.316,1.035,2.316,2.316v4.045h4.052V597.224z"/>
                            <rect x="876.915" y="608.704" fill="#808184" width="5.904" height="0.02"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M814.083,664.187c0,7.916-6.423,14.34-14.34,14.34
                                c-7.918,0-14.341-6.424-14.341-14.34s6.423-14.34,14.341-14.34C807.66,649.847,814.083,656.271,814.083,664.187z"/>
                            <path fill="#E2E2E2" d="M799.743,679.526c-8.459,0-15.341-6.882-15.341-15.34s6.882-15.34,15.341-15.34
                                c8.458,0,15.34,6.882,15.34,15.34S808.201,679.526,799.743,679.526L799.743,679.526z M799.743,650.847
                                c-7.356,0-13.341,5.984-13.341,13.34s5.984,13.34,13.341,13.34c7.355,0,13.34-5.984,13.34-13.34S807.098,650.847,799.743,650.847
                                L799.743,650.847z"/>
                        </g>
                        <g>
                            <path fill="#808184" d="M806.158,660.839l-6.147-7.311l-7.075,7.311v11.48h4.538v-4.045c0-1.277,1.037-2.316,2.316-2.316
                                s2.316,1.035,2.316,2.316v4.045h4.052V660.839z"/>
                            <rect x="796.595" y="672.319" fill="#808184" width="5.904" height="0.02"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M1414.158,640.104c0,7.916-6.423,14.34-14.34,14.34
                                c-7.918,0-14.341-6.424-14.341-14.34s6.423-14.34,14.341-14.34C1407.735,625.765,1414.158,632.188,1414.158,640.104z"/>
                            <path fill="#E2E2E2" d="M1399.818,655.444c-8.459,0-15.341-6.882-15.341-15.34s6.882-15.34,15.341-15.34
                                c8.458,0,15.34,6.882,15.34,15.34S1408.276,655.444,1399.818,655.444L1399.818,655.444z M1399.818,626.765
                                c-7.356,0-13.341,5.984-13.341,13.34s5.984,13.34,13.341,13.34c7.355,0,13.34-5.984,13.34-13.34
                                S1407.173,626.765,1399.818,626.765L1399.818,626.765z"/>
                        </g>
                        <g>
                            <path fill="#808184" d="M1406.233,636.757l-6.147-7.311l-7.075,7.311v11.48h4.538v-4.045c0-1.277,1.037-2.316,2.316-2.316
                                s2.316,1.035,2.316,2.316v4.045h4.052V636.757z"/>
                            <rect x="1396.67" y="648.237" fill="#808184" width="5.904" height="0.02"/>
                        </g>
                    </g>
                </g>
                <g id="Teleskis">
                    <g id="Teleski_da_lagoa" data-tooltip-content="2" data-tooltip-id="svgTooltipMeios" className='meios-mecanicos'>
                        <path fill="#9B9B9B" d="M670.525,453.524l-2.711-1.947l2.562-3.566l2.711,1.947L670.525,453.524L670.525,453.524z
                            M664.903,449.486l-2.912-2.091l2.561-3.566l2.912,2.091L664.903,449.486L664.903,449.486z M659.078,445.305l-2.912-2.092
                            l2.562-3.566l2.912,2.092L659.078,445.305L659.078,445.305z M653.254,441.122l-2.912-2.091l2.562-3.566l2.912,2.091
                            L653.254,441.122L653.254,441.122z M647.43,436.94l-2.912-2.091l2.562-3.567l2.912,2.092L647.43,436.94L647.43,436.94z
                            M641.606,432.759l-2.912-2.092l2.562-3.566l2.912,2.092L641.606,432.759L641.606,432.759z M635.782,428.576l-2.912-2.091
                            l2.562-3.566l2.912,2.091L635.782,428.576L635.782,428.576z M629.958,424.394l-2.912-2.091l2.562-3.566l2.912,2.091
                            L629.958,424.394L629.958,424.394z M624.134,420.212l-2.912-2.092l2.562-3.566l2.912,2.092L624.134,420.212L624.134,420.212z
                            M618.31,416.03l-2.912-2.092l2.562-3.566l2.912,2.092L618.31,416.03L618.31,416.03z M612.487,411.848l-2.912-2.091l2.561-3.566
                            l2.912,2.091L612.487,411.848L612.487,411.848z M606.662,407.665l-2.912-2.09l2.562-3.567l2.912,2.091L606.662,407.665
                            L606.662,407.665z M600.839,403.483l-2.912-2.091l2.561-3.566l2.912,2.091L600.839,403.483L600.839,403.483z M595.014,399.301
                            l-2.912-2.091l2.562-3.566l2.912,2.091L595.014,399.301L595.014,399.301z M589.191,395.119l-2.912-2.091l2.561-3.566l2.912,2.091
                            L589.191,395.119L589.191,395.119z"/>
                        <path fill="#9B9B9B" d="M861.42,588.407l-0.896-0.647l2.572-3.559l0.896,0.647L861.42,588.407L861.42,588.407z M857.621,585.658
                            l-2.905-2.101l2.572-3.559l2.905,2.101L857.621,585.658L857.621,585.658z M851.811,581.456l-2.905-2.101l2.572-3.559l2.905,2.101
                            L851.811,581.456L851.811,581.456z M846,577.254l-2.904-2.102l2.574-3.557l2.904,2.102L846,577.254L846,577.254z M840.19,573.052
                            l-2.904-2.102l2.574-3.557l2.904,2.102L840.19,573.052L840.19,573.052z M834.381,568.85l-2.905-2.102l2.574-3.557l2.904,2.102
                            L834.381,568.85L834.381,568.85z M828.571,564.647l-2.905-2.102l2.574-3.557l2.905,2.102L828.571,564.647L828.571,564.647z
                            M822.762,560.446l-2.905-2.101l2.573-3.559l2.905,2.101L822.762,560.446L822.762,560.446z M816.953,556.244l-2.905-2.101
                            l2.573-3.559l2.905,2.101L816.953,556.244L816.953,556.244z M811.143,552.042l-2.905-2.101l2.573-3.559l2.905,2.101
                            L811.143,552.042L811.143,552.042z M805.333,547.84l-2.905-2.101l2.573-3.559l2.905,2.101L805.333,547.84L805.333,547.84z
                            M799.523,543.638l-2.905-2.101l2.573-3.559l2.905,2.101L799.523,543.638L799.523,543.638z M793.713,539.436l-2.905-2.102
                            l2.574-3.557l2.905,2.102L793.713,539.436L793.713,539.436z M787.904,535.233l-2.905-2.102l2.574-3.557l2.905,2.102
                            L787.904,535.233L787.904,535.233z M782.094,531.031l-2.905-2.102l2.574-3.557l2.905,2.102L782.094,531.031L782.094,531.031z
                            M776.285,526.83l-2.905-2.101l2.573-3.559l2.905,2.101L776.285,526.83L776.285,526.83z M770.476,522.628l-2.905-2.101
                            l2.573-3.559l2.905,2.101L770.476,522.628L770.476,522.628z M764.666,518.426l-2.905-2.101l2.573-3.559l2.905,2.101
                            L764.666,518.426L764.666,518.426z M758.856,514.224l-2.905-2.101l2.573-3.559l2.905,2.101L758.856,514.224L758.856,514.224z
                            M753.047,510.021l-2.905-2.101l2.573-3.559l2.905,2.101L753.047,510.021L753.047,510.021z M747.237,505.819l-2.905-2.102
                            l2.574-3.557l2.905,2.102L747.237,505.819L747.237,505.819z M741.427,501.617l-2.905-2.102l2.574-3.557l2.905,2.102
                            L741.427,501.617L741.427,501.617z M735.617,497.415l-2.905-2.102l2.574-3.557l2.905,2.102L735.617,497.415L735.617,497.415z
                            M729.808,493.213l-2.905-2.102l2.574-3.557l2.905,2.102L729.808,493.213L729.808,493.213z M723.999,489.012l-2.905-2.101
                            l2.573-3.559l2.905,2.101L723.999,489.012L723.999,489.012z M718.189,484.81l-2.905-2.101l2.573-3.559l2.905,2.101L718.189,484.81
                            L718.189,484.81z M712.379,480.607l-2.905-2.101l2.573-3.559l2.905,2.101L712.379,480.607L712.379,480.607z M706.57,476.405
                            l-2.905-2.101l2.573-3.559l2.905,2.101L706.57,476.405L706.57,476.405z M700.76,472.203l-2.905-2.101l2.573-3.559l2.905,2.101
                            L700.76,472.203L700.76,472.203z"/>
                        <g id="Teleski_da_lagoa" data-tooltip-content="2" data-tooltip-id="svgTooltipMeios" className='meios-mecanicos'>
                            <g>
                                <path fill="#9B9B9B" d="M699.794,432.431l0.004-2.121h-23.25l-0.011,2.121h13.854l-4.073,8.379l-4.205,1.648v-0.031
                                    c0.074-0.609-0.156-1.832-1.514-2.045l-0.347-0.029c-1.142,0-1.53,1.023-1.57,1.598l0.005,8.24
                                    c0.008,0.037,0.245,1.117,1.239,3.184c0.834,1.74,3.107,5.391,3.921,6.852l-7.632,0.256l-0.7,2.02l0.421,0.006
                                    c0,0,7.681,0.145,13.068,0.145c4.767,0,5.089-0.115,5.229-0.164c0.868-0.305,1.747-0.809,2.312-2.318
                                    c0.202-0.508,0.096-1.273-0.135-1.607l-0.287-0.227h-0.165c-0.326,0.035-0.771,0.361-1.308,0.963
                                    c-0.271,0.305-0.758,0.703-1.91,0.791c-0.99,0.07-5.543-0.035-6.522-0.053l-3.228-7.465l10.47-20.141H699.794z M682.113,449.159
                                    v-4.713l3.298-1.414L682.113,449.159z"/>
                                <path fill="#9B9B9B" d="M680.742,439.171c0.487,0,0.954-0.154,1.345-0.455c0.97-0.738,1.148-2.121,0.413-3.096
                                    c-0.727-0.938-2.165-1.127-3.102-0.412c-0.47,0.371-0.775,0.879-0.85,1.467c-0.076,0.588,0.079,1.158,0.443,1.635
                                    C679.412,438.854,680.047,439.171,680.742,439.171z"/>
                            </g>
                        </g>
                        <g id="Teleski_da_lagoa" data-tooltip-content="2" data-tooltip-id="svgTooltipMeios" className='meios-mecanicos'>
                            <path fill="#9B9B9B" d="M657.444,392.384v2.455h-3.332v10.128h-3.063v-10.128h-3.332v-2.455H657.444z"/>
                            <path fill="#9B9B9B" d="M662.406,394.839v2.546h4.103v2.366h-4.103v2.76h4.64v2.456h-7.703v-12.583h7.703v2.455H662.406z"/>
                            <path fill="#9B9B9B" d="M672.455,402.601h4.013v2.366h-7.076v-12.583h3.063V402.601z"/>
                            <path fill="#9B9B9B" d="M681.358,394.839v2.546h4.103v2.366h-4.103v2.76h4.64v2.456h-7.703v-12.583h7.703v2.455H681.358z"/>
                            <path fill="#9B9B9B" d="M690.422,404.645c-0.729-0.299-1.311-0.741-1.747-1.327c-0.436-0.585-0.666-1.29-0.689-2.115h3.26
                                c0.048,0.467,0.209,0.822,0.484,1.067c0.274,0.244,0.633,0.367,1.075,0.367c0.454,0,0.812-0.104,1.075-0.313
                                s0.394-0.499,0.394-0.87c0-0.311-0.104-0.567-0.313-0.771s-0.466-0.37-0.77-0.502c-0.305-0.131-0.738-0.28-1.299-0.448
                                c-0.813-0.251-1.475-0.502-1.988-0.753c-0.514-0.251-0.956-0.621-1.326-1.111c-0.371-0.489-0.556-1.129-0.556-1.918
                                c0-1.171,0.424-2.088,1.272-2.751c0.848-0.663,1.953-0.995,3.314-0.995c1.385,0,2.502,0.332,3.35,0.995
                                c0.848,0.663,1.302,1.586,1.361,2.77h-3.314c-0.024-0.406-0.173-0.727-0.448-0.959c-0.275-0.233-0.627-0.35-1.057-0.35
                                c-0.37,0-0.669,0.099-0.896,0.296c-0.227,0.197-0.34,0.48-0.34,0.852c0,0.406,0.191,0.723,0.573,0.949
                                c0.382,0.228,0.979,0.473,1.792,0.735c0.812,0.275,1.472,0.538,1.979,0.789c0.507,0.25,0.946,0.615,1.316,1.093
                                c0.37,0.479,0.555,1.094,0.555,1.847c0,0.717-0.182,1.368-0.546,1.953c-0.365,0.586-0.893,1.052-1.585,1.398
                                c-0.693,0.347-1.511,0.52-2.455,0.52C691.975,405.092,691.151,404.943,690.422,404.645z"/>
                            <path fill="#9B9B9B" d="M707.011,404.967l-4.228-5.557v5.557h-3.063v-12.583h3.063v5.521l4.192-5.521h3.601l-4.873,6.166
                                l5.052,6.417H707.011z"/>
                            <path fill="#9B9B9B" d="M715.627,392.384v12.583h-3.063v-12.583H715.627z"/>
                            <path fill="#9B9B9B" d="M654.835,411.041c0.855,0.436,1.51,1.058,1.966,1.867c0.455,0.81,0.683,1.763,0.683,2.858
                                c0,1.097-0.228,2.047-0.683,2.851c-0.456,0.805-1.111,1.42-1.966,1.845s-1.866,0.638-3.033,0.638h-3.333v-10.712h3.333
                                C652.969,410.388,653.98,410.605,654.835,411.041z M654.966,418.848c0.727-0.731,1.09-1.759,1.09-3.081
                                c0-1.332-0.366-2.372-1.098-3.119c-0.732-0.748-1.784-1.122-3.156-1.122h-1.936v8.422h1.936
                                C653.185,419.947,654.239,419.581,654.966,418.848z"/>
                            <path fill="#9B9B9B" d="M665.963,418.718h-4.669l-0.86,2.382h-1.475l3.871-10.65h1.613l3.855,10.65h-1.475L665.963,418.718z
                                M665.564,417.58l-1.936-5.409l-1.935,5.409H665.564z"/>
                            <path fill="#9B9B9B" d="M676.1,419.962h3.748v1.138h-5.146v-10.712h1.397V419.962z"/>
                            <path fill="#9B9B9B" d="M687.972,418.718h-4.669l-0.86,2.382h-1.475l3.871-10.65h1.613l3.855,10.65h-1.475L687.972,418.718z
                                M687.573,417.58l-1.936-5.409l-1.935,5.409H687.573z"/>
                            <path fill="#9B9B9B" d="M700.413,413.492c-0.297-0.625-0.727-1.108-1.29-1.452c-0.563-0.343-1.219-0.515-1.966-0.515
                                c-0.748,0-1.421,0.172-2.02,0.515c-0.599,0.344-1.07,0.835-1.413,1.476c-0.343,0.64-0.515,1.381-0.515,2.221
                                s0.171,1.577,0.515,2.213c0.343,0.636,0.814,1.124,1.413,1.468c0.599,0.343,1.272,0.515,2.02,0.515
                                c1.044,0,1.904-0.313,2.58-0.938s1.07-1.47,1.183-2.536h-4.27v-1.137h5.76v1.075c-0.082,0.882-0.359,1.688-0.83,2.421
                                c-0.471,0.732-1.09,1.312-1.858,1.736c-0.768,0.426-1.623,0.638-2.565,0.638c-0.993,0-1.899-0.232-2.719-0.699
                                c-0.819-0.466-1.467-1.114-1.943-1.943c-0.476-0.83-0.714-1.768-0.714-2.813s0.238-1.985,0.714-2.82s1.124-1.485,1.943-1.952
                                c0.819-0.466,1.726-0.699,2.719-0.699c1.137,0,2.143,0.282,3.018,0.846s1.513,1.357,1.912,2.382H700.413z"/>
                            <path fill="#9B9B9B" d="M706.695,420.508c-0.819-0.466-1.467-1.116-1.943-1.952c-0.476-0.834-0.714-1.774-0.714-2.819
                                s0.238-1.985,0.714-2.82s1.124-1.485,1.943-1.952c0.819-0.466,1.726-0.699,2.719-0.699c1.003,0,1.915,0.233,2.734,0.699
                                c0.819,0.467,1.464,1.114,1.935,1.944c0.471,0.83,0.707,1.772,0.707,2.828c0,1.055-0.235,1.998-0.707,2.827
                                c-0.471,0.83-1.116,1.479-1.935,1.944c-0.819,0.467-1.73,0.699-2.734,0.699C708.42,421.207,707.514,420.975,706.695,420.508z
                                M711.433,419.471c0.599-0.349,1.07-0.846,1.413-1.491s0.515-1.393,0.515-2.243c0-0.861-0.171-1.611-0.515-2.252
                                c-0.343-0.64-0.812-1.135-1.405-1.482c-0.594-0.349-1.27-0.523-2.027-0.523s-1.434,0.175-2.027,0.523
                                c-0.594,0.348-1.063,0.843-1.405,1.482c-0.343,0.641-0.515,1.391-0.515,2.252c0,0.851,0.171,1.598,0.515,2.243
                                c0.343,0.646,0.814,1.143,1.413,1.491c0.599,0.349,1.272,0.522,2.02,0.522C710.161,419.993,710.834,419.819,711.433,419.471z"/>
                            <path fill="#9B9B9B" d="M723.267,418.718h-4.669l-0.86,2.382h-1.475l3.871-10.65h1.613l3.855,10.65h-1.475L723.267,418.718z
                                M722.868,417.58l-1.936-5.409l-1.935,5.409H722.868z"/>
                        </g>
                    </g>
                    <g id="Telecadeira_da_torre" data-tooltip-content="1" data-tooltip-id="svgTooltipMeios" className='meios-mecanicos'>
                        <g id="Telecadeira_da_torre" data-tooltip-content="1" data-tooltip-id="svgTooltipMeios" className='meios-mecanicos'>
                            <g>
                                <g>
                                    <path fill="#9B9B9B" d="M1109.221,414.999l1.245,2.234h13.007v-2.406h-6.907c-2.908-5.775,0.126-11.492,1.648-13.771
                                        l-0.043-0.039l0.926-1.576h-2.73l-2.056-5.316h15.605l0.006-1.328h-20.595l-0.006,1.328h2.867l2.056,5.316h-1.738l-0.033-0.068
                                        c-0.032,0.023-0.068,0.049-0.101,0.068h-0.165l-0.454,0.424c-2.559,1.889-4.209,4.832-4.451,8.049
                                        C1107.103,410.487,1107.791,412.962,1109.221,414.999z M1114.392,414.827h-2.772c-1.691-1.824-2.538-4.232-2.346-6.76
                                        c0.2-2.625,1.472-4.953,3.519-6.5h2.804C1113.101,406.075,1112.695,410.739,1114.392,414.827z"/>
                                    <path fill="#9B9B9B" d="M1123.83,420.177l0.333-5.996c0,0-2.538-0.332-3.475-0.406c-0.941-0.078-1.858-0.84-1.762-1.709
                                        l0.719-6.025c0,0,0.052-1.645,1.657-1.455c1.611,0.189,1.471,1.834,1.471,1.834l-0.557,4.688c0,0,2.388,0.393,3.109,0.658
                                        c0.725,0.277,1.302,0.861,1.145,2.174c-0.158,1.307-0.687,5.809-0.687,5.809s6.889-2.102,8.07-2.488
                                        c1.176-0.393,1.716-0.953,2.017-1.527c0.307-0.568,0.679-1.113,0.969-1.225c0.293-0.109,0.75,0.814,0.675,1.447
                                        c-0.179,1.545-0.817,2.273-1.716,2.883c-0.904,0.6-19.533,4.744-19.533,4.744l0.182-1.531L1123.83,420.177z"/>
                                    <path fill="#9B9B9B" d="M1119.974,403.013c-0.713-0.936-0.537-2.256,0.392-2.959c0.923-0.713,2.246-0.535,2.95,0.393
                                        c0.714,0.92,0.532,2.238-0.391,2.947C1122,404.104,1120.679,403.929,1119.974,403.013z"/>
                                </g>
                            </g>
                        </g>
                        <g id="Telecadeira_da_torre" data-tooltip-content="1" data-tooltip-id="svgTooltipMeios" className='meios-mecanicos'>
                            <path fill="#9B9B9B" d="M1161.196,393.709v1.964h-2.666v8.103h-2.45v-8.103h-2.666v-1.964H1161.196z"/>
                            <path fill="#9B9B9B" d="M1164.878,395.673v2.037h3.282v1.893h-3.282v2.208h3.712v1.965h-6.162v-10.066h6.162v1.964H1164.878z"/>
                            <path fill="#9B9B9B" d="M1172.631,401.883h3.211v1.893h-5.661v-10.066h2.45V401.883z"/>
                            <path fill="#9B9B9B" d="M1179.467,395.673v2.037h3.282v1.893h-3.282v2.208h3.712v1.965h-6.162v-10.066h6.162v1.964H1179.467z"/>
                            <path fill="#9B9B9B" d="M1185,396.067c0.43-0.778,1.029-1.386,1.798-1.821c0.77-0.435,1.642-0.652,2.616-0.652
                                c1.193,0,2.216,0.315,3.066,0.946c0.851,0.632,1.419,1.492,1.705,2.582h-2.693c-0.201-0.421-0.485-0.741-0.854-0.961
                                c-0.367-0.22-0.785-0.33-1.254-0.33c-0.755,0-1.366,0.263-1.834,0.789c-0.469,0.525-0.702,1.229-0.702,2.107
                                c0,0.88,0.233,1.582,0.702,2.108c0.468,0.525,1.079,0.788,1.834,0.788c0.469,0,0.887-0.109,1.254-0.329
                                c0.368-0.22,0.652-0.54,0.854-0.961h2.693c-0.286,1.09-0.854,1.948-1.705,2.574s-1.873,0.938-3.066,0.938
                                c-0.975,0-1.847-0.217-2.616-0.652c-0.769-0.435-1.368-1.039-1.798-1.813c-0.431-0.774-0.646-1.659-0.646-2.653
                                S1184.569,396.847,1185,396.067z"/>
                            <path fill="#9B9B9B" d="M1201.953,401.997h-3.755l-0.602,1.778h-2.565l3.64-10.066h2.838l3.64,10.066h-2.594L1201.953,401.997z
                                M1201.322,400.104l-1.247-3.686l-1.232,3.686H1201.322z"/>
                            <path fill="#9B9B9B" d="M1212.801,394.34c0.793,0.421,1.407,1.011,1.842,1.771c0.435,0.761,0.652,1.638,0.652,2.632
                                c0,0.984-0.218,1.859-0.652,2.624s-1.051,1.357-1.849,1.778s-1.722,0.631-2.773,0.631h-3.769v-10.066h3.769
                                C1211.082,393.709,1212.008,393.919,1212.801,394.34z M1212.028,400.893c0.516-0.506,0.773-1.223,0.773-2.15
                                s-0.258-1.649-0.773-2.165c-0.516-0.517-1.237-0.774-2.164-0.774h-1.161v5.851h1.161
                                C1210.791,401.653,1211.512,401.399,1212.028,400.893z"/>
                            <path fill="#9B9B9B" d="M1219.122,395.673v2.037h3.282v1.893h-3.282v2.208h3.712v1.965h-6.162v-10.066h6.162v1.964H1219.122z"/>
                            <path fill="#9B9B9B" d="M1226.875,393.709v10.066h-2.45v-10.066H1226.875z"/>
                            <path fill="#9B9B9B" d="M1233.783,403.775l-2.093-3.8h-0.588v3.8h-2.45v-10.066h4.113c0.793,0,1.469,0.139,2.027,0.416
                                c0.56,0.277,0.977,0.657,1.254,1.14s0.416,1.021,0.416,1.613c0,0.669-0.188,1.267-0.566,1.792
                                c-0.377,0.526-0.934,0.899-1.669,1.119l2.321,3.986H1233.783z M1231.102,398.24h1.52c0.449,0,0.785-0.109,1.01-0.33
                                c0.225-0.22,0.337-0.53,0.337-0.932c0-0.383-0.112-0.684-0.337-0.903s-0.561-0.33-1.01-0.33h-1.52V398.24z"/>
                            <path fill="#9B9B9B" d="M1244.258,401.997h-3.755l-0.602,1.778h-2.565l3.64-10.066h2.838l3.64,10.066h-2.594L1244.258,401.997z
                                M1243.627,400.104l-1.247-3.686l-1.232,3.686H1243.627z"/>
                            <path fill="#9B9B9B" d="M1160.619,409.85c0.854,0.436,1.511,1.058,1.966,1.867c0.456,0.81,0.684,1.763,0.684,2.858
                                c0,1.097-0.228,2.047-0.684,2.851c-0.455,0.805-1.111,1.42-1.966,1.845s-1.866,0.638-3.033,0.638h-3.333v-10.712h3.333
                                C1158.752,409.196,1159.764,409.414,1160.619,409.85z M1160.75,417.656c0.727-0.731,1.09-1.759,1.09-3.081
                                c0-1.332-0.366-2.372-1.098-3.119c-0.732-0.748-1.784-1.122-3.156-1.122h-1.936v8.422h1.936
                                C1158.967,418.756,1160.022,418.39,1160.75,417.656z"/>
                            <path fill="#9B9B9B" d="M1171.439,417.526h-4.669l-0.86,2.382h-1.475l3.87-10.65h1.613l3.854,10.65h-1.474L1171.439,417.526z
                                M1171.04,416.389l-1.936-5.409l-1.935,5.409H1171.04z"/>
                            <path fill="#9B9B9B" d="M1186.153,409.196v1.138h-2.918v9.574h-1.397v-9.574h-2.934v-1.138H1186.153z"/>
                            <path fill="#9B9B9B" d="M1190.007,419.316c-0.819-0.466-1.467-1.116-1.942-1.952c-0.477-0.834-0.715-1.774-0.715-2.819
                                s0.238-1.985,0.715-2.82c0.476-0.835,1.123-1.485,1.942-1.952c0.819-0.466,1.726-0.699,2.719-0.699
                                c1.003,0,1.915,0.233,2.733,0.699c0.819,0.467,1.465,1.114,1.936,1.944s0.707,1.772,0.707,2.828c0,1.055-0.236,1.998-0.707,2.827
                                c-0.471,0.83-1.116,1.479-1.936,1.944c-0.818,0.467-1.73,0.699-2.733,0.699C1191.733,420.016,1190.827,419.783,1190.007,419.316z
                                M1194.746,418.279c0.6-0.349,1.07-0.846,1.413-1.491s0.515-1.393,0.515-2.243c0-0.861-0.172-1.611-0.515-2.252
                                c-0.343-0.64-0.812-1.135-1.405-1.482c-0.594-0.349-1.27-0.523-2.027-0.523s-1.434,0.175-2.027,0.523
                                c-0.594,0.348-1.063,0.843-1.405,1.482c-0.344,0.641-0.515,1.391-0.515,2.252c0,0.851,0.171,1.598,0.515,2.243
                                c0.343,0.646,0.813,1.143,1.413,1.491c0.599,0.349,1.271,0.522,2.02,0.522C1193.473,418.802,1194.147,418.628,1194.746,418.279z"
                                />
                            <path fill="#9B9B9B" d="M1205.582,419.908l-2.55-4.38h-1.689v4.38h-1.397v-10.712h3.456c0.809,0,1.492,0.139,2.05,0.415
                                c0.559,0.276,0.976,0.65,1.252,1.122s0.415,1.009,0.415,1.613c0,0.738-0.213,1.389-0.638,1.952s-1.063,0.938-1.912,1.122
                                l2.688,4.487H1205.582z M1201.342,414.406h2.059c0.758,0,1.325-0.187,1.705-0.561c0.378-0.374,0.567-0.874,0.567-1.499
                                c0-0.635-0.187-1.127-0.561-1.475c-0.373-0.349-0.944-0.523-1.712-0.523h-2.059V414.406z"/>
                            <path fill="#9B9B9B" d="M1214.919,419.908l-2.55-4.38h-1.689v4.38h-1.397v-10.712h3.456c0.809,0,1.492,0.139,2.05,0.415
                                c0.559,0.276,0.976,0.65,1.252,1.122s0.415,1.009,0.415,1.613c0,0.738-0.213,1.389-0.638,1.952s-1.063,0.938-1.912,1.122
                                l2.688,4.487H1214.919z M1210.68,414.406h2.059c0.758,0,1.325-0.187,1.705-0.561c0.378-0.374,0.567-0.874,0.567-1.499
                                c0-0.635-0.187-1.127-0.561-1.475c-0.373-0.349-0.944-0.523-1.712-0.523h-2.059V414.406z"/>
                            <path fill="#9B9B9B" d="M1220.018,410.334v3.58h3.901v1.153h-3.901v3.688h4.362v1.152h-5.76v-10.728h5.76v1.153H1220.018z"/>
                        </g>
                        <path fill="#9B9B9B" d="M1055.871,374.178l-2.493-1.88l2.645-3.506l2.493,1.88L1055.871,374.178L1055.871,374.178z
                            M1050.515,370.139l-2.862-2.159l2.645-3.506l2.862,2.159L1050.515,370.139L1050.515,370.139z M1044.79,365.822l-2.862-2.159
                            l2.645-3.506l2.862,2.159L1044.79,365.822L1044.79,365.822z M1039.065,361.505l-2.862-2.159l2.645-3.506l2.862,2.159
                            L1039.065,361.505L1039.065,361.505z M1033.34,357.188l-2.862-2.159l2.645-3.506l2.862,2.159L1033.34,357.188L1033.34,357.188z
                            M1027.616,352.872l-2.862-2.159l2.645-3.506l2.862,2.159L1027.616,352.872L1027.616,352.872z M1021.891,348.555l-2.862-2.159
                            l2.645-3.506l2.862,2.159L1021.891,348.555L1021.891,348.555z M1016.166,344.238l-2.862-2.159l2.645-3.506l2.862,2.159
                            L1016.166,344.238L1016.166,344.238z M1010.441,339.921l-2.862-2.159l2.645-3.506l2.862,2.159L1010.441,339.921L1010.441,339.921z
                            M1004.716,335.604l-2.862-2.159l2.645-3.506l2.862,2.159L1004.716,335.604L1004.716,335.604z M998.992,331.287l-2.862-2.159
                            l2.645-3.506l2.862,2.159L998.992,331.287L998.992,331.287z M993.267,326.97l-2.862-2.159l2.645-3.506l2.862,2.159L993.267,326.97
                            L993.267,326.97z M987.543,322.653l-2.863-2.159l2.643-3.506l2.863,2.159L987.543,322.653L987.543,322.653z M981.817,318.336
                            l-2.862-2.159l2.645-3.506l2.862,2.159L981.817,318.336L981.817,318.336z M976.092,314.02l-2.862-2.159l2.645-3.506l2.862,2.159
                            L976.092,314.02L976.092,314.02z M970.368,309.702l-2.862-2.158l2.645-3.506l2.862,2.158L970.368,309.702L970.368,309.702z
                            M964.643,305.386l-2.862-2.159l2.645-3.506l2.862,2.159L964.643,305.386L964.643,305.386z M958.918,301.068l-2.862-2.158
                            l2.645-3.506l2.862,2.158L958.918,301.068L958.918,301.068z M953.193,296.751l-2.862-2.158l2.645-3.506l2.862,2.158
                            L953.193,296.751L953.193,296.751z M947.468,292.435l-2.862-2.158l2.645-3.506l2.862,2.158L947.468,292.435L947.468,292.435z
                            M941.744,288.118l-2.862-2.158l2.644-3.506l2.863,2.158L941.744,288.118L941.744,288.118z M936.019,283.801l-2.862-2.158
                            l2.645-3.506l2.861,2.158L936.019,283.801L936.019,283.801z M930.295,279.484l-2.862-2.158l2.644-3.506l2.862,2.158
                            L930.295,279.484L930.295,279.484z M924.571,275.167l-2.863-2.158l2.643-3.507l2.863,2.158L924.571,275.167L924.571,275.167z
                            M918.844,270.85l-2.861-2.158l2.644-3.506l2.862,2.158L918.844,270.85L918.844,270.85z M913.121,266.533l-2.863-2.158
                            l2.645-3.506l2.862,2.158L913.121,266.533L913.121,266.533z M907.395,262.216l-2.862-2.158l2.645-3.506l2.862,2.158
                            L907.395,262.216L907.395,262.216z M901.67,257.899l-2.862-2.158l2.645-3.506l2.862,2.158L901.67,257.899L901.67,257.899z
                            M895.946,253.583l-2.862-2.158l2.645-3.506l2.862,2.158L895.946,253.583L895.946,253.583z M890.22,249.266l-2.862-2.158
                            l2.645-3.506l2.862,2.158L890.22,249.266L890.22,249.266z M884.496,244.949l-2.862-2.158l2.645-3.506l2.862,2.158L884.496,244.949
                            L884.496,244.949z M878.771,240.632l-2.862-2.158l2.644-3.506l2.863,2.158L878.771,240.632L878.771,240.632z M873.046,236.315
                            l-2.862-2.158l2.645-3.506l2.861,2.158L873.046,236.315L873.046,236.315z M867.322,231.998l-2.861-2.158l2.644-3.506l2.862,2.158
                            L867.322,231.998L867.322,231.998z M861.598,227.681l-2.863-2.158l2.645-3.506l2.862,2.158L861.598,227.681L861.598,227.681z
                            M855.872,223.364l-2.862-2.158l2.645-3.506l2.862,2.158L855.872,223.364L855.872,223.364z M850.148,219.047l-2.862-2.158
                            l2.644-3.506l2.862,2.158L850.148,219.047L850.148,219.047z M844.422,214.73l-2.862-2.158l2.645-3.506l2.862,2.158L844.422,214.73
                            L844.422,214.73z M838.698,210.414l-2.862-2.158l2.644-3.506l2.862,2.158L838.698,210.414L838.698,210.414z M832.974,206.097
                            l-2.863-2.158l2.644-3.506l2.863,2.158L832.974,206.097L832.974,206.097z M827.249,201.78l-2.862-2.158l2.644-3.506l2.862,2.158
                            L827.249,201.78L827.249,201.78z M821.524,197.463l-2.862-2.158l2.644-3.506l2.862,2.158L821.524,197.463L821.524,197.463z
                            M815.799,193.146l-2.863-2.158l2.644-3.506l2.863,2.158L815.799,193.146L815.799,193.146z M810.074,188.829l-2.862-2.158
                            l2.644-3.506l2.862,2.158L810.074,188.829L810.074,188.829z M804.35,184.512l-2.862-2.158l2.644-3.506l2.862,2.158L804.35,184.512
                            L804.35,184.512z M798.625,180.195l-2.863-2.158l2.644-3.506l2.863,2.158L798.625,180.195L798.625,180.195z M792.9,175.878
                            l-2.862-2.158l2.644-3.506l2.862,2.158L792.9,175.878L792.9,175.878z M787.175,171.562l-2.862-2.158l2.644-3.506l2.862,2.158
                            L787.175,171.562L787.175,171.562z M781.451,167.245l-2.863-2.158l2.644-3.506l2.863,2.158L781.451,167.245L781.451,167.245z
                            M775.726,162.928l-2.862-2.158l2.644-3.506l2.862,2.158L775.726,162.928L775.726,162.928z"/>
                        <path fill="#9B9B9B" d="M1100.729,404.684l-1.318-0.942l2.553-3.573l1.318,0.942L1100.729,404.684L1100.729,404.684z
                            M1096.494,401.658l-2.918-2.084l2.553-3.573l2.918,2.084L1096.494,401.658L1096.494,401.658z M1090.659,397.49l-2.917-2.083
                            l2.553-3.573l2.917,2.083L1090.659,397.49L1090.659,397.49z M1084.825,393.323l-2.917-2.084l2.553-3.573l2.917,2.084
                            L1084.825,393.323L1084.825,393.323z"/>
                        <path fill="#9B9B9B" d="M1292.508,549.181l-2.852-2.171l2.66-3.494l2.852,2.172L1292.508,549.181L1292.508,549.181z
                            M1286.804,544.838l-2.853-2.172l2.66-3.494l2.853,2.172L1286.804,544.838L1286.804,544.838z M1281.099,540.494l-2.853-2.172
                            l2.66-3.494l2.853,2.172L1281.099,540.494L1281.099,540.494z M1275.395,536.151l-2.853-2.172l2.66-3.494l2.853,2.172
                            L1275.395,536.151L1275.395,536.151z M1269.69,531.808l-2.853-2.172l2.66-3.494l2.853,2.172L1269.69,531.808L1269.69,531.808z
                            M1263.985,527.464l-2.852-2.171l2.66-3.494l2.852,2.171L1263.985,527.464L1263.985,527.464z M1258.281,523.121l-2.853-2.172
                            l2.66-3.494l2.853,2.172L1258.281,523.121L1258.281,523.121z M1252.576,518.777l-2.853-2.172l2.66-3.494l2.853,2.172
                            L1252.576,518.777L1252.576,518.777z M1246.872,514.435l-2.853-2.172l2.66-3.494l2.853,2.172L1246.872,514.435L1246.872,514.435z
                            M1241.167,510.091l-2.853-2.172l2.66-3.494l2.853,2.172L1241.167,510.091L1241.167,510.091z M1235.461,505.747l-2.853-2.171
                            l2.66-3.494l2.853,2.171L1235.461,505.747L1235.461,505.747z M1229.757,501.404l-2.853-2.172l2.66-3.494l2.853,2.172
                            L1229.757,501.404L1229.757,501.404z M1224.052,497.061l-2.853-2.172l2.66-3.494l2.853,2.172L1224.052,497.061L1224.052,497.061z
                            M1218.347,492.717l-2.852-2.171l2.66-3.494l2.852,2.172L1218.347,492.717L1218.347,492.717z M1212.643,488.374l-2.853-2.172
                            l2.66-3.494l2.853,2.172L1212.643,488.374L1212.643,488.374z M1206.938,484.03l-2.853-2.172l2.66-3.494l2.853,2.172
                            L1206.938,484.03L1206.938,484.03z M1201.234,479.688l-2.853-2.172l2.66-3.494l2.853,2.172L1201.234,479.688L1201.234,479.688z
                            M1195.529,475.344l-2.853-2.172l2.66-3.494l2.853,2.172L1195.529,475.344L1195.529,475.344z M1189.824,471l-2.852-2.171
                            l2.66-3.494l2.852,2.171L1189.824,471L1189.824,471z M1184.12,466.657l-2.853-2.172l2.66-3.494l2.853,2.172L1184.12,466.657
                            L1184.12,466.657z M1178.415,462.313l-2.853-2.172l2.66-3.494l2.853,2.172L1178.415,462.313L1178.415,462.313z M1172.71,457.971
                            l-2.853-2.172l2.66-3.494l2.853,2.172L1172.71,457.971L1172.71,457.971z M1167.005,453.627l-2.853-2.172l2.66-3.494l2.853,2.172
                            L1167.005,453.627L1167.005,453.627z M1161.3,449.283l-2.853-2.172l2.66-3.494l2.853,2.172L1161.3,449.283L1161.3,449.283z
                            M1155.596,444.94l-2.853-2.172l2.66-3.494l2.853,2.172L1155.596,444.94L1155.596,444.94z M1149.891,440.597l-2.853-2.172
                            l2.66-3.494l2.853,2.172L1149.891,440.597L1149.891,440.597z M1144.186,436.253l-2.852-2.172l2.66-3.493l2.852,2.171
                            L1144.186,436.253L1144.186,436.253z M1138.482,431.909l-2.853-2.172l2.66-3.492l2.853,2.172L1138.482,431.909L1138.482,431.909z"
                            />
                        <path fill="#9B9B9B" d="M1369.496,608.945l-1.713-1.392l2.77-3.408l1.713,1.392L1369.496,608.945L1369.496,608.945z
                            M1365.001,605.293l-2.783-2.261l2.768-3.408l2.783,2.261L1365.001,605.293L1365.001,605.293z M1359.435,600.771l-2.782-2.261
                            l2.77-3.408l2.782,2.261L1359.435,600.771L1359.435,600.771z M1353.871,596.25l-2.782-2.261l2.77-3.408l2.782,2.261
                            L1353.871,596.25L1353.871,596.25z M1348.306,591.729l-2.782-2.261l2.77-3.408l2.782,2.261L1348.306,591.729L1348.306,591.729z
                            M1342.741,587.207l-2.782-2.261l2.77-3.408l2.782,2.261L1342.741,587.207L1342.741,587.207z M1337.176,582.686l-2.782-2.261
                            l2.77-3.408l2.782,2.261L1337.176,582.686L1337.176,582.686z M1331.612,578.165l-2.782-2.261l2.77-3.408l2.782,2.261
                            L1331.612,578.165L1331.612,578.165z M1326.047,573.644l-2.782-2.261l2.77-3.408l2.782,2.261L1326.047,573.644L1326.047,573.644z
                            M1320.482,569.122l-2.782-2.261l2.77-3.408l2.782,2.261L1320.482,569.122L1320.482,569.122z M1314.917,564.601l-2.782-2.261
                            l2.77-3.408l2.782,2.261L1314.917,564.601L1314.917,564.601z"/>
                    </g>
                    <g id="Tapete_do_cântaro" data-tooltip-content="4" data-tooltip-id="svgTooltipMeios" className='meios-mecanicos'>
                        <path fill="#5F6062" d="M862.745,685.168l-1.867-3.975l3.245-1.524l1.867,3.975L862.745,685.168L862.745,685.168z
                            M869.234,682.119l-1.867-3.975l3.245-1.524l1.867,3.975L869.234,682.119L869.234,682.119z M875.723,679.07l-1.867-3.975
                            l3.245-1.524l1.867,3.975L875.723,679.07L875.723,679.07z M882.213,676.021l-1.867-3.975l3.244-1.524l1.867,3.975L882.213,676.021
                            L882.213,676.021z M888.703,672.973l-1.867-3.975l3.244-1.524l1.867,3.975L888.703,672.973L888.703,672.973z M895.192,669.924
                            l-1.867-3.975l3.245-1.524l1.867,3.975L895.192,669.924L895.192,669.924z M901.681,666.875l-1.867-3.975l3.245-1.524l1.867,3.975
                            L901.681,666.875L901.681,666.875z M908.171,663.826l-1.867-3.975l3.244-1.524l1.867,3.975L908.171,663.826L908.171,663.826z
                            M914.661,660.777l-1.867-3.975l3.244-1.524l1.867,3.975L914.661,660.777L914.661,660.777z M921.15,657.729l-1.867-3.975
                            l3.245-1.524l1.867,3.975L921.15,657.729L921.15,657.729z M927.639,654.68l-1.867-3.975l3.245-1.524l1.867,3.975L927.639,654.68
                            L927.639,654.68z"/>
                        <g data-tooltip-content="4" data-tooltip-id="svgTooltipMeios" className='meios-mecanicos'>
                            <g>
                                <g>
                                    <path fill="#9B9B9B" d="M983.749,614.069v2.105h-2.857v8.684h-2.626v-8.684h-2.857v-2.105H983.749z"/>
                                    <path fill="#9B9B9B" d="M991.781,622.953h-4.024l-0.645,1.905h-2.749l3.9-10.789h3.042l3.9,10.789h-2.779L991.781,622.953z
                                        M991.105,620.924l-1.336-3.949l-1.321,3.949H991.105z"/>
                                    <path fill="#9B9B9B" d="M1004.175,619.257c-0.287,0.518-0.728,0.935-1.321,1.252c-0.594,0.318-1.331,0.477-2.211,0.477h-1.628
                                        v3.873h-2.627v-10.789h4.255c0.859,0,1.587,0.149,2.181,0.446s1.039,0.707,1.336,1.229s0.445,1.122,0.445,1.798
                                        C1004.605,618.168,1004.461,618.739,1004.175,619.257z M1001.564,618.542c0.246-0.235,0.369-0.568,0.369-0.999
                                        s-0.123-0.763-0.369-0.999c-0.245-0.235-0.619-0.354-1.121-0.354h-1.428v2.705h1.428
                                        C1000.945,618.896,1001.319,618.777,1001.564,618.542z"/>
                                    <path fill="#9B9B9B" d="M1008.599,616.175v2.183h3.517v2.028h-3.517v2.367h3.978v2.105h-6.604v-10.789h6.604v2.105H1008.599z"
                                        />
                                    <path fill="#9B9B9B" d="M1022.038,614.069v2.105h-2.857v8.684h-2.626v-8.684h-2.857v-2.105H1022.038z"/>
                                    <path fill="#9B9B9B" d="M1025.985,616.175v2.183h3.517v2.028h-3.517v2.367h3.978v2.105h-6.604v-10.789h6.604v2.105H1025.985z"
                                        />
                                    <path fill="#9B9B9B" d="M982.588,630.933c0.854,0.436,1.511,1.058,1.966,1.867c0.456,0.81,0.684,1.763,0.684,2.858
                                        c0,1.097-0.228,2.047-0.684,2.851c-0.455,0.805-1.111,1.42-1.966,1.845s-1.866,0.638-3.033,0.638h-3.333v-10.712h3.333
                                        C980.722,630.279,981.734,630.497,982.588,630.933z M982.719,638.739c0.727-0.731,1.09-1.759,1.09-3.081
                                        c0-1.332-0.366-2.372-1.098-3.119c-0.732-0.748-1.784-1.122-3.156-1.122h-1.936v8.422h1.936
                                        C980.937,639.839,981.992,639.473,982.719,638.739z"/>
                                    <path fill="#9B9B9B" d="M989.215,640.399c-0.819-0.466-1.467-1.116-1.942-1.952c-0.477-0.834-0.715-1.774-0.715-2.819
                                        s0.238-1.985,0.715-2.82c0.476-0.835,1.123-1.485,1.942-1.952c0.819-0.466,1.726-0.699,2.719-0.699
                                        c1.003,0,1.915,0.233,2.733,0.699c0.819,0.467,1.465,1.114,1.936,1.944s0.707,1.772,0.707,2.828
                                        c0,1.055-0.236,1.998-0.707,2.827c-0.471,0.83-1.116,1.479-1.936,1.944c-0.818,0.467-1.73,0.699-2.733,0.699
                                        C990.941,641.099,990.035,640.866,989.215,640.399z M993.954,639.362c0.6-0.349,1.07-0.846,1.413-1.491
                                        s0.515-1.393,0.515-2.243c0-0.861-0.172-1.611-0.515-2.252c-0.343-0.64-0.812-1.135-1.405-1.482
                                        c-0.594-0.349-1.27-0.523-2.027-0.523s-1.434,0.175-2.027,0.523c-0.594,0.348-1.063,0.843-1.405,1.482
                                        c-0.344,0.641-0.515,1.391-0.515,2.252c0,0.851,0.171,1.598,0.515,2.243c0.343,0.646,0.813,1.143,1.413,1.491
                                        c0.599,0.349,1.271,0.522,2.02,0.522C992.681,639.885,993.355,639.711,993.954,639.362z"/>
                                    <path fill="#9B9B9B" d="M1003.438,632.808c0.471-0.835,1.113-1.488,1.928-1.96c0.813-0.471,1.717-0.706,2.711-0.706
                                        c1.167,0,2.186,0.281,3.056,0.845s1.506,1.363,1.905,2.397h-1.675c-0.297-0.646-0.725-1.143-1.282-1.49
                                        c-0.559-0.349-1.227-0.523-2.004-0.523c-0.748,0-1.419,0.175-2.013,0.523c-0.594,0.348-1.06,0.843-1.397,1.482
                                        c-0.338,0.641-0.507,1.391-0.507,2.252c0,0.851,0.169,1.596,0.507,2.235c0.338,0.641,0.804,1.136,1.397,1.483
                                        c0.594,0.349,1.265,0.522,2.013,0.522c0.777,0,1.445-0.171,2.004-0.515c0.558-0.343,0.985-0.838,1.282-1.483h1.675
                                        c-0.399,1.025-1.035,1.816-1.905,2.375s-1.889,0.837-3.056,0.837c-0.994,0-1.897-0.232-2.711-0.699
                                        c-0.814-0.466-1.457-1.114-1.928-1.943c-0.472-0.83-0.707-1.768-0.707-2.813S1002.966,633.643,1003.438,632.808z"/>
                                    <path fill="#9B9B9B" d="M1021.439,638.609h-4.669l-0.86,2.382h-1.475l3.87-10.65h1.613l3.854,10.65h-1.474L1021.439,638.609z
                                        M1019.12,628.281l-2.135,1.307v-1.106l2.135-1.275l2.119,1.275v1.106L1019.12,628.281z M1021.04,637.472l-1.936-5.409
                                        l-1.935,5.409H1021.04z"/>
                                    <path fill="#9B9B9B" d="M1033.879,640.991h-1.397l-5.622-8.529v8.529h-1.397v-10.728h1.397l5.622,8.515v-8.515h1.397V640.991z"
                                        />
                                    <path fill="#9B9B9B" d="M1042.849,630.279v1.138h-2.918v9.574h-1.397v-9.574h-2.934v-1.138H1042.849z"/>
                                    <path fill="#9B9B9B" d="M1050.897,638.609h-4.669l-0.86,2.382h-1.475l3.87-10.65h1.613l3.854,10.65h-1.474L1050.897,638.609z
                                        M1050.498,637.472l-1.936-5.409l-1.935,5.409H1050.498z"/>
                                    <path fill="#9B9B9B" d="M1060.557,640.991l-2.55-4.38h-1.689v4.38h-1.397v-10.712h3.456c0.809,0,1.492,0.139,2.05,0.415
                                        c0.559,0.276,0.976,0.65,1.252,1.122s0.415,1.009,0.415,1.613c0,0.738-0.213,1.389-0.638,1.952s-1.063,0.938-1.912,1.122
                                        l2.688,4.487H1060.557z M1056.318,635.489h2.059c0.758,0,1.325-0.187,1.705-0.561c0.378-0.374,0.567-0.874,0.567-1.499
                                        c0-0.635-0.187-1.127-0.561-1.475c-0.373-0.349-0.944-0.523-1.712-0.523h-2.059V635.489z"/>
                                    <path fill="#9B9B9B" d="M1066.393,640.399c-0.819-0.466-1.467-1.116-1.942-1.952c-0.477-0.834-0.715-1.774-0.715-2.819
                                        s0.238-1.985,0.715-2.82c0.476-0.835,1.123-1.485,1.942-1.952c0.819-0.466,1.726-0.699,2.719-0.699
                                        c1.003,0,1.915,0.233,2.733,0.699c0.819,0.467,1.465,1.114,1.936,1.944s0.707,1.772,0.707,2.828
                                        c0,1.055-0.236,1.998-0.707,2.827c-0.471,0.83-1.116,1.479-1.936,1.944c-0.818,0.467-1.73,0.699-2.733,0.699
                                        C1068.119,641.099,1067.212,640.866,1066.393,640.399z M1071.131,639.362c0.6-0.349,1.07-0.846,1.413-1.491
                                        s0.515-1.393,0.515-2.243c0-0.861-0.172-1.611-0.515-2.252c-0.343-0.64-0.812-1.135-1.405-1.482
                                        c-0.594-0.349-1.27-0.523-2.027-0.523s-1.434,0.175-2.027,0.523c-0.594,0.348-1.063,0.843-1.405,1.482
                                        c-0.344,0.641-0.515,1.391-0.515,2.252c0,0.851,0.171,1.598,0.515,2.243c0.343,0.646,0.813,1.143,1.413,1.491
                                        c0.599,0.349,1.271,0.522,2.02,0.522C1069.859,639.885,1070.533,639.711,1071.131,639.362z"/>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <path fill="#9B9B9B" d="M948.616,618.122l10.47,20.139l-3.229,7.463c-0.978,0.018-5.531,0.123-6.522,0.057
                                            c-1.151-0.088-1.638-0.49-1.909-0.795c-0.537-0.602-0.982-0.928-1.308-0.963h-0.165l-0.286,0.227
                                            c-0.231,0.338-0.338,1.104-0.136,1.607c0.564,1.51,1.443,2.018,2.311,2.318c0.141,0.049,0.463,0.168,5.229,0.168
                                            c5.388,0,13.068-0.143,13.068-0.143l0.421-0.008l-0.7-2.023l-7.632-0.252c0.813-1.459,3.087-5.115,3.921-6.852
                                            c0.994-2.066,1.231-3.146,1.238-3.186l0.006-8.242c-0.04-0.574-0.429-1.596-1.57-1.596l-0.347,0.031
                                            c-1.357,0.211-1.587,1.432-1.514,2.041v0.031l-4.205-1.648l-4.073-8.375h13.854l-0.011-2.125h-23.249l0.003,2.125H948.616z
                                            M956.664,628.72l3.299,1.418v4.713L956.664,628.72z"/>
                                        <path fill="#9B9B9B" d="M961.333,624.858c-0.487,0-0.955-0.15-1.345-0.455c-0.97-0.74-1.148-2.123-0.413-3.092
                                            c0.727-0.941,2.165-1.131,3.102-0.414c0.47,0.373,0.775,0.881,0.849,1.467c0.077,0.59-0.078,1.16-0.442,1.637
                                            C962.664,624.544,962.028,624.858,961.333,624.858z"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
                <g id="Bolas_pistas">
                    <g data-tooltip-content="12" data-tooltip-id="svgTooltipPistas">
                        <path fill="#D9D9DA" d="M1300.401,609.361c-40.271-10.859-58.217-33.986-75.573-56.352c-17.782-22.916-34.579-44.562-74.032-53.87
                            l1.008-4.273c40.963,9.665,59.025,32.941,76.494,55.451c16.911,21.793,34.399,44.328,73.246,54.804L1300.401,609.361
                            L1300.401,609.361z"/>
                        <g>
                            <polygon fillRule="evenodd" clipRule="evenodd" fill="#B2B2B4" stroke="#FFFFFF" strokeWidth="5.3775" points="
                                1261.781,564.028 1267.792,598.433 1237.791,584.614 			"/>
                            <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M1275.628,549.587c0,23.26-18.862,42.125-42.123,42.125
                                c-23.258,0-42.123-18.865-42.123-42.125c0-23.256,18.865-42.123,42.123-42.123
                                C1256.766,507.464,1275.628,526.331,1275.628,549.587z"/>
                            <g>
                                <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M1269.356,549.587c0,19.795-16.057,35.85-35.851,35.85
                                    c-19.795,0-35.851-16.055-35.851-35.85s16.056-35.85,35.851-35.85C1253.299,513.737,1269.356,529.792,1269.356,549.587z"/>
                                <path fill="#D9D9DA" d="M1233.505,586.781c-20.51,0-37.195-16.686-37.195-37.194s16.686-37.194,37.195-37.194
                                    s37.195,16.686,37.195,37.194S1254.015,586.781,1233.505,586.781L1233.505,586.781z M1233.505,515.082
                                    c-19.026,0-34.506,15.479-34.506,34.505s15.479,34.505,34.506,34.505s34.506-15.479,34.506-34.505
                                    S1252.532,515.082,1233.505,515.082L1233.505,515.082z"/>
                            </g>
                            <g>
                                <path d="M1214.402,541.801c-0.498,0.481-1.257,0.722-2.276,0.722h-1.681v3.651h-1.141v-8.745h2.821
                                    c0.986,0,1.736,0.238,2.251,0.715c0.514,0.477,0.771,1.092,0.771,1.845C1215.148,540.716,1214.899,541.32,1214.402,541.801z
                                    M1213.53,541.167c0.301-0.275,0.451-0.669,0.451-1.179c0-1.079-0.618-1.619-1.855-1.619h-1.681v3.212h1.681
                                    C1212.76,541.581,1213.229,541.443,1213.53,541.167z"/>
                                <path d="M1216.602,537.955c-0.15-0.15-0.226-0.334-0.226-0.552s0.075-0.401,0.226-0.553c0.15-0.15,0.335-0.226,0.552-0.226
                                    c0.209,0,0.387,0.075,0.533,0.226c0.146,0.151,0.22,0.335,0.22,0.553s-0.073,0.401-0.22,0.552s-0.324,0.226-0.533,0.226
                                    C1216.937,538.181,1216.752,538.105,1216.602,537.955z M1217.706,539.298v6.876h-1.141v-6.876H1217.706z"/>
                                <path d="M1220.665,546.017c-0.418-0.18-0.748-0.431-0.991-0.753c-0.242-0.321-0.376-0.691-0.4-1.11h1.179
                                    c0.033,0.344,0.194,0.624,0.482,0.841c0.288,0.218,0.666,0.326,1.135,0.326c0.435,0,0.777-0.096,1.028-0.288
                                    s0.376-0.436,0.376-0.728c0-0.302-0.134-0.525-0.401-0.672s-0.682-0.29-1.241-0.433c-0.51-0.134-0.926-0.27-1.248-0.408
                                    c-0.321-0.138-0.598-0.343-0.827-0.614c-0.23-0.272-0.345-0.63-0.345-1.073c0-0.352,0.104-0.673,0.313-0.966
                                    s0.506-0.525,0.89-0.696c0.385-0.172,0.824-0.258,1.317-0.258c0.761,0,1.375,0.192,1.843,0.577
                                    c0.469,0.385,0.719,0.912,0.753,1.581h-1.142c-0.024-0.359-0.169-0.647-0.433-0.865c-0.263-0.218-0.616-0.326-1.06-0.326
                                    c-0.409,0-0.735,0.088-0.978,0.263c-0.243,0.176-0.364,0.406-0.364,0.69c0,0.226,0.073,0.412,0.22,0.559s0.33,0.264,0.552,0.352
                                    s0.528,0.186,0.922,0.295c0.493,0.134,0.895,0.266,1.203,0.395c0.31,0.13,0.575,0.324,0.797,0.584
                                    c0.222,0.259,0.336,0.598,0.345,1.017c0,0.376-0.104,0.715-0.313,1.016c-0.209,0.302-0.504,0.538-0.884,0.709
                                    c-0.381,0.172-0.817,0.258-1.311,0.258C1221.555,546.287,1221.083,546.196,1220.665,546.017z"/>
                                <path d="M1227.586,540.239v4.053c0,0.334,0.071,0.57,0.213,0.709c0.143,0.138,0.389,0.207,0.74,0.207h0.84v0.966h-1.028
                                    c-0.635,0-1.111-0.146-1.429-0.439c-0.318-0.292-0.477-0.773-0.477-1.442v-4.053h-0.891v-0.941h0.891v-1.731h1.141v1.731h1.793
                                    v0.941H1227.586z"/>
                                <path d="M1230.759,540.86c0.284-0.531,0.675-0.943,1.172-1.236c0.498-0.293,1.052-0.439,1.662-0.439
                                    c0.602,0,1.124,0.13,1.567,0.39c0.442,0.259,0.773,0.585,0.99,0.979v-1.255h1.154v6.876h-1.154v-1.28
                                    c-0.226,0.402-0.563,0.734-1.009,0.998c-0.447,0.264-0.968,0.396-1.562,0.396c-0.61,0-1.162-0.151-1.655-0.452
                                    s-0.882-0.724-1.166-1.268c-0.284-0.543-0.427-1.162-0.427-1.856C1230.333,542.008,1230.475,541.392,1230.759,540.86z
                                    M1235.837,541.368c-0.209-0.385-0.491-0.68-0.846-0.885c-0.355-0.205-0.747-0.308-1.173-0.308
                                    c-0.427,0-0.815,0.101-1.166,0.302c-0.352,0.2-0.632,0.493-0.841,0.878s-0.313,0.837-0.313,1.355
                                    c0,0.526,0.104,0.984,0.313,1.374c0.209,0.389,0.489,0.686,0.841,0.891c0.351,0.205,0.739,0.308,1.166,0.308
                                    c0.426,0,0.817-0.103,1.173-0.308c0.354-0.205,0.637-0.502,0.846-0.891c0.209-0.39,0.313-0.843,0.313-1.361
                                    S1236.046,541.753,1235.837,541.368z"/>
                                <path d="M1242.584,540.86c0.284-0.531,0.675-0.943,1.172-1.236c0.498-0.293,1.056-0.439,1.675-0.439
                                    c0.534,0,1.032,0.124,1.492,0.37c0.459,0.247,0.811,0.571,1.053,0.973v-3.639h1.154v9.285h-1.154v-1.292
                                    c-0.226,0.409-0.56,0.746-1.003,1.01s-0.962,0.396-1.555,0.396c-0.61,0-1.164-0.151-1.662-0.452
                                    c-0.497-0.301-0.888-0.724-1.172-1.268c-0.284-0.543-0.427-1.162-0.427-1.856C1242.158,542.008,1242.3,541.392,1242.584,540.86z
                                    M1247.663,541.368c-0.209-0.385-0.491-0.68-0.847-0.885c-0.354-0.205-0.746-0.308-1.172-0.308
                                    c-0.427,0-0.815,0.101-1.166,0.302c-0.352,0.2-0.632,0.493-0.841,0.878s-0.313,0.837-0.313,1.355
                                    c0,0.526,0.104,0.984,0.313,1.374c0.209,0.389,0.489,0.686,0.841,0.891c0.351,0.205,0.739,0.308,1.166,0.308
                                    c0.426,0,0.817-0.103,1.172-0.308c0.355-0.205,0.638-0.502,0.847-0.891c0.209-0.39,0.313-0.843,0.313-1.361
                                    S1247.872,541.753,1247.663,541.368z"/>
                                <path d="M1252.308,545.848c-0.522-0.293-0.932-0.709-1.229-1.249c-0.297-0.539-0.445-1.164-0.445-1.875
                                    c0-0.703,0.152-1.324,0.458-1.863c0.305-0.54,0.721-0.954,1.247-1.242c0.527-0.289,1.116-0.434,1.769-0.434
                                    s1.241,0.145,1.769,0.434c0.526,0.288,0.942,0.7,1.247,1.235c0.306,0.535,0.458,1.159,0.458,1.87s-0.157,1.336-0.471,1.875
                                    c-0.313,0.54-0.737,0.956-1.272,1.249s-1.129,0.439-1.78,0.439C1253.414,546.287,1252.831,546.141,1252.308,545.848z
                                    M1255.211,544.994c0.359-0.192,0.65-0.48,0.871-0.865c0.222-0.385,0.333-0.854,0.333-1.405c0-0.553-0.109-1.021-0.326-1.405
                                    c-0.218-0.385-0.502-0.672-0.853-0.86c-0.352-0.188-0.731-0.282-1.142-0.282c-0.418,0-0.801,0.095-1.147,0.282
                                    c-0.347,0.188-0.625,0.476-0.834,0.86s-0.313,0.853-0.313,1.405c0,0.561,0.103,1.033,0.308,1.418s0.479,0.671,0.821,0.859
                                    c0.343,0.188,0.719,0.282,1.129,0.282C1254.467,545.283,1254.852,545.187,1255.211,544.994z"/>
                                <path d="M1215.915,550.849v7.712c0,1.193-0.336,2.113-1.008,2.758c-0.672,0.646-1.577,0.969-2.717,0.969
                                    c-1.193,0-2.149-0.339-2.87-1.017c-0.72-0.678-1.08-1.64-1.08-2.888h2.741c0,0.474,0.097,0.831,0.29,1.072
                                    c0.193,0.242,0.473,0.363,0.839,0.363c0.333,0,0.591-0.107,0.773-0.322s0.274-0.527,0.274-0.936v-7.712H1215.915z"/>
                                <path d="M1218.115,555.221c0.349-0.699,0.824-1.236,1.427-1.613c0.602-0.376,1.273-0.564,2.016-0.564
                                    c0.634,0,1.189,0.129,1.668,0.387c0.479,0.259,0.847,0.598,1.104,1.017v-1.274h2.757v9.002h-2.757v-1.274
                                    c-0.269,0.419-0.643,0.758-1.12,1.017c-0.479,0.258-1.035,0.387-1.669,0.387c-0.73,0-1.397-0.19-1.999-0.572
                                    c-0.603-0.382-1.078-0.925-1.427-1.63c-0.35-0.704-0.524-1.519-0.524-2.444C1217.59,556.731,1217.765,555.92,1218.115,555.221z
                                    M1223.757,556.044c-0.382-0.398-0.847-0.598-1.395-0.598s-1.013,0.196-1.395,0.589s-0.572,0.934-0.572,1.621
                                    c0,0.688,0.19,1.234,0.572,1.638s0.847,0.605,1.395,0.605s1.013-0.199,1.395-0.597c0.382-0.398,0.572-0.941,0.572-1.63
                                    S1224.139,556.441,1223.757,556.044z"/>
                                <path d="M1233.132,559.593l1.951-6.421h2.935l-3.192,9.002h-3.402l-3.191-9.002h2.95L1233.132,559.593z"/>
                                <path d="M1239.155,555.221c0.349-0.699,0.824-1.236,1.427-1.613c0.602-0.376,1.273-0.564,2.016-0.564
                                    c0.634,0,1.189,0.129,1.668,0.387c0.479,0.259,0.847,0.598,1.104,1.017v-1.274h2.757v9.002h-2.757v-1.274
                                    c-0.269,0.419-0.643,0.758-1.12,1.017c-0.479,0.258-1.035,0.387-1.669,0.387c-0.73,0-1.397-0.19-1.999-0.572
                                    c-0.603-0.382-1.078-0.925-1.427-1.63c-0.35-0.704-0.524-1.519-0.524-2.444C1238.63,556.731,1238.805,555.92,1239.155,555.221z
                                    M1244.797,556.044c-0.382-0.398-0.847-0.598-1.395-0.598s-1.013,0.196-1.395,0.589s-0.572,0.934-0.572,1.621
                                    c0,0.688,0.19,1.234,0.572,1.638s0.847,0.605,1.395,0.605s1.013-0.199,1.395-0.597c0.382-0.398,0.572-0.941,0.572-1.63
                                    S1245.179,556.441,1244.797,556.044z"/>
                                <path d="M1252.883,550.235v11.938h-2.757v-11.938H1252.883z"/>
                                <path d="M1255.083,551.809c-0.307-0.285-0.46-0.637-0.46-1.057c0-0.43,0.153-0.788,0.46-1.072
                                    c0.307-0.285,0.701-0.428,1.185-0.428c0.474,0,0.863,0.143,1.169,0.428c0.307,0.284,0.46,0.643,0.46,1.072
                                    c0,0.42-0.153,0.771-0.46,1.057c-0.306,0.285-0.695,0.428-1.169,0.428C1255.785,552.236,1255.39,552.094,1255.083,551.809z
                                    M1257.639,553.172v9.002h-2.757v-9.002H1257.639z"/>
                            </g>
                        </g>
                    </g>
                    <g data-tooltip-content="7" data-tooltip-id="svgTooltipPistas">
                        <g>
                            
                                <polygon fillRule="evenodd" clipRule="evenodd" fill="#D6A1A7" stroke="#FFFFFF" strokeWidth="5.3775" strokeLinecap="round" strokeLinejoin="round" points="
                                545.904,368.028 555.823,392.796 527.577,388.907 			"/>
                            <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M547.868,361.071c0,23.256-18.862,42.119-42.123,42.119
                                c-23.259,0-42.124-18.863-42.124-42.119c0-23.262,18.865-42.123,42.124-42.123C529.005,318.948,547.868,337.81,547.868,361.071z"
                                />
                            <g>
                                <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M541.594,361.071c0,19.795-16.055,35.85-35.85,35.85
                                    c-19.794,0-35.851-16.055-35.851-35.85s16.057-35.85,35.851-35.85C525.54,325.222,541.594,341.276,541.594,361.071z"/>
                                <path fill="#E5C4C8" d="M505.745,398.265c-20.509,0-37.195-16.685-37.195-37.194s16.686-37.194,37.195-37.194
                                    c20.509,0,37.194,16.685,37.194,37.194S526.253,398.265,505.745,398.265L505.745,398.265z M505.745,326.566
                                    c-19.027,0-34.506,15.479-34.506,34.505s15.479,34.505,34.506,34.505c19.026,0,34.505-15.479,34.505-34.505
                                    S524.771,326.566,505.745,326.566L505.745,326.566z"/>
                            </g>
                            <g>
                                <path fill="#A00D0D" d="M487.265,353.88c-0.498,0.481-1.256,0.722-2.276,0.722h-1.681v3.651h-1.141v-8.745h2.822
                                    c0.986,0,1.737,0.238,2.251,0.715s0.771,1.092,0.771,1.845C488.011,352.795,487.762,353.399,487.265,353.88z M486.393,353.246
                                    c0.301-0.275,0.452-0.669,0.452-1.179c0-1.079-0.619-1.619-1.856-1.619h-1.681v3.212h1.681
                                    C485.624,353.66,486.092,353.522,486.393,353.246z"/>
                                <path fill="#A00D0D" d="M489.465,350.034c-0.151-0.15-0.226-0.334-0.226-0.552s0.075-0.401,0.226-0.553
                                    c0.15-0.15,0.334-0.226,0.552-0.226c0.208,0,0.386,0.075,0.533,0.226c0.146,0.151,0.219,0.335,0.219,0.553
                                    s-0.073,0.401-0.219,0.552c-0.146,0.15-0.324,0.226-0.533,0.226C489.799,350.26,489.616,350.185,489.465,350.034z
                                    M490.568,351.377v6.876h-1.141v-6.876H490.568z"/>
                                <path fill="#A00D0D" d="M493.528,358.096c-0.418-0.18-0.749-0.431-0.991-0.753c-0.243-0.321-0.376-0.691-0.401-1.11h1.179
                                    c0.033,0.344,0.194,0.624,0.483,0.841c0.289,0.218,0.667,0.326,1.135,0.326c0.435,0,0.777-0.096,1.028-0.288
                                    s0.376-0.436,0.376-0.728c0-0.302-0.134-0.525-0.401-0.672c-0.268-0.146-0.682-0.29-1.242-0.433
                                    c-0.51-0.134-0.926-0.27-1.248-0.408c-0.322-0.138-0.598-0.343-0.828-0.614c-0.23-0.272-0.345-0.63-0.345-1.073
                                    c0-0.352,0.104-0.673,0.313-0.966c0.208-0.293,0.505-0.525,0.89-0.696c0.384-0.172,0.823-0.258,1.317-0.258
                                    c0.76,0,1.375,0.192,1.843,0.577c0.468,0.385,0.719,0.912,0.752,1.581h-1.141c-0.025-0.359-0.169-0.647-0.433-0.865
                                    c-0.264-0.218-0.617-0.326-1.06-0.326c-0.41,0-0.735,0.088-0.978,0.263c-0.242,0.176-0.363,0.406-0.363,0.69
                                    c0,0.226,0.073,0.412,0.219,0.559c0.146,0.146,0.33,0.264,0.552,0.352c0.221,0.088,0.529,0.186,0.922,0.295
                                    c0.493,0.134,0.894,0.266,1.204,0.395c0.309,0.13,0.575,0.324,0.796,0.584c0.221,0.259,0.336,0.598,0.345,1.017
                                    c0,0.376-0.104,0.715-0.313,1.016c-0.209,0.302-0.504,0.538-0.884,0.709c-0.38,0.172-0.817,0.258-1.31,0.258
                                    C494.418,358.366,493.946,358.275,493.528,358.096z"/>
                                <path fill="#A00D0D" d="M500.45,352.318v4.053c0,0.334,0.071,0.57,0.213,0.709c0.142,0.138,0.389,0.207,0.74,0.207h0.84v0.966
                                    h-1.028c-0.636,0-1.112-0.146-1.43-0.439c-0.318-0.292-0.477-0.773-0.477-1.442v-4.053h-0.89v-0.941h0.89v-1.731h1.141v1.731
                                    h1.793v0.941H500.45z"/>
                                <path fill="#A00D0D" d="M503.622,352.939c0.284-0.531,0.675-0.943,1.173-1.236c0.497-0.293,1.051-0.439,1.662-0.439
                                    c0.602,0,1.124,0.13,1.567,0.39c0.443,0.259,0.773,0.585,0.991,0.979v-1.255h1.153v6.876h-1.153v-1.28
                                    c-0.226,0.402-0.563,0.734-1.01,0.998s-0.968,0.396-1.561,0.396c-0.61,0-1.162-0.151-1.655-0.452
                                    c-0.494-0.301-0.882-0.724-1.167-1.268c-0.284-0.543-0.426-1.162-0.426-1.856C503.196,354.087,503.338,353.471,503.622,352.939z
                                    M508.701,353.447c-0.209-0.385-0.491-0.68-0.847-0.885s-0.746-0.308-1.172-0.308s-0.815,0.101-1.167,0.302
                                    c-0.351,0.2-0.631,0.493-0.84,0.878c-0.209,0.385-0.313,0.837-0.313,1.355c0,0.526,0.104,0.984,0.313,1.374
                                    c0.208,0.389,0.489,0.686,0.84,0.891s0.74,0.308,1.167,0.308s0.817-0.103,1.172-0.308s0.637-0.502,0.847-0.891
                                    c0.208-0.39,0.313-0.843,0.313-1.361S508.91,353.832,508.701,353.447z"/>
                                <path fill="#A00D0D" d="M515.447,352.939c0.284-0.531,0.675-0.943,1.173-1.236c0.497-0.293,1.055-0.439,1.674-0.439
                                    c0.535,0,1.033,0.124,1.492,0.37c0.46,0.247,0.811,0.571,1.054,0.973v-3.639h1.153v9.285h-1.153v-1.292
                                    c-0.226,0.409-0.561,0.746-1.003,1.01c-0.443,0.264-0.961,0.396-1.555,0.396c-0.61,0-1.165-0.151-1.662-0.452
                                    c-0.498-0.301-0.889-0.724-1.173-1.268c-0.284-0.543-0.426-1.162-0.426-1.856C515.021,354.087,515.163,353.471,515.447,352.939z
                                    M520.526,353.447c-0.209-0.385-0.491-0.68-0.847-0.885s-0.746-0.308-1.172-0.308s-0.815,0.101-1.167,0.302
                                    c-0.351,0.2-0.631,0.493-0.84,0.878c-0.209,0.385-0.313,0.837-0.313,1.355c0,0.526,0.104,0.984,0.313,1.374
                                    c0.208,0.389,0.489,0.686,0.84,0.891s0.74,0.308,1.167,0.308s0.817-0.103,1.172-0.308s0.637-0.502,0.847-0.891
                                    c0.208-0.39,0.313-0.843,0.313-1.361S520.735,353.832,520.526,353.447z"/>
                                <path fill="#A00D0D" d="M523.924,352.939c0.284-0.531,0.675-0.943,1.173-1.236c0.497-0.293,1.051-0.439,1.662-0.439
                                    c0.602,0,1.124,0.13,1.567,0.39c0.443,0.259,0.773,0.585,0.991,0.979v-1.255h1.153v6.876h-1.153v-1.28
                                    c-0.226,0.402-0.563,0.734-1.01,0.998s-0.968,0.396-1.561,0.396c-0.61,0-1.162-0.151-1.655-0.452
                                    c-0.494-0.301-0.882-0.724-1.167-1.268c-0.284-0.543-0.426-1.162-0.426-1.856C523.498,354.087,523.64,353.471,523.924,352.939z
                                    M529.003,353.447c-0.209-0.385-0.491-0.68-0.847-0.885s-0.746-0.308-1.172-0.308s-0.815,0.101-1.167,0.302
                                    c-0.351,0.2-0.631,0.493-0.84,0.878c-0.209,0.385-0.313,0.837-0.313,1.355c0,0.526,0.104,0.984,0.313,1.374
                                    c0.208,0.389,0.489,0.686,0.84,0.891s0.74,0.308,1.167,0.308s0.817-0.103,1.172-0.308s0.637-0.502,0.847-0.891
                                    c0.208-0.39,0.313-0.843,0.313-1.361S529.211,353.832,529.003,353.447z"/>
                                <path fill="#A00D0D" d="M484.674,372.123h3.612v2.13h-6.369v-11.325h2.757V372.123z"/>
                                <path fill="#A00D0D" d="M489.583,367.3c0.349-0.699,0.825-1.236,1.427-1.613c0.602-0.376,1.273-0.564,2.015-0.564
                                    c0.634,0,1.19,0.129,1.669,0.387c0.478,0.259,0.846,0.598,1.104,1.017v-1.274h2.757v9.002h-2.757v-1.274
                                    c-0.269,0.419-0.643,0.758-1.121,1.017c-0.479,0.258-1.035,0.387-1.669,0.387c-0.731,0-1.397-0.19-1.999-0.572
                                    c-0.602-0.382-1.078-0.925-1.427-1.63c-0.35-0.704-0.524-1.519-0.524-2.444C489.06,368.811,489.234,367.999,489.583,367.3z
                                    M495.226,368.123c-0.381-0.398-0.846-0.598-1.395-0.598c-0.548,0-1.013,0.196-1.395,0.589
                                    c-0.382,0.393-0.572,0.934-0.572,1.621c0,0.688,0.19,1.234,0.572,1.638c0.381,0.403,0.847,0.605,1.395,0.605
                                    c0.548,0,1.013-0.199,1.395-0.597c0.381-0.398,0.573-0.941,0.573-1.63S495.607,368.521,495.226,368.123z"/>
                                <path fill="#A00D0D" d="M505.641,365.509c0.478,0.259,0.846,0.598,1.104,1.017v-1.274h2.757v8.985
                                    c0,0.828-0.164,1.579-0.492,2.251c-0.328,0.672-0.828,1.207-1.5,1.605c-0.672,0.397-1.507,0.597-2.507,0.597
                                    c-1.333,0-2.413-0.314-3.24-0.943c-0.828-0.63-1.301-1.482-1.419-2.558h2.725c0.086,0.344,0.291,0.615,0.613,0.814
                                    s0.72,0.299,1.193,0.299c0.57,0,1.024-0.164,1.362-0.492c0.339-0.328,0.508-0.853,0.508-1.573v-1.274
                                    c-0.269,0.42-0.64,0.762-1.113,1.024c-0.473,0.264-1.026,0.396-1.661,0.396c-0.742,0-1.414-0.19-2.015-0.572
                                    c-0.602-0.382-1.078-0.925-1.427-1.63c-0.35-0.704-0.524-1.519-0.524-2.444c0-0.925,0.174-1.736,0.524-2.436
                                    c0.349-0.699,0.825-1.236,1.427-1.613c0.602-0.376,1.273-0.564,2.015-0.564C504.606,365.122,505.163,365.251,505.641,365.509z
                                    M506.173,368.123c-0.381-0.398-0.846-0.598-1.395-0.598c-0.548,0-1.013,0.196-1.395,0.589
                                    c-0.382,0.393-0.572,0.934-0.572,1.621c0,0.688,0.19,1.234,0.572,1.638c0.381,0.403,0.847,0.605,1.395,0.605
                                    c0.548,0,1.013-0.199,1.395-0.597c0.381-0.398,0.573-0.941,0.573-1.63S506.554,368.521,506.173,368.123z"/>
                                <path fill="#A00D0D" d="M513.219,373.817c-0.704-0.376-1.258-0.914-1.661-1.613c-0.403-0.699-0.604-1.517-0.604-2.452
                                    c0-0.925,0.204-1.739,0.612-2.444c0.409-0.704,0.967-1.244,1.677-1.621c0.709-0.376,1.505-0.564,2.386-0.564
                                    s1.677,0.188,2.386,0.564c0.709,0.377,1.268,0.917,1.677,1.621c0.408,0.705,0.613,1.52,0.613,2.444s-0.207,1.74-0.621,2.444
                                    c-0.414,0.704-0.978,1.245-1.693,1.621c-0.715,0.377-1.513,0.564-2.394,0.564S513.922,374.194,513.219,373.817z
                                    M516.943,371.414c0.371-0.388,0.556-0.941,0.556-1.662s-0.18-1.274-0.54-1.662c-0.36-0.387-0.804-0.58-1.33-0.58
                                    c-0.538,0-0.983,0.19-1.338,0.572c-0.354,0.382-0.532,0.938-0.532,1.67c0,0.721,0.174,1.274,0.524,1.662
                                    c0.349,0.387,0.787,0.58,1.314,0.58C516.123,371.994,516.572,371.801,516.943,371.414z"/>
                                <path fill="#A00D0D" d="M521.748,367.3c0.349-0.699,0.825-1.236,1.427-1.613c0.602-0.376,1.273-0.564,2.015-0.564
                                    c0.634,0,1.19,0.129,1.669,0.387c0.478,0.259,0.846,0.598,1.104,1.017v-1.274h2.757v9.002h-2.757v-1.274
                                    c-0.269,0.419-0.643,0.758-1.121,1.017c-0.479,0.258-1.035,0.387-1.669,0.387c-0.731,0-1.397-0.19-1.999-0.572
                                    c-0.602-0.382-1.078-0.925-1.427-1.63c-0.35-0.704-0.524-1.519-0.524-2.444C521.224,368.811,521.398,367.999,521.748,367.3z
                                    M527.39,368.123c-0.381-0.398-0.846-0.598-1.395-0.598c-0.548,0-1.013,0.196-1.395,0.589c-0.382,0.393-0.572,0.934-0.572,1.621
                                    c0,0.688,0.19,1.234,0.572,1.638c0.381,0.403,0.847,0.605,1.395,0.605c0.548,0,1.013-0.199,1.395-0.597
                                    c0.381-0.398,0.573-0.941,0.573-1.63S527.771,368.521,527.39,368.123z"/>
                            </g>
                        </g>
                        <polygon fill="#A00D0D" points="892.797,635.915 558.129,396.374 560.685,392.804 895.354,632.345 892.797,635.915 		"/>
                    </g>
                    <g data-tooltip-content="5" data-tooltip-id="svgTooltipPistas">
                        <path fill="#0888C1" d="M932.58,608.867c-27.145-10.023-71.745-35.173-110.192-68.765
                            c-30.305-26.479-66.103-66.353-64.539-105.942c1.347-34.126-10.619-51.928-21.176-67.635
                            c-10.181-15.146-19.797-29.453-14.314-54.429l4.919,2.762c-4.99,22.73,3.923,33.886,13.858,48.667
                            c11.016,16.39,23.502,34.967,22.086,70.848c-1.154,29.231,21.115,65.342,62.704,101.68c37.933,33.142,81.835,57.918,108.518,67.77
                            L932.58,608.867L932.58,608.867z"/>
                        <g>
                            <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M801.044,446.771c0,23.258-18.863,42.121-42.124,42.121
                                c-23.257,0-42.123-18.863-42.123-42.121c0-23.26,18.866-42.123,42.123-42.123C782.181,404.647,801.044,423.511,801.044,446.771z"
                                />
                            <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M794.77,446.771c0,19.795-16.055,35.85-35.85,35.85
                                c-19.794,0-35.85-16.055-35.85-35.85s16.056-35.85,35.85-35.85C778.715,410.921,794.77,426.976,794.77,446.771z"/>
                            <path fill="#C2CFDF" d="M758.92,483.965c-20.509,0-37.194-16.686-37.194-37.194s16.685-37.193,37.194-37.193
                                s37.194,16.685,37.194,37.193S779.429,483.965,758.92,483.965L758.92,483.965z M758.92,412.265
                                c-19.026,0-34.505,15.479-34.505,34.506s15.479,34.505,34.505,34.505s34.505-15.479,34.505-34.505
                                S777.947,412.265,758.92,412.265L758.92,412.265z"/>
                            <g>
                                <path fill="#0888C1" d="M740.667,439.577c-0.498,0.481-1.256,0.722-2.276,0.722h-1.681v3.651h-1.141v-8.745h2.822
                                    c0.986,0,1.737,0.238,2.251,0.715s0.771,1.092,0.771,1.845C741.414,438.492,741.165,439.097,740.667,439.577z M739.796,438.943
                                    c0.301-0.275,0.452-0.669,0.452-1.179c0-1.079-0.619-1.619-1.856-1.619h-1.681v3.212h1.681
                                    C739.027,439.357,739.495,439.22,739.796,438.943z"/>
                                <path fill="#0888C1" d="M742.868,435.731c-0.151-0.15-0.226-0.334-0.226-0.552s0.075-0.401,0.226-0.553
                                    c0.15-0.15,0.334-0.226,0.552-0.226c0.208,0,0.386,0.075,0.533,0.226c0.146,0.151,0.219,0.335,0.219,0.553
                                    s-0.073,0.401-0.219,0.552c-0.146,0.15-0.324,0.226-0.533,0.226C743.202,435.957,743.019,435.882,742.868,435.731z
                                    M743.971,437.074v6.876h-1.141v-6.876H743.971z"/>
                                <path fill="#0888C1" d="M746.931,443.793c-0.418-0.18-0.749-0.431-0.991-0.753c-0.243-0.321-0.376-0.691-0.401-1.11h1.179
                                    c0.033,0.344,0.194,0.624,0.483,0.841c0.289,0.218,0.667,0.326,1.135,0.326c0.435,0,0.777-0.096,1.028-0.288
                                    s0.376-0.436,0.376-0.728c0-0.302-0.134-0.525-0.401-0.672c-0.268-0.146-0.682-0.29-1.242-0.433
                                    c-0.51-0.134-0.926-0.27-1.248-0.408c-0.322-0.138-0.598-0.343-0.828-0.614c-0.23-0.272-0.345-0.63-0.345-1.073
                                    c0-0.352,0.104-0.673,0.313-0.966c0.208-0.293,0.505-0.525,0.89-0.696c0.384-0.172,0.823-0.258,1.317-0.258
                                    c0.76,0,1.375,0.192,1.843,0.577c0.468,0.385,0.719,0.912,0.752,1.581h-1.141c-0.025-0.359-0.169-0.647-0.433-0.865
                                    c-0.264-0.218-0.617-0.326-1.06-0.326c-0.41,0-0.735,0.088-0.978,0.263c-0.242,0.176-0.363,0.406-0.363,0.69
                                    c0,0.226,0.073,0.412,0.219,0.559c0.146,0.146,0.33,0.264,0.552,0.352c0.221,0.088,0.529,0.186,0.922,0.295
                                    c0.493,0.134,0.894,0.266,1.204,0.395c0.309,0.13,0.575,0.324,0.796,0.584c0.221,0.259,0.336,0.598,0.345,1.017
                                    c0,0.376-0.104,0.715-0.313,1.016c-0.209,0.302-0.504,0.538-0.884,0.709c-0.38,0.172-0.817,0.258-1.31,0.258
                                    C747.821,444.063,747.349,443.973,746.931,443.793z"/>
                                <path fill="#0888C1" d="M753.853,438.016v4.053c0,0.334,0.071,0.57,0.213,0.709c0.142,0.138,0.389,0.207,0.74,0.207h0.84v0.966
                                    h-1.028c-0.636,0-1.112-0.146-1.43-0.439c-0.318-0.292-0.477-0.773-0.477-1.442v-4.053h-0.89v-0.941h0.89v-1.731h1.141v1.731
                                    h1.793v0.941H753.853z"/>
                                <path fill="#0888C1" d="M757.025,438.637c0.284-0.531,0.675-0.943,1.173-1.236c0.497-0.293,1.051-0.439,1.662-0.439
                                    c0.602,0,1.124,0.13,1.567,0.39c0.443,0.259,0.773,0.585,0.991,0.979v-1.255h1.153v6.876h-1.153v-1.28
                                    c-0.226,0.402-0.563,0.734-1.01,0.998s-0.968,0.396-1.561,0.396c-0.61,0-1.162-0.151-1.655-0.452
                                    c-0.494-0.301-0.882-0.724-1.167-1.268c-0.284-0.543-0.426-1.162-0.426-1.856C756.599,439.784,756.741,439.168,757.025,438.637z
                                    M762.104,439.145c-0.209-0.385-0.491-0.68-0.847-0.885s-0.746-0.308-1.172-0.308s-0.815,0.101-1.167,0.302
                                    c-0.351,0.2-0.631,0.493-0.84,0.878c-0.209,0.385-0.313,0.837-0.313,1.355c0,0.526,0.104,0.984,0.313,1.374
                                    c0.208,0.389,0.489,0.686,0.84,0.891s0.74,0.308,1.167,0.308s0.817-0.103,1.172-0.308s0.637-0.502,0.847-0.891
                                    c0.208-0.39,0.313-0.843,0.313-1.361S762.313,439.529,762.104,439.145z"/>
                                <path fill="#0888C1" d="M768.85,438.637c0.284-0.531,0.675-0.943,1.173-1.236c0.497-0.293,1.055-0.439,1.674-0.439
                                    c0.535,0,1.033,0.124,1.492,0.37c0.46,0.247,0.811,0.571,1.054,0.973v-3.639h1.153v9.285h-1.153v-1.292
                                    c-0.226,0.409-0.561,0.746-1.003,1.01c-0.443,0.264-0.961,0.396-1.555,0.396c-0.61,0-1.165-0.151-1.662-0.452
                                    c-0.498-0.301-0.889-0.724-1.173-1.268c-0.284-0.543-0.426-1.162-0.426-1.856C768.424,439.784,768.566,439.168,768.85,438.637z
                                    M773.929,439.145c-0.209-0.385-0.491-0.68-0.847-0.885s-0.746-0.308-1.172-0.308s-0.815,0.101-1.167,0.302
                                    c-0.351,0.2-0.631,0.493-0.84,0.878c-0.209,0.385-0.313,0.837-0.313,1.355c0,0.526,0.104,0.984,0.313,1.374
                                    c0.208,0.389,0.489,0.686,0.84,0.891s0.74,0.308,1.167,0.308s0.817-0.103,1.172-0.308s0.637-0.502,0.847-0.891
                                    c0.208-0.39,0.313-0.843,0.313-1.361S774.138,439.529,773.929,439.145z"/>
                                <path fill="#0888C1" d="M778.575,443.624c-0.522-0.293-0.932-0.709-1.229-1.249c-0.297-0.539-0.445-1.164-0.445-1.875
                                    c0-0.703,0.152-1.324,0.458-1.863c0.305-0.54,0.721-0.954,1.248-1.242c0.527-0.289,1.116-0.434,1.768-0.434
                                    c0.652,0,1.242,0.145,1.768,0.434c0.527,0.288,0.942,0.7,1.248,1.235c0.305,0.535,0.458,1.159,0.458,1.87
                                    s-0.157,1.336-0.47,1.875c-0.313,0.54-0.738,0.956-1.273,1.249s-1.128,0.439-1.781,0.439
                                    C779.68,444.063,779.097,443.917,778.575,443.624z M781.478,442.771c0.359-0.192,0.65-0.48,0.872-0.865
                                    c0.221-0.385,0.332-0.854,0.332-1.405c0-0.553-0.108-1.021-0.326-1.405c-0.218-0.385-0.501-0.672-0.853-0.86
                                    c-0.351-0.188-0.731-0.282-1.141-0.282c-0.418,0-0.8,0.095-1.147,0.282c-0.347,0.188-0.625,0.476-0.834,0.86
                                    c-0.209,0.385-0.313,0.853-0.313,1.405c0,0.561,0.102,1.033,0.307,1.418c0.205,0.385,0.479,0.671,0.821,0.859
                                    c0.343,0.188,0.719,0.282,1.128,0.282S781.118,442.963,781.478,442.771z"/>
                                <path fill="#0888C1" d="M752.418,450.55l-4.015,11.325h-3.45l-4.015-11.325h2.935l2.805,8.551l2.821-8.551H752.418z"/>
                                <path fill="#0888C1" d="M753.539,454.922c0.349-0.699,0.825-1.236,1.427-1.613c0.602-0.376,1.273-0.564,2.015-0.564
                                    c0.634,0,1.19,0.129,1.669,0.387c0.478,0.259,0.846,0.598,1.104,1.017v-1.274h2.757v9.002h-2.757v-1.274
                                    c-0.269,0.419-0.643,0.758-1.121,1.017c-0.479,0.258-1.035,0.387-1.669,0.387c-0.731,0-1.397-0.19-1.999-0.572
                                    c-0.602-0.382-1.078-0.925-1.427-1.63c-0.35-0.704-0.524-1.519-0.524-2.444C753.015,456.433,753.189,455.621,753.539,454.922z
                                    M759.181,455.745c-0.381-0.398-0.846-0.598-1.395-0.598c-0.548,0-1.013,0.196-1.395,0.589
                                    c-0.382,0.393-0.572,0.934-0.572,1.621c0,0.688,0.19,1.234,0.572,1.638c0.381,0.403,0.847,0.605,1.395,0.605
                                    c0.548,0,1.013-0.199,1.395-0.597c0.381-0.398,0.573-0.941,0.573-1.63S759.563,456.143,759.181,455.745z"/>
                                <path fill="#0888C1" d="M767.266,449.937v11.938h-2.757v-11.938H767.266z"/>
                                <path fill="#0888C1" d="M777.698,458.035h-6.239c0.042,0.56,0.223,0.987,0.54,1.283c0.317,0.296,0.707,0.443,1.169,0.443
                                    c0.688,0,1.166-0.29,1.435-0.871h2.934c-0.151,0.592-0.422,1.124-0.814,1.597c-0.393,0.474-0.884,0.845-1.476,1.113
                                    c-0.591,0.27-1.252,0.403-1.983,0.403c-0.881,0-1.666-0.188-2.354-0.564c-0.688-0.376-1.225-0.914-1.612-1.613
                                    c-0.387-0.699-0.58-1.517-0.58-2.452s0.19-1.753,0.572-2.452c0.381-0.699,0.916-1.236,1.604-1.613
                                    c0.688-0.376,1.478-0.564,2.37-0.564c0.871,0,1.645,0.183,2.322,0.549c0.677,0.365,1.206,0.887,1.588,1.564
                                    c0.381,0.678,0.572,1.468,0.572,2.371C777.746,457.487,777.73,457.756,777.698,458.035z M774.925,456.503
                                    c0-0.474-0.161-0.85-0.484-1.129c-0.322-0.28-0.726-0.42-1.209-0.42c-0.462,0-0.852,0.135-1.169,0.403
                                    c-0.317,0.269-0.513,0.651-0.588,1.146H774.925z"/>
                            </g>
                        </g>
                    </g>
                    <g data-tooltip-content="14" data-tooltip-id="svgTooltipPistas">
                        <g>
                            <g>
                                <polygon fillRule="evenodd" clipRule="evenodd" fill="#B2B2B4" points="550.65,650.157 583.76,639.257 574.534,670.935 				
                                    "/>
                                <path fill="#FFFFFF" d="M574.534,673.623c-0.64,0-1.269-0.229-1.765-0.66l-23.884-20.777c-0.729-0.635-1.062-1.612-0.871-2.56
                                    c0.191-0.948,0.876-1.721,1.795-2.022l33.11-10.9c0.948-0.313,1.991-0.073,2.709,0.62s0.992,1.728,0.713,2.686l-9.227,31.678
                                    c-0.256,0.88-0.944,1.569-1.824,1.828C575.042,673.588,574.787,673.623,574.534,673.623L574.534,673.623z M555.982,651.232
                                    l17.145,14.915l6.624-22.74L555.982,651.232L555.982,651.232z"/>
                            </g>
                            <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M583.674,685.505c0,24.408-19.796,44.207-44.206,44.207
                                c-24.407,0-44.207-19.799-44.207-44.207s19.8-44.205,44.207-44.205C563.878,641.3,583.674,661.097,583.674,685.505z"/>
                            <g>
                                <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M577.091,685.505c0,20.775-16.851,37.623-37.623,37.623
                                    c-20.773,0-37.624-16.848-37.624-37.623c0-20.77,16.851-37.621,37.624-37.621C560.241,647.884,577.091,664.735,577.091,685.505z
                                    "/>
                                <path fill="#D9D9DA" d="M539.468,724.473c-21.487,0-38.968-17.481-38.968-38.968c0-21.485,17.481-38.966,38.968-38.966
                                    c21.487,0,38.967,17.48,38.967,38.966C578.436,706.991,560.955,724.473,539.468,724.473L539.468,724.473z M539.468,649.229
                                    c-20.005,0-36.28,16.273-36.28,36.276c0,20.004,16.275,36.278,36.28,36.278c20.004,0,36.279-16.274,36.279-36.278
                                    C575.747,665.502,559.472,649.229,539.468,649.229L539.468,649.229z"/>
                            </g>
                            <g>
                                <path d="M520.536,677.58c-0.493,0.461-1.233,0.69-2.22,0.69h-1.768v3.739h-0.877v-8.771h2.646c0.978,0,1.716,0.23,2.213,0.689
                                    c0.498,0.461,0.746,1.071,0.746,1.832C521.276,676.514,521.029,677.12,520.536,677.58z M520.373,675.761
                                    c0-0.585-0.163-1.028-0.489-1.33c-0.326-0.301-0.849-0.451-1.568-0.451h-1.768v3.538h1.768
                                    C519.688,677.518,520.373,676.933,520.373,675.761z"/>
                                <path d="M522.73,673.679c-0.125-0.126-0.188-0.28-0.188-0.465c0-0.184,0.063-0.337,0.188-0.458s0.276-0.182,0.451-0.182
                                    c0.176,0,0.326,0.061,0.452,0.182s0.188,0.274,0.188,0.458c0,0.185-0.063,0.339-0.188,0.465
                                    c-0.125,0.125-0.276,0.188-0.452,0.188C523.006,673.866,522.856,673.804,522.73,673.679z M523.621,675.159v6.851h-0.877v-6.851
                                    H523.621z"/>
                                <path d="M526.047,681.564c-0.498-0.364-0.776-0.859-0.834-1.487h0.903c0.042,0.385,0.224,0.696,0.545,0.936
                                    c0.322,0.238,0.754,0.357,1.298,0.357c0.477,0,0.851-0.113,1.122-0.339c0.272-0.226,0.408-0.506,0.408-0.841
                                    c0-0.234-0.075-0.427-0.226-0.577s-0.341-0.27-0.571-0.357s-0.541-0.183-0.934-0.282c-0.51-0.134-0.924-0.268-1.241-0.402
                                    c-0.318-0.133-0.587-0.332-0.809-0.596s-0.332-0.616-0.332-1.061c0-0.334,0.1-0.644,0.301-0.928s0.485-0.511,0.853-0.678
                                    s0.786-0.251,1.254-0.251c0.736,0,1.33,0.187,1.781,0.559c0.452,0.372,0.694,0.884,0.728,1.537h-0.878
                                    c-0.025-0.402-0.182-0.726-0.47-0.973s-0.684-0.37-1.185-0.37c-0.443,0-0.803,0.104-1.079,0.313s-0.414,0.469-0.414,0.778
                                    c0,0.268,0.082,0.487,0.245,0.658c0.163,0.172,0.366,0.306,0.608,0.401c0.243,0.097,0.568,0.203,0.978,0.32
                                    c0.493,0.134,0.886,0.264,1.179,0.389c0.292,0.126,0.543,0.31,0.752,0.553c0.209,0.242,0.318,0.564,0.326,0.966
                                    c0,0.368-0.1,0.696-0.301,0.985c-0.201,0.288-0.481,0.517-0.84,0.684s-0.773,0.251-1.241,0.251
                                    C527.186,682.11,526.544,681.928,526.047,681.564z"/>
                                <path d="M533.151,675.899v4.253c0,0.419,0.079,0.707,0.238,0.866c0.159,0.159,0.439,0.238,0.84,0.238h0.803v0.753h-0.94
                                    c-0.619,0-1.079-0.145-1.379-0.433c-0.301-0.289-0.452-0.764-0.452-1.425v-4.253h-0.953v-0.74h0.953v-1.72h0.891v1.72h1.881
                                    v0.74H533.151z"/>
                                <path d="M536.493,676.721c0.28-0.531,0.669-0.94,1.166-1.229c0.498-0.288,1.06-0.433,1.687-0.433
                                    c0.652,0,1.214,0.15,1.687,0.451c0.472,0.302,0.813,0.687,1.022,1.154v-1.505h0.878v6.851h-0.878v-1.519
                                    c-0.218,0.469-0.563,0.855-1.035,1.161s-1.035,0.458-1.687,0.458c-0.619,0-1.177-0.146-1.674-0.439
                                    c-0.497-0.293-0.886-0.707-1.166-1.242c-0.28-0.535-0.42-1.154-0.42-1.857C536.073,677.869,536.212,677.252,536.493,676.721z
                                    M541.715,677.129c-0.226-0.418-0.533-0.74-0.921-0.966c-0.389-0.227-0.817-0.339-1.286-0.339c-0.485,0-0.919,0.108-1.304,0.326
                                    c-0.385,0.218-0.688,0.533-0.909,0.947c-0.222,0.414-0.333,0.905-0.333,1.474c0,0.561,0.111,1.053,0.333,1.475
                                    c0.221,0.423,0.524,0.745,0.909,0.967c0.384,0.222,0.819,0.332,1.304,0.332c0.468,0,0.897-0.113,1.286-0.339
                                    s0.696-0.548,0.921-0.966c0.226-0.418,0.339-0.903,0.339-1.456C542.054,678.032,541.941,677.547,541.715,677.129z"/>
                                <path d="M548.399,676.721c0.284-0.531,0.675-0.94,1.172-1.229c0.498-0.288,1.06-0.433,1.687-0.433
                                    c0.602,0,1.146,0.146,1.63,0.439s0.84,0.673,1.066,1.142v-3.915h0.878v9.285h-0.878v-1.531
                                    c-0.209,0.478-0.552,0.868-1.028,1.174s-1.037,0.458-1.68,0.458c-0.627,0-1.189-0.146-1.687-0.439
                                    c-0.497-0.293-0.886-0.707-1.166-1.242c-0.28-0.535-0.42-1.154-0.42-1.857C547.973,677.869,548.115,677.252,548.399,676.721z
                                    M553.615,677.129c-0.226-0.418-0.533-0.74-0.921-0.966c-0.389-0.227-0.817-0.339-1.286-0.339c-0.485,0-0.919,0.108-1.304,0.326
                                    c-0.385,0.218-0.688,0.533-0.909,0.947c-0.222,0.414-0.333,0.905-0.333,1.474c0,0.561,0.111,1.053,0.333,1.475
                                    c0.221,0.423,0.524,0.745,0.909,0.967c0.384,0.222,0.819,0.332,1.304,0.332c0.468,0,0.897-0.113,1.286-0.339
                                    s0.696-0.548,0.921-0.966c0.226-0.418,0.339-0.903,0.339-1.456C553.954,678.032,553.841,677.547,553.615,677.129z"/>
                                <path d="M556.857,676.721c0.28-0.531,0.669-0.94,1.166-1.229c0.498-0.288,1.06-0.433,1.687-0.433
                                    c0.652,0,1.214,0.15,1.687,0.451c0.472,0.302,0.813,0.687,1.022,1.154v-1.505h0.878v6.851h-0.878v-1.519
                                    c-0.218,0.469-0.563,0.855-1.035,1.161s-1.035,0.458-1.687,0.458c-0.619,0-1.177-0.146-1.674-0.439
                                    c-0.497-0.293-0.886-0.707-1.166-1.242c-0.28-0.535-0.42-1.154-0.42-1.857C556.437,677.869,556.577,677.252,556.857,676.721z
                                    M562.08,677.129c-0.226-0.418-0.533-0.74-0.921-0.966c-0.389-0.227-0.817-0.339-1.286-0.339c-0.485,0-0.919,0.108-1.304,0.326
                                    c-0.385,0.218-0.688,0.533-0.909,0.947c-0.222,0.414-0.333,0.905-0.333,1.474c0,0.561,0.111,1.053,0.333,1.475
                                    c0.221,0.423,0.524,0.745,0.909,0.967c0.384,0.222,0.819,0.332,1.304,0.332c0.468,0,0.897-0.113,1.286-0.339
                                    s0.696-0.548,0.921-0.966c0.226-0.418,0.339-0.903,0.339-1.456C562.418,678.032,562.306,677.547,562.08,677.129z"/>
                                <path d="M510.807,695.117h2.891v1.893H508.6v-10.066h2.207V695.117z"/>
                                <path d="M514.736,690.829c0.28-0.621,0.66-1.099,1.142-1.434s1.02-0.502,1.613-0.502c0.508,0,0.953,0.114,1.336,0.344
                                    s0.678,0.531,0.884,0.903v-1.133h2.207v8.002h-2.207v-1.133c-0.215,0.373-0.514,0.674-0.897,0.903s-0.828,0.344-1.335,0.344
                                    c-0.585,0-1.119-0.169-1.601-0.509c-0.482-0.339-0.862-0.822-1.142-1.448s-0.419-1.351-0.419-2.173
                                    C514.317,692.173,514.457,691.451,514.736,690.829z M519.253,691.561c-0.306-0.354-0.678-0.53-1.116-0.53
                                    c-0.439,0-0.811,0.174-1.117,0.523c-0.305,0.349-0.458,0.829-0.458,1.44c0,0.612,0.153,1.098,0.458,1.456
                                    c0.306,0.358,0.678,0.538,1.117,0.538c0.438,0,0.811-0.177,1.116-0.531c0.305-0.354,0.458-0.836,0.458-1.448
                                    C519.711,692.397,519.559,691.914,519.253,691.561z"/>
                                <path d="M527.591,689.237c0.383,0.229,0.678,0.531,0.884,0.903v-1.133h2.207v7.987c0,0.736-0.131,1.403-0.394,2.001
                                    c-0.263,0.597-0.663,1.073-1.2,1.427c-0.538,0.354-1.207,0.53-2.007,0.53c-1.067,0-1.932-0.279-2.594-0.839
                                    s-1.041-1.317-1.136-2.272h2.181c0.069,0.306,0.232,0.547,0.49,0.724c0.258,0.177,0.577,0.266,0.955,0.266
                                    c0.456,0,0.82-0.146,1.091-0.438s0.406-0.758,0.406-1.398v-1.133c-0.215,0.373-0.512,0.677-0.89,0.911
                                    c-0.379,0.234-0.822,0.351-1.33,0.351c-0.594,0-1.131-0.169-1.613-0.509c-0.482-0.339-0.862-0.822-1.142-1.448
                                    s-0.419-1.351-0.419-2.173c0-0.821,0.14-1.543,0.419-2.165c0.28-0.621,0.66-1.099,1.142-1.434s1.02-0.502,1.613-0.502
                                    C526.763,688.894,527.208,689.008,527.591,689.237z M528.017,691.561c-0.306-0.354-0.678-0.53-1.116-0.53
                                    c-0.439,0-0.811,0.174-1.117,0.523c-0.305,0.349-0.458,0.829-0.458,1.44c0,0.612,0.153,1.098,0.458,1.456
                                    c0.306,0.358,0.678,0.538,1.117,0.538c0.438,0,0.811-0.177,1.116-0.531c0.305-0.354,0.458-0.836,0.458-1.448
                                    C528.475,692.397,528.322,691.914,528.017,691.561z"/>
                                <path d="M532.263,690.829c0.28-0.621,0.66-1.099,1.142-1.434s1.02-0.502,1.613-0.502c0.508,0,0.953,0.114,1.336,0.344
                                    s0.678,0.531,0.884,0.903v-1.133h2.207v8.002h-2.207v-1.133c-0.215,0.373-0.514,0.674-0.897,0.903s-0.828,0.344-1.335,0.344
                                    c-0.585,0-1.119-0.169-1.601-0.509c-0.482-0.339-0.862-0.822-1.142-1.448s-0.419-1.351-0.419-2.173
                                    C531.843,692.173,531.983,691.451,532.263,690.829z M536.78,691.561c-0.306-0.354-0.678-0.53-1.116-0.53
                                    c-0.439,0-0.811,0.174-1.117,0.523c-0.305,0.349-0.458,0.829-0.458,1.44c0,0.612,0.153,1.098,0.458,1.456
                                    c0.306,0.358,0.678,0.538,1.117,0.538c0.438,0,0.811-0.177,1.116-0.531c0.305-0.354,0.458-0.836,0.458-1.448
                                    C537.238,692.397,537.085,691.914,536.78,691.561z"/>
                                <path d="M544.22,689.302c0.387-0.253,0.817-0.38,1.291-0.38v2.596h-0.606c-0.551,0-0.964,0.132-1.239,0.395
                                    s-0.413,0.724-0.413,1.384v3.714h-2.207v-8.002h2.207v1.334C543.51,689.902,543.833,689.556,544.22,689.302z"/>
                                <path d="M550.583,694.931v2.079h-1.123c-0.8,0-1.424-0.218-1.871-0.652c-0.448-0.435-0.671-1.145-0.671-2.13v-3.184h-0.877
                                    v-2.036h0.877v-1.95h2.207v1.95h1.445v2.036h-1.445v3.213c0,0.238,0.052,0.411,0.155,0.516c0.104,0.105,0.275,0.158,0.517,0.158
                                    H550.583z"/>
                                <path d="M551.97,687.796c-0.245-0.253-0.368-0.566-0.368-0.938c0-0.383,0.123-0.7,0.368-0.954
                                    c0.245-0.253,0.562-0.38,0.949-0.38c0.378,0,0.69,0.127,0.936,0.38c0.245,0.254,0.368,0.571,0.368,0.954
                                    c0,0.372-0.123,0.686-0.368,0.938c-0.245,0.254-0.557,0.381-0.936,0.381C552.531,688.177,552.215,688.05,551.97,687.796z
                                    M554.016,689.008v8.002h-2.207v-8.002H554.016z"/>
                                <path d="M559.875,697.01l-1.381-2.223l-1.162,2.223h-2.388l2.375-4.087l-2.439-3.915h2.478l1.381,2.209l1.162-2.209h2.388
                                    l-2.414,4.029l2.478,3.973H559.875z"/>
                                <path d="M563.185,690.829c0.28-0.621,0.66-1.099,1.142-1.434s1.02-0.502,1.613-0.502c0.508,0,0.953,0.114,1.336,0.344
                                    s0.678,0.531,0.884,0.903v-1.133h2.207v8.002h-2.207v-1.133c-0.215,0.373-0.514,0.674-0.897,0.903s-0.828,0.344-1.335,0.344
                                    c-0.585,0-1.119-0.169-1.601-0.509c-0.482-0.339-0.862-0.822-1.142-1.448s-0.419-1.351-0.419-2.173
                                    C562.765,692.173,562.905,691.451,563.185,690.829z M567.702,691.561c-0.306-0.354-0.678-0.53-1.116-0.53
                                    c-0.439,0-0.811,0.174-1.117,0.523c-0.305,0.349-0.458,0.829-0.458,1.44c0,0.612,0.153,1.098,0.458,1.456
                                    c0.306,0.358,0.678,0.538,1.117,0.538c0.438,0,0.811-0.177,1.116-0.531c0.305-0.354,0.458-0.836,0.458-1.448
                                    C568.16,692.397,568.007,691.914,567.702,691.561z"/>
                            </g>
                        </g>
                        <path fill="#E2E2E2" d="M738.408,696.452c-8.923,0-28.465-0.579-51.869-4.819c-29.615-5.366-71.704-17.859-104.032-46.909
                            l-0.467-0.419c-26.097-23.449-69.775-62.695-88.551-87.729l-1.263-1.681c-15.477-20.597-33.019-43.941-10.328-71.537
                            c43.764-53.227,75.088-85.845,75.399-86.168l3.163,3.047c-0.31,0.322-31.525,32.829-75.17,85.91
                            c-20.498,24.929-5.467,44.932,10.446,66.109l1.266,1.685c18.518,24.688,61.995,63.755,87.972,87.097l0.468,0.42
                            c60.438,54.308,156.996,50.573,157.964,50.525l0.201,4.387C743.541,696.373,741.695,696.452,738.408,696.452L738.408,696.452z"/>
                    </g>
                    <g data-tooltip-content="11" data-tooltip-id="svgTooltipPistas">
                        <path fill="#E2E2E2" d="M975.134,563.502l-4.377-0.354c4.195-52.026-35.676-90.212-101.367-134.96
                            c-74.623-50.828-111.655-96.634-136.16-126.943c-9.848-12.182-17.627-21.803-25.26-28.773l2.961-3.242
                            c7.879,7.193,15.749,16.929,25.714,29.254c24.343,30.11,61.13,75.612,135.218,126.076
                            C938.856,470.194,979.5,509.355,975.134,563.502L975.134,563.502z"/>
                        <g>
                            <g>
                                <polygon fillRule="evenodd" clipRule="evenodd" fill="#E2E2E2" points="892.561,417.03 902.704,450.37 871.256,440.407 				
                                    "/>
                                <path fill="#FFFFFF" d="M902.704,453.059c-0.271,0-0.545-0.041-0.813-0.125l-31.447-9.963c-0.874-0.277-1.546-0.98-1.784-1.866
                                    c-0.238-0.885-0.008-1.831,0.609-2.509l21.305-23.376c0.65-0.715,1.636-1.024,2.579-0.813c0.943,0.213,1.699,0.916,1.98,1.84
                                    l10.143,33.341c0.291,0.955,0.028,1.992-0.682,2.694C904.083,452.787,903.401,453.059,902.704,453.059L902.704,453.059z
                                    M876.074,439.113l22.57,7.15l-7.279-23.929L876.074,439.113L876.074,439.113z"/>
                            </g>
                            <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M903.934,407.378c0,23.262-18.863,42.123-42.123,42.123
                                c-23.257,0-42.124-18.861-42.124-42.123c0-23.258,18.867-42.123,42.124-42.123C885.071,365.255,903.934,384.12,903.934,407.378z"
                                />
                            <g>
                                <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M897.661,407.378c0,19.795-16.056,35.85-35.85,35.85
                                    c-19.795,0-35.851-16.055-35.851-35.85c0-19.793,16.056-35.85,35.851-35.85C881.605,371.528,897.661,387.585,897.661,407.378z"
                                    />
                                <path fill="#E2E2E2" d="M861.811,444.572c-20.509,0-37.194-16.686-37.194-37.194s16.686-37.194,37.194-37.194
                                    s37.194,16.686,37.194,37.194S882.32,444.572,861.811,444.572L861.811,444.572z M861.811,372.873
                                    c-19.026,0-34.506,15.479-34.506,34.505s15.479,34.505,34.506,34.505s34.505-15.479,34.505-34.505
                                    S880.837,372.873,861.811,372.873L861.811,372.873z"/>
                            </g>
                            <g>
                                <path d="M841.808,399.59c-0.498,0.481-1.257,0.722-2.276,0.722h-1.681v3.651h-1.141v-8.745h2.821
                                    c0.986,0,1.736,0.238,2.251,0.715c0.514,0.477,0.771,1.092,0.771,1.845C842.554,398.505,842.305,399.109,841.808,399.59z
                                    M840.936,398.956c0.301-0.275,0.451-0.669,0.451-1.179c0-1.079-0.618-1.619-1.855-1.619h-1.681v3.212h1.681
                                    C840.167,399.37,840.635,399.232,840.936,398.956z"/>
                                <path d="M844.008,395.744c-0.15-0.15-0.226-0.334-0.226-0.552s0.075-0.401,0.226-0.553c0.15-0.15,0.335-0.226,0.552-0.226
                                    c0.209,0,0.387,0.075,0.533,0.226c0.146,0.151,0.22,0.335,0.22,0.553s-0.073,0.401-0.22,0.552s-0.324,0.226-0.533,0.226
                                    C844.343,395.97,844.159,395.895,844.008,395.744z M845.112,397.087v6.876h-1.141v-6.876H845.112z"/>
                                <path d="M848.071,403.806c-0.418-0.18-0.748-0.431-0.991-0.753c-0.242-0.321-0.376-0.691-0.4-1.11h1.179
                                    c0.033,0.344,0.194,0.624,0.482,0.841c0.288,0.218,0.666,0.326,1.135,0.326c0.435,0,0.777-0.096,1.028-0.288
                                    s0.376-0.436,0.376-0.728c0-0.302-0.134-0.525-0.401-0.672s-0.682-0.29-1.241-0.433c-0.51-0.134-0.926-0.27-1.248-0.408
                                    c-0.321-0.138-0.598-0.343-0.827-0.614c-0.23-0.272-0.345-0.63-0.345-1.073c0-0.352,0.104-0.673,0.313-0.966
                                    s0.506-0.525,0.89-0.696c0.385-0.172,0.824-0.258,1.317-0.258c0.761,0,1.375,0.192,1.843,0.577
                                    c0.469,0.385,0.719,0.912,0.753,1.581h-1.142c-0.024-0.359-0.169-0.647-0.433-0.865c-0.263-0.218-0.616-0.326-1.06-0.326
                                    c-0.409,0-0.735,0.088-0.978,0.263c-0.243,0.176-0.364,0.406-0.364,0.69c0,0.226,0.073,0.412,0.22,0.559s0.33,0.264,0.552,0.352
                                    s0.528,0.186,0.922,0.295c0.493,0.134,0.895,0.266,1.203,0.395c0.31,0.13,0.575,0.324,0.797,0.584
                                    c0.222,0.259,0.336,0.598,0.345,1.017c0,0.376-0.104,0.715-0.313,1.016c-0.209,0.302-0.504,0.538-0.884,0.709
                                    c-0.381,0.172-0.817,0.258-1.311,0.258C848.961,404.076,848.489,403.985,848.071,403.806z"/>
                                <path d="M854.993,398.028v4.053c0,0.334,0.071,0.57,0.213,0.709c0.143,0.138,0.389,0.207,0.74,0.207h0.84v0.966h-1.028
                                    c-0.635,0-1.111-0.146-1.429-0.439c-0.318-0.292-0.477-0.773-0.477-1.442v-4.053h-0.891v-0.941h0.891v-1.731h1.141v1.731h1.793
                                    v0.941H854.993z"/>
                                <path d="M858.166,398.649c0.284-0.531,0.675-0.943,1.172-1.236c0.498-0.293,1.052-0.439,1.662-0.439
                                    c0.602,0,1.124,0.13,1.567,0.39c0.442,0.259,0.773,0.585,0.99,0.979v-1.255h1.154v6.876h-1.154v-1.28
                                    c-0.226,0.402-0.563,0.734-1.009,0.998c-0.447,0.264-0.968,0.396-1.562,0.396c-0.61,0-1.162-0.151-1.655-0.452
                                    s-0.882-0.724-1.166-1.268c-0.284-0.543-0.427-1.162-0.427-1.856C857.739,399.797,857.881,399.181,858.166,398.649z
                                    M863.244,399.157c-0.209-0.385-0.491-0.68-0.846-0.885c-0.355-0.205-0.747-0.308-1.173-0.308c-0.427,0-0.815,0.101-1.166,0.302
                                    c-0.352,0.2-0.632,0.493-0.841,0.878s-0.313,0.837-0.313,1.355c0,0.526,0.104,0.984,0.313,1.374
                                    c0.209,0.389,0.489,0.686,0.841,0.891c0.351,0.205,0.739,0.308,1.166,0.308c0.426,0,0.817-0.103,1.173-0.308
                                    c0.354-0.205,0.637-0.502,0.846-0.891c0.209-0.39,0.313-0.843,0.313-1.361S863.453,399.542,863.244,399.157z"/>
                                <path d="M869.991,398.649c0.284-0.531,0.675-0.943,1.172-1.236c0.498-0.293,1.056-0.439,1.675-0.439
                                    c0.534,0,1.032,0.124,1.492,0.37c0.459,0.247,0.811,0.571,1.053,0.973v-3.639h1.154v9.285h-1.154v-1.292
                                    c-0.226,0.409-0.56,0.746-1.003,1.01s-0.962,0.396-1.555,0.396c-0.61,0-1.164-0.151-1.662-0.452
                                    c-0.497-0.301-0.888-0.724-1.172-1.268c-0.284-0.543-0.427-1.162-0.427-1.856C869.564,399.797,869.707,399.181,869.991,398.649z
                                    M875.069,399.157c-0.209-0.385-0.491-0.68-0.847-0.885c-0.354-0.205-0.746-0.308-1.172-0.308c-0.427,0-0.815,0.101-1.166,0.302
                                    c-0.352,0.2-0.632,0.493-0.841,0.878s-0.313,0.837-0.313,1.355c0,0.526,0.104,0.984,0.313,1.374
                                    c0.209,0.389,0.489,0.686,0.841,0.891c0.351,0.205,0.739,0.308,1.166,0.308c0.426,0,0.817-0.103,1.172-0.308
                                    c0.355-0.205,0.638-0.502,0.847-0.891c0.209-0.39,0.313-0.843,0.313-1.361S875.278,399.542,875.069,399.157z"/>
                                <path d="M878.467,398.649c0.284-0.531,0.675-0.943,1.172-1.236c0.498-0.293,1.052-0.439,1.662-0.439
                                    c0.602,0,1.124,0.13,1.567,0.39c0.442,0.259,0.773,0.585,0.99,0.979v-1.255h1.154v6.876h-1.154v-1.28
                                    c-0.226,0.402-0.563,0.734-1.009,0.998c-0.447,0.264-0.968,0.396-1.562,0.396c-0.61,0-1.162-0.151-1.655-0.452
                                    s-0.882-0.724-1.166-1.268c-0.284-0.543-0.427-1.162-0.427-1.856C878.041,399.797,878.183,399.181,878.467,398.649z
                                    M883.545,399.157c-0.209-0.385-0.491-0.68-0.847-0.885c-0.354-0.205-0.746-0.308-1.172-0.308c-0.427,0-0.815,0.101-1.166,0.302
                                    c-0.352,0.2-0.632,0.493-0.841,0.878s-0.313,0.837-0.313,1.355c0,0.526,0.104,0.984,0.313,1.374
                                    c0.209,0.389,0.489,0.686,0.841,0.891c0.351,0.205,0.739,0.308,1.166,0.308c0.426,0,0.817-0.103,1.172-0.308
                                    c0.355-0.205,0.638-0.502,0.847-0.891c0.209-0.39,0.313-0.843,0.313-1.361S883.754,399.542,883.545,399.157z"/>
                                <path d="M841.287,416.982h3.357v1.98h-5.921v-10.53h2.563V416.982z"/>
                                <path d="M847.47,418.558c-0.655-0.35-1.17-0.85-1.545-1.5c-0.374-0.649-0.562-1.41-0.562-2.279c0-0.86,0.189-1.618,0.569-2.272
                                    c0.38-0.655,0.899-1.158,1.56-1.508c0.659-0.35,1.398-0.525,2.219-0.525c0.819,0,1.559,0.176,2.218,0.525
                                    c0.66,0.35,1.18,0.853,1.56,1.508c0.38,0.654,0.569,1.412,0.569,2.272c0,0.859-0.192,1.617-0.577,2.272
                                    c-0.385,0.654-0.909,1.157-1.574,1.507c-0.664,0.351-1.406,0.525-2.226,0.525S848.125,418.908,847.47,418.558z M850.933,416.323
                                    c0.345-0.36,0.517-0.875,0.517-1.545s-0.167-1.186-0.502-1.546c-0.335-0.359-0.747-0.539-1.236-0.539
                                    c-0.5,0-0.915,0.177-1.245,0.532c-0.329,0.354-0.494,0.872-0.494,1.553c0,0.67,0.162,1.185,0.487,1.545
                                    c0.324,0.359,0.731,0.54,1.222,0.54C850.17,416.863,850.588,416.683,850.933,416.323z"/>
                                <path d="M862.865,411.455c0.585,0.636,0.877,1.508,0.877,2.618v4.89h-2.548v-4.545c0-0.56-0.146-0.995-0.435-1.305
                                    c-0.29-0.311-0.68-0.466-1.17-0.466c-0.489,0-0.879,0.155-1.169,0.466c-0.29,0.31-0.435,0.745-0.435,1.305v4.545h-2.563v-8.37
                                    h2.563v1.11c0.26-0.37,0.609-0.662,1.049-0.878c0.439-0.215,0.935-0.322,1.484-0.322
                                    C861.499,410.503,862.281,410.82,862.865,411.455z"/>
                                <path d="M870.189,416.788v2.175h-1.305c-0.929,0-1.654-0.228-2.174-0.683s-0.779-1.197-0.779-2.228v-3.33h-1.02v-2.13h1.02
                                    v-2.04h2.563v2.04h1.679v2.13h-1.679v3.36c0,0.25,0.061,0.43,0.18,0.54c0.12,0.11,0.32,0.165,0.6,0.165H870.189z"/>
                                <path d="M875.3,410.9c0.45-0.265,0.949-0.397,1.499-0.397v2.715h-0.704c-0.64,0-1.12,0.138-1.439,0.413
                                    c-0.32,0.274-0.479,0.757-0.479,1.447v3.885h-2.563v-8.37h2.563v1.396C874.476,411.528,874.85,411.165,875.3,410.9z"/>
                                <path d="M878.006,412.498c0.324-0.65,0.767-1.15,1.326-1.5s1.185-0.525,1.874-0.525c0.59,0,1.106,0.12,1.552,0.36
                                    c0.444,0.24,0.787,0.555,1.026,0.945v-1.186h2.563v8.37h-2.563v-1.185c-0.25,0.39-0.597,0.704-1.041,0.944
                                    c-0.445,0.24-0.963,0.36-1.552,0.36c-0.68,0-1.3-0.178-1.859-0.532c-0.56-0.355-1.002-0.86-1.326-1.516
                                    c-0.325-0.654-0.487-1.412-0.487-2.272C877.519,413.903,877.681,413.148,878.006,412.498z M883.252,413.263
                                    c-0.354-0.37-0.786-0.555-1.296-0.555s-0.942,0.183-1.297,0.548c-0.355,0.364-0.532,0.867-0.532,1.507
                                    c0,0.641,0.177,1.147,0.532,1.522c0.354,0.375,0.787,0.563,1.297,0.563s0.941-0.185,1.296-0.555
                                    c0.355-0.37,0.532-0.875,0.532-1.515C883.785,414.138,883.608,413.633,883.252,413.263z"/>
                            </g>
                        </g>
                    </g>
                    <g data-tooltip-content="17" data-tooltip-id="svgTooltipPistas">
                        <g>
                            <g>
                                <polygon fillRule="evenodd" clipRule="evenodd" fill="#E2E2E2" points="1237.255,318.367 1229.615,351.626 1262.525,339.693 
                                                    "/>
                                <path fill="#FFFFFF" d="M1225.888,355.838l9.733-42.368l32.189,27.167L1225.888,355.838L1225.888,355.838z M1238.889,323.264
                                    l-5.548,24.151l23.897-8.666L1238.889,323.264L1238.889,323.264z"/>
                            </g>
                            <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M1309.089,304.614c0,22.764-18.463,41.225-41.228,41.225
                                s-41.228-18.461-41.228-41.225s18.463-41.227,41.228-41.227S1309.089,281.851,1309.089,304.614z"/>
                            <g>
                                <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M1303.712,304.614c0,19.795-16.056,35.85-35.851,35.85
                                    c-19.794,0-35.85-16.055-35.85-35.85s16.056-35.85,35.85-35.85C1287.657,268.765,1303.712,284.819,1303.712,304.614z"/>
                                <path fill="#E2E2E2" d="M1267.862,341.808c-20.509,0-37.194-16.685-37.194-37.194s16.686-37.194,37.194-37.194
                                    c20.51,0,37.195,16.685,37.195,37.194S1288.372,341.808,1267.862,341.808L1267.862,341.808z M1267.862,270.109
                                    c-19.026,0-34.505,15.479-34.505,34.505s15.479,34.505,34.505,34.505s34.506-15.479,34.506-34.505
                                    S1286.888,270.109,1267.862,270.109L1267.862,270.109z"/>
                            </g>
                            <g>
                                <path d="M1246.882,294.238c-0.498,0.481-1.257,0.722-2.276,0.722h-1.681v3.651h-1.141v-8.745h2.821
                                    c0.986,0,1.736,0.238,2.251,0.715c0.514,0.477,0.771,1.092,0.771,1.845C1247.628,293.153,1247.379,293.758,1246.882,294.238z
                                    M1246.01,293.604c0.301-0.275,0.451-0.669,0.451-1.179c0-1.079-0.618-1.619-1.855-1.619h-1.681v3.212h1.681
                                    C1245.241,294.019,1245.709,293.881,1246.01,293.604z"/>
                                <path d="M1249.583,290.393c-0.15-0.15-0.226-0.334-0.226-0.552s0.075-0.401,0.226-0.553c0.15-0.15,0.335-0.226,0.552-0.226
                                    c0.209,0,0.387,0.075,0.533,0.226c0.146,0.151,0.22,0.335,0.22,0.553s-0.073,0.401-0.22,0.552s-0.324,0.226-0.533,0.226
                                    C1249.918,290.618,1249.734,290.543,1249.583,290.393z M1250.687,291.735v6.876h-1.141v-6.876H1250.687z"/>
                                <path d="M1254.149,298.454c-0.418-0.18-0.748-0.431-0.991-0.753c-0.242-0.321-0.376-0.691-0.4-1.11h1.179
                                    c0.033,0.344,0.194,0.624,0.482,0.841c0.288,0.218,0.666,0.326,1.135,0.326c0.435,0,0.777-0.096,1.028-0.288
                                    s0.376-0.436,0.376-0.728c0-0.302-0.134-0.525-0.401-0.672s-0.682-0.29-1.241-0.433c-0.51-0.134-0.926-0.27-1.248-0.408
                                    c-0.321-0.138-0.598-0.343-0.827-0.614c-0.23-0.272-0.345-0.63-0.345-1.073c0-0.352,0.104-0.673,0.313-0.966
                                    s0.506-0.525,0.89-0.696c0.385-0.172,0.824-0.258,1.317-0.258c0.761,0,1.375,0.192,1.843,0.577
                                    c0.469,0.385,0.719,0.912,0.753,1.581h-1.142c-0.024-0.359-0.169-0.647-0.433-0.865c-0.263-0.218-0.616-0.326-1.06-0.326
                                    c-0.409,0-0.735,0.088-0.978,0.263c-0.243,0.176-0.364,0.406-0.364,0.69c0,0.226,0.073,0.412,0.22,0.559s0.33,0.264,0.552,0.352
                                    s0.528,0.186,0.922,0.295c0.493,0.134,0.895,0.266,1.203,0.395c0.31,0.13,0.575,0.324,0.797,0.584
                                    c0.222,0.259,0.336,0.598,0.345,1.017c0,0.376-0.104,0.715-0.313,1.016c-0.209,0.302-0.504,0.538-0.884,0.709
                                    c-0.381,0.172-0.817,0.258-1.311,0.258C1255.04,298.725,1254.567,298.634,1254.149,298.454z"/>
                                <path d="M1261.572,292.677v4.053c0,0.334,0.071,0.57,0.213,0.709c0.143,0.138,0.389,0.207,0.74,0.207h0.84v0.966h-1.028
                                    c-0.635,0-1.111-0.146-1.429-0.439c-0.318-0.292-0.477-0.773-0.477-1.442v-4.053h-0.891v-0.941h0.891v-1.731h1.141v1.731h1.793
                                    v0.941H1261.572z"/>
                                <path d="M1265.247,293.298c0.284-0.531,0.675-0.943,1.172-1.236c0.498-0.293,1.052-0.439,1.662-0.439
                                    c0.602,0,1.124,0.13,1.567,0.39c0.442,0.259,0.773,0.585,0.99,0.979v-1.255h1.154v6.876h-1.154v-1.28
                                    c-0.226,0.402-0.563,0.734-1.009,0.998c-0.447,0.264-0.968,0.396-1.562,0.396c-0.61,0-1.162-0.151-1.655-0.452
                                    s-0.882-0.724-1.166-1.268c-0.284-0.543-0.427-1.162-0.427-1.856C1264.82,294.445,1264.962,293.829,1265.247,293.298z
                                    M1270.325,293.806c-0.209-0.385-0.491-0.68-0.847-0.885c-0.354-0.205-0.746-0.308-1.172-0.308
                                    c-0.427,0-0.815,0.101-1.166,0.302c-0.352,0.2-0.632,0.493-0.841,0.878s-0.313,0.837-0.313,1.355
                                    c0,0.526,0.104,0.984,0.313,1.374c0.209,0.389,0.489,0.686,0.841,0.891c0.351,0.205,0.739,0.308,1.166,0.308
                                    c0.426,0,0.817-0.103,1.172-0.308c0.355-0.205,0.638-0.502,0.847-0.891c0.209-0.39,0.313-0.843,0.313-1.361
                                    S1270.534,294.19,1270.325,293.806z"/>
                                <path d="M1278.075,293.298c0.284-0.531,0.675-0.943,1.172-1.236c0.498-0.293,1.056-0.439,1.675-0.439
                                    c0.534,0,1.032,0.124,1.492,0.37c0.459,0.247,0.811,0.571,1.053,0.973v-3.639h1.154v9.285h-1.154v-1.292
                                    c-0.226,0.409-0.56,0.746-1.003,1.01s-0.962,0.396-1.555,0.396c-0.61,0-1.164-0.151-1.662-0.452
                                    c-0.497-0.301-0.888-0.724-1.172-1.268c-0.284-0.543-0.427-1.162-0.427-1.856
                                    C1277.648,294.445,1277.791,293.829,1278.075,293.298z M1283.153,293.806c-0.209-0.385-0.491-0.68-0.847-0.885
                                    c-0.354-0.205-0.746-0.308-1.172-0.308c-0.427,0-0.815,0.101-1.166,0.302c-0.352,0.2-0.632,0.493-0.841,0.878
                                    s-0.313,0.837-0.313,1.355c0,0.526,0.104,0.984,0.313,1.374c0.209,0.389,0.489,0.686,0.841,0.891
                                    c0.351,0.205,0.739,0.308,1.166,0.308c0.426,0,0.817-0.103,1.172-0.308c0.355-0.205,0.638-0.502,0.847-0.891
                                    c0.209-0.39,0.313-0.843,0.313-1.361S1283.362,294.19,1283.153,293.806z"/>
                                <path d="M1287.053,293.298c0.284-0.531,0.675-0.943,1.172-1.236c0.498-0.293,1.052-0.439,1.662-0.439
                                    c0.602,0,1.124,0.13,1.567,0.39c0.442,0.259,0.773,0.585,0.99,0.979v-1.255h1.154v6.876h-1.154v-1.28
                                    c-0.226,0.402-0.563,0.734-1.009,0.998c-0.447,0.264-0.968,0.396-1.562,0.396c-0.61,0-1.162-0.151-1.655-0.452
                                    s-0.882-0.724-1.166-1.268c-0.284-0.543-0.427-1.162-0.427-1.856C1286.626,294.445,1286.769,293.829,1287.053,293.298z
                                    M1292.131,293.806c-0.209-0.385-0.491-0.68-0.847-0.885c-0.354-0.205-0.746-0.308-1.172-0.308
                                    c-0.427,0-0.815,0.101-1.166,0.302c-0.352,0.2-0.632,0.493-0.841,0.878s-0.313,0.837-0.313,1.355
                                    c0,0.526,0.104,0.984,0.313,1.374c0.209,0.389,0.489,0.686,0.841,0.891c0.351,0.205,0.739,0.308,1.166,0.308
                                    c0.426,0,0.817-0.103,1.172-0.308c0.355-0.205,0.638-0.502,0.847-0.891c0.209-0.39,0.313-0.843,0.313-1.361
                                    S1292.34,294.19,1292.131,293.806z"/>
                                <path d="M1250.504,306.646l-4.014,11.325h-3.451l-4.014-11.325h2.934l2.806,8.551l2.821-8.551H1250.504z"/>
                                <path d="M1255.438,306.727l-3.886,1.629v-1.903l3.886-1.871V306.727z M1254.938,308.969v9.002h-2.757v-9.002H1254.938z"/>
                                <path d="M1261.339,309.227c0.483-0.258,1.037-0.387,1.66-0.387c0.742,0,1.414,0.188,2.016,0.564
                                    c0.602,0.377,1.077,0.914,1.427,1.613s0.524,1.511,0.524,2.436c0,0.926-0.175,1.74-0.524,2.444
                                    c-0.35,0.705-0.825,1.248-1.427,1.63s-1.273,0.572-2.016,0.572c-0.634,0-1.188-0.126-1.66-0.379
                                    c-0.474-0.253-0.844-0.589-1.112-1.009v1.259h-2.757v-11.938h2.757v4.211C1260.485,309.824,1260.856,309.485,1261.339,309.227z
                                    M1263.588,311.832c-0.382-0.393-0.852-0.589-1.411-0.589c-0.548,0-1.013,0.199-1.395,0.598
                                    c-0.382,0.397-0.572,0.94-0.572,1.629s0.19,1.231,0.572,1.63c0.382,0.397,0.847,0.597,1.395,0.597
                                    c0.549,0,1.016-0.202,1.403-0.605c0.387-0.403,0.58-0.949,0.58-1.638C1264.161,312.766,1263.97,312.225,1263.588,311.832z"/>
                                <path d="M1270.457,317.535c-0.704-0.376-1.258-0.914-1.661-1.613c-0.402-0.699-0.604-1.517-0.604-2.452
                                    c0-0.925,0.204-1.739,0.613-2.444c0.408-0.704,0.967-1.244,1.677-1.621c0.709-0.376,1.504-0.564,2.386-0.564
                                    c0.881,0,1.677,0.188,2.386,0.564c0.71,0.377,1.269,0.917,1.677,1.621c0.409,0.705,0.613,1.52,0.613,2.444
                                    s-0.207,1.74-0.621,2.444s-0.978,1.245-1.692,1.621c-0.715,0.377-1.514,0.564-2.395,0.564
                                    C1271.953,318.1,1271.161,317.912,1270.457,317.535z M1274.181,315.132c0.37-0.388,0.556-0.941,0.556-1.662
                                    s-0.18-1.274-0.54-1.662c-0.36-0.387-0.804-0.58-1.33-0.58c-0.537,0-0.983,0.19-1.338,0.572s-0.532,0.938-0.532,1.67
                                    c0,0.721,0.175,1.274,0.523,1.662c0.35,0.387,0.788,0.58,1.314,0.58S1273.81,315.519,1274.181,315.132z"/>
                                <path d="M1283.297,309.3c0.483-0.285,1.021-0.428,1.612-0.428v2.92h-0.758c-0.688,0-1.204,0.147-1.548,0.443
                                    s-0.516,0.815-0.516,1.557v4.179h-2.757v-9.002h2.757v1.5C1282.411,309.975,1282.814,309.585,1283.297,309.3z"/>
                                <path d="M1286.531,311.018c0.349-0.699,0.824-1.236,1.427-1.613c0.602-0.376,1.273-0.564,2.016-0.564
                                    c0.634,0,1.189,0.129,1.668,0.387c0.479,0.259,0.847,0.598,1.104,1.017v-1.274h2.757v9.002h-2.757v-1.274
                                    c-0.269,0.419-0.643,0.758-1.12,1.017c-0.479,0.258-1.035,0.387-1.669,0.387c-0.73,0-1.397-0.19-1.999-0.572
                                    c-0.603-0.382-1.078-0.925-1.427-1.63c-0.35-0.704-0.524-1.519-0.524-2.444C1286.006,312.528,1286.181,311.717,1286.531,311.018
                                    z M1292.173,311.841c-0.382-0.398-0.847-0.598-1.395-0.598s-1.013,0.196-1.395,0.589s-0.572,0.934-0.572,1.621
                                    c0,0.688,0.19,1.234,0.572,1.638s0.847,0.605,1.395,0.605s1.013-0.199,1.395-0.597c0.382-0.398,0.572-0.941,0.572-1.63
                                    S1292.555,312.238,1292.173,311.841z"/>
                            </g>
                        </g>
                        <path fill="#E2E2E2" d="M1257.326,382.765c-0.637-0.587-65.308-59.835-212.424-162.288
                            c-55.293-38.508-115.146-57.662-155.619-66.949c-49.664-11.396-91.38-12.762-110.691-9.062l-1.013-5.281
                            c18.411-3.529,59.027-3.264,112.906,9.102c40.938,9.394,101.49,28.778,157.489,67.778
                            c147.47,102.698,212.358,162.158,212.997,162.747L1257.326,382.765L1257.326,382.765z"/>
                    </g>
                    <g data-tooltip-content="16" data-tooltip-id="svgTooltipPistas">
                        <g>
                            <g>
                                <polygon fillRule="evenodd" clipRule="evenodd" fill="#E2E2E2" points="1152.181,296.403 1144.541,329.663 1177.451,317.729 
                                                    "/>
                                <path fill="#FFFFFF" d="M1140.814,333.875l9.733-42.368l32.189,27.167L1140.814,333.875L1140.814,333.875z M1153.815,301.3
                                    l-5.548,24.151l23.897-8.666L1153.815,301.3L1153.815,301.3z"/>
                            </g>
                            <g>
                                <g>
                                    <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M1229.324,287.288c0,22.764-18.462,41.227-41.229,41.227
                                        c-22.761,0-41.227-18.463-41.227-41.227s18.466-41.227,41.227-41.227C1210.862,246.062,1229.324,264.524,1229.324,287.288z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M1223.946,287.288c0,19.795-16.056,35.85-35.851,35.85
                                        c-19.794,0-35.85-16.055-35.85-35.85s16.056-35.85,35.85-35.85C1207.89,251.438,1223.946,267.493,1223.946,287.288z"/>
                                    <path fill="#E2E2E2" d="M1188.095,324.482c-20.509,0-37.194-16.685-37.194-37.194s16.686-37.194,37.194-37.194
                                        c20.51,0,37.195,16.685,37.195,37.194S1208.605,324.482,1188.095,324.482L1188.095,324.482z M1188.095,252.783
                                        c-19.026,0-34.505,15.479-34.505,34.505s15.479,34.505,34.505,34.505s34.506-15.479,34.506-34.505
                                        S1207.122,252.783,1188.095,252.783L1188.095,252.783z"/>
                                </g>
                                <g>
                                    <path d="M1168.84,279.481c0-0.753-0.258-1.368-0.771-1.845c-0.515-0.477-1.265-0.715-2.251-0.715h-2.821v8.745h1.141v-3.651
                                        h1.681c1.02,0,1.778-0.24,2.276-0.722C1168.591,280.813,1168.84,280.209,1168.84,279.481z M1167.222,280.66
                                        c-0.301,0.276-0.77,0.414-1.404,0.414h-1.681v-3.212h1.681c1.237,0,1.855,0.54,1.855,1.619
                                        C1167.673,279.991,1167.523,280.385,1167.222,280.66z"/>
                                    <rect x="1170.257" y="278.791" width="1.141" height="6.876"/>
                                    <path d="M1170.846,277.674c0.209,0,0.387-0.075,0.533-0.226s0.22-0.334,0.22-0.552s-0.073-0.401-0.22-0.553
                                        c-0.146-0.15-0.324-0.226-0.533-0.226c-0.217,0-0.401,0.075-0.552,0.226c-0.15,0.151-0.226,0.335-0.226,0.553
                                        s0.075,0.401,0.226,0.552S1170.629,277.674,1170.846,277.674z"/>
                                    <path d="M1177.968,284.813c0.209-0.301,0.313-0.64,0.313-1.016c-0.009-0.419-0.123-0.758-0.345-1.017
                                        c-0.222-0.26-0.487-0.454-0.797-0.584c-0.309-0.129-0.71-0.261-1.203-0.395c-0.394-0.109-0.7-0.207-0.922-0.295
                                        s-0.405-0.205-0.552-0.352s-0.22-0.333-0.22-0.559c0-0.284,0.121-0.515,0.364-0.69c0.242-0.175,0.568-0.263,0.978-0.263
                                        c0.443,0,0.797,0.108,1.06,0.326c0.264,0.218,0.408,0.506,0.433,0.865h1.142c-0.034-0.669-0.284-1.196-0.753-1.581
                                        c-0.468-0.385-1.082-0.577-1.843-0.577c-0.493,0-0.933,0.086-1.317,0.258c-0.384,0.171-0.681,0.403-0.89,0.696
                                        s-0.313,0.614-0.313,0.966c0,0.443,0.114,0.801,0.345,1.073c0.229,0.271,0.506,0.477,0.827,0.614
                                        c0.322,0.139,0.738,0.274,1.248,0.408c0.56,0.143,0.974,0.286,1.241,0.433s0.401,0.37,0.401,0.672
                                        c0,0.292-0.125,0.535-0.376,0.728s-0.594,0.288-1.028,0.288c-0.469,0-0.847-0.108-1.135-0.326
                                        c-0.288-0.217-0.449-0.497-0.482-0.841h-1.179c0.024,0.419,0.158,0.789,0.4,1.11c0.243,0.322,0.573,0.573,0.991,0.753
                                        s0.891,0.271,1.417,0.271c0.493,0,0.93-0.086,1.311-0.258C1177.464,285.352,1177.759,285.115,1177.968,284.813z"/>
                                    <path d="M1181.279,277.06h-1.141v1.731h-0.891v0.941h0.891v4.053c0,0.669,0.158,1.15,0.477,1.442
                                        c0.317,0.293,0.794,0.439,1.429,0.439h1.028v-0.966h-0.84c-0.352,0-0.598-0.069-0.74-0.207
                                        c-0.142-0.139-0.213-0.375-0.213-0.709v-4.053h1.793v-0.941h-1.793V277.06z"/>
                                    <path d="M1185.618,285.328c0.493,0.301,1.045,0.452,1.655,0.452c0.594,0,1.114-0.132,1.562-0.396
                                        c0.446-0.264,0.783-0.596,1.009-0.998v1.28h1.154v-6.876h-1.154v1.255c-0.217-0.394-0.548-0.72-0.99-0.979
                                        c-0.443-0.26-0.966-0.39-1.567-0.39c-0.61,0-1.164,0.146-1.662,0.439c-0.497,0.293-0.888,0.705-1.172,1.236
                                        s-0.427,1.147-0.427,1.851c0,0.694,0.143,1.313,0.427,1.856C1184.736,284.604,1185.125,285.027,1185.618,285.328z
                                        M1185.504,280.849c0.209-0.385,0.489-0.678,0.841-0.878c0.351-0.201,0.739-0.302,1.166-0.302c0.426,0,0.817,0.103,1.172,0.308
                                        c0.355,0.205,0.638,0.5,0.847,0.885s0.313,0.837,0.313,1.355s-0.104,0.972-0.313,1.361c-0.209,0.389-0.491,0.686-0.847,0.891
                                        c-0.354,0.205-0.746,0.308-1.172,0.308c-0.427,0-0.815-0.103-1.166-0.308c-0.352-0.205-0.632-0.502-0.841-0.891
                                        c-0.209-0.39-0.313-0.848-0.313-1.374C1185.191,281.686,1185.295,281.233,1185.504,280.849z"/>
                                    <path d="M1201.668,284.375v1.292h1.154v-9.285h-1.154v3.639c-0.242-0.401-0.594-0.726-1.053-0.973
                                        c-0.46-0.246-0.958-0.37-1.492-0.37c-0.619,0-1.177,0.146-1.675,0.439c-0.497,0.293-0.888,0.705-1.172,1.236
                                        s-0.427,1.147-0.427,1.851c0,0.694,0.143,1.313,0.427,1.856c0.284,0.544,0.675,0.967,1.172,1.268
                                        c0.498,0.301,1.052,0.452,1.662,0.452c0.593,0,1.111-0.132,1.555-0.396S1201.443,284.784,1201.668,284.375z M1200.509,284.469
                                        c-0.355,0.205-0.747,0.308-1.173,0.308c-0.427,0-0.815-0.103-1.166-0.308c-0.352-0.205-0.632-0.502-0.841-0.891
                                        c-0.209-0.39-0.313-0.848-0.313-1.374c0-0.519,0.104-0.971,0.313-1.355s0.489-0.678,0.841-0.878
                                        c0.351-0.201,0.739-0.302,1.166-0.302c0.426,0,0.817,0.103,1.173,0.308c0.354,0.205,0.637,0.5,0.846,0.885
                                        s0.313,0.837,0.313,1.355s-0.104,0.972-0.313,1.361C1201.146,283.967,1200.864,284.264,1200.509,284.469z"/>
                                    <path d="M1210.145,278.791v1.255c-0.217-0.394-0.548-0.72-0.99-0.979c-0.443-0.26-0.966-0.39-1.567-0.39
                                        c-0.61,0-1.164,0.146-1.662,0.439c-0.497,0.293-0.888,0.705-1.172,1.236s-0.427,1.147-0.427,1.851
                                        c0,0.694,0.143,1.313,0.427,1.856c0.284,0.544,0.673,0.967,1.166,1.268s1.045,0.452,1.655,0.452
                                        c0.594,0,1.114-0.132,1.562-0.396c0.446-0.264,0.783-0.596,1.009-0.998v1.28h1.154v-6.876H1210.145z M1209.832,283.578
                                        c-0.209,0.389-0.491,0.686-0.846,0.891c-0.355,0.205-0.747,0.308-1.173,0.308c-0.427,0-0.815-0.103-1.166-0.308
                                        c-0.352-0.205-0.632-0.502-0.841-0.891c-0.209-0.39-0.313-0.848-0.313-1.374c0-0.519,0.104-0.971,0.313-1.355
                                        s0.489-0.678,0.841-0.878c0.351-0.201,0.739-0.302,1.166-0.302c0.426,0,0.817,0.103,1.173,0.308
                                        c0.354,0.205,0.637,0.5,0.846,0.885s0.313,0.837,0.313,1.355S1210.041,283.188,1209.832,283.578z"/>
                                    <polygon points="1166.815,292.552 1169.814,292.552 1169.814,301.667 1172.571,301.667 1172.571,292.552 1175.57,292.552 
                                        1175.57,290.342 1166.815,290.342 					"/>
                                    <path d="M1179.713,294.165v-1.5h-2.757v9.002h2.757v-4.179c0-0.741,0.172-1.261,0.516-1.557s0.859-0.443,1.548-0.443h0.758
                                        v-2.92c-0.592,0-1.129,0.143-1.612,0.428S1180.036,293.671,1179.713,294.165z"/>
                                    <path d="M1189.983,297.553c0,0.603-0.155,1.07-0.468,1.404c-0.312,0.333-0.736,0.5-1.273,0.5c-0.526,0-0.945-0.167-1.258-0.5
                                        c-0.312-0.334-0.467-0.802-0.467-1.404v-4.888h-2.741v5.259c0,0.785,0.146,1.469,0.436,2.049
                                        c0.29,0.581,0.698,1.024,1.226,1.331c0.526,0.307,1.134,0.46,1.821,0.46c0.58,0,1.109-0.121,1.588-0.363
                                        c0.479-0.241,0.857-0.562,1.137-0.959v1.226h2.757v-9.002h-2.757V297.553z"/>
                                    <path d="M1198.109,299.15c-0.129-0.118-0.194-0.312-0.194-0.581v-3.613h1.807v-2.291h-1.807v-2.194h-2.757v2.194h-1.096v2.291
                                        h1.096v3.581c0,1.108,0.279,1.906,0.839,2.396c0.559,0.49,1.338,0.734,2.338,0.734h1.402v-2.339h-0.983
                                        C1198.453,299.328,1198.238,299.269,1198.109,299.15z"/>
                                    <path d="M1207.459,293.939c-0.258-0.419-0.626-0.758-1.104-1.017c-0.479-0.258-1.034-0.387-1.668-0.387
                                        c-0.742,0-1.414,0.188-2.016,0.564c-0.603,0.377-1.078,0.914-1.427,1.613c-0.35,0.699-0.524,1.511-0.524,2.436
                                        c0,0.926,0.175,1.74,0.524,2.444c0.349,0.705,0.824,1.248,1.427,1.63c0.602,0.382,1.269,0.572,1.999,0.572
                                        c0.634,0,1.19-0.129,1.669-0.387c0.478-0.259,0.852-0.598,1.12-1.017v1.274h2.757v-9.002h-2.757V293.939z M1206.887,298.796
                                        c-0.382,0.397-0.847,0.597-1.395,0.597s-1.013-0.202-1.395-0.605s-0.572-0.949-0.572-1.638c0-0.688,0.19-1.229,0.572-1.621
                                        s0.847-0.589,1.395-0.589s1.013,0.199,1.395,0.598c0.382,0.397,0.572,0.94,0.572,1.629S1207.269,298.397,1206.887,298.796z"/>
                                </g>
                            </g>
                        </g>
                        <path fill="#E2E2E2" d="M1190.891,358.426c-91.059-69.772-149.283-123.307-149.861-123.839l3.646-3.953
                            c0.575,0.531,58.637,53.911,149.486,123.524L1190.891,358.426L1190.891,358.426z"/>
                    </g>
                    <g data-tooltip-content="15" data-tooltip-id="svgTooltipPistas">
                        <path fill="#E2E2E2" d="M1069.11,426.067l-4.117-1.527c0.071-0.193,7.159-19.916,2.203-48.077
                            c-4.577-26.007-20.671-64.77-71.345-101.749c-93.584-68.29-147.434-111.664-147.967-112.094l2.758-3.418
                            c0.532,0.43,54.31,43.744,147.797,111.965c51.982,37.933,68.46,77.93,73.129,104.803
                            C1076.652,405.233,1069.42,425.23,1069.11,426.067L1069.11,426.067z"/>
                        <g>
                            <g>
                                <polygon fillRule="evenodd" clipRule="evenodd" fill="#B2B2B4" points="950.641,216.229 969.142,254.28 934.691,249.872 				
                                    "/>
                                <path fill="#FFFFFF" d="M969.142,256.969c-0.113,0-0.227-0.007-0.341-0.022l-34.451-4.408c-0.854-0.109-1.605-0.621-2.019-1.377
                                    s-0.439-1.664-0.07-2.442l15.95-33.643c0.443-0.934,1.383-1.532,2.416-1.537c0.005,0,0.01,0,0.014,0
                                    c1.029,0,1.968,0.587,2.418,1.513l18.501,38.051c0.432,0.888,0.342,1.942-0.234,2.744
                                    C970.817,256.557,970.001,256.969,969.142,256.969L969.142,256.969z M938.708,247.675l25.841,3.307l-13.877-28.541
                                    L938.708,247.675L938.708,247.675z"/>
                            </g>
                            <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M960.15,225.146c0,23.26-18.863,42.125-42.123,42.125
                                c-23.258,0-42.125-18.865-42.125-42.125c0-23.256,18.867-42.123,42.125-42.123C941.287,183.022,960.15,201.89,960.15,225.146z"/>
                            <g>
                                <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M953.875,225.146c0,19.795-16.055,35.85-35.849,35.85
                                    c-19.796,0-35.851-16.055-35.851-35.85s16.055-35.85,35.851-35.85C937.821,189.296,953.875,205.351,953.875,225.146z"/>
                                <path fill="#E2E2E2" d="M918.027,262.339c-20.51,0-37.195-16.685-37.195-37.194s16.686-37.194,37.195-37.194
                                    c20.508,0,37.193,16.685,37.193,37.194S938.535,262.339,918.027,262.339L918.027,262.339z M918.027,190.64
                                    c-19.026,0-34.506,15.479-34.506,34.505s15.479,34.505,34.506,34.505c19.025,0,34.504-15.479,34.504-34.505
                                    S937.052,190.64,918.027,190.64L918.027,190.64z"/>
                            </g>
                            <g>
                                <path d="M900.501,214.718c-0.493,0.461-1.232,0.69-2.219,0.69h-1.769v3.739h-0.878v-8.771h2.646c0.978,0,1.716,0.23,2.213,0.689
                                    c0.497,0.461,0.746,1.071,0.746,1.832C901.242,213.651,900.995,214.258,900.501,214.718z M900.339,212.898
                                    c0-0.585-0.163-1.028-0.489-1.33c-0.326-0.301-0.849-0.451-1.567-0.451h-1.769v3.538h1.769
                                    C899.654,214.655,900.339,214.07,900.339,212.898z"/>
                                <path d="M902.696,210.816c-0.125-0.126-0.188-0.28-0.188-0.465c0-0.184,0.063-0.337,0.188-0.458
                                    c0.126-0.121,0.276-0.182,0.452-0.182c0.175,0,0.326,0.061,0.451,0.182s0.188,0.274,0.188,0.458
                                    c0,0.185-0.063,0.339-0.188,0.465c-0.125,0.125-0.276,0.188-0.451,0.188C902.972,211.004,902.822,210.941,902.696,210.816z
                                    M903.586,212.297v6.851h-0.878v-6.851H903.586z"/>
                                <path d="M906.013,218.702c-0.497-0.364-0.775-0.859-0.834-1.487h0.903c0.042,0.385,0.224,0.696,0.545,0.936
                                    c0.322,0.238,0.755,0.357,1.298,0.357c0.477,0,0.851-0.113,1.123-0.339c0.271-0.226,0.407-0.506,0.407-0.841
                                    c0-0.234-0.075-0.427-0.226-0.577s-0.341-0.27-0.57-0.357c-0.23-0.088-0.542-0.183-0.935-0.282
                                    c-0.51-0.134-0.924-0.268-1.241-0.402c-0.318-0.133-0.588-0.332-0.809-0.596c-0.222-0.264-0.333-0.616-0.333-1.061
                                    c0-0.334,0.101-0.644,0.301-0.928c0.201-0.284,0.485-0.511,0.854-0.678c0.367-0.167,0.785-0.251,1.254-0.251
                                    c0.735,0,1.329,0.187,1.78,0.559s0.694,0.884,0.728,1.537h-0.878c-0.025-0.402-0.182-0.726-0.471-0.973
                                    c-0.288-0.247-0.683-0.37-1.185-0.37c-0.443,0-0.803,0.104-1.078,0.313c-0.276,0.209-0.414,0.469-0.414,0.778
                                    c0,0.268,0.081,0.487,0.244,0.658c0.163,0.172,0.366,0.306,0.608,0.401c0.242,0.097,0.568,0.203,0.979,0.32
                                    c0.493,0.134,0.886,0.264,1.179,0.389c0.292,0.126,0.543,0.31,0.752,0.553c0.209,0.242,0.317,0.564,0.326,0.966
                                    c0,0.368-0.101,0.696-0.301,0.985c-0.201,0.288-0.48,0.517-0.84,0.684c-0.36,0.167-0.773,0.251-1.242,0.251
                                    C907.153,219.248,906.51,219.065,906.013,218.702z"/>
                                <path d="M913.117,213.037v4.253c0,0.419,0.079,0.707,0.238,0.866c0.158,0.159,0.438,0.238,0.84,0.238h0.803v0.753h-0.94
                                    c-0.619,0-1.078-0.145-1.38-0.433c-0.301-0.289-0.451-0.764-0.451-1.425v-4.253h-0.953v-0.74h0.953v-1.72h0.891v1.72h1.881v0.74
                                    H913.117z"/>
                                <path d="M916.458,213.858c0.279-0.531,0.668-0.94,1.166-1.229c0.497-0.288,1.06-0.433,1.687-0.433
                                    c0.652,0,1.214,0.15,1.687,0.451c0.472,0.302,0.813,0.687,1.021,1.154v-1.505h0.878v6.851h-0.878v-1.519
                                    c-0.217,0.469-0.562,0.855-1.034,1.161s-1.034,0.458-1.687,0.458c-0.619,0-1.177-0.146-1.674-0.439
                                    c-0.498-0.293-0.887-0.707-1.166-1.242c-0.28-0.535-0.421-1.154-0.421-1.857C916.038,215.007,916.178,214.39,916.458,213.858z
                                    M921.681,214.267c-0.226-0.418-0.533-0.74-0.922-0.966c-0.389-0.227-0.817-0.339-1.285-0.339c-0.485,0-0.92,0.108-1.305,0.326
                                    s-0.688,0.533-0.909,0.947s-0.332,0.905-0.332,1.474c0,0.561,0.11,1.053,0.332,1.475c0.222,0.423,0.524,0.745,0.909,0.967
                                    s0.819,0.332,1.305,0.332c0.468,0,0.896-0.113,1.285-0.339s0.696-0.548,0.922-0.966s0.338-0.903,0.338-1.456
                                    C922.019,215.17,921.907,214.685,921.681,214.267z"/>
                                <path d="M927.383,213.858c0.284-0.531,0.675-0.94,1.173-1.229c0.497-0.288,1.06-0.433,1.687-0.433
                                    c0.602,0,1.145,0.146,1.63,0.439c0.484,0.293,0.84,0.673,1.065,1.142v-3.915h0.878v9.285h-0.878v-1.531
                                    c-0.209,0.478-0.552,0.868-1.028,1.174c-0.476,0.306-1.036,0.458-1.68,0.458c-0.627,0-1.189-0.146-1.687-0.439
                                    c-0.498-0.293-0.887-0.707-1.166-1.242c-0.28-0.535-0.421-1.154-0.421-1.857C926.957,215.007,927.099,214.39,927.383,213.858z
                                    M932.6,214.267c-0.226-0.418-0.533-0.74-0.922-0.966c-0.389-0.227-0.817-0.339-1.285-0.339c-0.485,0-0.92,0.108-1.305,0.326
                                    s-0.688,0.533-0.909,0.947s-0.332,0.905-0.332,1.474c0,0.561,0.11,1.053,0.332,1.475c0.222,0.423,0.524,0.745,0.909,0.967
                                    s0.819,0.332,1.305,0.332c0.468,0,0.896-0.113,1.285-0.339s0.696-0.548,0.922-0.966s0.338-0.903,0.338-1.456
                                    C932.938,215.17,932.826,214.685,932.6,214.267z"/>
                                <path d="M937.083,218.815c-0.515-0.289-0.92-0.699-1.217-1.23s-0.445-1.152-0.445-1.863s0.15-1.332,0.452-1.863
                                    c0.301-0.531,0.71-0.94,1.229-1.229c0.519-0.288,1.1-0.433,1.743-0.433s1.227,0.145,1.749,0.433
                                    c0.522,0.289,0.933,0.698,1.229,1.229c0.296,0.531,0.444,1.152,0.444,1.863c0,0.703-0.15,1.322-0.451,1.857
                                    s-0.715,0.947-1.241,1.236c-0.526,0.288-1.112,0.433-1.756,0.433S937.596,219.104,937.083,218.815z M940.073,218.175
                                    c0.385-0.205,0.696-0.515,0.935-0.929s0.357-0.922,0.357-1.524c0-0.602-0.117-1.11-0.352-1.524
                                    c-0.233-0.414-0.543-0.724-0.928-0.929c-0.385-0.204-0.803-0.307-1.254-0.307s-0.869,0.103-1.254,0.307
                                    c-0.385,0.205-0.691,0.515-0.922,0.929c-0.229,0.414-0.345,0.923-0.345,1.524c0,0.603,0.115,1.11,0.345,1.524
                                    c0.23,0.414,0.535,0.724,0.916,0.929c0.38,0.205,0.796,0.308,1.247,0.308S939.688,218.38,940.073,218.175z"/>
                                <path d="M900.473,225.081v10.066h-2.451v-6.037l-2.25,6.037h-1.978l-2.265-6.052v6.052h-2.45v-10.066h2.895l2.823,6.969
                                    l2.795-6.969H900.473z"/>
                                <path d="M902.415,225.934c-0.272-0.253-0.408-0.566-0.408-0.938c0-0.383,0.136-0.7,0.408-0.954
                                    c0.272-0.253,0.624-0.38,1.054-0.38c0.42,0,0.767,0.127,1.039,0.38c0.272,0.254,0.408,0.571,0.408,0.954
                                    c0,0.372-0.136,0.686-0.408,0.938c-0.272,0.254-0.619,0.381-1.039,0.381C903.039,226.314,902.687,226.188,902.415,225.934z
                                    M904.686,227.146v8.002h-2.45v-8.002H904.686z"/>
                                <path d="M908.914,224.536v10.611h-2.45v-10.611H908.914z"/>
                                <path d="M917.813,227.971c0.555,0.606,0.832,1.44,0.832,2.502v4.675h-2.437v-4.345c0-0.535-0.139-0.951-0.416-1.248
                                    c-0.277-0.296-0.649-0.444-1.117-0.444c-0.469,0-0.841,0.148-1.118,0.444c-0.277,0.297-0.416,0.713-0.416,1.248v4.345h-2.45
                                    v-10.611h2.45v3.686c0.249-0.354,0.588-0.636,1.018-0.847c0.431-0.21,0.912-0.315,1.447-0.315
                                    C916.524,227.06,917.259,227.363,917.813,227.971z"/>
                                <path d="M920.329,228.967c0.311-0.621,0.733-1.099,1.269-1.434c0.534-0.335,1.132-0.502,1.791-0.502
                                    c0.563,0,1.058,0.114,1.483,0.344c0.425,0.229,0.752,0.531,0.981,0.903v-1.133h2.451v8.002h-2.451v-1.133
                                    c-0.239,0.373-0.571,0.674-0.996,0.903s-0.92,0.344-1.483,0.344c-0.649,0-1.242-0.169-1.776-0.509
                                    c-0.535-0.339-0.958-0.822-1.269-1.448s-0.466-1.351-0.466-2.173C919.863,230.311,920.018,229.589,920.329,228.967z
                                    M925.344,229.698c-0.339-0.354-0.752-0.53-1.239-0.53s-0.9,0.174-1.24,0.523c-0.339,0.349-0.509,0.829-0.509,1.44
                                    c0,0.612,0.17,1.098,0.509,1.456c0.34,0.358,0.753,0.538,1.24,0.538s0.9-0.177,1.239-0.531c0.339-0.354,0.509-0.836,0.509-1.448
                                    C925.853,230.535,925.683,230.052,925.344,229.698z"/>
                                <path d="M934.093,229.182h-1.318v5.966h-2.451v-5.966h-0.889v-2.036h0.889v-0.229c0-0.984,0.282-1.732,0.846-2.244
                                    c0.563-0.511,1.391-0.767,2.479-0.767c0.182,0,0.315,0.005,0.401,0.014v2.08c-0.469-0.029-0.798,0.038-0.989,0.2
                                    c-0.191,0.163-0.286,0.454-0.286,0.875v0.071h1.318V229.182z"/>
                                <path d="M938.765,227.439c0.43-0.253,0.907-0.38,1.433-0.38v2.596h-0.673c-0.611,0-1.07,0.132-1.376,0.395
                                    s-0.459,0.724-0.459,1.384v3.714h-2.45v-8.002h2.45v1.334C937.977,228.04,938.335,227.693,938.765,227.439z"/>
                                <path d="M948.869,231.734h-5.546c0.038,0.497,0.198,0.877,0.479,1.141c0.282,0.263,0.628,0.394,1.039,0.394
                                    c0.611,0,1.037-0.258,1.275-0.774h2.608c-0.134,0.526-0.375,1-0.724,1.42c-0.349,0.421-0.786,0.751-1.312,0.989
                                    c-0.525,0.239-1.112,0.358-1.763,0.358c-0.783,0-1.48-0.167-2.092-0.501c-0.611-0.335-1.09-0.813-1.434-1.435
                                    c-0.344-0.621-0.516-1.348-0.516-2.18s0.169-1.558,0.509-2.18c0.339-0.621,0.814-1.099,1.426-1.434s1.313-0.502,2.106-0.502
                                    c0.774,0,1.462,0.162,2.064,0.487c0.602,0.325,1.072,0.789,1.411,1.391c0.339,0.603,0.509,1.306,0.509,2.108
                                    C948.912,231.247,948.897,231.486,948.869,231.734z M946.404,230.372c0-0.42-0.144-0.755-0.43-1.004
                                    c-0.287-0.248-0.646-0.372-1.075-0.372c-0.411,0-0.758,0.119-1.039,0.358c-0.282,0.238-0.456,0.578-0.523,1.018H946.404z"/>
                            </g>
                        </g>
                    </g>
                    <g data-tooltip-content="3" data-tooltip-id="svgTooltipPistas">
                        <path fill="#A00D0D" d="M1091.069,606.669c-5.115-4.125-10.293-8.111-15.775-12.33c-33.033-25.425-70.474-54.241-89.208-114.611
                            c-13.732-44.245-52.161-73.104-92.847-103.655c-31.567-23.705-64.21-48.218-87.602-81.613
                            c-26.565-37.926-36.854-81.978-31.452-134.67l4.493-0.027c-11.824,115.344,53.705,165.12,117.197,212.799
                            c41.27,30.991,80.251,60.264,94.403,105.866c18.322,59.04,53.588,86.183,87.692,112.432c5.503,4.235,10.7,8.236,15.854,12.393
                            L1091.069,606.669L1091.069,606.669z"/>
                        <g>
                            <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M1019.778,432.134c0,23.258-18.863,42.119-42.123,42.119
                                c-23.259,0-42.124-18.861-42.124-42.119c0-23.262,18.865-42.123,42.124-42.123
                                C1000.915,390.011,1019.778,408.872,1019.778,432.134z"/>
                            <g>
                                <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M1013.504,432.13c0,19.795-16.056,35.85-35.85,35.85
                                    c-19.795,0-35.851-16.055-35.851-35.85s16.056-35.85,35.851-35.85C997.449,396.28,1013.504,412.335,1013.504,432.13z"/>
                                <path fill="#E5C4C8" d="M977.655,469.324c-20.51,0-37.195-16.686-37.195-37.194s16.686-37.194,37.195-37.194
                                    c20.509,0,37.194,16.685,37.194,37.194S998.164,469.324,977.655,469.324L977.655,469.324z M977.655,397.625
                                    c-19.026,0-34.506,15.479-34.506,34.505s15.479,34.505,34.506,34.505s34.505-15.479,34.505-34.505
                                    S996.681,397.625,977.655,397.625L977.655,397.625z"/>
                            </g>
                            <g>
                                <path fill="#A00D0D" d="M957.119,424c-0.498,0.481-1.257,0.722-2.276,0.722h-1.681v3.651h-1.141v-8.745h2.821
                                    c0.986,0,1.736,0.238,2.251,0.715c0.514,0.477,0.771,1.092,0.771,1.845C957.865,422.915,957.616,423.52,957.119,424z
                                    M956.247,423.366c0.301-0.275,0.451-0.669,0.451-1.179c0-1.079-0.618-1.619-1.855-1.619h-1.681v3.212h1.681
                                    C955.477,423.78,955.946,423.643,956.247,423.366z"/>
                                <path fill="#A00D0D" d="M959.82,420.154c-0.15-0.15-0.226-0.334-0.226-0.552s0.075-0.401,0.226-0.553
                                    c0.15-0.15,0.335-0.226,0.552-0.226c0.209,0,0.387,0.075,0.533,0.226c0.146,0.151,0.22,0.335,0.22,0.553
                                    s-0.073,0.401-0.22,0.552s-0.324,0.226-0.533,0.226C960.155,420.38,959.97,420.305,959.82,420.154z M960.923,421.497v6.876
                                    h-1.141v-6.876H960.923z"/>
                                <path fill="#A00D0D" d="M964.385,428.216c-0.418-0.18-0.748-0.431-0.991-0.753c-0.242-0.321-0.376-0.691-0.4-1.11h1.179
                                    c0.033,0.344,0.194,0.624,0.482,0.841c0.288,0.218,0.666,0.326,1.135,0.326c0.435,0,0.777-0.096,1.028-0.288
                                    s0.376-0.436,0.376-0.728c0-0.302-0.134-0.525-0.401-0.672s-0.682-0.29-1.241-0.433c-0.51-0.134-0.926-0.27-1.248-0.408
                                    c-0.321-0.138-0.598-0.343-0.827-0.614c-0.23-0.272-0.345-0.63-0.345-1.073c0-0.352,0.104-0.673,0.313-0.966
                                    s0.506-0.525,0.89-0.696c0.385-0.172,0.824-0.258,1.317-0.258c0.761,0,1.375,0.192,1.843,0.577
                                    c0.469,0.385,0.719,0.912,0.753,1.581h-1.142c-0.024-0.359-0.169-0.647-0.433-0.865c-0.263-0.218-0.616-0.326-1.06-0.326
                                    c-0.409,0-0.735,0.088-0.978,0.263c-0.243,0.176-0.364,0.406-0.364,0.69c0,0.226,0.073,0.412,0.22,0.559s0.33,0.264,0.552,0.352
                                    s0.528,0.186,0.922,0.295c0.493,0.134,0.895,0.266,1.203,0.395c0.31,0.13,0.575,0.324,0.797,0.584
                                    c0.222,0.259,0.336,0.598,0.345,1.017c0,0.376-0.104,0.715-0.313,1.016c-0.209,0.302-0.504,0.538-0.884,0.709
                                    c-0.381,0.172-0.817,0.258-1.311,0.258C965.276,428.486,964.803,428.396,964.385,428.216z"/>
                                <path fill="#A00D0D" d="M971.808,422.438v4.053c0,0.334,0.071,0.57,0.213,0.709c0.143,0.138,0.389,0.207,0.74,0.207h0.84v0.966
                                    h-1.028c-0.635,0-1.111-0.146-1.429-0.439c-0.318-0.292-0.477-0.773-0.477-1.442v-4.053h-0.891v-0.941h0.891v-1.731h1.141v1.731
                                    h1.793v0.941H971.808z"/>
                                <path fill="#A00D0D" d="M975.483,423.06c0.284-0.531,0.675-0.943,1.172-1.236c0.498-0.293,1.052-0.439,1.662-0.439
                                    c0.602,0,1.124,0.13,1.567,0.39c0.442,0.259,0.773,0.585,0.99,0.979v-1.255h1.154v6.876h-1.154v-1.28
                                    c-0.226,0.402-0.563,0.734-1.009,0.998c-0.447,0.264-0.968,0.396-1.562,0.396c-0.61,0-1.162-0.151-1.655-0.452
                                    s-0.882-0.724-1.166-1.268c-0.284-0.543-0.427-1.162-0.427-1.856C975.056,424.207,975.199,423.591,975.483,423.06z
                                    M980.561,423.567c-0.209-0.385-0.491-0.68-0.847-0.885c-0.354-0.205-0.746-0.308-1.172-0.308c-0.427,0-0.815,0.101-1.166,0.302
                                    c-0.352,0.2-0.632,0.493-0.841,0.878s-0.313,0.837-0.313,1.355c0,0.526,0.104,0.984,0.313,1.374
                                    c0.209,0.389,0.489,0.686,0.841,0.891c0.351,0.205,0.739,0.308,1.166,0.308c0.426,0,0.817-0.103,1.172-0.308
                                    c0.355-0.205,0.638-0.502,0.847-0.891c0.209-0.39,0.313-0.843,0.313-1.361S980.77,423.952,980.561,423.567z"/>
                                <path fill="#A00D0D" d="M988.311,423.06c0.284-0.531,0.675-0.943,1.172-1.236c0.498-0.293,1.056-0.439,1.675-0.439
                                    c0.534,0,1.032,0.124,1.492,0.37c0.459,0.247,0.811,0.571,1.053,0.973v-3.639h1.154v9.285h-1.154v-1.292
                                    c-0.226,0.409-0.56,0.746-1.003,1.01s-0.962,0.396-1.555,0.396c-0.61,0-1.164-0.151-1.662-0.452
                                    c-0.497-0.301-0.888-0.724-1.172-1.268c-0.284-0.543-0.427-1.162-0.427-1.856C987.884,424.207,988.027,423.591,988.311,423.06z
                                    M993.389,423.567c-0.209-0.385-0.491-0.68-0.847-0.885c-0.354-0.205-0.746-0.308-1.172-0.308c-0.427,0-0.815,0.101-1.166,0.302
                                    c-0.352,0.2-0.632,0.493-0.841,0.878s-0.313,0.837-0.313,1.355c0,0.526,0.104,0.984,0.313,1.374
                                    c0.209,0.389,0.489,0.686,0.841,0.891c0.351,0.205,0.739,0.308,1.166,0.308c0.426,0,0.817-0.103,1.172-0.308
                                    c0.355-0.205,0.638-0.502,0.847-0.891c0.209-0.39,0.313-0.843,0.313-1.361S993.598,423.952,993.389,423.567z"/>
                                <path fill="#A00D0D" d="M998.537,428.047c-0.522-0.293-0.932-0.709-1.229-1.249c-0.297-0.539-0.445-1.164-0.445-1.875
                                    c0-0.703,0.152-1.324,0.458-1.863c0.305-0.54,0.721-0.954,1.247-1.242c0.527-0.289,1.116-0.434,1.769-0.434
                                    s1.241,0.145,1.769,0.434c0.526,0.288,0.942,0.7,1.247,1.235c0.306,0.535,0.458,1.159,0.458,1.87s-0.157,1.336-0.471,1.875
                                    c-0.313,0.54-0.737,0.956-1.272,1.249s-1.129,0.439-1.78,0.439C999.642,428.486,999.059,428.34,998.537,428.047z
                                    M1001.44,427.193c0.359-0.192,0.65-0.48,0.871-0.865c0.222-0.385,0.333-0.854,0.333-1.405c0-0.553-0.109-1.021-0.326-1.405
                                    c-0.218-0.385-0.502-0.672-0.853-0.86c-0.352-0.188-0.731-0.282-1.142-0.282c-0.418,0-0.801,0.095-1.147,0.282
                                    c-0.347,0.188-0.625,0.476-0.834,0.86s-0.313,0.853-0.313,1.405c0,0.561,0.103,1.033,0.308,1.418s0.479,0.671,0.821,0.859
                                    c0.343,0.188,0.719,0.282,1.129,0.282C1000.696,427.482,1001.081,427.386,1001.44,427.193z"/>
                                <path fill="#A00D0D" d="M952.75,435.898v2.13h3.433v1.979h-3.433v2.311h3.883v2.055h-6.446v-10.53h6.446v2.056H952.75z"/>
                                <path fill="#A00D0D" d="M959.75,444.118c-0.57-0.25-1.02-0.593-1.35-1.027c-0.33-0.436-0.515-0.923-0.555-1.463h2.533
                                    c0.03,0.29,0.165,0.525,0.405,0.705s0.534,0.271,0.885,0.271c0.319,0,0.566-0.063,0.741-0.188s0.263-0.288,0.263-0.488
                                    c0-0.239-0.125-0.417-0.375-0.532s-0.654-0.242-1.214-0.383c-0.6-0.14-1.1-0.287-1.499-0.442
                                    c-0.4-0.154-0.745-0.399-1.034-0.734c-0.29-0.335-0.435-0.788-0.435-1.357c0-0.48,0.132-0.918,0.396-1.313
                                    c0.265-0.396,0.654-0.708,1.17-0.938c0.515-0.23,1.126-0.346,1.836-0.346c1.05,0,1.876,0.261,2.481,0.78
                                    c0.604,0.521,0.951,1.21,1.042,2.07h-2.369c-0.04-0.29-0.167-0.521-0.382-0.69c-0.216-0.17-0.498-0.255-0.848-0.255
                                    c-0.3,0-0.529,0.058-0.689,0.173c-0.16,0.114-0.239,0.272-0.239,0.472c0,0.24,0.127,0.421,0.382,0.54
                                    c0.255,0.12,0.652,0.24,1.191,0.36c0.62,0.16,1.125,0.317,1.515,0.473s0.731,0.405,1.026,0.75s0.447,0.808,0.458,1.388
                                    c0,0.49-0.138,0.928-0.413,1.313c-0.274,0.385-0.669,0.688-1.184,0.907s-1.112,0.33-1.792,0.33
                                    C960.969,444.493,960.32,444.368,959.75,444.118z"/>
                                <path fill="#A00D0D" d="M971.324,442.198v2.175h-1.305c-0.929,0-1.654-0.228-2.174-0.683s-0.779-1.197-0.779-2.228v-3.33h-1.02
                                    v-2.13h1.02v-2.04h2.563v2.04h1.679v2.13h-1.679v3.36c0,0.25,0.061,0.43,0.18,0.54c0.12,0.11,0.32,0.165,0.6,0.165H971.324z"/>
                                <path fill="#A00D0D" d="M972.725,437.908c0.324-0.65,0.767-1.15,1.326-1.5s1.185-0.525,1.874-0.525
                                    c0.59,0,1.106,0.12,1.552,0.36c0.444,0.24,0.787,0.555,1.026,0.945v-1.186h2.563v8.37h-2.563v-1.185
                                    c-0.25,0.39-0.597,0.704-1.041,0.944c-0.445,0.24-0.963,0.36-1.552,0.36c-0.68,0-1.3-0.178-1.859-0.532
                                    c-0.56-0.355-1.002-0.86-1.326-1.516c-0.325-0.654-0.487-1.412-0.487-2.272C972.238,439.313,972.4,438.559,972.725,437.908z
                                    M977.971,438.673c-0.354-0.37-0.786-0.555-1.296-0.555s-0.942,0.183-1.297,0.548c-0.355,0.364-0.532,0.867-0.532,1.507
                                    c0,0.641,0.177,1.147,0.532,1.522c0.354,0.375,0.787,0.563,1.297,0.563s0.941-0.185,1.296-0.555
                                    c0.355-0.37,0.532-0.875,0.532-1.515C978.503,439.548,978.327,439.043,977.971,438.673z M978.699,433.918l-3.613,1.515v-1.77
                                    l3.613-1.74V433.918z"/>
                                <path fill="#A00D0D" d="M982.904,437.908c0.324-0.65,0.767-1.15,1.326-1.5s1.185-0.525,1.874-0.525
                                    c0.55,0,1.052,0.115,1.507,0.346c0.454,0.229,0.812,0.539,1.071,0.93v-3.885h2.563v11.1h-2.563v-1.2
                                    c-0.239,0.4-0.582,0.72-1.026,0.96c-0.445,0.24-0.962,0.36-1.552,0.36c-0.689,0-1.314-0.178-1.874-0.532
                                    c-0.56-0.355-1.002-0.86-1.326-1.516c-0.325-0.654-0.487-1.412-0.487-2.272C982.417,439.313,982.579,438.559,982.904,437.908z
                                    M988.15,438.673c-0.354-0.37-0.786-0.555-1.296-0.555s-0.942,0.183-1.297,0.548c-0.355,0.364-0.532,0.867-0.532,1.507
                                    c0,0.641,0.177,1.147,0.532,1.522c0.354,0.375,0.787,0.563,1.297,0.563s0.941-0.185,1.296-0.555
                                    c0.355-0.37,0.532-0.875,0.532-1.515C988.682,439.548,988.505,439.043,988.15,438.673z"/>
                                <path fill="#A00D0D" d="M993.292,434.735c-0.285-0.265-0.428-0.593-0.428-0.982c0-0.399,0.143-0.732,0.428-0.997
                                    c0.284-0.266,0.651-0.397,1.102-0.397c0.439,0,0.802,0.132,1.087,0.397c0.284,0.265,0.427,0.598,0.427,0.997
                                    c0,0.39-0.143,0.718-0.427,0.982c-0.285,0.266-0.647,0.397-1.087,0.397C993.944,435.133,993.577,435.001,993.292,434.735z
                                    M995.668,436.003v8.37h-2.563v-8.37H995.668z"/>
                                <path fill="#A00D0D" d="M999.124,443.968c-0.655-0.35-1.17-0.85-1.545-1.5c-0.374-0.649-0.562-1.41-0.562-2.279
                                    c0-0.86,0.189-1.618,0.569-2.272c0.38-0.655,0.899-1.158,1.56-1.508c0.659-0.35,1.398-0.525,2.219-0.525
                                    c0.819,0,1.559,0.176,2.218,0.525c0.66,0.35,1.18,0.853,1.56,1.508c0.38,0.654,0.569,1.412,0.569,2.272
                                    c0,0.859-0.192,1.617-0.577,2.272c-0.385,0.654-0.909,1.157-1.574,1.507c-0.664,0.351-1.406,0.525-2.226,0.525
                                    S999.778,444.318,999.124,443.968z M1002.586,441.733c0.345-0.36,0.517-0.875,0.517-1.545s-0.167-1.186-0.502-1.546
                                    c-0.335-0.359-0.747-0.539-1.236-0.539c-0.5,0-0.915,0.177-1.245,0.532c-0.329,0.354-0.494,0.872-0.494,1.553
                                    c0,0.67,0.162,1.185,0.487,1.545c0.324,0.359,0.731,0.54,1.222,0.54C1001.824,442.273,1002.242,442.093,1002.586,441.733z"/>
                            </g>
                        </g>
                    </g>
                    <g data-tooltip-content="21" data-tooltip-id="svgTooltipPistas">
                        <path fill="#E2E2E2" d="M1062.562,578.922c-42.359-60.694-39.871-94.593-37.466-127.376c1.039-14.162,2.021-27.538-0.541-42.91
                            c-6.992-41.95-43.847-66.365-90.506-97.275c-11.889-7.876-24.183-16.02-36.766-24.98
                            c-58.706-41.805-119.204-114.197-119.809-114.924l2.033-3.036c0.601,0.722,62.087,72.912,120.322,114.381
                            c12.523,8.919,24.786,17.042,36.645,24.897c47.549,31.5,85.106,56.381,92.412,100.214c2.648,15.892,1.602,30.157,0.589,43.954
                            c-2.35,32.019-4.779,65.126,36.688,124.541L1062.562,578.922L1062.562,578.922z"/>
                        <g>
                            <g>
                                <polygon fillRule="evenodd" clipRule="evenodd" fill="#B2B2B4" points="996.096,324.948 1001.248,359.468 971.58,344.925 				
                                    "/>
                                <path fill="#FFFFFF" d="M1001.248,362.157c-0.403,0-0.809-0.091-1.184-0.275l-29.668-14.543
                                    c-0.822-0.403-1.384-1.198-1.487-2.108c-0.104-0.91,0.262-1.811,0.973-2.39l24.517-19.977c0.749-0.61,1.769-0.772,2.669-0.423
                                    c0.901,0.349,1.546,1.155,1.688,2.11l5.151,34.52c0.147,0.988-0.266,1.977-1.071,2.567
                                    C1002.366,361.981,1001.808,362.157,1001.248,362.157L1001.248,362.157z M976.532,344.358l21.301,10.441l-3.698-24.784
                                    L976.532,344.358L976.532,344.358z"/>
                            </g>
                            <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M1013.029,310.288c0,23.262-18.864,42.125-42.125,42.125
                                c-23.256,0-42.123-18.863-42.123-42.125c0-23.256,18.867-42.123,42.123-42.123
                                C994.165,268.165,1013.029,287.032,1013.029,310.288z"/>
                            <g>
                                <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M1006.754,310.288c0,19.795-16.056,35.852-35.851,35.852
                                    c-19.794,0-35.849-16.057-35.849-35.852c0-19.793,16.055-35.85,35.849-35.85C990.699,274.438,1006.754,290.495,1006.754,310.288
                                    z"/>
                                <path fill="#D9D9DA" d="M970.904,347.484c-20.508,0-37.193-16.686-37.193-37.196c0-20.509,16.686-37.194,37.193-37.194
                                    c20.51,0,37.195,16.685,37.195,37.194C1008.099,330.798,991.414,347.484,970.904,347.484L970.904,347.484z M970.904,275.783
                                    c-19.025,0-34.504,15.479-34.504,34.505c0,19.027,15.479,34.507,34.504,34.507c19.026,0,34.506-15.48,34.506-34.507
                                    C1005.41,291.262,989.93,275.783,970.904,275.783L970.904,275.783z"/>
                            </g>
                            <g>
                                <path d="M951.802,301.503c-0.498,0.481-1.257,0.722-2.276,0.722h-1.681v3.651h-1.141v-8.745h2.821
                                    c0.986,0,1.736,0.238,2.251,0.715c0.514,0.477,0.771,1.092,0.771,1.845C952.548,300.418,952.299,301.022,951.802,301.503z
                                    M950.93,300.869c0.301-0.275,0.451-0.669,0.451-1.179c0-1.079-0.618-1.619-1.855-1.619h-1.681v3.212h1.681
                                    C950.161,301.283,950.629,301.146,950.93,300.869z"/>
                                <path d="M954.002,297.657c-0.15-0.15-0.226-0.334-0.226-0.552s0.075-0.401,0.226-0.553c0.15-0.15,0.335-0.226,0.552-0.226
                                    c0.209,0,0.387,0.075,0.533,0.226c0.146,0.151,0.22,0.335,0.22,0.553s-0.073,0.401-0.22,0.552s-0.324,0.226-0.533,0.226
                                    C954.337,297.883,954.153,297.808,954.002,297.657z M955.106,299v6.876h-1.141V299H955.106z"/>
                                <path d="M958.065,305.719c-0.418-0.18-0.748-0.431-0.991-0.753c-0.242-0.321-0.376-0.691-0.4-1.11h1.179
                                    c0.033,0.344,0.194,0.624,0.482,0.841c0.288,0.218,0.666,0.326,1.135,0.326c0.435,0,0.777-0.096,1.028-0.288
                                    s0.376-0.436,0.376-0.728c0-0.302-0.134-0.525-0.401-0.672s-0.682-0.29-1.241-0.433c-0.51-0.134-0.926-0.27-1.248-0.408
                                    c-0.321-0.138-0.598-0.343-0.827-0.614c-0.23-0.272-0.345-0.63-0.345-1.073c0-0.352,0.104-0.673,0.313-0.966
                                    s0.506-0.525,0.89-0.696c0.385-0.172,0.824-0.258,1.317-0.258c0.761,0,1.375,0.192,1.843,0.577
                                    c0.469,0.385,0.719,0.912,0.753,1.581h-1.142c-0.024-0.359-0.169-0.647-0.433-0.865c-0.263-0.218-0.616-0.326-1.06-0.326
                                    c-0.409,0-0.735,0.088-0.978,0.263c-0.243,0.176-0.364,0.406-0.364,0.69c0,0.226,0.073,0.412,0.22,0.559s0.33,0.264,0.552,0.352
                                    s0.528,0.186,0.922,0.295c0.493,0.134,0.895,0.266,1.203,0.395c0.31,0.13,0.575,0.324,0.797,0.584
                                    c0.222,0.259,0.336,0.598,0.345,1.017c0,0.376-0.104,0.715-0.313,1.016c-0.209,0.302-0.504,0.538-0.884,0.709
                                    c-0.381,0.172-0.817,0.258-1.311,0.258C958.956,305.989,958.483,305.898,958.065,305.719z"/>
                                <path d="M964.987,299.941v4.053c0,0.334,0.071,0.57,0.213,0.709c0.143,0.138,0.389,0.207,0.74,0.207h0.84v0.966h-1.028
                                    c-0.635,0-1.111-0.146-1.429-0.439c-0.318-0.292-0.477-0.773-0.477-1.442v-4.053h-0.891V299h0.891v-1.731h1.141V299h1.793v0.941
                                    H964.987z"/>
                                <path d="M968.16,300.563c0.284-0.531,0.675-0.943,1.172-1.236c0.498-0.293,1.052-0.439,1.662-0.439
                                    c0.602,0,1.124,0.13,1.567,0.39c0.442,0.259,0.773,0.585,0.99,0.979V299h1.154v6.876h-1.154v-1.28
                                    c-0.226,0.402-0.563,0.734-1.009,0.998c-0.447,0.264-0.968,0.396-1.562,0.396c-0.61,0-1.162-0.151-1.655-0.452
                                    s-0.882-0.724-1.166-1.268c-0.284-0.543-0.427-1.162-0.427-1.856C967.733,301.71,967.875,301.094,968.16,300.563z
                                    M973.238,301.07c-0.209-0.385-0.491-0.68-0.846-0.885c-0.355-0.205-0.747-0.308-1.173-0.308c-0.427,0-0.815,0.101-1.166,0.302
                                    c-0.352,0.2-0.632,0.493-0.841,0.878s-0.313,0.837-0.313,1.355c0,0.526,0.104,0.984,0.313,1.374
                                    c0.209,0.389,0.489,0.686,0.841,0.891c0.351,0.205,0.739,0.308,1.166,0.308c0.426,0,0.817-0.103,1.173-0.308
                                    c0.354-0.205,0.637-0.502,0.846-0.891c0.209-0.39,0.313-0.843,0.313-1.361S973.447,301.455,973.238,301.07z"/>
                                <path d="M979.985,300.563c0.284-0.531,0.675-0.943,1.172-1.236c0.498-0.293,1.056-0.439,1.675-0.439
                                    c0.534,0,1.032,0.124,1.492,0.37c0.459,0.247,0.811,0.571,1.053,0.973v-3.639h1.154v9.285h-1.154v-1.292
                                    c-0.226,0.409-0.56,0.746-1.003,1.01s-0.962,0.396-1.555,0.396c-0.61,0-1.164-0.151-1.662-0.452
                                    c-0.497-0.301-0.888-0.724-1.172-1.268c-0.284-0.543-0.427-1.162-0.427-1.856C979.558,301.71,979.701,301.094,979.985,300.563z
                                    M985.063,301.07c-0.209-0.385-0.491-0.68-0.847-0.885c-0.354-0.205-0.746-0.308-1.172-0.308c-0.427,0-0.815,0.101-1.166,0.302
                                    c-0.352,0.2-0.632,0.493-0.841,0.878s-0.313,0.837-0.313,1.355c0,0.526,0.104,0.984,0.313,1.374
                                    c0.209,0.389,0.489,0.686,0.841,0.891c0.351,0.205,0.739,0.308,1.166,0.308c0.426,0,0.817-0.103,1.172-0.308
                                    c0.355-0.205,0.638-0.502,0.847-0.891c0.209-0.39,0.313-0.843,0.313-1.361S985.272,301.455,985.063,301.07z"/>
                                <path d="M989.708,305.55c-0.522-0.293-0.932-0.709-1.229-1.249c-0.297-0.539-0.445-1.164-0.445-1.875
                                    c0-0.703,0.152-1.324,0.458-1.863c0.305-0.54,0.721-0.954,1.247-1.242c0.527-0.289,1.116-0.434,1.769-0.434
                                    s1.241,0.145,1.769,0.434c0.526,0.288,0.942,0.7,1.247,1.235c0.306,0.535,0.458,1.159,0.458,1.87s-0.157,1.336-0.471,1.875
                                    c-0.313,0.54-0.737,0.956-1.272,1.249s-1.129,0.439-1.78,0.439C990.814,305.989,990.231,305.843,989.708,305.55z
                                    M992.612,304.696c0.359-0.192,0.65-0.48,0.871-0.865c0.222-0.385,0.333-0.854,0.333-1.405c0-0.553-0.109-1.021-0.326-1.405
                                    c-0.218-0.385-0.502-0.672-0.853-0.86c-0.352-0.188-0.731-0.282-1.142-0.282c-0.418,0-0.801,0.095-1.147,0.282
                                    c-0.347,0.188-0.625,0.476-0.834,0.86s-0.313,0.853-0.313,1.405c0,0.561,0.103,1.033,0.308,1.418s0.479,0.671,0.821,0.859
                                    c0.343,0.188,0.719,0.282,1.129,0.282C991.868,304.985,992.252,304.889,992.612,304.696z"/>
                                <path d="M946.979,313.205c0.483-0.877,1.158-1.56,2.023-2.049s1.846-0.734,2.942-0.734c1.343,0,2.493,0.354,3.45,1.064
                                    c0.956,0.71,1.596,1.678,1.918,2.904h-3.03c-0.227-0.474-0.546-0.833-0.96-1.081c-0.414-0.247-0.884-0.371-1.41-0.371
                                    c-0.85,0-1.537,0.296-2.064,0.888c-0.526,0.592-0.79,1.382-0.79,2.371s0.264,1.78,0.79,2.371
                                    c0.527,0.592,1.215,0.888,2.064,0.888c0.526,0,0.996-0.123,1.41-0.371c0.414-0.247,0.733-0.607,0.96-1.081h3.03
                                    c-0.322,1.227-0.962,2.191-1.918,2.896c-0.957,0.704-2.107,1.057-3.45,1.057c-1.097,0-2.077-0.245-2.942-0.734
                                    s-1.54-1.17-2.023-2.041c-0.484-0.871-0.726-1.865-0.726-2.984C946.253,315.079,946.495,314.081,946.979,313.205z"/>
                                <path d="M960.723,321.44c-0.704-0.376-1.258-0.914-1.661-1.613c-0.402-0.699-0.604-1.517-0.604-2.452
                                    c0-0.925,0.204-1.739,0.613-2.444c0.408-0.704,0.967-1.244,1.677-1.621c0.709-0.376,1.504-0.564,2.386-0.564
                                    c0.881,0,1.677,0.188,2.386,0.564c0.71,0.377,1.269,0.917,1.677,1.621c0.409,0.705,0.613,1.52,0.613,2.444
                                    s-0.207,1.74-0.621,2.444s-0.978,1.245-1.692,1.621c-0.715,0.377-1.514,0.564-2.395,0.564
                                    C962.219,322.005,961.427,321.817,960.723,321.44z M964.448,319.037c0.37-0.388,0.556-0.941,0.556-1.662s-0.18-1.274-0.54-1.662
                                    c-0.36-0.387-0.804-0.58-1.33-0.58c-0.537,0-0.983,0.19-1.338,0.572s-0.532,0.938-0.532,1.67c0,0.721,0.175,1.274,0.523,1.662
                                    c0.35,0.387,0.788,0.58,1.314,0.58S964.077,319.424,964.448,319.037z"/>
                                <path d="M973.243,313.205c0.483-0.285,1.021-0.428,1.612-0.428v2.92h-0.758c-0.688,0-1.204,0.147-1.548,0.443
                                    s-0.516,0.815-0.516,1.557v4.179h-2.757v-9.002h2.757v1.5C972.356,313.88,972.759,313.49,973.243,313.205z"/>
                                <path d="M980.223,319.295l1.951-6.421h2.935l-3.192,9.002h-3.402l-3.191-9.002h2.95L980.223,319.295z"/>
                                <path d="M987.987,321.44c-0.704-0.376-1.258-0.914-1.661-1.613c-0.402-0.699-0.604-1.517-0.604-2.452
                                    c0-0.925,0.204-1.739,0.613-2.444c0.408-0.704,0.967-1.244,1.677-1.621c0.709-0.376,1.504-0.564,2.386-0.564
                                    c0.881,0,1.677,0.188,2.386,0.564c0.71,0.377,1.269,0.917,1.677,1.621c0.409,0.705,0.613,1.52,0.613,2.444
                                    s-0.207,1.74-0.621,2.444s-0.978,1.245-1.692,1.621c-0.715,0.377-1.514,0.564-2.395,0.564
                                    C989.483,322.005,988.691,321.817,987.987,321.44z M991.711,319.037c0.37-0.388,0.556-0.941,0.556-1.662s-0.18-1.274-0.54-1.662
                                    c-0.36-0.387-0.804-0.58-1.33-0.58c-0.537,0-0.983,0.19-1.338,0.572s-0.532,0.938-0.532,1.67c0,0.721,0.175,1.274,0.523,1.662
                                    c0.35,0.387,0.788,0.58,1.314,0.58S991.34,319.424,991.711,319.037z"/>
                            </g>
                        </g>
                    </g>
                    <g data-tooltip-content="8" data-tooltip-id="svgTooltipPistas">
                        <polygon fill="#378C6D" points="873.732,698.907 834.522,691.612 835.325,687.296 873.193,694.341 963.089,654.288 964.876,658.3 
                            873.732,698.907 		"/>
                        <g>
                            <g>
                                <polygon fillRule="evenodd" clipRule="evenodd" fill="#378C6D" points="881.218,729.737 915.694,727.864 897.707,695.97 				
                                    "/>
                                <path fill="#FFFFFF" d="M881.218,732.426c-0.898,0-1.741-0.449-2.241-1.203c-0.526-0.794-0.593-1.809-0.175-2.665l16.488-33.768
                                    c0.438-0.897,1.338-1.479,2.336-1.508c1.047-0.036,1.932,0.497,2.422,1.367l17.987,31.895c0.457,0.81,0.463,1.798,0.016,2.613
                                    c-0.446,0.814-1.283,1.342-2.212,1.392l-34.476,1.873C881.315,732.425,881.266,732.426,881.218,732.426L881.218,732.426z
                                    M897.877,701.747l-12.234,25.058l25.582-1.391L897.877,701.747L897.877,701.747z"/>
                            </g>
                            <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M940.101,756.897c0,23.258-18.862,42.119-42.123,42.119
                                c-23.256,0-42.124-18.861-42.124-42.119c0-23.262,18.868-42.123,42.124-42.123C921.239,714.774,940.101,733.636,940.101,756.897z
                                "/>
                            <g>
                                <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M933.828,756.894c0,19.795-16.055,35.85-35.85,35.85
                                    c-19.794,0-35.851-16.055-35.851-35.85c0-19.793,16.057-35.85,35.851-35.85C917.773,721.044,933.828,737.101,933.828,756.894z"
                                    />
                                <path fill="#ACCCC0" d="M897.978,794.088c-20.51,0-37.195-16.686-37.195-37.194s16.686-37.194,37.195-37.194
                                    c20.509,0,37.194,16.686,37.194,37.194S918.487,794.088,897.978,794.088L897.978,794.088z M897.978,722.389
                                    c-19.026,0-34.506,15.479-34.506,34.505s15.479,34.505,34.506,34.505s34.505-15.479,34.505-34.505
                                    S917.004,722.389,897.978,722.389L897.978,722.389z"/>
                            </g>
                            <g>
                                <path fill="#378C6D" d="M879.723,747.913c-0.498,0.481-1.257,0.722-2.276,0.722h-1.681v3.651h-1.141v-8.745h2.821
                                    c0.986,0,1.736,0.238,2.251,0.715c0.514,0.477,0.771,1.092,0.771,1.845C880.469,746.828,880.22,747.433,879.723,747.913z
                                    M878.851,747.279c0.301-0.275,0.451-0.669,0.451-1.179c0-1.079-0.618-1.619-1.855-1.619h-1.681v3.212h1.681
                                    C878.082,747.693,878.55,747.556,878.851,747.279z"/>
                                <path fill="#378C6D" d="M881.923,744.067c-0.15-0.15-0.226-0.334-0.226-0.552s0.075-0.401,0.226-0.553
                                    c0.15-0.15,0.335-0.226,0.552-0.226c0.209,0,0.387,0.075,0.533,0.226c0.146,0.151,0.22,0.335,0.22,0.553
                                    s-0.073,0.401-0.22,0.552s-0.324,0.226-0.533,0.226C882.258,744.293,882.074,744.218,881.923,744.067z M883.027,745.41v6.876
                                    h-1.141v-6.876H883.027z"/>
                                <path fill="#378C6D" d="M885.986,752.129c-0.418-0.18-0.748-0.431-0.991-0.753c-0.242-0.321-0.376-0.691-0.4-1.11h1.179
                                    c0.033,0.344,0.194,0.624,0.482,0.841c0.288,0.218,0.666,0.326,1.135,0.326c0.435,0,0.777-0.096,1.028-0.288
                                    s0.376-0.436,0.376-0.728c0-0.302-0.134-0.525-0.401-0.672s-0.682-0.29-1.241-0.433c-0.51-0.134-0.926-0.27-1.248-0.408
                                    c-0.321-0.138-0.598-0.343-0.827-0.614c-0.23-0.272-0.345-0.63-0.345-1.073c0-0.352,0.104-0.673,0.313-0.966
                                    s0.506-0.525,0.89-0.696c0.385-0.172,0.824-0.258,1.317-0.258c0.761,0,1.375,0.192,1.843,0.577
                                    c0.469,0.385,0.719,0.912,0.753,1.581h-1.142c-0.024-0.359-0.169-0.647-0.433-0.865c-0.263-0.218-0.616-0.326-1.06-0.326
                                    c-0.409,0-0.735,0.088-0.978,0.263c-0.243,0.176-0.364,0.406-0.364,0.69c0,0.226,0.073,0.412,0.22,0.559s0.33,0.264,0.552,0.352
                                    s0.528,0.186,0.922,0.295c0.493,0.134,0.895,0.266,1.203,0.395c0.31,0.13,0.575,0.324,0.797,0.584
                                    c0.222,0.259,0.336,0.598,0.345,1.017c0,0.376-0.104,0.715-0.313,1.016c-0.209,0.302-0.504,0.538-0.884,0.709
                                    c-0.381,0.172-0.817,0.258-1.311,0.258C886.876,752.399,886.404,752.309,885.986,752.129z"/>
                                <path fill="#378C6D" d="M892.908,746.352v4.053c0,0.334,0.071,0.57,0.213,0.709c0.143,0.138,0.389,0.207,0.74,0.207h0.84v0.966
                                    h-1.028c-0.635,0-1.111-0.146-1.429-0.439c-0.318-0.292-0.477-0.773-0.477-1.442v-4.053h-0.891v-0.941h0.891v-1.731h1.141v1.731
                                    h1.793v0.941H892.908z"/>
                                <path fill="#378C6D" d="M896.081,746.973c0.284-0.531,0.675-0.943,1.172-1.236c0.498-0.293,1.052-0.439,1.662-0.439
                                    c0.602,0,1.124,0.13,1.567,0.39c0.442,0.259,0.773,0.585,0.99,0.979v-1.255h1.154v6.876h-1.154v-1.28
                                    c-0.226,0.402-0.563,0.734-1.009,0.998c-0.447,0.264-0.968,0.396-1.562,0.396c-0.61,0-1.162-0.151-1.655-0.452
                                    s-0.882-0.724-1.166-1.268c-0.284-0.543-0.427-1.162-0.427-1.856C895.654,748.12,895.796,747.504,896.081,746.973z
                                    M901.159,747.48c-0.209-0.385-0.491-0.68-0.847-0.885c-0.354-0.205-0.746-0.308-1.172-0.308c-0.427,0-0.815,0.101-1.166,0.302
                                    c-0.352,0.2-0.632,0.493-0.841,0.878s-0.313,0.837-0.313,1.355c0,0.526,0.104,0.984,0.313,1.374
                                    c0.209,0.389,0.489,0.686,0.841,0.891c0.351,0.205,0.739,0.308,1.166,0.308c0.426,0,0.817-0.103,1.172-0.308
                                    c0.355-0.205,0.638-0.502,0.847-0.891c0.209-0.39,0.313-0.843,0.313-1.361S901.368,747.865,901.159,747.48z"/>
                                <path fill="#378C6D" d="M907.906,746.973c0.284-0.531,0.675-0.943,1.172-1.236c0.498-0.293,1.056-0.439,1.675-0.439
                                    c0.534,0,1.032,0.124,1.492,0.37c0.459,0.247,0.811,0.571,1.053,0.973v-3.639h1.154v9.285h-1.154v-1.292
                                    c-0.226,0.409-0.56,0.746-1.003,1.01s-0.962,0.396-1.555,0.396c-0.61,0-1.164-0.151-1.662-0.452
                                    c-0.497-0.301-0.888-0.724-1.172-1.268c-0.284-0.543-0.427-1.162-0.427-1.856C907.479,748.12,907.622,747.504,907.906,746.973z
                                    M912.984,747.48c-0.209-0.385-0.491-0.68-0.846-0.885c-0.355-0.205-0.747-0.308-1.173-0.308c-0.427,0-0.815,0.101-1.166,0.302
                                    c-0.352,0.2-0.632,0.493-0.841,0.878s-0.313,0.837-0.313,1.355c0,0.526,0.104,0.984,0.313,1.374
                                    c0.209,0.389,0.489,0.686,0.841,0.891c0.351,0.205,0.739,0.308,1.166,0.308c0.426,0,0.817-0.103,1.173-0.308
                                    c0.354-0.205,0.637-0.502,0.846-0.891c0.209-0.39,0.313-0.843,0.313-1.361S913.193,747.865,912.984,747.48z"/>
                                <path fill="#378C6D" d="M917.629,751.96c-0.522-0.293-0.932-0.709-1.229-1.249c-0.297-0.539-0.445-1.164-0.445-1.875
                                    c0-0.703,0.152-1.324,0.458-1.863c0.305-0.54,0.721-0.954,1.247-1.242c0.527-0.289,1.116-0.434,1.769-0.434
                                    s1.241,0.145,1.769,0.434c0.526,0.288,0.942,0.7,1.247,1.235c0.306,0.535,0.458,1.159,0.458,1.87s-0.157,1.336-0.471,1.875
                                    c-0.313,0.54-0.737,0.956-1.272,1.249s-1.129,0.439-1.78,0.439C918.735,752.399,918.152,752.253,917.629,751.96z
                                    M920.533,751.106c0.359-0.192,0.65-0.48,0.871-0.865c0.222-0.385,0.333-0.854,0.333-1.405c0-0.553-0.109-1.021-0.326-1.405
                                    c-0.218-0.385-0.502-0.672-0.853-0.86c-0.352-0.188-0.731-0.282-1.142-0.282c-0.418,0-0.801,0.095-1.147,0.282
                                    c-0.347,0.188-0.625,0.476-0.834,0.86s-0.313,0.853-0.313,1.405c0,0.561,0.103,1.033,0.308,1.418s0.479,0.671,0.821,0.859
                                    c0.343,0.188,0.719,0.282,1.129,0.282C919.789,751.396,920.173,751.299,920.533,751.106z"/>
                                <path fill="#378C6D" d="M870.858,761.786c0.404-0.778,0.968-1.386,1.691-1.821c0.724-0.435,1.543-0.652,2.46-0.652
                                    c1.123,0,2.085,0.315,2.885,0.946c0.8,0.632,1.334,1.492,1.604,2.582h-2.534c-0.188-0.421-0.456-0.741-0.802-0.961
                                    s-0.739-0.33-1.18-0.33c-0.71,0-1.285,0.263-1.725,0.789c-0.44,0.525-0.661,1.229-0.661,2.107c0,0.88,0.221,1.582,0.661,2.108
                                    c0.439,0.525,1.015,0.788,1.725,0.788c0.44,0,0.834-0.109,1.18-0.329s0.613-0.54,0.802-0.961h2.534
                                    c-0.27,1.09-0.804,1.948-1.604,2.574s-1.762,0.938-2.885,0.938c-0.917,0-1.736-0.217-2.46-0.652
                                    c-0.724-0.435-1.287-1.039-1.691-1.813s-0.606-1.659-0.606-2.653S870.454,762.565,870.858,761.786z"/>
                                <path fill="#378C6D" d="M880.893,763.313c0.292-0.621,0.689-1.099,1.193-1.434c0.503-0.335,1.064-0.502,1.685-0.502
                                    c0.53,0,0.995,0.114,1.396,0.344c0.399,0.229,0.707,0.531,0.923,0.903v-1.133h2.306v8.002h-2.306v-1.133
                                    c-0.225,0.373-0.537,0.674-0.937,0.903c-0.4,0.229-0.865,0.344-1.396,0.344c-0.611,0-1.168-0.169-1.671-0.509
                                    c-0.504-0.339-0.901-0.822-1.193-1.448s-0.438-1.351-0.438-2.173C880.455,764.657,880.601,763.936,880.893,763.313z
                                    M884.701,759.771l-2.089,1.176v-1.691l2.089-1.134l2.09,1.134v1.691L884.701,759.771z M885.611,764.045
                                    c-0.319-0.354-0.707-0.53-1.166-0.53c-0.458,0-0.847,0.174-1.166,0.523c-0.319,0.349-0.479,0.829-0.479,1.44
                                    c0,0.612,0.159,1.098,0.479,1.456s0.708,0.538,1.166,0.538c0.459,0,0.847-0.177,1.166-0.531
                                    c0.319-0.354,0.479-0.836,0.479-1.448C886.089,764.882,885.93,764.398,885.611,764.045z"/>
                                <path fill="#378C6D" d="M896.758,762.317c0.525,0.606,0.789,1.44,0.789,2.502v4.675h-2.292v-4.345
                                    c0-0.535-0.131-0.951-0.391-1.248c-0.261-0.296-0.611-0.444-1.052-0.444s-0.791,0.148-1.052,0.444
                                    c-0.261,0.297-0.391,0.713-0.391,1.248v4.345h-2.305v-8.002h2.305v1.062c0.233-0.354,0.548-0.634,0.943-0.839
                                    c0.396-0.206,0.841-0.309,1.335-0.309C895.53,761.406,896.233,761.71,896.758,762.317z"/>
                                <path fill="#378C6D" d="M903.343,767.415v2.079h-1.173c-0.836,0-1.487-0.218-1.955-0.652c-0.467-0.435-0.701-1.145-0.701-2.13
                                    v-3.184h-0.916v-2.036h0.916v-1.95h2.306v1.95h1.51v2.036h-1.51v3.213c0,0.238,0.054,0.411,0.161,0.516
                                    c0.108,0.105,0.288,0.158,0.54,0.158H903.343z"/>
                                <path fill="#378C6D" d="M904.603,763.313c0.292-0.621,0.689-1.099,1.193-1.434c0.503-0.335,1.064-0.502,1.685-0.502
                                    c0.53,0,0.995,0.114,1.396,0.344c0.399,0.229,0.707,0.531,0.923,0.903v-1.133h2.306v8.002h-2.306v-1.133
                                    c-0.225,0.373-0.537,0.674-0.937,0.903c-0.4,0.229-0.865,0.344-1.396,0.344c-0.611,0-1.168-0.169-1.671-0.509
                                    c-0.504-0.339-0.901-0.822-1.193-1.448s-0.438-1.351-0.438-2.173C904.165,764.657,904.311,763.936,904.603,763.313z
                                    M909.321,764.045c-0.319-0.354-0.707-0.53-1.166-0.53c-0.458,0-0.847,0.174-1.166,0.523c-0.319,0.349-0.479,0.829-0.479,1.44
                                    c0,0.612,0.159,1.098,0.479,1.456s0.708,0.538,1.166,0.538c0.459,0,0.847-0.177,1.166-0.531
                                    c0.319-0.354,0.479-0.836,0.479-1.448C909.799,764.882,909.64,764.398,909.321,764.045z"/>
                                <path fill="#378C6D" d="M917.091,761.786c0.404-0.253,0.854-0.38,1.349-0.38v2.596h-0.634c-0.575,0-1.007,0.132-1.294,0.395
                                    c-0.288,0.263-0.432,0.724-0.432,1.384v3.714h-2.305v-8.002h2.305v1.334C916.35,762.387,916.687,762.04,917.091,761.786z"/>
                                <path fill="#378C6D" d="M920.981,769.107c-0.589-0.335-1.052-0.813-1.389-1.435c-0.337-0.621-0.506-1.348-0.506-2.18
                                    c0-0.822,0.171-1.546,0.513-2.173c0.342-0.626,0.809-1.105,1.402-1.44c0.593-0.335,1.258-0.502,1.994-0.502
                                    c0.737,0,1.402,0.167,1.995,0.502c0.594,0.335,1.061,0.814,1.402,1.44c0.341,0.627,0.512,1.351,0.512,2.173
                                    s-0.173,1.547-0.519,2.173s-0.818,1.106-1.415,1.441c-0.599,0.334-1.266,0.501-2.002,0.501
                                    C922.232,769.608,921.569,769.441,920.981,769.107z M924.094,766.971c0.311-0.345,0.466-0.837,0.466-1.478
                                    s-0.151-1.133-0.452-1.477c-0.301-0.345-0.672-0.517-1.112-0.517c-0.449,0-0.822,0.17-1.118,0.509
                                    c-0.297,0.34-0.445,0.835-0.445,1.484c0,0.641,0.146,1.133,0.438,1.478c0.292,0.344,0.658,0.516,1.099,0.516
                                    S923.785,767.314,924.094,766.971z"/>
                            </g>
                        </g>
                    </g>
                    <g data-tooltip-content="4" data-tooltip-id="svgTooltipPistas">
                        <path fill="#0888C1" d="M770.943,691.315c-26.95,0.001-51.211-5.876-64.628-9.889C599.529,649.49,523.313,571.021,500.356,529.063
                            c-25.867-47.272,51.693-129.367,93.361-173.471c1.745-1.847,3.425-3.625,5.028-5.327
                            c38.746-41.148,175.604-194.134,176.972-195.674l3.106,3.623c-1.369,1.541-137.364,154.533-176.163,195.736
                            c-1.605,1.705-3.287,3.485-5.034,5.335c-19.827,20.985-49.79,52.7-70.904,83.989c-24.616,36.478-31.899,64.473-21.648,83.206
                            c22.547,41.21,97.538,118.317,202.782,149.793c24.661,7.377,86.976,21.27,132.874-8.966c6.89-4.538,16.062-11.446,26.681-19.445
                            c17.556-13.225,37.454-28.215,54.591-38.168c0.8-0.464,1.605-0.938,2.422-1.417c18.372-10.785,43.533-25.559,124.773-14.349
                            c30.513,4.207,75.503,15.553,123.136,27.564c84.838,21.396,180.995,45.645,221.224,36.011l1.252,5.23
                            c-41.517,9.938-138.354-14.481-223.79-36.026c-47.485-11.976-92.337-23.286-122.556-27.453
                            c-79.39-10.949-102.639,2.695-121.316,13.66c-0.824,0.484-1.638,0.961-2.443,1.43c-16.858,9.792-36.621,24.679-54.057,37.813
                            c-10.692,8.054-19.926,15.01-26.959,19.641C821.159,686.641,794.981,691.315,770.943,691.315L770.943,691.315z"/>
                        <g>
                            <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M680.625,322.343c0,23.258-18.864,42.119-42.124,42.119
                                c-23.259,0-42.124-18.861-42.124-42.119c0-23.26,18.865-42.123,42.124-42.123C661.761,280.22,680.625,299.083,680.625,322.343z"
                                />
                            <path fill="#9EBBD8" d="M638.645,359.535c-20.509,0-37.194-16.686-37.194-37.196c0-20.509,16.685-37.194,37.194-37.194
                                s37.194,16.685,37.194,37.194C675.839,342.849,659.154,359.535,638.645,359.535L638.645,359.535z M638.645,287.833
                                c-19.026,0-34.505,15.479-34.505,34.505c0,19.027,15.479,34.507,34.505,34.507s34.505-15.48,34.505-34.507
                                C673.15,303.313,657.671,287.833,638.645,287.833L638.645,287.833z"/>
                            <g>
                                <path fill="#0888C1" d="M617.365,313.959c-0.498,0.481-1.256,0.722-2.276,0.722h-1.681v3.651h-1.141v-8.745h2.822
                                    c0.986,0,1.737,0.238,2.251,0.715s0.771,1.092,0.771,1.845C618.111,312.874,617.862,313.479,617.365,313.959z M616.493,313.325
                                    c0.301-0.275,0.452-0.669,0.452-1.179c0-1.079-0.619-1.619-1.856-1.619h-1.681v3.212h1.681
                                    C615.724,313.739,616.192,313.602,616.493,313.325z"/>
                                <path fill="#0888C1" d="M620.067,310.113c-0.151-0.15-0.226-0.334-0.226-0.552s0.075-0.401,0.226-0.553
                                    c0.15-0.15,0.334-0.226,0.552-0.226c0.208,0,0.386,0.075,0.533,0.226c0.146,0.151,0.219,0.335,0.219,0.553
                                    s-0.073,0.401-0.219,0.552c-0.146,0.15-0.324,0.226-0.533,0.226C620.401,310.339,620.218,310.264,620.067,310.113z
                                    M621.17,311.456v6.876h-1.141v-6.876H621.17z"/>
                                <path fill="#0888C1" d="M624.631,318.175c-0.418-0.18-0.749-0.431-0.991-0.753c-0.243-0.321-0.376-0.691-0.401-1.11h1.179
                                    c0.033,0.344,0.194,0.624,0.483,0.841c0.289,0.218,0.667,0.326,1.135,0.326c0.435,0,0.777-0.096,1.028-0.288
                                    s0.376-0.436,0.376-0.728c0-0.302-0.134-0.525-0.401-0.672c-0.268-0.146-0.682-0.29-1.242-0.433
                                    c-0.51-0.134-0.926-0.27-1.248-0.408c-0.322-0.138-0.598-0.343-0.828-0.614c-0.23-0.272-0.345-0.63-0.345-1.073
                                    c0-0.352,0.104-0.673,0.313-0.966c0.208-0.293,0.505-0.525,0.89-0.696c0.384-0.172,0.823-0.258,1.317-0.258
                                    c0.76,0,1.375,0.192,1.843,0.577c0.468,0.385,0.719,0.912,0.752,1.581h-1.141c-0.025-0.359-0.169-0.647-0.433-0.865
                                    c-0.264-0.218-0.617-0.326-1.06-0.326c-0.41,0-0.735,0.088-0.978,0.263c-0.242,0.176-0.363,0.406-0.363,0.69
                                    c0,0.226,0.073,0.412,0.219,0.559c0.146,0.146,0.33,0.264,0.552,0.352c0.221,0.088,0.529,0.186,0.922,0.295
                                    c0.493,0.134,0.894,0.266,1.204,0.395c0.309,0.13,0.575,0.324,0.796,0.584c0.221,0.259,0.336,0.598,0.345,1.017
                                    c0,0.376-0.104,0.715-0.313,1.016c-0.209,0.302-0.504,0.538-0.884,0.709c-0.38,0.172-0.817,0.258-1.31,0.258
                                    C625.521,318.445,625.049,318.354,624.631,318.175z"/>
                                <path fill="#0888C1" d="M632.055,312.397v4.053c0,0.334,0.071,0.57,0.213,0.709c0.142,0.138,0.389,0.207,0.74,0.207h0.84v0.966
                                    h-1.028c-0.636,0-1.112-0.146-1.43-0.439c-0.318-0.292-0.477-0.773-0.477-1.442v-4.053h-0.89v-0.941h0.89v-1.731h1.141v1.731
                                    h1.793v0.941H632.055z"/>
                                <path fill="#0888C1" d="M635.729,313.019c0.284-0.531,0.675-0.943,1.173-1.236c0.497-0.293,1.051-0.439,1.662-0.439
                                    c0.602,0,1.124,0.13,1.567,0.39c0.443,0.259,0.773,0.585,0.991,0.979v-1.255h1.153v6.876h-1.153v-1.28
                                    c-0.226,0.402-0.563,0.734-1.01,0.998s-0.968,0.396-1.561,0.396c-0.61,0-1.162-0.151-1.655-0.452
                                    c-0.494-0.301-0.882-0.724-1.167-1.268c-0.284-0.543-0.426-1.162-0.426-1.856C635.302,314.166,635.444,313.55,635.729,313.019z
                                    M640.808,313.526c-0.209-0.385-0.491-0.68-0.847-0.885s-0.746-0.308-1.172-0.308s-0.815,0.101-1.167,0.302
                                    c-0.351,0.2-0.631,0.493-0.84,0.878c-0.209,0.385-0.313,0.837-0.313,1.355c0,0.526,0.104,0.984,0.313,1.374
                                    c0.208,0.389,0.489,0.686,0.84,0.891s0.74,0.308,1.167,0.308s0.817-0.103,1.172-0.308s0.637-0.502,0.847-0.891
                                    c0.208-0.39,0.313-0.843,0.313-1.361S641.016,313.911,640.808,313.526z"/>
                                <path fill="#0888C1" d="M648.557,313.019c0.284-0.531,0.675-0.943,1.173-1.236c0.497-0.293,1.055-0.439,1.674-0.439
                                    c0.535,0,1.033,0.124,1.492,0.37c0.46,0.247,0.811,0.571,1.054,0.973v-3.639h1.153v9.285h-1.153v-1.292
                                    c-0.226,0.409-0.561,0.746-1.003,1.01c-0.443,0.264-0.961,0.396-1.555,0.396c-0.61,0-1.165-0.151-1.662-0.452
                                    c-0.498-0.301-0.889-0.724-1.173-1.268c-0.284-0.543-0.426-1.162-0.426-1.856C648.13,314.166,648.272,313.55,648.557,313.019z
                                    M653.636,313.526c-0.209-0.385-0.491-0.68-0.847-0.885s-0.746-0.308-1.172-0.308s-0.815,0.101-1.167,0.302
                                    c-0.351,0.2-0.631,0.493-0.84,0.878c-0.209,0.385-0.313,0.837-0.313,1.355c0,0.526,0.104,0.984,0.313,1.374
                                    c0.208,0.389,0.489,0.686,0.84,0.891s0.74,0.308,1.167,0.308s0.817-0.103,1.172-0.308s0.637-0.502,0.847-0.891
                                    c0.208-0.39,0.313-0.843,0.313-1.361S653.844,313.911,653.636,313.526z"/>
                                <path fill="#0888C1" d="M657.535,313.019c0.284-0.531,0.675-0.943,1.173-1.236c0.497-0.293,1.051-0.439,1.662-0.439
                                    c0.602,0,1.124,0.13,1.567,0.39c0.443,0.259,0.773,0.585,0.991,0.979v-1.255h1.153v6.876h-1.153v-1.28
                                    c-0.226,0.402-0.563,0.734-1.01,0.998s-0.968,0.396-1.561,0.396c-0.61,0-1.162-0.151-1.655-0.452
                                    c-0.494-0.301-0.882-0.724-1.167-1.268c-0.284-0.543-0.426-1.162-0.426-1.856C657.109,314.166,657.251,313.55,657.535,313.019z
                                    M662.614,313.526c-0.209-0.385-0.491-0.68-0.847-0.885s-0.746-0.308-1.172-0.308s-0.815,0.101-1.167,0.302
                                    c-0.351,0.2-0.631,0.493-0.84,0.878c-0.209,0.385-0.313,0.837-0.313,1.355c0,0.526,0.104,0.984,0.313,1.374
                                    c0.208,0.389,0.489,0.686,0.84,0.891s0.74,0.308,1.167,0.308s0.817-0.103,1.172-0.308s0.637-0.502,0.847-0.891
                                    c0.208-0.39,0.313-0.843,0.313-1.361S662.823,313.911,662.614,313.526z"/>
                                <path fill="#0888C1" d="M617.72,332.352h3.358v1.98h-5.921v-10.53h2.563V332.352z"/>
                                <path fill="#0888C1" d="M623.903,333.927c-0.655-0.35-1.169-0.85-1.544-1.5c-0.375-0.649-0.562-1.41-0.562-2.279
                                    c0-0.86,0.189-1.618,0.569-2.272c0.38-0.655,0.899-1.158,1.559-1.508s1.399-0.525,2.219-0.525s1.559,0.176,2.219,0.525
                                    s1.179,0.853,1.559,1.508c0.379,0.654,0.569,1.412,0.569,2.272c0,0.859-0.192,1.617-0.577,2.272
                                    c-0.385,0.654-0.909,1.157-1.574,1.507c-0.665,0.351-1.407,0.525-2.226,0.525C625.295,334.452,624.558,334.277,623.903,333.927z
                                    M627.366,331.692c0.345-0.36,0.517-0.875,0.517-1.545s-0.167-1.186-0.502-1.546c-0.335-0.359-0.747-0.539-1.237-0.539
                                    c-0.5,0-0.915,0.177-1.244,0.532c-0.33,0.354-0.495,0.872-0.495,1.553c0,0.67,0.162,1.185,0.487,1.545
                                    c0.325,0.359,0.732,0.54,1.222,0.54C626.604,332.232,627.021,332.052,627.366,331.692z"/>
                                <path fill="#0888C1" d="M635.544,326.27c0.45-0.265,0.949-0.397,1.499-0.397v2.715h-0.705c-0.64,0-1.119,0.138-1.439,0.413
                                    c-0.32,0.274-0.48,0.757-0.48,1.447v3.885h-2.563v-8.37h2.563v1.396C634.719,326.897,635.094,326.534,635.544,326.27z"/>
                                <path fill="#0888C1" d="M638.459,324.694c-0.285-0.265-0.427-0.593-0.427-0.982c0-0.399,0.143-0.732,0.427-0.997
                                    c0.285-0.266,0.652-0.397,1.102-0.397c0.439,0,0.802,0.132,1.087,0.397c0.285,0.265,0.427,0.598,0.427,0.997
                                    c0,0.39-0.143,0.718-0.427,0.982c-0.285,0.266-0.647,0.397-1.087,0.397C639.112,325.092,638.745,324.96,638.459,324.694z
                                    M640.835,325.962v8.37h-2.563v-8.37H640.835z"/>
                                <path fill="#0888C1" d="M647.424,326.202c0.444,0.24,0.787,0.555,1.027,0.945v-1.186h2.563v8.355
                                    c0,0.77-0.153,1.467-0.458,2.092s-0.77,1.123-1.394,1.493c-0.625,0.369-1.401,0.555-2.331,0.555
                                    c-1.239,0-2.244-0.293-3.013-0.878s-1.209-1.377-1.319-2.377h2.534c0.08,0.319,0.27,0.572,0.57,0.758
                                    c0.3,0.185,0.669,0.277,1.109,0.277c0.53,0,0.952-0.153,1.267-0.458c0.314-0.305,0.472-0.792,0.472-1.462v-1.186
                                    c-0.25,0.391-0.595,0.708-1.035,0.953s-0.954,0.367-1.544,0.367c-0.689,0-1.314-0.178-1.874-0.532
                                    c-0.56-0.355-1.002-0.86-1.327-1.516c-0.325-0.654-0.487-1.412-0.487-2.272c0-0.859,0.162-1.614,0.487-2.265
                                    s0.767-1.15,1.327-1.5c0.56-0.35,1.185-0.525,1.874-0.525C646.462,325.842,646.979,325.962,647.424,326.202z M647.918,328.632
                                    c-0.355-0.37-0.787-0.555-1.297-0.555s-0.942,0.183-1.296,0.548c-0.355,0.364-0.532,0.867-0.532,1.507
                                    c0,0.641,0.177,1.147,0.532,1.522c0.354,0.375,0.787,0.563,1.296,0.563s0.942-0.185,1.297-0.555
                                    c0.354-0.37,0.532-0.875,0.532-1.515C648.451,329.507,648.273,329.002,647.918,328.632z"/>
                                <path fill="#0888C1" d="M652.85,327.867c0.325-0.65,0.767-1.15,1.327-1.5c0.56-0.35,1.185-0.525,1.874-0.525
                                    c0.59,0,1.107,0.12,1.552,0.36c0.444,0.24,0.787,0.555,1.027,0.945v-1.186h2.563v8.37h-2.563v-1.185
                                    c-0.25,0.39-0.597,0.704-1.042,0.944s-0.962,0.36-1.552,0.36c-0.68,0-1.299-0.178-1.859-0.532
                                    c-0.56-0.355-1.002-0.86-1.327-1.516c-0.325-0.654-0.487-1.412-0.487-2.272C652.363,329.272,652.525,328.518,652.85,327.867z
                                    M658.097,328.632c-0.355-0.37-0.787-0.555-1.297-0.555s-0.942,0.183-1.296,0.548c-0.355,0.364-0.532,0.867-0.532,1.507
                                    c0,0.641,0.177,1.147,0.532,1.522c0.354,0.375,0.787,0.563,1.296,0.563s0.942-0.185,1.297-0.555
                                    c0.354-0.37,0.532-0.875,0.532-1.515C658.629,329.507,658.452,329.002,658.097,328.632z"/>
                            </g>
                        </g>
                    </g>
                    <g data-tooltip-content="9" data-tooltip-id="svgTooltipPistas">
                        <polygon fill="#378C6D" points="757.851,667.503 564.261,581.464 566.044,577.452 759.634,663.491 757.851,667.503 		"/>
                        <g>
                            <g>
                                <polygon fillRule="evenodd" clipRule="evenodd" fill="#378C6D" points="742.254,697.472 776.73,695.599 758.743,663.706 				
                                    "/>
                                <path fill="#FFFFFF" d="M737.83,700.404l20.741-42.476l22.627,40.12L737.83,700.404L737.83,700.404z M758.914,669.483
                                    l-12.235,25.056l25.583-1.391L758.914,669.483L758.914,669.483z"/>
                            </g>
                            <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M803.138,724.632c0,23.258-18.863,42.121-42.124,42.121
                                c-23.257,0-42.123-18.863-42.123-42.121c0-23.26,18.866-42.123,42.123-42.123C784.275,682.509,803.138,701.372,803.138,724.632z"
                                />
                            <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" stroke="#ACCCC0" strokeWidth="2.6887" d="M796.865,724.632
                                c0,19.795-16.058,35.852-35.851,35.852c-19.794,0-35.85-16.057-35.85-35.852c0-19.793,16.056-35.85,35.85-35.85
                                C780.807,688.782,796.865,704.839,796.865,724.632z"/>
                            <g>
                                <path fill="#378C6D" d="M740.601,717.037c-0.498,0.481-1.256,0.722-2.276,0.722h-1.681v3.651h-1.141v-8.745h2.822
                                    c0.986,0,1.737,0.238,2.251,0.715s0.771,1.092,0.771,1.845C741.347,715.952,741.098,716.557,740.601,717.037z M739.729,716.403
                                    c0.301-0.275,0.452-0.669,0.452-1.179c0-1.079-0.619-1.619-1.856-1.619h-1.681v3.212h1.681
                                    C738.96,716.817,739.429,716.68,739.729,716.403z"/>
                                <path fill="#378C6D" d="M743.304,713.191c-0.151-0.15-0.226-0.334-0.226-0.552s0.075-0.401,0.226-0.553
                                    c0.15-0.15,0.334-0.226,0.552-0.226c0.208,0,0.386,0.075,0.533,0.226c0.146,0.151,0.219,0.335,0.219,0.553
                                    s-0.073,0.401-0.219,0.552c-0.146,0.15-0.324,0.226-0.533,0.226C743.638,713.417,743.454,713.342,743.304,713.191z
                                    M744.407,714.534v6.876h-1.141v-6.876H744.407z"/>
                                <path fill="#378C6D" d="M747.868,721.253c-0.418-0.18-0.749-0.431-0.991-0.753c-0.243-0.321-0.376-0.691-0.401-1.11h1.179
                                    c0.033,0.344,0.194,0.624,0.483,0.841c0.289,0.218,0.667,0.326,1.135,0.326c0.435,0,0.777-0.096,1.028-0.288
                                    s0.376-0.436,0.376-0.728c0-0.302-0.134-0.525-0.401-0.672c-0.268-0.146-0.682-0.29-1.242-0.433
                                    c-0.51-0.134-0.926-0.27-1.248-0.408c-0.322-0.138-0.598-0.343-0.828-0.614c-0.23-0.272-0.345-0.63-0.345-1.073
                                    c0-0.352,0.104-0.673,0.313-0.966c0.208-0.293,0.505-0.525,0.89-0.696c0.384-0.172,0.823-0.258,1.317-0.258
                                    c0.76,0,1.375,0.192,1.843,0.577c0.468,0.385,0.719,0.912,0.752,1.581h-1.141c-0.025-0.359-0.169-0.647-0.433-0.865
                                    c-0.264-0.218-0.617-0.326-1.06-0.326c-0.41,0-0.735,0.088-0.978,0.263c-0.242,0.176-0.363,0.406-0.363,0.69
                                    c0,0.226,0.073,0.412,0.219,0.559c0.146,0.146,0.33,0.264,0.552,0.352c0.221,0.088,0.529,0.186,0.922,0.295
                                    c0.493,0.134,0.894,0.266,1.204,0.395c0.309,0.13,0.575,0.324,0.796,0.584c0.221,0.259,0.336,0.598,0.345,1.017
                                    c0,0.376-0.104,0.715-0.313,1.016c-0.209,0.302-0.504,0.538-0.884,0.709c-0.38,0.172-0.817,0.258-1.31,0.258
                                    C748.758,721.523,748.286,721.433,747.868,721.253z"/>
                                <path fill="#378C6D" d="M755.291,715.476v4.053c0,0.334,0.071,0.57,0.213,0.709c0.142,0.138,0.389,0.207,0.74,0.207h0.84v0.966
                                    h-1.028c-0.636,0-1.112-0.146-1.43-0.439c-0.318-0.292-0.477-0.773-0.477-1.442v-4.053h-0.89v-0.941h0.89v-1.731h1.141v1.731
                                    h1.793v0.941H755.291z"/>
                                <path fill="#378C6D" d="M758.965,716.097c0.284-0.531,0.675-0.943,1.173-1.236c0.497-0.293,1.051-0.439,1.662-0.439
                                    c0.602,0,1.124,0.13,1.567,0.39c0.443,0.259,0.773,0.585,0.991,0.979v-1.255h1.153v6.876h-1.153v-1.28
                                    c-0.226,0.402-0.563,0.734-1.01,0.998s-0.968,0.396-1.561,0.396c-0.61,0-1.162-0.151-1.655-0.452
                                    c-0.494-0.301-0.882-0.724-1.167-1.268c-0.284-0.543-0.426-1.162-0.426-1.856C758.539,717.244,758.681,716.628,758.965,716.097z
                                    M764.044,716.604c-0.209-0.385-0.491-0.68-0.847-0.885s-0.746-0.308-1.172-0.308s-0.815,0.101-1.167,0.302
                                    c-0.351,0.2-0.631,0.493-0.84,0.878c-0.209,0.385-0.313,0.837-0.313,1.355c0,0.526,0.104,0.984,0.313,1.374
                                    c0.208,0.389,0.489,0.686,0.84,0.891s0.74,0.308,1.167,0.308s0.817-0.103,1.172-0.308s0.637-0.502,0.847-0.891
                                    c0.208-0.39,0.313-0.843,0.313-1.361S764.252,716.989,764.044,716.604z"/>
                                <path fill="#378C6D" d="M771.793,716.097c0.284-0.531,0.675-0.943,1.173-1.236c0.497-0.293,1.055-0.439,1.674-0.439
                                    c0.535,0,1.033,0.124,1.492,0.37c0.46,0.247,0.811,0.571,1.054,0.973v-3.639h1.153v9.285h-1.153v-1.292
                                    c-0.226,0.409-0.561,0.746-1.003,1.01c-0.443,0.264-0.961,0.396-1.555,0.396c-0.61,0-1.165-0.151-1.662-0.452
                                    c-0.498-0.301-0.889-0.724-1.173-1.268c-0.284-0.543-0.426-1.162-0.426-1.856C771.367,717.244,771.509,716.628,771.793,716.097z
                                    M776.872,716.604c-0.209-0.385-0.491-0.68-0.847-0.885s-0.746-0.308-1.172-0.308s-0.815,0.101-1.167,0.302
                                    c-0.351,0.2-0.631,0.493-0.84,0.878c-0.209,0.385-0.313,0.837-0.313,1.355c0,0.526,0.104,0.984,0.313,1.374
                                    c0.208,0.389,0.489,0.686,0.84,0.891s0.74,0.308,1.167,0.308s0.817-0.103,1.172-0.308s0.637-0.502,0.847-0.891
                                    c0.208-0.39,0.313-0.843,0.313-1.361S777.081,716.989,776.872,716.604z"/>
                                <path fill="#378C6D" d="M782.02,721.084c-0.522-0.293-0.932-0.709-1.229-1.249c-0.297-0.539-0.445-1.164-0.445-1.875
                                    c0-0.703,0.152-1.324,0.458-1.863c0.305-0.54,0.721-0.954,1.248-1.242c0.527-0.289,1.116-0.434,1.768-0.434
                                    c0.652,0,1.242,0.145,1.768,0.434c0.527,0.288,0.942,0.7,1.248,1.235c0.305,0.535,0.458,1.159,0.458,1.87
                                    s-0.157,1.336-0.47,1.875c-0.313,0.54-0.738,0.956-1.273,1.249s-1.128,0.439-1.781,0.439
                                    C783.125,721.523,782.542,721.377,782.02,721.084z M784.922,720.23c0.359-0.192,0.65-0.48,0.872-0.865
                                    c0.221-0.385,0.332-0.854,0.332-1.405c0-0.553-0.108-1.021-0.326-1.405c-0.218-0.385-0.501-0.672-0.853-0.86
                                    c-0.351-0.188-0.731-0.282-1.141-0.282c-0.418,0-0.8,0.095-1.147,0.282c-0.347,0.188-0.625,0.476-0.834,0.86
                                    c-0.209,0.385-0.313,0.853-0.313,1.405c0,0.561,0.102,1.033,0.307,1.418c0.205,0.385,0.479,0.671,0.821,0.859
                                    c0.343,0.188,0.719,0.282,1.128,0.282S784.563,720.423,784.922,720.23z"/>
                                <path fill="#378C6D" d="M735.51,728.739c0.484-0.877,1.158-1.56,2.023-2.049s1.846-0.734,2.942-0.734
                                    c1.344,0,2.494,0.354,3.45,1.064s1.596,1.678,1.919,2.904h-3.031c-0.226-0.474-0.545-0.833-0.959-1.081
                                    c-0.414-0.247-0.884-0.371-1.411-0.371c-0.849,0-1.537,0.296-2.063,0.888c-0.527,0.592-0.79,1.382-0.79,2.371
                                    s0.263,1.78,0.79,2.371c0.526,0.592,1.214,0.888,2.063,0.888c0.527,0,0.997-0.123,1.411-0.371
                                    c0.414-0.247,0.733-0.607,0.959-1.081h3.031c-0.323,1.227-0.962,2.191-1.919,2.896c-0.957,0.704-2.106,1.057-3.45,1.057
                                    c-1.096,0-2.077-0.245-2.942-0.734s-1.54-1.17-2.023-2.041c-0.483-0.871-0.725-1.865-0.725-2.984
                                    C734.785,730.613,735.026,729.615,735.51,728.739z"/>
                                <path fill="#378C6D" d="M749.254,736.975c-0.704-0.376-1.258-0.914-1.661-1.613c-0.403-0.699-0.604-1.517-0.604-2.452
                                    c0-0.925,0.204-1.739,0.612-2.444c0.409-0.704,0.967-1.244,1.677-1.621c0.709-0.376,1.505-0.564,2.386-0.564
                                    s1.677,0.188,2.386,0.564c0.709,0.377,1.268,0.917,1.677,1.621c0.408,0.705,0.613,1.52,0.613,2.444s-0.207,1.74-0.621,2.444
                                    c-0.414,0.704-0.978,1.245-1.693,1.621c-0.715,0.377-1.513,0.564-2.394,0.564S749.958,737.352,749.254,736.975z
                                    M752.979,734.571c0.371-0.388,0.556-0.941,0.556-1.662s-0.18-1.274-0.54-1.662c-0.36-0.387-0.804-0.58-1.33-0.58
                                    c-0.538,0-0.983,0.19-1.338,0.572c-0.354,0.382-0.532,0.938-0.532,1.67c0,0.721,0.174,1.274,0.524,1.662
                                    c0.349,0.387,0.787,0.58,1.314,0.58C752.159,735.151,752.608,734.958,752.979,734.571z"/>
                                <path fill="#378C6D" d="M761.854,734.829l1.951-6.421h2.935l-3.192,9.002h-3.402l-3.192-9.002h2.951L761.854,734.829z"/>
                                <path fill="#378C6D" d="M767.876,730.457c0.349-0.699,0.825-1.236,1.427-1.613c0.602-0.376,1.273-0.564,2.015-0.564
                                    c0.634,0,1.19,0.129,1.669,0.387c0.478,0.259,0.846,0.598,1.104,1.017v-1.274h2.757v9.002h-2.757v-1.274
                                    c-0.269,0.419-0.643,0.758-1.121,1.017c-0.479,0.258-1.035,0.387-1.669,0.387c-0.731,0-1.397-0.19-1.999-0.572
                                    c-0.602-0.382-1.078-0.925-1.427-1.63c-0.35-0.704-0.524-1.519-0.524-2.444C767.352,731.968,767.526,731.156,767.876,730.457z
                                    M771.431,725.263c0.236,0,0.443,0.035,0.621,0.104c0.177,0.07,0.395,0.175,0.653,0.314c0.193,0.107,0.349,0.186,0.467,0.234
                                    c0.118,0.048,0.242,0.072,0.371,0.072c0.397,0,0.629-0.242,0.693-0.726h1.418c-0.258,1.592-1,2.388-2.225,2.388
                                    c-0.237,0-0.443-0.035-0.621-0.105c-0.178-0.069-0.395-0.175-0.653-0.314c-0.193-0.107-0.349-0.186-0.467-0.233
                                    c-0.119-0.049-0.242-0.073-0.371-0.073c-0.397,0-0.634,0.242-0.709,0.727h-1.402
                                    C769.464,726.059,770.206,725.263,771.431,725.263z M773.519,731.28c-0.381-0.398-0.846-0.598-1.395-0.598
                                    c-0.548,0-1.013,0.196-1.395,0.589c-0.382,0.393-0.572,0.934-0.572,1.621c0,0.688,0.19,1.234,0.572,1.638
                                    c0.381,0.403,0.847,0.605,1.395,0.605c0.548,0,1.013-0.199,1.395-0.597c0.381-0.398,0.573-0.941,0.573-1.63
                                    S773.9,731.678,773.519,731.28z"/>
                                <path fill="#378C6D" d="M780.564,736.975c-0.704-0.376-1.258-0.914-1.661-1.613c-0.403-0.699-0.604-1.517-0.604-2.452
                                    c0-0.925,0.204-1.739,0.612-2.444c0.409-0.704,0.967-1.244,1.677-1.621c0.709-0.376,1.505-0.564,2.386-0.564
                                    s1.677,0.188,2.386,0.564c0.709,0.377,1.268,0.917,1.677,1.621c0.408,0.705,0.613,1.52,0.613,2.444s-0.207,1.74-0.621,2.444
                                    c-0.414,0.704-0.978,1.245-1.693,1.621c-0.715,0.377-1.513,0.564-2.394,0.564S781.268,737.352,780.564,736.975z
                                    M784.289,734.571c0.371-0.388,0.556-0.941,0.556-1.662s-0.18-1.274-0.54-1.662c-0.36-0.387-0.804-0.58-1.33-0.58
                                    c-0.538,0-0.983,0.19-1.338,0.572c-0.354,0.382-0.532,0.938-0.532,1.67c0,0.721,0.174,1.274,0.524,1.662
                                    c0.349,0.387,0.787,0.58,1.314,0.58C783.469,735.151,783.918,734.958,784.289,734.571z"/>
                            </g>
                        </g>
                    </g>
                    <g data-tooltip-content="1" data-tooltip-id="svgTooltipPistas">
                        <g>
                            <g>
                                <polygon fillRule="evenodd" clipRule="evenodd" points="1386.277,426.651 1360.516,460.12 1403.262,458.53 				"/>
                                <path fill="#FFFFFF" d="M1354.891,463.02l31.798-41.312l20.967,39.35L1354.891,463.02L1354.891,463.02z M1385.865,431.595
                                    l-19.724,25.626l32.728-1.218L1385.865,431.595L1385.865,431.595z"/>
                            </g>
                            <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M1456.672,431.282c0,22.764-18.462,41.225-41.228,41.225
                                c-22.765,0-41.228-18.461-41.228-41.225c0-22.76,18.463-41.227,41.228-41.227C1438.21,390.056,1456.672,408.522,1456.672,431.282
                                z"/>
                            <g>
                                <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M1451.295,431.282c0,19.795-16.056,35.852-35.851,35.852
                                    c-19.794,0-35.849-16.057-35.849-35.852c0-19.793,16.055-35.85,35.849-35.85C1435.24,395.433,1451.295,411.489,1451.295,431.282
                                    z"/>
                                <path d="M1415.445,468.479c-20.508,0-37.193-16.687-37.193-37.196c0-20.509,16.686-37.194,37.193-37.194
                                    c20.51,0,37.195,16.685,37.195,37.194C1452.64,451.792,1435.955,468.479,1415.445,468.479L1415.445,468.479z M1415.445,396.777
                                    c-19.025,0-34.504,15.479-34.504,34.505c0,19.027,15.479,34.507,34.504,34.507c19.026,0,34.506-15.479,34.506-34.507
                                    C1449.951,412.256,1434.471,396.777,1415.445,396.777L1415.445,396.777z"/>
                            </g>
                            <g>
                                <path d="M1396.118,423.703c-0.498,0.481-1.257,0.722-2.276,0.722h-1.681v3.651h-1.141v-8.745h2.821
                                    c0.986,0,1.736,0.238,2.251,0.715c0.514,0.477,0.771,1.092,0.771,1.845C1396.864,422.618,1396.615,423.223,1396.118,423.703z
                                    M1395.246,423.069c0.301-0.275,0.451-0.669,0.451-1.179c0-1.079-0.618-1.619-1.855-1.619h-1.681v3.212h1.681
                                    C1394.476,423.483,1394.945,423.346,1395.246,423.069z"/>
                                <path d="M1398.318,419.857c-0.15-0.15-0.226-0.334-0.226-0.552s0.075-0.401,0.226-0.553c0.15-0.15,0.335-0.226,0.552-0.226
                                    c0.209,0,0.387,0.075,0.533,0.226c0.146,0.151,0.22,0.335,0.22,0.553s-0.073,0.401-0.22,0.552s-0.324,0.226-0.533,0.226
                                    C1398.653,420.083,1398.468,420.008,1398.318,419.857z M1399.421,421.2v6.876h-1.141V421.2H1399.421z"/>
                                <path d="M1402.38,427.919c-0.418-0.18-0.748-0.431-0.991-0.753c-0.242-0.321-0.376-0.691-0.4-1.11h1.179
                                    c0.033,0.344,0.194,0.624,0.482,0.841c0.288,0.218,0.666,0.326,1.135,0.326c0.435,0,0.777-0.096,1.028-0.288
                                    s0.376-0.436,0.376-0.728c0-0.302-0.134-0.525-0.401-0.672s-0.682-0.29-1.241-0.433c-0.51-0.134-0.926-0.27-1.248-0.408
                                    c-0.321-0.138-0.598-0.343-0.827-0.614c-0.23-0.272-0.345-0.63-0.345-1.073c0-0.352,0.104-0.673,0.313-0.966
                                    s0.506-0.525,0.89-0.696c0.385-0.172,0.824-0.258,1.317-0.258c0.761,0,1.375,0.192,1.843,0.577
                                    c0.469,0.385,0.719,0.912,0.753,1.581h-1.142c-0.024-0.359-0.169-0.647-0.433-0.865c-0.263-0.218-0.616-0.326-1.06-0.326
                                    c-0.409,0-0.735,0.088-0.978,0.263c-0.243,0.176-0.364,0.406-0.364,0.69c0,0.226,0.073,0.412,0.22,0.559s0.33,0.264,0.552,0.352
                                    s0.528,0.186,0.922,0.295c0.493,0.134,0.895,0.266,1.203,0.395c0.31,0.13,0.575,0.324,0.797,0.584
                                    c0.222,0.259,0.336,0.598,0.345,1.017c0,0.376-0.104,0.715-0.313,1.016c-0.209,0.302-0.504,0.538-0.884,0.709
                                    c-0.381,0.172-0.817,0.258-1.311,0.258C1403.271,428.189,1402.798,428.099,1402.38,427.919z"/>
                                <path d="M1409.302,422.142v4.053c0,0.334,0.071,0.57,0.213,0.709c0.143,0.138,0.389,0.207,0.74,0.207h0.84v0.966h-1.028
                                    c-0.635,0-1.111-0.146-1.429-0.439c-0.318-0.292-0.477-0.773-0.477-1.442v-4.053h-0.891V421.2h0.891v-1.731h1.141v1.731h1.793
                                    v0.941H1409.302z"/>
                                <path d="M1412.475,422.763c0.284-0.531,0.675-0.943,1.172-1.236c0.498-0.293,1.052-0.439,1.662-0.439
                                    c0.602,0,1.124,0.13,1.567,0.39c0.442,0.259,0.773,0.585,0.99,0.979V421.2h1.154v6.876h-1.154v-1.28
                                    c-0.226,0.402-0.563,0.734-1.009,0.998c-0.447,0.264-0.968,0.396-1.562,0.396c-0.61,0-1.162-0.151-1.655-0.452
                                    s-0.882-0.724-1.166-1.268c-0.284-0.543-0.427-1.162-0.427-1.856C1412.048,423.91,1412.191,423.294,1412.475,422.763z
                                    M1417.553,423.271c-0.209-0.385-0.491-0.68-0.847-0.885c-0.354-0.205-0.746-0.308-1.172-0.308
                                    c-0.427,0-0.815,0.101-1.166,0.302c-0.352,0.2-0.632,0.493-0.841,0.878s-0.313,0.837-0.313,1.355
                                    c0,0.526,0.104,0.984,0.313,1.374c0.209,0.389,0.489,0.686,0.841,0.891c0.351,0.205,0.739,0.308,1.166,0.308
                                    c0.426,0,0.817-0.103,1.172-0.308c0.355-0.205,0.638-0.502,0.847-0.891c0.209-0.39,0.313-0.843,0.313-1.361
                                    S1417.762,423.655,1417.553,423.271z"/>
                                <path d="M1424.3,422.763c0.284-0.531,0.675-0.943,1.172-1.236c0.498-0.293,1.056-0.439,1.675-0.439
                                    c0.534,0,1.032,0.124,1.492,0.37c0.459,0.247,0.811,0.571,1.053,0.973v-3.639h1.154v9.285h-1.154v-1.292
                                    c-0.226,0.409-0.56,0.746-1.003,1.01s-0.962,0.396-1.555,0.396c-0.61,0-1.164-0.151-1.662-0.452
                                    c-0.497-0.301-0.888-0.724-1.172-1.268c-0.284-0.543-0.427-1.162-0.427-1.856C1423.874,423.91,1424.016,423.294,1424.3,422.763z
                                    M1429.378,423.271c-0.209-0.385-0.491-0.68-0.846-0.885c-0.355-0.205-0.747-0.308-1.173-0.308
                                    c-0.427,0-0.815,0.101-1.166,0.302c-0.352,0.2-0.632,0.493-0.841,0.878s-0.313,0.837-0.313,1.355
                                    c0,0.526,0.104,0.984,0.313,1.374c0.209,0.389,0.489,0.686,0.841,0.891c0.351,0.205,0.739,0.308,1.166,0.308
                                    c0.426,0,0.817-0.103,1.173-0.308c0.354-0.205,0.637-0.502,0.846-0.891c0.209-0.39,0.313-0.843,0.313-1.361
                                    S1429.587,423.655,1429.378,423.271z"/>
                                <path d="M1432.777,422.763c0.284-0.531,0.675-0.943,1.172-1.236c0.498-0.293,1.052-0.439,1.662-0.439
                                    c0.602,0,1.124,0.13,1.567,0.39c0.442,0.259,0.773,0.585,0.99,0.979V421.2h1.154v6.876h-1.154v-1.28
                                    c-0.226,0.402-0.563,0.734-1.009,0.998c-0.447,0.264-0.968,0.396-1.562,0.396c-0.61,0-1.162-0.151-1.655-0.452
                                    s-0.882-0.724-1.166-1.268c-0.284-0.543-0.427-1.162-0.427-1.856C1432.35,423.91,1432.493,423.294,1432.777,422.763z
                                    M1437.855,423.271c-0.209-0.385-0.491-0.68-0.846-0.885c-0.355-0.205-0.747-0.308-1.173-0.308
                                    c-0.427,0-0.815,0.101-1.166,0.302c-0.352,0.2-0.632,0.493-0.841,0.878s-0.313,0.837-0.313,1.355
                                    c0,0.526,0.104,0.984,0.313,1.374c0.209,0.389,0.489,0.686,0.841,0.891c0.351,0.205,0.739,0.308,1.166,0.308
                                    c0.426,0,0.817-0.103,1.173-0.308c0.354-0.205,0.637-0.502,0.846-0.891c0.209-0.39,0.313-0.843,0.313-1.361
                                    S1438.064,423.655,1437.855,423.271z"/>
                                <path d="M1402.547,432.751v2.21h-2.999v9.115h-2.757v-9.115h-2.999v-2.21H1402.547z"/>
                                <path d="M1405.651,443.641c-0.704-0.376-1.258-0.914-1.661-1.613c-0.402-0.699-0.604-1.517-0.604-2.452
                                    c0-0.925,0.204-1.739,0.613-2.444c0.408-0.704,0.967-1.244,1.677-1.621c0.709-0.376,1.504-0.564,2.386-0.564
                                    c0.881,0,1.677,0.188,2.386,0.564c0.71,0.377,1.269,0.917,1.677,1.621c0.409,0.705,0.613,1.52,0.613,2.444
                                    s-0.207,1.74-0.621,2.444s-0.978,1.245-1.692,1.621c-0.715,0.377-1.514,0.564-2.395,0.564
                                    C1407.147,444.205,1406.355,444.018,1405.651,443.641z M1409.375,441.237c0.37-0.388,0.556-0.941,0.556-1.662
                                    s-0.18-1.274-0.54-1.662c-0.36-0.387-0.804-0.58-1.33-0.58c-0.537,0-0.983,0.19-1.338,0.572s-0.532,0.938-0.532,1.67
                                    c0,0.721,0.175,1.274,0.523,1.662c0.35,0.387,0.788,0.58,1.314,0.58S1409.004,441.624,1409.375,441.237z"/>
                                <path d="M1418.169,435.405c0.483-0.285,1.021-0.428,1.612-0.428v2.92h-0.758c-0.688,0-1.204,0.147-1.548,0.443
                                    s-0.516,0.815-0.516,1.557v4.179h-2.757v-9.002h2.757v1.5C1417.283,436.08,1417.686,435.69,1418.169,435.405z"/>
                                <path d="M1425.07,435.405c0.483-0.285,1.021-0.428,1.612-0.428v2.92h-0.758c-0.688,0-1.204,0.147-1.548,0.443
                                    s-0.516,0.815-0.516,1.557v4.179h-2.757v-9.002h2.757v1.5C1424.183,436.08,1424.586,435.69,1425.07,435.405z"/>
                                <path d="M1436.436,440.236h-6.239c0.043,0.56,0.223,0.987,0.54,1.283c0.316,0.296,0.707,0.443,1.169,0.443
                                    c0.688,0,1.166-0.29,1.435-0.871h2.935c-0.15,0.592-0.422,1.124-0.814,1.597c-0.393,0.474-0.884,0.845-1.475,1.113
                                    c-0.592,0.27-1.253,0.403-1.983,0.403c-0.882,0-1.666-0.188-2.354-0.564c-0.688-0.376-1.226-0.914-1.612-1.613
                                    c-0.388-0.699-0.581-1.517-0.581-2.452s0.191-1.753,0.572-2.452c0.382-0.699,0.917-1.236,1.604-1.613
                                    c0.688-0.376,1.478-0.564,2.37-0.564c0.87,0,1.645,0.183,2.321,0.549c0.678,0.365,1.207,0.887,1.589,1.564
                                    c0.381,0.678,0.572,1.468,0.572,2.371C1436.485,439.688,1436.468,439.957,1436.436,440.236z M1433.663,438.704
                                    c0-0.474-0.161-0.85-0.483-1.129c-0.322-0.28-0.726-0.42-1.209-0.42c-0.462,0-0.852,0.135-1.169,0.403s-0.514,0.651-0.589,1.146
                                    H1433.663z"/>
                            </g>
                        </g>
                        <path d="M1383.305,664.725c-14.091,0.001-30.364-4.891-45.307-13.704c-17.495-10.318-30.987-24.707-37.99-40.517
                            c-8.869-20.02-11.663-51.374,25.39-87.055c32.35-31.153,36.102-52.942,28.825-62.945c-8.36-11.495-31.229-10.854-54.372,1.524
                            c-22.451,12.008-41.129,12.846-49.966,2.24c-7.388-8.865-5.047-23.527,5.963-37.353c11.004-13.817,14.873-24.706,11.831-33.288
                            c-4.695-13.243-25.736-23.088-70.354-32.917c-52.569-11.584-94.092-20.734-195.014-133.478
                            c-29.898-33.398-83.869-50.044-123.879-58.126c-47.636-9.622-90.129-10.319-102.202-9.162l-0.514-5.354
                            c12.318-1.18,55.558-0.496,103.78,9.244c40.809,8.244,95.938,25.313,126.82,59.812
                            c99.767,111.451,140.541,120.437,192.165,131.812c47.282,10.417,68.798,20.954,74.265,36.372
                            c3.714,10.475-0.438,23.047-12.692,38.435c-9.353,11.744-11.723,23.739-6.038,30.561c5.467,6.56,19.712,9.078,43.297-3.54
                            c25.95-13.877,51.141-13.856,61.258,0.055c11.149,15.328,0.143,41.49-29.443,69.981c-34.813,33.523-32.377,62.554-24.203,81.004
                            c14.306,32.293,54.643,52.752,81.906,50.904l0.363,5.365C1385.919,664.682,1384.621,664.725,1383.305,664.725L1383.305,664.725z"
                            />
                    </g>
                    <g data-tooltip-content="6" data-tooltip-id="svgTooltipPistas">
                        <g>
                            <path fill="#A00D0D" d="M706.585,244.019l-4.384,0.258c1.151,19.575,9.343,71.486,60.42,130.602
                                c5.749,6.654,11.795,13.777,18.195,21.32c51.848,61.096,122.013,143.729,216.402,193.729c3.159-0.003,6.46,0.053,9.907,0.172
                                c-97.611-49.083-169.917-134.237-222.961-196.743c-6.407-7.55-12.458-14.681-18.221-21.35
                                C715.745,313.909,707.709,263.146,706.585,244.019z"/>
                        </g>
                        <g>
                            
                                <polygon fillRule="evenodd" clipRule="evenodd" fill="#D6A1A7" stroke="#FFFFFF" strokeWidth="5.3775" strokeLinecap="round" strokeLinejoin="round" points="
                                699.894,222.128 707.871,247.657 679.933,241.491 			"/>
                            <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M706.026,208.532c0,23.258-18.864,42.121-42.124,42.121
                                c-23.261,0-42.124-18.863-42.124-42.121c0-23.26,18.863-42.123,42.124-42.123C687.162,166.409,706.026,185.272,706.026,208.532z"
                                />
                            <g>
                                <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M699.752,208.532c0,19.795-16.056,35.852-35.85,35.852
                                    c-19.795,0-35.85-16.057-35.85-35.852c0-19.793,16.055-35.85,35.85-35.85C683.696,172.683,699.752,188.739,699.752,208.532z"/>
                                <path fill="#E5C4C8" d="M663.902,245.728c-20.509,0-37.194-16.686-37.194-37.196c0-20.509,16.685-37.194,37.194-37.194
                                    s37.194,16.685,37.194,37.194C701.096,229.042,684.411,245.728,663.902,245.728L663.902,245.728z M663.902,174.027
                                    c-19.026,0-34.505,15.479-34.505,34.505c0,19.027,15.479,34.507,34.505,34.507s34.505-15.48,34.505-34.507
                                    C698.408,189.506,682.929,174.027,663.902,174.027L663.902,174.027z"/>
                            </g>
                            <g>
                                <path fill="#A00D0D" d="M643.948,200.505c-0.498,0.481-1.256,0.722-2.276,0.722h-1.681v3.651h-1.141v-8.745h2.822
                                    c0.986,0,1.737,0.238,2.251,0.715s0.771,1.092,0.771,1.845C644.694,199.42,644.445,200.024,643.948,200.505z M643.077,199.871
                                    c0.301-0.275,0.452-0.669,0.452-1.179c0-1.079-0.619-1.619-1.856-1.619h-1.681v3.212h1.681
                                    C642.308,200.285,642.776,200.147,643.077,199.871z"/>
                                <path fill="#A00D0D" d="M646.651,196.659c-0.151-0.15-0.226-0.334-0.226-0.552s0.075-0.401,0.226-0.553
                                    c0.15-0.15,0.334-0.226,0.552-0.226c0.208,0,0.386,0.075,0.533,0.226c0.146,0.151,0.219,0.335,0.219,0.553
                                    s-0.073,0.401-0.219,0.552c-0.146,0.15-0.324,0.226-0.533,0.226C646.985,196.885,646.801,196.81,646.651,196.659z
                                    M647.754,198.002v6.876h-1.141v-6.876H647.754z"/>
                                <path fill="#A00D0D" d="M651.215,204.721c-0.418-0.18-0.749-0.431-0.991-0.753c-0.243-0.321-0.376-0.691-0.401-1.11h1.179
                                    c0.033,0.344,0.194,0.624,0.483,0.841c0.289,0.218,0.667,0.326,1.135,0.326c0.435,0,0.777-0.096,1.028-0.288
                                    s0.376-0.436,0.376-0.728c0-0.302-0.134-0.525-0.401-0.672c-0.268-0.146-0.682-0.29-1.242-0.433
                                    c-0.51-0.134-0.926-0.27-1.248-0.408c-0.322-0.138-0.598-0.343-0.828-0.614c-0.23-0.272-0.345-0.63-0.345-1.073
                                    c0-0.352,0.104-0.673,0.313-0.966c0.208-0.293,0.505-0.525,0.89-0.696c0.384-0.172,0.823-0.258,1.317-0.258
                                    c0.76,0,1.375,0.192,1.843,0.577c0.468,0.385,0.719,0.912,0.752,1.581h-1.141c-0.025-0.359-0.169-0.647-0.433-0.865
                                    c-0.264-0.218-0.617-0.326-1.06-0.326c-0.41,0-0.735,0.088-0.978,0.263c-0.242,0.176-0.363,0.406-0.363,0.69
                                    c0,0.226,0.073,0.412,0.219,0.559c0.146,0.146,0.33,0.264,0.552,0.352c0.221,0.088,0.529,0.186,0.922,0.295
                                    c0.493,0.134,0.894,0.266,1.204,0.395c0.309,0.13,0.575,0.324,0.796,0.584c0.221,0.259,0.336,0.598,0.345,1.017
                                    c0,0.376-0.104,0.715-0.313,1.016c-0.209,0.302-0.504,0.538-0.884,0.709c-0.38,0.172-0.817,0.258-1.31,0.258
                                    C652.105,204.991,651.633,204.9,651.215,204.721z"/>
                                <path fill="#A00D0D" d="M658.638,198.943v4.053c0,0.334,0.071,0.57,0.213,0.709c0.142,0.138,0.389,0.207,0.74,0.207h0.84v0.966
                                    h-1.028c-0.636,0-1.112-0.146-1.43-0.439c-0.318-0.292-0.477-0.773-0.477-1.442v-4.053h-0.89v-0.941h0.89v-1.731h1.141v1.731
                                    h1.793v0.941H658.638z"/>
                                <path fill="#A00D0D" d="M662.312,199.564c0.284-0.531,0.675-0.943,1.173-1.236c0.497-0.293,1.051-0.439,1.662-0.439
                                    c0.602,0,1.124,0.13,1.567,0.39c0.443,0.259,0.773,0.585,0.991,0.979v-1.255h1.153v6.876h-1.153v-1.28
                                    c-0.226,0.402-0.563,0.734-1.01,0.998s-0.968,0.396-1.561,0.396c-0.61,0-1.162-0.151-1.655-0.452
                                    c-0.494-0.301-0.882-0.724-1.167-1.268c-0.284-0.543-0.426-1.162-0.426-1.856C661.886,200.712,662.028,200.096,662.312,199.564z
                                    M667.391,200.072c-0.209-0.385-0.491-0.68-0.847-0.885s-0.746-0.308-1.172-0.308s-0.815,0.101-1.167,0.302
                                    c-0.351,0.2-0.631,0.493-0.84,0.878c-0.209,0.385-0.313,0.837-0.313,1.355c0,0.526,0.104,0.984,0.313,1.374
                                    c0.208,0.389,0.489,0.686,0.84,0.891s0.74,0.308,1.167,0.308s0.817-0.103,1.172-0.308s0.637-0.502,0.847-0.891
                                    c0.208-0.39,0.313-0.843,0.313-1.361S667.6,200.457,667.391,200.072z"/>
                                <path fill="#A00D0D" d="M675.14,199.564c0.284-0.531,0.675-0.943,1.173-1.236c0.497-0.293,1.055-0.439,1.674-0.439
                                    c0.535,0,1.033,0.124,1.492,0.37c0.46,0.247,0.811,0.571,1.054,0.973v-3.639h1.153v9.285h-1.153v-1.292
                                    c-0.226,0.409-0.561,0.746-1.003,1.01c-0.443,0.264-0.961,0.396-1.555,0.396c-0.61,0-1.165-0.151-1.662-0.452
                                    c-0.498-0.301-0.889-0.724-1.173-1.268c-0.284-0.543-0.426-1.162-0.426-1.856C674.714,200.712,674.856,200.096,675.14,199.564z
                                    M680.219,200.072c-0.209-0.385-0.491-0.68-0.847-0.885s-0.746-0.308-1.172-0.308s-0.815,0.101-1.167,0.302
                                    c-0.351,0.2-0.631,0.493-0.84,0.878c-0.209,0.385-0.313,0.837-0.313,1.355c0,0.526,0.104,0.984,0.313,1.374
                                    c0.208,0.389,0.489,0.686,0.84,0.891s0.74,0.308,1.167,0.308s0.817-0.103,1.172-0.308s0.637-0.502,0.847-0.891
                                    c0.208-0.39,0.313-0.843,0.313-1.361S680.428,200.457,680.219,200.072z"/>
                                <path fill="#A00D0D" d="M685.367,204.552c-0.522-0.293-0.932-0.709-1.229-1.249c-0.297-0.539-0.445-1.164-0.445-1.875
                                    c0-0.703,0.152-1.324,0.458-1.863c0.305-0.54,0.721-0.954,1.248-1.242c0.527-0.289,1.116-0.434,1.768-0.434
                                    c0.652,0,1.242,0.145,1.768,0.434c0.527,0.288,0.942,0.7,1.248,1.235c0.305,0.535,0.458,1.159,0.458,1.87
                                    s-0.157,1.336-0.47,1.875c-0.313,0.54-0.738,0.956-1.273,1.249s-1.128,0.439-1.781,0.439
                                    C686.472,204.991,685.889,204.845,685.367,204.552z M688.27,203.698c0.359-0.192,0.65-0.48,0.872-0.865
                                    c0.221-0.385,0.332-0.854,0.332-1.405c0-0.553-0.108-1.021-0.326-1.405c-0.218-0.385-0.501-0.672-0.853-0.86
                                    c-0.351-0.188-0.731-0.282-1.141-0.282c-0.418,0-0.8,0.095-1.147,0.282c-0.347,0.188-0.625,0.476-0.834,0.86
                                    c-0.209,0.385-0.313,0.853-0.313,1.405c0,0.561,0.102,1.033,0.307,1.418c0.205,0.385,0.479,0.671,0.821,0.859
                                    c0.343,0.188,0.719,0.282,1.128,0.282S687.91,203.891,688.27,203.698z"/>
                                <path fill="#A00D0D" d="M648.185,209.553l-4.015,11.325h-3.45l-4.015-11.325h2.935l2.805,8.551l2.821-8.551H648.185z"/>
                                <path fill="#A00D0D" d="M649.531,210.513c-0.306-0.285-0.459-0.637-0.459-1.057c0-0.43,0.153-0.788,0.459-1.072
                                    c0.306-0.285,0.701-0.428,1.185-0.428c0.473,0,0.862,0.143,1.169,0.428c0.306,0.284,0.459,0.643,0.459,1.072
                                    c0,0.42-0.153,0.771-0.459,1.057c-0.307,0.285-0.696,0.428-1.169,0.428C650.232,210.94,649.837,210.798,649.531,210.513z
                                    M652.086,211.876v9.002h-2.757v-9.002H652.086z"/>
                                <path fill="#A00D0D" d="M658.051,212.207c0.483-0.285,1.021-0.428,1.612-0.428v2.92h-0.758c-0.688,0-1.204,0.147-1.548,0.443
                                    c-0.344,0.296-0.516,0.815-0.516,1.557v4.179h-2.757v-9.002h2.757v1.5C657.165,212.882,657.567,212.492,658.051,212.207z"/>
                                <path fill="#A00D0D" d="M661.187,210.513c-0.306-0.285-0.459-0.637-0.459-1.057c0-0.43,0.153-0.788,0.459-1.072
                                    c0.306-0.285,0.701-0.428,1.185-0.428c0.473,0,0.862,0.143,1.169,0.428c0.306,0.284,0.459,0.643,0.459,1.072
                                    c0,0.42-0.153,0.771-0.459,1.057c-0.307,0.285-0.696,0.428-1.169,0.428C661.888,210.94,661.493,210.798,661.187,210.513z
                                    M663.742,211.876v9.002h-2.757v-9.002H663.742z"/>
                                <path fill="#A00D0D" d="M665.718,213.925c0.349-0.699,0.825-1.236,1.427-1.613c0.602-0.376,1.273-0.564,2.015-0.564
                                    c0.634,0,1.19,0.129,1.669,0.387c0.478,0.259,0.846,0.598,1.104,1.017v-1.274h2.757v9.002h-2.757v-1.274
                                    c-0.269,0.419-0.643,0.758-1.121,1.017c-0.479,0.258-1.035,0.387-1.669,0.387c-0.731,0-1.397-0.19-1.999-0.572
                                    c-0.602-0.382-1.078-0.925-1.427-1.63c-0.35-0.704-0.524-1.519-0.524-2.444C665.194,215.436,665.368,214.624,665.718,213.925z
                                    M671.36,214.748c-0.381-0.398-0.846-0.598-1.395-0.598c-0.548,0-1.013,0.196-1.395,0.589c-0.382,0.393-0.572,0.934-0.572,1.621
                                    c0,0.688,0.19,1.234,0.572,1.638c0.381,0.403,0.847,0.605,1.395,0.605c0.548,0,1.013-0.199,1.395-0.597
                                    c0.381-0.398,0.573-0.941,0.573-1.63S671.742,215.146,671.36,214.748z"/>
                                <path fill="#A00D0D" d="M681.703,218.539v2.339H680.3c-1,0-1.779-0.244-2.337-0.734c-0.559-0.489-0.838-1.287-0.838-2.396
                                    v-3.581h-1.097v-2.291h1.097v-2.194h2.757v2.194h1.806v2.291h-1.806v3.613c0,0.27,0.064,0.463,0.193,0.581
                                    s0.344,0.178,0.645,0.178H681.703z"/>
                                <path fill="#A00D0D" d="M684.952,220.442c-0.704-0.376-1.258-0.914-1.661-1.613c-0.403-0.699-0.604-1.517-0.604-2.452
                                    c0-0.925,0.204-1.739,0.612-2.444c0.409-0.704,0.967-1.244,1.677-1.621c0.709-0.376,1.505-0.564,2.386-0.564
                                    s1.677,0.188,2.386,0.564c0.709,0.377,1.268,0.917,1.677,1.621c0.408,0.705,0.613,1.52,0.613,2.444s-0.207,1.74-0.621,2.444
                                    c-0.414,0.704-0.978,1.245-1.693,1.621c-0.715,0.377-1.513,0.564-2.394,0.564S685.655,220.819,684.952,220.442z
                                    M688.676,218.039c0.371-0.388,0.556-0.941,0.556-1.662s-0.18-1.274-0.54-1.662c-0.36-0.387-0.804-0.58-1.33-0.58
                                    c-0.538,0-0.983,0.19-1.338,0.572c-0.354,0.382-0.532,0.938-0.532,1.67c0,0.721,0.174,1.274,0.524,1.662
                                    c0.349,0.387,0.787,0.58,1.314,0.58C687.856,218.619,688.305,218.426,688.676,218.039z"/>
                            </g>
                        </g>
                    </g>
                    <g data-tooltip-content="10" data-tooltip-id="svgTooltipPistas">
                        <path fill="#E2E2E2" d="M820.77,492.466c-11.811-24.565-15.148-46.235-13.229-85.878c1.893-39.126-10.599-62.837-25.063-90.29
                            c-6.393-12.135-13.003-24.682-19.008-39.692c-19.21-48.025-14.865-73.251-8.934-89.862l-0.843-0.301l-5.64,6.326
                            c-4.63,17.303-5.767,42.703,11.339,85.468c6.09,15.224,12.754,27.874,19.2,40.108c14.164,26.885,26.396,50.103,24.562,88.03
                            c-1.959,40.475,1.487,62.679,13.658,87.993c22.562,46.928,87.311,67.511,90.057,68.364l1.304-4.193
                            C907.518,558.335,842.496,537.653,820.77,492.466z"/>
                        <g>
                            <g>
                                <polygon fillRule="evenodd" clipRule="evenodd" fill="#E2E2E2" points="869.856,513.825 879.999,547.165 848.551,537.2 				
                                    "/>
                                <path fill="#FFFFFF" d="M879.999,549.854c-0.271,0-0.546-0.041-0.813-0.125l-31.447-9.965c-0.874-0.277-1.546-0.98-1.784-1.866
                                    c-0.238-0.885-0.008-1.831,0.609-2.509l21.305-23.375c0.651-0.715,1.639-1.021,2.579-0.812c0.943,0.213,1.699,0.916,1.98,1.841
                                    l10.143,33.34c0.291,0.955,0.028,1.992-0.682,2.694C881.377,549.582,880.696,549.854,879.999,549.854L879.999,549.854z
                                    M853.369,535.906l22.57,7.151l-7.279-23.928L853.369,535.906L853.369,535.906z"/>
                            </g>
                            <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M883.022,504.173c0,23.26-18.863,42.125-42.124,42.125
                                c-23.258,0-42.124-18.865-42.124-42.125c0-23.256,18.866-42.123,42.124-42.123C864.159,462.05,883.022,480.917,883.022,504.173z"
                                />
                            <g>
                                <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M876.748,504.173c0,19.795-16.055,35.85-35.85,35.85
                                    s-35.851-16.055-35.851-35.85s16.056-35.85,35.851-35.85S876.748,484.378,876.748,504.173z"/>
                                <path fill="#E2E2E2" d="M840.898,541.367c-20.509,0-37.195-16.686-37.195-37.194s16.686-37.194,37.195-37.194
                                    c20.509,0,37.194,16.686,37.194,37.194S861.407,541.367,840.898,541.367L840.898,541.367z M840.898,469.668
                                    c-19.027,0-34.506,15.479-34.506,34.505s15.479,34.505,34.506,34.505c19.026,0,34.505-15.479,34.505-34.505
                                    S859.924,469.668,840.898,469.668L840.898,469.668z"/>
                            </g>
                            <g>
                                <path d="M820.814,496.387c-0.498,0.481-1.256,0.722-2.276,0.722h-1.681v3.651h-1.141v-8.745h2.822
                                    c0.986,0,1.737,0.238,2.251,0.715s0.771,1.092,0.771,1.845C821.56,495.302,821.311,495.906,820.814,496.387z M819.942,495.753
                                    c0.301-0.275,0.452-0.669,0.452-1.179c0-1.079-0.619-1.619-1.856-1.619h-1.681v3.212h1.681
                                    C819.173,496.167,819.642,496.029,819.942,495.753z"/>
                                <path d="M823.517,492.541c-0.151-0.15-0.226-0.334-0.226-0.552s0.075-0.401,0.226-0.553c0.15-0.15,0.334-0.226,0.552-0.226
                                    c0.208,0,0.386,0.075,0.533,0.226c0.146,0.151,0.219,0.335,0.219,0.553s-0.073,0.401-0.219,0.552
                                    c-0.146,0.15-0.324,0.226-0.533,0.226C823.851,492.767,823.667,492.691,823.517,492.541z M824.62,493.884v6.876h-1.141v-6.876
                                    H824.62z"/>
                                <path d="M828.081,500.603c-0.418-0.18-0.749-0.431-0.991-0.753c-0.243-0.321-0.376-0.691-0.401-1.11h1.179
                                    c0.033,0.344,0.194,0.624,0.483,0.841c0.289,0.218,0.667,0.326,1.135,0.326c0.435,0,0.777-0.096,1.028-0.288
                                    s0.376-0.436,0.376-0.728c0-0.302-0.134-0.525-0.401-0.672c-0.268-0.146-0.682-0.29-1.242-0.433
                                    c-0.51-0.134-0.926-0.27-1.248-0.408c-0.322-0.138-0.598-0.343-0.828-0.614c-0.23-0.272-0.345-0.63-0.345-1.073
                                    c0-0.352,0.104-0.673,0.313-0.966c0.208-0.293,0.505-0.525,0.89-0.696c0.384-0.172,0.823-0.258,1.317-0.258
                                    c0.76,0,1.375,0.192,1.843,0.577c0.468,0.385,0.719,0.912,0.752,1.581h-1.141c-0.025-0.359-0.169-0.647-0.433-0.865
                                    c-0.264-0.218-0.617-0.326-1.06-0.326c-0.41,0-0.735,0.088-0.978,0.263c-0.242,0.176-0.363,0.406-0.363,0.69
                                    c0,0.226,0.073,0.412,0.219,0.559c0.146,0.146,0.33,0.264,0.552,0.352c0.221,0.088,0.529,0.186,0.922,0.295
                                    c0.493,0.134,0.894,0.266,1.204,0.395c0.309,0.13,0.575,0.324,0.796,0.584c0.221,0.259,0.336,0.598,0.345,1.017
                                    c0,0.376-0.104,0.715-0.313,1.016c-0.209,0.302-0.504,0.538-0.884,0.709c-0.38,0.172-0.817,0.258-1.31,0.258
                                    C828.971,500.873,828.499,500.782,828.081,500.603z"/>
                                <path d="M835.504,494.825v4.053c0,0.334,0.071,0.57,0.213,0.709c0.142,0.138,0.389,0.207,0.739,0.207h0.841v0.966h-1.028
                                    c-0.636,0-1.112-0.146-1.43-0.439c-0.318-0.292-0.477-0.773-0.477-1.442v-4.053h-0.89v-0.941h0.89v-1.731h1.141v1.731h1.793
                                    v0.941H835.504z"/>
                                <path d="M839.178,495.446c0.284-0.531,0.675-0.943,1.172-1.236c0.498-0.293,1.052-0.439,1.662-0.439
                                    c0.602,0,1.124,0.13,1.567,0.39c0.442,0.259,0.773,0.585,0.99,0.979v-1.255h1.154v6.876h-1.154v-1.28
                                    c-0.226,0.402-0.563,0.734-1.009,0.998c-0.447,0.264-0.968,0.396-1.562,0.396c-0.61,0-1.162-0.151-1.655-0.452
                                    s-0.882-0.724-1.166-1.268c-0.284-0.543-0.427-1.162-0.427-1.856C838.751,496.594,838.894,495.978,839.178,495.446z
                                    M844.256,495.954c-0.209-0.385-0.491-0.68-0.847-0.885c-0.354-0.205-0.746-0.308-1.172-0.308c-0.427,0-0.815,0.101-1.166,0.302
                                    c-0.352,0.2-0.632,0.493-0.841,0.878s-0.313,0.837-0.313,1.355c0,0.526,0.104,0.984,0.313,1.374
                                    c0.209,0.389,0.489,0.686,0.841,0.891c0.351,0.205,0.739,0.308,1.166,0.308c0.426,0,0.817-0.103,1.172-0.308
                                    c0.355-0.205,0.638-0.502,0.847-0.891c0.209-0.39,0.313-0.843,0.313-1.361S844.465,496.339,844.256,495.954z"/>
                                <path d="M852.006,495.446c0.284-0.531,0.675-0.943,1.172-1.236c0.498-0.293,1.056-0.439,1.675-0.439
                                    c0.534,0,1.032,0.124,1.492,0.37c0.459,0.247,0.811,0.571,1.053,0.973v-3.639h1.154v9.285h-1.154v-1.292
                                    c-0.226,0.409-0.56,0.746-1.003,1.01s-0.962,0.396-1.555,0.396c-0.61,0-1.164-0.151-1.662-0.452
                                    c-0.497-0.301-0.888-0.724-1.172-1.268c-0.284-0.543-0.427-1.162-0.427-1.856C851.58,496.594,851.722,495.978,852.006,495.446z
                                    M857.084,495.954c-0.209-0.385-0.491-0.68-0.847-0.885c-0.354-0.205-0.746-0.308-1.172-0.308c-0.427,0-0.815,0.101-1.166,0.302
                                    c-0.352,0.2-0.632,0.493-0.841,0.878s-0.313,0.837-0.313,1.355c0,0.526,0.104,0.984,0.313,1.374
                                    c0.209,0.389,0.489,0.686,0.841,0.891c0.351,0.205,0.739,0.308,1.166,0.308c0.426,0,0.817-0.103,1.172-0.308
                                    c0.355-0.205,0.638-0.502,0.847-0.891c0.209-0.39,0.313-0.843,0.313-1.361S857.293,496.339,857.084,495.954z"/>
                                <path d="M860.985,495.446c0.284-0.531,0.675-0.943,1.172-1.236c0.498-0.293,1.052-0.439,1.662-0.439
                                    c0.602,0,1.124,0.13,1.567,0.39c0.442,0.259,0.773,0.585,0.99,0.979v-1.255h1.154v6.876h-1.154v-1.28
                                    c-0.226,0.402-0.563,0.734-1.009,0.998c-0.447,0.264-0.968,0.396-1.562,0.396c-0.61,0-1.162-0.151-1.655-0.452
                                    s-0.882-0.724-1.166-1.268c-0.284-0.543-0.427-1.162-0.427-1.856C860.558,496.594,860.701,495.978,860.985,495.446z
                                    M866.063,495.954c-0.209-0.385-0.491-0.68-0.847-0.885c-0.354-0.205-0.746-0.308-1.172-0.308c-0.427,0-0.815,0.101-1.166,0.302
                                    c-0.352,0.2-0.632,0.493-0.841,0.878s-0.313,0.837-0.313,1.355c0,0.526,0.104,0.984,0.313,1.374
                                    c0.209,0.389,0.489,0.686,0.841,0.891c0.351,0.205,0.739,0.308,1.166,0.308c0.426,0,0.817-0.103,1.172-0.308
                                    c0.355-0.205,0.638-0.502,0.847-0.891c0.209-0.39,0.313-0.843,0.313-1.361S866.272,496.339,866.063,495.954z"/>
                                <path d="M825.904,510.88c-0.301,0.543-0.763,0.981-1.387,1.314s-1.397,0.5-2.322,0.5h-1.709v4.065h-2.757v-11.325h4.466
                                    c0.903,0,1.666,0.156,2.29,0.468c0.624,0.313,1.091,0.742,1.403,1.291c0.312,0.549,0.467,1.178,0.467,1.888
                                    C826.355,509.737,826.205,510.337,825.904,510.88z M823.164,510.129c0.258-0.247,0.387-0.597,0.387-1.048
                                    c0-0.452-0.129-0.802-0.387-1.049c-0.258-0.247-0.65-0.371-1.177-0.371h-1.5v2.839h1.5
                                    C822.513,510.5,822.905,510.377,823.164,510.129z"/>
                                <path d="M836.062,512.92h-6.239c0.042,0.56,0.223,0.987,0.54,1.283c0.317,0.296,0.707,0.443,1.169,0.443
                                    c0.688,0,1.166-0.29,1.435-0.871h2.934c-0.151,0.592-0.422,1.124-0.814,1.597c-0.393,0.474-0.884,0.845-1.476,1.113
                                    c-0.591,0.27-1.252,0.403-1.983,0.403c-0.881,0-1.666-0.188-2.354-0.564c-0.688-0.376-1.225-0.914-1.612-1.613
                                    c-0.387-0.699-0.58-1.517-0.58-2.452s0.19-1.753,0.572-2.452c0.381-0.699,0.916-1.236,1.604-1.613
                                    c0.688-0.376,1.478-0.564,2.37-0.564c0.871,0,1.645,0.183,2.322,0.549c0.677,0.365,1.206,0.887,1.588,1.564
                                    c0.381,0.678,0.572,1.468,0.572,2.371C836.11,512.372,836.094,512.641,836.062,512.92z M833.289,511.388
                                    c0-0.474-0.161-0.85-0.484-1.129c-0.322-0.28-0.726-0.42-1.209-0.42c-0.462,0-0.852,0.135-1.169,0.403
                                    c-0.317,0.269-0.513,0.651-0.588,1.146H833.289z"/>
                                <path d="M841.366,508.089c0.483-0.285,1.021-0.428,1.612-0.428v2.92h-0.758c-0.688,0-1.204,0.147-1.548,0.443
                                    s-0.516,0.815-0.516,1.557v4.179H837.4v-9.002h2.757v1.5C840.479,508.764,840.882,508.374,841.366,508.089z"/>
                                <path d="M844.115,509.807c0.349-0.699,0.824-1.236,1.427-1.613c0.602-0.376,1.273-0.564,2.016-0.564
                                    c0.591,0,1.131,0.124,1.62,0.371s0.873,0.581,1.152,1v-4.179h2.757v11.938h-2.757v-1.291c-0.258,0.431-0.626,0.774-1.104,1.033
                                    c-0.479,0.258-1.034,0.387-1.668,0.387c-0.742,0-1.414-0.19-2.016-0.572c-0.603-0.382-1.078-0.925-1.427-1.63
                                    c-0.35-0.704-0.524-1.519-0.524-2.444C843.59,511.317,843.765,510.506,844.115,509.807z M849.757,510.63
                                    c-0.382-0.398-0.847-0.598-1.395-0.598s-1.013,0.196-1.395,0.589s-0.572,0.934-0.572,1.621c0,0.688,0.19,1.234,0.572,1.638
                                    s0.847,0.605,1.395,0.605s1.013-0.199,1.395-0.597c0.382-0.398,0.572-0.941,0.572-1.63S850.139,511.027,849.757,510.63z"/>
                                <path d="M855.126,506.395c-0.307-0.285-0.46-0.637-0.46-1.057c0-0.43,0.153-0.788,0.46-1.072
                                    c0.307-0.285,0.701-0.428,1.185-0.428c0.474,0,0.863,0.143,1.169,0.428c0.307,0.284,0.46,0.643,0.46,1.072
                                    c0,0.42-0.153,0.771-0.46,1.057c-0.306,0.285-0.695,0.428-1.169,0.428C855.828,506.822,855.433,506.68,855.126,506.395z
                                    M857.682,507.758v9.002h-2.757v-9.002H857.682z"/>
                                <path d="M862.099,514.485H866v2.274h-6.98v-2.194l3.74-4.533h-3.708v-2.274h6.868v2.194L862.099,514.485z"/>
                            </g>
                        </g>
                    </g>
                    <g data-tooltip-content="2" data-tooltip-id="svgTooltipPistas">
                        <path fill="#A00D0D" d="M1252.223,644.473c-58.702-28.151-71.074-63.582-84.174-101.094
                            c-13.018-37.278-26.479-75.825-89.185-109.267c-78.282-41.75-139.568-80.519-187.361-118.52
                            c-67.984-54.057-107.928-106.469-118.723-155.778l4.289-0.939c18.23,83.274,117.624,172.038,303.861,271.362
                            c64.219,34.249,77.968,73.62,91.264,111.694c12.79,36.625,24.87,71.22,81.927,98.582L1252.223,644.473L1252.223,644.473z"/>
                        <g>
                            <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M1140.048,478.983c0,23.258-18.862,42.119-42.123,42.119
                                c-23.258,0-42.124-18.861-42.124-42.119c0-23.262,18.866-42.123,42.124-42.123
                                C1121.186,436.86,1140.048,455.722,1140.048,478.983z"/>
                            <g>
                                <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M1133.775,478.983c0,19.795-16.055,35.85-35.85,35.85
                                    c-19.794,0-35.85-16.055-35.85-35.85s16.056-35.85,35.85-35.85C1117.72,443.134,1133.775,459.188,1133.775,478.983z"/>
                                <path fill="#E5C4C8" d="M1097.925,516.178c-20.509,0-37.194-16.686-37.194-37.194s16.686-37.194,37.194-37.194
                                    s37.194,16.686,37.194,37.194S1118.434,516.178,1097.925,516.178L1097.925,516.178z M1097.925,444.479
                                    c-19.026,0-34.505,15.479-34.505,34.505s15.479,34.505,34.505,34.505s34.505-15.479,34.505-34.505
                                    S1116.952,444.479,1097.925,444.479L1097.925,444.479z"/>
                            </g>
                            <g>
                                <path fill="#A00D0D" d="M1079.445,472c-0.498,0.481-1.257,0.722-2.276,0.722h-1.681v3.651h-1.141v-8.745h2.821
                                    c0.986,0,1.736,0.238,2.251,0.715c0.514,0.477,0.771,1.092,0.771,1.845C1080.191,470.915,1079.942,471.52,1079.445,472z
                                    M1078.573,471.366c0.301-0.275,0.451-0.669,0.451-1.179c0-1.079-0.618-1.619-1.855-1.619h-1.681v3.212h1.681
                                    C1077.803,471.78,1078.272,471.643,1078.573,471.366z"/>
                                <path fill="#A00D0D" d="M1081.645,468.154c-0.15-0.15-0.226-0.334-0.226-0.552s0.075-0.401,0.226-0.553
                                    c0.15-0.15,0.335-0.226,0.552-0.226c0.209,0,0.387,0.075,0.533,0.226c0.146,0.151,0.22,0.335,0.22,0.553
                                    s-0.073,0.401-0.22,0.552s-0.324,0.226-0.533,0.226C1081.98,468.38,1081.795,468.305,1081.645,468.154z M1082.749,469.497v6.876
                                    h-1.141v-6.876H1082.749z"/>
                                <path fill="#A00D0D" d="M1085.708,476.216c-0.418-0.18-0.748-0.431-0.991-0.753c-0.242-0.321-0.376-0.691-0.4-1.11h1.179
                                    c0.033,0.344,0.194,0.624,0.482,0.841c0.288,0.218,0.666,0.326,1.135,0.326c0.435,0,0.777-0.096,1.028-0.288
                                    s0.376-0.436,0.376-0.728c0-0.302-0.134-0.525-0.401-0.672s-0.682-0.29-1.241-0.433c-0.51-0.134-0.926-0.27-1.248-0.408
                                    c-0.321-0.138-0.598-0.343-0.827-0.614c-0.23-0.272-0.345-0.63-0.345-1.073c0-0.352,0.104-0.673,0.313-0.966
                                    s0.506-0.525,0.89-0.696c0.385-0.172,0.824-0.258,1.317-0.258c0.761,0,1.375,0.192,1.843,0.577
                                    c0.469,0.385,0.719,0.912,0.753,1.581h-1.142c-0.024-0.359-0.169-0.647-0.433-0.865c-0.263-0.218-0.616-0.326-1.06-0.326
                                    c-0.409,0-0.735,0.088-0.978,0.263c-0.243,0.176-0.364,0.406-0.364,0.69c0,0.226,0.073,0.412,0.22,0.559s0.33,0.264,0.552,0.352
                                    s0.528,0.186,0.922,0.295c0.493,0.134,0.895,0.266,1.203,0.395c0.31,0.13,0.575,0.324,0.797,0.584
                                    c0.222,0.259,0.336,0.598,0.345,1.017c0,0.376-0.104,0.715-0.313,1.016c-0.209,0.302-0.504,0.538-0.884,0.709
                                    c-0.381,0.172-0.817,0.258-1.311,0.258C1086.598,476.486,1086.125,476.396,1085.708,476.216z"/>
                                <path fill="#A00D0D" d="M1092.629,470.438v4.053c0,0.334,0.071,0.57,0.213,0.709c0.143,0.138,0.389,0.207,0.74,0.207h0.84v0.966
                                    h-1.028c-0.635,0-1.111-0.146-1.429-0.439c-0.318-0.292-0.477-0.773-0.477-1.442v-4.053h-0.891v-0.941h0.891v-1.731h1.141v1.731
                                    h1.793v0.941H1092.629z"/>
                                <path fill="#A00D0D" d="M1095.802,471.06c0.284-0.531,0.675-0.943,1.172-1.236c0.498-0.293,1.052-0.439,1.662-0.439
                                    c0.602,0,1.124,0.13,1.567,0.39c0.442,0.259,0.773,0.585,0.99,0.979v-1.255h1.154v6.876h-1.154v-1.28
                                    c-0.226,0.402-0.563,0.734-1.009,0.998c-0.447,0.264-0.968,0.396-1.562,0.396c-0.61,0-1.162-0.151-1.655-0.452
                                    s-0.882-0.724-1.166-1.268c-0.284-0.543-0.427-1.162-0.427-1.856C1095.375,472.207,1095.518,471.591,1095.802,471.06z
                                    M1100.88,471.567c-0.209-0.385-0.491-0.68-0.846-0.885c-0.355-0.205-0.747-0.308-1.173-0.308c-0.427,0-0.815,0.101-1.166,0.302
                                    c-0.352,0.2-0.632,0.493-0.841,0.878s-0.313,0.837-0.313,1.355c0,0.526,0.104,0.984,0.313,1.374
                                    c0.209,0.389,0.489,0.686,0.841,0.891c0.351,0.205,0.739,0.308,1.166,0.308c0.426,0,0.817-0.103,1.173-0.308
                                    c0.354-0.205,0.637-0.502,0.846-0.891c0.209-0.39,0.313-0.843,0.313-1.361S1101.089,471.952,1100.88,471.567z"/>
                                <path fill="#A00D0D" d="M1107.627,471.06c0.284-0.531,0.675-0.943,1.172-1.236c0.498-0.293,1.056-0.439,1.675-0.439
                                    c0.534,0,1.032,0.124,1.492,0.37c0.459,0.247,0.811,0.571,1.053,0.973v-3.639h1.154v9.285h-1.154v-1.292
                                    c-0.226,0.409-0.56,0.746-1.003,1.01s-0.962,0.396-1.555,0.396c-0.61,0-1.164-0.151-1.662-0.452
                                    c-0.497-0.301-0.888-0.724-1.172-1.268c-0.284-0.543-0.427-1.162-0.427-1.856
                                    C1107.201,472.207,1107.343,471.591,1107.627,471.06z M1112.706,471.567c-0.209-0.385-0.491-0.68-0.847-0.885
                                    c-0.354-0.205-0.746-0.308-1.172-0.308c-0.427,0-0.815,0.101-1.166,0.302c-0.352,0.2-0.632,0.493-0.841,0.878
                                    s-0.313,0.837-0.313,1.355c0,0.526,0.104,0.984,0.313,1.374c0.209,0.389,0.489,0.686,0.841,0.891
                                    c0.351,0.205,0.739,0.308,1.166,0.308c0.426,0,0.817-0.103,1.172-0.308c0.355-0.205,0.638-0.502,0.847-0.891
                                    c0.209-0.39,0.313-0.843,0.313-1.361S1112.915,471.952,1112.706,471.567z"/>
                                <path fill="#A00D0D" d="M1116.104,471.06c0.284-0.531,0.675-0.943,1.172-1.236c0.498-0.293,1.052-0.439,1.662-0.439
                                    c0.602,0,1.124,0.13,1.567,0.39c0.442,0.259,0.773,0.585,0.99,0.979v-1.255h1.154v6.876h-1.154v-1.28
                                    c-0.226,0.402-0.563,0.734-1.009,0.998c-0.447,0.264-0.968,0.396-1.562,0.396c-0.61,0-1.162-0.151-1.655-0.452
                                    s-0.882-0.724-1.166-1.268c-0.284-0.543-0.427-1.162-0.427-1.856C1115.677,472.207,1115.82,471.591,1116.104,471.06z
                                    M1121.182,471.567c-0.209-0.385-0.491-0.68-0.847-0.885c-0.354-0.205-0.746-0.308-1.172-0.308
                                    c-0.427,0-0.815,0.101-1.166,0.302c-0.352,0.2-0.632,0.493-0.841,0.878s-0.313,0.837-0.313,1.355
                                    c0,0.526,0.104,0.984,0.313,1.374c0.209,0.389,0.489,0.686,0.841,0.891c0.351,0.205,0.739,0.308,1.166,0.308
                                    c0.426,0,0.817-0.103,1.172-0.308c0.355-0.205,0.638-0.502,0.847-0.891c0.209-0.39,0.313-0.843,0.313-1.361
                                    S1121.391,471.952,1121.182,471.567z"/>
                                <path fill="#A00D0D" d="M1075.585,483.898v2.13h3.433v1.979h-3.433v2.311h3.883v2.055h-6.446v-10.53h6.446v2.056H1075.585z"/>
                                <path fill="#A00D0D" d="M1082.585,492.118c-0.57-0.25-1.02-0.593-1.35-1.027c-0.33-0.436-0.515-0.923-0.555-1.463h2.533
                                    c0.03,0.29,0.165,0.525,0.405,0.705s0.534,0.271,0.885,0.271c0.319,0,0.566-0.063,0.741-0.188s0.263-0.288,0.263-0.488
                                    c0-0.239-0.125-0.417-0.375-0.532s-0.654-0.242-1.214-0.383c-0.6-0.14-1.1-0.287-1.499-0.442
                                    c-0.4-0.154-0.745-0.399-1.034-0.734c-0.29-0.335-0.435-0.788-0.435-1.357c0-0.48,0.132-0.918,0.396-1.313
                                    c0.265-0.396,0.654-0.708,1.17-0.938c0.515-0.23,1.126-0.346,1.836-0.346c1.05,0,1.876,0.261,2.481,0.78
                                    c0.604,0.521,0.951,1.21,1.042,2.07h-2.369c-0.04-0.29-0.167-0.521-0.382-0.69c-0.216-0.17-0.498-0.255-0.848-0.255
                                    c-0.3,0-0.529,0.058-0.689,0.173c-0.16,0.114-0.239,0.272-0.239,0.472c0,0.24,0.127,0.421,0.382,0.54
                                    c0.255,0.12,0.652,0.24,1.191,0.36c0.62,0.16,1.125,0.317,1.515,0.473s0.731,0.405,1.026,0.75s0.447,0.808,0.458,1.388
                                    c0,0.49-0.138,0.928-0.413,1.313c-0.274,0.385-0.669,0.688-1.184,0.907s-1.112,0.33-1.792,0.33
                                    C1083.804,492.493,1083.155,492.368,1082.585,492.118z"/>
                                <path fill="#A00D0D" d="M1094.159,490.198v2.175h-1.305c-0.929,0-1.654-0.228-2.174-0.683s-0.779-1.197-0.779-2.228v-3.33h-1.02
                                    v-2.13h1.02v-2.04h2.563v2.04h1.679v2.13h-1.679v3.36c0,0.25,0.061,0.43,0.18,0.54c0.12,0.11,0.32,0.165,0.6,0.165H1094.159z"/>
                                <path fill="#A00D0D" d="M1099.27,484.311c0.45-0.265,0.949-0.397,1.499-0.397v2.715h-0.704c-0.64,0-1.12,0.138-1.439,0.413
                                    c-0.32,0.274-0.479,0.757-0.479,1.447v3.885h-2.563v-8.37h2.563v1.396C1098.446,484.938,1098.82,484.575,1099.27,484.311z"/>
                                <path fill="#A00D0D" d="M1109.838,488.803h-5.802c0.04,0.521,0.208,0.918,0.503,1.192c0.294,0.275,0.656,0.413,1.087,0.413
                                    c0.639,0,1.084-0.271,1.334-0.811h2.729c-0.141,0.551-0.393,1.046-0.758,1.485c-0.364,0.44-0.821,0.785-1.371,1.035
                                    s-1.164,0.375-1.844,0.375c-0.82,0-1.55-0.175-2.188-0.525c-0.641-0.35-1.14-0.85-1.5-1.5c-0.359-0.649-0.539-1.41-0.539-2.279
                                    c0-0.87,0.177-1.63,0.532-2.28c0.354-0.65,0.852-1.15,1.491-1.5s1.374-0.525,2.204-0.525c0.81,0,1.528,0.17,2.158,0.51
                                    c0.63,0.341,1.122,0.825,1.477,1.455c0.354,0.631,0.532,1.365,0.532,2.205C1109.883,488.293,1109.869,488.543,1109.838,488.803z
                                    M1107.26,487.378c0-0.439-0.15-0.79-0.45-1.05s-0.674-0.39-1.124-0.39c-0.43,0-0.792,0.125-1.087,0.375
                                    s-0.478,0.604-0.547,1.064H1107.26z"/>
                                <path fill="#A00D0D" d="M1113.796,481.273v11.1h-2.563v-11.1H1113.796z"/>
                                <path fill="#A00D0D" d="M1115.633,485.908c0.324-0.65,0.767-1.15,1.326-1.5s1.185-0.525,1.874-0.525
                                    c0.59,0,1.106,0.12,1.552,0.36c0.444,0.24,0.787,0.555,1.026,0.945v-1.186h2.563v8.37h-2.563v-1.185
                                    c-0.25,0.39-0.597,0.704-1.041,0.944c-0.445,0.24-0.963,0.36-1.552,0.36c-0.68,0-1.3-0.178-1.859-0.532
                                    c-0.56-0.355-1.002-0.86-1.326-1.516c-0.325-0.654-0.487-1.412-0.487-2.272C1115.146,487.313,1115.308,486.559,1115.633,485.908
                                    z M1120.879,486.673c-0.354-0.37-0.786-0.555-1.296-0.555s-0.942,0.183-1.297,0.548c-0.355,0.364-0.532,0.867-0.532,1.507
                                    c0,0.641,0.177,1.147,0.532,1.522c0.354,0.375,0.787,0.563,1.297,0.563s0.941-0.185,1.296-0.555
                                    c0.355-0.37,0.532-0.875,0.532-1.515C1121.412,487.548,1121.235,487.043,1120.879,486.673z"/>
                            </g>
                        </g>
                    </g>
                    <g data-tooltip-content="13" data-tooltip-id="svgTooltipPistas">
                        <path fill="#E2E2E2" d="M927.976,417.611c-0.702-5.928-3.868-18.987-5.276-24.526c-7.644-23.749-22.278-43.833-44.307-60.934
                            c-50.716-39.371-75.075-80.189-86.58-107.499c-7.939-18.846-11.014-34.014-12.204-42.603c-0.745-1.922-1.441-3.839-2.101-5.752
                            c-0.257,6.346-0.281,12.494-0.056,18.43c0.008,0.035,0.015,0.068,0.023,0.103c1.916,8.403,5.053,19.05,10.189,31.285
                            c11.694,27.856,36.46,69.467,88.035,109.505c20.736,16.098,33.369,33.85,40.604,52.448c11.164,36.158,6.413,82.7-0.435,118.571
                            c-0.614,3.216-1.223,6.401-1.811,9.554c-10.01,53.612-19.209,74.713-19.301,74.92l4.013,1.784
                            c0.383-0.858,9.479-21.658,19.604-75.897c0.588-3.147,1.195-6.327,1.808-9.536C925.516,479.525,931.561,447.858,927.976,417.611z"
                            />
                        <g>
                            <g>
                                <polygon fillRule="evenodd" clipRule="evenodd" fill="#E2E2E2" points="898.537,330.989 908.678,364.331 877.231,354.366 				
                                    "/>
                                <path fill="#FFFFFF" d="M908.678,367.02c-0.271,0-0.546-0.042-0.813-0.126l-31.447-9.965c-0.874-0.277-1.546-0.98-1.784-1.865
                                    c-0.238-0.886-0.008-1.831,0.609-2.509l21.306-23.377c0.651-0.715,1.639-1.022,2.579-0.812c0.943,0.213,1.699,0.916,1.98,1.841
                                    l10.142,33.342c0.291,0.955,0.028,1.993-0.682,2.694C910.057,366.749,909.375,367.02,908.678,367.02L908.678,367.02z
                                    M882.048,353.072l22.57,7.152l-7.279-23.93L882.048,353.072L882.048,353.072z"/>
                            </g>
                            <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M909.909,321.337c0,23.262-18.862,42.125-42.123,42.125
                                c-23.258,0-42.124-18.863-42.124-42.125c0-23.256,18.866-42.123,42.124-42.123C891.046,279.214,909.909,298.081,909.909,321.337z
                                "/>
                            <g>
                                <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M903.635,321.337c0,19.795-16.056,35.852-35.85,35.852
                                    c-19.795,0-35.851-16.057-35.851-35.852c0-19.793,16.056-35.848,35.851-35.848C887.58,285.489,903.635,301.544,903.635,321.337z
                                    "/>
                                <path fill="#E2E2E2" d="M867.786,358.533c-20.51,0-37.195-16.686-37.195-37.196c0-20.508,16.685-37.192,37.195-37.192
                                    c20.509,0,37.194,16.684,37.194,37.192C904.98,341.847,888.294,358.533,867.786,358.533L867.786,358.533z M867.786,286.833
                                    c-19.026,0-34.506,15.478-34.506,34.503c0,19.027,15.48,34.507,34.506,34.507s34.505-15.48,34.505-34.507
                                    C902.291,302.312,886.812,286.833,867.786,286.833L867.786,286.833z"/>
                            </g>
                            <g>
                                <path d="M848.936,310.966c-0.498,0.481-1.257,0.722-2.276,0.722h-1.681v3.651h-1.141v-8.745h2.821
                                    c0.986,0,1.736,0.238,2.251,0.715c0.514,0.477,0.771,1.092,0.771,1.845C849.682,309.881,849.433,310.485,848.936,310.966z
                                    M848.064,310.332c0.301-0.275,0.451-0.669,0.451-1.179c0-1.079-0.618-1.619-1.855-1.619h-1.681v3.212h1.681
                                    C847.294,310.746,847.763,310.608,848.064,310.332z"/>
                                <path d="M851.136,307.12c-0.15-0.15-0.226-0.334-0.226-0.552s0.075-0.401,0.226-0.553c0.15-0.15,0.335-0.226,0.552-0.226
                                    c0.209,0,0.387,0.075,0.533,0.226c0.146,0.151,0.22,0.335,0.22,0.553s-0.073,0.401-0.22,0.552s-0.324,0.226-0.533,0.226
                                    C851.471,307.346,851.287,307.271,851.136,307.12z M852.24,308.463v6.876h-1.141v-6.876H852.24z"/>
                                <path d="M855.199,315.182c-0.418-0.18-0.748-0.431-0.991-0.753c-0.242-0.321-0.376-0.691-0.4-1.11h1.179
                                    c0.033,0.344,0.194,0.624,0.482,0.841c0.288,0.218,0.666,0.326,1.135,0.326c0.435,0,0.777-0.096,1.028-0.288
                                    s0.376-0.436,0.376-0.728c0-0.302-0.134-0.525-0.401-0.672s-0.682-0.29-1.241-0.433c-0.51-0.134-0.926-0.27-1.248-0.408
                                    c-0.321-0.138-0.598-0.343-0.827-0.614c-0.23-0.272-0.345-0.63-0.345-1.073c0-0.352,0.104-0.673,0.313-0.966
                                    s0.506-0.525,0.89-0.696c0.385-0.172,0.824-0.258,1.317-0.258c0.761,0,1.375,0.192,1.843,0.577
                                    c0.469,0.385,0.719,0.912,0.753,1.581h-1.142c-0.024-0.359-0.169-0.647-0.433-0.865c-0.263-0.218-0.616-0.326-1.06-0.326
                                    c-0.409,0-0.735,0.088-0.978,0.263c-0.243,0.176-0.364,0.406-0.364,0.69c0,0.226,0.073,0.412,0.22,0.559s0.33,0.264,0.552,0.352
                                    s0.528,0.186,0.922,0.295c0.493,0.134,0.895,0.266,1.203,0.395c0.31,0.13,0.575,0.324,0.797,0.584
                                    c0.222,0.259,0.336,0.598,0.345,1.017c0,0.376-0.104,0.715-0.313,1.016c-0.209,0.302-0.504,0.538-0.884,0.709
                                    c-0.381,0.172-0.817,0.258-1.311,0.258C856.089,315.452,855.617,315.361,855.199,315.182z"/>
                                <path d="M862.121,309.404v4.053c0,0.334,0.071,0.57,0.213,0.709c0.143,0.138,0.389,0.207,0.74,0.207h0.84v0.966h-1.028
                                    c-0.635,0-1.111-0.146-1.429-0.439c-0.318-0.292-0.477-0.773-0.477-1.442v-4.053h-0.891v-0.941h0.891v-1.731h1.141v1.731h1.793
                                    v0.941H862.121z"/>
                                <path d="M865.293,310.025c0.284-0.531,0.675-0.943,1.172-1.236c0.498-0.293,1.052-0.439,1.662-0.439
                                    c0.602,0,1.124,0.13,1.567,0.39c0.442,0.259,0.773,0.585,0.99,0.979v-1.255h1.154v6.876h-1.154v-1.28
                                    c-0.226,0.402-0.563,0.734-1.009,0.998c-0.447,0.264-0.968,0.396-1.562,0.396c-0.61,0-1.162-0.151-1.655-0.452
                                    s-0.882-0.724-1.166-1.268c-0.284-0.543-0.427-1.162-0.427-1.856C864.867,311.173,865.009,310.557,865.293,310.025z
                                    M870.372,310.533c-0.209-0.385-0.491-0.68-0.847-0.885c-0.354-0.205-0.746-0.308-1.172-0.308c-0.427,0-0.815,0.101-1.166,0.302
                                    c-0.352,0.2-0.632,0.493-0.841,0.878s-0.313,0.837-0.313,1.355c0,0.526,0.104,0.984,0.313,1.374
                                    c0.209,0.389,0.489,0.686,0.841,0.891c0.351,0.205,0.739,0.308,1.166,0.308c0.426,0,0.817-0.103,1.172-0.308
                                    c0.355-0.205,0.638-0.502,0.847-0.891c0.209-0.39,0.313-0.843,0.313-1.361S870.581,310.918,870.372,310.533z"/>
                                <path d="M876.162,310.025c0.284-0.531,0.675-0.943,1.172-1.236c0.498-0.293,1.056-0.439,1.675-0.439
                                    c0.534,0,1.032,0.124,1.492,0.37c0.459,0.247,0.811,0.571,1.053,0.973v-3.639h1.154v9.285h-1.154v-1.292
                                    c-0.226,0.409-0.56,0.746-1.003,1.01s-0.962,0.396-1.555,0.396c-0.61,0-1.164-0.151-1.662-0.452
                                    c-0.497-0.301-0.888-0.724-1.172-1.268c-0.284-0.543-0.427-1.162-0.427-1.856C875.735,311.173,875.877,310.557,876.162,310.025z
                                    M881.24,310.533c-0.209-0.385-0.491-0.68-0.846-0.885c-0.355-0.205-0.747-0.308-1.173-0.308c-0.427,0-0.815,0.101-1.166,0.302
                                    c-0.352,0.2-0.632,0.493-0.841,0.878s-0.313,0.837-0.313,1.355c0,0.526,0.104,0.984,0.313,1.374
                                    c0.209,0.389,0.489,0.686,0.841,0.891c0.351,0.205,0.739,0.308,1.166,0.308c0.426,0,0.817-0.103,1.173-0.308
                                    c0.354-0.205,0.637-0.502,0.846-0.891c0.209-0.39,0.313-0.843,0.313-1.361S881.449,310.918,881.24,310.533z"/>
                                <path d="M884.639,310.025c0.284-0.531,0.675-0.943,1.172-1.236c0.498-0.293,1.052-0.439,1.662-0.439
                                    c0.602,0,1.124,0.13,1.567,0.39c0.442,0.259,0.773,0.585,0.99,0.979v-1.255h1.154v6.876h-1.154v-1.28
                                    c-0.226,0.402-0.563,0.734-1.009,0.998c-0.447,0.264-0.968,0.396-1.562,0.396c-0.61,0-1.162-0.151-1.655-0.452
                                    s-0.882-0.724-1.166-1.268c-0.284-0.543-0.427-1.162-0.427-1.856C884.212,311.173,884.355,310.557,884.639,310.025z
                                    M889.717,310.533c-0.209-0.385-0.491-0.68-0.847-0.885c-0.354-0.205-0.746-0.308-1.172-0.308c-0.427,0-0.815,0.101-1.166,0.302
                                    c-0.352,0.2-0.632,0.493-0.841,0.878s-0.313,0.837-0.313,1.355c0,0.526,0.104,0.984,0.313,1.374
                                    c0.209,0.389,0.489,0.686,0.841,0.891c0.351,0.205,0.739,0.308,1.166,0.308c0.426,0,0.817-0.103,1.172-0.308
                                    c0.355-0.205,0.638-0.502,0.847-0.891c0.209-0.39,0.313-0.843,0.313-1.361S889.926,310.918,889.717,310.533z"/>
                                <path d="M842.973,331.339l-2.354-4.275h-0.661v4.275h-2.758v-11.325h4.628c0.892,0,1.652,0.156,2.281,0.468
                                    c0.629,0.313,1.099,0.74,1.411,1.283c0.312,0.543,0.467,1.147,0.467,1.814c0,0.753-0.212,1.425-0.637,2.017
                                    s-1.051,1.011-1.878,1.259l2.611,4.484H842.973z M839.958,325.111h1.709c0.505,0,0.884-0.123,1.137-0.371
                                    c0.252-0.247,0.379-0.597,0.379-1.048c0-0.431-0.127-0.77-0.379-1.017c-0.253-0.247-0.632-0.371-1.137-0.371h-1.709V325.111z"/>
                                <path d="M847.689,324.386c0.349-0.699,0.824-1.236,1.427-1.613c0.602-0.376,1.273-0.564,2.016-0.564
                                    c0.634,0,1.189,0.129,1.668,0.387c0.479,0.259,0.847,0.598,1.104,1.017v-1.274h2.757v9.002h-2.757v-1.274
                                    c-0.269,0.419-0.643,0.758-1.12,1.017c-0.479,0.258-1.035,0.387-1.669,0.387c-0.73,0-1.397-0.19-1.999-0.572
                                    c-0.603-0.382-1.078-0.925-1.427-1.63c-0.35-0.704-0.524-1.519-0.524-2.444C847.165,325.896,847.339,325.085,847.689,324.386z
                                    M853.332,325.209c-0.382-0.398-0.847-0.598-1.395-0.598s-1.013,0.196-1.395,0.589s-0.572,0.934-0.572,1.621
                                    c0,0.688,0.19,1.234,0.572,1.638s0.847,0.605,1.395,0.605s1.013-0.199,1.395-0.597c0.382-0.398,0.572-0.941,0.572-1.63
                                    S853.713,325.606,853.332,325.209z"/>
                                <path d="M862.53,322.595c0.473-0.258,1.026-0.387,1.66-0.387c0.742,0,1.414,0.188,2.016,0.564
                                    c0.602,0.377,1.077,0.914,1.427,1.613s0.524,1.511,0.524,2.436c0,0.926-0.175,1.74-0.524,2.444
                                    c-0.35,0.705-0.825,1.248-1.427,1.63s-1.273,0.572-2.016,0.572c-0.623,0-1.174-0.129-1.652-0.387
                                    c-0.479-0.259-0.852-0.592-1.12-1.001v5.55h-2.757v-13.293h2.757v1.274C861.686,323.192,862.056,322.854,862.53,322.595z
                                    M864.779,325.2c-0.382-0.393-0.852-0.589-1.411-0.589c-0.548,0-1.013,0.199-1.395,0.598c-0.382,0.397-0.572,0.94-0.572,1.629
                                    s0.19,1.231,0.572,1.63c0.382,0.397,0.847,0.597,1.395,0.597c0.549,0,1.016-0.202,1.403-0.605
                                    c0.387-0.403,0.58-0.949,0.58-1.638C865.351,326.134,865.161,325.593,864.779,325.2z"/>
                                <path d="M871.325,330.903c-0.704-0.376-1.258-0.914-1.661-1.613c-0.402-0.699-0.604-1.517-0.604-2.452
                                    c0-0.925,0.204-1.739,0.613-2.444c0.408-0.704,0.967-1.244,1.677-1.621c0.709-0.376,1.504-0.564,2.386-0.564
                                    c0.881,0,1.677,0.188,2.386,0.564c0.71,0.377,1.269,0.917,1.677,1.621c0.409,0.705,0.613,1.52,0.613,2.444
                                    s-0.207,1.74-0.621,2.444s-0.978,1.245-1.692,1.621c-0.715,0.377-1.514,0.564-2.395,0.564
                                    C872.821,331.468,872.029,331.28,871.325,330.903z M875.049,328.5c0.37-0.388,0.556-0.941,0.556-1.662s-0.18-1.274-0.54-1.662
                                    c-0.36-0.387-0.804-0.58-1.33-0.58c-0.537,0-0.983,0.19-1.338,0.572s-0.532,0.938-0.532,1.67c0,0.721,0.175,1.274,0.523,1.662
                                    c0.35,0.387,0.788,0.58,1.314,0.58S874.678,328.887,875.049,328.5z"/>
                                <path d="M881.441,331.064c-0.612-0.269-1.097-0.637-1.451-1.104s-0.554-0.992-0.597-1.573h2.725
                                    c0.032,0.312,0.178,0.564,0.436,0.758c0.258,0.194,0.575,0.291,0.951,0.291c0.344,0,0.61-0.067,0.798-0.202
                                    c0.188-0.134,0.282-0.309,0.282-0.524c0-0.258-0.134-0.448-0.402-0.572c-0.27-0.124-0.704-0.261-1.307-0.411
                                    c-0.645-0.15-1.183-0.31-1.612-0.477c-0.43-0.166-0.801-0.43-1.112-0.79s-0.468-0.847-0.468-1.46
                                    c0-0.517,0.143-0.987,0.428-1.412c0.284-0.425,0.704-0.761,1.258-1.008c0.553-0.247,1.212-0.371,1.975-0.371
                                    c1.129,0,2.018,0.279,2.668,0.839s1.024,1.302,1.121,2.227h-2.548c-0.043-0.313-0.18-0.56-0.411-0.742
                                    c-0.23-0.183-0.534-0.274-0.91-0.274c-0.323,0-0.57,0.062-0.742,0.186s-0.258,0.293-0.258,0.508
                                    c0,0.259,0.137,0.452,0.411,0.581s0.701,0.258,1.282,0.387c0.666,0.173,1.209,0.342,1.628,0.509s0.787,0.436,1.104,0.807
                                    c0.316,0.371,0.48,0.868,0.491,1.492c0,0.527-0.147,0.997-0.443,1.411c-0.295,0.414-0.72,0.74-1.273,0.977
                                    s-1.195,0.354-1.927,0.354C882.752,331.468,882.053,331.334,881.441,331.064z"/>
                                <path d="M888.849,324.386c0.349-0.699,0.824-1.236,1.427-1.613c0.602-0.376,1.273-0.564,2.016-0.564
                                    c0.634,0,1.189,0.129,1.668,0.387c0.479,0.259,0.847,0.598,1.104,1.017v-1.274h2.757v9.002h-2.757v-1.274
                                    c-0.269,0.419-0.643,0.758-1.12,1.017c-0.479,0.258-1.035,0.387-1.669,0.387c-0.73,0-1.397-0.19-1.999-0.572
                                    c-0.603-0.382-1.078-0.925-1.427-1.63c-0.35-0.704-0.524-1.519-0.524-2.444C888.325,325.896,888.5,325.085,888.849,324.386z
                                    M894.492,325.209c-0.382-0.398-0.847-0.598-1.395-0.598s-1.013,0.196-1.395,0.589s-0.572,0.934-0.572,1.621
                                    c0,0.688,0.19,1.234,0.572,1.638s0.847,0.605,1.395,0.605s1.013-0.199,1.395-0.597c0.382-0.398,0.572-0.941,0.572-1.63
                                    S894.874,325.606,894.492,325.209z"/>
                            </g>
                        </g>
                    </g>
                    <g data-tooltip-content="22" data-tooltip-id="svgTooltipPistas">
                        <polygon fill="#378C6D" points="790.178,646.479 581.439,553.696 583.223,549.685 791.961,642.467 790.178,646.479 		"/>
                        <g>
                            <g>
                                <polygon fillRule="evenodd" clipRule="evenodd" fill="#378C6D" points="539.592,532.084 541.465,566.559 573.357,548.572 				
                                    "/>
                                <path fill="#FFFFFF" d="M536.659,527.658l42.476,20.742l-40.12,22.627L536.659,527.658L536.659,527.658z M567.58,548.742
                                    l-25.056-12.234l1.391,25.582L567.58,548.742L567.58,548.742z"/>
                            </g>
                            <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M512.432,592.967c-23.258,0-42.121-18.863-42.121-42.123
                                c0-23.258,18.863-42.123,42.121-42.123c23.26,0,42.123,18.865,42.123,42.123C554.555,574.104,535.691,592.967,512.432,592.967z"
                                />
                            <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" stroke="#ACCCC0" strokeWidth="2.6887" d="M512.432,586.693
                                c-19.795,0-35.852-16.057-35.852-35.85c0-19.795,16.057-35.85,35.852-35.85c19.793,0,35.85,16.055,35.85,35.85
                                C548.281,570.637,532.225,586.693,512.432,586.693z"/>
                            <g>
                                <path fill="#378C6D" d="M491.126,543.975c-0.498,0.481-1.256,0.722-2.275,0.722h-1.682v3.651h-1.141v-8.745h2.822
                                    c0.986,0,1.736,0.238,2.25,0.715c0.515,0.477,0.771,1.092,0.771,1.845C491.872,542.89,491.623,543.494,491.126,543.975z
                                    M490.255,543.341c0.301-0.275,0.451-0.669,0.451-1.179c0-1.079-0.619-1.619-1.855-1.619h-1.682v3.212h1.682
                                    C489.485,543.755,489.954,543.617,490.255,543.341z"/>
                                <path fill="#378C6D" d="M493.829,540.129c-0.151-0.15-0.227-0.334-0.227-0.552s0.075-0.401,0.227-0.553
                                    c0.15-0.15,0.334-0.226,0.551-0.226c0.209,0,0.387,0.075,0.533,0.226c0.146,0.151,0.219,0.335,0.219,0.553
                                    s-0.072,0.401-0.219,0.552s-0.324,0.226-0.533,0.226C494.163,540.354,493.979,540.279,493.829,540.129z M494.932,541.472v6.876
                                    h-1.142v-6.876H494.932z"/>
                                <path fill="#378C6D" d="M498.393,548.19c-0.419-0.18-0.749-0.431-0.991-0.753c-0.242-0.321-0.376-0.691-0.4-1.11h1.178
                                    c0.033,0.344,0.195,0.624,0.483,0.841c0.288,0.218,0.667,0.326,1.135,0.326c0.435,0,0.777-0.096,1.028-0.288
                                    s0.376-0.436,0.376-0.728c0-0.302-0.134-0.525-0.401-0.672s-0.682-0.29-1.241-0.433c-0.51-0.134-0.926-0.27-1.247-0.408
                                    c-0.322-0.138-0.599-0.343-0.828-0.614c-0.23-0.272-0.345-0.63-0.345-1.073c0-0.352,0.104-0.673,0.313-0.966
                                    s0.506-0.525,0.891-0.696c0.384-0.172,0.822-0.258,1.316-0.258c0.76,0,1.375,0.192,1.844,0.577
                                    c0.467,0.385,0.719,0.912,0.752,1.581h-1.141c-0.025-0.359-0.17-0.647-0.434-0.865s-0.616-0.326-1.06-0.326
                                    c-0.409,0-0.735,0.088-0.978,0.263c-0.242,0.176-0.363,0.406-0.363,0.69c0,0.226,0.072,0.412,0.219,0.559
                                    s0.33,0.264,0.552,0.352s0.528,0.186,0.922,0.295c0.493,0.134,0.894,0.266,1.204,0.395c0.309,0.13,0.574,0.324,0.796,0.584
                                    c0.222,0.259,0.337,0.598,0.345,1.017c0,0.376-0.104,0.715-0.313,1.016c-0.209,0.302-0.504,0.538-0.884,0.709
                                    c-0.381,0.172-0.817,0.258-1.311,0.258C499.282,548.461,498.811,548.37,498.393,548.19z"/>
                                <path fill="#378C6D" d="M505.815,542.413v4.053c0,0.334,0.071,0.57,0.214,0.709c0.142,0.138,0.389,0.207,0.739,0.207h0.841
                                    v0.966h-1.028c-0.636,0-1.112-0.146-1.43-0.439c-0.318-0.292-0.477-0.773-0.477-1.442v-4.053h-0.891v-0.941h0.891v-1.731h1.141
                                    v1.731h1.794v0.941H505.815z"/>
                                <path fill="#378C6D" d="M509.489,543.034c0.285-0.531,0.676-0.943,1.174-1.236c0.496-0.293,1.051-0.439,1.661-0.439
                                    c0.602,0,1.124,0.13,1.567,0.39c0.443,0.259,0.773,0.585,0.99,0.979v-1.255h1.154v6.876h-1.154v-1.28
                                    c-0.226,0.402-0.563,0.734-1.01,0.998s-0.967,0.396-1.561,0.396c-0.61,0-1.162-0.151-1.655-0.452s-0.882-0.724-1.167-1.268
                                    c-0.283-0.543-0.426-1.162-0.426-1.856C509.063,544.182,509.206,543.565,509.489,543.034z M514.569,543.542
                                    c-0.21-0.385-0.492-0.68-0.848-0.885s-0.746-0.308-1.172-0.308s-0.815,0.101-1.166,0.302c-0.352,0.2-0.632,0.493-0.84,0.878
                                    c-0.209,0.385-0.314,0.837-0.314,1.355c0,0.526,0.105,0.984,0.314,1.374c0.208,0.389,0.488,0.686,0.84,0.891
                                    c0.351,0.205,0.74,0.308,1.166,0.308s0.816-0.103,1.172-0.308s0.638-0.502,0.848-0.891c0.208-0.39,0.313-0.843,0.313-1.361
                                    S514.777,543.927,514.569,543.542z"/>
                                <path fill="#378C6D" d="M522.317,543.034c0.285-0.531,0.676-0.943,1.174-1.236c0.496-0.293,1.055-0.439,1.674-0.439
                                    c0.535,0,1.032,0.124,1.492,0.37c0.459,0.247,0.811,0.571,1.053,0.973v-3.639h1.154v9.285h-1.154v-1.292
                                    c-0.226,0.409-0.561,0.746-1.003,1.01c-0.443,0.264-0.962,0.396-1.555,0.396c-0.61,0-1.165-0.151-1.661-0.452
                                    c-0.498-0.301-0.889-0.724-1.174-1.268c-0.283-0.543-0.426-1.162-0.426-1.856C521.892,544.182,522.034,543.565,522.317,543.034z
                                    M527.397,543.542c-0.21-0.385-0.492-0.68-0.848-0.885s-0.746-0.308-1.172-0.308s-0.815,0.101-1.166,0.302
                                    c-0.352,0.2-0.632,0.493-0.84,0.878c-0.209,0.385-0.314,0.837-0.314,1.355c0,0.526,0.105,0.984,0.314,1.374
                                    c0.208,0.389,0.488,0.686,0.84,0.891c0.351,0.205,0.74,0.308,1.166,0.308s0.816-0.103,1.172-0.308s0.638-0.502,0.848-0.891
                                    c0.208-0.39,0.313-0.843,0.313-1.361S527.605,543.927,527.397,543.542z"/>
                                <path fill="#378C6D" d="M532.544,548.021c-0.521-0.293-0.932-0.709-1.229-1.249c-0.297-0.539-0.445-1.164-0.445-1.875
                                    c0-0.703,0.152-1.324,0.458-1.863c0.305-0.54,0.721-0.954,1.247-1.242c0.527-0.289,1.117-0.434,1.769-0.434
                                    c0.652,0,1.241,0.145,1.769,0.434c0.526,0.288,0.941,0.7,1.248,1.235c0.305,0.535,0.457,1.159,0.457,1.87
                                    s-0.156,1.336-0.471,1.875c-0.313,0.54-0.737,0.956-1.272,1.249s-1.128,0.439-1.78,0.439
                                    C533.649,548.461,533.067,548.314,532.544,548.021z M535.447,547.168c0.359-0.192,0.649-0.48,0.872-0.865
                                    c0.221-0.385,0.332-0.854,0.332-1.405c0-0.553-0.109-1.021-0.326-1.405c-0.218-0.385-0.502-0.672-0.854-0.86
                                    c-0.351-0.188-0.73-0.282-1.141-0.282c-0.418,0-0.801,0.095-1.146,0.282c-0.348,0.188-0.626,0.476-0.834,0.86
                                    c-0.209,0.385-0.314,0.853-0.314,1.405c0,0.561,0.103,1.033,0.308,1.418c0.204,0.385,0.479,0.671,0.821,0.859
                                    c0.343,0.188,0.719,0.282,1.129,0.282C534.703,547.457,535.088,547.36,535.447,547.168z"/>
                                <path fill="#378C6D" d="M500.215,557.604c-0.204-0.377-0.497-0.664-0.878-0.863c-0.383-0.199-0.831-0.299-1.348-0.299
                                    c-0.892,0-1.606,0.293-2.144,0.879c-0.538,0.587-0.806,1.369-0.806,2.348c0,1.043,0.281,1.858,0.846,2.444
                                    s1.341,0.879,2.33,0.879c0.678,0,1.25-0.172,1.717-0.516c0.468-0.345,0.809-0.84,1.023-1.484h-3.498v-2.033h5.998v2.565
                                    c-0.205,0.688-0.551,1.328-1.041,1.92c-0.488,0.591-1.109,1.07-1.861,1.436c-0.753,0.365-1.602,0.549-2.548,0.549
                                    c-1.118,0-2.114-0.245-2.991-0.734c-0.875-0.489-1.559-1.17-2.047-2.041c-0.489-0.871-0.733-1.865-0.733-2.984
                                    c0-1.118,0.244-2.116,0.733-2.992c0.488-0.877,1.169-1.56,2.039-2.049c0.871-0.489,1.865-0.734,2.982-0.734
                                    c1.355,0,2.497,0.328,3.427,0.984s1.545,1.564,1.847,2.727H500.215z"/>
                                <path fill="#378C6D" d="M508.953,556.677c0.483-0.285,1.021-0.428,1.612-0.428v2.92h-0.758c-0.688,0-1.204,0.147-1.548,0.443
                                    c-0.345,0.296-0.517,0.815-0.517,1.557v4.179h-2.756v-9.002h2.756v1.5C508.066,557.352,508.47,556.962,508.953,556.677z"/>
                                <path fill="#378C6D" d="M512.089,554.982c-0.307-0.285-0.459-0.637-0.459-1.057c0-0.43,0.152-0.788,0.459-1.072
                                    c0.307-0.285,0.701-0.428,1.186-0.428c0.473,0,0.861,0.143,1.168,0.428c0.307,0.284,0.46,0.643,0.46,1.072
                                    c0,0.42-0.153,0.771-0.46,1.057s-0.695,0.428-1.168,0.428C512.79,555.41,512.396,555.268,512.089,554.982z M514.644,556.346
                                    v9.002h-2.756v-9.002H514.644z"/>
                                <path fill="#378C6D" d="M521.158,558.637h-1.483v6.711h-2.757v-6.711h-1v-2.291h1v-0.258c0-1.107,0.317-1.949,0.951-2.525
                                    c0.634-0.575,1.563-0.862,2.789-0.862c0.204,0,0.354,0.005,0.451,0.016v2.339c-0.526-0.032-0.897,0.044-1.112,0.227
                                    s-0.322,0.511-0.322,0.983v0.081h1.483V558.637z"/>
                                <path fill="#378C6D" d="M524.165,564.912c-0.705-0.376-1.258-0.914-1.661-1.613s-0.604-1.517-0.604-2.452
                                    c0-0.925,0.203-1.739,0.612-2.444c0.409-0.704,0.968-1.244,1.677-1.621c0.709-0.376,1.505-0.564,2.387-0.564
                                    c0.881,0,1.676,0.188,2.386,0.564c0.71,0.377,1.269,0.917,1.677,1.621c0.408,0.705,0.613,1.52,0.613,2.444
                                    s-0.207,1.74-0.621,2.444s-0.979,1.245-1.693,1.621c-0.715,0.377-1.513,0.564-2.395,0.564
                                    C525.661,565.477,524.868,565.289,524.165,564.912z M527.889,562.509c0.371-0.388,0.556-0.941,0.556-1.662
                                    s-0.18-1.274-0.539-1.662c-0.361-0.387-0.805-0.58-1.33-0.58c-0.538,0-0.984,0.19-1.339,0.572s-0.532,0.938-0.532,1.67
                                    c0,0.721,0.175,1.274,0.524,1.662c0.349,0.387,0.787,0.58,1.313,0.58C527.069,563.089,527.519,562.896,527.889,562.509z"/>
                            </g>
                        </g>
                    </g>
                </g>
                <g id="Teleski_do_covão" data-tooltip-content="3" data-tooltip-id="svgTooltipMeios" className='meios-mecanicos'>
                    <polygon fill="#FFFFFF" points="741.024,637.83 837.826,636.801 802.59,601.047 741.024,600.936 	"/>
                    <g>
                        <circle fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" cx="707.997" cy="616.111" r="22.406"/>
                        <g>
                            <g>
                                <path fill="#9B9B9B" d="M721.317,602.749l0.003-2.125h-23.25l-0.01,2.125h13.854l-4.073,8.375l-4.205,1.648v-0.031
                                    c0.073-0.609-0.156-1.83-1.515-2.041l-0.347-0.031c-1.141,0-1.529,1.021-1.57,1.596l0.006,8.242
                                    c0.007,0.039,0.245,1.119,1.239,3.186c0.833,1.736,3.106,5.393,3.921,6.852l-7.632,0.252l-0.7,2.023l0.42,0.008
                                    c0,0,7.682,0.143,13.069,0.143c4.767,0,5.089-0.119,5.229-0.168c0.868-0.301,1.747-0.809,2.311-2.318
                                    c0.203-0.504,0.097-1.27-0.135-1.607l-0.287-0.227h-0.164c-0.325,0.035-0.771,0.361-1.308,0.963
                                    c-0.271,0.305-0.759,0.707-1.91,0.795c-0.991,0.066-5.544-0.039-6.522-0.057l-3.228-7.463l10.47-20.139H721.317z
                                    M703.635,619.478v-4.713l3.298-1.418L703.635,619.478z"/>
                                <path fill="#9B9B9B" d="M702.264,609.485c0.487,0,0.954-0.15,1.345-0.455c0.97-0.74,1.148-2.123,0.413-3.092
                                    c-0.727-0.941-2.165-1.131-3.102-0.414c-0.47,0.373-0.775,0.881-0.85,1.467c-0.076,0.59,0.079,1.16,0.443,1.637
                                    C700.934,609.171,701.57,609.485,702.264,609.485z"/>
                            </g>
                        </g>
                    </g>
                    <g>
                        <path fill="#9B9B9B" d="M751.888,605.796v1.062h-2.723v8.934h-1.304v-8.934h-2.737v-1.062H751.888z"/>
                        <path fill="#9B9B9B" d="M754.797,606.857v3.341h3.64v1.075h-3.64v3.442h4.07v1.075h-5.374v-10.01h5.374v1.076H754.797z"/>
                        <path fill="#9B9B9B" d="M762.149,614.729h3.497v1.062h-4.801v-9.995h1.304V614.729z"/>
                        <path fill="#9B9B9B" d="M768.34,606.857v3.341h3.64v1.075h-3.64v3.442h4.07v1.075h-5.374v-10.01h5.374v1.076H768.34z"/>
                        <path fill="#9B9B9B" d="M775.785,615.54c-0.521-0.234-0.93-0.56-1.226-0.975c-0.296-0.416-0.449-0.896-0.458-1.441h1.39
                            c0.048,0.469,0.241,0.862,0.581,1.183c0.339,0.32,0.833,0.48,1.483,0.48c0.621,0,1.111-0.155,1.469-0.466s0.537-0.71,0.537-1.197
                            c0-0.383-0.105-0.693-0.315-0.933c-0.21-0.238-0.473-0.42-0.789-0.545c-0.315-0.124-0.74-0.258-1.275-0.401
                            c-0.659-0.172-1.187-0.344-1.583-0.516c-0.396-0.173-0.736-0.442-1.018-0.811s-0.423-0.862-0.423-1.484
                            c0-0.545,0.139-1.027,0.416-1.448c0.277-0.421,0.666-0.746,1.168-0.976c0.501-0.229,1.077-0.344,1.727-0.344
                            c0.937,0,1.703,0.234,2.3,0.703c0.597,0.468,0.934,1.09,1.01,1.863h-1.433c-0.048-0.382-0.249-0.719-0.602-1.011
                            c-0.354-0.291-0.822-0.438-1.404-0.438c-0.544,0-0.989,0.142-1.333,0.424c-0.344,0.281-0.516,0.676-0.516,1.183
                            c0,0.363,0.103,0.659,0.308,0.889s0.458,0.404,0.76,0.523c0.301,0.12,0.724,0.256,1.268,0.409
                            c0.659,0.182,1.189,0.361,1.591,0.538s0.745,0.449,1.032,0.817c0.287,0.367,0.43,0.867,0.43,1.498
                            c0,0.487-0.129,0.946-0.387,1.377c-0.258,0.43-0.64,0.779-1.146,1.047s-1.104,0.401-1.792,0.401
                            C776.896,615.892,776.306,615.774,775.785,615.54z"/>
                        <path fill="#9B9B9B" d="M788.217,615.791l-4.113-4.561v4.561H782.8v-9.995h1.304v4.632l4.127-4.632h1.648l-4.529,5.005l4.572,4.99
                            H788.217z"/>
                        <path fill="#9B9B9B" d="M792.688,605.796v9.995h-1.304v-9.995H792.688z"/>
                        <path fill="#9B9B9B" d="M752.075,623.563c0.793,0.421,1.407,1.011,1.841,1.771c0.435,0.761,0.652,1.638,0.652,2.632
                            c0,0.984-0.218,1.859-0.652,2.624s-1.051,1.357-1.849,1.778s-1.722,0.631-2.773,0.631h-3.769v-10.066h3.769
                            C750.355,622.933,751.281,623.143,752.075,623.563z M751.301,630.116c0.516-0.506,0.774-1.223,0.774-2.15
                            s-0.258-1.649-0.774-2.165c-0.516-0.517-1.237-0.774-2.164-0.774h-1.161v5.851h1.161
                            C750.063,630.877,750.785,630.623,751.301,630.116z"/>
                        <path fill="#9B9B9B" d="M758.101,632.439c-0.788-0.439-1.414-1.054-1.877-1.843c-0.463-0.788-0.695-1.675-0.695-2.659
                            c0-0.985,0.231-1.869,0.695-2.653s1.089-1.396,1.877-1.836c0.788-0.439,1.655-0.659,2.601-0.659s1.813,0.22,2.601,0.659
                            c0.788,0.44,1.409,1.052,1.863,1.836c0.454,0.784,0.681,1.668,0.681,2.653c0,0.984-0.229,1.871-0.688,2.659
                            c-0.458,0.789-1.08,1.403-1.863,1.843c-0.783,0.44-1.648,0.66-2.594,0.66S758.889,632.88,758.101,632.439z M762.629,630.06
                            c0.482-0.535,0.724-1.243,0.724-2.122c0-0.89-0.242-1.6-0.724-2.13c-0.483-0.53-1.125-0.796-1.928-0.796
                            c-0.812,0-1.459,0.263-1.942,0.789c-0.482,0.525-0.724,1.237-0.724,2.137c0,0.889,0.241,1.599,0.724,2.129s1.13,0.796,1.942,0.796
                            C761.504,630.862,762.146,630.595,762.629,630.06z"/>
                        <path fill="#9B9B9B" d="M770.476,625.291c0.43-0.778,1.03-1.386,1.799-1.821c0.769-0.435,1.641-0.652,2.615-0.652
                            c1.194,0,2.216,0.315,3.067,0.946c0.851,0.632,1.419,1.492,1.706,2.582h-2.694c-0.201-0.421-0.485-0.741-0.853-0.961
                            c-0.368-0.22-0.786-0.33-1.254-0.33c-0.755,0-1.367,0.263-1.834,0.789c-0.468,0.525-0.702,1.229-0.702,2.107
                            c0,0.88,0.234,1.582,0.702,2.108c0.468,0.525,1.08,0.788,1.834,0.788c0.468,0,0.886-0.109,1.254-0.329
                            c0.368-0.22,0.652-0.54,0.853-0.961h2.694c-0.287,1.09-0.855,1.948-1.706,2.574s-1.873,0.938-3.067,0.938
                            c-0.975,0-1.846-0.217-2.615-0.652c-0.769-0.435-1.369-1.039-1.799-1.813c-0.43-0.774-0.645-1.659-0.645-2.653
                            S770.046,626.07,770.476,625.291z"/>
                        <path fill="#9B9B9B" d="M783.323,632.439c-0.788-0.439-1.414-1.054-1.877-1.843c-0.463-0.788-0.695-1.675-0.695-2.659
                            c0-0.985,0.231-1.869,0.695-2.653s1.089-1.396,1.877-1.836c0.788-0.439,1.655-0.659,2.601-0.659s1.813,0.22,2.601,0.659
                            c0.788,0.44,1.409,1.052,1.863,1.836c0.454,0.784,0.681,1.668,0.681,2.653c0,0.984-0.229,1.871-0.688,2.659
                            c-0.458,0.789-1.08,1.403-1.863,1.843c-0.783,0.44-1.648,0.66-2.594,0.66S784.111,632.88,783.323,632.439z M787.852,630.06
                            c0.482-0.535,0.724-1.243,0.724-2.122c0-0.89-0.242-1.6-0.724-2.13c-0.483-0.53-1.125-0.796-1.928-0.796
                            c-0.812,0-1.459,0.263-1.942,0.789c-0.482,0.525-0.724,1.237-0.724,2.137c0,0.889,0.241,1.599,0.724,2.129s1.13,0.796,1.942,0.796
                            C786.727,630.862,787.369,630.595,787.852,630.06z"/>
                        <path fill="#9B9B9B" d="M801.875,622.933l-3.569,10.066h-3.067l-3.568-10.066h2.608l2.494,7.6l2.508-7.6H801.875z"/>
                        <path fill="#9B9B9B" d="M809.155,631.221H805.4l-0.602,1.778h-2.565l3.64-10.066h2.837l3.64,10.066h-2.594L809.155,631.221z
                            M806.404,620.107c0.21,0,0.394,0.031,0.552,0.093c0.157,0.063,0.351,0.156,0.58,0.28c0.172,0.096,0.311,0.165,0.416,0.208
                            s0.215,0.064,0.33,0.064c0.354,0,0.559-0.216,0.617-0.646h1.261c-0.229,1.415-0.888,2.122-1.978,2.122
                            c-0.21,0-0.394-0.03-0.552-0.093s-0.351-0.155-0.581-0.28c-0.172-0.095-0.311-0.164-0.416-0.207
                            c-0.105-0.044-0.215-0.065-0.33-0.065c-0.354,0-0.564,0.216-0.63,0.646h-1.247C804.655,620.815,805.314,620.107,806.404,620.107z
                            M808.524,629.328l-1.247-3.686l-1.232,3.686H808.524z"/>
                        <path fill="#9B9B9B" d="M815.611,632.439c-0.788-0.439-1.414-1.054-1.877-1.843c-0.463-0.788-0.695-1.675-0.695-2.659
                            c0-0.985,0.231-1.869,0.695-2.653s1.089-1.396,1.877-1.836c0.788-0.439,1.655-0.659,2.601-0.659s1.813,0.22,2.601,0.659
                            c0.788,0.44,1.409,1.052,1.863,1.836c0.454,0.784,0.681,1.668,0.681,2.653c0,0.984-0.229,1.871-0.688,2.659
                            c-0.458,0.789-1.08,1.403-1.863,1.843c-0.783,0.44-1.648,0.66-2.594,0.66S816.399,632.88,815.611,632.439z M820.14,630.06
                            c0.482-0.535,0.724-1.243,0.724-2.122c0-0.89-0.242-1.6-0.724-2.13c-0.483-0.53-1.125-0.796-1.928-0.796
                            c-0.812,0-1.459,0.263-1.942,0.789c-0.482,0.525-0.724,1.237-0.724,2.137c0,0.889,0.241,1.599,0.724,2.129s1.13,0.796,1.942,0.796
                            C819.015,630.862,819.657,630.595,820.14,630.06z"/>
                    </g>
                    <path fill="#9B9B9B" d="M686.821,617.851l-3.271-1.467l1.797-4.006l3.271,1.467L686.821,617.851L686.821,617.851z M680.279,614.919
                        l-3.271-1.466l1.796-4.008l3.271,1.466L680.279,614.919L680.279,614.919z M673.736,611.986l-3.271-1.466l1.796-4.008l3.271,1.466
                        L673.736,611.986L673.736,611.986z M667.193,609.054l-3.271-1.467l1.797-4.006l3.271,1.467L667.193,609.054L667.193,609.054z
                        M660.65,606.122l-3.271-1.466l1.796-4.008l3.271,1.466L660.65,606.122L660.65,606.122z M654.107,603.189l-3.271-1.466l1.796-4.008
                        l3.271,1.466L654.107,603.189L654.107,603.189z M647.564,600.257l-3.271-1.467l1.797-4.006l3.271,1.467L647.564,600.257
                        L647.564,600.257z M641.021,597.325l-3.271-1.466l1.796-4.008l3.271,1.466L641.021,597.325L641.021,597.325z M634.479,594.393
                        l-3.271-1.466l1.796-4.008l3.271,1.466L634.479,594.393L634.479,594.393z M627.935,591.46l-3.271-1.467l1.797-4.006l3.271,1.467
                        L627.935,591.46L627.935,591.46z M621.393,588.528l-3.271-1.466l1.796-4.008l3.271,1.466L621.393,588.528L621.393,588.528z
                        M614.85,585.596l-3.271-1.466l1.796-4.008l3.271,1.466L614.85,585.596L614.85,585.596z M608.306,582.663l-3.271-1.467l1.797-4.006
                        l3.271,1.467L608.306,582.663L608.306,582.663z M601.764,579.731l-3.271-1.466l1.796-4.008l3.271,1.466L601.764,579.731
                        L601.764,579.731z M595.221,576.799l-3.271-1.466l1.796-4.008l3.271,1.466L595.221,576.799L595.221,576.799z M588.677,573.866
                        l-3.271-1.467l1.797-4.006l3.271,1.467L588.677,573.866L588.677,573.866z M582.135,570.935l-3.271-1.466l1.796-4.008l3.271,1.466
                        L582.135,570.935L582.135,570.935z M575.592,568.002l-3.271-1.466l1.796-4.008l3.271,1.466L575.592,568.002L575.592,568.002z"/>
                    <path fill="#9B9B9B" d="M773.056,656.149l-2.85-1.319l1.845-3.984l2.85,1.319L773.056,656.149L773.056,656.149z M766.952,653.323
                        l-3.253-1.506l1.845-3.984l3.253,1.506L766.952,653.323L766.952,653.323z M760.445,650.312l-3.253-1.507l1.846-3.984l3.253,1.507
                        L760.445,650.312L760.445,650.312z M753.939,647.299l-3.253-1.506l1.845-3.984l3.253,1.506L753.939,647.299L753.939,647.299z
                        M747.433,644.286l-3.253-1.506l1.845-3.984l3.253,1.506L747.433,644.286L747.433,644.286z M740.926,641.274l-3.253-1.507
                        l1.846-3.984l3.253,1.507L740.926,641.274L740.926,641.274z M734.42,638.262l-3.253-1.506l1.845-3.984l3.253,1.506L734.42,638.262
                        L734.42,638.262z M727.914,635.249l-3.253-1.506l1.845-3.984l3.253,1.506L727.914,635.249L727.914,635.249z"/>
                </g>
                <g id="EE">
                    <g>
                        <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" stroke="#E6E7E8" strokeWidth="1.1472" d="M943.369,596.485
                            c0,7.916-6.422,14.342-14.339,14.342c-7.916,0-14.34-6.426-14.34-14.342c0-7.914,6.424-14.34,14.34-14.34
                            C936.947,582.146,943.369,588.571,943.369,596.485z"/>
                        <g>
                            <path fill="#808184" d="M922.867,592.698v3.692h4.345v1.684h-4.345v3.855h4.888v1.684h-6.951v-12.599h6.951v1.684H922.867z"/>
                            <path fill="#808184" d="M932.351,592.698v3.692h4.345v1.684h-4.345v3.855h4.888v1.684h-6.951v-12.599h6.951v1.684H932.351z"/>
                        </g>
                    </g>
                </g>
                <g id="Alt">
                    <g>
                        <path fillRule="evenodd" clipRule="evenodd" fill="#E6E7E8" d="M1484.558,653.014c8.556,0.422,15.365,7.494,15.365,16.156
                            c0,8.654-6.796,15.719-15.341,16.154c-0.276,0.014-81.129,0.023-81.412,0.023c-8.934,0-16.174-7.244-16.174-16.178
                            s7.24-16.174,16.174-16.174C1403.444,652.996,1484.289,653.002,1484.558,653.014z"/>
                        <g>
                            <path fill="#808184" d="M1407.055,671.986h-4.469l-0.769,2.183h-1.827l3.824-10.696h2.027l3.824,10.696h-1.843L1407.055,671.986z
                                M1406.564,670.558l-1.735-4.964l-1.751,4.964H1406.564z"/>
                            <path fill="#808184" d="M1413.384,672.755h3.609v1.414h-5.36v-10.681h1.751V672.755z"/>
                            <path fill="#808184" d="M1425.655,663.488v1.429h-2.841v9.252h-1.751v-9.252h-2.857v-1.429H1425.655z"/>
                            <path fill="#808184" d="M1427.636,673.954c-0.215-0.216-0.322-0.481-0.322-0.8c0-0.317,0.107-0.584,0.322-0.799
                                s0.481-0.322,0.799-0.322c0.308,0,0.568,0.107,0.783,0.322s0.322,0.481,0.322,0.799c0,0.318-0.107,0.584-0.322,0.8
                                c-0.215,0.215-0.476,0.322-0.783,0.322C1428.118,674.276,1427.851,674.169,1427.636,673.954z"/>
                            <path fill="#808184" d="M1435.465,664.489v-2.081h3.886v11.761h-2.321v-9.68H1435.465z"/>
                            <path fill="#808184" d="M1442.253,665.345c0-0.581,0.147-1.11,0.443-1.589s0.742-0.86,1.339-1.146
                                c0.596-0.285,1.324-0.428,2.185-0.428c0.859,0,1.588,0.143,2.185,0.428c0.596,0.285,1.042,0.667,1.338,1.146
                                s0.443,1.008,0.443,1.589c0,0.592-0.146,1.11-0.436,1.557s-0.677,0.793-1.161,1.04c0.592,0.259,1.054,0.635,1.387,1.13
                                c0.333,0.494,0.5,1.081,0.5,1.758c0,0.731-0.186,1.369-0.556,1.912c-0.371,0.543-0.879,0.96-1.524,1.25
                                c-0.645,0.291-1.37,0.436-2.176,0.436c-0.807,0-1.529-0.145-2.169-0.436c-0.64-0.29-1.145-0.707-1.516-1.25
                                s-0.556-1.181-0.556-1.912c0-0.677,0.166-1.266,0.499-1.766c0.333-0.501,0.796-0.874,1.387-1.122
                                C1442.791,667.383,1442.253,666.517,1442.253,665.345z M1444.728,669.394c-0.381,0.313-0.572,0.759-0.572,1.339
                                c0,0.538,0.186,0.977,0.557,1.315s0.873,0.508,1.508,0.508c0.634,0,1.134-0.172,1.499-0.517c0.365-0.344,0.548-0.779,0.548-1.307
                                c0-0.569-0.188-1.014-0.564-1.331c-0.376-0.316-0.87-0.476-1.482-0.476C1445.607,668.926,1445.11,669.082,1444.728,669.394z
                                M1447.518,664.441c-0.317-0.291-0.75-0.436-1.298-0.436c-0.538,0-0.965,0.145-1.282,0.436c-0.317,0.29-0.476,0.704-0.476,1.242
                                c0,0.483,0.164,0.871,0.492,1.161c0.327,0.29,0.749,0.436,1.266,0.436c0.516,0,0.94-0.147,1.273-0.443s0.5-0.686,0.5-1.17
                                C1447.994,665.141,1447.834,664.731,1447.518,664.441z"/>
                            <path fill="#808184" d="M1460.489,664.36h-5.224v2.759c0.226-0.279,0.548-0.508,0.967-0.686s0.865-0.267,1.338-0.267
                                c0.86,0,1.564,0.188,2.112,0.564c0.549,0.377,0.946,0.858,1.193,1.444s0.371,1.213,0.371,1.879c0,1.237-0.353,2.232-1.057,2.985
                                s-1.706,1.129-3.007,1.129c-1.225,0-2.203-0.307-2.935-0.92c-0.73-0.612-1.145-1.414-1.241-2.403h2.193
                                c0.097,0.431,0.313,0.774,0.652,1.032c0.339,0.259,0.771,0.388,1.298,0.388c0.635,0,1.112-0.199,1.436-0.598
                                c0.322-0.397,0.483-0.925,0.483-1.58c0-0.667-0.164-1.176-0.492-1.525c-0.328-0.349-0.809-0.523-1.442-0.523
                                c-0.452,0-0.828,0.112-1.129,0.338c-0.301,0.227-0.516,0.527-0.645,0.904h-2.161v-6.905h7.288V664.36z"/>
                            <path fill="#808184" d="M1463.019,664.489v-2.081h3.886v11.761h-2.321v-9.68H1463.019z"/>
                            <path fill="#808184" d="M1487.615,666.11c0.671,0.673,1.007,1.611,1.007,2.815v5.243h-2.257v-4.937
                                c0-0.699-0.178-1.234-0.532-1.605s-0.838-0.557-1.451-0.557c-0.612,0-1.099,0.186-1.459,0.557s-0.54,0.906-0.54,1.605v4.937
                                h-2.257v-4.937c0-0.699-0.178-1.234-0.532-1.605s-0.838-0.557-1.451-0.557c-0.623,0-1.115,0.186-1.475,0.557
                                c-0.36,0.371-0.54,0.906-0.54,1.605v4.937h-2.258v-8.938h2.258v1.081c0.29-0.376,0.663-0.672,1.12-0.888
                                c0.457-0.215,0.959-0.322,1.508-0.322c0.698,0,1.321,0.147,1.87,0.443c0.548,0.296,0.973,0.718,1.273,1.267
                                c0.29-0.517,0.712-0.93,1.266-1.242c0.554-0.312,1.152-0.468,1.798-0.468C1486.058,665.103,1486.943,665.438,1487.615,666.11z"/>
                        </g>
                    </g>
                </g>
            </svg>

            <Tooltip 
            
                id="svgTooltipPistas"
                delayShow={300}
                render={({ content}) => (tooltipInfoPistas(content) )}
              
            />

            <Tooltip 
                id="svgTooltipMeios"
                delayShow={300}
                render={({ content}) => (tooltipInfoMeiosMecanicos(content) )}
            />

            <Tooltip 
                id="svgTooltipGeral"
                delayShow={100}
                //event={'click'}
                //delayHide={500}
                //place={'right'}
                //effect='solid'
                //border={true}
                //type={'light'}
                //clickable={true}
                //padding='15px 25px'
                //html={true}
                //textColor='yellow'
                //backgroundColor='gray'
                //arrowColor='red'
            />

        </div>
    )
};
export default Mapa;