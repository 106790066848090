import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// import {
//     FacebookIcon,
//     FacebookShareButton,
// } from "react-share";




import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';
import { Helmet } from 'react-helmet';

import * as Hosts from '../../../Hosts';
import { reqGET, content_language } from '../../../Utils';

import Loader from '../../../loader';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Breadcrumb from '../Breadcrumb/Breadcrumb';

// import DivSharer from '../DivSharer/DivSharer';

import { language } from '../WEBContent/Language';

import AssecImage from "../ASSEC_Image/Image"


import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';



export default function Insight(props) {

    Fancybox.bind('[data-fancybox]', {
        // Your custom options
      });  
    
    const { pathname } = useLocation();

    const [isloading, setLoading] = useState(true);

	//const [lang, ] = useState('pt');
    const [lang, set_lang] = useState(localStorage.getItem('lang'));
	const [lang_id, set_lang_id] = useState('1');

    const [/*link*/, set_link] = useState('')

    const data_interface = {
        id: '',
        info: {},
        content: {}
    }
    const [content, set_content] = useState(data_interface);

    useEffect(() => {

        set_lang(localStorage.getItem('lang'));
        content_language.forEach(el => {
            if (el.code === localStorage.getItem('lang')) {
            set_lang_id(el.id)
            }
        })

        try {
			let id = props.info.state.id;
			let slug = props.info.state.slug;
			let preview_id = props.info.state.preview;

            if(id !== '') {
        
                let getPreviewInfoId = async () => {
                    await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${preview_id}`)
                        .then(res => { 
                            if(res.data.length > 0) {
                                res.data[0].info['preview'] = true

                                var hoje = new Date();
                                let status = 0
                                if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                    var date_p = new Date(res.data[0].info.date.published);
        
                                    status = 1
                                    if(hoje >= date_p) 
                                        status = 2
                                }
       
                                if(res.data[0].info.preview === true) {
                                    //res.data[0].content = {text: 'preview'}
                                    console.log('Normal Preview')
        
                                    set_content(res.data[0])
                                    setLoading(false)   
                                }
                                else if(status !== 2) {
                                    res.data[0].content = {text: 'rascunho ou agendado'}
        
                                    set_content(res.data[0])
                                    setLoading(false)    
                                }
                                else {
                                    set_content(res.data[0]) 
            
                                    setLoading(false) 
                                }
                            }
                        })
                        .catch(erro => console.log('ERROR', erro))
                }

                let getInfoId = async () => {
                    await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${id}`)
                        .then(res => { 
                            if(res.data.length > 0) {
        
                                if(res.data[0].content[0].contacts !== undefined && res.data[0].content[0].contacts['website'] !== undefined && res.data[0].content[0].contacts['website'] !== '') {
                                    const { hostname } = new URL(res.data[0].content[0].contacts['website'].includes('http') ? res.data[0].content[0].contacts['website'] : 'http://' + res.data[0].content[0].contacts['website'])
                                    set_link(hostname)
                                }

                                var hoje = new Date();
                                let status = 0
                                if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                    var date_p = new Date(res.data[0].info.date.published);
        
                                    status = 1
                                    if(hoje >= date_p) 
                                        status = 2
                                }
        
                                if(status !== 2) {
                                    res.data[0].content = {text: 'rascunho ou agendado'}
        
                                    set_content(res.data[0])
                                    setLoading(false)    
                                }
                                else {
                                    set_content(res.data[0]) 
            
                                    setLoading(false) 
                                }
                            }
                        })
                        .catch(erro => console.log('ERROR', erro))
                }
            
                let getInfoSlug = async () => {
                    reqGET(`${Hosts.SIMCore_API}/web/content/slug/${Hosts.companycode}/${slug}`)
                        .then(res => { 
                            if(res.data.length > 0) {
        
                                if(res.data[0].content[0].contacts !== undefined && res.data[0].content[0].contacts['website'] !== undefined && res.data[0].content[0].contacts['website'] !== '') {
                                    const { hostname } = new URL(res.data[0].content[0].contacts['website'].includes('http') ? res.data[0].content[0].contacts['website'] : 'http://' + res.data[0].content[0].contacts['website'])
                                    set_link(hostname)
                                }
        
                                var hoje = new Date();
                                let status = 0
                                if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                    var date_p = new Date(res.data[0].info.date.published);
        
                                    status = 1
                                    if(hoje >= date_p) 
                                        status = 2
                                }
        
                                if(status !== 2)  {
                                    res.data[0].content = [{text: 'rascunho ou agendado'}]
        
                                    set_content(res.data[0])
                                    setLoading(false)
                                }
                                else {
                                    set_content(res.data[0]) 
        
                                    setLoading(false) 
                                }
                            }
                        })
                        .catch(erro => console.log('ERROR', erro))
                }
        
                if(preview_id !== undefined && preview_id !== 'undefined' && preview_id !== '') {
                    getPreviewInfoId()
                }
                else if(id !== undefined && id !== 'undefined' && id !== '') {
                    getInfoId()
                }
                else if(slug !== undefined && slug !== 'undefined' && slug !== '')  {
                    getInfoSlug()
                }
            }
            else {
                console.log('=== ""')
            }

            return

        } catch {
            console.log('')

			return
		}
        
    }, [props.info.state.id, props.info.state.preview, props.info.state.slug]);

    const get_seo_data = (type) => {

        if(type === 'title') {
            if(content.info.seo !== undefined && content.info.seo['title'] !== undefined  && content.info.seo['title'] !== '') {
                return content.info.seo['title']
            } 
            else {
                return Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_TITLE
            }
        }
        else if(type === 'description') {
            if(content.info.seo !== undefined && content.info.seo['description'] !== undefined  && content.info.seo['description'] !== '') {
                return content.info.seo['description']
            } 
            else if(content.content !== undefined && content.content.length > 0 && content.content[0]['abstract'] !== undefined && content.content[0]['abstract'] !== undefined  && content.content[0]['abstract'] !== '') {
                return content.content[0]['abstract']
            } 
            else {
                return Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_DESCRIPTION
            }
        }
        if(type === 'keywords') {
            if(content.info.seo !== undefined && content.info.seo['keywords'] !== undefined  && content.info.seo['keywords'] !== '') {
                return content.info.seo['keywords']
            } 
            else {
                return Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_KEYWORDS
            }
        }
        else if(type === 'image') {
            if(content.content !== undefined && content.content.length > 0 && content.content[0]['media'] !== undefined && content.content[0]['media']['image'] !== undefined && content.content[0]['media']['image'].length > 0 && content.content[0]['media']['image'][0]['link'] !== '') {
                return content.content[0]['media']['image'][0]['link']
            } 
            else {
                return Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_IMAGE
            }
        }
        else if(type === 'link') {
            let title_seo = Hosts.WEB_SITE_URI + pathname
            return '.' + title_seo.replace("//", "/");
        }
    }

    return (isloading ?

		<Loader />
		
		:

        <>
            <Navbar />
            <Breadcrumb />
            <main className="article" id="gallery">
                <div>
                    <Helmet>
                        <title>{ get_seo_data('title') }</title>
                        <meta property="og:url" content={ get_seo_data('link') } />
                        <meta property="og:title" content={ get_seo_data('title') }></meta>
                        <meta name="twitter:title" content={ get_seo_data('title') }></meta>
                        <meta name="description" content={ get_seo_data('description') } />
                        <meta property="og:description" content={ get_seo_data('description') }></meta>
                        <meta name="twitter:description" content={ get_seo_data('description') }></meta>
                        <meta name="keywords" content={ get_seo_data('keywords') } />
                        <meta property="og:image" content={ get_seo_data('image') }></meta>
                        <meta name="twitter:image" content={ get_seo_data('image') }></meta>
                    </Helmet>
                </div>

                {
                content.content.map((el, k) => { 

                    return (
                        <section key={k} className="content-body">
                            <div className="container">
                                <div className="row">
                                    <div className="px-3 my-3 text-right w-100">
                                        <a className="btn btn-text" href={'./gallery/all'} >
                                            <i className="bi bi-arrow-left"></i> {language[lang].back}
                                         </a>
                                    </div>
                                </div>

                                <div className="row">
                                    <div id="div-content" className="div-content col-12">

                                        <div className={"text-center " + (el.text !== '' ? 'mb-3' : 'mb-5')}>
                                            <h3 className="titulo-primario">
                                                {lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title)}
                                            </h3>
                                        </div>

                                        {
                                            el.text !== '' ?

                                                <div className="div-text text-center mb-5">
                                                    {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.text : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].text === undefined || el.language[lang_id].text === '' || el.language[lang_id].text === '<p></p>\n' ? el.text : el.language[lang_id].text))}
                                                </div>
                                            : null 
                                        }
                                    </div>

                                    {
                                        lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 0 ?
                                            <div className="div-content col-12 card-columns mb-5">
                                                {
                                                    el.language[lang_id].media.image.map((el_img, k_img) => {

                                                        let link = ''
                                                        let youtube_thumbnail = ''

                                                        if(el_img.type === 'video') {

                                                            link = el_img.link.replace('watch?v=', 'embed/');
                                                        
                                                            youtube_thumbnail = link.split('/').pop();
                                                        }

                                                        return (
                                                            <div key={k_img} className="card mb-3">

                                                                    {
                                                                        el_img.type === 'video' ?

                                                                            <a
                                                                                className='text-decoration-none'
                                                                                href={link}
                                                                                data-fancybox="gallery"
                                                                                data-caption={(el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png') || el_img.name.includes('youtube.com')) ? '' : el_img.name}
                                                                            >
                                                                                <figure className="shadow-sm hover-animate m-0">
                                                                                    <img src={'https://i1.ytimg.com/vi/' + youtube_thumbnail +'/0.jpg'} className="img-fluid" alt={el_img.name} title={el_img.name} />
                                                                                    {
                                                                                        (el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png') || el_img.name.includes('youtube.com')) ?
                                                                                            ''
                                                                                        :
                                                                                            <figcaption className='p-3 text-center'>{el_img.name}</figcaption>
                                                                                    }
                                                                                </figure>
                                                                            </a>

                                                                        :
                                                                            <a
                                                                                className='text-decoration-none'
                                                                                href={el_img.link}
                                                                                data-fancybox="gallery"
                                                                                data-caption={(el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ? '' : el_img.name}
                                                                            >
                                                                                <figure className="shadow-sm hover-animate m-0">
                                                                                    <AssecImage 
                                                                                        linkImage={el_img.link}
                                                                                        width="450"
                                                                                        height="300"
                                                                                        fit='crop'
                                                                                        type='img'
                                                                                        sClass="img-fluid" 
                                                                                        alt={el_img.name}
                                                                                    />
                                                                                    {/*<img src={el_img.link} className="img-fluid" alt={el_img.name} title={el_img.name} />*/}
                                                                                    {
                                                                                        (el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ?
                                                                                            ''
                                                                                        :
                                                                                            <figcaption className='p-3 text-center'>{el_img.name}</figcaption>
                                                                                    }
                                                                                </figure>
                                                                            </a>
                                                                    }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        
                                        :
                                            el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 0 ?
                                                <div className="div-content col-12 card-columns mb-5">
                                                        {
                                                            el.media.image.map((el_img, k_img) => {

                                                                let link = ''
                                                                let youtube_thumbnail = ''

                                                                if(el_img.type === 'video') {

                                                                    link = el_img.link.replace('watch?v=', 'embed/');
                                                                
                                                                    youtube_thumbnail = link.split('/').pop();
                                                                }

                                                                return (
                                                                    <div key={k_img} className="card mb-3">

                                                                            {
                                                                                el_img.type === 'video' ?

                                                                                    <a
                                                                                        className='text-decoration-none'
                                                                                        href={link}
                                                                                        data-fancybox="gallery"
                                                                                        data-caption={(el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png') || el_img.name.includes('youtube.com')) ? '' : el_img.name}
                                                                                    >
                                                                                        <figure className="shadow-sm hover-animate m-0">
                                                                                            <img src={'https://i1.ytimg.com/vi/' + youtube_thumbnail +'/0.jpg'} className="img-fluid" alt={el_img.name} title={el_img.name} />
                                                                                            {
                                                                                                (el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png') || el_img.name.includes('youtube.com')) ?
                                                                                                    ''
                                                                                                :
                                                                                                    <figcaption className='p-3 text-center'>{el_img.name}</figcaption>
                                                                                            }
                                                                                        </figure>
                                                                                    </a>

                                                                                :
                                                                                    <a
                                                                                        className='text-decoration-none'
                                                                                        href={el_img.link}
                                                                                        data-fancybox="gallery"
                                                                                        data-caption={(el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ? '' : el_img.name}
                                                                                    >
                                                                                        <figure className="shadow-sm hover-animate m-0">
                                                                                            <AssecImage 
                                                                                                linkImage={el_img.link}
                                                                                                width="450"
                                                                                                height="300"
                                                                                                fit='crop'
                                                                                                type='img'
                                                                                                sClass="img-fluid" 
                                                                                                alt={el_img.name}
                                                                                            />
                                                                                            {/*<img src={el_img.link} className="img-fluid" alt={el_img.name} title={el_img.name} />*/}
                                                                                            {
                                                                                                (el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ?
                                                                                                    ''
                                                                                                :
                                                                                                    <figcaption className='p-3 text-center'>{el_img.name}</figcaption>
                                                                                            }
                                                                                        </figure>
                                                                                    </a>
                                                                            }
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                </div>
                                            : null
                                    }

                                    { 
                                        //sem card-columns (desactivado)
                                        lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 0 && 0?
                                            <div className="div-content col-12 mb-5">
                                                <div className="row div-gallery">
                                                    {
                                                        el.language[lang_id].media.image.map((el_img, k_img) => {

                                                            let link = el_img.link
                                                            let youtube_thumbnail = ''

                                                            if(el_img.type === 'video') {

                                                                link = link.replace('watch?v=', 'embed/');
                                                            
                                                                youtube_thumbnail = link.split('/').pop();
                                                            }

                                                            return (
                                                                <div key={k_img} className="col-6 col-md-4 col-lg-4">

                                                                    {
                                                                        el_img.type === 'video' ?

                                                                            <a
                                                                                className='text-decoration-none'
                                                                                href={link}
                                                                                data-fancybox="gallery"
                                                                                data-caption={(el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png') || el_img.name.includes('youtube.com')) ? '' : el_img.name}
                                                                            >
                                                                                <figure className="shadow-sm hover-animate m-2">
                                                                                    <img src={'https://i1.ytimg.com/vi/' + youtube_thumbnail +'/0.jpg'} className="img-fluid" alt={el_img.name} title={el_img.name} />
                                                                                    {
                                                                                        (el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png') || el_img.name.includes('youtube.com')) ?
                                                                                            ''
                                                                                        :
                                                                                            <figcaption className='p-3 text-center'>{el_img.name}</figcaption>
                                                                                    }
                                                                                </figure>
                                                                            </a>

                                                                        :
                                                                            <a
                                                                                className='text-decoration-none'
                                                                                href={el_img.link}
                                                                                data-fancybox="gallery"
                                                                                data-caption={(el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ? '' : el_img.name}
                                                                            >
                                                                                <figure className="shadow-sm hover-animate m-2">
                                                                                    <AssecImage 
                                                                                        linkImage={el_img.link}
                                                                                        width="450"
                                                                                        height="300"
                                                                                        fit='crop'
                                                                                        type='img'
                                                                                        sClass="img-fluid" 
                                                                                        alt={el_img.name}
                                                                                    />
                                                                                    {/*<img src={el_img.link} className="img-fluid" alt={el_img.name} title={el_img.name} />*/}
                                                                                    {
                                                                                        (el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ?
                                                                                            ''
                                                                                        :
                                                                                            <figcaption className='p-3 text-center'>{el_img.name}</figcaption>
                                                                                    }
                                                                                </figure>
                                                                            </a>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        :
                                            el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 0 && 0 ?
                                                <div className="div-content col-12 mb-5">
                                                    <div className="row div-gallery">
                                                        {
                                                            el.media.image.map((el_img, k_img) => {

                                                                let link = el_img.link
                                                                let youtube_thumbnail = ''

                                                                if(el_img.type === 'video') {

                                                                    link = link.replace('watch?v=', 'embed/');
                                                                
                                                                    youtube_thumbnail = link.split('/').pop();
                                                                }

                                                                return (
                                                                    <div key={k_img} className="col-6 col-md-4 col-lg-4">

                                                                        {
                                                                            el_img.type === 'video' ?

                                                                                <a
                                                                                    className='text-decoration-none'
                                                                                    href={link}
                                                                                    data-fancybox="gallery"
                                                                                    data-caption={(el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png') || el_img.name.includes('youtube.com')) ? '' : el_img.name}
                                                                                >
                                                                                    <figure className="shadow-sm hover-animate m-2">
                                                                                        <img src={'https://i1.ytimg.com/vi/' + youtube_thumbnail +'/0.jpg'} className="img-fluid" alt={el_img.name} title={el_img.name} />
                                                                                        {
                                                                                                (el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png') || el_img.name.includes('youtube.com')) ?
                                                                                                    ''
                                                                                                :
                                                                                                    <figcaption className='p-3 text-center'>{el_img.name}</figcaption>
                                                                                            }
                                                                                    </figure>
                                                                                </a>

                                                                            :
                                                                                <a
                                                                                    className='text-decoration-none'
                                                                                    href={el_img.link}
                                                                                    data-fancybox="gallery"
                                                                                    data-caption={(el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ? '' : el_img.name}
                                                                                >
                                                                                    <figure className="shadow-sm hover-animate m-2">
                                                                                        <AssecImage 
                                                                                            linkImage={el_img.link}
                                                                                            width="450"
                                                                                            height="300"
                                                                                            fit='crop'
                                                                                            type='img'
                                                                                            sClass="img-fluid" 
                                                                                            alt={el_img.name}
                                                                                        />
                                                                                        {/*<img src={el_img.link} className="img-fluid" alt={el_img.name} title={el_img.name} />*/}
                                                                                        {
                                                                                            (el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ?
                                                                                                ''
                                                                                            :
                                                                                                <figcaption className='p-3 text-center'>{el_img.name}</figcaption>
                                                                                        }
                                                                                    </figure>
                                                                                </a>
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            :
                                                null
                                    }

                                    {
                                        lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['doc'] !== undefined && el.language[lang_id]['media']['doc'].length > 0 ?
                                            <div className="div-content col-12 mb-5">
                                                <div className="row div-documents">
                                                    {
                                                    el.language[lang_id].media.doc.map((el_doc, k_doc) => {
                                                        if(el_doc['visible'] !== undefined && el_doc['visible'] === true) {
                                                            return (
                                                                <div key={k_doc} className="mb-2">
                                                                    <img className="img-fluid" src={el_doc.link} alt="" />
                                                                    <a className="btn btn-primary" href={el_doc.link} target="_blank" rel="noreferrer">{el_doc.name}</a>
                                                                </div>
                                                            )
                                                        }
                                                        else {
                                                            return null
                                                        }
                                                    })
                                                    }
                                                </div>
                                            </div>

                                        :
                                            el['media'] !== undefined && el['media']['doc'] !== undefined && el.media.doc.length > 0 ?
                                                <div className="div-content col-12 mb-5">
                                                    <div className="row div-documents">
                                                        {
                                                        el.media.doc.map((el_doc, k_doc) => {
                                                            if(el_doc['visible'] !== undefined && el_doc['visible'] === true) {
                                                                return (
                                                                    <div key={k_doc} className="mb-2">
                                                                        <img className="img-fluid" src={el_doc.link} alt="" />
                                                                        <a className="btn btn-primary" href={el_doc.link} target="_blank" rel="noreferrer">{el_doc.name}</a>
                                                                    </div>
                                                                )
                                                            }
                                                            else {
                                                                return null
                                                            }
                                                        })
                                                        }
                                                    </div>
                                                </div>
                                            :
                                                null
                                        }
                                </div>
                            </div>
                        </section>
                    )
                })
                }
            </main>
            <Footer />
        </>
    )
}