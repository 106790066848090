import React, { useState, useEffect, useRef } from 'react';

//import { Button } from "reactstrap";

//import TextareaCounter from 'react-textarea-counter';

import './Forms.css';

import * as Hosts from '../../../Hosts';
import {
    set_language,

    reqPOST,
    repo_banner_link,
    content_language

} from '../../../Utils';

import Swal from 'sweetalert2'

import { language } from '../WEBContent/Language';

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const data_interface = {
    id: '',
    info: {
        companycode: '',
        type: 'testimony',
        layout: 'normal',
        date: {
            creation: '',
            published: '',
            avaiable: '',
            modified: []
        },
        language: [],
        media: {
            id: '',
            link: '',
        },
        seo: {
            title: '',
            description: '',
            keywords: '',
            language: []
        },
        tags: [],
        status: 'under_review',
        slug: '',
        parent: '0',
        order: '1',
    },
    content: [{
        name: '',
        email: '',
        address: '',
        message: '',
        media: {
            image: [],
            doc: []
        }
    }]
}


const OpinionModal = (props) => {

    const [, set_dados] = useState(undefined);

    const [lang, set_lang] = useState('pt');
    const [, set_lang_id] = useState('1');

    const dados = useRef(data_interface);

    const [counterText, setCounterText] = useState('');

    const handle_change_info = (e) => {
        const { name, value } = e.target;
        //console.log('NAME ', name)
        //console.log('VALUE ', value)
        set_dados(dados.current.content[0][name] = value)
        set_dados(dados.current.info.date.creation = new Date())
        //console.log('Dados Current:' + JSON.stringify(dados))
    }

    const handle_change_info_textarea = (e) => {
        const { name, value } = e.target;
        //console.log('NAME ', name)
        //console.log('VALUE ', value)
        setCounterText(e.target.value)

        set_dados(dados.current.content[0][name] = value)
        set_dados(dados.current.info.date.creation = new Date())
        //console.log('Dados Current:' + JSON.stringify(dados))
    }

    /*    const clean_data = async () => {
           // DEEP COPY OF data_interface 
           set_dados(dados.current = JSON.parse(JSON.stringify(data_interface)))
           //set_dados(dados.current.info.companycode = getTokenInfo().companycode)
       }
    */
    useEffect(() => {

        //dados.current = data_interface;
        set_dados(dados.current.info.companycode = Hosts.companycode)

        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }

    }, [])

    const handleSubmit = async e => {
        
        e.preventDefault();

        reqPOST(`${Hosts.SIMCore_API}/web/content`, dados.current)
            .then(() => {

                //alert('Registo efetuado com sucesso!\n\nConsulte os seus dados de acesso no email enviado.')
                set_dados(dados.current = JSON.parse(JSON.stringify(data_interface)))
                //props.history.push(endpoints.list) 

                Swal.fire({
                    title: language[lang].opinion_form,
                    text: language[lang].sent_successfully,
                    icon: 'success',
                    //showCancelButton: true,
                    confirmButtonColor: '#08396a',
                    //confirmButtonText: 'Tentar novamente',
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                      //Swal.fire('Saved!', '', 'success')
                      document.getElementById('closeButton').click()
                    } /*else if (result.isDenied) {
                        Swal.fire('Changes are not saved', '', 'info')
                      }*/
                  })

                reqPOST(`${Hosts.SIMCore_API_MAIL}`, { data: mailOptions })
                    .then(() => {
                        //onsole.log('Notificações enviadas com sucesso')
                        document.getElementById('form_modal').reset();

                        //props.onClose()

                        //document.getElementById('modal_form').modal('hide');
                        //clean_data()
                    })
                    .catch((error) => console.log(error)/*alert('Problema ao enviar notificações!')*/)

            })
            .catch((error) => console.log(error) /*alert('Problema ao atualizar Registo!')*/)

        //alert('SUBMIT');
        //return;

    }

    let mailOptions = {
        from: Hosts.contactFrom,
        // vários emails separados por virgulas
        to: dados.current.content[0].email,
        //to: 'suporte@assec.pt',

        //cc-> opcional
        cc: Hosts.contactFrom,
        subject: Hosts.webSiteTitle + ' - ' + language[lang].testimonial_form,
        //text: 'Teste de EMAIL ', -> opcional
        html:
            '<html><head></head><body style="background-color:#F7F7F7;">' +
            '<div style="width:100% !important;margin-top:0;margin-bottom:0;margin-right:0;margin-left:0;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;color:#425968;background-color:#F7F7F7;background-image:none;background-repeat:repeat;background-position:top left;background-attachment:scroll;font-family:Arial,Helvetica,sans-serif;font-size:14px;line-height:16px;">' +
            '<table border="0" align="center" cellpadding="0" cellspacing="0" style="margin-top:0;margin-bottom:0;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;border-collapse:collapse;">' +
            '<tr style="border-collapse: collapse;">' +
            '<td style="border-collapse: collapse;">' +
            '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; height: 90px; background-color:#ffffff;border:1px solid transparent;border-collapse:collapse;">' +
            '<tr style="border-collapse: collapse;">' +
            '<td align="left" class="logo" style="padding:0; border-collapse:collapse;">' +
            '<a alt="' + Hosts.webSiteTitle + '" title="' + Hosts.webSiteTitle + '" href="' + Hosts.EMAIL_WEB_SITE_URL + '" target="_blank" rel="noreferrer">' +
            '<img style="width: 600px; height: 90px;" width="600" height="90" src="' + repo_banner_link("banner-email.jpg") + '"/>' +
            '</a>' +
            '</td>' +
            '</tr>' +
            '</table>' +
            '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; height: 90px; padding: 15px 30px; background-color:#ffffff;border:1px solid transparent;border-collapse:collapse;">' +
            '<tr style="border-collapse: collapse;">' +
            '<td align="left" class style="padding:0 0 10px; border-collapse:collapse;">' +
            '<div style="width:540px; margin: 0 auto; padding: 15px 30px;">' +
            '<h4 style="text-transform: uppercase;font-family:Arial,Helvetica,sans-serif;">' + language[lang].testimonial_form + ':</h4>' +
            '<span style="font-family:Arial,Helvetica,sans-serif;font-size:14px;line-height:18px;"><b>' + language[lang].name + ': </b><label>' + dados.current.content[0].name + '</label></span>' +
            '<br/>' +
            '<span style="font-family:Arial,Helvetica,sans-serif;font-size:14px;line-height:18px;"><b>Email: </b><label>' + dados.current.content[0].email + '</label></span>' +
            '<br/>' +
            '<span style="' + ((dados.current.content[0].address !== '') ? 'display: block;font-family:Arial,Helvetica,sans-serif;font-size:14px;line-height:18px;' : 'display: none') + '"><b>' + language[lang].location + ': </b><label>' + dados.current.content[0].address + '</label></span>' +
            '<br/>' +
            '<span style="font-family:Arial,Helvetica,sans-serif;font-size:14px;line-height:18px;"><b>' + language[lang].message + ': </b><label>' + dados.current.content[0].message + '</label></span>' +
            '<br/>' +
            '</div>' +
            '</td>' +
            '</tr>' +
            '</table>' +
            '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; height: 90px; background-color:' + Hosts.FOOTER_EMAIL_COLOR + '; padding: 10px; border:1px solid transparent;border-collapse:collapse;">' +
            '<tr style="border-collapse: collapse;">' +
            '<td align="left" style="padding:10px 0px; border-collapse:collapse;">' +
            '<div style="margin: 0 auto; width:600px;">' +
            '<div style="text-align: center; font-size: small; color: #FFFFFF;">' +
            '<span style="text-transform: uppercase;font-family:Arial,Helvetica,sans-serif;font-size:12px;">' + Hosts.webSiteTitle + '</span>' +
            '<br/>' +
            '<span style="font-family:Arial,Helvetica,sans-serif;font-size:12px;">' + Hosts.webSiteAdress + '</span>' +
            '<br/>' +
            '<a alt="' + Hosts.webSiteTitle + '" title="' + Hosts.webSiteTitle + '" href="' + Hosts.EMAIL_WEB_SITE_URL + '" target="_blank" rel="noreferrer" style="font-family:Arial,Helvetica,sans-serif;text-decoration:none;"> ' +
            '<span style="color: #FFFFFF;font-family:Arial,Helvetica,sans-serif;font-size:12px;" >' + Hosts.EMAIL_WEB_SITE_URL + '</span>' +
            '</a>' +
            '</div>' +
            '</div>' +
            '</td>' +
            '</tr>' +
            '</table>' +
            '</td>' +
            '</tr>' +
            '</table>' +
            '</div>' +
            '<body><html>',
    };

    return (

        !props.show && false ?
            <></>
            :
            <div className="modal" id="modal_form" tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <form id="form_modal" onSubmit={(e) => handleSubmit(e)}>
                            <div className="modal-header">
                                <div className="col-12 d-flex">
                                    <h5 className="modal-title">{language[lang].testimonial_form}</h5>
                                    <button type="button" id="closeButton" className='close' data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon className="mr-2" icon={faTimes} />
                                    </button>
                                </div>
                            </div>
                            <div className="modal-body">
                                
                                <div className="col-12 text-left">
                                        <label>{language[lang].name}*</label>
                                        <input type="text" name="name"
                                            className="form-control mb-3"
                                            placeholder=""
                                            value={dados.current.content.name}
                                            onChange={handle_change_info}
                                            required
                                        />

                                        <label>Email*</label>
                                        <input type="email" name="email"
                                            className="form-control mb-3"
                                            placeholder=""
                                            value={dados.current.content.email}
                                            onChange={handle_change_info}
                                            required
                                        />

                                        <label>{language[lang].location}</label>
                                        <input type="text" name="address"
                                            className="form-control mb-3"
                                            placeholder=""
                                            value={dados.current.content.address}
                                            onChange={handle_change_info}
                                        />

                                        <label>{language[lang].message}*</label>
                                        {/*<TextareaCounter countLimit={200} style={{ height: "178px", width: "100%" }} id="message" name="message"
                                            value={dados.current.content.testimony}
                                            onChange={handle_change_info} required
                                        />*/}

                                        {/* <input type="text" name="message" value={dados.current.info.message} className="form-control" placeholder="" onChange={handle_change_info} /> */}

                                        <textarea
                                            style={{ height: "180px", width: "100%" }}
                                            id="message"
                                            name="message"
                                            maxLength="400"
                                            value={dados.current.info.description_10}
                                            onChange={handle_change_info_textarea}
                                            required
                                        />
                                        <p className="text-right my-2 font-weight-bold small">{language[lang].maximum_numbers_of_caracters}&#58;&nbsp;{counterText.length}/400</p>
                                        <div htmlFor="terms">
                                            <input type="checkbox" name="terms" id="terms"
                                                className="mr-2"
                                                required
                                            />
                                            {/*language[lang].accept_terms*/}
                                            <label htmlFor="terms">{language[lang].agree_terms_conditions}</label>
                                            <a href={Hosts.WEB_SITE_URI + "terms-conditions"} target="_blank" rel="noreferrer"> {language[lang].terms_conditions_link}</a>
                                            <label className="pl-2 small" htmlFor="terms">{language[lang].terms_conditions_required}</label>
                                        </div>
                                </div>
                                
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary" /*data-dismiss="modal"*/>{language[lang].send}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
    );


}

export default OpinionModal;