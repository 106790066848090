/** Ficheiro de Configuração de Acessos Remotos */

if (process.env.REACT_APP_MODE === 'production') console.log = function () { };

// ============== PARA ELIMINAR ============== 

export const SITES = {
    SIMCONFERENCE: {
        // ACCESS_TOKEN: "SIMCONFERENCE;I1NiJ9.eyJSb2xlIjoiQWRtaW4iLCJJc3N1ZXIiOiJJc3N1ZXIiLCJVc2VybmFtZSI6IkphdmFJblVzZSIsImV4cCI6MTYzNTQxNzIxMCwiaWF0IjoxNjM1NDE3MjEwfQ.lwwQmI9SGdUZm3tJm2YOmIIuwx82CBcrO_WZmJn3CGQ",
        ACCESS_TOKEN: "SIMWEBSITE;ERRI1NiJ9.kkkkb2xlIjoiQWRtaW4iLCJJc3N1ZXIiOiJJc3N1ZXIiLCJVc2VybmFtZSI6IkphdmFJblVzZSIsImV4cCI6MTYzNTQxNzIxMCwiaWF0IjoxNjM1NDE3MjEwfQ.lwwQmI9SGdUZm3tJm2YOmIIuwx82CBcrO_WZmJn3CGQ",
    },
    SIMWEBSITE: {
        ACCESS_TOKEN: "SIMWEBSITE;ERRI1NiJ9.kkkkb2xlIjoiQWRtaW4iLCJJc3N1ZXIiOiJJc3N1ZXIiLCJVc2VybmFtZSI6IkphdmFJblVzZSIsImV4cCI6MTYzNTQxNzIxMCwiaWF0IjoxNjM1NDE3MjEwfQ.lwwQmI9SGdUZm3tJm2YOmIIuwx82CBcrO_WZmJn3CGQ",
        DEFAULT_SEO_TITLE: "Estância de Ski - Serra da Estrela",
        DEFAULT_SEO_DESCRIPTION: "A Estância está dotada de recentes instalações, que albergam todas as infra-estruturas de apoio necessárias e dispõe de modernos e sofisticados equipamentos: meios mecânicos de última geração, um sistema de identificação de forfaits dos mais modernos na Europa e um inovador processo de produção de neve artificial que permite assegurar 120 a 150 dias de neve por ano.",
        DEFAULT_SEO_KEYWORDS: "Estância Ski, Serra da Estrela, Turistrela",
    }
}

//const webSiteURL = '/demo/assec/sim/conference/'

//const emailWebSiteURL = `https://sede.assec.pt${webSiteURL}`

//const backofficeURL = '/demo/assec/sim/conference-gest'

//const emailBackofficeURL = `https://sede.assec.pt${backofficeURL}`

// ============================================ 


export const webSiteTitle = 'Estância de Ski - Serra da Estrela'

export const webSiteAdress = 'Estrada Nacional 339, Apartado 332 - 6200-073 Covilhã, Portugal'

export const companycode = '0000000';
//const companycode = '0000251';

export const fbLink = 'https://www.facebook.com/SkiSerraDaEstrela';
export const instaLink = 'https://www.instagram.com/estanciadeskiserradaestrela/';
export const linkedinLink = '';

//export const contactFrom = 'web.dev@assec.pt';

export const contactFrom = process.env.REACT_APP_API_EMAIL_CONTACT;

// ########################### CONFIG ##################################

export const EMAIL_BODY_BACKGROUND_COLOR = ' #F7F7F7'
export const EMAIL_TEXT_SIZE = ' 14px'
export const EMAIL_TEXT_LINE_HEIGHT = ' 24px'
export const EMAIL_TEXT_COLOR = ' #444444'
export const EMAIL_BACKGROUND_COLOR = ' #FFFFFF'
export const FOOTER_EMAIL_TEXT_SIZE = ' 12px'
export const FOOTER_EMAIL_TEX_LINE_HEIGHT = ' 20px'
export const FOOTER_EMAIL_COLOR = 'transparent'
export const FOOTER_EMAIL_TEXT_COLOR = '#656565'

console.log('HOSTS APPLICATION_URL:', process.env.REACT_APP_PRINCIPAL_URL)

console.log('HOSTS APPLICATION_SITE_URI:', process.env.REACT_APP_PRINCIPAL_URL)
export const WEB_SITE_URI = process.env.REACT_APP_SITE_URI

console.log('HOSTS APPLICATION_BACKOFFICE_URI:', process.env.REACT_APP_GEST_URI)
export const WEB_BACKOFFICE_URI = process.env.REACT_APP_GEST_URI

console.log('NODE_ENV SIMCore_API:', process.env.REACT_APP_API_URL)
export const SIMCore_API = process.env.REACT_APP_API_URL;

console.log('NODE_ENV APP_REPO_FOLDER:', process.env.REACT_APP_REPO_FOLDER)
export const APP_REPO_FOLDER = process.env.REACT_APP_REPO_FOLDER

console.log('NODE_ENV REPO URL:', process.env.REACT_APP_REPO_URL)
export const REPO = process.env.REACT_APP_REPO_URL

console.log('NODE_ENV SIMCore_API_MAIL:', process.env.REACT_APP_API_EMAIL_URL)
export const SIMCore_API_MAIL = process.env.REACT_APP_API_EMAIL_URL

console.log('NODE_ENV EMAIL_WEB_SITE_URL:', process.env.REACT_APP_SITE_URL)
console.log('NODE_ENV EMAIL_WEB_SITE_URL_REAL :', process.env.REACT_APP_SITE_URL_REAL)
export const EMAIL_WEB_SITE_URL = process.env.REACT_APP_SITE_URL_REAL

console.log('NODE_ENV EMAIL_WEB_BACKOFFICE_URL:', process.env.REACT_APP_GEST_URL)
console.log('NODE_ENV EMAIL_WEB_BACKOFFICE_URL_REAL:', process.env.REACT_APP_BACKOFFICE_URL_REAL)
export const EMAIL_WEB_BACKOFFICE_URL = process.env.REACT_APP_BACKOFFICE_URL_REAL;


// #####################################################################


