import React, { useState, useEffect } from 'react';


import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';
/* import { Helmet } from 'react-helmet'; */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

import * as Hosts from '../../../Hosts';
import { reqGET, content_language } from '../../../Utils';

import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Loader from '../../../loader';
import Breadcrumb from '../Breadcrumb/Breadcrumb';

export default function List(props) {

    const [isloading, setLoading] = useState(true);
    
    const [lang, set_lang] = useState(localStorage.getItem('lang'));

    const [lang_id, set_lang_id] = useState('1');

    const data_interface = {
        id: '',
        info: {},
        content: {}
    }
    const [content, set_content] = useState(data_interface);
    const [/*gallery*/, set_gallery] = useState([]);

    useEffect(() => {

        set_lang(localStorage.getItem('lang'))
        content_language.forEach(el => {
            if (el.code === localStorage.getItem('lang')) {
            set_lang_id(el.id)
            }
        })

        try {
			let id = props.info.state.id;
			let slug = props.info.state.slug;
            let preview_id = props.info.state.preview;

            if(id !== '') {

                let getPreviewInfoId = async () => {
                    await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${preview_id}`)
                        .then(res => { 
                            if(res.data.length > 0) {
                                res.data[0].info['preview'] = true

                                var hoje = new Date();
                                let status = 0
                                if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                    var date_p = new Date(res.data[0].info.date.published);
        
                                    status = 1
                                    if(hoje >= date_p) 
                                        status = 2
                                }
       
                                if(res.data[0].info.preview === true) {
                                    //res.data[0].content = {text: 'preview'}
        
                                    set_content(res.data[0])
                                    setLoading(false)   
                                }
                                else if(status !== 2) {
                                    res.data[0].content = {text: 'rascunho ou agendado'}
        
                                    set_content(res.data[0])
                                    setLoading(false)    
                                }
                                else {
                                    set_content(res.data[0]) 
        
                                    if(res.data[0].content['gallery'] !== undefined && res.data[0].content['gallery'] !== '') {
                                        reqGET(`${Hosts.SIMCore_API}/gallery/${Hosts.companycode}/${res.data[0].content['gallery']}`)
                                            .then(res => {
                                                set_gallery(res.data[0].info.media);
                                            })
                                            .catch(error => console.log(error)/*TODO: GO TO FAIL FETCH*/)
                                    }
            
                                    setLoading(false) 
                                }
                            }
                        })
                        .catch(erro => console.log('ERROR', erro))
                }
        
                let getInfoId = async () => {
                    await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${id}`)
                        .then(res => { 
                            if(res.data.length > 0) {
        
                                var hoje = new Date();
                                let status = 0
                                if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                    var date_p = new Date(res.data[0].info.date.published);
        
                                    status = 1
                                    if(hoje >= date_p) 
                                        status = 2
                                }
        
                                if(res.data[0].info.preview === true) {
                                    set_content(res.data[0])
                                    setLoading(false)   
                                }else if(status !== 2) {
                                    res.data[0].content = {text: 'rascunho ou agendado'}
        
                                    set_content(res.data[0])
                                    setLoading(false)    
                                }
                                else {
                                    set_content(res.data[0]) 
        
                                    if(res.data[0].content['gallery'] !== undefined && res.data[0].content['gallery'] !== '') {
                                        reqGET(`${Hosts.SIMCore_API}/gallery/${Hosts.companycode}/${res.data[0].content['gallery']}`)
                                            .then(res => {
                                                set_gallery(res.data[0].info.media);
                                            })
                                            .catch(error => console.log(error)/*TODO: GO TO FAIL FETCH*/)
                                    }
            
                                    setLoading(false) 
                                }
                            }
                        })
                        .catch(erro => console.log('ERROR', erro))
                }
            
                let getInfoSlug = async () => {
                    reqGET(`${Hosts.SIMCore_API}/web/content/slug/${Hosts.companycode}/${slug}`)
                        .then(res => { 
                            if(res.data.length > 0) {
        
                                var hoje = new Date();
                                let status = 0
                                if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                    var date_p = new Date(res.data[0].info.date.published);
        
                                    status = 1
                                    if(hoje >= date_p) 
                                        status = 2
                                }
        
                                if(status !== 2)  {
                                    res.data[0].content = [{text: 'rascunho ou agendado'}]
        
                                    set_content(res.data[0])
                                    setLoading(false)
                                }
                                else {
                                    if(res.data[0].content['gallery'] !== undefined && res.data[0].content['gallery'] !== '') {
                                        reqGET(`${Hosts.SIMCore_API}/gallery/${Hosts.companycode}/${res.data[0].content['gallery']}`)
                                            .then(res => {
                                                set_gallery(res.data[0].info.media);
                                            })
                                            .catch(error => console.log(error)/*TODO: GO TO FAIL FETCH*/)
                                    }
        
                                    set_content(res.data[0]) 
        
                                    setLoading(false) 
                                }
                            }
                        })
                        .catch(erro => console.log('ERROR', erro))
                }
        
                if(preview_id !== undefined && preview_id !== 'undefined' && preview_id !== '') {
                    getPreviewInfoId()
                }else if(id !== undefined && id !== 'undefined' && id !== '') {
                    getInfoId()
                }
                else if(slug !== undefined && slug !== 'undefined' && slug !== '')  {
                    getInfoSlug()
                }
            }
            else {
                console.log('=== ""')
            }

            return

        } catch {
            console.log('nada')

			return
		}
        
    }, [props.info.state.id, props.info.state.slug, props.info.state.preview]);

    return (isloading ?

		<Loader />
		
		:
        <>

            {
            content.info['preview'] === true ? 
                <div className="col-12 text-center p-2 bg-warning">
                    <span>This is a preview</span>
                </div>
            : 
                null
            }
            <Navbar />
            <Breadcrumb />
            <main className="list mt-3">
                <div>
                    {/* <Helmet>
                        <title>{content.info.seo !== undefined && content.info.seo['title'] !== undefined ? content.info.seo['title'] : Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_TITLE}</title>
                        <meta name="description" content={content.info.seo !== undefined && content.info.seo['description'] !== undefined ? content.info.seo['description'] : Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_DESCRIPTION} />
                        <meta name="keywords" content={content.info.seo !== undefined && content.info.seo['keywords'] !== undefined ? content.info.seo['keywords'] : Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_KEYWORDS} />
                    </Helmet> */}
                </div>

                <section className="content-body">
                    <div className="container">
                        <div className="row">
                        {
                        content.content.map((el, k) => {  
                            if (k === 0){
                                return (
                                    <div key={k}>
                                        {
                                            <div className="col-12 mb-5" data-k={k}>

                                                {
                                                    lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 0 ?

                                                        <div className="div-image"> 
                                                            <img src={el['language'][lang_id]['media']['image'][0]['link']} className="img-fluid" alt={el['language'][lang_id]['media']['image'][0]['name']} />
                                                        </div>

                                                    :

                                                        el['media'] !== undefined && el['media']['image'] !== undefined && el['media']['image'].length > 0 ? (
                                                            <div className="div-image"> 
                                                                <img src={el['media']['image'][0]['link']} className="img-fluid" alt={el['media']['image'][0]['name']} />
                                                            </div>

                                                        ) : null 

                                                }

                                                <div className="div-content" data-k={k}>
                                                    <h2 className={el.title !== '' ? "titulo-primario my-3" : "d-none"}>
                                                        {lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title)}
                                                    </h2>
                                                    { 
                                                        el.subtitle !== '' ?
                                                            <h2 className={el.subtitle !== '' ? "titulo-secundario mb-3" : "d-none"}>
                                                                {lang === 'pt' || el.language === undefined ? el.subtitle : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].subtitle === undefined || el.language[lang_id].subtitle === '' ? el.subtitle : el.language[lang_id].subtitle)}
                                                            </h2>
                                                        :
                                                            ''
                                                    }
                                                    { 
                                                        el.text !== '' ?
                                                            <div className={"div-text"}>
                                                                {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.text : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].text === undefined || el.language[lang_id].text === '' || el.language[lang_id].text === '<p></p>\n' ? el.text : el.language[lang_id].text))}
                                                            </div>
                                                        :
                                                            ''
                                                    }
                                                </div>

                                                {
                                                    lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 1 ?
                                                        <div className="div-content mb-5">
                                                            <div className="row div-gallery">
                                                                {
                                                                el.language[lang_id]['media']['image'].slice(1).map((el_img, k_img) => {
                                                                    return (
                                                                        <div key={k_img} className="col-2">
                                                                            <figure>
                                                                                <img className="img-fluid" src={el_img.link} alt={el_img.name} title={el_img.name} />
                                                                                <figcaption>{el_img.description}</figcaption>
                                                                            </figure>
                                                                        </div>
                                                                    )
                                                                })
                                                                }
                                                            </div>
                                                        </div>
                                                    :
                                                        el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 1 ? (
                                                            <div className="div-content mb-5">
                                                                <div className="row div-gallery">
                                                                    {
                                                                    el.media.image.slice(1).map((el_img, k_img) => {
                                                                        return (
                                                                            <div key={k_img} className="col-2">
                                                                                <figure>
                                                                                    <img className="img-fluid" src={el_img.link} alt={el_img.name} title={el_img.name} />
                                                                                    <figcaption>{el_img.description}</figcaption>
                                                                                </figure>
                                                                            </div>
                                                                        )
                                                                    })
                                                                    }
                                                                </div>
                                                            </div>
                                                        ) : null 
                                                }
                                                {
                                                    lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['doc'] !== undefined && el.language[lang_id]['media']['doc'].length > 0 ?
                                                        <div className="div-content mb-5">
                                                            <div className="row div-documents">
                                                                {
                                                                el.language[lang_id]['media']['doc'].map((el_doc, k_doc) => {
                                                                    return (
                                                                        <div key={k_doc} className="col-12 mb-2">
                                                                            {/* <img className="img-fluid" src={el_doc.link} title="" /> */}
                                                                            
                                                                            <a href={el_doc.link} rel="noreferrer" target="_blank">
                                                                                <FontAwesomeIcon className="font-weight-small pr-2" icon={faFilePdf} fixedWidth />
                                                                                {el_doc.name}
                                                                            </a>
                                                                        </div>
                                                                    )
                                                                })
                                                                }
                                                            </div>
                                                        </div>
                                                    :
                                                        el['media'] !== undefined && el['media']['doc'] !== undefined && el.media.doc.length > 0 ? (
                                                            <div className="div-content mb-5">
                                                                <div className="row div-documents">
                                                                    {
                                                                    el.media.doc.map((el_doc, k_doc) => {
                                                                        return (
                                                                            <div key={k_doc} className="col-12 mb-2">
                                                                                {/* <img className="img-fluid" src={el_doc.link} title="" /> */}
                                                                                
                                                                                <a href={el_doc.link} rel="noreferrer" target="_blank">
                                                                                    <FontAwesomeIcon className="font-weight-small pr-2" icon={faFilePdf} fixedWidth />
                                                                                    {el_doc.name}
                                                                                </a>
                                                                            </div>
                                                                        )
                                                                    })
                                                                    }
                                                                </div>
                                                            </div>
                                                        ) : null 
                                                }
                                            </div>
                                        }
                                    </div>
                                )
                            }
                            return ''
                        })
                        }
                        </div>
                    </div>
                </section>

                <section className="content-body content-list">
                    <div className="container">
                            {
                            content.content.map((el, k) => {  
                                return (
                                    k > 0 ?
                                        /*<div key={k} className={el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 0 ? "d-flex align-items-center row mb-5" : "row mb-5"} >*/
                                        <div key={k} className={"align-items-center row mb-5"} >
                                            {
                                                lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 0 ?
                                                    <div className="col-5 col-image d-flex align-items-center justify-content-center">
                                                                                                        
                                                        <div 
                                                            style={{ 
                                                                backgroundImage: `url(` + el['language'][lang_id]['media']['image'][0]['link'] + `)`,
                                                                backgroundSize: `cover`,
                                                                backgroundPosition: `center center`,
                                                                width: `100%`,
                                                                height: `350px`
                                                            }}
                                                        >
                                                        </div>
                                                    </div>
                                                :
                                                    el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 0 ?
                                                        <div className="col-5 col-image d-flex align-items-center justify-content-center">
                                                            <div
                                                                style={{ 
                                                                    backgroundImage: `url(` + el.media.image[0].link + `)`,
                                                                    backgroundSize: `cover`,
                                                                    backgroundPosition: `center center`,
                                                                    width: `100%`,
                                                                    height: `350px`
                                                                }}
                                                            >
                                                                {/* <img src={el.media.image[0].link} /> */}
                                                            </div>
                                                        </div>
                                                    :
                                                        ''
                                            }
                                            
                                            <div className={(((el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 0) || (lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 0)) ? "col-7 " : "col-12 " ) + "col-text mt-4 mt-md-0"} >
                                                <div className="div-content">
                                                    <h2 className={el.title !== '' ? "titulo-primario my-3" : "d-none"}>
                                                        {lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title)}
                                                    </h2>
                                                    { 
                                                        el.subtitle !== '' ?
                                                            <h2 className={el.subtitle !== '' ? "titulo-secundario mb-3" : "d-none"}>
                                                                {lang === 'pt' || el.language === undefined ? el.subtitle : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].subtitle === undefined || el.language[lang_id].subtitle === '' ? el.subtitle : el.language[lang_id].subtitle)}
                                                            </h2>
                                                        :
                                                            ''
                                                    }
                                                    { 
                                                        el.text !== '' ?
                                                            <div className={"div-text"}>
                                                                {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.text : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].text === undefined || el.language[lang_id].text === '' ? el.text : el.language[lang_id].text))}
                                                            </div>
                                                        :
                                                            ''
                                                    }
                                                </div>
                                                {
                                                    lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['doc'] !== undefined && el.language[lang_id]['media']['doc'].length > 0 ?
                                                        <div className="div-content mb-5">
                                                            <div className="row div-documents">
                                                                {
                                                                el.language[lang_id]['media']['doc'].map((el_doc, k_doc) => {
                                                                    return (
                                                                        <div key={k_doc} className="col-12 mb-2">
                                                                            {/* <img className="img-fluid" src={el_doc.link} title="" /> */}
                                                                            
                                                                            <a href={el_doc.link} rel="noreferrer" target="_blank">
                                                                                <FontAwesomeIcon className="font-weight-small pr-2" icon={faFilePdf} fixedWidth />
                                                                                {el_doc.name}
                                                                            </a>
                                                                        </div>
                                                                    )
                                                                })
                                                                }
                                                            </div>
                                                        </div>
                                                    :
                                                        el['media'] !== undefined && el['media']['doc'] !== undefined && el.media.doc.length > 0 ? (
                                                            <div className="div-content mb-5">
                                                                <div className="row div-documents">
                                                                    {
                                                                    el.media.doc.map((el_doc, k_doc) => {
                                                                        return (
                                                                            <div key={k_doc} className="col-12 mb-2">
                                                                                {/* <img className="img-fluid" src={el_doc.link} title="" /> */}
                                                                                
                                                                                <a href={el_doc.link} rel="noreferrer" target="_blank">
                                                                                    <FontAwesomeIcon className="font-weight-small pr-2" icon={faFilePdf} fixedWidth />
                                                                                    {el_doc.name}
                                                                                </a>
                                                                            </div>
                                                                        )
                                                                    })
                                                                    }
                                                                </div>
                                                            </div>
                                                        ) : null 
                                                }
                                            </div>
                                        </div>
                                    :
                                        ''
                                )
                            })
                            }
                    </div>
                </section>

            </main>
            <Footer />
        </>
    )
}