import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';


//import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';
//import parse from 'html-react-parser';
//import { Helmet } from 'react-helmet';

import * as Hosts from '../../../Hosts';
import { reqGET, content_language, /*set_language*/ } from '../../../Utils';

import Loader from '../../../loader';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
// import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { language } from '../WEBContent/Language';

import AssecImage from "../ASSEC_Image/Image"

export default function PartnerCards(props) {
    const { pathname } = useLocation();

    const [isloading, setLoading] = useState(true);

    //const [lang, set_lang] = useState('pt');
    const [lang, set_lang] = useState(localStorage.getItem('lang'));
    const [lang_id, set_lang_id] = useState('1');

    const data_interface = {
        id: '',
        info: {},
        content: {}
    }
    const [/*content_parent*/, set_content_parent] = useState(data_interface);
    const [list_content, set_list_content] = useState([]);

    useEffect(() => {
        /* console.log('aaaa') */
        set_lang(localStorage.getItem('lang'))
        content_language.forEach(el => {
            if (el.code === localStorage.getItem('lang')) {
            set_lang_id(el.id)
            }
        })

        try {
            let getInfoList = async () => {

                await reqGET(`${Hosts.SIMCore_API}/web/structure/${Hosts.companycode}/1`)
                    .then(res => {
                        if(res.data.length > 0) {
                            res.data.forEach((v) => {
                                if (v.info.principal === true) {
                                    //set_dados(dados.current = v);

                                    v.content.childs.forEach((el) => {
                                        
                                        if(el.id_content === '' && ('/' + el.link === pathname)) {
                                            if(el['parent'] !== undefined) {
                                                console.log(el['parent'])
                                            }
                                        }

                                        if(el['children'] !== undefined && el['children'].length > 0) {
                                            el['children'].forEach(async (el_c) => {
                                                if(el_c.id_content === '' && ('/' + el_c.link === pathname)) {
                                                    if(el_c['parent'] !== undefined) {

                                                        await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${el_c['parent']}`)
                                                            .then(res => { 
                                                                if(res.data.length > 0) {
                                            
                                                                    var hoje = new Date();
                                                                    let status = 0
                                                                    if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                                                        var date_p = new Date(res.data[0].info.date.published);
                                            
                                                                        status = 1
                                                                        if(hoje >= date_p) 
                                                                            status = 2
                                                                    }
                                            
                                                                    if(status !== 2) {
                                                                        res.data[0].content = {text: 'rascunho ou agendado'}
                                            
                                                                        set_content_parent(res.data[0])   
                                                                    }
                                                                    else {
                                                                        set_content_parent(res.data[0]) 
                                                                    }
                                                                }
                                                            })
                                                            .catch(erro => console.log('ERROR', erro))

                                                    }
                                                }
                                            })
                                        }

                                    })
                                    
                                }
                            })
                        }
                        else {
                            return []
                        }

                    })
                    .catch(erro => console.log('ERROR', erro))

                await reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/gallery/order`)
                    .then(res => { 
                        if (res.data.length > 0) {

                            var hoje = new Date();
                            let aux = []
                            res.data.forEach((v) => {
                              if(v.info.date.published !== undefined && v.info.date.published !== '') {
                                  var date_p = new Date(v.info.date.published);
                    
                                  if(hoje >= date_p) 
                                    aux.push(v)
                              }
                            })
                            set_list_content(aux)
                            //set_list_content(res.data)
                            //console.log('NOTICIAS', res.data)
                  
                            setLoading(false)
                          }
                    })
                    .catch(erro => console.log('ERROR', erro))
            }
            getInfoList()
 
            return

        } catch {
            console.log('nada')

			return
		}
        
    }, [pathname]);

    return (isloading ?

		<Loader />
		
		:
        <>
            <Navbar />
            <Breadcrumb />
            <main className="gallery-cards">
            {
                list_content.length > 0 ?
                    <section id="gallery-cards" className="content-body">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 py-3 py-md-5 text-center">
                                    <h3 className="titulo-primario">{language[lang].galleries}</h3>
                                </div>
                            </div>
                                        
                            <div className="row justify-content-center">
                                {
                                list_content.map((content) => {
                                    return (
                                        content.content.map((el, k) => {  
                                            return (
                                                <div key={k} className="col-6 col-md-4 col-lg-4 my-3">
                                                    <div className="card gallery-item hover-animate">
                                                        <a className='text-decoration-none' href={'./gallery/' + content.id} alt={lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title)} title={lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title)}>
                                                            <div className="partner-img"> 
                                                            {
                                                                lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 0 ?
                                                                    <AssecImage 
                                                                        linkImage={el['language'][lang_id]['media']['image'][0]['link']}
                                                                        width="450"
                                                                        height="300"
                                                                        fit='crop'
                                                                        type='img'
                                                                        sClass="img-fluid" 
                                                                        alt={el['language'][lang_id]['media']['image'][0]['name']}
                                                                    />
                                                                :
                                                                    el['media'] !== undefined && el['media']['image'] !== undefined && el['media']['image'].length > 0 ? (
                                                                        <AssecImage 
                                                                            linkImage={el['media']['image'][0]['link']}
                                                                            width="450"
                                                                            height="300"
                                                                            fit='crop'
                                                                            type='img'
                                                                            sClass="img-fluid" 
                                                                            alt={el['media']['image'][0]['name']}
                                                                        />
                                                                    ) : null 
                                                            }
                                                            </div>
                                                            {/*<img src={el['media']['image'][0]['link']} className="img-fluid" alt={el['media']['image'][0]['name']} />*/}
                                                            <div className="card-body">
                                                                <div className="card-title subtitulo-primario text-center m-0">
                                                                    {lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title)}
                                                                </div>
                                                                {/*
                                                                    el.abstract !== '' ?
                                                                        <div className="card-text">{ReactHtmlParser(el.abstract)}</div>  
                                                                    : null 
                                                                */}                                                              
                                                            </div>
                                                        </a>
                                                        <div className="card-footer text-center d-none">
                                                            <a className="btn btn-text" href={'./gallery/' + content.id} title={language[lang].see_gallery}>
                                                                {language[lang].see_gallery}
                                                                <i className="bi bi-chevron-right"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div> 
                                            )
                                        })
                                    )
                                })
                                }
                            </div>
                        </div>
                    </section>
                : null
            }
            </main>
            <Footer />
        </>
    )
}