import React, { useState, useEffect } from 'react';

//import parse from 'html-react-parser';

//import ReactHtmlParser from 'react-html-parser';

import "../Pagina.css";
import "../content-styles.css"

import * as Hosts from '../../../Hosts';
import {
    set_language,
	reqGET,
    content_language,
    /*repo_site_assets_link,*/

} from '../../../Utils';


import Navbar from "../Navbar/Navbar";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Footer from "../Footer/Footer";

//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faCircle, faCheck, faBan} from "@fortawesome/free-solid-svg-icons";

import { language } from '../WEBContent/Language';

import Loader from '../../../loader';


const LiveCam = () => {

    const [lang, set_lang] = useState('pt');
    const [/*lang_id*/, set_lang_id] = useState('1');
    const [url, set_url] = useState();

    const [isloading, setLoading] = useState(true);


    const [dataWebcams, setDataWebcams] = useState([]);

    let getInfoWebcams = async () => {

        reqGET(`${Hosts.SIMCore_API}/estancia/multimedia/webcams`)
            .then(res => {
                //console.log(res.data[0].multimedia.link_videos.filter(v => v[localStorage.getItem('lang')]))
                setDataWebcams(res.data[0].multimedia.link_videos.filter(v => v[localStorage.getItem('lang')]))
                setLoading(false)
            })
        .catch(erro => console.log(erro))  


    }

    
    useEffect(() => {

        let url = window.location.hostname
        set_url(url)

        if(localStorage.getItem('lang') === undefined  || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if(el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }

        getInfoWebcams()
    
    }, []); 


    return (

        isloading ?

            <Loader />

		:

        <>
            <Navbar />  

            <Breadcrumb /> 

            <div id="livecam" className="container-fluid my-5">
                <div className="row">
                    <div className="container">
                        <div className='row'>
                            <div className="col-12 my-4">
                                <h3 className="titulo-primario">{language[lang].livecam}</h3>
                            </div>
                            {
                                dataWebcams.map((v, key) => {

                                    if(v.is_twitch) {

                                        let aux_twitch_url = v.url.split('/').pop();
                                    
                                        return (

                                            <div className={"col-12 col-sm-6 text-center my-4"} key={key}>
                                                <h3 className="subtitulo-primario mb-2">{v[lang]}</h3>
                                                <div>
                                                    <iframe alt={v[lang]} title={v[lang]} frameBorder="0" allowFullScreen={true} scrolling="no" src={"https://player.twitch.tv/?channel=" + aux_twitch_url + "&parent=" + url + "&muted=true"} width="100%" height="303"></iframe>
                                                </div>

                                            </div>
                                        );
                                    }else {

                                        return (

                                            <div className={"col-12 col-sm-6 text-center my-4"} key={key}>
                                                <h3 className="subtitulo-primario mb-2">{v[lang]}</h3>
                                                <div>
                                                    <img className="img-fluid" alt={v[lang]} title={v[lang]} src={v.url} />
                                                </div>
                                            </div>
                                        );
                                        
                                    }

                                })
                            }

                        </div>
                        {/*
                        //webcams estáticas
                        <div className="row d-none">
                            <div className="col-12 my-4">
                                <h3 className="titulo-primario">{language[lang].livecam}</h3>
                            </div>
                            <div className="col-12 col-sm-6 text-center my-4">
                                <h3 className="subtitulo-primario mb-2">{language[lang].livecam_2} 1</h3>
                                <div>
                                    <iframe alt={language[lang].livecam_2 + ' 1'} title={language[lang].livecam_2 + ' 1'} frameBorder="0" allowFullScreen={true} scrolling="no" src={"https://player.twitch.tv/?channel=meteoestrelawebcams&parent=" + url + "&muted=true"} width="100%" height="303"></iframe>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 text-center my-4">
                                <h3 className="subtitulo-primario mb-2">{language[lang].livecam_2} 2</h3>
                                <div>
                                    <img className="img-fluid" alt={language[lang].livecam_2 + ' 2'} title={language[lang].livecam_2 + ' 2'} src="https://www.meteoestrela.pt/assets/webcam/cam_50.jpg" />
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 text-center my-4">
                                <h3 className="subtitulo-primario mb-2">{language[lang].livecam_2} 3</h3>
                                <div>
                                    <img className="img-fluid" alt={language[lang].livecam_2 + ' 3'} title={language[lang].livecam_2 + ' 3'} src="https://www.meteoestrela.pt/assets/webcam/cam_20.jpg" />
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 text-center my-4">
                                <h3 className="subtitulo-primario mb-2">{language[lang].livecam_2} 4</h3>
                                <div>
                                    <img className="img-fluid" alt={language[lang].livecam_2 + ' 4'} title={language[lang].livecam_2 + ' 4'} src="https://www.meteoestrela.pt/assets/webcam/cam_7.jpg" />
                                </div>
                            </div>

                            <div className="col-12 col-sm-6 text-center my-4">
                                <h3 className="subtitulo-primario mb-2">{language[lang].livecam_2} 5</h3>
                                <div>
                                    <img className="img-fluid" alt={language[lang].livecam_2 + ' 5'} title={language[lang].livecam_2 + ' 5'} src="https://www.meteoestrela.pt/assets/webcam/cam_60.jpg" />
                                </div>
                            </div>

                            <div className="col-12 col-sm-6 text-center my-4 d-none">
                                <h3 className="subtitulo-primario mb-2">{language[lang].livecam_2} 6</h3>
                                <div>
                                    <img className="img-fluid" alt={language[lang].livecam_2 + ' 6'} title={language[lang].livecam_2 + ' 6'} src="https://www.meteoestrela.pt/assets/webcam/cam_2.jpg" />
                                </div>
                            </div>

                        </div>
                        */}
                    </div>  
                </div>              
            </div>    
        
            <Footer />
        </>
      
    );

};
export default LiveCam;